@import "../../../../../styles/_colors.scss";
@import "./node_modules/bulma/sass/utilities/mixins.sass";



.risk {
    @include until($desktop) {
        background-color: $peach-very-light;
        min-height: calc(100% - 47px);
        overflow: auto;
    }
    

    
    .button {
        @include until($desktop) {
            width: 95%;
            display: inline-block;
        }
    }
    
    .process {
        .columns {
            @include from($desktop) {
                max-width: 48.125rem;
            }
            
        }
    }

    .result {
        .meter-container {
            justify-content: center;

        }
        &.is-primary {
            .meter-responsive {
            @include until($desktop){
                width: 100%!important;
                display:block;
            }
        }
        }

        &.is-secondary {
            .meter-container {
    
                transform: scale(0.7);

                @include from($tablet) {
                    transform: scale(0.9);
                }
                @include from($desktop) {
                    transform: scale(0.6);
                }
                @include from($widescreen) {
                    transform: scale(0.75);
                }
            }
        }
    }

    .results {
        max-width: 61.875rem;
    }

}