@import "../../styles/_colors.scss";
@import "/node_modules/bulma/sass/utilities/mixins.sass";

.info-icon {
    vertical-align: middle;
}

.info-explainer {
    position: absolute;
    z-index: 40;
    min-width: 14rem;
    max-width: 36rem;
    color:black;
    font-weight: 400;
    white-space:normal !important;

    &.is-positioned-top {
        bottom: 2em !important;
        
        &-touch {
            bottom: null;

            @include until($desktop) {
                bottom: 2em !important;
            }
        }
        
        &-desktop {
            top: null;

            @include from($desktop) {
                bottom: 2em !important;
            }
        }
    }

    &.is-positioned-bottom {
        top: 0px;

        &-touch {
            top: null;

            @include until($desktop) {
                top: 0px;
            }
        }
        
        &-desktop {
            top: null;

            @include from($desktop) {
                top: 0px;
            }
        }
    }

    &.is-positioned-right {
        right: 0px;

        &-touch {
            right: null;

            @include until($desktop) {
                right: 0px;
            }
        }
        
        &-desktop {
            top: null;

            @include from($desktop) {
                right: 0px;
            }
        }
    }

    &.is-positioned-left {
        left: 0px;
        
        &-touch {
            left: null;

            @include until($desktop) {
                left: 0px;
            }
        }
        
        &-desktop {
            left: null;

            @include from($desktop) {
                left: 0px;
            }
        }
    }

    .notification {
        border: 1px solid $grey;
    }
}