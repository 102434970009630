@use "../../styles/colors";
@import '../../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../../node_modules/bulma/sass/components/card.sass';
@import '../../styles/fonts';

$card-radius: 10px;

.carousel-with-parts-overlay {
    /// This really needs to be moved so carousel component handles this
    .tile {
        article {
            max-width: 100%;
        }
    }

    .card-image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        width: 8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparentize($color: $black, $amount: .25);
        color: $white;
        border-top-right-radius: $card-radius;
        border-bottom-right-radius: $card-radius;
        font-size: 1.25rem;

        &.purple-blue-300 {
            background-color: transparentize($color: map-get(map-get(colors.$color-pallete, "purple-blue"), "300"), $amount: .25);
        }

        &.purple-blue-500 {
            background-color: transparentize($color: map-get(map-get(colors.$color-pallete, "purple-blue"), "500"), $amount: .25);
        }
    }
}