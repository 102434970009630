@import '../../../styles/colors';
@import "../../../../node_modules/bulma/sass/utilities/mixins.sass";

$border-radius: 0.5rem;

.dashboard {
    .dashboard-join-our-community {
        background: $peach-gradient-horizantal;
        border-radius: $border-radius;
    }

    .dashboard-upcoming-events {
        border-radius: 0.5rem;
        border-radius: $border-radius;
    }
}