@use "../../../../../styles/_colors.scss" as colors;
@import "../../../../../../node_modules/bulma/sass/utilities/_all.sass";

.level-card {
	border-radius: 30px !important;
	box-shadow: none !important;
	min-height: 150px;
	width: 100%;
	white-space: normal !important;

	.url-icon {
		height: 100%;
		svg {
			height: 100%;
		}
	}

	&.complete {
		border: 1px solid #CBFFB1;
		background: #F1FFEA;

		.level-circle {
			background: #E3FFD6;
			border: 1px solid #CBFFB1;
		}
		.url-icon {
			color: #1B0777 !important;
		}
	}



	&.active {
		border: 1px solid #F5BDAC;
		background: linear-gradient(118.93deg, #FFEFEA -5.87%, #FFEBD9 109.87%);
		color: #1B0777;

		.level-circle {
			background: linear-gradient(118.93deg, #F5BDAC 66.92%, #F5D5B8 109.87%);
		}

		.active,
		.complete {
			color: #1B0777 !important;
		}

		.inactive {
			color: #a2a2a2 !important;
			cursor: not-allowed !important;
			pointer-events: none !important;
			filter: none;
		}
	}


	&.inactive,
	&.coming-soon {
		border: 1px solid #E0E0E0;
		background: #F6F6F6;
		filter: none;

		.url-icon {
			color: #a2a2a2 !important;
			cursor: not-allowed !important;
			pointer-events: none !important;
		}

		.topics-container {
			color: #a2a2a2;
		}

		.level-circle {
			background: #EBEBEB;
			border: 1px solid #E0E0E0;
		}
	}

	.level-circle {
		font-weight: 600;
		font-size: 18px;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		position: absolute;
		top: -15px;
		left: -15px;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
		margin: 2px;
	}

	.level-num {
		font-size: 14px;
		line-height: 21px;
		text-transform: uppercase;
	}

	.total-time {
		font-size: 16px;
		text-align: right !important;
	}

	.level-title {
		font-weight: 600;
		font-size: 18px;
		height: 34px;
	}

	.topics-to-complete {
		font-size: 16px;
		text-align: right;
		height: 34px;
		
		@include mobile {
			display: none;
		}
	}

	.level-description {
		font-size: 16px;
		height: initial;
	}

	.explandable {
		text-align: center;

		a {
			text-decoration: underline;
			color: #393939;
		}
	}

	@media(max-width: 1023px) {
		padding: 28px 26px;

		.reverse-columns {
			flex-direction: column-reverse;
			display: flex;
		}

		.level-title {
			height: initial;
		}

		.total-time {
			font-size: 14px;
		}
	}

	.topics-container {
		margin-bottom: 1.5em;
		counter-reset: topic;

		@media(max-width: 1023px) {
			margin-top: 1.5em;
		}

		.topic-row {
			border-top: 1px solid #aaa;
			.topic-title::before {
				counter-increment: topic;
				content: "" counter(topic)". ";
				margin-right: 3px;
			}

			.title-and-duration {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				@include tablet {
					align-items: center;
				}

				@include mobile {
					flex-direction: column;
				}
			}

			.topic-title {
				flex-grow: 10;
				max-width: 75%;

				@media(max-width: 1023px) {
					display: flex;
					max-width: 100%;
				}
			}

			.topic-duration {
				text-align: left;
				flex-grow: 2;

				@include touch {
					font-size: 14px;
					padding-left: .6rem;
				}
			}

			.topic-progress-bar {
				flex-grow: 5;
			}

			@media(max-width: 1023px) {
				display: flex;
				flex-direction: row;

				.title-and-duration {
					flex-grow: 6;
				}
			}
		}

		.topic-play-icon {
			text-align: right;
			display: flex;

			a {
				align-self: center;
				color: map-get(map-get(colors.$color-pallete, "purple-blue"), "500");
			}

			@media(max-width: 1023px) {
				display: flex;
				flex-grow: 1;

				.topic-play-icon {
					align-self: center;
					margin:auto;
					padding:0px;
					border:1px solid red;
				}
			}
		}
	}

	.total-time{
		.lock-icon{
			margin-bottom: -4px;
			margin-left:3px;
		}
	}

	.progress-bar-container {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		column-gap: 10px;
		height: 100%;

		.percentage-label {
			width: 4em;
			margin: auto;
		}

		@media(max-width: 1023px) {
			height: 100%;
			.percentage-label {
				font-size: 14px;
			}
		}
	}
}