@import "../../styles/_colors.scss";

$sizes: (
    50: 50px,
    60: 60px,
    70: 70px,
    80: 80px,
    90: 90px,
    100: 100px,
    110: 110px,
    120: 120px,
    130: 130px,
    140: 140px,
    150: 150px,
    160: 160px,
    170: 170px,
    180: 180px,
    190: 190px,
    200: 200px
);

.meter {
    width: 200px;
    height: 200px;
}

@each $size, $value in $sizes {
    .meter--#{$size} {
        width: $value;
        height: $value;
    }
}