@use "../../../../node_modules/bulma/sass/utilities/mixins.sass" as mixins;
@use "../../../styles/_colors.scss" as colors;
@import "../../../styles/_fonts.scss";

footer {
    position: relative;
    z-index: 20;
    .propelle-logo {
        position: relative;
        @include mixins.mobile {
            margin-bottom: 2rem;
        }

        img {
            width: 80%;
            @include mixins.tablet {
                width: 300px;
            }
        }
        p {
            color: transparentize(map-get(map-get(colors.$color-pallete, "grey"), "100"), 0.4);
            margin-top: -1rem;
            @include mixins.tablet {
                margin-top: 0;
            }
            @include mixins.desktop {
                margin-top: 0;
                color: #FFF;

                position: absolute;
                bottom: 2rem;
            }
        }
    }
    .logos {
        @include mixins.desktop {
            order: 2;
        }
    }
    .footer-nav {
        a {
            color: #FFF;
        }
        .legal {
            @include mixins.touch {
                order: 1;
            }
        }
    }
    .social-media {
        img {
            @include mixins.until-widescreen {
                height: 30px;
            }
        }
    }
}

