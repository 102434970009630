@use '../../styles/colors.scss' as colors;
@use './Card.scss' as card;
@use "../../../node_modules/bulma/sass/utilities/mixins.sass" as mixins;

.card-image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparentize($color: map-get(map-get(colors.$color-pallete, "grey"), "500"), $amount: .25);
    color: map-get(map-get(colors.$color-pallete, "grey"), "100");
    border-top-right-radius: card.$card-radius;
    border-bottom-right-radius: card.$card-radius;
    font-size: 1.25rem;

    &.purple-blue-300 {
        background-color: transparentize($color: map-get(map-get(colors.$color-pallete, "purple-blue"), "300"), $amount: .25);
    }

    &.purple-blue-500 {
        background-color: transparentize($color: map-get(map-get(colors.$color-pallete, "purple-blue"), "500"), $amount: .25);
    }

    &.purple-blue-400 {
        background-color: transparentize($color: map-get(map-get(colors.$color-pallete, "purple-blue"), "400"), $amount: .25);
    }
}