@import "../../../../styles/_colors.scss";
@import "../../../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../components/WealthMappingNavbar/_variables.scss";

.wealth-mapping-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @include from ($desktop) {
        min-height: 100vh;
    }
    
    h1, h2, h3, h4, h5, h6 {
        color: $navy;
    }

    a {
        font-weight: 400;
    }

    .primary-cta {   
        @include until($desktop) {
            display: flex;
            width: 100%;
            justify-content: center;
        }
        
        margin-top: 3rem;
    }

    .secondary-cta {
        text-decoration: underline;
        margin-top: 0.5rem;

        @include until($desktop) {
            margin-top: 0.5rem;
            text-align: center;
        }

        @include from($desktop) {
            margin-top: 1rem;
            margin-left: 0.25rem;
        }
    }
}