.centered-column-6 .padded-content {
  padding: 4rem 1.5rem;
}

footer {
  z-index: 20;
  position: relative;
}

footer .propelle-logo {
  position: relative;
}

@media screen and (width <= 768px) {
  footer .propelle-logo {
    margin-bottom: 2rem;
  }
}

footer .propelle-logo img {
  width: 80%;
}

@media screen and (width >= 769px), print {
  footer .propelle-logo img {
    width: 300px;
  }
}

footer .propelle-logo p {
  color: #fff9;
  margin-top: -1rem;
}

@media screen and (width >= 769px), print {
  footer .propelle-logo p {
    margin-top: 0;
  }
}

@media screen and (width >= 1024px) {
  footer .propelle-logo p {
    color: #fff;
    margin-top: 0;
    position: absolute;
    bottom: 2rem;
  }

  footer .logos {
    order: 2;
  }
}

footer .footer-nav a {
  color: #fff;
}

@media screen and (width <= 1023px) {
  footer .footer-nav .legal {
    order: 1;
  }
}

@media screen and (width <= 1215px) {
  footer .social-media img {
    height: 30px;
  }
}

.wealth-mapping-nav {
  height: 108px;
}

.wealth-mapping-nav .navbar {
  z-index: 10;
  background-image: url("peach-white-fade.0f7923c2.svg");
  min-height: 108px;
}

@media screen and (width >= 1024px) {
  .wealth-mapping-nav .navbar {
    padding: 0 1rem;
  }
}

.wealth-mapping-nav .navbar img {
  min-height: 64.8px;
  max-height: 64.8px;
  margin: .25rem .5rem;
}

.wealth-mapping-nav .navbar .navbar-brand {
  justify-content: space-between;
  height: 108px;
}

.wealth-mapping-nav .navbar a {
  color: #1b0777;
  font-weight: 600;
}

.wealth-mapping-nav .is-hidden-touch .navbar a:last-child {
  margin-left: 8rem;
}

.content-rv9AIY:last-of-type {
  margin-bottom: 0 !important;
}

.wealth-mapping-layout {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

@media screen and (width >= 1024px) {
  .wealth-mapping-layout {
    min-height: 100vh;
  }
}

.wealth-mapping-layout h1, .wealth-mapping-layout h2, .wealth-mapping-layout h3, .wealth-mapping-layout h4, .wealth-mapping-layout h5, .wealth-mapping-layout h6 {
  color: #1b0777;
}

.wealth-mapping-layout a {
  font-weight: 400;
}

.wealth-mapping-layout .primary-cta {
  margin-top: 3rem;
}

@media screen and (width <= 1023px) {
  .wealth-mapping-layout .primary-cta {
    justify-content: center;
    width: 100%;
    display: flex;
  }
}

.wealth-mapping-layout .secondary-cta {
  margin-top: .5rem;
  text-decoration: underline;
}

@media screen and (width <= 1023px) {
  .wealth-mapping-layout .secondary-cta {
    text-align: center;
    margin-top: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .wealth-mapping-layout .secondary-cta {
    margin-top: 1rem;
    margin-left: .25rem;
  }
}

.propelle-navbar.navbar {
  background: linear-gradient(90deg, #fff 0%, #fff6e9 100%);
  width: 100%;
  height: 108px;
}

@media screen and (width <= 1023px) {
  .propelle-navbar.navbar {
    height: 57px;
  }

  .propelle-navbar.navbar.is-white-touch {
    background: #fff;
  }
}

@media screen and (width >= 1024px) {
  .propelle-navbar.navbar {
    padding: 0 1rem;
  }
}

.propelle-navbar.navbar img {
  min-height: 64.8px;
  max-height: 64.8px;
  margin: .25rem .5rem;
}

@media screen and (width <= 1023px) {
  .propelle-navbar.navbar img {
    min-height: 45.6px;
    max-height: 45.6px;
  }
}

.propelle-navbar.navbar .navbar-brand {
  justify-content: space-between;
  height: 108px;
}

@media screen and (width <= 1023px) {
  .propelle-navbar.navbar .navbar-brand {
    height: 57px;
  }
}

.propelle-navbar.navbar a {
  color: #1b0777;
  font-weight: 600;
}

.propelle-navbar .navbar-burger {
  min-height: 108px;
}

@media screen and (width <= 1023px) {
  .propelle-navbar .navbar-burger {
    min-height: 57px;
  }
}

.propelle-navbar .navbar-burger:hover {
  background-color: inherit;
}

.propelle-navbar .navbar-burger span {
  background-color: #0c0cbd;
  width: 24px;
  height: 1px;
  left: calc(50% - 16px);
}

.propelle-navbar .navbar-menu {
  height: 100%;
  font-family: Montserrat;
}

.propelle-navbar .navbar-menu .navbar-link, .propelle-navbar .navbar-menu .navbar-dropdown a {
  color: #1b0777;
}

.propelle-navbar .navbar-menu .navbar-dropdown a:hover {
  color: #1b0777;
  background-color: #1b07771a;
}

.propelle-navbar .navbar-menu .navbar-dropdown a:active {
  color: #fff;
  background-color: #1b0777;
}

.propelle-navbar .navbar-menu .navbar-item {
  color: #1b0777;
  display: flex;
}

.propelle-navbar .navbar-menu .navbar-item.has-dropdown.is-desktop {
  padding-right: 3rem;
}

.propelle-navbar .navbar-menu .navbar-item.is-mobile {
  color: #1b0777;
}

@media screen and (width >= 1024px) {
  .propelle-navbar .navbar-menu .navbar-item.is-mobile {
    display: none;
  }
}

.propelle-navbar .navbar-menu .navbar-item.is-mobile:hover {
  color: #1b0777;
  background-color: #1b07771a;
}

.propelle-navbar .navbar-menu .navbar-item.is-mobile:active {
  color: #fff;
  background-color: #1b0777;
}

@media screen and (width <= 1023px) {
  .propelle-navbar .navbar-menu .navbar-item.is-desktop {
    display: none;
  }

  .propelle-navbar .navbar-menu {
    margin-left: 50%;
  }
}

._88Y_7a_chevron img {
  max-width: none;
}

.menu-container {
  z-index: 10;
  background-color: #fafafa;
  width: 14.5rem;
  min-height: calc(100vh - 108px);
  position: fixed;
  top: 108px;
  left: 0;
}

@media screen and (width <= 1023px) {
  .menu-container {
    display: none;
  }
}

.menu {
  margin-top: 3rem;
}

.menu .menu-list {
  border-left: none;
  margin: 0;
}

.menu .menu-list li:not(.has-children) {
  padding-left: 2rem;
}

.menu .menu-list li.has-children p {
  padding-left: 2.75rem;
}

.menu .menu-list li.has-children ul li {
  padding-left: 3rem;
}

.menu .menu-list li ul {
  padding-left: 0;
}

.menu .menu-list a:hover {
  color: #1b0777;
  background: inherit;
}

.menu ul li {
  padding: .5rem 0;
}

.menu ul li.has-children > p:hover {
  color: #1b0777;
  cursor: pointer;
  background-color: #f3f0ff;
}

.menu ul li.has-children ul {
  max-height: 0;
  transition: max-height .3s;
  overflow-y: clip;
}

.menu ul li.has-children ul.is-expanded {
  max-height: 13rem;
}

.menu ul li.has-children .chevron {
  padding: 0 2rem;
}

.menu ul li.has-children.is-active > p {
  background-color: #f3f0ff;
}

.menu ul li:not(.has-children):hover {
  cursor: pointer;
  background-color: #f3f0ff;
}

.menu ul li:not(.has-children).is-active {
  background-color: #d9d4f2;
}

.menu a {
  text-transform: none;
  color: #1b0777;
  letter-spacing: .2em;
  font-family: Montserrat;
  font-size: 1em;
  font-weight: 700;
}

.menu a:hover {
  color: #1b0777;
  background-color: #0000;
}

.menu p {
  text-transform: none;
  color: #1b0777;
  letter-spacing: .2em;
  padding: 1rem;
  font-family: Montserrat;
  font-size: 1em;
  font-weight: 700;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.content-container {
  z-index: 0;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 108px;
}

@media screen and (width <= 1023px) {
  .content-container {
    width: 100%;
    height: calc(100vh - 109px);
    top: 57px;
    left: 0;
  }
}

@media screen and (width >= 1024px) {
  .content-container {
    width: calc(100vw - 14.5rem);
    min-height: calc(100vh - 108px);
    left: 14.5rem;
  }
}

@media screen and (width <= 1023px) {
  .content-container > .propelle-loader {
    height: calc(100vh - 160px - 3rem);
  }
}

@media screen and (width >= 1024px) {
  .content-container > .propelle-loader {
    height: calc(100vh - 108px - 3rem);
  }
}

.columns.layout.is-full-height {
  margin: 0;
}

.columns.layout .column {
  padding: 0;
}

.columns.layout .menu-column {
  background-color: #fff;
  max-width: 24rem;
  display: none;
}

@media screen and (width >= 1024px) {
  .columns.layout .menu-column {
    display: flex;
  }
}

.columns.is-full-height {
  height: 100%;
}

.background {
  z-index: -1;
  background-color: #fff8f1;
  background-image: url("background-yellow-violet-fade.6d1552d5.svg");
  background-repeat: no-repeat;
  width: 100vw;
  position: fixed;
  top: 108px;
}

@media screen and (width <= 1023px) {
  .background {
    width: 100%;
    height: calc(100vh - 109px);
    top: 57px;
    left: 0;
  }
}

@media screen and (width >= 1024px) {
  .background {
    min-height: calc(100vh - 108px);
    left: 14.5rem;
  }
}

@media screen and (width <= 1023px) {
  .mobile-menu-spacer {
    height: 52px;
    min-height: 52px;
  }
}

.mobile-menu {
  background-color: #fff;
  width: 100%;
  height: 52px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.mobile-menu .mobile-menu-item {
  color: #707070 !important;
}

.mobile-menu .mobile-menu-item a {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: #707070 !important;
}

.mobile-menu .mobile-menu-item.is-active, .mobile-menu .mobile-menu-item.is-active a {
  color: #0c0cbd !important;
}

.propelle-loader span {
  background-color: #a594f9;
  border-radius: 9999px;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  animation-name: pulse;
  animation-duration: .8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: inline-block;
}

.propelle-loader span:not(:first-of-type) {
  margin-left: 1rem;
}

.propelle-loader span:first-child {
  animation-delay: 0s;
}

.propelle-loader span:nth-child(2) {
  animation-delay: .1s;
}

.propelle-loader span:nth-child(3) {
  animation-delay: .2s;
}

@keyframes pulse {
  0%, 60% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.2);
  }
}

.authorizing {
  width: 100vw;
  height: 100vh;
}

.chat-page .navbar {
  background-color: #fff6;
}

.chat-page a {
  color: #000;
}

.chat-page a span {
  margin: 0 .5rem;
}

.chat-page.is-hidden-touch {
  background-image: url("background-yellow-violet-fade.6d1552d5.svg");
}

.chat-page.is-hidden-touch .section {
  padding: 0;
  position: relative;
}

.chat-page.is-hidden-touch .section.is-nav {
  z-index: 2;
  background: #fffc;
  width: 100%;
  height: 6rem;
  padding-top: 0;
  position: fixed;
  top: 0;
}

.chat-page.is-hidden-touch .section.is-menu {
  z-index: 2;
  background: none;
  width: 100%;
  height: 4rem;
  padding-top: 6rem;
  position: fixed;
  top: 0;
}

.chat-page.is-hidden-touch .section.chat {
  margin-top: 6rem;
}

.chat-page.is-hidden-desktop {
  background-color: #fff;
}

.chat-page.is-hidden-desktop .navbar {
  background-image: none;
  border-bottom: 1px solid #cdcdcd;
}

.chat-page.is-hidden-desktop .section {
  background-color: #fff;
  margin-top: 8.5rem;
  padding: 0;
  position: relative;
}

.chat-page.is-hidden-desktop .section.is-nav {
  z-index: 2;
  background: #fffc;
  width: 100%;
  margin-top: 0;
  position: fixed;
  top: 0;
}

.chat .chat-dialog .chat-message-line .chat-message.answer {
  color: #000;
  background-color: #c4bfdf;
  border-radius: 1em;
  max-width: 40rem;
}

@keyframes bounce {
  0%, 90% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(-.2rem);
  }
}

.faces-container {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (width <= 1023px) {
  .faces-container {
    display: none;
  }
}

.faces-container .faces {
  width: 18rem;
  position: absolute;
}

.faces-container .faces.left {
  height: 100%;
  right: 0;
}

.faces-container .faces.left .face {
  background-repeat: no-repeat;
  background-size: cover;
  width: 8rem;
  height: 8rem;
}

.faces-container .faces.left .face.top {
  background-image: url("tl.352f2b4b.webp");
  top: 4rem;
  right: 8rem;
}

.faces-container .faces.left .face.bottom {
  background-image: url("bl.ee07c9e2.webp");
  bottom: 4rem;
  right: 2rem;
}

.faces-container .faces.right {
  height: 100%;
  left: 0;
}

.faces-container .faces.right .face {
  background-repeat: no-repeat;
  background-size: cover;
  width: 8rem;
  height: 8rem;
}

.faces-container .faces.right .face.top {
  background-image: url("tr.325f4eee.webp");
  top: 1rem;
  left: 3rem;
}

.faces-container .faces.right .face.bottom {
  background-image: url("br.8231c2cb.webp");
  bottom: 3rem;
  left: 5rem;
}

.faces-container .faces .face-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.faces-container .faces .face-container .face {
  position: absolute;
}

.result strong {
  color: #1b0777;
}

.result .goal {
  position: relative;
}

.result .goal .decoration {
  z-index: -1;
  width: 1px;
  height: 0;
  display: block;
  position: absolute;
}

.result .goal .decoration.top-left:before {
  content: "";
  background-image: url("yay-tl.108043e9.svg");
  background-repeat: no-repeat;
  width: 92px;
  height: 92px;
  position: absolute;
  top: -36px;
  left: 0;
}

.result .goal .decoration.top-right {
  width: 100%;
}

.result .goal .decoration.top-right:before {
  content: "";
  background-image: url("yay-tr.ca62a0d7.svg");
  background-repeat: no-repeat;
  width: 92px;
  height: 92px;
  position: absolute;
  top: -36px;
  right: 0;
}

.result .goal .decoration.bottom-left:before {
  content: "";
  background-image: url("yay-bl.b3211b01.svg");
  background-repeat: no-repeat;
  width: 92px;
  height: 92px;
  position: absolute;
  left: 0;
}

.result .goal .decoration.bottom-right {
  width: 100%;
}

.result .goal .decoration.bottom-right:before {
  content: "";
  background-image: url("yay-br.8ea30de6.svg");
  background-repeat: no-repeat;
  width: 92px;
  height: 92px;
  position: absolute;
  top: -24px;
  right: 0;
}

.footer-container {
  flex-grow: 1;
  align-items: flex-end;
  height: 100%;
  display: flex;
}

.footer-container .footer {
  width: 100%;
}

.calculator .section {
  background: url("background-yellow-violet-fade.6d1552d5.svg");
  min-height: calc(100vh - 6rem);
  padding: 0;
}

.calculator .section > :not(.footer-container) {
  padding: 3rem 1.5rem;
}

.calculator .section .footer {
  margin-top: 3rem;
}

.calculator .card .card-content {
  color: #1b0777;
}

.calculator .card .card-content p:not(:first-of-type) {
  margin-top: 1rem;
}

.calculator .card .card-content p strong {
  color: #1b0777;
}

@media screen and (width >= 1024px) {
  .calculator .help.is-danger {
    text-align: right;
  }
}

.sign-up ul {
  margin-top: 1rem;
  padding-left: 2rem;
}

.sign-up ul li:before {
  content: url("bullet.a91f4255.svg");
  width: 48px;
  height: 16px;
  margin-top: 4px;
  margin-left: -48px;
  display: inline-block;
}

.calculator-result.default-background {
  background-color: #f5f3ff !important;
}

.calculator-result.goal-reached {
  background-color: #efe !important;
}

.calculator-result .title.is-4 {
  margin-bottom: .75rem;
}

.calculator-result p {
  font-size: .8em;
}

.calculator-result .highlighted {
  margin: 1.5rem 0;
  transform: scale(1.2);
}

@media screen and (width >= 1024px) {
  .calculator-result .highlighted {
    transform: scale(1.2)translateY(-.75rem);
  }
}

.calculator-result .goal-planning-padding-x {
  padding: 0 2.5rem;
}

@media screen and (width <= 768px) {
  .calculator-result .goal-planning-padding-x {
    padding: 0 1.625rem;
  }
}

.calculator-result .goal-planning-padding-top {
  padding-top: 3.125rem;
}

.calculator-result .goal-planning-padding-bottom {
  padding-bottom: 2.813rem;
}

.performance-explainer .has-performance-bullet-down:before {
  content: "";
  background-image: url("down.4356c845.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 33px;
  height: 18px;
  margin-right: 8px;
  display: block;
}

.performance-explainer .has-performance-bullet-level:before {
  content: "";
  background-image: url("level.8189fad0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 23px;
  height: 18px;
  margin-top: 3px;
  margin-right: 18px;
  display: block;
}

.performance-explainer .has-performance-bullet-up:before {
  content: "";
  background-image: url("up.26aaf614.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 33px;
  height: 18px;
  margin-right: 8px;
  display: block;
}

.info-icon {
  vertical-align: middle;
}

.info-explainer {
  z-index: 40;
  color: #000;
  min-width: 14rem;
  max-width: 36rem;
  font-weight: 400;
  position: absolute;
  white-space: normal !important;
}

.info-explainer.is-positioned-top {
  bottom: 2em !important;
}

@media screen and (width <= 1023px) {
  .info-explainer.is-positioned-top-touch {
    bottom: 2em !important;
  }
}

@media screen and (width >= 1024px) {
  .info-explainer.is-positioned-top-desktop {
    bottom: 2em !important;
  }
}

.info-explainer.is-positioned-bottom {
  top: 0;
}

@media screen and (width <= 1023px) {
  .info-explainer.is-positioned-bottom-touch {
    top: 0;
  }
}

@media screen and (width >= 1024px) {
  .info-explainer.is-positioned-bottom-desktop {
    top: 0;
  }
}

.info-explainer.is-positioned-right {
  right: 0;
}

@media screen and (width <= 1023px) {
  .info-explainer.is-positioned-right-touch {
    right: 0;
  }
}

@media screen and (width >= 1024px) {
  .info-explainer.is-positioned-right-desktop {
    right: 0;
  }
}

.info-explainer.is-positioned-left {
  left: 0;
}

@media screen and (width <= 1023px) {
  .info-explainer.is-positioned-left-touch {
    left: 0;
  }
}

@media screen and (width >= 1024px) {
  .info-explainer.is-positioned-left-desktop {
    left: 0;
  }
}

.info-explainer .notification {
  border: 1px solid #cdcdcd;
}

.projection {
  margin-top: 1.5rem;
  overflow: hidden;
}

.projection .projection-header {
  background-color: #f5f3ff;
}

.projection .input-big {
  max-width: 15.625rem;
}

.projection .input-small {
  width: 4.063rem;
}

.projection .calculate-button {
  width: 10.625rem;
}

@media screen and (width <= 768px) {
  .projection .calculate-button {
    width: 100%;
  }
}

.projection .goal-planning-padding-x {
  padding: 0 2.5rem;
}

@media screen and (width <= 768px) {
  .projection .goal-planning-padding-x {
    padding: 0 1.625rem;
  }
}

.projection .goal-planning-padding-top {
  padding-top: 3.125rem;
}

.projection .goal-planning-padding-bottom {
  padding-bottom: 2.813rem;
}

.projection .default-background {
  background-color: #f5f3ff !important;
}

.card .card-content {
  color: #1b0777;
}

.card .card-content p:not(:first-of-type) {
  margin-top: 1rem;
}

.card .card-content p strong {
  color: #1b0777;
}

@media screen and (width >= 1024px) {
  .help.is-danger {
    text-align: right;
  }
}

.calculator-padding {
  padding: 3.125rem 2.5rem 2.813rem;
}

._0ZIwra_is-size-h1, ._0ZIwra_is-size-h2 {
  font-family: Syne;
  font-size: 60px;
  font-weight: 700;
}

._0ZIwra_is-size-h3 {
  font-family: Syne;
  font-size: 40px;
  font-weight: 700;
}

._0ZIwra_is-size-h4 {
  font-family: Syne;
  font-size: 30px;
  font-weight: 700;
}

._0ZIwra_is-size-h5 {
  font-family: Syne;
  font-size: 25px;
  font-weight: 700;
}

._0ZIwra_is-size-h6 {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 600;
}

._0ZIwra_is-size-h7 {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

._0ZIwra_is-size-h8 {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

._0ZIwra_label {
  font-size: 18px;
}

@media screen and (width <= 1023px) {
  ._0ZIwra_label {
    font-size: 16px;
  }
}

._5D-QhW_options-container {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}

._5D-QhW_options-container::-webkit-scrollbar {
  display: none;
}

@media screen and (width <= 1023px) {
  ._5D-QhW_options {
    gap: 5px;
  }
}

@media screen and (width >= 1024px) {
  ._5D-QhW_options {
    gap: 7.5px;
  }
}

._5D-QhW_bar {
  background: linear-gradient(326deg, #e9897d 0%, #a594f9 99.95%);
  height: 2px;
}

._5D-QhW_scale ._5D-QhW_label {
  margin: 0 !important;
}

._5D-QhW_scale ._5D-QhW_label-start {
  color: #a594f9;
}

._5D-QhW_scale ._5D-QhW_label-end {
  color: #e9897d;
}

.dashboard .dashboard-join-our-community {
  background: linear-gradient(120deg, #f5bdac -5.87%, #f5d5b8 110%);
  border-radius: .5rem;
}

.dashboard .dashboard-upcoming-events {
  border-radius: .5rem;
}

.article-card .card-large .level {
  margin: 0;
}

@media screen and (width >= 769px), print {
  .article-card .card-large .card-content {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .article-card .card-large .card-image figure {
    padding-top: 75%;
  }
}

.article-card .card-small {
  flex-direction: row;
  display: flex;
}

@media screen and (width <= 768px) {
  .article-card .card-small .card-image {
    flex: none;
    width: 6rem;
    height: 6rem;
  }

  .article-card .card-small .card-image figure {
    padding-top: 100%;
  }
}

@media screen and (width >= 769px), print {
  .article-card .card-small .card-image figure {
    width: 125px;
    min-height: 100%;
  }
}

@media screen and (width >= 1408px) {
  .article-card .card-small .card-image figure {
    width: 200px;
  }
}

.article-card .level.content-label .level-item {
  justify-content: start;
  text-decoration: underline;
}

.article-card .level.content-label .level-item.content-label-icon {
  flex-grow: 0;
}

.-EykYG_badge svg {
  vertical-align: middle;
}

@media screen and (width >= 769px), print {
  .latest-articles .article-list .card-wrapper {
    min-height: 7rem;
    display: flex;
  }

  .latest-articles .article-list .card-wrapper .card {
    width: 100%;
  }
}

._1MvwLW_main {
  min-width: 300px;
}

@media screen and (width <= 1023px) {
  .background {
    background-image: unset;
  }
}

.LbKGgW_stack {
  flex-direction: row;
  display: flex;
}

@media screen and (width <= 768px) {
  .LbKGgW_stack.LbKGgW_below-size-mobile {
    flex-direction: column !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-0 {
    gap: 0 !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-1 {
    gap: 5px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-2 {
    gap: 10px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-3 {
    gap: 15px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-4 {
    gap: 20px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-5 {
    gap: 25px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-6 {
    gap: 30px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-7 {
    gap: 40px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-8 {
    gap: 50px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack-auto {
    gap: auto !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--1 {
    gap: -5px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--2 {
    gap: -10px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--3 {
    gap: -15px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--4 {
    gap: -20px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--5 {
    gap: -25px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--6 {
    gap: -30px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--7 {
    gap: -40px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-mobile.LbKGgW_gap-on-stack--8 {
    gap: -50px !important;
  }
}

@media screen and (width <= 1023px) {
  .LbKGgW_stack.LbKGgW_below-size-tablet {
    flex-direction: column !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-0 {
    gap: 0 !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-1 {
    gap: 5px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-2 {
    gap: 10px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-3 {
    gap: 15px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-4 {
    gap: 20px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-5 {
    gap: 25px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-6 {
    gap: 30px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-7 {
    gap: 40px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-8 {
    gap: 50px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack-auto {
    gap: auto !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--1 {
    gap: -5px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--2 {
    gap: -10px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--3 {
    gap: -15px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--4 {
    gap: -20px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--5 {
    gap: -25px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--6 {
    gap: -30px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--7 {
    gap: -40px !important;
  }

  .LbKGgW_stack.LbKGgW_below-size-tablet.LbKGgW_gap-on-stack--8 {
    gap: -50px !important;
  }
}

.LbKGgW_stack.LbKGgW_gap-0 {
  gap: 0;
}

.LbKGgW_stack.LbKGgW_gap-1 {
  gap: 5px;
}

.LbKGgW_stack.LbKGgW_gap-2 {
  gap: 10px;
}

.LbKGgW_stack.LbKGgW_gap-3 {
  gap: 15px;
}

.LbKGgW_stack.LbKGgW_gap-4 {
  gap: 20px;
}

.LbKGgW_stack.LbKGgW_gap-5 {
  gap: 25px;
}

.LbKGgW_stack.LbKGgW_gap-6 {
  gap: 30px;
}

.LbKGgW_stack.LbKGgW_gap-7 {
  gap: 40px;
}

.LbKGgW_stack.LbKGgW_gap-8 {
  gap: 50px;
}

.LbKGgW_stack.LbKGgW_gap-auto {
  gap: auto;
}

.LbKGgW_stack.LbKGgW_gap--1 {
  gap: -5px;
}

.LbKGgW_stack.LbKGgW_gap--2 {
  gap: -10px;
}

.LbKGgW_stack.LbKGgW_gap--3 {
  gap: -15px;
}

.LbKGgW_stack.LbKGgW_gap--4 {
  gap: -20px;
}

.LbKGgW_stack.LbKGgW_gap--5 {
  gap: -25px;
}

.LbKGgW_stack.LbKGgW_gap--6 {
  gap: -30px;
}

.LbKGgW_stack.LbKGgW_gap--7 {
  gap: -40px;
}

.LbKGgW_stack.LbKGgW_gap--8 {
  gap: -50px;
}

._7rw3ta_link, ._7rw3ta_link :visited, ._7rw3ta_link :hover {
  color: #000;
}

.goal-planning .goal-planning-content {
  max-width: 52.5rem;
}

.goal-planning .goal-planning-padding {
  padding: 3.125rem 2.5rem 2.813rem;
}

.goal-planning .goal-planning-padding-x {
  padding: 0 2.5rem;
}

@media screen and (width <= 768px) {
  .goal-planning .goal-planning-padding-x {
    padding: 0 1rem;
  }
}

.goal-planning .goal-planning-padding-top {
  padding-top: 3.125rem;
}

@media screen and (width <= 768px) {
  .goal-planning .goal-planning-padding-top {
    padding-top: 0;
  }
}

.goal-planning .goal-planning-padding-bottom {
  padding-bottom: 2.813rem;
}

.cGCXfG_info {
  background-color: #e4deff;
}

.cGCXfG_standard {
  background: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
}

@media screen and (width >= 1024px) {
  .glide .glide__track__wrapper {
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
  }

  .glide .glide__track__wrapper .glide__track, .glide .glide__track__wrapper .glide__slides {
    overflow: visible;
  }
}

.glide .glide__track__wrapper .glide__slide {
  height: auto;
}

.carousel-controls.controls-with-title {
  margin-top: -50px;
}

@media screen and (width >= 1024px) {
  .carousel-controls.controls-with-title {
    margin-top: -40px;
  }
}

.carousel-controls button:focus:not(:active) {
  box-shadow: none;
}

.carousel-controls button img {
  width: 30px;
}

@media screen and (width >= 1024px) {
  .carousel-controls button img {
    width: 40px;
  }
}

.carousel-with-parts-overlay .tile article {
  max-width: 100%;
}

.carousel-with-parts-overlay .card-image-overlay {
  z-index: 1;
  color: #fff;
  background-color: #0a0a0abf;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 8rem;
  font-size: 1.25rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.carousel-with-parts-overlay .card-image-overlay.purple-blue-300 {
  background-color: #a594f9bf;
}

.carousel-with-parts-overlay .card-image-overlay.purple-blue-500 {
  background-color: #1b0777bf;
}

.card-wrapper {
  position: relative;
  text-decoration: none !important;
}

.card-wrapper .card {
  box-shadow: none;
  background-color: #0000;
}

.card-wrapper .card .card-header {
  box-shadow: none;
}

.card-wrapper .card .card-image img {
  object-fit: cover;
  border-radius: 10px;
}

.card-wrapper .card .is-overlay {
  z-index: 10;
}

.card-wrapper .card .match-height .card-header-title {
  align-items: baseline;
}

.card .card-header {
  box-shadow: none;
}

@media screen and (width >= 1024px) {
  a.card-wrapper .card:hover {
    background-color: #fff;
    box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
  }
}

.card-image-overlay {
  z-index: 1;
  color: #fff;
  background-color: #393939bf;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 8rem;
  font-size: 1.25rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.card-image-overlay.purple-blue-300 {
  background-color: #a594f9bf;
}

.card-image-overlay.purple-blue-500 {
  background-color: #1b0777bf;
}

.card-image-overlay.purple-blue-400 {
  background-color: #2727c4bf;
}

.title_97532d {
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (width <= 1023px) {
  .title_97532d {
    flex-wrap: wrap;
  }
}

.title_97532d:after {
  content: "";
  background-color: #e2e2e2;
  flex: 1;
  margin: auto 10px;
  padding: 1px;
}

.separator-header_97532d {
  order: 1;
}

@media screen and (width <= 1023px) {
  .separator-header_97532d {
    order: -1;
    width: 100%;
  }
}

.separator-header_97532d svg {
  vertical-align: middle;
}

.section_97532d .carousel-wrapper.with-controls {
  margin-top: 60px;
}

.section_97532d .carousel-wrapper.with-controls .carousel-overlay {
  top: -3rem;
}

.VoovBa_tools-tile {
  width: 18.75rem;
  min-height: 9.375rem;
}

@media screen and (width <= 1023px) {
  .VoovBa_tools-tile {
    width: 100%;
  }
}

.VoovBa_peach-gradient {
  background: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
}

.VoovBa_icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  max-height: 30px;
}

.VoovBa_icon svg {
  width: 100%;
}

.secondary-nav a {
  letter-spacing: .2em;
  color: #1b0777;
  font-family: Montserrat;
}

.secondary-nav .header {
  height: 52px;
}

.secondary-sub-nav {
  margin-bottom: .5rem;
}

.secondary-sub-nav .button {
  margin-left: .5rem;
  margin-right: .5rem;
  padding: 1.25rem;
}

.secondary-sub-nav .button.is-light.is-active {
  color: #fff;
  background-color: #1b0777;
}

.secondary-sub-nav .header {
  border-top: 1px solid #cdcdcd;
  height: 60px;
}

.secondary-sub-nav .navbar {
  padding-top: .5rem;
}

.secondary-nav-spacer {
  width: 100%;
  height: 3rem;
  min-height: 3rem;
  position: relative;
}

.header-container {
  z-index: 30;
  width: 100%;
  min-height: 3.25rem;
  position: fixed;
}

@media screen and (width >= 1024px) {
  .header-container {
    width: calc(100% - 14.5rem);
  }
}

.header-container .header {
  z-index: 30;
  background: linear-gradient(90deg, #fff 0%, #fff6e9 100%);
  width: 100%;
  overflow: auto hidden;
}

.header-container .header .navbar {
  background-color: #0000;
}

.header-container .header .navbar .navbar-brand {
  width: 100%;
  max-width: 1024px;
}

@media screen and (width >= 1024px) {
  .header-container .header .navbar .navbar-brand {
    width: 50%;
  }
}

.header-container .header .navbar .navbar-brand .navbar-item.is-active {
  padding-bottom: .5rem;
  position: relative;
}

.header-container .header .navbar .navbar-brand .navbar-item.is-active:after {
  content: "";
  background-color: #a594f9;
  border-radius: 5px 5px 0 0;
  width: calc(100% - 3rem);
  min-width: 3rem;
  height: .5rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header-container .header .navbar .navbar-brand .navbar-item:not(.is-active):hover {
  color: #1b0777;
  background-color: #f3f0ff;
}

@media screen and (width <= 1023px) {
  .risk {
    background-color: #fff8f1;
    min-height: calc(100% - 47px);
    overflow: auto;
  }

  .risk .button {
    width: 95%;
    display: inline-block;
  }
}

@media screen and (width >= 1024px) {
  .risk .process .columns {
    max-width: 48.125rem;
  }
}

.risk .result .meter-container {
  justify-content: center;
}

@media screen and (width <= 1023px) {
  .risk .result.is-primary .meter-responsive {
    display: block;
    width: 100% !important;
  }
}

.risk .result.is-secondary .meter-container {
  transform: scale(.7);
}

@media screen and (width >= 769px) {
  .risk .result.is-secondary .meter-container {
    transform: scale(.9);
  }
}

@media screen and (width >= 1024px) {
  .risk .result.is-secondary .meter-container {
    transform: scale(.6);
  }
}

@media screen and (width >= 1216px) {
  .risk .result.is-secondary .meter-container {
    transform: scale(.75);
  }
}

.risk .results {
  max-width: 61.875rem;
}

.meter {
  width: 200px;
  height: 200px;
}

.meter--50 {
  width: 50px;
  height: 50px;
}

.meter--60 {
  width: 60px;
  height: 60px;
}

.meter--70 {
  width: 70px;
  height: 70px;
}

.meter--80 {
  width: 80px;
  height: 80px;
}

.meter--90 {
  width: 90px;
  height: 90px;
}

.meter--100 {
  width: 100px;
  height: 100px;
}

.meter--110 {
  width: 110px;
  height: 110px;
}

.meter--120 {
  width: 120px;
  height: 120px;
}

.meter--130 {
  width: 130px;
  height: 130px;
}

.meter--140 {
  width: 140px;
  height: 140px;
}

.meter--150 {
  width: 150px;
  height: 150px;
}

.meter--160 {
  width: 160px;
  height: 160px;
}

.meter--170 {
  width: 170px;
  height: 170px;
}

.meter--180 {
  width: 180px;
  height: 180px;
}

.meter--190 {
  width: 190px;
  height: 190px;
}

.meter--200 {
  width: 200px;
  height: 200px;
}

.chat > .mobile {
  background-color: #fff;
}

.chat > .mobile > .columns, .chat > .desktop > .columns {
  margin: 0;
}

.chat > .desktop > .columns > .column:nth-child(2) {
  background-color: #fff8f1;
}

.chat .chat-dialog .chat-message-line:first-child {
  margin-top: 0;
}

.chat .chat-dialog .chat-message-line .chat-message {
  border-radius: 1.5rem;
  max-width: 80%;
  padding: .7rem 1.5rem;
}

.chat .chat-dialog .chat-message-line .chat-message.customer {
  color: #000;
  background-color: #f5bdac;
  margin: 1rem 0;
}

.chat .chat-dialog .chat-message-line .chat-message.bot {
  color: #fff;
  background-color: #1b0777;
  margin: 1rem 0;
}

.chat .chat-dialog .chat-message-line .chat-message.bot.loading {
  color: #c9c9c9;
}

.chat .chat-dialog .chat-message-line .chat-message.answer {
  color: #000;
  background-color: #c4bfdf;
  border-radius: 1em;
}

.chat .chat-dialog .chat-message-line .chat-message:not(.loading) {
  min-height: 1.5rem;
}

.chat .chat-dialog .chat-message-line .chat-message.loading {
  color: #c9c9c9;
  min-width: 5rem;
  max-height: 1.5em;
  padding: 0 .5rem;
  font-size: 2em;
}

.chat .chat-dialog .chat-message-line .chat-message.loading span {
  font-size: 1.2em;
  animation-name: bounce;
  animation-duration: .6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.chat .chat-dialog .chat-message-line .chat-message.loading span:first-child {
  animation-delay: 0s;
}

.chat .chat-dialog .chat-message-line .chat-message.loading span:nth-child(2) {
  animation-delay: 40ms;
}

.chat .chat-dialog .chat-message-line .chat-message.loading span:nth-child(3) {
  animation-delay: 80ms;
}

.multiple-choice-radio-option .expander {
  margin-top: .3rem;
  margin-left: -.5rem;
}

.multiple-choice-radio-option .expander.is-expanded img {
  transition: transform .2s;
  transform: rotate(180deg);
}

.multiple-choice-radio-option .expander:not(.is-expanded) img {
  transition: transform .2s;
  transform: rotate(0);
}

.multiple-choice-radio-option input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  color: #888;
  background-color: #c4bfdf;
  border: .15em solid #888;
  border-radius: 50%;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  margin-right: .5rem;
  display: grid;
  transform: translateY(-.075em);
}

.multiple-choice-radio-option input[type="radio"]:before {
  content: "";
  border-radius: 50%;
  width: .65em;
  height: .65em;
  transition: transform .12s ease-in-out;
  transform: scale(0);
  box-shadow: inset 1em 1em #1b0777;
}

.multiple-choice-radio-option input[type="radio"]:checked:before {
  transform: scale(1);
}

.chat-input .input {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

.chat-input .input:focus, .chat-input .input:hover, .chat-input .input:active {
  box-shadow: none;
  border: none;
}

.chat-input .answer {
  max-width: 40rem;
}

.chat-input input[type="number"] {
  -moz-appearance: textfield;
}

.chat-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.chat-input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.chat-input .placeholder-text {
  white-space: nowrap;
}

.select-input {
  gap: 1rem;
  max-width: 80%;
}

.select-input input[type="checkbox"] {
  display: none;
}

.select-input :hover {
  cursor: pointer;
}

.start-over a {
  color: #000;
}

.start-over img {
  object-fit: contain;
}

@media screen and (width <= 1023px) {
  .risk-tolerance {
    background-color: #fff8f1;
  }
}

.risk-tolerance .start-over-nav {
  z-index: 30;
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 108px;
  left: 0;
}

.risk-tolerance .start-over-nav .is-overlay {
  height: 3rem;
}

.risk-tolerance .start-over-nav .is-overlay.has-background {
  z-index: -1;
}

@media screen and (width <= 1023px) {
  .risk-tolerance .start-over-nav .is-overlay.has-background {
    background: #fff;
  }

  .risk-tolerance .start-over-nav {
    justify-content: end;
    top: 57px;
  }

  .risk-capacity {
    background-color: #fff8f1;
  }
}

.risk-capacity .start-over-nav {
  z-index: 30;
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 108px;
  left: 0;
}

.risk-capacity .start-over-nav .is-overlay {
  height: 3rem;
}

.risk-capacity .start-over-nav .is-overlay.has-background {
  z-index: -1;
}

@media screen and (width <= 1023px) {
  .risk-capacity .start-over-nav .is-overlay.has-background {
    background: #fff;
  }

  .risk-capacity .start-over-nav {
    justify-content: end;
    top: 57px;
  }
}

.chat-layout .background {
  left: 0;
}

@media screen and (width <= 1023px) {
  .chat-layout .background {
    display: none;
  }

  .chat-layout .propelle-navbar.navbar {
    background: #fff;
    border-bottom: 2px solid #cdcdcd;
  }
}

@media screen and (width >= 1024px) {
  .chat-layout .chat-main {
    width: 100%;
    min-height: calc(100vh - 108px);
    left: 0;
  }
}

@media screen and (width <= 1023px) {
  .chat-layout .chat-main {
    min-height: calc(100vh - 57px);
  }
}

.button.button-back {
  background-color: #0000;
}

.article .author img {
  object-fit: cover;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
}

@media screen and (width <= 768px) {
  .article .article-info {
    flex-wrap: wrap;
  }

  .article .article-info .audio {
    width: 100%;
  }
}

.article .rm-audio-player-provider {
  --rm-audio-player-interface-container: transparent;
  --spectrum-alias-background-color-default: transparent;
  --rm-audio-player-track-current-time: #e9897d;
  --rm-audio-player-progress-bar: #e9897d;
  border-radius: .25rem;
  width: 22rem !important;
}

@media screen and (width <= 768px) {
  .article .rm-audio-player-provider {
    --rm-audio-player-interface-container: #ffffff80;
    --spectrum-alias-background-color-default: #ffffff80;
  }
}

.article .rm-audio-player-provider .track-current-time, .article .rm-audio-player-provider .track-time-duration-container {
  letter-spacing: normal;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

.article .rm-audio-player-provider .btn-wrapper button {
  color: #1b0777;
}

.article .rm-audio-player-provider .interface-grid {
  border-radius: .25rem;
}

.article .rm-audio-player-provider .play-button + .prev-n-next-button {
  display: none;
}

.spectrum_b37d53 {
  background-color: var(--spectrum-alias-background-color-default, var(--spectrum-global-color-gray-100));
  -webkit-tap-highlight-color: #0000;
}

.spectrum_2a241c {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(zh-Hans), .spectrum_2a241c:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c {
  font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100));
  color: var(--spectrum-global-color-gray-800);
}

.spectrum_2a241c, .spectrum_2a241c.spectrum-Body_2a241c, .spectrum-Body_2a241c {
  font-size: var(--spectrum-body-4-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-body-4-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  line-height: var(--spectrum-body-4-text-line-height, var(--spectrum-alias-body-text-line-height));
  font-style: var(--spectrum-body-4-text-font-style, var(--spectrum-global-font-style-regular));
}

.spectrum-Body--italic_2a241c {
  font-style: var(--spectrum-body-4-emphasis-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum--darkest_256eeb {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-celery-400: #3da74e;
  --spectrum-global-color-celery-500: #44b556;
  --spectrum-global-color-celery-600: #4bc35f;
  --spectrum-global-color-celery-700: #51d267;
  --spectrum-global-color-chartreuse-400: #7cc33f;
  --spectrum-global-color-chartreuse-500: #85d044;
  --spectrum-global-color-chartreuse-600: #8ede49;
  --spectrum-global-color-chartreuse-700: #9bec54;
  --spectrum-global-color-yellow-400: #d2b200;
  --spectrum-global-color-yellow-500: #dfbf00;
  --spectrum-global-color-yellow-600: #edcc00;
  --spectrum-global-color-yellow-700: #fad900;
  --spectrum-global-color-magenta-400: #ca2996;
  --spectrum-global-color-magenta-500: #d83790;
  --spectrum-global-color-magenta-600: #e2499d;
  --spectrum-global-color-magenta-700: #ec5aaa;
  --spectrum-global-color-fuchsia-400: #b130bd;
  --spectrum-global-color-fuchsia-500: #c038cc;
  --spectrum-global-color-fuchsia-600: #cf3edc;
  --spectrum-global-color-fuchsia-700: #d951e5;
  --spectrum-global-color-purple-400: #864ccc;
  --spectrum-global-color-purple-500: #9256d9;
  --spectrum-global-color-purple-600: #9d64e1;
  --spectrum-global-color-purple-700: #a873df;
  --spectrum-global-color-indigo-400: #5c5ce0;
  --spectrum-global-color-indigo-500: #6767ec;
  --spectrum-global-color-indigo-600: #7575f1;
  --spectrum-global-color-indigo-700: #8282f6;
  --spectrum-global-color-seafoam-400: #16878c;
  --spectrum-global-color-seafoam-500: #1b959a;
  --spectrum-global-color-seafoam-600: #20a3a8;
  --spectrum-global-color-seafoam-700: #23b2b8;
  --spectrum-global-color-red-400: #d7373f;
  --spectrum-global-color-red-500: #e34850;
  --spectrum-global-color-red-600: #ec5b62;
  --spectrum-global-color-red-700: #f76d74;
  --spectrum-global-color-orange-400: #da7b11;
  --spectrum-global-color-orange-500: #e68619;
  --spectrum-global-color-orange-600: #f29423;
  --spectrum-global-color-orange-700: #f9a43f;
  --spectrum-global-color-green-400: #268e6c;
  --spectrum-global-color-green-500: #2d9d78;
  --spectrum-global-color-green-600: #33ab84;
  --spectrum-global-color-green-700: #39b990;
  --spectrum-global-color-blue-400: #1473e6;
  --spectrum-global-color-blue-500: #2680eb;
  --spectrum-global-color-blue-600: #378ef0;
  --spectrum-global-color-blue-700: #4b9cf5;
  --spectrum-global-color-gray-50: #080808;
  --spectrum-global-color-gray-75: #1a1a1a;
  --spectrum-global-color-gray-100: #1e1e1e;
  --spectrum-global-color-gray-200: #2c2c2c;
  --spectrum-global-color-gray-300: #393939;
  --spectrum-global-color-gray-400: #494949;
  --spectrum-global-color-gray-500: #5c5c5c;
  --spectrum-global-color-gray-600: #7c7c7c;
  --spectrum-global-color-gray-700: #a2a2a2;
  --spectrum-global-color-gray-800: #c8c8c8;
  --spectrum-global-color-gray-900: #efefef;
  --spectrum-alias-background-color-modal-overlay: #0009;
  --spectrum-alias-dropshadow-color: #000c;
  --spectrum-alias-background-color-hover-overlay: #efefef14;
  --spectrum-alias-highlight-hover: #efefef14;
  --spectrum-alias-highlight-active: #efefef26;
  --spectrum-alias-highlight-selected: #2680eb33;
  --spectrum-alias-highlight-selected-hover: #2680eb4d;
  --spectrum-alias-text-highlight-color: #2680eb4d;
  --spectrum-alias-background-color-quickactions: #1e1e1ee6;
  --spectrum-alias-radial-reaction-color-default: #c8c8c899;
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-50);
  --spectrum-colorarea-border-color: #efefef1a;
  --spectrum-colorarea-border-color-hover: #efefef1a;
  --spectrum-colorarea-border-color-down: #efefef1a;
  --spectrum-colorarea-border-color-key-focus: #efefef1a;
  --spectrum-colorslider-border-color: #efefef1a;
  --spectrum-colorslider-border-color-hover: #efefef1a;
  --spectrum-colorslider-border-color-down: #efefef1a;
  --spectrum-colorslider-border-color-key-focus: #efefef1a;
  --spectrum-colorslider-vertical-border-color: #efefef1a;
  --spectrum-colorslider-vertical-border-color-hover: #efefef1a;
  --spectrum-colorslider-vertical-border-color-down: #efefef1a;
  --spectrum-colorslider-vertical-border-color-key-focus: #efefef1a;
  --spectrum-colorwheel-border-color: #efefef1a;
  --spectrum-colorwheel-border-color-hover: #efefef1a;
  --spectrum-colorwheel-border-color-down: #efefef1a;
  --spectrum-colorwheel-border-color-key-focus: #efefef1a;
  --spectrum-miller-column-item-background-color-selected: #2680eb1a;
  --spectrum-miller-column-item-background-color-selected-hover: #2680eb33;
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: #c8c8c805;
  --spectrum-well-border-color: #efefef0d;
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-100);
  --react-spectrum-datepicker-placeholder-color: var(--spectrum-global-color-gray-600);
}

.spectrum_9e130c {
  --spectrum-global-animation-duration-0: 0s;
  --spectrum-global-animation-duration-100: .13s;
  --spectrum-global-animation-duration-200: .16s;
  --spectrum-global-animation-duration-300: .19s;
  --spectrum-global-animation-duration-400: .22s;
  --spectrum-global-animation-duration-500: .25s;
  --spectrum-global-animation-duration-600: .3s;
  --spectrum-global-animation-duration-700: .35s;
  --spectrum-global-animation-duration-800: .4s;
  --spectrum-global-animation-duration-900: .45s;
  --spectrum-global-animation-duration-1000: .5s;
  --spectrum-global-animation-duration-2000: 1s;
  --spectrum-global-animation-duration-4000: 2s;
  --spectrum-global-animation-ease-in-out: cubic-bezier(.45, 0, .4, 1);
  --spectrum-global-animation-ease-in: cubic-bezier(.5, 0, 1, 1);
  --spectrum-global-animation-ease-out: cubic-bezier(0, 0, .4, 1);
  --spectrum-global-animation-linear: cubic-bezier(0, 0, 1, 1);
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-static-black: #000;
  --spectrum-global-color-static-white: #fff;
  --spectrum-global-color-static-blue: #1473e6;
  --spectrum-global-color-static-gray-50: #fff;
  --spectrum-global-color-static-gray-75: #fff;
  --spectrum-global-color-static-gray-100: #fff;
  --spectrum-global-color-static-gray-200: #f4f4f4;
  --spectrum-global-color-static-gray-300: #eaeaea;
  --spectrum-global-color-static-gray-400: #d3d3d3;
  --spectrum-global-color-static-gray-500: #bcbcbc;
  --spectrum-global-color-static-gray-600: #959595;
  --spectrum-global-color-static-gray-700: #747474;
  --spectrum-global-color-static-gray-800: #505050;
  --spectrum-global-color-static-gray-900: #323232;
  --spectrum-global-color-static-blue-200: #5aa9fa;
  --spectrum-global-color-static-blue-300: #4b9cf5;
  --spectrum-global-color-static-blue-400: #378ef0;
  --spectrum-global-color-static-blue-500: #2680eb;
  --spectrum-global-color-static-blue-600: #1473e6;
  --spectrum-global-color-static-blue-700: #0d66d0;
  --spectrum-global-color-static-blue-800: #095aba;
  --spectrum-global-color-static-red-400: #ec5b62;
  --spectrum-global-color-static-red-500: #e34850;
  --spectrum-global-color-static-red-600: #d7373f;
  --spectrum-global-color-static-red-700: #c9252d;
  --spectrum-global-color-static-orange-400: #f29423;
  --spectrum-global-color-static-orange-500: #e68619;
  --spectrum-global-color-static-orange-600: #da7b11;
  --spectrum-global-color-static-orange-700: #cb6f10;
  --spectrum-global-color-static-green-400: #33ab84;
  --spectrum-global-color-static-green-500: #2d9d78;
  --spectrum-global-color-static-green-600: #268e6c;
  --spectrum-global-color-static-green-700: #12805c;
  --spectrum-global-color-static-celery-200: #58e06f;
  --spectrum-global-color-static-celery-300: #51d267;
  --spectrum-global-color-static-celery-400: #4bc35f;
  --spectrum-global-color-static-celery-500: #44b556;
  --spectrum-global-color-static-celery-600: #3da74e;
  --spectrum-global-color-static-celery-700: #379947;
  --spectrum-global-color-static-chartreuse-300: #9bec54;
  --spectrum-global-color-static-chartreuse-400: #8ede49;
  --spectrum-global-color-static-chartreuse-500: #85d044;
  --spectrum-global-color-static-chartreuse-600: #7cc33f;
  --spectrum-global-color-static-chartreuse-700: #73b53a;
  --spectrum-global-color-static-yellow-200: #ffe22e;
  --spectrum-global-color-static-yellow-300: #fad900;
  --spectrum-global-color-static-yellow-400: #edcc00;
  --spectrum-global-color-static-yellow-500: #dfbf00;
  --spectrum-global-color-static-yellow-600: #d2b200;
  --spectrum-global-color-static-yellow-700: #c4a600;
  --spectrum-global-color-static-magenta-200: #f56bb7;
  --spectrum-global-color-static-magenta-300: #ec5aaa;
  --spectrum-global-color-static-magenta-400: #e2499d;
  --spectrum-global-color-static-magenta-500: #d83790;
  --spectrum-global-color-static-magenta-600: #ca2982;
  --spectrum-global-color-static-magenta-700: #bc1c74;
  --spectrum-global-color-static-fuchsia-400: #cf3edc;
  --spectrum-global-color-static-fuchsia-500: #c038cc;
  --spectrum-global-color-static-fuchsia-600: #b130bd;
  --spectrum-global-color-static-fuchsia-700: #a228ad;
  --spectrum-global-color-static-purple-400: #9d64e1;
  --spectrum-global-color-static-purple-500: #9256d9;
  --spectrum-global-color-static-purple-600: #864ccc;
  --spectrum-global-color-static-purple-700: #7a42bf;
  --spectrum-global-color-static-purple-800: #6f38b1;
  --spectrum-global-color-static-indigo-200: #9090fa;
  --spectrum-global-color-static-indigo-300: #8282f6;
  --spectrum-global-color-static-indigo-400: #7575f1;
  --spectrum-global-color-static-indigo-500: #6767ec;
  --spectrum-global-color-static-indigo-600: #5c5ce0;
  --spectrum-global-color-static-indigo-700: #5151d3;
  --spectrum-global-color-static-seafoam-200: #26c0c7;
  --spectrum-global-color-static-seafoam-300: #23b2b8;
  --spectrum-global-color-static-seafoam-400: #20a3a8;
  --spectrum-global-color-static-seafoam-500: #1b959a;
  --spectrum-global-color-static-seafoam-600: #16878c;
  --spectrum-global-color-static-seafoam-700: #0f797d;
  --spectrum-global-color-opacity-100: 1;
  --spectrum-global-color-opacity-90: .9;
  --spectrum-global-color-opacity-80: .8;
  --spectrum-global-color-opacity-60: .6;
  --spectrum-global-color-opacity-50: .5;
  --spectrum-global-color-opacity-42: .42;
  --spectrum-global-color-opacity-40: .4;
  --spectrum-global-color-opacity-30: .3;
  --spectrum-global-color-opacity-25: .25;
  --spectrum-global-color-opacity-20: .2;
  --spectrum-global-color-opacity-15: .15;
  --spectrum-global-color-opacity-10: .1;
  --spectrum-global-color-opacity-8: .08;
  --spectrum-global-color-opacity-7: .07;
  --spectrum-global-color-opacity-6: .06;
  --spectrum-global-color-opacity-5: .05;
  --spectrum-global-color-opacity-4: .04;
  --spectrum-semantic-negative-color-background: var(--spectrum-global-color-static-red-700, #c9252d);
  --spectrum-semantic-negative-color-default: var(--spectrum-global-color-red-500);
  --spectrum-semantic-negative-color-state-hover: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-dark: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-border: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-color-icon: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-status: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-color-text-large: var(--spectrum-global-color-red-500);
  --spectrum-semantic-negative-color-text-small: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-state-down: var(--spectrum-global-color-red-700);
  --spectrum-semantic-negative-color-state-focus: var(--spectrum-global-color-red-400);
  --spectrum-semantic-notice-color-background: var(--spectrum-global-color-static-orange-700, #cb6f10);
  --spectrum-semantic-notice-color-default: var(--spectrum-global-color-orange-500);
  --spectrum-semantic-notice-color-dark: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-border: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-color-icon: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-status: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-color-text-large: var(--spectrum-global-color-orange-500);
  --spectrum-semantic-notice-color-text-small: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-state-down: var(--spectrum-global-color-orange-700);
  --spectrum-semantic-notice-color-state-focus: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-positive-color-background: var(--spectrum-global-color-static-green-700, #12805c);
  --spectrum-semantic-positive-color-default: var(--spectrum-global-color-green-500);
  --spectrum-semantic-positive-color-dark: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-border: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-color-icon: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-status: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-color-text-large: var(--spectrum-global-color-green-500);
  --spectrum-semantic-positive-color-text-small: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-state-down: var(--spectrum-global-color-green-700);
  --spectrum-semantic-positive-color-state-focus: var(--spectrum-global-color-green-400);
  --spectrum-semantic-informative-color-background: var(--spectrum-global-color-static-blue-700, #0d66d0);
  --spectrum-semantic-informative-color-default: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-informative-color-dark: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-border: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-color-icon: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-status: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-color-text-large: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-informative-color-text-small: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-state-down: var(--spectrum-global-color-blue-700);
  --spectrum-semantic-informative-color-state-focus: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-cta-color-background-default: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-cta-color-background-hover: var(--spectrum-global-color-static-blue-700, #0d66d0);
  --spectrum-semantic-cta-color-background-down: var(--spectrum-global-color-static-blue-800, #095aba);
  --spectrum-semantic-cta-color-background-key-focus: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-background-color-key-focus: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-neutral-color-background: var(--spectrum-global-color-static-gray-700, #747474);
  --spectrum-semantic-presence-color-1: var(--spectrum-global-color-static-red-500, #e34850);
  --spectrum-semantic-presence-color-2: var(--spectrum-global-color-static-orange-400, #f29423);
  --spectrum-semantic-presence-color-3: var(--spectrum-global-color-static-yellow-400, #edcc00);
  --spectrum-semantic-presence-color-4: #4bcca2;
  --spectrum-semantic-presence-color-5: #00c7ff;
  --spectrum-semantic-presence-color-6: #008cb8;
  --spectrum-semantic-presence-color-7: #7e4bf3;
  --spectrum-semantic-presence-color-8: var(--spectrum-global-color-static-fuchsia-600, #b130bd);
  --spectrum-global-dimension-static-size-0: 0px;
  --spectrum-global-dimension-static-size-10: 1px;
  --spectrum-global-dimension-static-size-25: 2px;
  --spectrum-global-dimension-static-size-50: 4px;
  --spectrum-global-dimension-static-size-40: 3px;
  --spectrum-global-dimension-static-size-65: 5px;
  --spectrum-global-dimension-static-size-100: 8px;
  --spectrum-global-dimension-static-size-115: 9px;
  --spectrum-global-dimension-static-size-125: 10px;
  --spectrum-global-dimension-static-size-130: 11px;
  --spectrum-global-dimension-static-size-150: 12px;
  --spectrum-global-dimension-static-size-160: 13px;
  --spectrum-global-dimension-static-size-175: 14px;
  --spectrum-global-dimension-static-size-200: 16px;
  --spectrum-global-dimension-static-size-225: 18px;
  --spectrum-global-dimension-static-size-250: 20px;
  --spectrum-global-dimension-static-size-300: 24px;
  --spectrum-global-dimension-static-size-400: 32px;
  --spectrum-global-dimension-static-size-450: 36px;
  --spectrum-global-dimension-static-size-500: 40px;
  --spectrum-global-dimension-static-size-550: 44px;
  --spectrum-global-dimension-static-size-600: 48px;
  --spectrum-global-dimension-static-size-700: 56px;
  --spectrum-global-dimension-static-size-800: 64px;
  --spectrum-global-dimension-static-size-900: 72px;
  --spectrum-global-dimension-static-size-1000: 80px;
  --spectrum-global-dimension-static-size-1200: 96px;
  --spectrum-global-dimension-static-size-1700: 136px;
  --spectrum-global-dimension-static-size-2400: 192px;
  --spectrum-global-dimension-static-size-2600: 208px;
  --spectrum-global-dimension-static-size-3400: 272px;
  --spectrum-global-dimension-static-size-3600: 288px;
  --spectrum-global-dimension-static-size-4600: 368px;
  --spectrum-global-dimension-static-size-5000: 400px;
  --spectrum-global-dimension-static-size-6000: 480px;
  --spectrum-global-dimension-static-font-size-50: 11px;
  --spectrum-global-dimension-static-font-size-75: 12px;
  --spectrum-global-dimension-static-font-size-100: 14px;
  --spectrum-global-dimension-static-font-size-150: 15px;
  --spectrum-global-dimension-static-font-size-200: 16px;
  --spectrum-global-dimension-static-font-size-300: 18px;
  --spectrum-global-dimension-static-font-size-400: 20px;
  --spectrum-global-dimension-static-font-size-500: 22px;
  --spectrum-global-dimension-static-font-size-600: 25px;
  --spectrum-global-dimension-static-font-size-700: 28px;
  --spectrum-global-dimension-static-font-size-800: 32px;
  --spectrum-global-dimension-static-font-size-900: 36px;
  --spectrum-global-dimension-static-font-size-1000: 40px;
  --spectrum-global-dimension-static-percent-50: 50%;
  --spectrum-global-dimension-static-percent-100: 100%;
  --spectrum-global-dimension-static-breakpoint-xsmall: 304px;
  --spectrum-global-dimension-static-breakpoint-small: 768px;
  --spectrum-global-dimension-static-breakpoint-medium: 1280px;
  --spectrum-global-dimension-static-breakpoint-large: 1768px;
  --spectrum-global-dimension-static-breakpoint-xlarge: 2160px;
  --spectrum-global-dimension-static-grid-columns: 12;
  --spectrum-global-dimension-static-grid-fluid-width: 100%;
  --spectrum-global-dimension-static-grid-fixed-max-width: 1280px;
  --spectrum-global-font-family-base: adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-global-font-family-serif: adobe-clean-serif, "Source Serif Pro", Georgia, serif;
  --spectrum-global-font-family-code: "Source Code Pro", Monaco, monospace;
  --spectrum-global-font-weight-thin: 100;
  --spectrum-global-font-weight-ultra-light: 200;
  --spectrum-global-font-weight-light: 300;
  --spectrum-global-font-weight-regular: 400;
  --spectrum-global-font-weight-medium: 500;
  --spectrum-global-font-weight-semi-bold: 600;
  --spectrum-global-font-weight-bold: 700;
  --spectrum-global-font-weight-extra-bold: 800;
  --spectrum-global-font-weight-black: 900;
  --spectrum-global-font-style-regular: normal;
  --spectrum-global-font-style-italic: italic;
  --spectrum-global-font-letter-spacing-none: 0;
  --spectrum-global-font-letter-spacing-small: .0125em;
  --spectrum-global-font-letter-spacing-han: .05em;
  --spectrum-global-font-letter-spacing-medium: .06em;
  --spectrum-global-font-line-height-large: 1.7;
  --spectrum-global-font-line-height-medium: 1.5;
  --spectrum-global-font-line-height-small: 1.3;
  --spectrum-global-font-multiplier-25: .25em;
  --spectrum-global-font-multiplier-75: .75em;
  --spectrum-alias-border-size-thin: var(--spectrum-global-dimension-static-size-10, 1px);
  --spectrum-alias-border-size-thick: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-border-size-thicker: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-alias-border-size-thickest: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-border-offset-thin: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-border-offset-thick: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-alias-border-offset-thicker: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-border-offset-thickest: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-baseline: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-grid-gutter-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-gutter-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-gutter-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-gutter-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-gutter-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-grid-margin-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-margin-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-margin-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-margin-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-margin-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-grid-layout-region-margin-bottom-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-layout-region-margin-bottom-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-layout-region-margin-bottom-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-layout-region-margin-bottom-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-layout-region-margin-bottom-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-radial-reaction-size-default: var(--spectrum-global-dimension-static-size-550, 44px);
  --spectrum-alias-font-family-ar: myriad-arabic, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-font-family-he: myriad-hebrew, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-font-family-zh: adobe-clean-han-traditional, source-han-traditional, "MingLiu", "Heiti TC Light", "sans-serif";
  --spectrum-alias-font-family-zhhans: adobe-clean-han-simplified-c, source-han-simplified-c, "SimSun", "Heiti SC Light", "sans-serif";
  --spectrum-alias-font-family-ko: adobe-clean-han-korean, source-han-korean, "Malgun Gothic", "Apple Gothic", "sans-serif";
  --spectrum-alias-font-family-ja: adobe-clean-han-japanese, source-han-japanese, "Yu Gothic", "メイリオ", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", "Osaka", "ＭＳＰゴシック", "MS PGothic", "sans-serif";
  --spectrum-alias-font-family-condensed: adobe-clean-han-traditional, source-han-traditional, "MingLiu", "Heiti TC Light", adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-line-height-body: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-line-height-title: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-body-han-text-line-height: var(--spectrum-global-font-line-height-large, 1.7);
  --spectrum-alias-body-text-font-family: var(--spectrum-global-font-family-base, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif);
  --spectrum-alias-body-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-body-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-body-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-button-text-line-height: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-heading-han-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-heading-text-line-height: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-subheading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-detail-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-detail-text-font-weight-light: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-serif-text-font-family: var(--spectrum-global-font-family-serif, adobe-clean-serif, "Source Serif Pro", Georgia, serif);
  --spectrum-alias-article-text-font-family: var(--spectrum-global-font-family-serif, adobe-clean-serif, "Source Serif Pro", Georgia, serif);
  --spectrum-alias-article-body-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-body-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-heading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-subheading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-detail-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-detail-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-code-text-font-family: var(--spectrum-global-font-family-code, "Source Code Pro", Monaco, monospace);
  --spectrum-alias-han-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-regular-emphasis: var(--spectrum-global-font-weight-extra-bold, 800);
  --spectrum-alias-han-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-han-heading-text-font-weight-quiet-emphasis: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-light: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-han-heading-text-font-weight-light-emphasis: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-heading-text-font-weight-light-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-strong-emphasis: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy-emphasis: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-body-text-font-weight-regular: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-body-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-body-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-subheading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-subheading-text-font-weight-emphasis: var(--spectrum-global-font-weight-extra-bold, 800);
  --spectrum-alias-han-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-detail-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-detail-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-code-text-font-weight-regular: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-code-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-code-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-heading-margin-bottom: var(--spectrum-global-font-multiplier-25, .25em);
  --spectrum-alias-body-margin-bottom: var(--spectrum-global-font-multiplier-75, .75em);
  --spectrum-alias-focus-ring-gap: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-focus-ring-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-loupe-entry-animation-duration: var(--spectrum-global-animation-duration-300, .19s);
  --spectrum-alias-loupe-exit-animation-duration: var(--spectrum-global-animation-duration-300, .19s);
}

.spectrum--medium_9e130c, .spectrum--large_9e130c {
  --spectrum-alias-dropshadow-blur: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-dropshadow-offset-y: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-font-size-default: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-line-height-small: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-line-height-medium: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-line-height-large: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-line-height-xlarge: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-line-height-xxlarge: var(--spectrum-global-dimension-size-600);
  --spectrum-alias-layout-label-gap-size: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-pill-button-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-pill-button-text-baseline: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-alias-border-radius-xsmall: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-border-radius-small: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-border-radius-regular: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-border-radius-medium: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-border-radius-large: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-single-line-height: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-single-line-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-alias-workflow-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-heading-display1-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-display1-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-display1-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-han-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-display1-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-display2-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-xxl-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-display2-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xxl-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-display2-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-xxl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-display2-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xxl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading1-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-xl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-han-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xl-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-han-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-han-xl-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading2-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-l-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading2-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-l-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading2-han-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-han-l-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading2-han-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-han-l-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading3-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-m-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading3-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-m-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading3-han-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-han-m-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading3-han-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-han-m-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading4-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-s-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading4-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-s-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading5-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-xs-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading5-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xs-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading6-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xxs-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading6-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-heading-xxs-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-avatar-size-50: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-avatar-size-75: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-avatar-size-200: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-avatar-size-300: var(--spectrum-global-dimension-size-325);
  --spectrum-alias-avatar-size-500: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-avatar-size-700: var(--spectrum-global-dimension-size-500);
}

.spectrum--darkest_9e130c, .spectrum--dark_9e130c, .spectrum--light_9e130c, .spectrum--lightest_9e130c {
  --spectrum-alias-background-color-default: var(--spectrum-global-color-gray-100);
  --spectrum-alias-background-color-transparent: transparent;
  --spectrum-alias-background-color-label-gray: #707070;
  --spectrum-alias-background-color-quickactions-overlay: #0003;
  --spectrum-alias-placeholder-text-color: var(--spectrum-global-color-gray-600);
  --spectrum-alias-placeholder-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-placeholder-text-color-selected: var(--spectrum-global-color-gray-800);
  --spectrum-alias-label-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alias-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-alias-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-mouse-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-alias-text-color-invalid: var(--spectrum-global-color-red-500);
  --spectrum-alias-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-selected-neutral: var(--spectrum-global-color-gray-900);
  --spectrum-alias-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alias-heading-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alias-border-color: var(--spectrum-global-color-gray-400);
  --spectrum-alias-border-color-hover: var(--spectrum-global-color-gray-500);
  --spectrum-alias-border-color-down: var(--spectrum-global-color-gray-500);
  --spectrum-alias-border-color-focus: var(--spectrum-global-color-blue-400);
  --spectrum-alias-border-color-mouse-focus: var(--spectrum-global-color-blue-500);
  --spectrum-alias-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-alias-border-color-extralight: var(--spectrum-global-color-gray-100);
  --spectrum-alias-border-color-light: var(--spectrum-global-color-gray-200);
  --spectrum-alias-border-color-mid: var(--spectrum-global-color-gray-300);
  --spectrum-alias-border-color-dark: var(--spectrum-global-color-gray-400);
  --spectrum-alias-border-color-transparent: transparent;
  --spectrum-alias-border-color-translucent-dark: #0000000d;
  --spectrum-alias-border-color-translucent-darker: #0000001a;
  --spectrum-alias-focus-color: var(--spectrum-global-color-blue-400);
  --spectrum-alias-focus-ring-color: var(--spectrum-alias-focus-color, var(--spectrum-global-color-blue-400));
  --spectrum-alias-track-color-default: var(--spectrum-global-color-gray-300);
  --spectrum-alias-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-alias-track-color-over-background: #fff3;
  --spectrum-alias-icon-color: var(--spectrum-global-color-gray-700);
  --spectrum-alias-icon-color-over-background: var(--spectrum-global-color-static-white, #fff);
  --spectrum-alias-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-focus: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-alias-icon-color-selected-neutral: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-alias-icon-color-selected-hover: var(--spectrum-global-color-blue-600);
  --spectrum-alias-icon-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-alias-icon-color-selected-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-icon-color-error: var(--spectrum-global-color-red-400);
  --spectrum-alias-toolbar-background-color: var(--spectrum-global-color-gray-100);
  --spectrum-alias-colorhandle-outer-border-color: #0000006b;
  --spectrum-alias-categorical-color-1: var(--spectrum-global-color-static-seafoam-200, #26c0c7);
  --spectrum-alias-categorical-color-2: var(--spectrum-global-color-static-indigo-700, #5151d3);
  --spectrum-alias-categorical-color-3: var(--spectrum-global-color-static-orange-500, #e68619);
  --spectrum-alias-categorical-color-4: var(--spectrum-global-color-static-magenta-500, #d83790);
  --spectrum-alias-categorical-color-5: var(--spectrum-global-color-static-indigo-200, #9090fa);
  --spectrum-alias-categorical-color-6: var(--spectrum-global-color-static-celery-200, #58e06f);
  --spectrum-alias-categorical-color-7: var(--spectrum-global-color-static-blue-500, #2680eb);
  --spectrum-alias-categorical-color-8: var(--spectrum-global-color-static-purple-800, #6f38b1);
  --spectrum-alias-categorical-color-9: var(--spectrum-global-color-static-yellow-500, #dfbf00);
  --spectrum-alias-categorical-color-10: var(--spectrum-global-color-static-orange-700, #cb6f10);
  --spectrum-alias-categorical-color-11: var(--spectrum-global-color-static-green-600, #268e6c);
  --spectrum-alias-categorical-color-12: var(--spectrum-global-color-static-chartreuse-300, #9bec54);
  --spectrum-alias-categorical-color-13: var(--spectrum-global-color-static-blue-200, #5aa9fa);
  --spectrum-alias-categorical-color-14: var(--spectrum-global-color-static-fuchsia-500, #c038cc);
  --spectrum-alias-categorical-color-15: var(--spectrum-global-color-static-magenta-200, #f56bb7);
  --spectrum-alias-categorical-color-16: var(--spectrum-global-color-static-yellow-200, #ffe22e);
}

.spectrum--large_c40598 {
  --spectrum-global-dimension-scale-factor: 1.25;
  --spectrum-global-dimension-size-0: 0px;
  --spectrum-global-dimension-size-10: 1px;
  --spectrum-global-dimension-size-25: 2px;
  --spectrum-global-dimension-size-40: 4px;
  --spectrum-global-dimension-size-50: 5px;
  --spectrum-global-dimension-size-65: 6px;
  --spectrum-global-dimension-size-75: 8px;
  --spectrum-global-dimension-size-85: 9px;
  --spectrum-global-dimension-size-100: 10px;
  --spectrum-global-dimension-size-115: 11px;
  --spectrum-global-dimension-size-125: 13px;
  --spectrum-global-dimension-size-130: 14px;
  --spectrum-global-dimension-size-150: 15px;
  --spectrum-global-dimension-size-160: 16px;
  --spectrum-global-dimension-size-175: 18px;
  --spectrum-global-dimension-size-200: 20px;
  --spectrum-global-dimension-size-225: 22px;
  --spectrum-global-dimension-size-250: 25px;
  --spectrum-global-dimension-size-275: 28px;
  --spectrum-global-dimension-size-300: 30px;
  --spectrum-global-dimension-size-325: 32px;
  --spectrum-global-dimension-size-350: 35px;
  --spectrum-global-dimension-size-400: 40px;
  --spectrum-global-dimension-size-450: 45px;
  --spectrum-global-dimension-size-500: 50px;
  --spectrum-global-dimension-size-550: 56px;
  --spectrum-global-dimension-size-600: 60px;
  --spectrum-global-dimension-size-675: 68px;
  --spectrum-global-dimension-size-700: 70px;
  --spectrum-global-dimension-size-800: 80px;
  --spectrum-global-dimension-size-900: 90px;
  --spectrum-global-dimension-size-1000: 100px;
  --spectrum-global-dimension-size-1200: 120px;
  --spectrum-global-dimension-size-1250: 125px;
  --spectrum-global-dimension-size-1600: 160px;
  --spectrum-global-dimension-size-1700: 170px;
  --spectrum-global-dimension-size-2000: 200px;
  --spectrum-global-dimension-size-2400: 240px;
  --spectrum-global-dimension-size-3000: 300px;
  --spectrum-global-dimension-size-3400: 340px;
  --spectrum-global-dimension-size-3600: 360px;
  --spectrum-global-dimension-size-4600: 460px;
  --spectrum-global-dimension-size-5000: 500px;
  --spectrum-global-dimension-size-6000: 600px;
  --spectrum-global-dimension-font-size-25: 12px;
  --spectrum-global-dimension-font-size-50: 13px;
  --spectrum-global-dimension-font-size-75: 15px;
  --spectrum-global-dimension-font-size-100: 17px;
  --spectrum-global-dimension-font-size-150: 18px;
  --spectrum-global-dimension-font-size-200: 19px;
  --spectrum-global-dimension-font-size-300: 22px;
  --spectrum-global-dimension-font-size-400: 24px;
  --spectrum-global-dimension-font-size-500: 27px;
  --spectrum-global-dimension-font-size-600: 31px;
  --spectrum-global-dimension-font-size-700: 34px;
  --spectrum-global-dimension-font-size-800: 39px;
  --spectrum-global-dimension-font-size-900: 44px;
  --spectrum-global-dimension-font-size-1000: 49px;
  --spectrum-global-dimension-font-size-1100: 55px;
  --spectrum-global-dimension-font-size-1200: 62px;
  --spectrum-global-dimension-font-size-1300: 70px;
  --spectrum-alias-avatar-size-100: 26px;
  --spectrum-alias-avatar-size-400: 36px;
  --spectrum-alias-avatar-size-600: 46px;
  --spectrum-actionbutton-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-barloader-large-border-radius: 4px;
  --spectrum-barloader-large-indeterminate-border-radius: 4px;
  --spectrum-barloader-large-over-background-border-radius: 4px;
  --spectrum-barloader-small-border-radius: 3px;
  --spectrum-barloader-small-indeterminate-border-radius: 3px;
  --spectrum-barloader-small-over-background-border-radius: 3px;
  --spectrum-breadcrumb-compact-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-compact-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-multiline-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-multiline-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-cta-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-cta-min-width: 90px;
  --spectrum-button-cta-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-cta-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-over-background-min-width: 90px;
  --spectrum-button-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-primary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-primary-min-width: 90px;
  --spectrum-button-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-over-background-min-width: 90px;
  --spectrum-button-quiet-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-over-background-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-primary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-primary-min-width: 90px;
  --spectrum-button-quiet-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-primary-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-secondary-min-width: 90px;
  --spectrum-button-quiet-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-secondary-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-warning-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-warning-min-width: 90px;
  --spectrum-button-quiet-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-warning-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-secondary-min-width: 90px;
  --spectrum-button-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-warning-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-warning-min-width: 90px;
  --spectrum-button-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-small-border-size: 3px;
  --spectrum-circleloader-small-over-background-border-size: 3px;
  --spectrum-colorhandle-loupe-margin: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorloupe-colorhandle-gap: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorslider-touch-hit-y: var(--spectrum-global-dimension-size-85);
  --spectrum-colorslider-vertical-touch-hit-x: var(--spectrum-global-dimension-size-85);
  --spectrum-colorwheel-min-size: var(--spectrum-global-dimension-static-size-2600, 208px);
  --spectrum-colorwheel-touch-hit-outer: var(--spectrum-global-dimension-size-85);
  --spectrum-colorwheel-touch-hit-inner: var(--spectrum-global-dimension-size-85);
  --spectrum-cyclebutton-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-cyclebutton-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dialog-confirm-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-confirm-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-confirm-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-confirm-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-confirm-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-content-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-content-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-destructive-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-destructive-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-destructive-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-destructive-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-destructive-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-error-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-error-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-error-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-error-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-error-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-info-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-info-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-info-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-info-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-info-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dropdown-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-fieldbutton-quiet-min-width: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-arrow-down-small-height: 12px;
  --spectrum-icon-arrow-left-medium-height: 12px;
  --spectrum-icon-checkmark-medium-width: 16px;
  --spectrum-icon-checkmark-medium-height: 16px;
  --spectrum-icon-checkmark-small-width: 12px;
  --spectrum-icon-checkmark-small-height: 12px;
  --spectrum-icon-chevron-down-medium-width: 12px;
  --spectrum-icon-chevron-left-large-width: 16px;
  --spectrum-icon-chevron-left-medium-height: 12px;
  --spectrum-icon-chevron-right-large-width: 16px;
  --spectrum-icon-chevron-right-medium-height: 12px;
  --spectrum-icon-cross-large-width: 16px;
  --spectrum-icon-cross-large-height: 16px;
  --spectrum-icon-dash-small-width: 12px;
  --spectrum-icon-dash-small-height: 12px;
  --spectrum-icon-skip-left-width: 10px;
  --spectrum-icon-skip-left-height: 12px;
  --spectrum-icon-skip-right-width: 10px;
  --spectrum-icon-skip-right-height: 12px;
  --spectrum-icon-triplegripper-width: 12px;
  --spectrum-listitem-option-icon-size: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-listitem-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-listitem-option-height: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-listitem-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-listitem-thumbnail-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-listitem-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-thumbnail-small-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-size: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-selectlist-option-height: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-selectlist-option-padding-height: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-selectlist-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-loader-bar-large-border-radius: 4px;
  --spectrum-loader-bar-large-over-background-border-radius: 4px;
  --spectrum-loader-bar-small-border-radius: 3px;
  --spectrum-loader-bar-small-over-background-border-radius: 3px;
  --spectrum-loader-circle-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-loader-circle-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-loader-circle-small-border-size: 3px;
  --spectrum-loader-circle-small-over-background-border-size: 3px;
  --spectrum-meter-large-border-radius: 4px;
  --spectrum-meter-small-border-radius: 3px;
  --spectrum-pagination-page-button-line-height: 32px;
  --spectrum-overlay-positive-transform-distance: 8px;
  --spectrum-overlay-negative-transform-distance: -8px;
  --spectrum-picker-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-quiet-min-width: var(--spectrum-global-dimension-size-200);
  --spectrum-picker-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progressbar-large-border-radius: 4px;
  --spectrum-progressbar-large-indeterminate-border-radius: 4px;
  --spectrum-progressbar-large-over-background-border-radius: 4px;
  --spectrum-progressbar-small-border-radius: 3px;
  --spectrum-progressbar-small-indeterminate-border-radius: 3px;
  --spectrum-progressbar-small-over-background-border-radius: 3px;
  --spectrum-progresscircle-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progresscircle-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progresscircle-small-border-size: 3px;
  --spectrum-progresscircle-small-indeterminate-border-size: 3px;
  --spectrum-progresscircle-small-over-background-border-size: 3px;
  --spectrum-progresscircle-medium-indeterminate-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-icon-width: 30px;
  --spectrum-rating-indicator-width: 20px;
  --spectrum-rating-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-emphasized-icon-width: 30px;
  --spectrum-rating-emphasized-indicator-width: 20px;
  --spectrum-rating-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-quiet-icon-width: 30px;
  --spectrum-rating-quiet-indicator-width: 20px;
  --spectrum-rating-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-search-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-sidenav-item-touch-hit-bottom: 3px;
  --spectrum-sidenav-multilevel-item-touch-hit-bottom: 3px;
  --spectrum-slider-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-editable-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-editable-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-editable-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-fill-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-fill-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-fill-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-switch-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-handle-border-radius: 9px;
  --spectrum-switch-handle-transform-x-ltr: 18px;
  --spectrum-switch-handle-transform-x-rtl: -18px;
  --spectrum-switch-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-switch-emphasized-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-emphasized-handle-border-radius: 9px;
  --spectrum-switch-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-switch-quiet-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-quiet-handle-border-radius: 9px;
  --spectrum-switch-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tabs-compact-focus-ring-border-radius: 6px;
  --spectrum-tabs-compact-margin-left: -11px;
  --spectrum-tabs-compact-margin-right: -11px;
  --spectrum-tabs-compact-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-focus-ring-border-radius: 6px;
  --spectrum-tabs-margin-left: -11px;
  --spectrum-tabs-margin-right: -11px;
  --spectrum-tabs-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-emphasized-margin-left: -11px;
  --spectrum-tabs-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-margin-left: -11px;
  --spectrum-tabs-quiet-margin-right: -11px;
  --spectrum-tabs-quiet-compact-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-margin-left: -11px;
  --spectrum-tabs-quiet-compact-margin-right: -11px;
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-emphasized-margin-left: -11px;
  --spectrum-tabs-quiet-compact-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-compact-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-emphasized-margin-left: -11px;
  --spectrum-tabs-quiet-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-vertical-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-vertical-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-vertical-focus-ring-border-radius: 6px;
  --spectrum-textarea-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textarea-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textfield-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textfield-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-tool-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-high-emphasis-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-high-emphasis-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tooltip-padding-bottom: 6px;
  --spectrum-tooltip-content-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-padding-bottom: 6px;
  --spectrum-tooltip-info-content-max-width: 126px;
  --spectrum-tooltip-negative-padding-bottom: 6px;
  --spectrum-tooltip-negative-content-max-width: 126px;
  --spectrum-tooltip-positive-padding-bottom: 6px;
  --spectrum-tooltip-positive-content-max-width: 126px;
}

.spectrum--light_a40724 {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-celery-400: #44b556;
  --spectrum-global-color-celery-500: #3da74e;
  --spectrum-global-color-celery-600: #379947;
  --spectrum-global-color-celery-700: #318b40;
  --spectrum-global-color-chartreuse-400: #85d044;
  --spectrum-global-color-chartreuse-500: #7cc33f;
  --spectrum-global-color-chartreuse-600: #73b53a;
  --spectrum-global-color-chartreuse-700: #6aa834;
  --spectrum-global-color-yellow-400: #dfbf00;
  --spectrum-global-color-yellow-500: #d2b200;
  --spectrum-global-color-yellow-600: #c4a600;
  --spectrum-global-color-yellow-700: #b79900;
  --spectrum-global-color-magenta-400: #d83790;
  --spectrum-global-color-magenta-500: #ce2783;
  --spectrum-global-color-magenta-600: #bc1c74;
  --spectrum-global-color-magenta-700: #ae0e66;
  --spectrum-global-color-fuchsia-400: #c038cc;
  --spectrum-global-color-fuchsia-500: #b130bd;
  --spectrum-global-color-fuchsia-600: #a228ad;
  --spectrum-global-color-fuchsia-700: #93219e;
  --spectrum-global-color-purple-400: #9256d9;
  --spectrum-global-color-purple-500: #864ccc;
  --spectrum-global-color-purple-600: #7a42bf;
  --spectrum-global-color-purple-700: #6f38b1;
  --spectrum-global-color-indigo-400: #6767ec;
  --spectrum-global-color-indigo-500: #5c5ce0;
  --spectrum-global-color-indigo-600: #5151d3;
  --spectrum-global-color-indigo-700: #4646c6;
  --spectrum-global-color-seafoam-400: #1b959a;
  --spectrum-global-color-seafoam-500: #16878c;
  --spectrum-global-color-seafoam-600: #0f797d;
  --spectrum-global-color-seafoam-700: #096c6f;
  --spectrum-global-color-red-400: #e34850;
  --spectrum-global-color-red-500: #d7373f;
  --spectrum-global-color-red-600: #c9252d;
  --spectrum-global-color-red-700: #bb121a;
  --spectrum-global-color-orange-400: #e68619;
  --spectrum-global-color-orange-500: #da7b11;
  --spectrum-global-color-orange-600: #cb6f10;
  --spectrum-global-color-orange-700: #bd640d;
  --spectrum-global-color-green-400: #2d9d78;
  --spectrum-global-color-green-500: #268e6c;
  --spectrum-global-color-green-600: #12805c;
  --spectrum-global-color-green-700: #107154;
  --spectrum-global-color-blue-400: #2680eb;
  --spectrum-global-color-blue-500: #1473e6;
  --spectrum-global-color-blue-600: #0d66d0;
  --spectrum-global-color-blue-700: #095aba;
  --spectrum-global-color-gray-50: #fff;
  --spectrum-global-color-gray-75: #fafafa;
  --spectrum-global-color-gray-100: #f5f5f5;
  --spectrum-global-color-gray-200: #eaeaea;
  --spectrum-global-color-gray-300: #e1e1e1;
  --spectrum-global-color-gray-400: #cacaca;
  --spectrum-global-color-gray-500: #b3b3b3;
  --spectrum-global-color-gray-600: #8e8e8e;
  --spectrum-global-color-gray-700: #6e6e6e;
  --spectrum-global-color-gray-800: #4b4b4b;
  --spectrum-global-color-gray-900: #2c2c2c;
  --spectrum-alias-background-color-modal-overlay: #0006;
  --spectrum-alias-dropshadow-color: #00000026;
  --spectrum-alias-background-color-hover-overlay: #2c2c2c0a;
  --spectrum-alias-highlight-hover: #2c2c2c0f;
  --spectrum-alias-highlight-active: #2c2c2c1a;
  --spectrum-alias-highlight-selected: #1473e61a;
  --spectrum-alias-highlight-selected-hover: #1473e633;
  --spectrum-alias-text-highlight-color: #1473e633;
  --spectrum-alias-background-color-quickactions: #f5f5f5e6;
  --spectrum-alias-radial-reaction-color-default: #4b4b4b99;
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-300);
  --spectrum-colorarea-border-color: #2c2c2c1a;
  --spectrum-colorarea-border-color-hover: #2c2c2c1a;
  --spectrum-colorarea-border-color-down: #2c2c2c1a;
  --spectrum-colorarea-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorslider-border-color: #2c2c2c1a;
  --spectrum-colorslider-border-color-hover: #2c2c2c1a;
  --spectrum-colorslider-border-color-down: #2c2c2c1a;
  --spectrum-colorslider-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-hover: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-down: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorwheel-border-color: #2c2c2c1a;
  --spectrum-colorwheel-border-color-hover: #2c2c2c1a;
  --spectrum-colorwheel-border-color-down: #2c2c2c1a;
  --spectrum-colorwheel-border-color-key-focus: #2c2c2c1a;
  --spectrum-miller-column-item-background-color-selected: #1473e61a;
  --spectrum-miller-column-item-background-color-selected-hover: #1473e633;
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: #4b4b4b05;
  --spectrum-well-border-color: #2c2c2c0d;
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-50);
  --react-spectrum-datepicker-placeholder-color: #767676;
}

.spectrum--medium_4b172c {
  --spectrum-global-dimension-scale-factor: 1;
  --spectrum-global-dimension-size-0: 0px;
  --spectrum-global-dimension-size-10: 1px;
  --spectrum-global-dimension-size-25: 2px;
  --spectrum-global-dimension-size-40: 3px;
  --spectrum-global-dimension-size-50: 4px;
  --spectrum-global-dimension-size-65: 5px;
  --spectrum-global-dimension-size-75: 6px;
  --spectrum-global-dimension-size-85: 7px;
  --spectrum-global-dimension-size-100: 8px;
  --spectrum-global-dimension-size-115: 9px;
  --spectrum-global-dimension-size-125: 10px;
  --spectrum-global-dimension-size-130: 11px;
  --spectrum-global-dimension-size-150: 12px;
  --spectrum-global-dimension-size-160: 13px;
  --spectrum-global-dimension-size-175: 14px;
  --spectrum-global-dimension-size-200: 16px;
  --spectrum-global-dimension-size-225: 18px;
  --spectrum-global-dimension-size-250: 20px;
  --spectrum-global-dimension-size-275: 22px;
  --spectrum-global-dimension-size-300: 24px;
  --spectrum-global-dimension-size-325: 26px;
  --spectrum-global-dimension-size-350: 28px;
  --spectrum-global-dimension-size-400: 32px;
  --spectrum-global-dimension-size-450: 36px;
  --spectrum-global-dimension-size-500: 40px;
  --spectrum-global-dimension-size-550: 44px;
  --spectrum-global-dimension-size-600: 48px;
  --spectrum-global-dimension-size-675: 54px;
  --spectrum-global-dimension-size-700: 56px;
  --spectrum-global-dimension-size-800: 64px;
  --spectrum-global-dimension-size-900: 72px;
  --spectrum-global-dimension-size-1000: 80px;
  --spectrum-global-dimension-size-1200: 96px;
  --spectrum-global-dimension-size-1250: 100px;
  --spectrum-global-dimension-size-1600: 128px;
  --spectrum-global-dimension-size-1700: 136px;
  --spectrum-global-dimension-size-2000: 160px;
  --spectrum-global-dimension-size-2400: 192px;
  --spectrum-global-dimension-size-3000: 240px;
  --spectrum-global-dimension-size-3400: 272px;
  --spectrum-global-dimension-size-3600: 288px;
  --spectrum-global-dimension-size-4600: 368px;
  --spectrum-global-dimension-size-5000: 400px;
  --spectrum-global-dimension-size-6000: 480px;
  --spectrum-global-dimension-font-size-25: 10px;
  --spectrum-global-dimension-font-size-50: 11px;
  --spectrum-global-dimension-font-size-75: 12px;
  --spectrum-global-dimension-font-size-100: 14px;
  --spectrum-global-dimension-font-size-150: 15px;
  --spectrum-global-dimension-font-size-200: 16px;
  --spectrum-global-dimension-font-size-300: 18px;
  --spectrum-global-dimension-font-size-400: 20px;
  --spectrum-global-dimension-font-size-500: 22px;
  --spectrum-global-dimension-font-size-600: 25px;
  --spectrum-global-dimension-font-size-700: 28px;
  --spectrum-global-dimension-font-size-800: 32px;
  --spectrum-global-dimension-font-size-900: 36px;
  --spectrum-global-dimension-font-size-1000: 40px;
  --spectrum-global-dimension-font-size-1100: 45px;
  --spectrum-global-dimension-font-size-1200: 50px;
  --spectrum-global-dimension-font-size-1300: 60px;
  --spectrum-alias-avatar-size-100: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-avatar-size-400: var(--spectrum-global-dimension-size-350);
  --spectrum-alias-avatar-size-600: var(--spectrum-global-dimension-size-450);
  --spectrum-actionbutton-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-barloader-large-border-radius: 3px;
  --spectrum-barloader-large-indeterminate-border-radius: 3px;
  --spectrum-barloader-large-over-background-border-radius: 3px;
  --spectrum-barloader-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-barloader-small-indeterminate-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-barloader-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-breadcrumb-compact-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-compact-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-multiline-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-multiline-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-cta-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-cta-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-cta-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-cta-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-over-background-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-primary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-primary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-over-background-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-over-background-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-primary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-primary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-primary-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-secondary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-secondary-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-warning-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-warning-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-warning-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-secondary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-warning-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-warning-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-circleloader-medium-border-size: 3px;
  --spectrum-circleloader-medium-over-background-border-size: 3px;
  --spectrum-circleloader-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-circleloader-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-colorhandle-loupe-margin: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-colorloupe-colorhandle-gap: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-colorslider-touch-hit-y: var(--spectrum-global-dimension-size-150);
  --spectrum-colorslider-vertical-touch-hit-x: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-min-size: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorwheel-touch-hit-outer: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-touch-hit-inner: var(--spectrum-global-dimension-size-150);
  --spectrum-cyclebutton-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-cyclebutton-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dialog-confirm-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-confirm-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-confirm-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-confirm-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-confirm-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-content-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-content-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-destructive-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-destructive-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-destructive-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-destructive-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-destructive-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-error-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-error-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-error-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-error-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-error-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-info-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-info-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-info-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-info-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-info-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dropdown-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-dropdown-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-fieldbutton-quiet-min-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-arrow-down-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-arrow-left-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-checkmark-medium-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-checkmark-medium-height: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-checkmark-small-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-checkmark-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-down-medium-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-left-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-chevron-left-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-right-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-chevron-right-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-cross-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-cross-large-height: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-dash-small-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-dash-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-skip-left-width: 9px;
  --spectrum-icon-skip-left-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-skip-right-width: 9px;
  --spectrum-icon-skip-right-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-triplegripper-width: var(--spectrum-global-dimension-size-125);
  --spectrum-listitem-option-icon-size: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-listitem-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-listitem-option-height: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-listitem-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-listitem-thumbnail-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-listitem-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-thumbnail-small-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-option-icon-size: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-selectlist-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-selectlist-option-height: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-selectlist-option-padding-height: 7px;
  --spectrum-selectlist-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-loader-bar-large-border-radius: 3px;
  --spectrum-loader-bar-large-over-background-border-radius: 3px;
  --spectrum-loader-bar-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-bar-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-circle-medium-border-size: 3px;
  --spectrum-loader-circle-medium-over-background-border-size: 3px;
  --spectrum-loader-circle-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-circle-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-meter-large-border-radius: 3px;
  --spectrum-meter-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-pagination-page-button-line-height: 26px;
  --spectrum-overlay-positive-transform-distance: 6px;
  --spectrum-overlay-negative-transform-distance: -6px;
  --spectrum-picker-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-quiet-min-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-picker-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-progressbar-large-border-radius: 3px;
  --spectrum-progressbar-large-indeterminate-border-radius: 3px;
  --spectrum-progressbar-large-over-background-border-radius: 3px;
  --spectrum-progressbar-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progressbar-small-indeterminate-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progressbar-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-medium-border-size: 3px;
  --spectrum-progresscircle-medium-over-background-border-size: 3px;
  --spectrum-progresscircle-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-small-indeterminate-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-medium-indeterminate-border-size: 3px;
  --spectrum-radio-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-margin-bottom: 0px;
  --spectrum-radio-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-margin-bottom: 0px;
  --spectrum-radio-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-margin-bottom: 0px;
  --spectrum-radio-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-icon-width: 24px;
  --spectrum-rating-indicator-width: 16px;
  --spectrum-rating-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-emphasized-icon-width: 24px;
  --spectrum-rating-emphasized-indicator-width: 16px;
  --spectrum-rating-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-quiet-icon-width: 24px;
  --spectrum-rating-quiet-indicator-width: 16px;
  --spectrum-rating-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-search-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-sidenav-item-touch-hit-bottom: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-sidenav-multilevel-item-touch-hit-bottom: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-slider-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-editable-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-editable-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-editable-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-fill-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-fill-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-fill-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-switch-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-track-width: 26px;
  --spectrum-switch-handle-border-radius: 7px;
  --spectrum-switch-handle-transform-x-ltr: 12px;
  --spectrum-switch-handle-transform-x-rtl: -12px;
  --spectrum-switch-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-switch-emphasized-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-track-width: 26px;
  --spectrum-switch-emphasized-handle-border-radius: 7px;
  --spectrum-switch-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-switch-quiet-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-track-width: 26px;
  --spectrum-switch-quiet-handle-border-radius: 7px;
  --spectrum-switch-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tabs-compact-focus-ring-border-radius: 5px;
  --spectrum-tabs-compact-margin-left: -8px;
  --spectrum-tabs-compact-margin-right: -8px;
  --spectrum-tabs-compact-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-focus-ring-border-radius: 5px;
  --spectrum-tabs-margin-left: -8px;
  --spectrum-tabs-margin-right: -8px;
  --spectrum-tabs-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-emphasized-margin-left: -8px;
  --spectrum-tabs-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-margin-left: -8px;
  --spectrum-tabs-quiet-margin-right: -8px;
  --spectrum-tabs-quiet-compact-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-margin-left: -8px;
  --spectrum-tabs-quiet-compact-margin-right: -8px;
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-emphasized-margin-left: -8px;
  --spectrum-tabs-quiet-compact-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-compact-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-emphasized-margin-left: -8px;
  --spectrum-tabs-quiet-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-vertical-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-vertical-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-vertical-focus-ring-border-radius: 5px;
  --spectrum-textarea-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textarea-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textfield-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textfield-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-tool-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-high-emphasis-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-high-emphasis-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tooltip-padding-bottom: 5px;
  --spectrum-tooltip-content-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-padding-bottom: 5px;
  --spectrum-tooltip-info-content-max-width: 101px;
  --spectrum-tooltip-negative-padding-bottom: 5px;
  --spectrum-tooltip-negative-content-max-width: 101px;
  --spectrum-tooltip-positive-padding-bottom: 5px;
  --spectrum-tooltip-positive-content-max-width: 101px;
}

.spectrum--medium_9e130c, .spectrum--large_9e130c {
  --rm-audio-player-interface-container: var(--spectrum-global-color-gray-100);
  --rm-audio-player-volume-background: #ccc;
  --rm-audio-player-volume-panel-background: #f2f2f2;
  --rm-audio-player-volume-panel-border: #ccc;
  --rm-audio-player-volume-thumb: #d3d3d3;
  --rm-audio-player-volume-fill: #00000080;
  --rm-audio-player-volume-track: #ababab;
  --rm-audio-player-track-current-time: #0072f5;
  --rm-audio-player-track-duration: #8c8c8c;
  --rm-audio-player-progress-bar: #0072f5;
  --rm-audio-player-progress-bar-background: #d1d1d1;
  --rm-audio-player-waveform-cursor: var(--spectrum-global-color-gray-800);
  --rm-audio-player-waveform-background: var(--rm-audio-player-progress-bar-background);
  --rm-audio-player-waveform-bar: var(--rm-audio-player-progress-bar);
  --rm-audio-player-sortable-list: var(--spectrum-global-color-gray-200);
  --rm-audio-player-sortable-list-button-active: #0072f5;
  --rm-audio-player-selected-list-item-background: var(--spectrum-global-color-gray-500);
}

.flex-container_e15493, .flex_e15493 {
  display: flex;
}

.flex-gap_e15493 {
  --gap: 0px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
  width: calc(100% + var(--column-gap)  + 1px);
  height: calc(100% + var(--row-gap));
}

.flex-container_e15493 .flex-gap_e15493 > * {
  margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
}

.topic-page .unit-progress-container {
  gap: 10px;
}

.topic-page .author img {
  object-fit: cover;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
}

.topic-page .topic-complete-container .topic-complete-content .topic-complete-title, .topic-page .topic-complete-container .topic-complete-content .topic-complete-title--xl {
  text-align: center;
  font-size: 25px;
  line-height: normal;
}

.topic-page .topic-complete-container .topic-complete-content .topic-complete-title--xl {
  font-size: 60px;
}

@media screen and (width <= 1023px) {
  .topic-page .topic-breadcrumb {
    margin-left: -1rem !important;
  }
}

.topic-page .btn-locked {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.topic-page .is-mid-width {
  flex-wrap: wrap !important;
}

@media screen and (width >= 1024px) {
  .topic-page .is-mid-width {
    max-width: 800px;
  }

  .topic-page .is-mid-width-btn-container {
    min-width: 345px;
    max-width: 345px;
  }

  .topic-page .is-small-width-btn-container {
    min-width: 245px;
    max-width: 245px;
  }
}

.topic-page .quiz-questions-container .quiz-question-index {
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.topic-page .quiz-questions-container .is-align-center {
  text-align: center;
}

.topic-page .quiz-questions-container .topic-btn, .topic-page .quiz-questions-container .quiz-option, .topic-page .quiz-questions-container .wrong, .topic-page .quiz-questions-container .correct, .topic-page .quiz-questions-container .selected {
  justify-content: center;
}

.topic-page .quiz-questions-container .quiz-option, .topic-page .quiz-questions-container .wrong, .topic-page .quiz-questions-container .correct, .topic-page .quiz-questions-container .selected {
  color: #393939;
  white-space: normal;
  background-color: #e9e6f7;
  height: fit-content;
  min-height: 2.5em;
}

.topic-page .quiz-questions-container .quiz-option:hover, .topic-page .quiz-questions-container .wrong:hover, .topic-page .quiz-questions-container .correct:hover, .topic-page .quiz-questions-container .selected:hover {
  color: #393939;
  background-color: #cdc2ff;
}

.topic-page .quiz-questions-container .selected {
  background-color: #cdc2ff;
}

.topic-page .quiz-questions-container .correct {
  background-color: #dcffca !important;
}

.topic-page .quiz-questions-container .wrong {
  background-color: #f5c6b7 !important;
}

.topic-page .quiz-questions-container .quiz-answer {
  font-size: 18px;
  font-weight: 400;
}

.topic-page .close-quiz {
  color: #fff !important;
}

@media screen and (width <= 768px) {
  .topic-page .article-info {
    flex-wrap: wrap;
  }

  .topic-page .article-info .audio {
    width: 100%;
  }
}

.topic-page .rm-audio-player-provider {
  --rm-audio-player-interface-container: transparent;
  --spectrum-alias-background-color-default: transparent;
  --rm-audio-player-track-current-time: #e9897d;
  --rm-audio-player-progress-bar: #e9897d;
  border-radius: .25rem;
  width: 22rem !important;
}

@media screen and (width <= 768px) {
  .topic-page .rm-audio-player-provider {
    --rm-audio-player-interface-container: #ffffff80;
    --spectrum-alias-background-color-default: #ffffff80;
  }
}

.topic-page .rm-audio-player-provider .track-current-time, .topic-page .rm-audio-player-provider .track-time-duration-container {
  letter-spacing: normal;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

.topic-page .rm-audio-player-provider .btn-wrapper button {
  color: #1b0777;
}

.topic-page .rm-audio-player-provider .interface-grid {
  border-radius: .25rem;
}

.topic-page .rm-audio-player-provider .play-button + .prev-n-next-button {
  display: none;
}

@media (width <= 767px) {
  .topic-page {
    background: #fff8f1;
    min-height: fit-content;
  }

  .topic-page .glass-desktop {
    min-height: calc(100vh - 11.5rem);
  }

  .topic-page .wordpress-content {
    min-height: max-content;
  }

  .topic-page .is-type-video .wp-block-embed__wrapper, .topic-page .is-type-video .wp-block-embed__wrapper iframe {
    width: 100%;
  }
}

.contact-us {
  color: #000;
}

.contact-us a {
  color: #000;
  text-decoration: underline;
}

.social-icon {
  background-color: #a594f9;
  border-radius: 9999px;
}

.my-account form .label {
  color: #000;
}

.my-account .explainer {
  z-index: 40;
  color: #000;
  max-width: 36rem;
  position: absolute;
  left: 0;
}

.my-account .explainer .notification {
  border: 1px solid #cdcdcd;
}

.faqs.border-top, .faqs .border-top {
  border-top: 1px solid #b0b0b0;
}

.faqs .border-bottom {
  border-bottom: 1px solid #b0b0b0;
}

.results {
  max-width: 61.875rem;
}

@media screen and (width <= 1023px) {
  .results {
    background-color: #fff8f1;
    min-width: 100%;
    min-height: 100%;
  }

  .results h4 {
    text-align: center;
  }

  .results .button {
    width: 100%;
    display: inline-block;
  }
}

.property-opportunity .border-bottom {
  border-bottom: 2px solid #e2e2e2;
}

.property-opportunity .tag {
  z-index: 10;
  border-top-right-radius: 10px;
  height: 35px;
  font-size: 14px;
  position: absolute;
  inset: auto 0 auto auto;
}

.events-carousel .card .card-header {
  box-shadow: none;
}

.events-carousel .card .card-image img {
  border-radius: 10px;
}

.events-carousel .level.virtual-event-label .level-item {
  justify-content: start;
}

.events-carousel .level.virtual-event-label .level-item.virtual-event-label-icon {
  flex-grow: 0;
}

.-doTKG_link {
  white-space: nowrap;
}

.Q_Y3EG_label {
  font-size: 22px;
}

.Q_Y3EG_overlay-container {
  z-index: 100;
}

.Q_Y3EG_overlay {
  stroke-width: 1px;
  stroke: var(--black-200, #e2e2e2);
  filter: drop-shadow(0 4px 20px #0000001a);
}

.Q_Y3EG_peach-gradient-background {
  background: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
}

.r3FlRa_chevron {
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
}

.XePi2G_table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto hidden;
}

.XePi2G_table {
  border-collapse: collapse;
  width: 100%;
  display: table;
}

.XePi2G_table > * {
  display: table-row;
}

.XePi2G_table.XePi2G_with-border-top {
  border-top: 1px solid #e2e2e2;
}

.XePi2G_table .XePi2G_header, .XePi2G_table .XePi2G_with-border-bottom {
  border-bottom: 1px solid #e2e2e2;
}

.XePi2G_table .XePi2G_cell {
  min-width: 80px;
  display: table-cell;
}

.XePi2G_table .XePi2G_cell-contents {
  white-space: nowrap;
}

.nzcqXa_radio {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.nzcqXa_radio input[type="radio"] {
  accent-color: #c9c9f0;
  width: 20px;
  height: 20px;
}

.nzcqXa_radio.nzcqXa_square input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 2px solid #1b0777;
  border-radius: 2px;
  position: relative;
}

.nzcqXa_radio.nzcqXa_square input[type="radio"]:checked {
  background-color: #1b0777;
}

.nzcqXa_radio.nzcqXa_square input[type="radio"]:checked:before {
  content: "✓";
  color: #fff;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 700;
  display: flex;
}

.breadcrumb-display-name {
  text-transform: capitalize;
}

.level-card {
  width: 100%;
  min-height: 150px;
  box-shadow: none !important;
  white-space: normal !important;
  border-radius: 30px !important;
}

.level-card .url-icon, .level-card .url-icon svg {
  height: 100%;
}

.level-card.complete {
  background: #f1ffea;
  border: 1px solid #cbffb1;
}

.level-card.complete .level-circle {
  background: #e3ffd6;
  border: 1px solid #cbffb1;
}

.level-card.complete .url-icon {
  color: #1b0777 !important;
}

.level-card.active {
  color: #1b0777;
  background: linear-gradient(118.93deg, #ffefea -5.87%, #ffebd9 109.87%);
  border: 1px solid #f5bdac;
}

.level-card.active .level-circle {
  background: linear-gradient(118.93deg, #f5bdac 66.92%, #f5d5b8 109.87%);
}

.level-card.active .active, .level-card.active .complete {
  color: #1b0777 !important;
}

.level-card.active .inactive {
  filter: none;
  color: #a2a2a2 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.level-card.inactive, .level-card.coming-soon {
  filter: none;
  background: #f6f6f6;
  border: 1px solid #e0e0e0;
}

.level-card.inactive .url-icon, .level-card.coming-soon .url-icon {
  color: #a2a2a2 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.level-card.inactive .topics-container, .level-card.coming-soon .topics-container {
  color: #a2a2a2;
}

.level-card.inactive .level-circle, .level-card.coming-soon .level-circle {
  background: #ebebeb;
  border: 1px solid #e0e0e0;
}

.level-card .level-circle {
  text-align: center;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 2px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: -15px;
  left: -15px;
  box-shadow: 0 4px 20px #00000026;
}

.level-card .level-num {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 21px;
}

.level-card .total-time {
  font-size: 16px;
  text-align: right !important;
}

.level-card .level-title {
  height: 34px;
  font-size: 18px;
  font-weight: 600;
}

.level-card .topics-to-complete {
  text-align: right;
  height: 34px;
  font-size: 16px;
}

@media screen and (width <= 768px) {
  .level-card .topics-to-complete {
    display: none;
  }
}

.level-card .level-description {
  height: initial;
  font-size: 16px;
}

.level-card .explandable {
  text-align: center;
}

.level-card .explandable a {
  color: #393939;
  text-decoration: underline;
}

@media (width <= 1023px) {
  .level-card {
    padding: 28px 26px;
  }

  .level-card .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }

  .level-card .level-title {
    height: initial;
  }

  .level-card .total-time {
    font-size: 14px;
  }
}

.level-card .topics-container {
  counter-reset: topic;
  margin-bottom: 1.5em;
}

@media (width <= 1023px) {
  .level-card .topics-container {
    margin-top: 1.5em;
  }
}

.level-card .topics-container .topic-row {
  border-top: 1px solid #aaa;
}

.level-card .topics-container .topic-row .topic-title:before {
  counter-increment: topic;
  content: "" counter(topic) ". ";
  margin-right: 3px;
}

.level-card .topics-container .topic-row .title-and-duration {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 769px), print {
  .level-card .topics-container .topic-row .title-and-duration {
    align-items: center;
  }
}

@media screen and (width <= 768px) {
  .level-card .topics-container .topic-row .title-and-duration {
    flex-direction: column;
  }
}

.level-card .topics-container .topic-row .topic-title {
  flex-grow: 10;
  max-width: 75%;
}

@media (width <= 1023px) {
  .level-card .topics-container .topic-row .topic-title {
    max-width: 100%;
    display: flex;
  }
}

.level-card .topics-container .topic-row .topic-duration {
  text-align: left;
  flex-grow: 2;
}

@media screen and (width <= 1023px) {
  .level-card .topics-container .topic-row .topic-duration {
    padding-left: .6rem;
    font-size: 14px;
  }
}

.level-card .topics-container .topic-row .topic-progress-bar {
  flex-grow: 5;
}

@media (width <= 1023px) {
  .level-card .topics-container .topic-row {
    flex-direction: row;
    display: flex;
  }

  .level-card .topics-container .topic-row .title-and-duration {
    flex-grow: 6;
  }
}

.level-card .topics-container .topic-play-icon {
  text-align: right;
  display: flex;
}

.level-card .topics-container .topic-play-icon a {
  color: #1b0777;
  align-self: center;
}

@media (width <= 1023px) {
  .level-card .topics-container .topic-play-icon {
    flex-grow: 1;
    display: flex;
  }

  .level-card .topics-container .topic-play-icon .topic-play-icon {
    border: 1px solid red;
    align-self: center;
    margin: auto;
    padding: 0;
  }
}

.level-card .total-time .lock-icon {
  margin-bottom: -4px;
  margin-left: 3px;
}

.level-card .progress-bar-container {
  flex-direction: row;
  align-items: baseline;
  column-gap: 10px;
  height: 100%;
  display: flex;
}

.level-card .progress-bar-container .percentage-label {
  width: 4em;
  margin: auto;
}

@media (width <= 1023px) {
  .level-card .progress-bar-container {
    height: 100%;
  }

  .level-card .progress-bar-container .percentage-label {
    font-size: 14px;
  }
}

._1NTJWa_has-stripe-logo {
  background-image: url("stripe.45cc92b1.png");
  background-position: center;
  background-size: cover;
  width: 62px;
  height: 37px;
}

.XhOy9q_check {
  width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
}

.vl_FJq_explainer {
  max-width: 250px;
}

.vl_FJq_icon {
  padding: 2px;
}

.J-ag1W_form {
  max-width: 600px;
  margin: 0 auto;
}

.QF2awW_is-red {
  background-color: #fe9aa9;
}

.QF2awW_is-amber {
  background-color: #fff1a9;
}

.QF2awW_is-green {
  background-color: #c1ffa1;
}

._4LchSG_with-border-top {
  border-top: 1px solid #e2e2e2;
}

._4LchSG_with-border-left {
  border-left: 1px solid #e2e2e2;
}

._4LchSG_rag-table {
  overflow-x: auto;
}

._4LchSG_dimension-limits {
  min-width: 550px;
  max-width: 850px;
  margin: 0 auto;
}

._4LchSG_y-axis-label {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.Rq4ztG_has-border-bottom {
  border-bottom: 1px solid #e2e2e2;
}

@media screen and (width >= 1024px) {
  ._3riKSa_summary-width {
    max-width: 400px;
  }
}

.CTgjwq_submit {
  max-width: 400px;
}

.XW472W_copy {
  cursor: pointer;
}

.XW472W_copy svg:hover {
  stroke: #1b077780;
}

.table_14e57d .cell:nth-child(2) {
  justify-content: end;
  display: flex;
}

.wHjviq_active span {
  color: #2727c4;
}

.wHjviq_active > svg {
  stroke: #d85568;
}

.wHjviq_inactive span {
  color: #a8a8a8;
}

.wHjviq_inactive > svg {
  stroke: #a8a8a8;
}

.account-setup-overview ul li.step {
  min-height: 82px;
  padding: 15px;
  line-height: 31px;
}

.account-setup-overview ul li.step.step-in-progress {
  color: #1b0777;
  background: linear-gradient(118.93deg, #ffd5c8e6 -5.87%, #fce5d0e6 109.87%);
  border: 1px solid #e9897d;
  border-radius: 10px;
}

.account-setup-overview ul li.step.step-in-progress .bullet-point {
  color: #fff;
  background-image: url("ellipse.d5c74b2a.svg");
}

.account-setup-overview ul li.step.step-completed {
  color: #616161;
}

.account-setup-overview ul li.step.step-completed .bullet-point {
  color: #fff;
  opacity: .6;
  background-image: url("check.e2be6d93.svg"), url("ellipse.d5c74b2a.svg");
}

.account-setup-overview .bullet-point {
  color: #858585;
  text-align: center;
  background-image: url("ellipse_grey.4dd5dc3c.svg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 31px;
  min-height: 100%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.is-size-h1, .is-size-h2 {
  font-family: Syne;
  font-size: 60px;
  font-weight: 700;
}

.is-size-h3 {
  font-family: Syne;
  font-size: 40px;
  font-weight: 700;
}

.is-size-h4 {
  font-family: Syne;
  font-size: 30px;
  font-weight: 700;
}

.is-size-h5 {
  font-family: Syne;
  font-size: 25px;
  font-weight: 700;
}

.is-size-h6 {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.is-size-h7 {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.is-size-h8 {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .textarea, .input, .button, .wordpress-content .wp-block-button__link {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 2.5em;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 16px;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus, .pagination-ellipsis:focus, .file-cta:focus, .file-name:focus, .select select:focus, .textarea:focus, .input:focus, .button:focus, .wordpress-content .wp-block-button__link:focus, .is-focused.pagination-previous, .is-focused.pagination-next, .is-focused.pagination-link, .is-focused.pagination-ellipsis, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.button, .wordpress-content .is-focused.wp-block-button__link, .pagination-previous:active, .pagination-next:active, .pagination-link:active, .pagination-ellipsis:active, .file-cta:active, .file-name:active, .select select:active, .textarea:active, .input:active, .button:active, .wordpress-content .wp-block-button__link:active, .is-active.pagination-previous, .is-active.pagination-next, .is-active.pagination-link, .is-active.pagination-ellipsis, .is-active.file-cta, .is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.button, .wordpress-content .is-active.wp-block-button__link {
  outline: none;
}

[disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, [disabled].file-cta, [disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button, .wordpress-content [disabled].wp-block-button__link, fieldset[disabled] .pagination-previous, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button, fieldset[disabled] .wordpress-content .wp-block-button__link, .wordpress-content fieldset[disabled] .wp-block-button__link {
  cursor: not-allowed;
}

.is-unselectable, .tabs, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .breadcrumb, .file, .button, .wordpress-content .wp-block-button__link {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  pointer-events: none;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.tabs:not(:last-child), .pagination:not(:last-child), .message:not(:last-child), .level:not(:last-child), .breadcrumb:not(:last-child), .block:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .progress:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal-close, .delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: #0a0a0a33;
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.modal-close:before, .delete:before, .modal-close:after, .delete:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before, .delete:before {
  width: 50%;
  height: 2px;
}

.modal-close:after, .delete:after {
  width: 2px;
  height: 50%;
}

.modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
  background-color: #0a0a0a4d;
}

.modal-close:active, .delete:active {
  background-color: #0a0a0a66;
}

.is-small.modal-close, .is-small.delete {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.modal-close, .is-medium.delete {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.modal-close, .is-large.delete {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.control.is-loading:after, .select.is-loading:after, .loader, .button.is-loading:after, .wordpress-content .is-loading.wp-block-button__link:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.hero-video, .is-overlay, .modal-background, .modal, .image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  position: absolute;
  inset: 0;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 16px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #0c0cbd;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.box {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.5rem;
  display: block;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

a.box:hover, a.box:focus {
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0c0cbd;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0a0a0a33, 0 0 0 1px #0c0cbd;
}

.button, .wordpress-content .wp-block-button__link {
  color: #363636;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 1px;
  border-color: #dbdbdb;
  justify-content: center;
  padding: calc(.5em - 1px) 1em;
}

.button strong, .wordpress-content .wp-block-button__link strong {
  color: inherit;
}

.button .icon, .wordpress-content .wp-block-button__link .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child), .wordpress-content .wp-block-button__link .icon:first-child:not(:last-child) {
  margin-left: calc(-.5em - 1px);
  margin-right: .25em;
}

.button .icon:last-child:not(:first-child), .wordpress-content .wp-block-button__link .icon:last-child:not(:first-child) {
  margin-left: .25em;
  margin-right: calc(-.5em - 1px);
}

.button .icon:first-child:last-child, .wordpress-content .wp-block-button__link .icon:first-child:last-child {
  margin-left: calc(-.5em - 1px);
  margin-right: calc(-.5em - 1px);
}

.button:hover, .wordpress-content .wp-block-button__link:hover, .button.is-hovered, .wordpress-content .is-hovered.wp-block-button__link {
  color: #363636;
  border-color: #fff8f1;
}

.button:focus, .wordpress-content .wp-block-button__link:focus, .button.is-focused, .wordpress-content .is-focused.wp-block-button__link {
  color: #363636;
  border-color: #485fc7;
}

.button:focus:not(:active), .wordpress-content .wp-block-button__link:focus:not(:active), .button.is-focused:not(:active), .wordpress-content .is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.button:active, .wordpress-content .wp-block-button__link:active, .button.is-active, .wordpress-content .is-active.wp-block-button__link {
  color: #363636;
  border-color: #4a4a4a;
}

.button.is-text, .wordpress-content .is-text.wp-block-button__link {
  color: #4a4a4a;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .wordpress-content .is-text.wp-block-button__link:hover, .button.is-text.is-hovered, .wordpress-content .is-text.is-hovered.wp-block-button__link, .button.is-text:focus, .wordpress-content .is-text.wp-block-button__link:focus, .button.is-text.is-focused, .wordpress-content .is-text.is-focused.wp-block-button__link {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-text:active, .wordpress-content .is-text.wp-block-button__link:active, .button.is-text.is-active, .wordpress-content .is-text.is-active.wp-block-button__link {
  color: #363636;
  background-color: #e8e8e8;
}

.button.is-text[disabled], .wordpress-content .is-text[disabled].wp-block-button__link, fieldset[disabled] .button.is-text, fieldset[disabled] .wordpress-content .is-text.wp-block-button__link, .wordpress-content fieldset[disabled] .is-text.wp-block-button__link {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-ghost, .wordpress-content .is-ghost.wp-block-button__link {
  color: #0c0cbd;
  background: none;
  border-color: #0000;
  text-decoration: none;
}

.button.is-ghost:hover, .wordpress-content .is-ghost.wp-block-button__link:hover, .button.is-ghost.is-hovered, .wordpress-content .is-ghost.is-hovered.wp-block-button__link {
  color: #0c0cbd;
  text-decoration: underline;
}

.button.is-white, .wordpress-content .is-white.wp-block-button__link {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white:hover, .wordpress-content .is-white.wp-block-button__link:hover, .button.is-white.is-hovered, .wordpress-content .is-white.is-hovered.wp-block-button__link {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-white:focus, .wordpress-content .is-white.wp-block-button__link:focus, .button.is-white.is-focused, .wordpress-content .is-white.is-focused.wp-block-button__link {
  color: #0a0a0a;
  border-color: #0000;
}

.button.is-white:focus:not(:active), .wordpress-content .is-white.wp-block-button__link:focus:not(:active), .button.is-white.is-focused:not(:active), .wordpress-content .is-white.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-white:active, .wordpress-content .is-white.wp-block-button__link:active, .button.is-white.is-active, .wordpress-content .is-white.is-active.wp-block-button__link {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], .wordpress-content .is-white[disabled].wp-block-button__link, fieldset[disabled] .button.is-white, fieldset[disabled] .wordpress-content .is-white.wp-block-button__link, .wordpress-content fieldset[disabled] .is-white.wp-block-button__link {
  box-shadow: none;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-inverted, .wordpress-content .is-white.is-inverted.wp-block-button__link {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted:hover, .wordpress-content .is-white.is-inverted.wp-block-button__link:hover, .button.is-white.is-inverted.is-hovered, .wordpress-content .is-white.is-inverted.is-hovered.wp-block-button__link {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], .wordpress-content .is-white.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-white.is-inverted, fieldset[disabled] .wordpress-content .is-white.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-white.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-white.is-loading:after, .wordpress-content .is-white.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined, .wordpress-content .is-white.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .wordpress-content .is-white.is-outlined.wp-block-button__link:hover, .button.is-white.is-outlined.is-hovered, .wordpress-content .is-white.is-outlined.is-hovered.wp-block-button__link, .button.is-white.is-outlined:focus, .wordpress-content .is-white.is-outlined.wp-block-button__link:focus, .button.is-white.is-outlined.is-focused, .wordpress-content .is-white.is-outlined.is-focused.wp-block-button__link {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after, .wordpress-content .is-white.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .wordpress-content .is-white.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-white.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-white.is-outlined.is-loading:focus:after, .wordpress-content .is-white.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after, .wordpress-content .is-white.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined[disabled], .wordpress-content .is-white.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-white.is-outlined, fieldset[disabled] .wordpress-content .is-white.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-white.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined, .wordpress-content .is-white.is-inverted.is-outlined.wp-block-button__link {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined:hover, .wordpress-content .is-white.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .wordpress-content .is-white.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-white.is-inverted.is-outlined:focus, .wordpress-content .is-white.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-white.is-inverted.is-outlined.is-focused, .wordpress-content .is-white.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-white.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-white.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-white.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-white.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], .wordpress-content .is-white.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-white.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-white.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-white.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black, .wordpress-content .is-black.wp-block-button__link {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-black:hover, .wordpress-content .is-black.wp-block-button__link:hover, .button.is-black.is-hovered, .wordpress-content .is-black.is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #040404;
  border-color: #0000;
}

.button.is-black:focus, .wordpress-content .is-black.wp-block-button__link:focus, .button.is-black.is-focused, .wordpress-content .is-black.is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-black:focus:not(:active), .wordpress-content .is-black.wp-block-button__link:focus:not(:active), .button.is-black.is-focused:not(:active), .wordpress-content .is-black.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.button.is-black:active, .wordpress-content .is-black.wp-block-button__link:active, .button.is-black.is-active, .wordpress-content .is-black.is-active.wp-block-button__link {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black[disabled], .wordpress-content .is-black[disabled].wp-block-button__link, fieldset[disabled] .button.is-black, fieldset[disabled] .wordpress-content .is-black.wp-block-button__link, .wordpress-content fieldset[disabled] .is-black.wp-block-button__link {
  box-shadow: none;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted, .wordpress-content .is-black.is-inverted.wp-block-button__link {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .wordpress-content .is-black.is-inverted.wp-block-button__link:hover, .button.is-black.is-inverted.is-hovered, .wordpress-content .is-black.is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], .wordpress-content .is-black.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-black.is-inverted, fieldset[disabled] .wordpress-content .is-black.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-black.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after, .wordpress-content .is-black.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined, .wordpress-content .is-black.is-outlined.wp-block-button__link {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined:hover, .wordpress-content .is-black.is-outlined.wp-block-button__link:hover, .button.is-black.is-outlined.is-hovered, .wordpress-content .is-black.is-outlined.is-hovered.wp-block-button__link, .button.is-black.is-outlined:focus, .wordpress-content .is-black.is-outlined.wp-block-button__link:focus, .button.is-black.is-outlined.is-focused, .wordpress-content .is-black.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined.is-loading:after, .wordpress-content .is-black.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .wordpress-content .is-black.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-black.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-black.is-outlined.is-loading:focus:after, .wordpress-content .is-black.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after, .wordpress-content .is-black.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], .wordpress-content .is-black.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-black.is-outlined, fieldset[disabled] .wordpress-content .is-black.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-black.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted.is-outlined, .wordpress-content .is-black.is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .wordpress-content .is-black.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .wordpress-content .is-black.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-black.is-inverted.is-outlined:focus, .wordpress-content .is-black.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-black.is-inverted.is-outlined.is-focused, .wordpress-content .is-black.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-black.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-black.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-black.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-black.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-inverted.is-outlined[disabled], .wordpress-content .is-black.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-black.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-black.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-black.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light, .wordpress-content .is-light.wp-block-button__link {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.button.is-light:hover, .wordpress-content .is-light.wp-block-button__link:hover, .button.is-light.is-hovered, .wordpress-content .is-light.is-hovered.wp-block-button__link {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.button.is-light:focus, .wordpress-content .is-light.wp-block-button__link:focus, .button.is-light.is-focused, .wordpress-content .is-light.is-focused.wp-block-button__link {
  color: #000000b3;
  border-color: #0000;
}

.button.is-light:focus:not(:active), .wordpress-content .is-light.wp-block-button__link:focus:not(:active), .button.is-light.is-focused:not(:active), .wordpress-content .is-light.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.button.is-light:active, .wordpress-content .is-light.wp-block-button__link:active, .button.is-light.is-active, .wordpress-content .is-light.is-active.wp-block-button__link {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.button.is-light[disabled], .wordpress-content .is-light[disabled].wp-block-button__link, fieldset[disabled] .button.is-light, fieldset[disabled] .wordpress-content .is-light.wp-block-button__link, .wordpress-content fieldset[disabled] .is-light.wp-block-button__link {
  box-shadow: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted, .wordpress-content .is-light.is-inverted.wp-block-button__link {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted:hover, .wordpress-content .is-light.is-inverted.wp-block-button__link:hover, .button.is-light.is-inverted.is-hovered, .wordpress-content .is-light.is-inverted.is-hovered.wp-block-button__link {
  background-color: #000000b3;
}

.button.is-light.is-inverted[disabled], .wordpress-content .is-light.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-light.is-inverted, fieldset[disabled] .wordpress-content .is-light.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-light.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-light.is-loading:after, .wordpress-content .is-light.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined, .wordpress-content .is-light.is-outlined.wp-block-button__link {
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined:hover, .wordpress-content .is-light.is-outlined.wp-block-button__link:hover, .button.is-light.is-outlined.is-hovered, .wordpress-content .is-light.is-outlined.is-hovered.wp-block-button__link, .button.is-light.is-outlined:focus, .wordpress-content .is-light.is-outlined.wp-block-button__link:focus, .button.is-light.is-outlined.is-focused, .wordpress-content .is-light.is-outlined.is-focused.wp-block-button__link {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-loading:after, .wordpress-content .is-light.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .wordpress-content .is-light.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-light.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-light.is-outlined.is-loading:focus:after, .wordpress-content .is-light.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after, .wordpress-content .is-light.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined[disabled], .wordpress-content .is-light.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-light.is-outlined, fieldset[disabled] .wordpress-content .is-light.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-light.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted.is-outlined, .wordpress-content .is-light.is-inverted.is-outlined.wp-block-button__link {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined:hover, .wordpress-content .is-light.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .wordpress-content .is-light.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-light.is-inverted.is-outlined:focus, .wordpress-content .is-light.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-light.is-inverted.is-outlined.is-focused, .wordpress-content .is-light.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-light.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-light.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-light.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-light.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], .wordpress-content .is-light.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-light.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-light.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-light.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-dark, .wordpress-content .is-dark.wp-block-button__link {
  color: #fff;
  background-color: #363636;
  border-color: #0000;
}

.button.is-dark:hover, .wordpress-content .is-dark.wp-block-button__link:hover, .button.is-dark.is-hovered, .wordpress-content .is-dark.is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #0000;
}

.button.is-dark:focus, .wordpress-content .is-dark.wp-block-button__link:focus, .button.is-dark.is-focused, .wordpress-content .is-dark.is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-dark:focus:not(:active), .wordpress-content .is-dark.wp-block-button__link:focus:not(:active), .button.is-dark.is-focused:not(:active), .wordpress-content .is-dark.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #36363640;
}

.button.is-dark:active, .wordpress-content .is-dark.wp-block-button__link:active, .button.is-dark.is-active, .wordpress-content .is-dark.is-active.wp-block-button__link {
  color: #fff;
  background-color: #292929;
  border-color: #0000;
}

.button.is-dark[disabled], .wordpress-content .is-dark[disabled].wp-block-button__link, fieldset[disabled] .button.is-dark, fieldset[disabled] .wordpress-content .is-dark.wp-block-button__link, .wordpress-content fieldset[disabled] .is-dark.wp-block-button__link {
  box-shadow: none;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-inverted, .wordpress-content .is-dark.is-inverted.wp-block-button__link {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .wordpress-content .is-dark.is-inverted.wp-block-button__link:hover, .button.is-dark.is-inverted.is-hovered, .wordpress-content .is-dark.is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], .wordpress-content .is-dark.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-dark.is-inverted, fieldset[disabled] .wordpress-content .is-dark.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-dark.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #363636;
  background-color: #fff;
  border-color: #0000;
}

.button.is-dark.is-loading:after, .wordpress-content .is-dark.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined, .wordpress-content .is-dark.is-outlined.wp-block-button__link {
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-outlined:hover, .wordpress-content .is-dark.is-outlined.wp-block-button__link:hover, .button.is-dark.is-outlined.is-hovered, .wordpress-content .is-dark.is-outlined.is-hovered.wp-block-button__link, .button.is-dark.is-outlined:focus, .wordpress-content .is-dark.is-outlined.wp-block-button__link:focus, .button.is-dark.is-outlined.is-focused, .wordpress-content .is-dark.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-outlined.is-loading:after, .wordpress-content .is-dark.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .wordpress-content .is-dark.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-dark.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-dark.is-outlined.is-loading:focus:after, .wordpress-content .is-dark.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after, .wordpress-content .is-dark.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], .wordpress-content .is-dark.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-dark.is-outlined, fieldset[disabled] .wordpress-content .is-dark.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-dark.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-inverted.is-outlined, .wordpress-content .is-dark.is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .wordpress-content .is-dark.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .wordpress-content .is-dark.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-dark.is-inverted.is-outlined:focus, .wordpress-content .is-dark.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-dark.is-inverted.is-outlined.is-focused, .wordpress-content .is-dark.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-dark.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-dark.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-dark.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-dark.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], .wordpress-content .is-dark.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-dark.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-dark.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-dark.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary, .wordpress-content .wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0000;
}

.button.is-primary:hover, .wordpress-content .wp-block-button__link:hover, .button.is-primary.is-hovered, .wordpress-content .is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #0b0bb1;
  border-color: #0000;
}

.button.is-primary:focus, .wordpress-content .wp-block-button__link:focus, .button.is-primary.is-focused, .wordpress-content .is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-primary:focus:not(:active), .wordpress-content .wp-block-button__link:focus:not(:active), .button.is-primary.is-focused:not(:active), .wordpress-content .is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.button.is-primary:active, .wordpress-content .wp-block-button__link:active, .button.is-primary.is-active, .wordpress-content .is-active.wp-block-button__link {
  color: #fff;
  background-color: #0a0aa5;
  border-color: #0000;
}

.button.is-primary[disabled], .wordpress-content [disabled].wp-block-button__link, fieldset[disabled] .button.is-primary, fieldset[disabled] .wordpress-content .wp-block-button__link, .wordpress-content fieldset[disabled] .wp-block-button__link {
  box-shadow: none;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.button.is-primary.is-inverted, .wordpress-content .is-inverted.wp-block-button__link {
  color: #0c0cbd;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .wordpress-content .is-inverted.wp-block-button__link:hover, .button.is-primary.is-inverted.is-hovered, .wordpress-content .is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], .wordpress-content .is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-primary.is-inverted, fieldset[disabled] .wordpress-content .is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #0c0cbd;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after, .wordpress-content .is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined, .wordpress-content .is-outlined.wp-block-button__link {
  color: #0c0cbd;
  background-color: #0000;
  border-color: #0c0cbd;
}

.button.is-primary.is-outlined:hover, .wordpress-content .is-outlined.wp-block-button__link:hover, .button.is-primary.is-outlined.is-hovered, .wordpress-content .is-outlined.is-hovered.wp-block-button__link, .button.is-primary.is-outlined:focus, .wordpress-content .is-outlined.wp-block-button__link:focus, .button.is-primary.is-outlined.is-focused, .wordpress-content .is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.button.is-primary.is-outlined.is-loading:after, .wordpress-content .is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #0c0cbd #0c0cbd !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .wordpress-content .is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-primary.is-outlined.is-loading:focus:after, .wordpress-content .is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after, .wordpress-content .is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], .wordpress-content .is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-primary.is-outlined, fieldset[disabled] .wordpress-content .is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #0c0cbd;
  background-color: #0000;
  border-color: #0c0cbd;
}

.button.is-primary.is-inverted.is-outlined, .wordpress-content .is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .wordpress-content .is-inverted.is-outlined.wp-block-button__link:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .wordpress-content .is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-primary.is-inverted.is-outlined:focus, .wordpress-content .is-inverted.is-outlined.wp-block-button__link:focus, .button.is-primary.is-inverted.is-outlined.is-focused, .wordpress-content .is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #0c0cbd;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #0c0cbd #0c0cbd !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], .wordpress-content .is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-primary.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-light, .wordpress-content .is-light.wp-block-button__link {
  color: #2222f1;
  background-color: #ececfe;
}

.button.is-primary.is-light:hover, .wordpress-content .is-light.wp-block-button__link:hover, .button.is-primary.is-light.is-hovered, .wordpress-content .is-light.is-hovered.wp-block-button__link {
  color: #2222f1;
  background-color: #e0e0fd;
  border-color: #0000;
}

.button.is-primary.is-light:active, .wordpress-content .is-light.wp-block-button__link:active, .button.is-primary.is-light.is-active, .wordpress-content .is-light.is-active.wp-block-button__link {
  color: #2222f1;
  background-color: #d4d4fc;
  border-color: #0000;
}

.button.is-link, .wordpress-content .is-link.wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0000;
}

.button.is-link:hover, .wordpress-content .is-link.wp-block-button__link:hover, .button.is-link.is-hovered, .wordpress-content .is-link.is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #0b0bb1;
  border-color: #0000;
}

.button.is-link:focus, .wordpress-content .is-link.wp-block-button__link:focus, .button.is-link.is-focused, .wordpress-content .is-link.is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-link:focus:not(:active), .wordpress-content .is-link.wp-block-button__link:focus:not(:active), .button.is-link.is-focused:not(:active), .wordpress-content .is-link.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.button.is-link:active, .wordpress-content .is-link.wp-block-button__link:active, .button.is-link.is-active, .wordpress-content .is-link.is-active.wp-block-button__link {
  color: #fff;
  background-color: #0a0aa5;
  border-color: #0000;
}

.button.is-link[disabled], .wordpress-content .is-link[disabled].wp-block-button__link, fieldset[disabled] .button.is-link, fieldset[disabled] .wordpress-content .is-link.wp-block-button__link, .wordpress-content fieldset[disabled] .is-link.wp-block-button__link {
  box-shadow: none;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.button.is-link.is-inverted, .wordpress-content .is-link.is-inverted.wp-block-button__link {
  color: #0c0cbd;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .wordpress-content .is-link.is-inverted.wp-block-button__link:hover, .button.is-link.is-inverted.is-hovered, .wordpress-content .is-link.is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], .wordpress-content .is-link.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-link.is-inverted, fieldset[disabled] .wordpress-content .is-link.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-link.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #0c0cbd;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after, .wordpress-content .is-link.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined, .wordpress-content .is-link.is-outlined.wp-block-button__link {
  color: #0c0cbd;
  background-color: #0000;
  border-color: #0c0cbd;
}

.button.is-link.is-outlined:hover, .wordpress-content .is-link.is-outlined.wp-block-button__link:hover, .button.is-link.is-outlined.is-hovered, .wordpress-content .is-link.is-outlined.is-hovered.wp-block-button__link, .button.is-link.is-outlined:focus, .wordpress-content .is-link.is-outlined.wp-block-button__link:focus, .button.is-link.is-outlined.is-focused, .wordpress-content .is-link.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.button.is-link.is-outlined.is-loading:after, .wordpress-content .is-link.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #0c0cbd #0c0cbd !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .wordpress-content .is-link.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-link.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-link.is-outlined.is-loading:focus:after, .wordpress-content .is-link.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after, .wordpress-content .is-link.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], .wordpress-content .is-link.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-link.is-outlined, fieldset[disabled] .wordpress-content .is-link.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-link.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #0c0cbd;
  background-color: #0000;
  border-color: #0c0cbd;
}

.button.is-link.is-inverted.is-outlined, .wordpress-content .is-link.is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .wordpress-content .is-link.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .wordpress-content .is-link.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-link.is-inverted.is-outlined:focus, .wordpress-content .is-link.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-link.is-inverted.is-outlined.is-focused, .wordpress-content .is-link.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #0c0cbd;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-link.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-link.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-link.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-link.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #0c0cbd #0c0cbd !important;
}

.button.is-link.is-inverted.is-outlined[disabled], .wordpress-content .is-link.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-link.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-link.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-link.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-light, .wordpress-content .is-link.is-light.wp-block-button__link {
  color: #2222f1;
  background-color: #ececfe;
}

.button.is-link.is-light:hover, .wordpress-content .is-link.is-light.wp-block-button__link:hover, .button.is-link.is-light.is-hovered, .wordpress-content .is-link.is-light.is-hovered.wp-block-button__link {
  color: #2222f1;
  background-color: #e0e0fd;
  border-color: #0000;
}

.button.is-link.is-light:active, .wordpress-content .is-link.is-light.wp-block-button__link:active, .button.is-link.is-light.is-active, .wordpress-content .is-link.is-light.is-active.wp-block-button__link {
  color: #2222f1;
  background-color: #d4d4fc;
  border-color: #0000;
}

.button.is-info, .wordpress-content .is-info.wp-block-button__link {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #0000;
}

.button.is-info:hover, .wordpress-content .is-info.wp-block-button__link:hover, .button.is-info.is-hovered, .wordpress-content .is-info.is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #3488ce;
  border-color: #0000;
}

.button.is-info:focus, .wordpress-content .is-info.wp-block-button__link:focus, .button.is-info.is-focused, .wordpress-content .is-info.is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-info:focus:not(:active), .wordpress-content .is-info.wp-block-button__link:focus:not(:active), .button.is-info.is-focused:not(:active), .wordpress-content .is-info.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.button.is-info:active, .wordpress-content .is-info.wp-block-button__link:active, .button.is-info.is-active, .wordpress-content .is-info.is-active.wp-block-button__link {
  color: #fff;
  background-color: #3082c5;
  border-color: #0000;
}

.button.is-info[disabled], .wordpress-content .is-info[disabled].wp-block-button__link, fieldset[disabled] .button.is-info, fieldset[disabled] .wordpress-content .is-info.wp-block-button__link, .wordpress-content fieldset[disabled] .is-info.wp-block-button__link {
  box-shadow: none;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.button.is-info.is-inverted, .wordpress-content .is-info.is-inverted.wp-block-button__link {
  color: #3e8ed0;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .wordpress-content .is-info.is-inverted.wp-block-button__link:hover, .button.is-info.is-inverted.is-hovered, .wordpress-content .is-info.is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], .wordpress-content .is-info.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-info.is-inverted, fieldset[disabled] .wordpress-content .is-info.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-info.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #3e8ed0;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after, .wordpress-content .is-info.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined, .wordpress-content .is-info.is-outlined.wp-block-button__link {
  color: #3e8ed0;
  background-color: #0000;
  border-color: #3e8ed0;
}

.button.is-info.is-outlined:hover, .wordpress-content .is-info.is-outlined.wp-block-button__link:hover, .button.is-info.is-outlined.is-hovered, .wordpress-content .is-info.is-outlined.is-hovered.wp-block-button__link, .button.is-info.is-outlined:focus, .wordpress-content .is-info.is-outlined.wp-block-button__link:focus, .button.is-info.is-outlined.is-focused, .wordpress-content .is-info.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.button.is-info.is-outlined.is-loading:after, .wordpress-content .is-info.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #3e8ed0 #3e8ed0 !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .wordpress-content .is-info.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-info.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-info.is-outlined.is-loading:focus:after, .wordpress-content .is-info.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after, .wordpress-content .is-info.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], .wordpress-content .is-info.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-info.is-outlined, fieldset[disabled] .wordpress-content .is-info.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-info.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #3e8ed0;
  background-color: #0000;
  border-color: #3e8ed0;
}

.button.is-info.is-inverted.is-outlined, .wordpress-content .is-info.is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .wordpress-content .is-info.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .wordpress-content .is-info.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-info.is-inverted.is-outlined:focus, .wordpress-content .is-info.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-info.is-inverted.is-outlined.is-focused, .wordpress-content .is-info.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #3e8ed0;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-info.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-info.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-info.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-info.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #3e8ed0 #3e8ed0 !important;
}

.button.is-info.is-inverted.is-outlined[disabled], .wordpress-content .is-info.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-info.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-info.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-info.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-light, .wordpress-content .is-info.is-light.wp-block-button__link {
  color: #296fa8;
  background-color: #eff5fb;
}

.button.is-info.is-light:hover, .wordpress-content .is-info.is-light.wp-block-button__link:hover, .button.is-info.is-light.is-hovered, .wordpress-content .is-info.is-light.is-hovered.wp-block-button__link {
  color: #296fa8;
  background-color: #e4eff9;
  border-color: #0000;
}

.button.is-info.is-light:active, .wordpress-content .is-info.is-light.wp-block-button__link:active, .button.is-info.is-light.is-active, .wordpress-content .is-info.is-light.is-active.wp-block-button__link {
  color: #296fa8;
  background-color: #dae9f6;
  border-color: #0000;
}

.button.is-success, .wordpress-content .is-success.wp-block-button__link {
  color: #fff;
  background-color: #48c78e;
  border-color: #0000;
}

.button.is-success:hover, .wordpress-content .is-success.wp-block-button__link:hover, .button.is-success.is-hovered, .wordpress-content .is-success.is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #3ec487;
  border-color: #0000;
}

.button.is-success:focus, .wordpress-content .is-success.wp-block-button__link:focus, .button.is-success.is-focused, .wordpress-content .is-success.is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-success:focus:not(:active), .wordpress-content .is-success.wp-block-button__link:focus:not(:active), .button.is-success.is-focused:not(:active), .wordpress-content .is-success.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.button.is-success:active, .wordpress-content .is-success.wp-block-button__link:active, .button.is-success.is-active, .wordpress-content .is-success.is-active.wp-block-button__link {
  color: #fff;
  background-color: #3abb81;
  border-color: #0000;
}

.button.is-success[disabled], .wordpress-content .is-success[disabled].wp-block-button__link, fieldset[disabled] .button.is-success, fieldset[disabled] .wordpress-content .is-success.wp-block-button__link, .wordpress-content fieldset[disabled] .is-success.wp-block-button__link {
  box-shadow: none;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-inverted, .wordpress-content .is-success.is-inverted.wp-block-button__link {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted:hover, .wordpress-content .is-success.is-inverted.wp-block-button__link:hover, .button.is-success.is-inverted.is-hovered, .wordpress-content .is-success.is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], .wordpress-content .is-success.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-success.is-inverted, fieldset[disabled] .wordpress-content .is-success.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-success.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #48c78e;
  background-color: #fff;
  border-color: #0000;
}

.button.is-success.is-loading:after, .wordpress-content .is-success.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined, .wordpress-content .is-success.is-outlined.wp-block-button__link {
  color: #48c78e;
  background-color: #0000;
  border-color: #48c78e;
}

.button.is-success.is-outlined:hover, .wordpress-content .is-success.is-outlined.wp-block-button__link:hover, .button.is-success.is-outlined.is-hovered, .wordpress-content .is-success.is-outlined.is-hovered.wp-block-button__link, .button.is-success.is-outlined:focus, .wordpress-content .is-success.is-outlined.wp-block-button__link:focus, .button.is-success.is-outlined.is-focused, .wordpress-content .is-success.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-outlined.is-loading:after, .wordpress-content .is-success.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #48c78e #48c78e !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .wordpress-content .is-success.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-success.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-success.is-outlined.is-loading:focus:after, .wordpress-content .is-success.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after, .wordpress-content .is-success.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined[disabled], .wordpress-content .is-success.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-success.is-outlined, fieldset[disabled] .wordpress-content .is-success.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-success.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #48c78e;
  background-color: #0000;
  border-color: #48c78e;
}

.button.is-success.is-inverted.is-outlined, .wordpress-content .is-success.is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined:hover, .wordpress-content .is-success.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .wordpress-content .is-success.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-success.is-inverted.is-outlined:focus, .wordpress-content .is-success.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-success.is-inverted.is-outlined.is-focused, .wordpress-content .is-success.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-success.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-success.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-success.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-success.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #48c78e #48c78e !important;
}

.button.is-success.is-inverted.is-outlined[disabled], .wordpress-content .is-success.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-success.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-success.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-success.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-light, .wordpress-content .is-success.is-light.wp-block-button__link {
  color: #257953;
  background-color: #effaf5;
}

.button.is-success.is-light:hover, .wordpress-content .is-success.is-light.wp-block-button__link:hover, .button.is-success.is-light.is-hovered, .wordpress-content .is-success.is-light.is-hovered.wp-block-button__link {
  color: #257953;
  background-color: #e6f7ef;
  border-color: #0000;
}

.button.is-success.is-light:active, .wordpress-content .is-success.is-light.wp-block-button__link:active, .button.is-success.is-light.is-active, .wordpress-content .is-success.is-light.is-active.wp-block-button__link {
  color: #257953;
  background-color: #dcf4e9;
  border-color: #0000;
}

.button.is-warning, .wordpress-content .is-warning.wp-block-button__link {
  color: #000000b3;
  background-color: #ffb938;
  border-color: #0000;
}

.button.is-warning:hover, .wordpress-content .is-warning.wp-block-button__link:hover, .button.is-warning.is-hovered, .wordpress-content .is-warning.is-hovered.wp-block-button__link {
  color: #000000b3;
  background-color: #ffb52b;
  border-color: #0000;
}

.button.is-warning:focus, .wordpress-content .is-warning.wp-block-button__link:focus, .button.is-warning.is-focused, .wordpress-content .is-warning.is-focused.wp-block-button__link {
  color: #000000b3;
  border-color: #0000;
}

.button.is-warning:focus:not(:active), .wordpress-content .is-warning.wp-block-button__link:focus:not(:active), .button.is-warning.is-focused:not(:active), .wordpress-content .is-warning.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #ffb93840;
}

.button.is-warning:active, .wordpress-content .is-warning.wp-block-button__link:active, .button.is-warning.is-active, .wordpress-content .is-warning.is-active.wp-block-button__link {
  color: #000000b3;
  background-color: #ffb01f;
  border-color: #0000;
}

.button.is-warning[disabled], .wordpress-content .is-warning[disabled].wp-block-button__link, fieldset[disabled] .button.is-warning, fieldset[disabled] .wordpress-content .is-warning.wp-block-button__link, .wordpress-content fieldset[disabled] .is-warning.wp-block-button__link {
  box-shadow: none;
  background-color: #ffb938;
  border-color: #ffb938;
}

.button.is-warning.is-inverted, .wordpress-content .is-warning.is-inverted.wp-block-button__link {
  color: #ffb938;
  background-color: #000000b3;
}

.button.is-warning.is-inverted:hover, .wordpress-content .is-warning.is-inverted.wp-block-button__link:hover, .button.is-warning.is-inverted.is-hovered, .wordpress-content .is-warning.is-inverted.is-hovered.wp-block-button__link {
  background-color: #000000b3;
}

.button.is-warning.is-inverted[disabled], .wordpress-content .is-warning.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-warning.is-inverted, fieldset[disabled] .wordpress-content .is-warning.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-warning.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #ffb938;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-warning.is-loading:after, .wordpress-content .is-warning.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined, .wordpress-content .is-warning.is-outlined.wp-block-button__link {
  color: #ffb938;
  background-color: #0000;
  border-color: #ffb938;
}

.button.is-warning.is-outlined:hover, .wordpress-content .is-warning.is-outlined.wp-block-button__link:hover, .button.is-warning.is-outlined.is-hovered, .wordpress-content .is-warning.is-outlined.is-hovered.wp-block-button__link, .button.is-warning.is-outlined:focus, .wordpress-content .is-warning.is-outlined.wp-block-button__link:focus, .button.is-warning.is-outlined.is-focused, .wordpress-content .is-warning.is-outlined.is-focused.wp-block-button__link {
  color: #000000b3;
  background-color: #ffb938;
  border-color: #ffb938;
}

.button.is-warning.is-outlined.is-loading:after, .wordpress-content .is-warning.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #ffb938 #ffb938 !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .wordpress-content .is-warning.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-warning.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-warning.is-outlined.is-loading:focus:after, .wordpress-content .is-warning.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after, .wordpress-content .is-warning.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined[disabled], .wordpress-content .is-warning.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-warning.is-outlined, fieldset[disabled] .wordpress-content .is-warning.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-warning.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #ffb938;
  background-color: #0000;
  border-color: #ffb938;
}

.button.is-warning.is-inverted.is-outlined, .wordpress-content .is-warning.is-inverted.is-outlined.wp-block-button__link {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined:hover, .wordpress-content .is-warning.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .wordpress-content .is-warning.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-warning.is-inverted.is-outlined:focus, .wordpress-content .is-warning.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-warning.is-inverted.is-outlined.is-focused, .wordpress-content .is-warning.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #ffb938;
  background-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-warning.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-warning.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-warning.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-warning.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #ffb938 #ffb938 !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], .wordpress-content .is-warning.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-warning.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-warning.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-warning.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-light, .wordpress-content .is-warning.is-light.wp-block-button__link {
  color: #946000;
  background-color: #fff8eb;
}

.button.is-warning.is-light:hover, .wordpress-content .is-warning.is-light.wp-block-button__link:hover, .button.is-warning.is-light.is-hovered, .wordpress-content .is-warning.is-light.is-hovered.wp-block-button__link {
  color: #946000;
  background-color: #fff3de;
  border-color: #0000;
}

.button.is-warning.is-light:active, .wordpress-content .is-warning.is-light.wp-block-button__link:active, .button.is-warning.is-light.is-active, .wordpress-content .is-warning.is-light.is-active.wp-block-button__link {
  color: #946000;
  background-color: #ffefd1;
  border-color: #0000;
}

.button.is-danger, .wordpress-content .is-danger.wp-block-button__link {
  color: #fff;
  background-color: #ea3f3f;
  border-color: #0000;
}

.button.is-danger:hover, .wordpress-content .is-danger.wp-block-button__link:hover, .button.is-danger.is-hovered, .wordpress-content .is-danger.is-hovered.wp-block-button__link {
  color: #fff;
  background-color: #e93434;
  border-color: #0000;
}

.button.is-danger:focus, .wordpress-content .is-danger.wp-block-button__link:focus, .button.is-danger.is-focused, .wordpress-content .is-danger.is-focused.wp-block-button__link {
  color: #fff;
  border-color: #0000;
}

.button.is-danger:focus:not(:active), .wordpress-content .is-danger.wp-block-button__link:focus:not(:active), .button.is-danger.is-focused:not(:active), .wordpress-content .is-danger.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #ea3f3f40;
}

.button.is-danger:active, .wordpress-content .is-danger.wp-block-button__link:active, .button.is-danger.is-active, .wordpress-content .is-danger.is-active.wp-block-button__link {
  color: #fff;
  background-color: #e72828;
  border-color: #0000;
}

.button.is-danger[disabled], .wordpress-content .is-danger[disabled].wp-block-button__link, fieldset[disabled] .button.is-danger, fieldset[disabled] .wordpress-content .is-danger.wp-block-button__link, .wordpress-content fieldset[disabled] .is-danger.wp-block-button__link {
  box-shadow: none;
  background-color: #ea3f3f;
  border-color: #ea3f3f;
}

.button.is-danger.is-inverted, .wordpress-content .is-danger.is-inverted.wp-block-button__link {
  color: #ea3f3f;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .wordpress-content .is-danger.is-inverted.wp-block-button__link:hover, .button.is-danger.is-inverted.is-hovered, .wordpress-content .is-danger.is-inverted.is-hovered.wp-block-button__link {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], .wordpress-content .is-danger.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-danger.is-inverted, fieldset[disabled] .wordpress-content .is-danger.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-danger.is-inverted.wp-block-button__link {
  box-shadow: none;
  color: #ea3f3f;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after, .wordpress-content .is-danger.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined, .wordpress-content .is-danger.is-outlined.wp-block-button__link {
  color: #ea3f3f;
  background-color: #0000;
  border-color: #ea3f3f;
}

.button.is-danger.is-outlined:hover, .wordpress-content .is-danger.is-outlined.wp-block-button__link:hover, .button.is-danger.is-outlined.is-hovered, .wordpress-content .is-danger.is-outlined.is-hovered.wp-block-button__link, .button.is-danger.is-outlined:focus, .wordpress-content .is-danger.is-outlined.wp-block-button__link:focus, .button.is-danger.is-outlined.is-focused, .wordpress-content .is-danger.is-outlined.is-focused.wp-block-button__link {
  color: #fff;
  background-color: #ea3f3f;
  border-color: #ea3f3f;
}

.button.is-danger.is-outlined.is-loading:after, .wordpress-content .is-danger.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #ea3f3f #ea3f3f !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .wordpress-content .is-danger.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-danger.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-danger.is-outlined.is-loading:focus:after, .wordpress-content .is-danger.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after, .wordpress-content .is-danger.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], .wordpress-content .is-danger.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-danger.is-outlined, fieldset[disabled] .wordpress-content .is-danger.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-danger.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #ea3f3f;
  background-color: #0000;
  border-color: #ea3f3f;
}

.button.is-danger.is-inverted.is-outlined, .wordpress-content .is-danger.is-inverted.is-outlined.wp-block-button__link {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .wordpress-content .is-danger.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .wordpress-content .is-danger.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-danger.is-inverted.is-outlined:focus, .wordpress-content .is-danger.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-danger.is-inverted.is-outlined.is-focused, .wordpress-content .is-danger.is-inverted.is-outlined.is-focused.wp-block-button__link {
  color: #ea3f3f;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-danger.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-danger.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-danger.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-danger.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #ea3f3f #ea3f3f !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], .wordpress-content .is-danger.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-danger.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-danger.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-danger.is-inverted.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-light, .wordpress-content .is-danger.is-light.wp-block-button__link {
  color: #ca1616;
  background-color: #fdeded;
}

.button.is-danger.is-light:hover, .wordpress-content .is-danger.is-light.wp-block-button__link:hover, .button.is-danger.is-light.is-hovered, .wordpress-content .is-danger.is-light.is-hovered.wp-block-button__link {
  color: #ca1616;
  background-color: #fce1e1;
  border-color: #0000;
}

.button.is-danger.is-light:active, .wordpress-content .is-danger.is-light.wp-block-button__link:active, .button.is-danger.is-light.is-active, .wordpress-content .is-danger.is-light.is-active.wp-block-button__link {
  color: #ca1616;
  background-color: #fad6d6;
  border-color: #0000;
}

.button.is-secondary, .wordpress-content .is-secondary.wp-block-button__link {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
  border-color: #0000;
}

.button.is-secondary:hover, .wordpress-content .is-secondary.wp-block-button__link:hover, .button.is-secondary.is-hovered, .wordpress-content .is-secondary.is-hovered.wp-block-button__link {
  color: findColorInvert(#f5bdac);
  background-color: #f4b4a1;
  border-color: #0000;
}

.button.is-secondary:focus, .wordpress-content .is-secondary.wp-block-button__link:focus, .button.is-secondary.is-focused, .wordpress-content .is-secondary.is-focused.wp-block-button__link {
  color: findColorInvert(#f5bdac);
  border-color: #0000;
}

.button.is-secondary:focus:not(:active), .wordpress-content .is-secondary.wp-block-button__link:focus:not(:active), .button.is-secondary.is-focused:not(:active), .wordpress-content .is-secondary.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #f5bdac40;
}

.button.is-secondary:active, .wordpress-content .is-secondary.wp-block-button__link:active, .button.is-secondary.is-active, .wordpress-content .is-secondary.is-active.wp-block-button__link {
  color: findColorInvert(#f5bdac);
  background-color: #f2ab95;
  border-color: #0000;
}

.button.is-secondary[disabled], .wordpress-content .is-secondary[disabled].wp-block-button__link, fieldset[disabled] .button.is-secondary, fieldset[disabled] .wordpress-content .is-secondary.wp-block-button__link, .wordpress-content fieldset[disabled] .is-secondary.wp-block-button__link {
  box-shadow: none;
  background-color: #f5bdac;
  border-color: #f5bdac;
}

.button.is-secondary.is-inverted, .wordpress-content .is-secondary.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#f5bdac);
  color: #f5bdac;
}

.button.is-secondary.is-inverted:hover, .wordpress-content .is-secondary.is-inverted.wp-block-button__link:hover, .button.is-secondary.is-inverted.is-hovered, .wordpress-content .is-secondary.is-inverted.is-hovered.wp-block-button__link {
  background-color: findColorInvert(#f5bdac);
}

.button.is-secondary.is-inverted[disabled], .wordpress-content .is-secondary.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-secondary.is-inverted, fieldset[disabled] .wordpress-content .is-secondary.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-secondary.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#f5bdac);
  box-shadow: none;
  color: #f5bdac;
  border-color: #0000;
}

.button.is-secondary.is-loading:after, .wordpress-content .is-secondary.is-loading.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#f5bdac) findColorInvert(#f5bdac) !important;
}

.button.is-secondary.is-outlined, .wordpress-content .is-secondary.is-outlined.wp-block-button__link {
  color: #f5bdac;
  background-color: #0000;
  border-color: #f5bdac;
}

.button.is-secondary.is-outlined:hover, .wordpress-content .is-secondary.is-outlined.wp-block-button__link:hover, .button.is-secondary.is-outlined.is-hovered, .wordpress-content .is-secondary.is-outlined.is-hovered.wp-block-button__link, .button.is-secondary.is-outlined:focus, .wordpress-content .is-secondary.is-outlined.wp-block-button__link:focus, .button.is-secondary.is-outlined.is-focused, .wordpress-content .is-secondary.is-outlined.is-focused.wp-block-button__link {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
  border-color: #f5bdac;
}

.button.is-secondary.is-outlined.is-loading:after, .wordpress-content .is-secondary.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #f5bdac #f5bdac !important;
}

.button.is-secondary.is-outlined.is-loading:hover:after, .wordpress-content .is-secondary.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-secondary.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-secondary.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-secondary.is-outlined.is-loading:focus:after, .wordpress-content .is-secondary.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-secondary.is-outlined.is-loading.is-focused:after, .wordpress-content .is-secondary.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#f5bdac) findColorInvert(#f5bdac) !important;
}

.button.is-secondary.is-outlined[disabled], .wordpress-content .is-secondary.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-secondary.is-outlined, fieldset[disabled] .wordpress-content .is-secondary.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-secondary.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #f5bdac;
  background-color: #0000;
  border-color: #f5bdac;
}

.button.is-secondary.is-inverted.is-outlined, .wordpress-content .is-secondary.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#f5bdac);
  color: findColorInvert(#f5bdac);
  background-color: #0000;
}

.button.is-secondary.is-inverted.is-outlined:hover, .wordpress-content .is-secondary.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-secondary.is-inverted.is-outlined.is-hovered, .wordpress-content .is-secondary.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-secondary.is-inverted.is-outlined:focus, .wordpress-content .is-secondary.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-secondary.is-inverted.is-outlined.is-focused, .wordpress-content .is-secondary.is-inverted.is-outlined.is-focused.wp-block-button__link {
  background-color: findColorInvert(#f5bdac);
  color: #f5bdac;
}

.button.is-secondary.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-secondary.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-secondary.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-secondary.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-secondary.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-secondary.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-secondary.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-secondary.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #f5bdac #f5bdac !important;
}

.button.is-secondary.is-inverted.is-outlined[disabled], .wordpress-content .is-secondary.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-secondary.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-secondary.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-secondary.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#f5bdac);
  box-shadow: none;
  color: findColorInvert(#f5bdac);
  background-color: #0000;
}

.button.is-secondary.is-light, .wordpress-content .is-secondary.is-light.wp-block-button__link {
  color: #842b10;
  background-color: #fdf1ed;
}

.button.is-secondary.is-light:hover, .wordpress-content .is-secondary.is-light.wp-block-button__link:hover, .button.is-secondary.is-light.is-hovered, .wordpress-content .is-secondary.is-light.is-hovered.wp-block-button__link {
  color: #842b10;
  background-color: #fbe7e1;
  border-color: #0000;
}

.button.is-secondary.is-light:active, .wordpress-content .is-secondary.is-light.wp-block-button__link:active, .button.is-secondary.is-light.is-active, .wordpress-content .is-secondary.is-light.is-active.wp-block-button__link {
  color: #842b10;
  background-color: #faded6;
  border-color: #0000;
}

.button.is-peach, .wordpress-content .is-peach.wp-block-button__link {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
  border-color: #0000;
}

.button.is-peach:hover, .wordpress-content .is-peach.wp-block-button__link:hover, .button.is-peach.is-hovered, .wordpress-content .is-peach.is-hovered.wp-block-button__link {
  color: findColorInvert(#e9897d);
  background-color: #e77f72;
  border-color: #0000;
}

.button.is-peach:focus, .wordpress-content .is-peach.wp-block-button__link:focus, .button.is-peach.is-focused, .wordpress-content .is-peach.is-focused.wp-block-button__link {
  color: findColorInvert(#e9897d);
  border-color: #0000;
}

.button.is-peach:focus:not(:active), .wordpress-content .is-peach.wp-block-button__link:focus:not(:active), .button.is-peach.is-focused:not(:active), .wordpress-content .is-peach.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #e9897d40;
}

.button.is-peach:active, .wordpress-content .is-peach.wp-block-button__link:active, .button.is-peach.is-active, .wordpress-content .is-peach.is-active.wp-block-button__link {
  color: findColorInvert(#e9897d);
  background-color: #e57567;
  border-color: #0000;
}

.button.is-peach[disabled], .wordpress-content .is-peach[disabled].wp-block-button__link, fieldset[disabled] .button.is-peach, fieldset[disabled] .wordpress-content .is-peach.wp-block-button__link, .wordpress-content fieldset[disabled] .is-peach.wp-block-button__link {
  box-shadow: none;
  background-color: #e9897d;
  border-color: #e9897d;
}

.button.is-peach.is-inverted, .wordpress-content .is-peach.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#e9897d);
  color: #e9897d;
}

.button.is-peach.is-inverted:hover, .wordpress-content .is-peach.is-inverted.wp-block-button__link:hover, .button.is-peach.is-inverted.is-hovered, .wordpress-content .is-peach.is-inverted.is-hovered.wp-block-button__link {
  background-color: findColorInvert(#e9897d);
}

.button.is-peach.is-inverted[disabled], .wordpress-content .is-peach.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-peach.is-inverted, fieldset[disabled] .wordpress-content .is-peach.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-peach.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#e9897d);
  box-shadow: none;
  color: #e9897d;
  border-color: #0000;
}

.button.is-peach.is-loading:after, .wordpress-content .is-peach.is-loading.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#e9897d) findColorInvert(#e9897d) !important;
}

.button.is-peach.is-outlined, .wordpress-content .is-peach.is-outlined.wp-block-button__link {
  color: #e9897d;
  background-color: #0000;
  border-color: #e9897d;
}

.button.is-peach.is-outlined:hover, .wordpress-content .is-peach.is-outlined.wp-block-button__link:hover, .button.is-peach.is-outlined.is-hovered, .wordpress-content .is-peach.is-outlined.is-hovered.wp-block-button__link, .button.is-peach.is-outlined:focus, .wordpress-content .is-peach.is-outlined.wp-block-button__link:focus, .button.is-peach.is-outlined.is-focused, .wordpress-content .is-peach.is-outlined.is-focused.wp-block-button__link {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
  border-color: #e9897d;
}

.button.is-peach.is-outlined.is-loading:after, .wordpress-content .is-peach.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #e9897d #e9897d !important;
}

.button.is-peach.is-outlined.is-loading:hover:after, .wordpress-content .is-peach.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-peach.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-peach.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-peach.is-outlined.is-loading:focus:after, .wordpress-content .is-peach.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-peach.is-outlined.is-loading.is-focused:after, .wordpress-content .is-peach.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#e9897d) findColorInvert(#e9897d) !important;
}

.button.is-peach.is-outlined[disabled], .wordpress-content .is-peach.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-peach.is-outlined, fieldset[disabled] .wordpress-content .is-peach.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-peach.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #e9897d;
  background-color: #0000;
  border-color: #e9897d;
}

.button.is-peach.is-inverted.is-outlined, .wordpress-content .is-peach.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#e9897d);
  color: findColorInvert(#e9897d);
  background-color: #0000;
}

.button.is-peach.is-inverted.is-outlined:hover, .wordpress-content .is-peach.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-peach.is-inverted.is-outlined.is-hovered, .wordpress-content .is-peach.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-peach.is-inverted.is-outlined:focus, .wordpress-content .is-peach.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-peach.is-inverted.is-outlined.is-focused, .wordpress-content .is-peach.is-inverted.is-outlined.is-focused.wp-block-button__link {
  background-color: findColorInvert(#e9897d);
  color: #e9897d;
}

.button.is-peach.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-peach.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-peach.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-peach.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-peach.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-peach.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-peach.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-peach.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #e9897d #e9897d !important;
}

.button.is-peach.is-inverted.is-outlined[disabled], .wordpress-content .is-peach.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-peach.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-peach.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-peach.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#e9897d);
  box-shadow: none;
  color: findColorInvert(#e9897d);
  background-color: #0000;
}

.button.is-peach.is-light, .wordpress-content .is-peach.is-light.wp-block-button__link {
  color: #99281a;
  background-color: #fcefee;
}

.button.is-peach.is-light:hover, .wordpress-content .is-peach.is-light.wp-block-button__link:hover, .button.is-peach.is-light.is-hovered, .wordpress-content .is-peach.is-light.is-hovered.wp-block-button__link {
  color: #99281a;
  background-color: #fae5e3;
  border-color: #0000;
}

.button.is-peach.is-light:active, .wordpress-content .is-peach.is-light.wp-block-button__link:active, .button.is-peach.is-light.is-active, .wordpress-content .is-peach.is-light.is-active.wp-block-button__link {
  color: #99281a;
  background-color: #f8dbd8;
  border-color: #0000;
}

.button.is-navy, .wordpress-content .is-navy.wp-block-button__link {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
  border-color: #0000;
}

.button.is-navy:hover, .wordpress-content .is-navy.wp-block-button__link:hover, .button.is-navy.is-hovered, .wordpress-content .is-navy.is-hovered.wp-block-button__link {
  color: findColorInvert(#1b0777);
  background-color: #18066b;
  border-color: #0000;
}

.button.is-navy:focus, .wordpress-content .is-navy.wp-block-button__link:focus, .button.is-navy.is-focused, .wordpress-content .is-navy.is-focused.wp-block-button__link {
  color: findColorInvert(#1b0777);
  border-color: #0000;
}

.button.is-navy:focus:not(:active), .wordpress-content .is-navy.wp-block-button__link:focus:not(:active), .button.is-navy.is-focused:not(:active), .wordpress-content .is-navy.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #1b077740;
}

.button.is-navy:active, .wordpress-content .is-navy.wp-block-button__link:active, .button.is-navy.is-active, .wordpress-content .is-navy.is-active.wp-block-button__link {
  color: findColorInvert(#1b0777);
  background-color: #16065f;
  border-color: #0000;
}

.button.is-navy[disabled], .wordpress-content .is-navy[disabled].wp-block-button__link, fieldset[disabled] .button.is-navy, fieldset[disabled] .wordpress-content .is-navy.wp-block-button__link, .wordpress-content fieldset[disabled] .is-navy.wp-block-button__link {
  box-shadow: none;
  background-color: #1b0777;
  border-color: #1b0777;
}

.button.is-navy.is-inverted, .wordpress-content .is-navy.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#1b0777);
  color: #1b0777;
}

.button.is-navy.is-inverted:hover, .wordpress-content .is-navy.is-inverted.wp-block-button__link:hover, .button.is-navy.is-inverted.is-hovered, .wordpress-content .is-navy.is-inverted.is-hovered.wp-block-button__link {
  background-color: findColorInvert(#1b0777);
}

.button.is-navy.is-inverted[disabled], .wordpress-content .is-navy.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-navy.is-inverted, fieldset[disabled] .wordpress-content .is-navy.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-navy.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#1b0777);
  box-shadow: none;
  color: #1b0777;
  border-color: #0000;
}

.button.is-navy.is-loading:after, .wordpress-content .is-navy.is-loading.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#1b0777) findColorInvert(#1b0777) !important;
}

.button.is-navy.is-outlined, .wordpress-content .is-navy.is-outlined.wp-block-button__link {
  color: #1b0777;
  background-color: #0000;
  border-color: #1b0777;
}

.button.is-navy.is-outlined:hover, .wordpress-content .is-navy.is-outlined.wp-block-button__link:hover, .button.is-navy.is-outlined.is-hovered, .wordpress-content .is-navy.is-outlined.is-hovered.wp-block-button__link, .button.is-navy.is-outlined:focus, .wordpress-content .is-navy.is-outlined.wp-block-button__link:focus, .button.is-navy.is-outlined.is-focused, .wordpress-content .is-navy.is-outlined.is-focused.wp-block-button__link {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
  border-color: #1b0777;
}

.button.is-navy.is-outlined.is-loading:after, .wordpress-content .is-navy.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #1b0777 #1b0777 !important;
}

.button.is-navy.is-outlined.is-loading:hover:after, .wordpress-content .is-navy.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-navy.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-navy.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-navy.is-outlined.is-loading:focus:after, .wordpress-content .is-navy.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-navy.is-outlined.is-loading.is-focused:after, .wordpress-content .is-navy.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#1b0777) findColorInvert(#1b0777) !important;
}

.button.is-navy.is-outlined[disabled], .wordpress-content .is-navy.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-navy.is-outlined, fieldset[disabled] .wordpress-content .is-navy.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-navy.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #1b0777;
  background-color: #0000;
  border-color: #1b0777;
}

.button.is-navy.is-inverted.is-outlined, .wordpress-content .is-navy.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#1b0777);
  color: findColorInvert(#1b0777);
  background-color: #0000;
}

.button.is-navy.is-inverted.is-outlined:hover, .wordpress-content .is-navy.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-navy.is-inverted.is-outlined.is-hovered, .wordpress-content .is-navy.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-navy.is-inverted.is-outlined:focus, .wordpress-content .is-navy.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-navy.is-inverted.is-outlined.is-focused, .wordpress-content .is-navy.is-inverted.is-outlined.is-focused.wp-block-button__link {
  background-color: findColorInvert(#1b0777);
  color: #1b0777;
}

.button.is-navy.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-navy.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-navy.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-navy.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-navy.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-navy.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-navy.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-navy.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #1b0777 #1b0777 !important;
}

.button.is-navy.is-inverted.is-outlined[disabled], .wordpress-content .is-navy.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-navy.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-navy.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-navy.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#1b0777);
  box-shadow: none;
  color: findColorInvert(#1b0777);
  background-color: #0000;
}

.button.is-navy.is-light, .wordpress-content .is-navy.is-light.wp-block-button__link {
  color: #4f2bf3;
  background-color: #efecfe;
}

.button.is-navy.is-light:hover, .wordpress-content .is-navy.is-light.wp-block-button__link:hover, .button.is-navy.is-light.is-hovered, .wordpress-content .is-navy.is-light.is-hovered.wp-block-button__link {
  color: #4f2bf3;
  background-color: #e5e0fd;
  border-color: #0000;
}

.button.is-navy.is-light:active, .wordpress-content .is-navy.is-light.wp-block-button__link:active, .button.is-navy.is-light.is-active, .wordpress-content .is-navy.is-light.is-active.wp-block-button__link {
  color: #4f2bf3;
  background-color: #dbd4fc;
  border-color: #0000;
}

.button.is-propelle-grey-light, .wordpress-content .is-propelle-grey-light.wp-block-button__link {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
  border-color: #0000;
}

.button.is-propelle-grey-light:hover, .wordpress-content .is-propelle-grey-light.wp-block-button__link:hover, .button.is-propelle-grey-light.is-hovered, .wordpress-content .is-propelle-grey-light.is-hovered.wp-block-button__link {
  color: findColorInvert(#fff8f1);
  background-color: #fff2e4;
  border-color: #0000;
}

.button.is-propelle-grey-light:focus, .wordpress-content .is-propelle-grey-light.wp-block-button__link:focus, .button.is-propelle-grey-light.is-focused, .wordpress-content .is-propelle-grey-light.is-focused.wp-block-button__link {
  color: findColorInvert(#fff8f1);
  border-color: #0000;
}

.button.is-propelle-grey-light:focus:not(:active), .wordpress-content .is-propelle-grey-light.wp-block-button__link:focus:not(:active), .button.is-propelle-grey-light.is-focused:not(:active), .wordpress-content .is-propelle-grey-light.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #fff8f140;
}

.button.is-propelle-grey-light:active, .wordpress-content .is-propelle-grey-light.wp-block-button__link:active, .button.is-propelle-grey-light.is-active, .wordpress-content .is-propelle-grey-light.is-active.wp-block-button__link {
  color: findColorInvert(#fff8f1);
  background-color: #ffebd8;
  border-color: #0000;
}

.button.is-propelle-grey-light[disabled], .wordpress-content .is-propelle-grey-light[disabled].wp-block-button__link, fieldset[disabled] .button.is-propelle-grey-light, fieldset[disabled] .wordpress-content .is-propelle-grey-light.wp-block-button__link, .wordpress-content fieldset[disabled] .is-propelle-grey-light.wp-block-button__link {
  box-shadow: none;
  background-color: #fff8f1;
  border-color: #fff8f1;
}

.button.is-propelle-grey-light.is-inverted, .wordpress-content .is-propelle-grey-light.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#fff8f1);
  color: #fff8f1;
}

.button.is-propelle-grey-light.is-inverted:hover, .wordpress-content .is-propelle-grey-light.is-inverted.wp-block-button__link:hover, .button.is-propelle-grey-light.is-inverted.is-hovered, .wordpress-content .is-propelle-grey-light.is-inverted.is-hovered.wp-block-button__link {
  background-color: findColorInvert(#fff8f1);
}

.button.is-propelle-grey-light.is-inverted[disabled], .wordpress-content .is-propelle-grey-light.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-propelle-grey-light.is-inverted, fieldset[disabled] .wordpress-content .is-propelle-grey-light.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-propelle-grey-light.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#fff8f1);
  box-shadow: none;
  color: #fff8f1;
  border-color: #0000;
}

.button.is-propelle-grey-light.is-loading:after, .wordpress-content .is-propelle-grey-light.is-loading.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#fff8f1) findColorInvert(#fff8f1) !important;
}

.button.is-propelle-grey-light.is-outlined, .wordpress-content .is-propelle-grey-light.is-outlined.wp-block-button__link {
  color: #fff8f1;
  background-color: #0000;
  border-color: #fff8f1;
}

.button.is-propelle-grey-light.is-outlined:hover, .wordpress-content .is-propelle-grey-light.is-outlined.wp-block-button__link:hover, .button.is-propelle-grey-light.is-outlined.is-hovered, .wordpress-content .is-propelle-grey-light.is-outlined.is-hovered.wp-block-button__link, .button.is-propelle-grey-light.is-outlined:focus, .wordpress-content .is-propelle-grey-light.is-outlined.wp-block-button__link:focus, .button.is-propelle-grey-light.is-outlined.is-focused, .wordpress-content .is-propelle-grey-light.is-outlined.is-focused.wp-block-button__link {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
  border-color: #fff8f1;
}

.button.is-propelle-grey-light.is-outlined.is-loading:after, .wordpress-content .is-propelle-grey-light.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #fff8f1 #fff8f1 !important;
}

.button.is-propelle-grey-light.is-outlined.is-loading:hover:after, .wordpress-content .is-propelle-grey-light.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-propelle-grey-light.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-propelle-grey-light.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-propelle-grey-light.is-outlined.is-loading:focus:after, .wordpress-content .is-propelle-grey-light.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-propelle-grey-light.is-outlined.is-loading.is-focused:after, .wordpress-content .is-propelle-grey-light.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#fff8f1) findColorInvert(#fff8f1) !important;
}

.button.is-propelle-grey-light.is-outlined[disabled], .wordpress-content .is-propelle-grey-light.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-propelle-grey-light.is-outlined, fieldset[disabled] .wordpress-content .is-propelle-grey-light.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-propelle-grey-light.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #fff8f1;
  background-color: #0000;
  border-color: #fff8f1;
}

.button.is-propelle-grey-light.is-inverted.is-outlined, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#fff8f1);
  color: findColorInvert(#fff8f1);
  background-color: #0000;
}

.button.is-propelle-grey-light.is-inverted.is-outlined:hover, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-propelle-grey-light.is-inverted.is-outlined.is-hovered, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-propelle-grey-light.is-inverted.is-outlined:focus, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-propelle-grey-light.is-inverted.is-outlined.is-focused, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.is-focused.wp-block-button__link {
  background-color: findColorInvert(#fff8f1);
  color: #fff8f1;
}

.button.is-propelle-grey-light.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-propelle-grey-light.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-propelle-grey-light.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-propelle-grey-light.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #fff8f1 #fff8f1 !important;
}

.button.is-propelle-grey-light.is-inverted.is-outlined[disabled], .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-propelle-grey-light.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-propelle-grey-light.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-propelle-grey-light.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#fff8f1);
  box-shadow: none;
  color: findColorInvert(#fff8f1);
  background-color: #0000;
}

.button.is-propelle-grey-light.is-light, .wordpress-content .is-propelle-grey-light.is-light.wp-block-button__link {
  color: #944a00;
  background-color: #fff8f1;
}

.button.is-propelle-grey-light.is-light:hover, .wordpress-content .is-propelle-grey-light.is-light.wp-block-button__link:hover, .button.is-propelle-grey-light.is-light.is-hovered, .wordpress-content .is-propelle-grey-light.is-light.is-hovered.wp-block-button__link {
  color: #944a00;
  background-color: #fff2e4;
  border-color: #0000;
}

.button.is-propelle-grey-light.is-light:active, .wordpress-content .is-propelle-grey-light.is-light.wp-block-button__link:active, .button.is-propelle-grey-light.is-light.is-active, .wordpress-content .is-propelle-grey-light.is-light.is-active.wp-block-button__link {
  color: #944a00;
  background-color: #ffebd8;
  border-color: #0000;
}

.button.is-default-body, .wordpress-content .is-default-body.wp-block-button__link {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
  border-color: #0000;
}

.button.is-default-body:hover, .wordpress-content .is-default-body.wp-block-button__link:hover, .button.is-default-body.is-hovered, .wordpress-content .is-default-body.is-hovered.wp-block-button__link {
  color: findColorInvert(#4a4a4a);
  background-color: #444;
  border-color: #0000;
}

.button.is-default-body:focus, .wordpress-content .is-default-body.wp-block-button__link:focus, .button.is-default-body.is-focused, .wordpress-content .is-default-body.is-focused.wp-block-button__link {
  color: findColorInvert(#4a4a4a);
  border-color: #0000;
}

.button.is-default-body:focus:not(:active), .wordpress-content .is-default-body.wp-block-button__link:focus:not(:active), .button.is-default-body.is-focused:not(:active), .wordpress-content .is-default-body.is-focused.wp-block-button__link:not(:active) {
  box-shadow: 0 0 0 .125em #4a4a4a40;
}

.button.is-default-body:active, .wordpress-content .is-default-body.wp-block-button__link:active, .button.is-default-body.is-active, .wordpress-content .is-default-body.is-active.wp-block-button__link {
  color: findColorInvert(#4a4a4a);
  background-color: #3d3d3d;
  border-color: #0000;
}

.button.is-default-body[disabled], .wordpress-content .is-default-body[disabled].wp-block-button__link, fieldset[disabled] .button.is-default-body, fieldset[disabled] .wordpress-content .is-default-body.wp-block-button__link, .wordpress-content fieldset[disabled] .is-default-body.wp-block-button__link {
  box-shadow: none;
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

.button.is-default-body.is-inverted, .wordpress-content .is-default-body.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#4a4a4a);
  color: #4a4a4a;
}

.button.is-default-body.is-inverted:hover, .wordpress-content .is-default-body.is-inverted.wp-block-button__link:hover, .button.is-default-body.is-inverted.is-hovered, .wordpress-content .is-default-body.is-inverted.is-hovered.wp-block-button__link {
  background-color: findColorInvert(#4a4a4a);
}

.button.is-default-body.is-inverted[disabled], .wordpress-content .is-default-body.is-inverted[disabled].wp-block-button__link, fieldset[disabled] .button.is-default-body.is-inverted, fieldset[disabled] .wordpress-content .is-default-body.is-inverted.wp-block-button__link, .wordpress-content fieldset[disabled] .is-default-body.is-inverted.wp-block-button__link {
  background-color: findColorInvert(#4a4a4a);
  box-shadow: none;
  color: #4a4a4a;
  border-color: #0000;
}

.button.is-default-body.is-loading:after, .wordpress-content .is-default-body.is-loading.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#4a4a4a) findColorInvert(#4a4a4a) !important;
}

.button.is-default-body.is-outlined, .wordpress-content .is-default-body.is-outlined.wp-block-button__link {
  color: #4a4a4a;
  background-color: #0000;
  border-color: #4a4a4a;
}

.button.is-default-body.is-outlined:hover, .wordpress-content .is-default-body.is-outlined.wp-block-button__link:hover, .button.is-default-body.is-outlined.is-hovered, .wordpress-content .is-default-body.is-outlined.is-hovered.wp-block-button__link, .button.is-default-body.is-outlined:focus, .wordpress-content .is-default-body.is-outlined.wp-block-button__link:focus, .button.is-default-body.is-outlined.is-focused, .wordpress-content .is-default-body.is-outlined.is-focused.wp-block-button__link {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

.button.is-default-body.is-outlined.is-loading:after, .wordpress-content .is-default-body.is-outlined.is-loading.wp-block-button__link:after {
  border-color: #0000 #0000 #4a4a4a #4a4a4a !important;
}

.button.is-default-body.is-outlined.is-loading:hover:after, .wordpress-content .is-default-body.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-default-body.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-default-body.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-default-body.is-outlined.is-loading:focus:after, .wordpress-content .is-default-body.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-default-body.is-outlined.is-loading.is-focused:after, .wordpress-content .is-default-body.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: transparent transparent findColorInvert(#4a4a4a) findColorInvert(#4a4a4a) !important;
}

.button.is-default-body.is-outlined[disabled], .wordpress-content .is-default-body.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-default-body.is-outlined, fieldset[disabled] .wordpress-content .is-default-body.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-default-body.is-outlined.wp-block-button__link {
  box-shadow: none;
  color: #4a4a4a;
  background-color: #0000;
  border-color: #4a4a4a;
}

.button.is-default-body.is-inverted.is-outlined, .wordpress-content .is-default-body.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#4a4a4a);
  color: findColorInvert(#4a4a4a);
  background-color: #0000;
}

.button.is-default-body.is-inverted.is-outlined:hover, .wordpress-content .is-default-body.is-inverted.is-outlined.wp-block-button__link:hover, .button.is-default-body.is-inverted.is-outlined.is-hovered, .wordpress-content .is-default-body.is-inverted.is-outlined.is-hovered.wp-block-button__link, .button.is-default-body.is-inverted.is-outlined:focus, .wordpress-content .is-default-body.is-inverted.is-outlined.wp-block-button__link:focus, .button.is-default-body.is-inverted.is-outlined.is-focused, .wordpress-content .is-default-body.is-inverted.is-outlined.is-focused.wp-block-button__link {
  background-color: findColorInvert(#4a4a4a);
  color: #4a4a4a;
}

.button.is-default-body.is-inverted.is-outlined.is-loading:hover:after, .wordpress-content .is-default-body.is-inverted.is-outlined.is-loading.wp-block-button__link:hover:after, .button.is-default-body.is-inverted.is-outlined.is-loading.is-hovered:after, .wordpress-content .is-default-body.is-inverted.is-outlined.is-loading.is-hovered.wp-block-button__link:after, .button.is-default-body.is-inverted.is-outlined.is-loading:focus:after, .wordpress-content .is-default-body.is-inverted.is-outlined.is-loading.wp-block-button__link:focus:after, .button.is-default-body.is-inverted.is-outlined.is-loading.is-focused:after, .wordpress-content .is-default-body.is-inverted.is-outlined.is-loading.is-focused.wp-block-button__link:after {
  border-color: #0000 #0000 #4a4a4a #4a4a4a !important;
}

.button.is-default-body.is-inverted.is-outlined[disabled], .wordpress-content .is-default-body.is-inverted.is-outlined[disabled].wp-block-button__link, fieldset[disabled] .button.is-default-body.is-inverted.is-outlined, fieldset[disabled] .wordpress-content .is-default-body.is-inverted.is-outlined.wp-block-button__link, .wordpress-content fieldset[disabled] .is-default-body.is-inverted.is-outlined.wp-block-button__link {
  border-color: findColorInvert(#4a4a4a);
  box-shadow: none;
  color: findColorInvert(#4a4a4a);
  background-color: #0000;
}

.button.is-default-body.is-light, .wordpress-content .is-default-body.is-light.wp-block-button__link {
  color: #828282;
  background-color: #f5f5f5;
}

.button.is-default-body.is-light:hover, .wordpress-content .is-default-body.is-light.wp-block-button__link:hover, .button.is-default-body.is-light.is-hovered, .wordpress-content .is-default-body.is-light.is-hovered.wp-block-button__link {
  color: #828282;
  background-color: #eee;
  border-color: #0000;
}

.button.is-default-body.is-light:active, .wordpress-content .is-default-body.is-light.wp-block-button__link:active, .button.is-default-body.is-light.is-active, .wordpress-content .is-default-body.is-light.is-active.wp-block-button__link {
  color: #828282;
  background-color: #e8e8e8;
  border-color: #0000;
}

.button.is-small, .wordpress-content .is-small.wp-block-button__link {
  font-size: 14px;
}

.button.is-small:not(.is-rounded):not(.is-rounded-top):not(.is-rounded-bottom), .wordpress-content .is-small.wp-block-button__link:not(.is-rounded):not(.is-rounded-top):not(.is-rounded-bottom) {
  border-radius: 2px;
}

.button.is-normal, .wordpress-content .is-normal.wp-block-button__link {
  font-size: 16px;
}

.button.is-medium, .wordpress-content .is-medium.wp-block-button__link {
  font-size: 18px;
}

.button.is-large, .wordpress-content .is-large.wp-block-button__link {
  font-size: 25px;
}

.button[disabled], .wordpress-content [disabled].wp-block-button__link, fieldset[disabled] .button, fieldset[disabled] .wordpress-content .wp-block-button__link, .wordpress-content fieldset[disabled] .wp-block-button__link {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #dbdbdb;
}

.button.is-fullwidth, .wordpress-content .is-fullwidth.wp-block-button__link {
  width: 100%;
  display: flex;
}

.button.is-loading, .wordpress-content .is-loading.wp-block-button__link {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after, .wordpress-content .is-loading.wp-block-button__link:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static, .wordpress-content .is-static.wp-block-button__link {
  color: #cdcdcd;
  box-shadow: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.button.is-rounded, .wordpress-content .is-rounded.wp-block-button__link, .button.is-rounded-top, .wordpress-content .is-rounded-top.wp-block-button__link, .button.is-rounded-bottom, .wordpress-content .is-rounded-bottom.wp-block-button__link {
  border-radius: 9999px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button, .buttons .wordpress-content .wp-block-button__link, .wordpress-content .buttons .wp-block-button__link {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth), .buttons .wordpress-content .wp-block-button__link:not(:last-child):not(.is-fullwidth), .wordpress-content .buttons .wp-block-button__link:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large), .buttons.are-small .wordpress-content .wp-block-button__link:not(.is-normal):not(.is-medium):not(.is-large), .wordpress-content .buttons.are-small .wp-block-button__link:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: 14px;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded):not(.is-rounded-top):not(.is-rounded-bottom), .buttons.are-small .wordpress-content .wp-block-button__link:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded):not(.is-rounded-top):not(.is-rounded-bottom), .wordpress-content .buttons.are-small .wp-block-button__link:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded):not(.is-rounded-top):not(.is-rounded-bottom) {
  border-radius: 2px;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large), .buttons.are-medium .wordpress-content .wp-block-button__link:not(.is-small):not(.is-normal):not(.is-large), .wordpress-content .buttons.are-medium .wp-block-button__link:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 18px;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium), .buttons.are-large .wordpress-content .wp-block-button__link:not(.is-small):not(.is-normal):not(.is-medium), .wordpress-content .buttons.are-large .wp-block-button__link:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 25px;
}

.buttons.has-addons .button:not(:first-child), .buttons.has-addons .wordpress-content .wp-block-button__link:not(:first-child), .wordpress-content .buttons.has-addons .wp-block-button__link:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child), .buttons.has-addons .wordpress-content .wp-block-button__link:not(:last-child), .wordpress-content .buttons.has-addons .wp-block-button__link:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child, .buttons.has-addons .wordpress-content .wp-block-button__link:last-child, .wordpress-content .buttons.has-addons .wp-block-button__link:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .wordpress-content .wp-block-button__link:hover, .wordpress-content .buttons.has-addons .wp-block-button__link:hover, .buttons.has-addons .button.is-hovered, .buttons.has-addons .wordpress-content .is-hovered.wp-block-button__link, .wordpress-content .buttons.has-addons .is-hovered.wp-block-button__link {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .wordpress-content .wp-block-button__link:focus, .wordpress-content .buttons.has-addons .wp-block-button__link:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .wordpress-content .is-focused.wp-block-button__link, .wordpress-content .buttons.has-addons .is-focused.wp-block-button__link, .buttons.has-addons .button:active, .buttons.has-addons .wordpress-content .wp-block-button__link:active, .wordpress-content .buttons.has-addons .wp-block-button__link:active, .buttons.has-addons .button.is-active, .buttons.has-addons .wordpress-content .is-active.wp-block-button__link, .wordpress-content .buttons.has-addons .is-active.wp-block-button__link, .buttons.has-addons .button.is-selected, .buttons.has-addons .wordpress-content .is-selected.wp-block-button__link, .wordpress-content .buttons.has-addons .is-selected.wp-block-button__link {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .wordpress-content .wp-block-button__link:focus:hover, .wordpress-content .buttons.has-addons .wp-block-button__link:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .wordpress-content .is-focused.wp-block-button__link:hover, .wordpress-content .buttons.has-addons .is-focused.wp-block-button__link:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .wordpress-content .wp-block-button__link:active:hover, .wordpress-content .buttons.has-addons .wp-block-button__link:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .wordpress-content .is-active.wp-block-button__link:hover, .wordpress-content .buttons.has-addons .is-active.wp-block-button__link:hover, .buttons.has-addons .button.is-selected:hover, .buttons.has-addons .wordpress-content .is-selected.wp-block-button__link:hover, .wordpress-content .buttons.has-addons .is-selected.wp-block-button__link:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded, .buttons.has-addons .wordpress-content .is-expanded.wp-block-button__link, .wordpress-content .buttons.has-addons .is-expanded.wp-block-button__link {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth), .buttons.is-centered:not(.has-addons) .wordpress-content .wp-block-button__link:not(.is-fullwidth), .wordpress-content .buttons.is-centered:not(.has-addons) .wp-block-button__link:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth), .buttons.is-right:not(.has-addons) .wordpress-content .wp-block-button__link:not(.is-fullwidth), .wordpress-content .buttons.is-right:not(.has-addons) .wp-block-button__link:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

@media screen and (width <= 768px) {
  .button.is-responsive.is-small, .wordpress-content .is-responsive.is-small.wp-block-button__link {
    font-size: 10.5px;
  }

  .button.is-responsive, .wordpress-content .is-responsive.wp-block-button__link, .button.is-responsive.is-normal {
    font-size: 12.25px;
  }

  .button.is-responsive.is-medium, .wordpress-content .is-responsive.is-medium.wp-block-button__link {
    font-size: 14px;
  }

  .button.is-responsive.is-large, .wordpress-content .is-responsive.is-large.wp-block-button__link {
    font-size: 16px;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .button.is-responsive.is-small, .wordpress-content .is-responsive.is-small.wp-block-button__link {
    font-size: 12.25px;
  }

  .button.is-responsive, .wordpress-content .is-responsive.wp-block-button__link, .button.is-responsive.is-normal {
    font-size: 14px;
  }

  .button.is-responsive.is-medium, .wordpress-content .is-responsive.is-medium.wp-block-button__link {
    font-size: 16px;
  }

  .button.is-responsive.is-large, .wordpress-content .is-responsive.is-large.wp-block-button__link {
    font-size: 18px;
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #f5f5f5;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #363636;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: 14px;
}

.content.is-normal {
  font-size: 16px;
}

.content.is-medium {
  font-size: 18px;
}

.content.is-large {
  font-size: 25px;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
}

.icon.is-small {
  width: 1rem;
  height: 1rem;
}

.icon.is-medium {
  width: 2rem;
  height: 2rem;
}

.icon.is-large {
  width: 3rem;
  height: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image img.is-rounded, .image img.is-rounded-top, .image img.is-rounded-bottom {
  border-radius: 9999px;
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  width: 100%;
  height: 100%;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.notification {
  background-color: #fff;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.notification.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.notification.is-dark {
  color: #fff;
  background-color: #363636;
}

.notification.is-primary, .wordpress-content .notification.wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
}

.notification.is-primary.is-light, .wordpress-content .notification.is-light.wp-block-button__link {
  color: #2222f1;
  background-color: #ececfe;
}

.notification.is-link {
  color: #fff;
  background-color: #0c0cbd;
}

.notification.is-link.is-light {
  color: #2222f1;
  background-color: #ececfe;
}

.notification.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.notification.is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.notification.is-success {
  color: #fff;
  background-color: #48c78e;
}

.notification.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.notification.is-warning {
  color: #000000b3;
  background-color: #ffb938;
}

.notification.is-warning.is-light {
  color: #946000;
  background-color: #fff8eb;
}

.notification.is-danger {
  color: #fff;
  background-color: #ea3f3f;
}

.notification.is-danger.is-light {
  color: #ca1616;
  background-color: #fdeded;
}

.notification.is-secondary {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
}

.notification.is-secondary.is-light {
  color: #842b10;
  background-color: #fdf1ed;
}

.notification.is-peach {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
}

.notification.is-peach.is-light {
  color: #99281a;
  background-color: #fcefee;
}

.notification.is-navy {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
}

.notification.is-navy.is-light {
  color: #4f2bf3;
  background-color: #efecfe;
}

.notification.is-propelle-grey-light {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
}

.notification.is-propelle-grey-light.is-light {
  color: #944a00;
  background-color: #fff8f1;
}

.notification.is-default-body {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
}

.notification.is-default-body.is-light {
  color: #828282;
  background-color: #f5f5f5;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 9999px;
  width: 100%;
  height: 16px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #aaa;
}

.progress::-webkit-progress-value {
  background-color: #e9897d;
}

.progress::-moz-progress-bar {
  background-color: #e9897d;
}

.progress::-ms-fill {
  background-color: #e9897d;
  border: none;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fff;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fff;
}

.progress.is-white::-ms-fill {
  background-color: #fff;
}

.progress.is-white:indeterminate {
  background-image: linear-gradient(to right, #fff 30%, #aaa 30%);
}

.progress.is-black::-webkit-progress-value {
  background-color: #0a0a0a;
}

.progress.is-black::-moz-progress-bar {
  background-color: #0a0a0a;
}

.progress.is-black::-ms-fill {
  background-color: #0a0a0a;
}

.progress.is-black:indeterminate {
  background-image: linear-gradient(to right, #0a0a0a 30%, #aaa 30%);
}

.progress.is-light::-webkit-progress-value {
  background-color: #f5f5f5;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f5f5f5;
}

.progress.is-light::-ms-fill {
  background-color: #f5f5f5;
}

.progress.is-light:indeterminate {
  background-image: linear-gradient(to right, #f5f5f5 30%, #aaa 30%);
}

.progress.is-dark::-webkit-progress-value {
  background-color: #363636;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #363636;
}

.progress.is-dark::-ms-fill {
  background-color: #363636;
}

.progress.is-dark:indeterminate {
  background-image: linear-gradient(to right, #363636 30%, #aaa 30%);
}

.progress.is-primary::-webkit-progress-value {
  background-color: #0c0cbd;
}

.wordpress-content .progress.wp-block-button__link::-webkit-progress-value {
  background-color: #0c0cbd;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #0c0cbd;
}

.wordpress-content .progress.wp-block-button__link::-moz-progress-bar {
  background-color: #0c0cbd;
}

.progress.is-primary::-ms-fill {
  background-color: #0c0cbd;
}

.wordpress-content .progress.wp-block-button__link::-ms-fill {
  background-color: #0c0cbd;
}

.progress.is-primary:indeterminate, .wordpress-content .progress.wp-block-button__link:indeterminate {
  background-image: linear-gradient(to right, #0c0cbd 30%, #aaa 30%);
}

.progress.is-link::-webkit-progress-value {
  background-color: #0c0cbd;
}

.progress.is-link::-moz-progress-bar {
  background-color: #0c0cbd;
}

.progress.is-link::-ms-fill {
  background-color: #0c0cbd;
}

.progress.is-link:indeterminate {
  background-image: linear-gradient(to right, #0c0cbd 30%, #aaa 30%);
}

.progress.is-info::-webkit-progress-value {
  background-color: #3e8ed0;
}

.progress.is-info::-moz-progress-bar {
  background-color: #3e8ed0;
}

.progress.is-info::-ms-fill {
  background-color: #3e8ed0;
}

.progress.is-info:indeterminate {
  background-image: linear-gradient(to right, #3e8ed0 30%, #aaa 30%);
}

.progress.is-success::-webkit-progress-value {
  background-color: #48c78e;
}

.progress.is-success::-moz-progress-bar {
  background-color: #48c78e;
}

.progress.is-success::-ms-fill {
  background-color: #48c78e;
}

.progress.is-success:indeterminate {
  background-image: linear-gradient(to right, #48c78e 30%, #aaa 30%);
}

.progress.is-warning::-webkit-progress-value {
  background-color: #ffb938;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #ffb938;
}

.progress.is-warning::-ms-fill {
  background-color: #ffb938;
}

.progress.is-warning:indeterminate {
  background-image: linear-gradient(to right, #ffb938 30%, #aaa 30%);
}

.progress.is-danger::-webkit-progress-value {
  background-color: #ea3f3f;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #ea3f3f;
}

.progress.is-danger::-ms-fill {
  background-color: #ea3f3f;
}

.progress.is-danger:indeterminate {
  background-image: linear-gradient(to right, #ea3f3f 30%, #aaa 30%);
}

.progress.is-secondary::-webkit-progress-value {
  background-color: #f5bdac;
}

.progress.is-secondary::-moz-progress-bar {
  background-color: #f5bdac;
}

.progress.is-secondary::-ms-fill {
  background-color: #f5bdac;
}

.progress.is-secondary:indeterminate {
  background-image: linear-gradient(to right, #f5bdac 30%, #aaa 30%);
}

.progress.is-peach::-webkit-progress-value {
  background-color: #e9897d;
}

.progress.is-peach::-moz-progress-bar {
  background-color: #e9897d;
}

.progress.is-peach::-ms-fill {
  background-color: #e9897d;
}

.progress.is-peach:indeterminate {
  background-image: linear-gradient(to right, #e9897d 30%, #aaa 30%);
}

.progress.is-navy::-webkit-progress-value {
  background-color: #1b0777;
}

.progress.is-navy::-moz-progress-bar {
  background-color: #1b0777;
}

.progress.is-navy::-ms-fill {
  background-color: #1b0777;
}

.progress.is-navy:indeterminate {
  background-image: linear-gradient(to right, #1b0777 30%, #aaa 30%);
}

.progress.is-propelle-grey-light::-webkit-progress-value {
  background-color: #fff8f1;
}

.progress.is-propelle-grey-light::-moz-progress-bar {
  background-color: #fff8f1;
}

.progress.is-propelle-grey-light::-ms-fill {
  background-color: #fff8f1;
}

.progress.is-propelle-grey-light:indeterminate {
  background-image: linear-gradient(to right, #fff8f1 30%, #aaa 30%);
}

.progress.is-default-body::-webkit-progress-value {
  background-color: #4a4a4a;
}

.progress.is-default-body::-moz-progress-bar {
  background-color: #4a4a4a;
}

.progress.is-default-body::-ms-fill {
  background-color: #4a4a4a;
}

.progress.is-default-body:indeterminate {
  background-image: linear-gradient(to right, #4a4a4a 30%, #aaa 30%);
}

.progress:indeterminate {
  background-color: #aaa;
  background-image: linear-gradient(to right, #4a4a4a 30%, #aaa 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: moveIndeterminate;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

.progress.is-small {
  height: 14px;
}

.progress.is-medium {
  height: 18px;
}

.progress.is-large {
  height: 25px;
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.table td.is-light, .table th.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.table td.is-primary, .table .wordpress-content td.wp-block-button__link, .wordpress-content .table td.wp-block-button__link, .table th.is-primary, .table .wordpress-content th.wp-block-button__link, .wordpress-content .table th.wp-block-button__link, .table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #ffb938;
  border-color: #ffb938;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #ea3f3f;
  border-color: #ea3f3f;
}

.table td.is-secondary, .table th.is-secondary {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
  border-color: #f5bdac;
}

.table td.is-peach, .table th.is-peach {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
  border-color: #e9897d;
}

.table td.is-navy, .table th.is-navy {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
  border-color: #1b0777;
}

.table td.is-propelle-grey-light, .table th.is-propelle-grey-light {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
  border-color: #fff8f1;
}

.table td.is-default-body, .table th.is-default-body {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #0c0cbd;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #0c0cbd;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: #0000;
}

.table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: #0000;
}

.table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: #0000;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto hidden;
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 16px;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 18px;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #4a4a4a;
  white-space: nowrap;
  background-color: #f5f5f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: .75em;
  padding-right: .75em;
  font-size: 14px;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.tag:not(body).is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.tag:not(body).is-dark {
  color: #fff;
  background-color: #363636;
}

.tag:not(body).is-primary, .wordpress-content .tag.wp-block-button__link:not(body) {
  color: #fff;
  background-color: #0c0cbd;
}

.tag:not(body).is-primary.is-light, .wordpress-content .tag.wp-block-button__link:not(body).is-light {
  color: #2222f1;
  background-color: #ececfe;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #0c0cbd;
}

.tag:not(body).is-link.is-light {
  color: #2222f1;
  background-color: #ececfe;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.tag:not(body).is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.tag:not(body).is-success {
  color: #fff;
  background-color: #48c78e;
}

.tag:not(body).is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.tag:not(body).is-warning {
  color: #000000b3;
  background-color: #ffb938;
}

.tag:not(body).is-warning.is-light {
  color: #946000;
  background-color: #fff8eb;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #ea3f3f;
}

.tag:not(body).is-danger.is-light {
  color: #ca1616;
  background-color: #fdeded;
}

.tag:not(body).is-secondary {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
}

.tag:not(body).is-secondary.is-light {
  color: #842b10;
  background-color: #fdf1ed;
}

.tag:not(body).is-peach {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
}

.tag:not(body).is-peach.is-light {
  color: #99281a;
  background-color: #fcefee;
}

.tag:not(body).is-navy {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
}

.tag:not(body).is-navy.is-light {
  color: #4f2bf3;
  background-color: #efecfe;
}

.tag:not(body).is-propelle-grey-light {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
}

.tag:not(body).is-propelle-grey-light.is-light {
  color: #944a00;
  background-color: #fff8f1;
}

.tag:not(body).is-default-body {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
}

.tag:not(body).is-default-body.is-light {
  color: #828282;
  background-color: #f5f5f5;
}

.tag:not(body).is-normal {
  font-size: 14px;
}

.tag:not(body).is-medium {
  font-size: 16px;
}

.tag:not(body).is-large {
  font-size: 18px;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  width: 50%;
  height: 1px;
}

.tag:not(body).is-delete:after {
  width: 1px;
  height: 50%;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #e8e8e8;
}

.tag:not(body).is-delete:active {
  background-color: #dbdbdb;
}

.tag:not(body).is-rounded, .tag.is-rounded-top:not(body), .tag.is-rounded-bottom:not(body) {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 50px;
}

.title.is-2 {
  font-size: 40px;
}

.title.is-3 {
  font-size: 30px;
}

.title.is-4 {
  font-size: 25px;
}

.title.is-5 {
  font-size: 18px;
}

.title.is-6 {
  font-size: 16px;
}

.title.is-7 {
  font-size: 14px;
}

.subtitle {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 50px;
}

.subtitle.is-2 {
  font-size: 40px;
}

.subtitle.is-3 {
  font-size: 30px;
}

.subtitle.is-4 {
  font-size: 25px;
}

.subtitle.is-5 {
  font-size: 18px;
}

.subtitle.is-6 {
  font-size: 16px;
}

.subtitle.is-7 {
  font-size: 14px;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #f5f5f5;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 18px;
  display: inline-flex;
}

.select select, .textarea, .input {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.select select::-moz-placeholder {
  color: #3636364d;
}

.textarea::-moz-placeholder {
  color: #3636364d;
}

.input::-moz-placeholder {
  color: #3636364d;
}

.select select::-webkit-input-placeholder {
  color: #3636364d;
}

.textarea::-webkit-input-placeholder {
  color: #3636364d;
}

.input::-webkit-input-placeholder {
  color: #3636364d;
}

.select select:-moz-placeholder {
  color: #3636364d;
}

.textarea:-moz-placeholder {
  color: #3636364d;
}

.input:-moz-placeholder {
  color: #3636364d;
}

.select select:-ms-input-placeholder {
  color: #3636364d;
}

.textarea:-ms-input-placeholder {
  color: #3636364d;
}

.input:-ms-input-placeholder {
  color: #3636364d;
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: #fff8f1;
}

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  border-color: #0c0cbd;
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #cdcdcd;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.select select[disabled]::-moz-placeholder {
  color: #cdcdcd4d;
}

[disabled].textarea::-moz-placeholder {
  color: #cdcdcd4d;
}

[disabled].input::-moz-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .select select::-moz-placeholder {
  color: #cdcdcd4d;
}

.select fieldset[disabled] select::-moz-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .textarea::-moz-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .input::-moz-placeholder {
  color: #cdcdcd4d;
}

.select select[disabled]::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

[disabled].textarea::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

[disabled].input::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .select select::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

.select fieldset[disabled] select::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .input::-webkit-input-placeholder {
  color: #cdcdcd4d;
}

.select select[disabled]:-moz-placeholder {
  color: #cdcdcd4d;
}

[disabled].textarea:-moz-placeholder {
  color: #cdcdcd4d;
}

[disabled].input:-moz-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .select select:-moz-placeholder {
  color: #cdcdcd4d;
}

.select fieldset[disabled] select:-moz-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .textarea:-moz-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .input:-moz-placeholder {
  color: #cdcdcd4d;
}

.select select[disabled]:-ms-input-placeholder {
  color: #cdcdcd4d;
}

[disabled].textarea:-ms-input-placeholder {
  color: #cdcdcd4d;
}

[disabled].input:-ms-input-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .select select:-ms-input-placeholder {
  color: #cdcdcd4d;
}

.select fieldset[disabled] select:-ms-input-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .textarea:-ms-input-placeholder {
  color: #cdcdcd4d;
}

fieldset[disabled] .input:-ms-input-placeholder {
  color: #cdcdcd4d;
}

.textarea, .input {
  width: 100%;
  max-width: 100%;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.is-black.textarea, .is-black.input {
  border-color: #0a0a0a;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.is-light.textarea, .is-light.input {
  border-color: #f5f5f5;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.is-dark.textarea, .is-dark.input {
  border-color: #363636;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em #36363640;
}

.is-primary.textarea, .wordpress-content .textarea.wp-block-button__link, .is-primary.input, .wordpress-content .input.wp-block-button__link {
  border-color: #0c0cbd;
}

.is-primary.textarea:focus, .wordpress-content .textarea.wp-block-button__link:focus, .is-primary.input:focus, .wordpress-content .input.wp-block-button__link:focus, .is-primary.is-focused.textarea, .wordpress-content .is-focused.textarea.wp-block-button__link, .is-primary.is-focused.input, .wordpress-content .is-focused.input.wp-block-button__link, .is-primary.textarea:active, .wordpress-content .textarea.wp-block-button__link:active, .is-primary.input:active, .wordpress-content .input.wp-block-button__link:active, .is-primary.is-active.textarea, .wordpress-content .is-active.textarea.wp-block-button__link, .is-primary.is-active.input, .wordpress-content .is-active.input.wp-block-button__link {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.is-link.textarea, .is-link.input {
  border-color: #0c0cbd;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.is-info.textarea, .is-info.input {
  border-color: #3e8ed0;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.is-success.textarea, .is-success.input {
  border-color: #48c78e;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.is-warning.textarea, .is-warning.input {
  border-color: #ffb938;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em #ffb93840;
}

.is-danger.textarea, .is-danger.input {
  border-color: #ea3f3f;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em #ea3f3f40;
}

.is-secondary.textarea, .is-secondary.input {
  border-color: #f5bdac;
}

.is-secondary.textarea:focus, .is-secondary.input:focus, .is-secondary.is-focused.textarea, .is-secondary.is-focused.input, .is-secondary.textarea:active, .is-secondary.input:active, .is-secondary.is-active.textarea, .is-secondary.is-active.input {
  box-shadow: 0 0 0 .125em #f5bdac40;
}

.is-peach.textarea, .is-peach.input {
  border-color: #e9897d;
}

.is-peach.textarea:focus, .is-peach.input:focus, .is-peach.is-focused.textarea, .is-peach.is-focused.input, .is-peach.textarea:active, .is-peach.input:active, .is-peach.is-active.textarea, .is-peach.is-active.input {
  box-shadow: 0 0 0 .125em #e9897d40;
}

.is-navy.textarea, .is-navy.input {
  border-color: #1b0777;
}

.is-navy.textarea:focus, .is-navy.input:focus, .is-navy.is-focused.textarea, .is-navy.is-focused.input, .is-navy.textarea:active, .is-navy.input:active, .is-navy.is-active.textarea, .is-navy.is-active.input {
  box-shadow: 0 0 0 .125em #1b077740;
}

.is-propelle-grey-light.textarea, .is-propelle-grey-light.input {
  border-color: #fff8f1;
}

.is-propelle-grey-light.textarea:focus, .is-propelle-grey-light.input:focus, .is-propelle-grey-light.is-focused.textarea, .is-propelle-grey-light.is-focused.input, .is-propelle-grey-light.textarea:active, .is-propelle-grey-light.input:active, .is-propelle-grey-light.is-active.textarea, .is-propelle-grey-light.is-active.input {
  box-shadow: 0 0 0 .125em #fff8f140;
}

.is-default-body.textarea, .is-default-body.input {
  border-color: #4a4a4a;
}

.is-default-body.textarea:focus, .is-default-body.input:focus, .is-default-body.is-focused.textarea, .is-default-body.is-focused.input, .is-default-body.textarea:active, .is-default-body.input:active, .is-default-body.is-active.textarea, .is-default-body.is-active.input {
  box-shadow: 0 0 0 .125em #4a4a4a40;
}

.is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: 14px;
}

.is-medium.textarea, .is-medium.input {
  font-size: 18px;
}

.is-large.textarea, .is-large.input {
  font-size: 25px;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded, .input.is-rounded-top, .input.is-rounded-bottom {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  min-height: 8em;
  max-height: 40em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #363636;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: #cdcdcd;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 2.5em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #0c0cbd;
  right: 1.125em;
}

.select.is-rounded select, .select.is-rounded-top select, .select.is-rounded-bottom select {
  border-radius: 9999px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #f5f5f5;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #363636;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.select.is-black:not(:hover):after, .select.is-black select {
  border-color: #0a0a0a;
}

.select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #000;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f5;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e8e8e8;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #363636;
}

.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #292929;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em #36363640;
}

.select.is-primary:not(:hover):after, .wordpress-content .select.wp-block-button__link:not(:hover):after, .select.is-primary select, .wordpress-content .select.wp-block-button__link select {
  border-color: #0c0cbd;
}

.select.is-primary select:hover, .wordpress-content .select.wp-block-button__link select:hover, .select.is-primary select.is-hovered, .wordpress-content .select.wp-block-button__link select.is-hovered {
  border-color: #0a0aa5;
}

.select.is-primary select:focus, .wordpress-content .select.wp-block-button__link select:focus, .select.is-primary select.is-focused, .wordpress-content .select.wp-block-button__link select.is-focused, .select.is-primary select:active, .wordpress-content .select.wp-block-button__link select:active, .select.is-primary select.is-active, .wordpress-content .select.wp-block-button__link select.is-active {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #0c0cbd;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #0a0aa5;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em #0c0cbd40;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #3e8ed0;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #3082c5;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #48c78e;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #3abb81;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #ffb938;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ffb01f;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em #ffb93840;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #ea3f3f;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #e72828;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em #ea3f3f40;
}

.select.is-secondary:not(:hover):after, .select.is-secondary select {
  border-color: #f5bdac;
}

.select.is-secondary select:hover, .select.is-secondary select.is-hovered {
  border-color: #f2ab95;
}

.select.is-secondary select:focus, .select.is-secondary select.is-focused, .select.is-secondary select:active, .select.is-secondary select.is-active {
  box-shadow: 0 0 0 .125em #f5bdac40;
}

.select.is-peach:not(:hover):after, .select.is-peach select {
  border-color: #e9897d;
}

.select.is-peach select:hover, .select.is-peach select.is-hovered {
  border-color: #e57567;
}

.select.is-peach select:focus, .select.is-peach select.is-focused, .select.is-peach select:active, .select.is-peach select.is-active {
  box-shadow: 0 0 0 .125em #e9897d40;
}

.select.is-navy:not(:hover):after, .select.is-navy select {
  border-color: #1b0777;
}

.select.is-navy select:hover, .select.is-navy select.is-hovered {
  border-color: #16065f;
}

.select.is-navy select:focus, .select.is-navy select.is-focused, .select.is-navy select:active, .select.is-navy select.is-active {
  box-shadow: 0 0 0 .125em #1b077740;
}

.select.is-propelle-grey-light:not(:hover):after, .select.is-propelle-grey-light select {
  border-color: #fff8f1;
}

.select.is-propelle-grey-light select:hover, .select.is-propelle-grey-light select.is-hovered {
  border-color: #ffebd8;
}

.select.is-propelle-grey-light select:focus, .select.is-propelle-grey-light select.is-focused, .select.is-propelle-grey-light select:active, .select.is-propelle-grey-light select.is-active {
  box-shadow: 0 0 0 .125em #fff8f140;
}

.select.is-default-body:not(:hover):after, .select.is-default-body select {
  border-color: #4a4a4a;
}

.select.is-default-body select:hover, .select.is-default-body select.is-hovered {
  border-color: #3d3d3d;
}

.select.is-default-body select:focus, .select.is-default-body select.is-focused, .select.is-default-body select:active, .select.is-default-body select.is-active {
  box-shadow: 0 0 0 .125em #4a4a4a40;
}

.select.is-small {
  border-radius: 2px;
  font-size: 14px;
}

.select.is-medium {
  font-size: 18px;
}

.select.is-large {
  font-size: 25px;
}

.select.is-disabled:after {
  opacity: .5;
  border-color: #cdcdcd !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: 14px;
}

.select.is-loading.is-medium:after {
  font-size: 18px;
}

.select.is-loading.is-large:after {
  font-size: 25px;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #0a0a0a;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #040404;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #0a0a0a40;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #f5f5f540;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #fff;
  background-color: #363636;
  border-color: #0000;
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #36363640;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #292929;
  border-color: #0000;
}

.file.is-primary .file-cta, .wordpress-content .file.wp-block-button__link .file-cta {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .wordpress-content .file.wp-block-button__link:hover .file-cta, .file.is-primary.is-hovered .file-cta, .wordpress-content .file.is-hovered.wp-block-button__link .file-cta {
  color: #fff;
  background-color: #0b0bb1;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .wordpress-content .file.wp-block-button__link:focus .file-cta, .file.is-primary.is-focused .file-cta, .wordpress-content .file.is-focused.wp-block-button__link .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #0c0cbd40;
}

.file.is-primary:active .file-cta, .wordpress-content .file.wp-block-button__link:active .file-cta, .file.is-primary.is-active .file-cta, .wordpress-content .file.is-active.wp-block-button__link .file-cta {
  color: #fff;
  background-color: #0a0aa5;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #0b0bb1;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #0c0cbd40;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #0a0aa5;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #3488ce;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #3e8ed040;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #3082c5;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #48c78e;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fff;
  background-color: #3ec487;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #48c78e40;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fff;
  background-color: #3abb81;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #000000b3;
  background-color: #ffb938;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #000000b3;
  background-color: #ffb52b;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffb93840;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #000000b3;
  background-color: #ffb01f;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #ea3f3f;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #e93434;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #ea3f3f40;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #e72828;
  border-color: #0000;
}

.file.is-secondary .file-cta {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
  border-color: #0000;
}

.file.is-secondary:hover .file-cta, .file.is-secondary.is-hovered .file-cta {
  color: findColorInvert(#f5bdac);
  background-color: #f4b4a1;
  border-color: #0000;
}

.file.is-secondary:focus .file-cta, .file.is-secondary.is-focused .file-cta {
  color: findColorInvert(#f5bdac);
  border-color: #0000;
  box-shadow: 0 0 .5em #f5bdac40;
}

.file.is-secondary:active .file-cta, .file.is-secondary.is-active .file-cta {
  color: findColorInvert(#f5bdac);
  background-color: #f2ab95;
  border-color: #0000;
}

.file.is-peach .file-cta {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
  border-color: #0000;
}

.file.is-peach:hover .file-cta, .file.is-peach.is-hovered .file-cta {
  color: findColorInvert(#e9897d);
  background-color: #e77f72;
  border-color: #0000;
}

.file.is-peach:focus .file-cta, .file.is-peach.is-focused .file-cta {
  color: findColorInvert(#e9897d);
  border-color: #0000;
  box-shadow: 0 0 .5em #e9897d40;
}

.file.is-peach:active .file-cta, .file.is-peach.is-active .file-cta {
  color: findColorInvert(#e9897d);
  background-color: #e57567;
  border-color: #0000;
}

.file.is-navy .file-cta {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
  border-color: #0000;
}

.file.is-navy:hover .file-cta, .file.is-navy.is-hovered .file-cta {
  color: findColorInvert(#1b0777);
  background-color: #18066b;
  border-color: #0000;
}

.file.is-navy:focus .file-cta, .file.is-navy.is-focused .file-cta {
  color: findColorInvert(#1b0777);
  border-color: #0000;
  box-shadow: 0 0 .5em #1b077740;
}

.file.is-navy:active .file-cta, .file.is-navy.is-active .file-cta {
  color: findColorInvert(#1b0777);
  background-color: #16065f;
  border-color: #0000;
}

.file.is-propelle-grey-light .file-cta {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
  border-color: #0000;
}

.file.is-propelle-grey-light:hover .file-cta, .file.is-propelle-grey-light.is-hovered .file-cta {
  color: findColorInvert(#fff8f1);
  background-color: #fff2e4;
  border-color: #0000;
}

.file.is-propelle-grey-light:focus .file-cta, .file.is-propelle-grey-light.is-focused .file-cta {
  color: findColorInvert(#fff8f1);
  border-color: #0000;
  box-shadow: 0 0 .5em #fff8f140;
}

.file.is-propelle-grey-light:active .file-cta, .file.is-propelle-grey-light.is-active .file-cta {
  color: findColorInvert(#fff8f1);
  background-color: #ffebd8;
  border-color: #0000;
}

.file.is-default-body .file-cta {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
  border-color: #0000;
}

.file.is-default-body:hover .file-cta, .file.is-default-body.is-hovered .file-cta {
  color: findColorInvert(#4a4a4a);
  background-color: #444;
  border-color: #0000;
}

.file.is-default-body:focus .file-cta, .file.is-default-body.is-focused .file-cta {
  color: findColorInvert(#4a4a4a);
  border-color: #0000;
  box-shadow: 0 0 .5em #4a4a4a40;
}

.file.is-default-body:active .file-cta, .file.is-default-body.is-active .file-cta {
  color: findColorInvert(#4a4a4a);
  background-color: #3d3d3d;
  border-color: #0000;
}

.file.is-small {
  font-size: 14px;
}

.file.is-normal {
  font-size: 16px;
}

.file.is-medium {
  font-size: 18px;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 25px;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: 4px;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

.file-name {
  text-align: inherit;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: 14px;
}

.label.is-medium {
  font-size: 18px;
}

.label.is-large {
  font-size: 25px;
}

.help {
  margin-top: .25rem;
  font-size: 14px;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: #f5f5f5;
}

.help.is-dark {
  color: #363636;
}

.help.is-primary, .wordpress-content .help.wp-block-button__link, .help.is-link {
  color: #0c0cbd;
}

.help.is-info {
  color: #3e8ed0;
}

.help.is-success {
  color: #48c78e;
}

.help.is-warning {
  color: #ffb938;
}

.help.is-danger {
  color: #ea3f3f;
}

.help.is-secondary {
  color: #f5bdac;
}

.help.is-peach {
  color: #e9897d;
}

.help.is-navy {
  color: #1b0777;
}

.help.is-propelle-grey-light {
  color: #fff8f1;
}

.help.is-default-body {
  color: #4a4a4a;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .wordpress-content .wp-block-button__link, .wordpress-content .field.has-addons .control:not(:first-child):not(:last-child) .wp-block-button__link, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .wordpress-content .wp-block-button__link, .wordpress-content .field.has-addons .control:first-child:not(:only-child) .wp-block-button__link, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .wordpress-content .wp-block-button__link, .wordpress-content .field.has-addons .control:last-child:not(:only-child) .wp-block-button__link, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]):hover, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]).is-hovered, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]):focus, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]).is-focused, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]):active, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]).is-active, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]):focus:hover, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]).is-focused:hover, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]):active:hover, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .wordpress-content .wp-block-button__link:not([disabled]).is-active:hover, .wordpress-content .field.has-addons .control .wp-block-button__link:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: 14px;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 18px;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 25px;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: 16px;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #4a4a4a;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: 14px;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 18px;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 25px;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  pointer-events: none;
  z-index: 4;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.5em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: 14px;
}

.control.is-loading.is-medium:after {
  font-size: 18px;
}

.control.is-loading.is-large:after {
  font-size: 25px;
}

.breadcrumb {
  white-space: nowrap;
  font-size: 16px;
}

.breadcrumb a {
  color: #0c0cbd;
  justify-content: center;
  align-items: center;
  padding: 0 .75em;
  display: flex;
}

.breadcrumb a:hover {
  color: #363636;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #363636;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #fff8f1;
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: 14px;
}

.breadcrumb.is-medium {
  font-size: 18px;
}

.breadcrumb.is-large {
  font-size: 25px;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 10px;
  max-width: 100%;
  position: relative;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.card-header {
  background-color: #0000;
  align-items: stretch;
  display: flex;
  box-shadow: 0 .125em .25em #0a0a0a1a;
}

.card-header-title {
  color: #363636;
  flex-grow: 1;
  align-items: center;
  padding: .75rem 1rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-image:last-child img {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.card-content {
  background-color: #0000;
  padding: 1.5rem;
}

.card-footer {
  background-color: #0000;
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #ededed;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  z-index: 20;
  min-width: 12rem;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.dropdown-item {
  color: #4a4a4a;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

a.dropdown-item, button.dropdown-item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  padding-right: 3rem;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  color: #0a0a0a;
  background-color: #f5f5f5;
}

a.dropdown-item.is-active, button.dropdown-item.is-active {
  color: #fff;
  background-color: #0c0cbd;
}

.dropdown-divider {
  background-color: #ededed;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (width <= 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (width <= 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (width >= 769px), print {
  .level-right {
    display: flex;
  }
}

.media {
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #dbdbdb80;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #dbdbdb80;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (width <= 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.menu {
  font-size: 16px;
}

.menu.is-small {
  font-size: 14px;
}

.menu.is-medium {
  font-size: 18px;
}

.menu.is-large {
  font-size: 25px;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #4a4a4a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #0c0cbd;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #cdcdcd;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 16px;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: 14px;
}

.message.is-medium {
  font-size: 18px;
}

.message.is-large {
  font-size: 25px;
}

.message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #0a0a0a;
  background-color: #fff;
}

.message.is-white .message-body {
  border-color: #fff;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #0a0a0a;
}

.message.is-black .message-body {
  border-color: #0a0a0a;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: #000000b3;
  background-color: #f5f5f5;
}

.message.is-light .message-body {
  border-color: #f5f5f5;
}

.message.is-dark {
  background-color: #fafafa;
}

.message.is-dark .message-header {
  color: #fff;
  background-color: #363636;
}

.message.is-dark .message-body {
  border-color: #363636;
}

.message.is-primary, .wordpress-content .message.wp-block-button__link {
  background-color: #ececfe;
}

.message.is-primary .message-header, .wordpress-content .message.wp-block-button__link .message-header {
  color: #fff;
  background-color: #0c0cbd;
}

.message.is-primary .message-body, .wordpress-content .message.wp-block-button__link .message-body {
  color: #2222f1;
  border-color: #0c0cbd;
}

.message.is-link {
  background-color: #ececfe;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #0c0cbd;
}

.message.is-link .message-body {
  color: #2222f1;
  border-color: #0c0cbd;
}

.message.is-info {
  background-color: #eff5fb;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #3e8ed0;
}

.message.is-info .message-body {
  color: #296fa8;
  border-color: #3e8ed0;
}

.message.is-success {
  background-color: #effaf5;
}

.message.is-success .message-header {
  color: #fff;
  background-color: #48c78e;
}

.message.is-success .message-body {
  color: #257953;
  border-color: #48c78e;
}

.message.is-warning {
  background-color: #fff8eb;
}

.message.is-warning .message-header {
  color: #000000b3;
  background-color: #ffb938;
}

.message.is-warning .message-body {
  color: #946000;
  border-color: #ffb938;
}

.message.is-danger {
  background-color: #fdeded;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #ea3f3f;
}

.message.is-danger .message-body {
  color: #ca1616;
  border-color: #ea3f3f;
}

.message.is-secondary {
  background-color: #fdf1ed;
}

.message.is-secondary .message-header {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
}

.message.is-secondary .message-body {
  color: #842b10;
  border-color: #f5bdac;
}

.message.is-peach {
  background-color: #fcefee;
}

.message.is-peach .message-header {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
}

.message.is-peach .message-body {
  color: #99281a;
  border-color: #e9897d;
}

.message.is-navy {
  background-color: #efecfe;
}

.message.is-navy .message-header {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
}

.message.is-navy .message-body {
  color: #4f2bf3;
  border-color: #1b0777;
}

.message.is-propelle-grey-light {
  background-color: #fff8f1;
}

.message.is-propelle-grey-light .message-header {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
}

.message.is-propelle-grey-light .message-body {
  color: #944a00;
  border-color: #fff8f1;
}

.message.is-default-body {
  background-color: #f5f5f5;
}

.message.is-default-body .message-header {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
}

.message.is-default-body .message-body {
  color: #828282;
  border-color: #4a4a4a;
}

.message-header {
  color: #fff;
  background-color: #4a4a4a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #4a4a4a;
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 1.25em 1.5em;
}

.message-body code, .message-body pre {
  background-color: #fff;
}

.message-body pre code {
  background-color: #0000;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #0a0a0adb;
}

.modal-content, .modal-card {
  width: 100%;
  max-height: calc(100vh - 160px);
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

@media screen and (width >= 769px) {
  .modal-content, .modal-card {
    width: 640px;
    max-height: calc(100vh - 40px);
    margin: 0 auto;
  }
}

.modal-close {
  background: none;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  -ms-overflow-y: visible;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #f5f5f5;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 25px;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.modal-card-foot .button:not(:last-child), .modal-card-foot .wordpress-content .wp-block-button__link:not(:last-child), .wordpress-content .modal-card-foot .wp-block-button__link:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.navbar {
  z-index: 30;
  background-color: #fff;
  min-height: 3.25rem;
  position: relative;
}

.navbar.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #0a0a0a;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #0a0a0a;
}

.navbar.is-white .navbar-burger {
  color: #0a0a0a;
}

@media screen and (width >= 1024px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #0a0a0a;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #0a0a0a;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #0a0a0a;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0a0a0a;
  }
}

.navbar.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-light .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #f5f5f5;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #363636;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #363636;
  }
}

.navbar.is-primary, .wordpress-content .navbar.wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
}

.navbar.is-primary .navbar-brand > .navbar-item, .wordpress-content .navbar.wp-block-button__link .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link, .wordpress-content .navbar.wp-block-button__link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .wordpress-content .navbar.wp-block-button__link .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .wordpress-content .navbar.wp-block-button__link .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .wordpress-content .navbar.wp-block-button__link .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .wordpress-content .navbar.wp-block-button__link .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #0a0aa5;
}

.navbar.is-primary .navbar-brand .navbar-link:after, .wordpress-content .navbar.wp-block-button__link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger, .wordpress-content .navbar.wp-block-button__link .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item, .wordpress-content .navbar.wp-block-button__link .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .wordpress-content .navbar.wp-block-button__link .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .wordpress-content .navbar.wp-block-button__link .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link, .wordpress-content .navbar.wp-block-button__link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .wordpress-content .navbar.wp-block-button__link .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .wordpress-content .navbar.wp-block-button__link .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .wordpress-content .navbar.wp-block-button__link .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .wordpress-content .navbar.wp-block-button__link .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .wordpress-content .navbar.wp-block-button__link .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .wordpress-content .navbar.wp-block-button__link .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .wordpress-content .navbar.wp-block-button__link .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .wordpress-content .navbar.wp-block-button__link .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #0a0aa5;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .wordpress-content .navbar.wp-block-button__link .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after, .wordpress-content .navbar.wp-block-button__link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .wordpress-content .navbar.wp-block-button__link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .wordpress-content .navbar.wp-block-button__link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link, .wordpress-content .navbar.wp-block-button__link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #0a0aa5;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active, .wordpress-content .navbar.wp-block-button__link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0c0cbd;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #0c0cbd;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #0a0aa5;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #0a0aa5;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #0a0aa5;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0c0cbd;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3082c5;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3082c5;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #3e8ed0;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #48c78e;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-success .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #48c78e;
  }
}

.navbar.is-warning {
  color: #000000b3;
  background-color: #ffb938;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffb01f;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-warning .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #ffb01f;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #ffb01f;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #ffb938;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #ea3f3f;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #e72828;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #e72828;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #e72828;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #ea3f3f;
  }
}

.navbar.is-secondary {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
}

.navbar.is-secondary .navbar-brand > .navbar-item, .navbar.is-secondary .navbar-brand .navbar-link {
  color: findColorInvert(#f5bdac);
}

.navbar.is-secondary .navbar-brand > a.navbar-item:focus, .navbar.is-secondary .navbar-brand > a.navbar-item:hover, .navbar.is-secondary .navbar-brand > a.navbar-item.is-active, .navbar.is-secondary .navbar-brand .navbar-link:focus, .navbar.is-secondary .navbar-brand .navbar-link:hover, .navbar.is-secondary .navbar-brand .navbar-link.is-active {
  color: findColorInvert(#f5bdac);
  background-color: #f2ab95;
}

.navbar.is-secondary .navbar-brand .navbar-link:after {
  border-color: findColorInvert(#f5bdac);
}

.navbar.is-secondary .navbar-burger {
  color: findColorInvert(#f5bdac);
}

@media screen and (width >= 1024px) {
  .navbar.is-secondary .navbar-start > .navbar-item, .navbar.is-secondary .navbar-start .navbar-link, .navbar.is-secondary .navbar-end > .navbar-item, .navbar.is-secondary .navbar-end .navbar-link {
    color: findColorInvert(#f5bdac);
  }

  .navbar.is-secondary .navbar-start > a.navbar-item:focus, .navbar.is-secondary .navbar-start > a.navbar-item:hover, .navbar.is-secondary .navbar-start > a.navbar-item.is-active, .navbar.is-secondary .navbar-start .navbar-link:focus, .navbar.is-secondary .navbar-start .navbar-link:hover, .navbar.is-secondary .navbar-start .navbar-link.is-active, .navbar.is-secondary .navbar-end > a.navbar-item:focus, .navbar.is-secondary .navbar-end > a.navbar-item:hover, .navbar.is-secondary .navbar-end > a.navbar-item.is-active, .navbar.is-secondary .navbar-end .navbar-link:focus, .navbar.is-secondary .navbar-end .navbar-link:hover, .navbar.is-secondary .navbar-end .navbar-link.is-active {
    color: findColorInvert(#f5bdac);
    background-color: #f2ab95;
  }

  .navbar.is-secondary .navbar-start .navbar-link:after, .navbar.is-secondary .navbar-end .navbar-link:after {
    border-color: findColorInvert(#f5bdac);
  }

  .navbar.is-secondary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-secondary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-secondary .navbar-item.has-dropdown.is-active .navbar-link {
    color: findColorInvert(#f5bdac);
    background-color: #f2ab95;
  }

  .navbar.is-secondary .navbar-dropdown a.navbar-item.is-active {
    color: findColorInvert(#f5bdac);
    background-color: #f5bdac;
  }
}

.navbar.is-peach {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
}

.navbar.is-peach .navbar-brand > .navbar-item, .navbar.is-peach .navbar-brand .navbar-link {
  color: findColorInvert(#e9897d);
}

.navbar.is-peach .navbar-brand > a.navbar-item:focus, .navbar.is-peach .navbar-brand > a.navbar-item:hover, .navbar.is-peach .navbar-brand > a.navbar-item.is-active, .navbar.is-peach .navbar-brand .navbar-link:focus, .navbar.is-peach .navbar-brand .navbar-link:hover, .navbar.is-peach .navbar-brand .navbar-link.is-active {
  color: findColorInvert(#e9897d);
  background-color: #e57567;
}

.navbar.is-peach .navbar-brand .navbar-link:after {
  border-color: findColorInvert(#e9897d);
}

.navbar.is-peach .navbar-burger {
  color: findColorInvert(#e9897d);
}

@media screen and (width >= 1024px) {
  .navbar.is-peach .navbar-start > .navbar-item, .navbar.is-peach .navbar-start .navbar-link, .navbar.is-peach .navbar-end > .navbar-item, .navbar.is-peach .navbar-end .navbar-link {
    color: findColorInvert(#e9897d);
  }

  .navbar.is-peach .navbar-start > a.navbar-item:focus, .navbar.is-peach .navbar-start > a.navbar-item:hover, .navbar.is-peach .navbar-start > a.navbar-item.is-active, .navbar.is-peach .navbar-start .navbar-link:focus, .navbar.is-peach .navbar-start .navbar-link:hover, .navbar.is-peach .navbar-start .navbar-link.is-active, .navbar.is-peach .navbar-end > a.navbar-item:focus, .navbar.is-peach .navbar-end > a.navbar-item:hover, .navbar.is-peach .navbar-end > a.navbar-item.is-active, .navbar.is-peach .navbar-end .navbar-link:focus, .navbar.is-peach .navbar-end .navbar-link:hover, .navbar.is-peach .navbar-end .navbar-link.is-active {
    color: findColorInvert(#e9897d);
    background-color: #e57567;
  }

  .navbar.is-peach .navbar-start .navbar-link:after, .navbar.is-peach .navbar-end .navbar-link:after {
    border-color: findColorInvert(#e9897d);
  }

  .navbar.is-peach .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-peach .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-peach .navbar-item.has-dropdown.is-active .navbar-link {
    color: findColorInvert(#e9897d);
    background-color: #e57567;
  }

  .navbar.is-peach .navbar-dropdown a.navbar-item.is-active {
    color: findColorInvert(#e9897d);
    background-color: #e9897d;
  }
}

.navbar.is-navy {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
}

.navbar.is-navy .navbar-brand > .navbar-item, .navbar.is-navy .navbar-brand .navbar-link {
  color: findColorInvert(#1b0777);
}

.navbar.is-navy .navbar-brand > a.navbar-item:focus, .navbar.is-navy .navbar-brand > a.navbar-item:hover, .navbar.is-navy .navbar-brand > a.navbar-item.is-active, .navbar.is-navy .navbar-brand .navbar-link:focus, .navbar.is-navy .navbar-brand .navbar-link:hover, .navbar.is-navy .navbar-brand .navbar-link.is-active {
  color: findColorInvert(#1b0777);
  background-color: #16065f;
}

.navbar.is-navy .navbar-brand .navbar-link:after {
  border-color: findColorInvert(#1b0777);
}

.navbar.is-navy .navbar-burger {
  color: findColorInvert(#1b0777);
}

@media screen and (width >= 1024px) {
  .navbar.is-navy .navbar-start > .navbar-item, .navbar.is-navy .navbar-start .navbar-link, .navbar.is-navy .navbar-end > .navbar-item, .navbar.is-navy .navbar-end .navbar-link {
    color: findColorInvert(#1b0777);
  }

  .navbar.is-navy .navbar-start > a.navbar-item:focus, .navbar.is-navy .navbar-start > a.navbar-item:hover, .navbar.is-navy .navbar-start > a.navbar-item.is-active, .navbar.is-navy .navbar-start .navbar-link:focus, .navbar.is-navy .navbar-start .navbar-link:hover, .navbar.is-navy .navbar-start .navbar-link.is-active, .navbar.is-navy .navbar-end > a.navbar-item:focus, .navbar.is-navy .navbar-end > a.navbar-item:hover, .navbar.is-navy .navbar-end > a.navbar-item.is-active, .navbar.is-navy .navbar-end .navbar-link:focus, .navbar.is-navy .navbar-end .navbar-link:hover, .navbar.is-navy .navbar-end .navbar-link.is-active {
    color: findColorInvert(#1b0777);
    background-color: #16065f;
  }

  .navbar.is-navy .navbar-start .navbar-link:after, .navbar.is-navy .navbar-end .navbar-link:after {
    border-color: findColorInvert(#1b0777);
  }

  .navbar.is-navy .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-navy .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-navy .navbar-item.has-dropdown.is-active .navbar-link {
    color: findColorInvert(#1b0777);
    background-color: #16065f;
  }

  .navbar.is-navy .navbar-dropdown a.navbar-item.is-active {
    color: findColorInvert(#1b0777);
    background-color: #1b0777;
  }
}

.navbar.is-propelle-grey-light {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
}

.navbar.is-propelle-grey-light .navbar-brand > .navbar-item, .navbar.is-propelle-grey-light .navbar-brand .navbar-link {
  color: findColorInvert(#fff8f1);
}

.navbar.is-propelle-grey-light .navbar-brand > a.navbar-item:focus, .navbar.is-propelle-grey-light .navbar-brand > a.navbar-item:hover, .navbar.is-propelle-grey-light .navbar-brand > a.navbar-item.is-active, .navbar.is-propelle-grey-light .navbar-brand .navbar-link:focus, .navbar.is-propelle-grey-light .navbar-brand .navbar-link:hover, .navbar.is-propelle-grey-light .navbar-brand .navbar-link.is-active {
  color: findColorInvert(#fff8f1);
  background-color: #ffebd8;
}

.navbar.is-propelle-grey-light .navbar-brand .navbar-link:after {
  border-color: findColorInvert(#fff8f1);
}

.navbar.is-propelle-grey-light .navbar-burger {
  color: findColorInvert(#fff8f1);
}

@media screen and (width >= 1024px) {
  .navbar.is-propelle-grey-light .navbar-start > .navbar-item, .navbar.is-propelle-grey-light .navbar-start .navbar-link, .navbar.is-propelle-grey-light .navbar-end > .navbar-item, .navbar.is-propelle-grey-light .navbar-end .navbar-link {
    color: findColorInvert(#fff8f1);
  }

  .navbar.is-propelle-grey-light .navbar-start > a.navbar-item:focus, .navbar.is-propelle-grey-light .navbar-start > a.navbar-item:hover, .navbar.is-propelle-grey-light .navbar-start > a.navbar-item.is-active, .navbar.is-propelle-grey-light .navbar-start .navbar-link:focus, .navbar.is-propelle-grey-light .navbar-start .navbar-link:hover, .navbar.is-propelle-grey-light .navbar-start .navbar-link.is-active, .navbar.is-propelle-grey-light .navbar-end > a.navbar-item:focus, .navbar.is-propelle-grey-light .navbar-end > a.navbar-item:hover, .navbar.is-propelle-grey-light .navbar-end > a.navbar-item.is-active, .navbar.is-propelle-grey-light .navbar-end .navbar-link:focus, .navbar.is-propelle-grey-light .navbar-end .navbar-link:hover, .navbar.is-propelle-grey-light .navbar-end .navbar-link.is-active {
    color: findColorInvert(#fff8f1);
    background-color: #ffebd8;
  }

  .navbar.is-propelle-grey-light .navbar-start .navbar-link:after, .navbar.is-propelle-grey-light .navbar-end .navbar-link:after {
    border-color: findColorInvert(#fff8f1);
  }

  .navbar.is-propelle-grey-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-propelle-grey-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-propelle-grey-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: findColorInvert(#fff8f1);
    background-color: #ffebd8;
  }

  .navbar.is-propelle-grey-light .navbar-dropdown a.navbar-item.is-active {
    color: findColorInvert(#fff8f1);
    background-color: #fff8f1;
  }
}

.navbar.is-default-body {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
}

.navbar.is-default-body .navbar-brand > .navbar-item, .navbar.is-default-body .navbar-brand .navbar-link {
  color: findColorInvert(#4a4a4a);
}

.navbar.is-default-body .navbar-brand > a.navbar-item:focus, .navbar.is-default-body .navbar-brand > a.navbar-item:hover, .navbar.is-default-body .navbar-brand > a.navbar-item.is-active, .navbar.is-default-body .navbar-brand .navbar-link:focus, .navbar.is-default-body .navbar-brand .navbar-link:hover, .navbar.is-default-body .navbar-brand .navbar-link.is-active {
  color: findColorInvert(#4a4a4a);
  background-color: #3d3d3d;
}

.navbar.is-default-body .navbar-brand .navbar-link:after {
  border-color: findColorInvert(#4a4a4a);
}

.navbar.is-default-body .navbar-burger {
  color: findColorInvert(#4a4a4a);
}

@media screen and (width >= 1024px) {
  .navbar.is-default-body .navbar-start > .navbar-item, .navbar.is-default-body .navbar-start .navbar-link, .navbar.is-default-body .navbar-end > .navbar-item, .navbar.is-default-body .navbar-end .navbar-link {
    color: findColorInvert(#4a4a4a);
  }

  .navbar.is-default-body .navbar-start > a.navbar-item:focus, .navbar.is-default-body .navbar-start > a.navbar-item:hover, .navbar.is-default-body .navbar-start > a.navbar-item.is-active, .navbar.is-default-body .navbar-start .navbar-link:focus, .navbar.is-default-body .navbar-start .navbar-link:hover, .navbar.is-default-body .navbar-start .navbar-link.is-active, .navbar.is-default-body .navbar-end > a.navbar-item:focus, .navbar.is-default-body .navbar-end > a.navbar-item:hover, .navbar.is-default-body .navbar-end > a.navbar-item.is-active, .navbar.is-default-body .navbar-end .navbar-link:focus, .navbar.is-default-body .navbar-end .navbar-link:hover, .navbar.is-default-body .navbar-end .navbar-link.is-active {
    color: findColorInvert(#4a4a4a);
    background-color: #3d3d3d;
  }

  .navbar.is-default-body .navbar-start .navbar-link:after, .navbar.is-default-body .navbar-end .navbar-link:after {
    border-color: findColorInvert(#4a4a4a);
  }

  .navbar.is-default-body .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-default-body .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-default-body .navbar-item.has-dropdown.is-active .navbar-link {
    color: findColorInvert(#4a4a4a);
    background-color: #3d3d3d;
  }

  .navbar.is-default-body .navbar-dropdown a.navbar-item.is-active {
    color: findColorInvert(#4a4a4a);
    background-color: #4a4a4a;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  min-height: 3.25rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #f5f5f5;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #f5f5f5;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 3.25rem;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: stretch;
  min-height: 3.25rem;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  color: #4a4a4a;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 3.25rem;
  height: 3.25rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  transform-origin: center;
  background-color: currentColor;
  width: 16px;
  height: 1px;
  transition-property: background-color, opacity, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:first-child {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #4a4a4a;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:hover, .navbar-link.is-active, a.navbar-item:focus-within, .navbar-link:focus-within {
  color: #0c0cbd;
  background-color: #0000;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 1.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  min-height: 3.25rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #0c0cbd;
}

.navbar-item.is-tab.is-active {
  color: #0c0cbd;
  background-color: #0000;
  border-bottom: 3px solid #0c0cbd;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #0c0cbd;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: .5rem 0;
  display: none;
}

@media screen and (width <= 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0a0a0a1a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem;
  }
}

@media screen and (width >= 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 3.25rem;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link {
    background-color: #0000 !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #0c0cbd;
    background-color: #f5f5f5;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0a0a0a1a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #dbdbdb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 100%;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0a0a0a1a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar-dropdown a.navbar-item.is-active {
    color: #0c0cbd;
    background-color: #f5f5f5;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 6px;
    transition-property: opacity, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 8px 8px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #0a0a0a;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover), .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #0000;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.pagination {
  margin: -.25rem;
  font-size: 16px;
}

.pagination.is-small {
  font-size: 14px;
}

.pagination.is-medium {
  font-size: 18px;
}

.pagination.is-large {
  font-size: 25px;
}

.pagination.is-rounded .pagination-previous, .pagination.is-rounded-top .pagination-previous, .pagination.is-rounded-bottom .pagination-previous, .pagination.is-rounded .pagination-next, .pagination.is-rounded-top .pagination-next, .pagination.is-rounded-bottom .pagination-next {
  border-radius: 9999px;
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link, .pagination.is-rounded-top .pagination-link, .pagination.is-rounded-bottom .pagination-link {
  border-radius: 9999px;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  text-align: center;
  justify-content: center;
  margin: .25rem;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1em;
}

.pagination-previous, .pagination-next, .pagination-link {
  color: #363636;
  border-color: #dbdbdb;
  min-width: 2.5em;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  color: #363636;
  border-color: #fff8f1;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: #485fc7;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: inset 0 1px 2px #0a0a0a33;
}

.pagination-previous[disabled], .pagination-previous.is-disabled, .pagination-next[disabled], .pagination-next.is-disabled, .pagination-link[disabled], .pagination-link.is-disabled {
  box-shadow: none;
  color: #cdcdcd;
  opacity: .5;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fff;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.pagination-ellipsis {
  color: #fff8f1;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

.pagination-list li {
  list-style: none;
}

@media screen and (width <= 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (width >= 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  border-radius: 6px;
  font-size: 16px;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel.is-white .panel-heading {
  color: #0a0a0a;
  background-color: #fff;
}

.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: #fff;
}

.panel.is-white .panel-block.is-active .panel-icon {
  color: #fff;
}

.panel.is-black .panel-heading {
  color: #fff;
  background-color: #0a0a0a;
}

.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: #0a0a0a;
}

.panel.is-black .panel-block.is-active .panel-icon {
  color: #0a0a0a;
}

.panel.is-light .panel-heading {
  color: #000000b3;
  background-color: #f5f5f5;
}

.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: #f5f5f5;
}

.panel.is-light .panel-block.is-active .panel-icon {
  color: #f5f5f5;
}

.panel.is-dark .panel-heading {
  color: #fff;
  background-color: #363636;
}

.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: #363636;
}

.panel.is-dark .panel-block.is-active .panel-icon {
  color: #363636;
}

.panel.is-primary .panel-heading, .wordpress-content .panel.wp-block-button__link .panel-heading {
  color: #fff;
  background-color: #0c0cbd;
}

.panel.is-primary .panel-tabs a.is-active, .wordpress-content .panel.wp-block-button__link .panel-tabs a.is-active {
  border-bottom-color: #0c0cbd;
}

.panel.is-primary .panel-block.is-active .panel-icon, .wordpress-content .panel.wp-block-button__link .panel-block.is-active .panel-icon {
  color: #0c0cbd;
}

.panel.is-link .panel-heading {
  color: #fff;
  background-color: #0c0cbd;
}

.panel.is-link .panel-tabs a.is-active {
  border-bottom-color: #0c0cbd;
}

.panel.is-link .panel-block.is-active .panel-icon {
  color: #0c0cbd;
}

.panel.is-info .panel-heading {
  color: #fff;
  background-color: #3e8ed0;
}

.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: #3e8ed0;
}

.panel.is-info .panel-block.is-active .panel-icon {
  color: #3e8ed0;
}

.panel.is-success .panel-heading {
  color: #fff;
  background-color: #48c78e;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: #48c78e;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: #48c78e;
}

.panel.is-warning .panel-heading {
  color: #000000b3;
  background-color: #ffb938;
}

.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: #ffb938;
}

.panel.is-warning .panel-block.is-active .panel-icon {
  color: #ffb938;
}

.panel.is-danger .panel-heading {
  color: #fff;
  background-color: #ea3f3f;
}

.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: #ea3f3f;
}

.panel.is-danger .panel-block.is-active .panel-icon {
  color: #ea3f3f;
}

.panel.is-secondary .panel-heading {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
}

.panel.is-secondary .panel-tabs a.is-active {
  border-bottom-color: #f5bdac;
}

.panel.is-secondary .panel-block.is-active .panel-icon {
  color: #f5bdac;
}

.panel.is-peach .panel-heading {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
}

.panel.is-peach .panel-tabs a.is-active {
  border-bottom-color: #e9897d;
}

.panel.is-peach .panel-block.is-active .panel-icon {
  color: #e9897d;
}

.panel.is-navy .panel-heading {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
}

.panel.is-navy .panel-tabs a.is-active {
  border-bottom-color: #1b0777;
}

.panel.is-navy .panel-block.is-active .panel-icon {
  color: #1b0777;
}

.panel.is-propelle-grey-light .panel-heading {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
}

.panel.is-propelle-grey-light .panel-tabs a.is-active {
  border-bottom-color: #fff8f1;
}

.panel.is-propelle-grey-light .panel-block.is-active .panel-icon {
  color: #fff8f1;
}

.panel.is-default-body .panel-heading {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
}

.panel.is-default-body .panel-tabs a.is-active {
  border-bottom-color: #4a4a4a;
}

.panel.is-default-body .panel-block.is-active .panel-icon {
  color: #4a4a4a;
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.panel-heading {
  color: #363636;
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  padding: .75em 1em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #363636;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #4a4a4a;
}

.panel-list a:hover {
  color: #0c0cbd;
}

.panel-block {
  color: #363636;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #363636;
  border-left-color: #0c0cbd;
}

.panel-block.is-active .panel-icon {
  color: #0c0cbd;
}

.panel-block:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #f5f5f5;
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  color: #cdcdcd;
  width: 1em;
  height: 1em;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 16px;
  display: flex;
  overflow: auto hidden;
}

.tabs a {
  color: #4a4a4a;
  vertical-align: top;
  border-bottom: 1px solid #dbdbdb;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #363636;
  border-bottom-color: #363636;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #0c0cbd;
  border-bottom-color: #0c0cbd;
}

.tabs ul {
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #f5f5f5;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #dbdbdb;
  border-bottom-color: #0000 !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #f5f5f5;
  border-color: #fff8f1;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #0c0cbd;
  border-color: #0c0cbd;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: 14px;
}

.tabs.is-medium {
  font-size: 18px;
}

.tabs.is-large {
  font-size: 25px;
}

.column {
  flex: 1 1 0;
  padding: 10px;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}

.columns:last-child {
  margin-bottom: -10px;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 10px);
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  flex: 1 1 0;
  align-items: stretch;
  min-height: min-content;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (width >= 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    flex: none;
    width: 8.33333%;
  }

  .tile.is-2 {
    flex: none;
    width: 16.6667%;
  }

  .tile.is-3 {
    flex: none;
    width: 25%;
  }

  .tile.is-4 {
    flex: none;
    width: 33.3333%;
  }

  .tile.is-5 {
    flex: none;
    width: 41.6667%;
  }

  .tile.is-6 {
    flex: none;
    width: 50%;
  }

  .tile.is-7 {
    flex: none;
    width: 58.3333%;
  }

  .tile.is-8 {
    flex: none;
    width: 66.6667%;
  }

  .tile.is-9 {
    flex: none;
    width: 75%;
  }

  .tile.is-10 {
    flex: none;
    width: 83.3333%;
  }

  .tile.is-11 {
    flex: none;
    width: 91.6667%;
  }

  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #0a0a0a !important;
}

a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #0a0a0a !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #363636 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #1c1c1c !important;
}

.has-background-dark {
  background-color: #363636 !important;
}

.has-text-primary {
  color: #0c0cbd !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #09098d !important;
}

.has-background-primary {
  background-color: #0c0cbd !important;
}

.has-text-primary-light {
  color: #ececfe !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #bcbcfb !important;
}

.has-background-primary-light {
  background-color: #ececfe !important;
}

.has-text-primary-dark {
  color: #2222f1 !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #5252f4 !important;
}

.has-background-primary-dark {
  background-color: #2222f1 !important;
}

.has-text-link {
  color: #0c0cbd !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #09098d !important;
}

.has-background-link {
  background-color: #0c0cbd !important;
}

.has-text-link-light {
  color: #ececfe !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #bcbcfb !important;
}

.has-background-link-light {
  background-color: #ececfe !important;
}

.has-text-link-dark {
  color: #2222f1 !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #5252f4 !important;
}

.has-background-link-dark {
  background-color: #2222f1 !important;
}

.has-text-info {
  color: #3e8ed0 !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #2b74b1 !important;
}

.has-background-info {
  background-color: #3e8ed0 !important;
}

.has-text-info-light {
  color: #eff5fb !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #c6ddf1 !important;
}

.has-background-info-light {
  background-color: #eff5fb !important;
}

.has-text-info-dark {
  color: #296fa8 !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #368ace !important;
}

.has-background-info-dark {
  background-color: #296fa8 !important;
}

.has-text-success {
  color: #48c78e !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #34a873 !important;
}

.has-background-success {
  background-color: #48c78e !important;
}

.has-text-success-light {
  color: #effaf5 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #c8eedd !important;
}

.has-background-success-light {
  background-color: #effaf5 !important;
}

.has-text-success-dark {
  color: #257953 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #31a06e !important;
}

.has-background-success-dark {
  background-color: #257953 !important;
}

.has-text-warning {
  color: #ffb938 !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffa705 !important;
}

.has-background-warning {
  background-color: #ffb938 !important;
}

.has-text-warning-light {
  color: #fff8eb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffe6b8 !important;
}

.has-background-warning-light {
  background-color: #fff8eb !important;
}

.has-text-warning-dark {
  color: #946000 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c78100 !important;
}

.has-background-warning-dark {
  background-color: #946000 !important;
}

.has-text-danger {
  color: #ea3f3f !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #de1818 !important;
}

.has-background-danger {
  background-color: #ea3f3f !important;
}

.has-text-danger-light {
  color: #fdeded !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #f8bfbf !important;
}

.has-background-danger-light {
  background-color: #fdeded !important;
}

.has-text-danger-dark {
  color: #ca1616 !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #e82c2c !important;
}

.has-background-danger-dark {
  background-color: #ca1616 !important;
}

.has-text-secondary {
  color: #f5bdac !important;
}

a.has-text-secondary:hover, a.has-text-secondary:focus {
  color: #f0997e !important;
}

.has-background-secondary {
  background-color: #f5bdac !important;
}

.has-text-secondary-light {
  color: #fdf1ed !important;
}

a.has-text-secondary-light:hover, a.has-text-secondary-light:focus {
  color: #f7ccbf !important;
}

.has-background-secondary-light {
  background-color: #fdf1ed !important;
}

.has-text-secondary-dark {
  color: #842b10 !important;
}

a.has-text-secondary-dark:hover, a.has-text-secondary-dark:focus {
  color: #b23a15 !important;
}

.has-background-secondary-dark {
  background-color: #842b10 !important;
}

a.has-text-peach:hover, a.has-text-peach:focus {
  color: #e26151 !important;
}

.has-text-peach-light {
  color: #fcefee !important;
}

a.has-text-peach-light:hover, a.has-text-peach-light:focus {
  color: #f5c8c2 !important;
}

.has-background-peach-light {
  background-color: #fcefee !important;
}

.has-text-peach-dark {
  color: #99281a !important;
}

a.has-text-peach-dark:hover, a.has-text-peach-dark:focus {
  color: #c43321 !important;
}

.has-background-peach-dark {
  background-color: #99281a !important;
}

.has-text-navy {
  color: #1b0777 !important;
}

a.has-text-navy:hover, a.has-text-navy:focus {
  color: #100447 !important;
}

.has-background-navy {
  background-color: #1b0777 !important;
}

.has-text-navy-light {
  color: #efecfe !important;
}

a.has-text-navy-light:hover, a.has-text-navy-light:focus {
  color: #c7bcfb !important;
}

.has-background-navy-light {
  background-color: #efecfe !important;
}

.has-text-navy-dark {
  color: #4f2bf3 !important;
}

a.has-text-navy-dark:hover, a.has-text-navy-dark:focus {
  color: #775bf5 !important;
}

.has-background-navy-dark {
  background-color: #4f2bf3 !important;
}

.has-text-propelle-grey-light {
  color: #fff8f1 !important;
}

a.has-text-propelle-grey-light:hover, a.has-text-propelle-grey-light:focus {
  color: #ffdfbe !important;
}

.has-background-propelle-grey-light {
  background-color: #fff8f1 !important;
}

.has-text-propelle-grey-light-light {
  color: #fff8f1 !important;
}

a.has-text-propelle-grey-light-light:hover, a.has-text-propelle-grey-light-light:focus {
  color: #ffdfbe !important;
}

.has-background-propelle-grey-light-light {
  background-color: #fff8f1 !important;
}

.has-text-propelle-grey-light-dark {
  color: #944a00 !important;
}

a.has-text-propelle-grey-light-dark:hover, a.has-text-propelle-grey-light-dark:focus {
  color: #c76300 !important;
}

.has-background-propelle-grey-light-dark {
  background-color: #944a00 !important;
}

.has-text-default-body {
  color: #4a4a4a !important;
}

a.has-text-default-body:hover, a.has-text-default-body:focus {
  color: #313131 !important;
}

.has-background-default-body {
  background-color: #4a4a4a !important;
}

.has-text-default-body-light {
  color: #f5f5f5 !important;
}

a.has-text-default-body-light:hover, a.has-text-default-body-light:focus {
  color: #dbdbdb !important;
}

.has-background-default-body-light {
  background-color: #f5f5f5 !important;
}

.has-text-default-body-dark {
  color: #828282 !important;
}

a.has-text-default-body-dark:hover, a.has-text-default-body-dark:focus {
  color: #9c9c9c !important;
}

.has-background-default-body-dark {
  background-color: #828282 !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey-light {
  color: #fff8f1 !important;
}

.has-background-grey-light {
  background-color: #fff8f1 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.is-relative {
  position: relative !important;
}

.is-size-1 {
  font-size: 50px !important;
}

.is-size-2 {
  font-size: 40px !important;
}

.is-size-3 {
  font-size: 30px !important;
}

.is-size-4 {
  font-size: 25px !important;
}

.is-size-5 {
  font-size: 18px !important;
}

.is-size-6 {
  font-size: 16px !important;
}

.is-size-7 {
  font-size: 14px !important;
}

@media screen and (width <= 768px) {
  .is-size-1-mobile {
    font-size: 50px !important;
  }

  .is-size-2-mobile {
    font-size: 40px !important;
  }

  .is-size-3-mobile {
    font-size: 30px !important;
  }

  .is-size-4-mobile {
    font-size: 25px !important;
  }

  .is-size-5-mobile {
    font-size: 18px !important;
  }

  .is-size-6-mobile {
    font-size: 16px !important;
  }

  .is-size-7-mobile {
    font-size: 14px !important;
  }
}

@media screen and (width >= 769px), print {
  .is-size-1-tablet {
    font-size: 50px !important;
  }

  .is-size-2-tablet {
    font-size: 40px !important;
  }

  .is-size-3-tablet {
    font-size: 30px !important;
  }

  .is-size-4-tablet {
    font-size: 25px !important;
  }

  .is-size-5-tablet {
    font-size: 18px !important;
  }

  .is-size-6-tablet {
    font-size: 16px !important;
  }

  .is-size-7-tablet {
    font-size: 14px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-size-1-touch {
    font-size: 50px !important;
  }

  .is-size-2-touch {
    font-size: 40px !important;
  }

  .is-size-3-touch {
    font-size: 30px !important;
  }

  .is-size-4-touch {
    font-size: 25px !important;
  }

  .is-size-5-touch {
    font-size: 18px !important;
  }

  .is-size-6-touch {
    font-size: 16px !important;
  }

  .is-size-7-touch {
    font-size: 14px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-size-1-desktop {
    font-size: 50px !important;
  }

  .is-size-2-desktop {
    font-size: 40px !important;
  }

  .is-size-3-desktop {
    font-size: 30px !important;
  }

  .is-size-4-desktop {
    font-size: 25px !important;
  }

  .is-size-5-desktop {
    font-size: 18px !important;
  }

  .is-size-6-desktop {
    font-size: 16px !important;
  }

  .is-size-7-desktop {
    font-size: 14px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-size-1-widescreen {
    font-size: 50px !important;
  }

  .is-size-2-widescreen {
    font-size: 40px !important;
  }

  .is-size-3-widescreen {
    font-size: 30px !important;
  }

  .is-size-4-widescreen {
    font-size: 25px !important;
  }

  .is-size-5-widescreen {
    font-size: 18px !important;
  }

  .is-size-6-widescreen {
    font-size: 16px !important;
  }

  .is-size-7-widescreen {
    font-size: 14px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-size-1-fullhd {
    font-size: 50px !important;
  }

  .is-size-2-fullhd {
    font-size: 40px !important;
  }

  .is-size-3-fullhd {
    font-size: 30px !important;
  }

  .is-size-4-fullhd {
    font-size: 25px !important;
  }

  .is-size-5-fullhd {
    font-size: 18px !important;
  }

  .is-size-6-fullhd {
    font-size: 16px !important;
  }

  .is-size-7-fullhd {
    font-size: 14px !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary {
  font-family: Poppins, sans-serif !important;
}

.is-family-secondary {
  font-family: Syne !important;
}

.is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (width <= 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (width >= 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (width <= 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (width <= 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: #0a0a0ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (width <= 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #0a0a0ab3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (width <= 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #0a0a0a !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (width <= 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #363636;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #363636 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary, .wordpress-content .hero.wp-block-button__link {
  color: #fff;
  background-color: #0c0cbd;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .wordpress-content .hero.wp-block-button__link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong, .wordpress-content .hero.wp-block-button__link strong {
  color: inherit;
}

.hero.is-primary .title, .wordpress-content .hero.wp-block-button__link .title {
  color: #fff;
}

.hero.is-primary .subtitle, .wordpress-content .hero.wp-block-button__link .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .wordpress-content .hero.wp-block-button__link .subtitle a:not(.button), .hero.is-primary .subtitle strong, .wordpress-content .hero.wp-block-button__link .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-primary .navbar-menu, .wordpress-content .hero.wp-block-button__link .navbar-menu {
    background-color: #0c0cbd;
  }
}

.hero.is-primary .navbar-item, .wordpress-content .hero.wp-block-button__link .navbar-item, .hero.is-primary .navbar-link, .wordpress-content .hero.wp-block-button__link .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .wordpress-content .hero.wp-block-button__link a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .wordpress-content .hero.wp-block-button__link a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .wordpress-content .hero.wp-block-button__link .navbar-link:hover, .hero.is-primary .navbar-link.is-active, .wordpress-content .hero.wp-block-button__link .navbar-link.is-active {
  color: #fff;
  background-color: #0a0aa5;
}

.hero.is-primary .tabs a, .wordpress-content .hero.wp-block-button__link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover, .wordpress-content .hero.wp-block-button__link .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a, .wordpress-content .hero.wp-block-button__link .tabs li.is-active a {
  opacity: 1;
  color: #0c0cbd !important;
}

.hero.is-primary .tabs.is-boxed a, .wordpress-content .hero.wp-block-button__link .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a, .wordpress-content .hero.wp-block-button__link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .wordpress-content .hero.wp-block-button__link .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover, .wordpress-content .hero.wp-block-button__link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .wordpress-content .hero.wp-block-button__link .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .wordpress-content .hero.wp-block-button__link .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #0c0cbd;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold, .wordpress-content .hero.is-bold.wp-block-button__link {
  background-image: linear-gradient(141deg, #011a95 0%, #0c0cbd 71%, #2b08db 100%);
}

@media screen and (width <= 768px) {
  .hero.is-primary.is-bold .navbar-menu, .wordpress-content .hero.is-bold.wp-block-button__link .navbar-menu {
    background-image: linear-gradient(141deg, #011a95 0%, #0c0cbd 71%, #2b08db 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #0c0cbd;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #0c0cbd;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #0a0aa5;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #0c0cbd !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #0c0cbd;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #011a95 0%, #0c0cbd 71%, #2b08db 100%);
}

@media screen and (width <= 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #011a95 0%, #0c0cbd 71%, #2b08db 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #3e8ed0;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #3e8ed0 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #3e8ed0;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
}

@media screen and (width <= 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #48c78e;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #48c78e;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #48c78e !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #48c78e;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
}

@media screen and (width <= 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #ffb938;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffb938;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffb01f;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffb938 !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffb938;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ff7d05 0%, #ffb938 71%, #ffdf52 100%);
}

@media screen and (width <= 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ff7d05 0%, #ffb938 71%, #ffdf52 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #ea3f3f;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #ea3f3f;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #e72828;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #ea3f3f !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #ea3f3f;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #ea0c31 0%, #ea3f3f 71%, #f16c51 100%);
}

@media screen and (width <= 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ea0c31 0%, #ea3f3f 71%, #f16c51 100%);
  }
}

.hero.is-secondary {
  color: findColorInvert(#f5bdac);
  background-color: #f5bdac;
}

.hero.is-secondary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-secondary strong {
  color: inherit;
}

.hero.is-secondary .title, .hero.is-secondary .subtitle, .hero.is-secondary .subtitle a:not(.button), .hero.is-secondary .subtitle strong {
  color: findColorInvert(#f5bdac);
}

@media screen and (width <= 1023px) {
  .hero.is-secondary .navbar-menu {
    background-color: #f5bdac;
  }
}

.hero.is-secondary .navbar-item, .hero.is-secondary .navbar-link {
  color: findColorInvert(#f5bdac);
}

.hero.is-secondary a.navbar-item:hover, .hero.is-secondary a.navbar-item.is-active, .hero.is-secondary .navbar-link:hover, .hero.is-secondary .navbar-link.is-active {
  color: findColorInvert(#f5bdac);
  background-color: #f2ab95;
}

.hero.is-secondary .tabs a {
  color: findColorInvert(#f5bdac);
  opacity: .9;
}

.hero.is-secondary .tabs a:hover {
  opacity: 1;
}

.hero.is-secondary .tabs li.is-active a {
  opacity: 1;
  color: #f5bdac !important;
}

.hero.is-secondary .tabs.is-boxed a, .hero.is-secondary .tabs.is-toggle a {
  color: findColorInvert(#f5bdac);
}

.hero.is-secondary .tabs.is-boxed a:hover, .hero.is-secondary .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-secondary .tabs.is-boxed li.is-active a, .hero.is-secondary .tabs.is-boxed li.is-active a:hover, .hero.is-secondary .tabs.is-toggle li.is-active a, .hero.is-secondary .tabs.is-toggle li.is-active a:hover {
  background-color: findColorInvert(#f5bdac);
  border-color: findColorInvert(#f5bdac);
  color: #f5bdac;
}

.hero.is-secondary.is-bold {
  background-image: linear-gradient(141deg, #f78077 0%, #f5bdac 71%, #f9d8c1 100%);
}

@media screen and (width <= 768px) {
  .hero.is-secondary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #f78077 0%, #f5bdac 71%, #f9d8c1 100%);
  }
}

.hero.is-peach {
  color: findColorInvert(#e9897d);
  background-color: #e9897d;
}

.hero.is-peach a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-peach strong {
  color: inherit;
}

.hero.is-peach .title, .hero.is-peach .subtitle, .hero.is-peach .subtitle a:not(.button), .hero.is-peach .subtitle strong {
  color: findColorInvert(#e9897d);
}

@media screen and (width <= 1023px) {
  .hero.is-peach .navbar-menu {
    background-color: #e9897d;
  }
}

.hero.is-peach .navbar-item, .hero.is-peach .navbar-link {
  color: findColorInvert(#e9897d);
}

.hero.is-peach a.navbar-item:hover, .hero.is-peach a.navbar-item.is-active, .hero.is-peach .navbar-link:hover, .hero.is-peach .navbar-link.is-active {
  color: findColorInvert(#e9897d);
  background-color: #e57567;
}

.hero.is-peach .tabs a {
  color: findColorInvert(#e9897d);
  opacity: .9;
}

.hero.is-peach .tabs a:hover {
  opacity: 1;
}

.hero.is-peach .tabs li.is-active a {
  opacity: 1;
  color: #e9897d !important;
}

.hero.is-peach .tabs.is-boxed a, .hero.is-peach .tabs.is-toggle a {
  color: findColorInvert(#e9897d);
}

.hero.is-peach .tabs.is-boxed a:hover, .hero.is-peach .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-peach .tabs.is-boxed li.is-active a, .hero.is-peach .tabs.is-boxed li.is-active a:hover, .hero.is-peach .tabs.is-toggle li.is-active a, .hero.is-peach .tabs.is-toggle li.is-active a:hover {
  background-color: findColorInvert(#e9897d);
  border-color: findColorInvert(#e9897d);
  color: #e9897d;
}

.hero.is-peach.is-bold {
  background-image: linear-gradient(141deg, #ec4750 0%, #e9897d 71%, #f0aa90 100%);
}

@media screen and (width <= 768px) {
  .hero.is-peach.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ec4750 0%, #e9897d 71%, #f0aa90 100%);
  }
}

.hero.is-navy {
  color: findColorInvert(#1b0777);
  background-color: #1b0777;
}

.hero.is-navy a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-navy strong {
  color: inherit;
}

.hero.is-navy .title, .hero.is-navy .subtitle, .hero.is-navy .subtitle a:not(.button), .hero.is-navy .subtitle strong {
  color: findColorInvert(#1b0777);
}

@media screen and (width <= 1023px) {
  .hero.is-navy .navbar-menu {
    background-color: #1b0777;
  }
}

.hero.is-navy .navbar-item, .hero.is-navy .navbar-link {
  color: findColorInvert(#1b0777);
}

.hero.is-navy a.navbar-item:hover, .hero.is-navy a.navbar-item.is-active, .hero.is-navy .navbar-link:hover, .hero.is-navy .navbar-link.is-active {
  color: findColorInvert(#1b0777);
  background-color: #16065f;
}

.hero.is-navy .tabs a {
  color: findColorInvert(#1b0777);
  opacity: .9;
}

.hero.is-navy .tabs a:hover {
  opacity: 1;
}

.hero.is-navy .tabs li.is-active a {
  opacity: 1;
  color: #1b0777 !important;
}

.hero.is-navy .tabs.is-boxed a, .hero.is-navy .tabs.is-toggle a {
  color: findColorInvert(#1b0777);
}

.hero.is-navy .tabs.is-boxed a:hover, .hero.is-navy .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-navy .tabs.is-boxed li.is-active a, .hero.is-navy .tabs.is-boxed li.is-active a:hover, .hero.is-navy .tabs.is-toggle li.is-active a, .hero.is-navy .tabs.is-toggle li.is-active a:hover {
  background-color: findColorInvert(#1b0777);
  border-color: findColorInvert(#1b0777);
  color: #1b0777;
}

.hero.is-navy.is-bold {
  background-image: linear-gradient(141deg, #01004b 0%, #1b0777 71%, #360593 100%);
}

@media screen and (width <= 768px) {
  .hero.is-navy.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #01004b 0%, #1b0777 71%, #360593 100%);
  }
}

.hero.is-propelle-grey-light {
  color: findColorInvert(#fff8f1);
  background-color: #fff8f1;
}

.hero.is-propelle-grey-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-propelle-grey-light strong {
  color: inherit;
}

.hero.is-propelle-grey-light .title, .hero.is-propelle-grey-light .subtitle, .hero.is-propelle-grey-light .subtitle a:not(.button), .hero.is-propelle-grey-light .subtitle strong {
  color: findColorInvert(#fff8f1);
}

@media screen and (width <= 1023px) {
  .hero.is-propelle-grey-light .navbar-menu {
    background-color: #fff8f1;
  }
}

.hero.is-propelle-grey-light .navbar-item, .hero.is-propelle-grey-light .navbar-link {
  color: findColorInvert(#fff8f1);
}

.hero.is-propelle-grey-light a.navbar-item:hover, .hero.is-propelle-grey-light a.navbar-item.is-active, .hero.is-propelle-grey-light .navbar-link:hover, .hero.is-propelle-grey-light .navbar-link.is-active {
  color: findColorInvert(#fff8f1);
  background-color: #ffebd8;
}

.hero.is-propelle-grey-light .tabs a {
  color: findColorInvert(#fff8f1);
  opacity: .9;
}

.hero.is-propelle-grey-light .tabs a:hover {
  opacity: 1;
}

.hero.is-propelle-grey-light .tabs li.is-active a {
  opacity: 1;
  color: #fff8f1 !important;
}

.hero.is-propelle-grey-light .tabs.is-boxed a, .hero.is-propelle-grey-light .tabs.is-toggle a {
  color: findColorInvert(#fff8f1);
}

.hero.is-propelle-grey-light .tabs.is-boxed a:hover, .hero.is-propelle-grey-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-propelle-grey-light .tabs.is-boxed li.is-active a, .hero.is-propelle-grey-light .tabs.is-boxed li.is-active a:hover, .hero.is-propelle-grey-light .tabs.is-toggle li.is-active a, .hero.is-propelle-grey-light .tabs.is-toggle li.is-active a:hover {
  background-color: findColorInvert(#fff8f1);
  border-color: findColorInvert(#fff8f1);
  color: #fff8f1;
}

.hero.is-propelle-grey-light.is-bold {
  background-image: linear-gradient(141deg, #ffd4be 0%, #fff8f1 71%, #fff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-propelle-grey-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffd4be 0%, #fff8f1 71%, #fff 100%);
  }
}

.hero.is-default-body {
  color: findColorInvert(#4a4a4a);
  background-color: #4a4a4a;
}

.hero.is-default-body a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-default-body strong {
  color: inherit;
}

.hero.is-default-body .title, .hero.is-default-body .subtitle, .hero.is-default-body .subtitle a:not(.button), .hero.is-default-body .subtitle strong {
  color: findColorInvert(#4a4a4a);
}

@media screen and (width <= 1023px) {
  .hero.is-default-body .navbar-menu {
    background-color: #4a4a4a;
  }
}

.hero.is-default-body .navbar-item, .hero.is-default-body .navbar-link {
  color: findColorInvert(#4a4a4a);
}

.hero.is-default-body a.navbar-item:hover, .hero.is-default-body a.navbar-item.is-active, .hero.is-default-body .navbar-link:hover, .hero.is-default-body .navbar-link.is-active {
  color: findColorInvert(#4a4a4a);
  background-color: #3d3d3d;
}

.hero.is-default-body .tabs a {
  color: findColorInvert(#4a4a4a);
  opacity: .9;
}

.hero.is-default-body .tabs a:hover {
  opacity: 1;
}

.hero.is-default-body .tabs li.is-active a {
  opacity: 1;
  color: #4a4a4a !important;
}

.hero.is-default-body .tabs.is-boxed a, .hero.is-default-body .tabs.is-toggle a {
  color: findColorInvert(#4a4a4a);
}

.hero.is-default-body .tabs.is-boxed a:hover, .hero.is-default-body .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-default-body .tabs.is-boxed li.is-active a, .hero.is-default-body .tabs.is-boxed li.is-active a:hover, .hero.is-default-body .tabs.is-toggle li.is-active a, .hero.is-default-body .tabs.is-toggle li.is-active a:hover {
  background-color: findColorInvert(#4a4a4a);
  border-color: findColorInvert(#4a4a4a);
  color: #4a4a4a;
}

.hero.is-default-body.is-bold {
  background-image: linear-gradient(141deg, #352c2d 0%, #4a4a4a 71%, #5b5452 100%);
}

@media screen and (width <= 768px) {
  .hero.is-default-body.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #352c2d 0%, #4a4a4a 71%, #5b5452 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 768px) {
  .hero-buttons .button, .hero-buttons .wordpress-content .wp-block-button__link, .wordpress-content .hero-buttons .wp-block-button__link {
    display: flex;
  }

  .hero-buttons .button:not(:last-child), .hero-buttons .wordpress-content .wp-block-button__link:not(:last-child), .wordpress-content .hero-buttons .wp-block-button__link:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child), .hero-buttons .wordpress-content .wp-block-button__link:not(:last-child), .wordpress-content .hero-buttons .wp-block-button__link:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (width >= 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.fa-bounce.bounce-once {
  --fa-animation-duration: 2s;
  --fa-animation-iteration-count: 1;
}

.fa-beat.beat-slowly {
  --fa-animation-duration: 3s;
  --fa-beat-scale: .9;
}

@media screen and (width <= 767px) {
  .section {
    padding: 1.5rem;
  }

  .footer {
    padding: 1.5rem 1.5rem 2rem;
  }
}

.divider {
  background-color: #cacaca;
  height: 1px;
}

main.without-nav, main.without-nav .background {
  left: 0;
}

@media screen and (width >= 1024px) {
  main.without-nav .section {
    position: relative;
    left: 14.5rem;
  }
}

@media screen and (width <= 768px) {
  .button.is-fullwidth-mobile, .wordpress-content .is-fullwidth-mobile.wp-block-button__link {
    justify-content: center;
    width: 100%;
  }
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.mt-1 {
  margin-top: 5px !important;
}

.mr-1 {
  margin-right: 5px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.mx-1 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.mx-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.mr-3 {
  margin-right: 15px !important;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.ml-3 {
  margin-left: 15px !important;
}

.mx-3 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-3 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-4 {
  margin: 20px !important;
}

.mt-4 {
  margin-top: 20px !important;
}

.mr-4 {
  margin-right: 20px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

.ml-4 {
  margin-left: 20px !important;
}

.mx-4 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-4 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-5 {
  margin: 25px !important;
}

.mt-5 {
  margin-top: 25px !important;
}

.mr-5 {
  margin-right: 25px !important;
}

.mb-5 {
  margin-bottom: 25px !important;
}

.ml-5 {
  margin-left: 25px !important;
}

.mx-5 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my-5 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-6 {
  margin: 30px !important;
}

.mt-6 {
  margin-top: 30px !important;
}

.mr-6 {
  margin-right: 30px !important;
}

.mb-6 {
  margin-bottom: 30px !important;
}

.ml-6 {
  margin-left: 30px !important;
}

.mx-6 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-6 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-7 {
  margin: 40px !important;
}

.mt-7 {
  margin-top: 40px !important;
}

.mr-7 {
  margin-right: 40px !important;
}

.mb-7 {
  margin-bottom: 40px !important;
}

.ml-7 {
  margin-left: 40px !important;
}

.mx-7 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-7 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-8 {
  margin: 50px !important;
}

.mt-8 {
  margin-top: 50px !important;
}

.mr-8 {
  margin-right: 50px !important;
}

.mb-8 {
  margin-bottom: 50px !important;
}

.ml-8 {
  margin-left: 50px !important;
}

.mx-8 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-8 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m--1 {
  margin: -5px !important;
}

.mt--1 {
  margin-top: -5px !important;
}

.mr--1 {
  margin-right: -5px !important;
}

.mb--1 {
  margin-bottom: -5px !important;
}

.ml--1 {
  margin-left: -5px !important;
}

.mx--1 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.my--1 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.m--2 {
  margin: -10px !important;
}

.mt--2 {
  margin-top: -10px !important;
}

.mr--2 {
  margin-right: -10px !important;
}

.mb--2 {
  margin-bottom: -10px !important;
}

.ml--2 {
  margin-left: -10px !important;
}

.mx--2 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.my--2 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.m--3 {
  margin: -15px !important;
}

.mt--3 {
  margin-top: -15px !important;
}

.mr--3 {
  margin-right: -15px !important;
}

.mb--3 {
  margin-bottom: -15px !important;
}

.ml--3 {
  margin-left: -15px !important;
}

.mx--3 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.my--3 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.m--4 {
  margin: -20px !important;
}

.mt--4 {
  margin-top: -20px !important;
}

.mr--4 {
  margin-right: -20px !important;
}

.mb--4 {
  margin-bottom: -20px !important;
}

.ml--4 {
  margin-left: -20px !important;
}

.mx--4 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.my--4 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.m--5 {
  margin: -25px !important;
}

.mt--5 {
  margin-top: -25px !important;
}

.mr--5 {
  margin-right: -25px !important;
}

.mb--5 {
  margin-bottom: -25px !important;
}

.ml--5 {
  margin-left: -25px !important;
}

.mx--5 {
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.my--5 {
  margin-top: -25px !important;
  margin-bottom: -25px !important;
}

.m--6 {
  margin: -30px !important;
}

.mt--6 {
  margin-top: -30px !important;
}

.mr--6 {
  margin-right: -30px !important;
}

.mb--6 {
  margin-bottom: -30px !important;
}

.ml--6 {
  margin-left: -30px !important;
}

.mx--6 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.my--6 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.m--7 {
  margin: -40px !important;
}

.mt--7 {
  margin-top: -40px !important;
}

.mr--7 {
  margin-right: -40px !important;
}

.mb--7 {
  margin-bottom: -40px !important;
}

.ml--7 {
  margin-left: -40px !important;
}

.mx--7 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.my--7 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.m--8 {
  margin: -50px !important;
}

.mt--8 {
  margin-top: -50px !important;
}

.mr--8 {
  margin-right: -50px !important;
}

.mb--8 {
  margin-bottom: -50px !important;
}

.ml--8 {
  margin-left: -50px !important;
}

.mx--8 {
  margin-left: -50px !important;
  margin-right: -50px !important;
}

.my--8 {
  margin-top: -50px !important;
  margin-bottom: -50px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.pl-1 {
  padding-left: 5px !important;
}

.px-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pr-2 {
  padding-right: 10px !important;
}

.pb-2 {
  padding-bottom: 10px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.px-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.pt-3 {
  padding-top: 15px !important;
}

.pr-3 {
  padding-right: 15px !important;
}

.pb-3 {
  padding-bottom: 15px !important;
}

.pl-3 {
  padding-left: 15px !important;
}

.px-3 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-3 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.pt-4 {
  padding-top: 20px !important;
}

.pr-4 {
  padding-right: 20px !important;
}

.pb-4 {
  padding-bottom: 20px !important;
}

.pl-4 {
  padding-left: 20px !important;
}

.px-4 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-4 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-5 {
  padding: 25px !important;
}

.pt-5 {
  padding-top: 25px !important;
}

.pr-5 {
  padding-right: 25px !important;
}

.pb-5 {
  padding-bottom: 25px !important;
}

.pl-5 {
  padding-left: 25px !important;
}

.px-5 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py-5 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-6 {
  padding: 30px !important;
}

.pt-6 {
  padding-top: 30px !important;
}

.pr-6 {
  padding-right: 30px !important;
}

.pb-6 {
  padding-bottom: 30px !important;
}

.pl-6 {
  padding-left: 30px !important;
}

.px-6 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-6 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-7 {
  padding: 40px !important;
}

.pt-7 {
  padding-top: 40px !important;
}

.pr-7 {
  padding-right: 40px !important;
}

.pb-7 {
  padding-bottom: 40px !important;
}

.pl-7 {
  padding-left: 40px !important;
}

.px-7 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-7 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-8 {
  padding: 50px !important;
}

.pt-8 {
  padding-top: 50px !important;
}

.pr-8 {
  padding-right: 50px !important;
}

.pb-8 {
  padding-bottom: 50px !important;
}

.pl-8 {
  padding-left: 50px !important;
}

.px-8 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-8 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.p--1 {
  padding: -5px !important;
}

.pt--1 {
  padding-top: -5px !important;
}

.pr--1 {
  padding-right: -5px !important;
}

.pb--1 {
  padding-bottom: -5px !important;
}

.pl--1 {
  padding-left: -5px !important;
}

.px--1 {
  padding-left: -5px !important;
  padding-right: -5px !important;
}

.py--1 {
  padding-top: -5px !important;
  padding-bottom: -5px !important;
}

.p--2 {
  padding: -10px !important;
}

.pt--2 {
  padding-top: -10px !important;
}

.pr--2 {
  padding-right: -10px !important;
}

.pb--2 {
  padding-bottom: -10px !important;
}

.pl--2 {
  padding-left: -10px !important;
}

.px--2 {
  padding-left: -10px !important;
  padding-right: -10px !important;
}

.py--2 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.p--3 {
  padding: -15px !important;
}

.pt--3 {
  padding-top: -15px !important;
}

.pr--3 {
  padding-right: -15px !important;
}

.pb--3 {
  padding-bottom: -15px !important;
}

.pl--3 {
  padding-left: -15px !important;
}

.px--3 {
  padding-left: -15px !important;
  padding-right: -15px !important;
}

.py--3 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.p--4 {
  padding: -20px !important;
}

.pt--4 {
  padding-top: -20px !important;
}

.pr--4 {
  padding-right: -20px !important;
}

.pb--4 {
  padding-bottom: -20px !important;
}

.pl--4 {
  padding-left: -20px !important;
}

.px--4 {
  padding-left: -20px !important;
  padding-right: -20px !important;
}

.py--4 {
  padding-top: -20px !important;
  padding-bottom: -20px !important;
}

.p--5 {
  padding: -25px !important;
}

.pt--5 {
  padding-top: -25px !important;
}

.pr--5 {
  padding-right: -25px !important;
}

.pb--5 {
  padding-bottom: -25px !important;
}

.pl--5 {
  padding-left: -25px !important;
}

.px--5 {
  padding-left: -25px !important;
  padding-right: -25px !important;
}

.py--5 {
  padding-top: -25px !important;
  padding-bottom: -25px !important;
}

.p--6 {
  padding: -30px !important;
}

.pt--6 {
  padding-top: -30px !important;
}

.pr--6 {
  padding-right: -30px !important;
}

.pb--6 {
  padding-bottom: -30px !important;
}

.pl--6 {
  padding-left: -30px !important;
}

.px--6 {
  padding-left: -30px !important;
  padding-right: -30px !important;
}

.py--6 {
  padding-top: -30px !important;
  padding-bottom: -30px !important;
}

.p--7 {
  padding: -40px !important;
}

.pt--7 {
  padding-top: -40px !important;
}

.pr--7 {
  padding-right: -40px !important;
}

.pb--7 {
  padding-bottom: -40px !important;
}

.pl--7 {
  padding-left: -40px !important;
}

.px--7 {
  padding-left: -40px !important;
  padding-right: -40px !important;
}

.py--7 {
  padding-top: -40px !important;
  padding-bottom: -40px !important;
}

.p--8 {
  padding: -50px !important;
}

.pt--8 {
  padding-top: -50px !important;
}

.pr--8 {
  padding-right: -50px !important;
}

.pb--8 {
  padding-bottom: -50px !important;
}

.pl--8 {
  padding-left: -50px !important;
}

.px--8 {
  padding-left: -50px !important;
  padding-right: -50px !important;
}

.py--8 {
  padding-top: -50px !important;
  padding-bottom: -50px !important;
}

@media screen and (width <= 768px) {
  .pull-right-mobile {
    order: 1;
  }
}

@media screen and (width >= 769px) {
  .pull-right-tablet {
    order: 1;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .pull-right-tablet-only {
    order: 1;
  }
}

@media screen and (width <= 1023px) {
  .pull-right-touch {
    order: 1;
  }
}

@media screen and (width >= 1024px) {
  .pull-right-desktop {
    order: 1;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .pull-right-desktop-only {
    order: 1;
  }
}

@media screen and (width <= 1215px) {
  .pull-right-until-widescreen {
    order: 1;
  }
}

@media screen and (width >= 1216px) {
  .pull-right-widescreen {
    order: 1;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .pull-right-widescreen-only {
    order: 1;
  }
}

@media screen and (width <= 1407px) {
  .pull-right-until-fullhd {
    order: 1;
  }
}

@media screen and (width >= 1408px) {
  .pull-right-fullhd {
    order: 1;
  }
}

@media screen and (width <= 768px) {
  .image-banner-mobile figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-mobile figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 769px) {
  .image-banner-tablet figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-tablet figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .image-banner-tablet-only figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-tablet-only figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width <= 1023px) {
  .image-banner-touch figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-touch figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 1024px) {
  .image-banner-desktop figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-desktop figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .image-banner-desktop-only figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-desktop-only figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width <= 1215px) {
  .image-banner-until-widescreen figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-until-widescreen figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 1216px) {
  .image-banner-widescreen figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-widescreen figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .image-banner-widescreen-only figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-widescreen-only figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width <= 1407px) {
  .image-banner-until-fullhd figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-until-fullhd figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

@media screen and (width >= 1408px) {
  .image-banner-fullhd figure {
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .image-banner-fullhd figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-mobile .columns {
  padding-top: 30px;
}

@media screen and (width <= 768px) {
  .criss-cross-mobile .columns {
    padding-top: 16px;
  }
}

.criss-cross-mobile .columns:first-child {
  padding-top: 0;
}

.criss-cross-mobile .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width <= 768px) {
  .criss-cross-mobile .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-mobile .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-mobile .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-tablet .columns {
  padding-top: 30px;
}

@media screen and (width >= 769px) {
  .criss-cross-tablet .columns {
    padding-top: 16px;
  }
}

.criss-cross-tablet .columns:first-child {
  padding-top: 0;
}

.criss-cross-tablet .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 769px) {
  .criss-cross-tablet .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-tablet .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-tablet .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-tablet-only .columns {
  padding-top: 30px;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .criss-cross-tablet-only .columns {
    padding-top: 16px;
  }
}

.criss-cross-tablet-only .columns:first-child {
  padding-top: 0;
}

.criss-cross-tablet-only .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .criss-cross-tablet-only .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-tablet-only .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-tablet-only .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-touch .columns {
  padding-top: 30px;
}

@media screen and (width <= 1023px) {
  .criss-cross-touch .columns {
    padding-top: 16px;
  }
}

.criss-cross-touch .columns:first-child {
  padding-top: 0;
}

.criss-cross-touch .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width <= 1023px) {
  .criss-cross-touch .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-touch .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-touch .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-desktop .columns {
  padding-top: 30px;
}

@media screen and (width >= 1024px) {
  .criss-cross-desktop .columns {
    padding-top: 16px;
  }
}

.criss-cross-desktop .columns:first-child {
  padding-top: 0;
}

.criss-cross-desktop .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 1024px) {
  .criss-cross-desktop .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-desktop .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-desktop .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-desktop-only .columns {
  padding-top: 30px;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .criss-cross-desktop-only .columns {
    padding-top: 16px;
  }
}

.criss-cross-desktop-only .columns:first-child {
  padding-top: 0;
}

.criss-cross-desktop-only .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .criss-cross-desktop-only .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-desktop-only .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-desktop-only .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-until-widescreen .columns {
  padding-top: 30px;
}

@media screen and (width <= 1215px) {
  .criss-cross-until-widescreen .columns {
    padding-top: 16px;
  }
}

.criss-cross-until-widescreen .columns:first-child {
  padding-top: 0;
}

.criss-cross-until-widescreen .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width <= 1215px) {
  .criss-cross-until-widescreen .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-until-widescreen .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-until-widescreen .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-widescreen .columns {
  padding-top: 30px;
}

@media screen and (width >= 1216px) {
  .criss-cross-widescreen .columns {
    padding-top: 16px;
  }
}

.criss-cross-widescreen .columns:first-child {
  padding-top: 0;
}

.criss-cross-widescreen .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 1216px) {
  .criss-cross-widescreen .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-widescreen .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-widescreen .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-widescreen-only .columns {
  padding-top: 30px;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .criss-cross-widescreen-only .columns {
    padding-top: 16px;
  }
}

.criss-cross-widescreen-only .columns:first-child {
  padding-top: 0;
}

.criss-cross-widescreen-only .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .criss-cross-widescreen-only .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-widescreen-only .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-widescreen-only .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-until-fullhd .columns {
  padding-top: 30px;
}

@media screen and (width <= 1407px) {
  .criss-cross-until-fullhd .columns {
    padding-top: 16px;
  }
}

.criss-cross-until-fullhd .columns:first-child {
  padding-top: 0;
}

.criss-cross-until-fullhd .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width <= 1407px) {
  .criss-cross-until-fullhd .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-until-fullhd .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-until-fullhd .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.criss-cross-fullhd .columns {
  padding-top: 30px;
}

@media screen and (width >= 1408px) {
  .criss-cross-fullhd .columns {
    padding-top: 16px;
  }
}

.criss-cross-fullhd .columns:first-child {
  padding-top: 0;
}

.criss-cross-fullhd .columns:nth-child(2n-1) .column:first-child {
  order: 1;
}

@media screen and (width >= 1408px) {
  .criss-cross-fullhd .columns:nth-child(2n-1) .column:first-child {
    order: 0;
  }

  .criss-cross-fullhd .columns .column figure {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  .criss-cross-fullhd .columns .column figure img {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
  }
}

.glass-mobile {
  position: relative;
}

@media screen and (width <= 768px) {
  .glass-mobile {
    background-color: #0000 !important;
  }

  .glass-mobile:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-mobile > * {
  background-color: #0000;
}

.glass-tablet {
  position: relative;
}

@media screen and (width >= 769px) {
  .glass-tablet {
    background-color: #0000 !important;
  }

  .glass-tablet:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-tablet > * {
  background-color: #0000;
}

.glass-tablet-only {
  position: relative;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .glass-tablet-only {
    background-color: #0000 !important;
  }

  .glass-tablet-only:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-tablet-only > * {
  background-color: #0000;
}

.glass-touch {
  position: relative;
}

@media screen and (width <= 1023px) {
  .glass-touch {
    background-color: #0000 !important;
  }

  .glass-touch:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-touch > * {
  background-color: #0000;
}

.glass-desktop {
  position: relative;
}

@media screen and (width >= 1024px) {
  .glass-desktop {
    background-color: #0000 !important;
  }

  .glass-desktop:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-desktop > * {
  background-color: #0000;
}

.glass-desktop-only {
  position: relative;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .glass-desktop-only {
    background-color: #0000 !important;
  }

  .glass-desktop-only:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-desktop-only > * {
  background-color: #0000;
}

.glass-until-widescreen {
  position: relative;
}

@media screen and (width <= 1215px) {
  .glass-until-widescreen {
    background-color: #0000 !important;
  }

  .glass-until-widescreen:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-until-widescreen > * {
  background-color: #0000;
}

.glass-widescreen {
  position: relative;
}

@media screen and (width >= 1216px) {
  .glass-widescreen {
    background-color: #0000 !important;
  }

  .glass-widescreen:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-widescreen > * {
  background-color: #0000;
}

.glass-widescreen-only {
  position: relative;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .glass-widescreen-only {
    background-color: #0000 !important;
  }

  .glass-widescreen-only:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-widescreen-only > * {
  background-color: #0000;
}

.glass-until-fullhd {
  position: relative;
}

@media screen and (width <= 1407px) {
  .glass-until-fullhd {
    background-color: #0000 !important;
  }

  .glass-until-fullhd:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-until-fullhd > * {
  background-color: #0000;
}

.glass-fullhd {
  position: relative;
}

@media screen and (width >= 1408px) {
  .glass-fullhd {
    background-color: #0000 !important;
  }

  .glass-fullhd:before {
    content: "";
    z-index: -1;
    background-color: #ffffff80;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.glass-fullhd > * {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .m-0-mobile {
    margin: 0 !important;
  }

  .mt-0-mobile {
    margin-top: 0 !important;
  }

  .mr-0-mobile {
    margin-right: 0 !important;
  }

  .mb-0-mobile {
    margin-bottom: 0 !important;
  }

  .ml-0-mobile {
    margin-left: 0 !important;
  }

  .mx-0-mobile {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-mobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-mobile {
    margin: 5px !important;
  }

  .mt-1-mobile {
    margin-top: 5px !important;
  }

  .mr-1-mobile {
    margin-right: 5px !important;
  }

  .mb-1-mobile {
    margin-bottom: 5px !important;
  }

  .ml-1-mobile {
    margin-left: 5px !important;
  }

  .mx-1-mobile {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-mobile {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-mobile {
    margin: 10px !important;
  }

  .mt-2-mobile {
    margin-top: 10px !important;
  }

  .mr-2-mobile {
    margin-right: 10px !important;
  }

  .mb-2-mobile {
    margin-bottom: 10px !important;
  }

  .ml-2-mobile {
    margin-left: 10px !important;
  }

  .mx-2-mobile {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-mobile {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-mobile {
    margin: 15px !important;
  }

  .mt-3-mobile {
    margin-top: 15px !important;
  }

  .mr-3-mobile {
    margin-right: 15px !important;
  }

  .mb-3-mobile {
    margin-bottom: 15px !important;
  }

  .ml-3-mobile {
    margin-left: 15px !important;
  }

  .mx-3-mobile {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-mobile {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-mobile {
    margin: 20px !important;
  }

  .mt-4-mobile {
    margin-top: 20px !important;
  }

  .mr-4-mobile {
    margin-right: 20px !important;
  }

  .mb-4-mobile {
    margin-bottom: 20px !important;
  }

  .ml-4-mobile {
    margin-left: 20px !important;
  }

  .mx-4-mobile {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-mobile {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-mobile {
    margin: 25px !important;
  }

  .mt-5-mobile {
    margin-top: 25px !important;
  }

  .mr-5-mobile {
    margin-right: 25px !important;
  }

  .mb-5-mobile {
    margin-bottom: 25px !important;
  }

  .ml-5-mobile {
    margin-left: 25px !important;
  }

  .mx-5-mobile {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-mobile {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-mobile {
    margin: 30px !important;
  }

  .mt-6-mobile {
    margin-top: 30px !important;
  }

  .mr-6-mobile {
    margin-right: 30px !important;
  }

  .mb-6-mobile {
    margin-bottom: 30px !important;
  }

  .ml-6-mobile {
    margin-left: 30px !important;
  }

  .mx-6-mobile {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-mobile {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-mobile {
    margin: 40px !important;
  }

  .mt-7-mobile {
    margin-top: 40px !important;
  }

  .mr-7-mobile {
    margin-right: 40px !important;
  }

  .mb-7-mobile {
    margin-bottom: 40px !important;
  }

  .ml-7-mobile {
    margin-left: 40px !important;
  }

  .mx-7-mobile {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-mobile {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-mobile {
    margin: 50px !important;
  }

  .mt-8-mobile {
    margin-top: 50px !important;
  }

  .mr-8-mobile {
    margin-right: 50px !important;
  }

  .mb-8-mobile {
    margin-bottom: 50px !important;
  }

  .ml-8-mobile {
    margin-left: 50px !important;
  }

  .mx-8-mobile {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-mobile {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-mobile {
    margin: auto !important;
  }

  .mt-auto-mobile {
    margin-top: auto !important;
  }

  .mr-auto-mobile {
    margin-right: auto !important;
  }

  .mb-auto-mobile {
    margin-bottom: auto !important;
  }

  .ml-auto-mobile {
    margin-left: auto !important;
  }

  .mx-auto-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-mobile {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-mobile {
    margin: -5px !important;
  }

  .mt--1-mobile {
    margin-top: -5px !important;
  }

  .mr--1-mobile {
    margin-right: -5px !important;
  }

  .mb--1-mobile {
    margin-bottom: -5px !important;
  }

  .ml--1-mobile {
    margin-left: -5px !important;
  }

  .mx--1-mobile {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-mobile {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-mobile {
    margin: -10px !important;
  }

  .mt--2-mobile {
    margin-top: -10px !important;
  }

  .mr--2-mobile {
    margin-right: -10px !important;
  }

  .mb--2-mobile {
    margin-bottom: -10px !important;
  }

  .ml--2-mobile {
    margin-left: -10px !important;
  }

  .mx--2-mobile {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-mobile {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-mobile {
    margin: -15px !important;
  }

  .mt--3-mobile {
    margin-top: -15px !important;
  }

  .mr--3-mobile {
    margin-right: -15px !important;
  }

  .mb--3-mobile {
    margin-bottom: -15px !important;
  }

  .ml--3-mobile {
    margin-left: -15px !important;
  }

  .mx--3-mobile {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-mobile {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-mobile {
    margin: -20px !important;
  }

  .mt--4-mobile {
    margin-top: -20px !important;
  }

  .mr--4-mobile {
    margin-right: -20px !important;
  }

  .mb--4-mobile {
    margin-bottom: -20px !important;
  }

  .ml--4-mobile {
    margin-left: -20px !important;
  }

  .mx--4-mobile {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-mobile {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-mobile {
    margin: -25px !important;
  }

  .mt--5-mobile {
    margin-top: -25px !important;
  }

  .mr--5-mobile {
    margin-right: -25px !important;
  }

  .mb--5-mobile {
    margin-bottom: -25px !important;
  }

  .ml--5-mobile {
    margin-left: -25px !important;
  }

  .mx--5-mobile {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-mobile {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-mobile {
    margin: -30px !important;
  }

  .mt--6-mobile {
    margin-top: -30px !important;
  }

  .mr--6-mobile {
    margin-right: -30px !important;
  }

  .mb--6-mobile {
    margin-bottom: -30px !important;
  }

  .ml--6-mobile {
    margin-left: -30px !important;
  }

  .mx--6-mobile {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-mobile {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-mobile {
    margin: -40px !important;
  }

  .mt--7-mobile {
    margin-top: -40px !important;
  }

  .mr--7-mobile {
    margin-right: -40px !important;
  }

  .mb--7-mobile {
    margin-bottom: -40px !important;
  }

  .ml--7-mobile {
    margin-left: -40px !important;
  }

  .mx--7-mobile {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-mobile {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-mobile {
    margin: -50px !important;
  }

  .mt--8-mobile {
    margin-top: -50px !important;
  }

  .mr--8-mobile {
    margin-right: -50px !important;
  }

  .mb--8-mobile {
    margin-bottom: -50px !important;
  }

  .ml--8-mobile {
    margin-left: -50px !important;
  }

  .mx--8-mobile {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-mobile {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 769px) {
  .m-0-tablet {
    margin: 0 !important;
  }

  .mt-0-tablet {
    margin-top: 0 !important;
  }

  .mr-0-tablet {
    margin-right: 0 !important;
  }

  .mb-0-tablet {
    margin-bottom: 0 !important;
  }

  .ml-0-tablet {
    margin-left: 0 !important;
  }

  .mx-0-tablet {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-tablet {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-tablet {
    margin: 5px !important;
  }

  .mt-1-tablet {
    margin-top: 5px !important;
  }

  .mr-1-tablet {
    margin-right: 5px !important;
  }

  .mb-1-tablet {
    margin-bottom: 5px !important;
  }

  .ml-1-tablet {
    margin-left: 5px !important;
  }

  .mx-1-tablet {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-tablet {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-tablet {
    margin: 10px !important;
  }

  .mt-2-tablet {
    margin-top: 10px !important;
  }

  .mr-2-tablet {
    margin-right: 10px !important;
  }

  .mb-2-tablet {
    margin-bottom: 10px !important;
  }

  .ml-2-tablet {
    margin-left: 10px !important;
  }

  .mx-2-tablet {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-tablet {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-tablet {
    margin: 15px !important;
  }

  .mt-3-tablet {
    margin-top: 15px !important;
  }

  .mr-3-tablet {
    margin-right: 15px !important;
  }

  .mb-3-tablet {
    margin-bottom: 15px !important;
  }

  .ml-3-tablet {
    margin-left: 15px !important;
  }

  .mx-3-tablet {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-tablet {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-tablet {
    margin: 20px !important;
  }

  .mt-4-tablet {
    margin-top: 20px !important;
  }

  .mr-4-tablet {
    margin-right: 20px !important;
  }

  .mb-4-tablet {
    margin-bottom: 20px !important;
  }

  .ml-4-tablet {
    margin-left: 20px !important;
  }

  .mx-4-tablet {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-tablet {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-tablet {
    margin: 25px !important;
  }

  .mt-5-tablet {
    margin-top: 25px !important;
  }

  .mr-5-tablet {
    margin-right: 25px !important;
  }

  .mb-5-tablet {
    margin-bottom: 25px !important;
  }

  .ml-5-tablet {
    margin-left: 25px !important;
  }

  .mx-5-tablet {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-tablet {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-tablet {
    margin: 30px !important;
  }

  .mt-6-tablet {
    margin-top: 30px !important;
  }

  .mr-6-tablet {
    margin-right: 30px !important;
  }

  .mb-6-tablet {
    margin-bottom: 30px !important;
  }

  .ml-6-tablet {
    margin-left: 30px !important;
  }

  .mx-6-tablet {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-tablet {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-tablet {
    margin: 40px !important;
  }

  .mt-7-tablet {
    margin-top: 40px !important;
  }

  .mr-7-tablet {
    margin-right: 40px !important;
  }

  .mb-7-tablet {
    margin-bottom: 40px !important;
  }

  .ml-7-tablet {
    margin-left: 40px !important;
  }

  .mx-7-tablet {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-tablet {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-tablet {
    margin: 50px !important;
  }

  .mt-8-tablet {
    margin-top: 50px !important;
  }

  .mr-8-tablet {
    margin-right: 50px !important;
  }

  .mb-8-tablet {
    margin-bottom: 50px !important;
  }

  .ml-8-tablet {
    margin-left: 50px !important;
  }

  .mx-8-tablet {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-tablet {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-tablet {
    margin: auto !important;
  }

  .mt-auto-tablet {
    margin-top: auto !important;
  }

  .mr-auto-tablet {
    margin-right: auto !important;
  }

  .mb-auto-tablet {
    margin-bottom: auto !important;
  }

  .ml-auto-tablet {
    margin-left: auto !important;
  }

  .mx-auto-tablet {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-tablet {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-tablet {
    margin: -5px !important;
  }

  .mt--1-tablet {
    margin-top: -5px !important;
  }

  .mr--1-tablet {
    margin-right: -5px !important;
  }

  .mb--1-tablet {
    margin-bottom: -5px !important;
  }

  .ml--1-tablet {
    margin-left: -5px !important;
  }

  .mx--1-tablet {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-tablet {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-tablet {
    margin: -10px !important;
  }

  .mt--2-tablet {
    margin-top: -10px !important;
  }

  .mr--2-tablet {
    margin-right: -10px !important;
  }

  .mb--2-tablet {
    margin-bottom: -10px !important;
  }

  .ml--2-tablet {
    margin-left: -10px !important;
  }

  .mx--2-tablet {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-tablet {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-tablet {
    margin: -15px !important;
  }

  .mt--3-tablet {
    margin-top: -15px !important;
  }

  .mr--3-tablet {
    margin-right: -15px !important;
  }

  .mb--3-tablet {
    margin-bottom: -15px !important;
  }

  .ml--3-tablet {
    margin-left: -15px !important;
  }

  .mx--3-tablet {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-tablet {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-tablet {
    margin: -20px !important;
  }

  .mt--4-tablet {
    margin-top: -20px !important;
  }

  .mr--4-tablet {
    margin-right: -20px !important;
  }

  .mb--4-tablet {
    margin-bottom: -20px !important;
  }

  .ml--4-tablet {
    margin-left: -20px !important;
  }

  .mx--4-tablet {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-tablet {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-tablet {
    margin: -25px !important;
  }

  .mt--5-tablet {
    margin-top: -25px !important;
  }

  .mr--5-tablet {
    margin-right: -25px !important;
  }

  .mb--5-tablet {
    margin-bottom: -25px !important;
  }

  .ml--5-tablet {
    margin-left: -25px !important;
  }

  .mx--5-tablet {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-tablet {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-tablet {
    margin: -30px !important;
  }

  .mt--6-tablet {
    margin-top: -30px !important;
  }

  .mr--6-tablet {
    margin-right: -30px !important;
  }

  .mb--6-tablet {
    margin-bottom: -30px !important;
  }

  .ml--6-tablet {
    margin-left: -30px !important;
  }

  .mx--6-tablet {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-tablet {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-tablet {
    margin: -40px !important;
  }

  .mt--7-tablet {
    margin-top: -40px !important;
  }

  .mr--7-tablet {
    margin-right: -40px !important;
  }

  .mb--7-tablet {
    margin-bottom: -40px !important;
  }

  .ml--7-tablet {
    margin-left: -40px !important;
  }

  .mx--7-tablet {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-tablet {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-tablet {
    margin: -50px !important;
  }

  .mt--8-tablet {
    margin-top: -50px !important;
  }

  .mr--8-tablet {
    margin-right: -50px !important;
  }

  .mb--8-tablet {
    margin-bottom: -50px !important;
  }

  .ml--8-tablet {
    margin-left: -50px !important;
  }

  .mx--8-tablet {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-tablet {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .m-0-tablet-only {
    margin: 0 !important;
  }

  .mt-0-tablet-only {
    margin-top: 0 !important;
  }

  .mr-0-tablet-only {
    margin-right: 0 !important;
  }

  .mb-0-tablet-only {
    margin-bottom: 0 !important;
  }

  .ml-0-tablet-only {
    margin-left: 0 !important;
  }

  .mx-0-tablet-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-tablet-only {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-tablet-only {
    margin: 5px !important;
  }

  .mt-1-tablet-only {
    margin-top: 5px !important;
  }

  .mr-1-tablet-only {
    margin-right: 5px !important;
  }

  .mb-1-tablet-only {
    margin-bottom: 5px !important;
  }

  .ml-1-tablet-only {
    margin-left: 5px !important;
  }

  .mx-1-tablet-only {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-tablet-only {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-tablet-only {
    margin: 10px !important;
  }

  .mt-2-tablet-only {
    margin-top: 10px !important;
  }

  .mr-2-tablet-only {
    margin-right: 10px !important;
  }

  .mb-2-tablet-only {
    margin-bottom: 10px !important;
  }

  .ml-2-tablet-only {
    margin-left: 10px !important;
  }

  .mx-2-tablet-only {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-tablet-only {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-tablet-only {
    margin: 15px !important;
  }

  .mt-3-tablet-only {
    margin-top: 15px !important;
  }

  .mr-3-tablet-only {
    margin-right: 15px !important;
  }

  .mb-3-tablet-only {
    margin-bottom: 15px !important;
  }

  .ml-3-tablet-only {
    margin-left: 15px !important;
  }

  .mx-3-tablet-only {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-tablet-only {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-tablet-only {
    margin: 20px !important;
  }

  .mt-4-tablet-only {
    margin-top: 20px !important;
  }

  .mr-4-tablet-only {
    margin-right: 20px !important;
  }

  .mb-4-tablet-only {
    margin-bottom: 20px !important;
  }

  .ml-4-tablet-only {
    margin-left: 20px !important;
  }

  .mx-4-tablet-only {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-tablet-only {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-tablet-only {
    margin: 25px !important;
  }

  .mt-5-tablet-only {
    margin-top: 25px !important;
  }

  .mr-5-tablet-only {
    margin-right: 25px !important;
  }

  .mb-5-tablet-only {
    margin-bottom: 25px !important;
  }

  .ml-5-tablet-only {
    margin-left: 25px !important;
  }

  .mx-5-tablet-only {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-tablet-only {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-tablet-only {
    margin: 30px !important;
  }

  .mt-6-tablet-only {
    margin-top: 30px !important;
  }

  .mr-6-tablet-only {
    margin-right: 30px !important;
  }

  .mb-6-tablet-only {
    margin-bottom: 30px !important;
  }

  .ml-6-tablet-only {
    margin-left: 30px !important;
  }

  .mx-6-tablet-only {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-tablet-only {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-tablet-only {
    margin: 40px !important;
  }

  .mt-7-tablet-only {
    margin-top: 40px !important;
  }

  .mr-7-tablet-only {
    margin-right: 40px !important;
  }

  .mb-7-tablet-only {
    margin-bottom: 40px !important;
  }

  .ml-7-tablet-only {
    margin-left: 40px !important;
  }

  .mx-7-tablet-only {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-tablet-only {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-tablet-only {
    margin: 50px !important;
  }

  .mt-8-tablet-only {
    margin-top: 50px !important;
  }

  .mr-8-tablet-only {
    margin-right: 50px !important;
  }

  .mb-8-tablet-only {
    margin-bottom: 50px !important;
  }

  .ml-8-tablet-only {
    margin-left: 50px !important;
  }

  .mx-8-tablet-only {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-tablet-only {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-tablet-only {
    margin: auto !important;
  }

  .mt-auto-tablet-only {
    margin-top: auto !important;
  }

  .mr-auto-tablet-only {
    margin-right: auto !important;
  }

  .mb-auto-tablet-only {
    margin-bottom: auto !important;
  }

  .ml-auto-tablet-only {
    margin-left: auto !important;
  }

  .mx-auto-tablet-only {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-tablet-only {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-tablet-only {
    margin: -5px !important;
  }

  .mt--1-tablet-only {
    margin-top: -5px !important;
  }

  .mr--1-tablet-only {
    margin-right: -5px !important;
  }

  .mb--1-tablet-only {
    margin-bottom: -5px !important;
  }

  .ml--1-tablet-only {
    margin-left: -5px !important;
  }

  .mx--1-tablet-only {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-tablet-only {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-tablet-only {
    margin: -10px !important;
  }

  .mt--2-tablet-only {
    margin-top: -10px !important;
  }

  .mr--2-tablet-only {
    margin-right: -10px !important;
  }

  .mb--2-tablet-only {
    margin-bottom: -10px !important;
  }

  .ml--2-tablet-only {
    margin-left: -10px !important;
  }

  .mx--2-tablet-only {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-tablet-only {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-tablet-only {
    margin: -15px !important;
  }

  .mt--3-tablet-only {
    margin-top: -15px !important;
  }

  .mr--3-tablet-only {
    margin-right: -15px !important;
  }

  .mb--3-tablet-only {
    margin-bottom: -15px !important;
  }

  .ml--3-tablet-only {
    margin-left: -15px !important;
  }

  .mx--3-tablet-only {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-tablet-only {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-tablet-only {
    margin: -20px !important;
  }

  .mt--4-tablet-only {
    margin-top: -20px !important;
  }

  .mr--4-tablet-only {
    margin-right: -20px !important;
  }

  .mb--4-tablet-only {
    margin-bottom: -20px !important;
  }

  .ml--4-tablet-only {
    margin-left: -20px !important;
  }

  .mx--4-tablet-only {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-tablet-only {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-tablet-only {
    margin: -25px !important;
  }

  .mt--5-tablet-only {
    margin-top: -25px !important;
  }

  .mr--5-tablet-only {
    margin-right: -25px !important;
  }

  .mb--5-tablet-only {
    margin-bottom: -25px !important;
  }

  .ml--5-tablet-only {
    margin-left: -25px !important;
  }

  .mx--5-tablet-only {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-tablet-only {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-tablet-only {
    margin: -30px !important;
  }

  .mt--6-tablet-only {
    margin-top: -30px !important;
  }

  .mr--6-tablet-only {
    margin-right: -30px !important;
  }

  .mb--6-tablet-only {
    margin-bottom: -30px !important;
  }

  .ml--6-tablet-only {
    margin-left: -30px !important;
  }

  .mx--6-tablet-only {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-tablet-only {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-tablet-only {
    margin: -40px !important;
  }

  .mt--7-tablet-only {
    margin-top: -40px !important;
  }

  .mr--7-tablet-only {
    margin-right: -40px !important;
  }

  .mb--7-tablet-only {
    margin-bottom: -40px !important;
  }

  .ml--7-tablet-only {
    margin-left: -40px !important;
  }

  .mx--7-tablet-only {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-tablet-only {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-tablet-only {
    margin: -50px !important;
  }

  .mt--8-tablet-only {
    margin-top: -50px !important;
  }

  .mr--8-tablet-only {
    margin-right: -50px !important;
  }

  .mb--8-tablet-only {
    margin-bottom: -50px !important;
  }

  .ml--8-tablet-only {
    margin-left: -50px !important;
  }

  .mx--8-tablet-only {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-tablet-only {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width <= 1023px) {
  .m-0-touch {
    margin: 0 !important;
  }

  .mt-0-touch {
    margin-top: 0 !important;
  }

  .mr-0-touch {
    margin-right: 0 !important;
  }

  .mb-0-touch {
    margin-bottom: 0 !important;
  }

  .ml-0-touch {
    margin-left: 0 !important;
  }

  .mx-0-touch {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-touch {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-touch {
    margin: 5px !important;
  }

  .mt-1-touch {
    margin-top: 5px !important;
  }

  .mr-1-touch {
    margin-right: 5px !important;
  }

  .mb-1-touch {
    margin-bottom: 5px !important;
  }

  .ml-1-touch {
    margin-left: 5px !important;
  }

  .mx-1-touch {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-touch {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-touch {
    margin: 10px !important;
  }

  .mt-2-touch {
    margin-top: 10px !important;
  }

  .mr-2-touch {
    margin-right: 10px !important;
  }

  .mb-2-touch {
    margin-bottom: 10px !important;
  }

  .ml-2-touch {
    margin-left: 10px !important;
  }

  .mx-2-touch {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-touch {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-touch {
    margin: 15px !important;
  }

  .mt-3-touch {
    margin-top: 15px !important;
  }

  .mr-3-touch {
    margin-right: 15px !important;
  }

  .mb-3-touch {
    margin-bottom: 15px !important;
  }

  .ml-3-touch {
    margin-left: 15px !important;
  }

  .mx-3-touch {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-touch {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-touch {
    margin: 20px !important;
  }

  .mt-4-touch {
    margin-top: 20px !important;
  }

  .mr-4-touch {
    margin-right: 20px !important;
  }

  .mb-4-touch {
    margin-bottom: 20px !important;
  }

  .ml-4-touch {
    margin-left: 20px !important;
  }

  .mx-4-touch {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-touch {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-touch {
    margin: 25px !important;
  }

  .mt-5-touch {
    margin-top: 25px !important;
  }

  .mr-5-touch {
    margin-right: 25px !important;
  }

  .mb-5-touch {
    margin-bottom: 25px !important;
  }

  .ml-5-touch {
    margin-left: 25px !important;
  }

  .mx-5-touch {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-touch {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-touch {
    margin: 30px !important;
  }

  .mt-6-touch {
    margin-top: 30px !important;
  }

  .mr-6-touch {
    margin-right: 30px !important;
  }

  .mb-6-touch {
    margin-bottom: 30px !important;
  }

  .ml-6-touch {
    margin-left: 30px !important;
  }

  .mx-6-touch {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-touch {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-touch {
    margin: 40px !important;
  }

  .mt-7-touch {
    margin-top: 40px !important;
  }

  .mr-7-touch {
    margin-right: 40px !important;
  }

  .mb-7-touch {
    margin-bottom: 40px !important;
  }

  .ml-7-touch {
    margin-left: 40px !important;
  }

  .mx-7-touch {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-touch {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-touch {
    margin: 50px !important;
  }

  .mt-8-touch {
    margin-top: 50px !important;
  }

  .mr-8-touch {
    margin-right: 50px !important;
  }

  .mb-8-touch {
    margin-bottom: 50px !important;
  }

  .ml-8-touch {
    margin-left: 50px !important;
  }

  .mx-8-touch {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-touch {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-touch {
    margin: auto !important;
  }

  .mt-auto-touch {
    margin-top: auto !important;
  }

  .mr-auto-touch {
    margin-right: auto !important;
  }

  .mb-auto-touch {
    margin-bottom: auto !important;
  }

  .ml-auto-touch {
    margin-left: auto !important;
  }

  .mx-auto-touch {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-touch {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-touch {
    margin: -5px !important;
  }

  .mt--1-touch {
    margin-top: -5px !important;
  }

  .mr--1-touch {
    margin-right: -5px !important;
  }

  .mb--1-touch {
    margin-bottom: -5px !important;
  }

  .ml--1-touch {
    margin-left: -5px !important;
  }

  .mx--1-touch {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-touch {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-touch {
    margin: -10px !important;
  }

  .mt--2-touch {
    margin-top: -10px !important;
  }

  .mr--2-touch {
    margin-right: -10px !important;
  }

  .mb--2-touch {
    margin-bottom: -10px !important;
  }

  .ml--2-touch {
    margin-left: -10px !important;
  }

  .mx--2-touch {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-touch {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-touch {
    margin: -15px !important;
  }

  .mt--3-touch {
    margin-top: -15px !important;
  }

  .mr--3-touch {
    margin-right: -15px !important;
  }

  .mb--3-touch {
    margin-bottom: -15px !important;
  }

  .ml--3-touch {
    margin-left: -15px !important;
  }

  .mx--3-touch {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-touch {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-touch {
    margin: -20px !important;
  }

  .mt--4-touch {
    margin-top: -20px !important;
  }

  .mr--4-touch {
    margin-right: -20px !important;
  }

  .mb--4-touch {
    margin-bottom: -20px !important;
  }

  .ml--4-touch {
    margin-left: -20px !important;
  }

  .mx--4-touch {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-touch {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-touch {
    margin: -25px !important;
  }

  .mt--5-touch {
    margin-top: -25px !important;
  }

  .mr--5-touch {
    margin-right: -25px !important;
  }

  .mb--5-touch {
    margin-bottom: -25px !important;
  }

  .ml--5-touch {
    margin-left: -25px !important;
  }

  .mx--5-touch {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-touch {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-touch {
    margin: -30px !important;
  }

  .mt--6-touch {
    margin-top: -30px !important;
  }

  .mr--6-touch {
    margin-right: -30px !important;
  }

  .mb--6-touch {
    margin-bottom: -30px !important;
  }

  .ml--6-touch {
    margin-left: -30px !important;
  }

  .mx--6-touch {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-touch {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-touch {
    margin: -40px !important;
  }

  .mt--7-touch {
    margin-top: -40px !important;
  }

  .mr--7-touch {
    margin-right: -40px !important;
  }

  .mb--7-touch {
    margin-bottom: -40px !important;
  }

  .ml--7-touch {
    margin-left: -40px !important;
  }

  .mx--7-touch {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-touch {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-touch {
    margin: -50px !important;
  }

  .mt--8-touch {
    margin-top: -50px !important;
  }

  .mr--8-touch {
    margin-right: -50px !important;
  }

  .mb--8-touch {
    margin-bottom: -50px !important;
  }

  .ml--8-touch {
    margin-left: -50px !important;
  }

  .mx--8-touch {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-touch {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 1024px) {
  .m-0-desktop {
    margin: 0 !important;
  }

  .mt-0-desktop {
    margin-top: 0 !important;
  }

  .mr-0-desktop {
    margin-right: 0 !important;
  }

  .mb-0-desktop {
    margin-bottom: 0 !important;
  }

  .ml-0-desktop {
    margin-left: 0 !important;
  }

  .mx-0-desktop {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-desktop {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-desktop {
    margin: 5px !important;
  }

  .mt-1-desktop {
    margin-top: 5px !important;
  }

  .mr-1-desktop {
    margin-right: 5px !important;
  }

  .mb-1-desktop {
    margin-bottom: 5px !important;
  }

  .ml-1-desktop {
    margin-left: 5px !important;
  }

  .mx-1-desktop {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-desktop {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-desktop {
    margin: 10px !important;
  }

  .mt-2-desktop {
    margin-top: 10px !important;
  }

  .mr-2-desktop {
    margin-right: 10px !important;
  }

  .mb-2-desktop {
    margin-bottom: 10px !important;
  }

  .ml-2-desktop {
    margin-left: 10px !important;
  }

  .mx-2-desktop {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-desktop {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-desktop {
    margin: 15px !important;
  }

  .mt-3-desktop {
    margin-top: 15px !important;
  }

  .mr-3-desktop {
    margin-right: 15px !important;
  }

  .mb-3-desktop {
    margin-bottom: 15px !important;
  }

  .ml-3-desktop {
    margin-left: 15px !important;
  }

  .mx-3-desktop {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-desktop {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-desktop {
    margin: 20px !important;
  }

  .mt-4-desktop {
    margin-top: 20px !important;
  }

  .mr-4-desktop {
    margin-right: 20px !important;
  }

  .mb-4-desktop {
    margin-bottom: 20px !important;
  }

  .ml-4-desktop {
    margin-left: 20px !important;
  }

  .mx-4-desktop {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-desktop {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-desktop {
    margin: 25px !important;
  }

  .mt-5-desktop {
    margin-top: 25px !important;
  }

  .mr-5-desktop {
    margin-right: 25px !important;
  }

  .mb-5-desktop {
    margin-bottom: 25px !important;
  }

  .ml-5-desktop {
    margin-left: 25px !important;
  }

  .mx-5-desktop {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-desktop {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-desktop {
    margin: 30px !important;
  }

  .mt-6-desktop {
    margin-top: 30px !important;
  }

  .mr-6-desktop {
    margin-right: 30px !important;
  }

  .mb-6-desktop {
    margin-bottom: 30px !important;
  }

  .ml-6-desktop {
    margin-left: 30px !important;
  }

  .mx-6-desktop {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-desktop {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-desktop {
    margin: 40px !important;
  }

  .mt-7-desktop {
    margin-top: 40px !important;
  }

  .mr-7-desktop {
    margin-right: 40px !important;
  }

  .mb-7-desktop {
    margin-bottom: 40px !important;
  }

  .ml-7-desktop {
    margin-left: 40px !important;
  }

  .mx-7-desktop {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-desktop {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-desktop {
    margin: 50px !important;
  }

  .mt-8-desktop {
    margin-top: 50px !important;
  }

  .mr-8-desktop {
    margin-right: 50px !important;
  }

  .mb-8-desktop {
    margin-bottom: 50px !important;
  }

  .ml-8-desktop {
    margin-left: 50px !important;
  }

  .mx-8-desktop {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-desktop {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-desktop {
    margin: auto !important;
  }

  .mt-auto-desktop {
    margin-top: auto !important;
  }

  .mr-auto-desktop {
    margin-right: auto !important;
  }

  .mb-auto-desktop {
    margin-bottom: auto !important;
  }

  .ml-auto-desktop {
    margin-left: auto !important;
  }

  .mx-auto-desktop {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-desktop {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-desktop {
    margin: -5px !important;
  }

  .mt--1-desktop {
    margin-top: -5px !important;
  }

  .mr--1-desktop {
    margin-right: -5px !important;
  }

  .mb--1-desktop {
    margin-bottom: -5px !important;
  }

  .ml--1-desktop {
    margin-left: -5px !important;
  }

  .mx--1-desktop {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-desktop {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-desktop {
    margin: -10px !important;
  }

  .mt--2-desktop {
    margin-top: -10px !important;
  }

  .mr--2-desktop {
    margin-right: -10px !important;
  }

  .mb--2-desktop {
    margin-bottom: -10px !important;
  }

  .ml--2-desktop {
    margin-left: -10px !important;
  }

  .mx--2-desktop {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-desktop {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-desktop {
    margin: -15px !important;
  }

  .mt--3-desktop {
    margin-top: -15px !important;
  }

  .mr--3-desktop {
    margin-right: -15px !important;
  }

  .mb--3-desktop {
    margin-bottom: -15px !important;
  }

  .ml--3-desktop {
    margin-left: -15px !important;
  }

  .mx--3-desktop {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-desktop {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-desktop {
    margin: -20px !important;
  }

  .mt--4-desktop {
    margin-top: -20px !important;
  }

  .mr--4-desktop {
    margin-right: -20px !important;
  }

  .mb--4-desktop {
    margin-bottom: -20px !important;
  }

  .ml--4-desktop {
    margin-left: -20px !important;
  }

  .mx--4-desktop {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-desktop {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-desktop {
    margin: -25px !important;
  }

  .mt--5-desktop {
    margin-top: -25px !important;
  }

  .mr--5-desktop {
    margin-right: -25px !important;
  }

  .mb--5-desktop {
    margin-bottom: -25px !important;
  }

  .ml--5-desktop {
    margin-left: -25px !important;
  }

  .mx--5-desktop {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-desktop {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-desktop {
    margin: -30px !important;
  }

  .mt--6-desktop {
    margin-top: -30px !important;
  }

  .mr--6-desktop {
    margin-right: -30px !important;
  }

  .mb--6-desktop {
    margin-bottom: -30px !important;
  }

  .ml--6-desktop {
    margin-left: -30px !important;
  }

  .mx--6-desktop {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-desktop {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-desktop {
    margin: -40px !important;
  }

  .mt--7-desktop {
    margin-top: -40px !important;
  }

  .mr--7-desktop {
    margin-right: -40px !important;
  }

  .mb--7-desktop {
    margin-bottom: -40px !important;
  }

  .ml--7-desktop {
    margin-left: -40px !important;
  }

  .mx--7-desktop {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-desktop {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-desktop {
    margin: -50px !important;
  }

  .mt--8-desktop {
    margin-top: -50px !important;
  }

  .mr--8-desktop {
    margin-right: -50px !important;
  }

  .mb--8-desktop {
    margin-bottom: -50px !important;
  }

  .ml--8-desktop {
    margin-left: -50px !important;
  }

  .mx--8-desktop {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-desktop {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .m-0-desktop-only {
    margin: 0 !important;
  }

  .mt-0-desktop-only {
    margin-top: 0 !important;
  }

  .mr-0-desktop-only {
    margin-right: 0 !important;
  }

  .mb-0-desktop-only {
    margin-bottom: 0 !important;
  }

  .ml-0-desktop-only {
    margin-left: 0 !important;
  }

  .mx-0-desktop-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-desktop-only {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-desktop-only {
    margin: 5px !important;
  }

  .mt-1-desktop-only {
    margin-top: 5px !important;
  }

  .mr-1-desktop-only {
    margin-right: 5px !important;
  }

  .mb-1-desktop-only {
    margin-bottom: 5px !important;
  }

  .ml-1-desktop-only {
    margin-left: 5px !important;
  }

  .mx-1-desktop-only {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-desktop-only {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-desktop-only {
    margin: 10px !important;
  }

  .mt-2-desktop-only {
    margin-top: 10px !important;
  }

  .mr-2-desktop-only {
    margin-right: 10px !important;
  }

  .mb-2-desktop-only {
    margin-bottom: 10px !important;
  }

  .ml-2-desktop-only {
    margin-left: 10px !important;
  }

  .mx-2-desktop-only {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-desktop-only {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-desktop-only {
    margin: 15px !important;
  }

  .mt-3-desktop-only {
    margin-top: 15px !important;
  }

  .mr-3-desktop-only {
    margin-right: 15px !important;
  }

  .mb-3-desktop-only {
    margin-bottom: 15px !important;
  }

  .ml-3-desktop-only {
    margin-left: 15px !important;
  }

  .mx-3-desktop-only {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-desktop-only {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-desktop-only {
    margin: 20px !important;
  }

  .mt-4-desktop-only {
    margin-top: 20px !important;
  }

  .mr-4-desktop-only {
    margin-right: 20px !important;
  }

  .mb-4-desktop-only {
    margin-bottom: 20px !important;
  }

  .ml-4-desktop-only {
    margin-left: 20px !important;
  }

  .mx-4-desktop-only {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-desktop-only {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-desktop-only {
    margin: 25px !important;
  }

  .mt-5-desktop-only {
    margin-top: 25px !important;
  }

  .mr-5-desktop-only {
    margin-right: 25px !important;
  }

  .mb-5-desktop-only {
    margin-bottom: 25px !important;
  }

  .ml-5-desktop-only {
    margin-left: 25px !important;
  }

  .mx-5-desktop-only {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-desktop-only {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-desktop-only {
    margin: 30px !important;
  }

  .mt-6-desktop-only {
    margin-top: 30px !important;
  }

  .mr-6-desktop-only {
    margin-right: 30px !important;
  }

  .mb-6-desktop-only {
    margin-bottom: 30px !important;
  }

  .ml-6-desktop-only {
    margin-left: 30px !important;
  }

  .mx-6-desktop-only {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-desktop-only {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-desktop-only {
    margin: 40px !important;
  }

  .mt-7-desktop-only {
    margin-top: 40px !important;
  }

  .mr-7-desktop-only {
    margin-right: 40px !important;
  }

  .mb-7-desktop-only {
    margin-bottom: 40px !important;
  }

  .ml-7-desktop-only {
    margin-left: 40px !important;
  }

  .mx-7-desktop-only {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-desktop-only {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-desktop-only {
    margin: 50px !important;
  }

  .mt-8-desktop-only {
    margin-top: 50px !important;
  }

  .mr-8-desktop-only {
    margin-right: 50px !important;
  }

  .mb-8-desktop-only {
    margin-bottom: 50px !important;
  }

  .ml-8-desktop-only {
    margin-left: 50px !important;
  }

  .mx-8-desktop-only {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-desktop-only {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-desktop-only {
    margin: auto !important;
  }

  .mt-auto-desktop-only {
    margin-top: auto !important;
  }

  .mr-auto-desktop-only {
    margin-right: auto !important;
  }

  .mb-auto-desktop-only {
    margin-bottom: auto !important;
  }

  .ml-auto-desktop-only {
    margin-left: auto !important;
  }

  .mx-auto-desktop-only {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-desktop-only {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-desktop-only {
    margin: -5px !important;
  }

  .mt--1-desktop-only {
    margin-top: -5px !important;
  }

  .mr--1-desktop-only {
    margin-right: -5px !important;
  }

  .mb--1-desktop-only {
    margin-bottom: -5px !important;
  }

  .ml--1-desktop-only {
    margin-left: -5px !important;
  }

  .mx--1-desktop-only {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-desktop-only {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-desktop-only {
    margin: -10px !important;
  }

  .mt--2-desktop-only {
    margin-top: -10px !important;
  }

  .mr--2-desktop-only {
    margin-right: -10px !important;
  }

  .mb--2-desktop-only {
    margin-bottom: -10px !important;
  }

  .ml--2-desktop-only {
    margin-left: -10px !important;
  }

  .mx--2-desktop-only {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-desktop-only {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-desktop-only {
    margin: -15px !important;
  }

  .mt--3-desktop-only {
    margin-top: -15px !important;
  }

  .mr--3-desktop-only {
    margin-right: -15px !important;
  }

  .mb--3-desktop-only {
    margin-bottom: -15px !important;
  }

  .ml--3-desktop-only {
    margin-left: -15px !important;
  }

  .mx--3-desktop-only {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-desktop-only {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-desktop-only {
    margin: -20px !important;
  }

  .mt--4-desktop-only {
    margin-top: -20px !important;
  }

  .mr--4-desktop-only {
    margin-right: -20px !important;
  }

  .mb--4-desktop-only {
    margin-bottom: -20px !important;
  }

  .ml--4-desktop-only {
    margin-left: -20px !important;
  }

  .mx--4-desktop-only {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-desktop-only {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-desktop-only {
    margin: -25px !important;
  }

  .mt--5-desktop-only {
    margin-top: -25px !important;
  }

  .mr--5-desktop-only {
    margin-right: -25px !important;
  }

  .mb--5-desktop-only {
    margin-bottom: -25px !important;
  }

  .ml--5-desktop-only {
    margin-left: -25px !important;
  }

  .mx--5-desktop-only {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-desktop-only {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-desktop-only {
    margin: -30px !important;
  }

  .mt--6-desktop-only {
    margin-top: -30px !important;
  }

  .mr--6-desktop-only {
    margin-right: -30px !important;
  }

  .mb--6-desktop-only {
    margin-bottom: -30px !important;
  }

  .ml--6-desktop-only {
    margin-left: -30px !important;
  }

  .mx--6-desktop-only {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-desktop-only {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-desktop-only {
    margin: -40px !important;
  }

  .mt--7-desktop-only {
    margin-top: -40px !important;
  }

  .mr--7-desktop-only {
    margin-right: -40px !important;
  }

  .mb--7-desktop-only {
    margin-bottom: -40px !important;
  }

  .ml--7-desktop-only {
    margin-left: -40px !important;
  }

  .mx--7-desktop-only {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-desktop-only {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-desktop-only {
    margin: -50px !important;
  }

  .mt--8-desktop-only {
    margin-top: -50px !important;
  }

  .mr--8-desktop-only {
    margin-right: -50px !important;
  }

  .mb--8-desktop-only {
    margin-bottom: -50px !important;
  }

  .ml--8-desktop-only {
    margin-left: -50px !important;
  }

  .mx--8-desktop-only {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-desktop-only {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width <= 1215px) {
  .m-0-until-widescreen {
    margin: 0 !important;
  }

  .mt-0-until-widescreen {
    margin-top: 0 !important;
  }

  .mr-0-until-widescreen {
    margin-right: 0 !important;
  }

  .mb-0-until-widescreen {
    margin-bottom: 0 !important;
  }

  .ml-0-until-widescreen {
    margin-left: 0 !important;
  }

  .mx-0-until-widescreen {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-until-widescreen {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-until-widescreen {
    margin: 5px !important;
  }

  .mt-1-until-widescreen {
    margin-top: 5px !important;
  }

  .mr-1-until-widescreen {
    margin-right: 5px !important;
  }

  .mb-1-until-widescreen {
    margin-bottom: 5px !important;
  }

  .ml-1-until-widescreen {
    margin-left: 5px !important;
  }

  .mx-1-until-widescreen {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-until-widescreen {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-until-widescreen {
    margin: 10px !important;
  }

  .mt-2-until-widescreen {
    margin-top: 10px !important;
  }

  .mr-2-until-widescreen {
    margin-right: 10px !important;
  }

  .mb-2-until-widescreen {
    margin-bottom: 10px !important;
  }

  .ml-2-until-widescreen {
    margin-left: 10px !important;
  }

  .mx-2-until-widescreen {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-until-widescreen {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-until-widescreen {
    margin: 15px !important;
  }

  .mt-3-until-widescreen {
    margin-top: 15px !important;
  }

  .mr-3-until-widescreen {
    margin-right: 15px !important;
  }

  .mb-3-until-widescreen {
    margin-bottom: 15px !important;
  }

  .ml-3-until-widescreen {
    margin-left: 15px !important;
  }

  .mx-3-until-widescreen {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-until-widescreen {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-until-widescreen {
    margin: 20px !important;
  }

  .mt-4-until-widescreen {
    margin-top: 20px !important;
  }

  .mr-4-until-widescreen {
    margin-right: 20px !important;
  }

  .mb-4-until-widescreen {
    margin-bottom: 20px !important;
  }

  .ml-4-until-widescreen {
    margin-left: 20px !important;
  }

  .mx-4-until-widescreen {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-until-widescreen {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-until-widescreen {
    margin: 25px !important;
  }

  .mt-5-until-widescreen {
    margin-top: 25px !important;
  }

  .mr-5-until-widescreen {
    margin-right: 25px !important;
  }

  .mb-5-until-widescreen {
    margin-bottom: 25px !important;
  }

  .ml-5-until-widescreen {
    margin-left: 25px !important;
  }

  .mx-5-until-widescreen {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-until-widescreen {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-until-widescreen {
    margin: 30px !important;
  }

  .mt-6-until-widescreen {
    margin-top: 30px !important;
  }

  .mr-6-until-widescreen {
    margin-right: 30px !important;
  }

  .mb-6-until-widescreen {
    margin-bottom: 30px !important;
  }

  .ml-6-until-widescreen {
    margin-left: 30px !important;
  }

  .mx-6-until-widescreen {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-until-widescreen {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-until-widescreen {
    margin: 40px !important;
  }

  .mt-7-until-widescreen {
    margin-top: 40px !important;
  }

  .mr-7-until-widescreen {
    margin-right: 40px !important;
  }

  .mb-7-until-widescreen {
    margin-bottom: 40px !important;
  }

  .ml-7-until-widescreen {
    margin-left: 40px !important;
  }

  .mx-7-until-widescreen {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-until-widescreen {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-until-widescreen {
    margin: 50px !important;
  }

  .mt-8-until-widescreen {
    margin-top: 50px !important;
  }

  .mr-8-until-widescreen {
    margin-right: 50px !important;
  }

  .mb-8-until-widescreen {
    margin-bottom: 50px !important;
  }

  .ml-8-until-widescreen {
    margin-left: 50px !important;
  }

  .mx-8-until-widescreen {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-until-widescreen {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-until-widescreen {
    margin: auto !important;
  }

  .mt-auto-until-widescreen {
    margin-top: auto !important;
  }

  .mr-auto-until-widescreen {
    margin-right: auto !important;
  }

  .mb-auto-until-widescreen {
    margin-bottom: auto !important;
  }

  .ml-auto-until-widescreen {
    margin-left: auto !important;
  }

  .mx-auto-until-widescreen {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-until-widescreen {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-until-widescreen {
    margin: -5px !important;
  }

  .mt--1-until-widescreen {
    margin-top: -5px !important;
  }

  .mr--1-until-widescreen {
    margin-right: -5px !important;
  }

  .mb--1-until-widescreen {
    margin-bottom: -5px !important;
  }

  .ml--1-until-widescreen {
    margin-left: -5px !important;
  }

  .mx--1-until-widescreen {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-until-widescreen {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-until-widescreen {
    margin: -10px !important;
  }

  .mt--2-until-widescreen {
    margin-top: -10px !important;
  }

  .mr--2-until-widescreen {
    margin-right: -10px !important;
  }

  .mb--2-until-widescreen {
    margin-bottom: -10px !important;
  }

  .ml--2-until-widescreen {
    margin-left: -10px !important;
  }

  .mx--2-until-widescreen {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-until-widescreen {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-until-widescreen {
    margin: -15px !important;
  }

  .mt--3-until-widescreen {
    margin-top: -15px !important;
  }

  .mr--3-until-widescreen {
    margin-right: -15px !important;
  }

  .mb--3-until-widescreen {
    margin-bottom: -15px !important;
  }

  .ml--3-until-widescreen {
    margin-left: -15px !important;
  }

  .mx--3-until-widescreen {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-until-widescreen {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-until-widescreen {
    margin: -20px !important;
  }

  .mt--4-until-widescreen {
    margin-top: -20px !important;
  }

  .mr--4-until-widescreen {
    margin-right: -20px !important;
  }

  .mb--4-until-widescreen {
    margin-bottom: -20px !important;
  }

  .ml--4-until-widescreen {
    margin-left: -20px !important;
  }

  .mx--4-until-widescreen {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-until-widescreen {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-until-widescreen {
    margin: -25px !important;
  }

  .mt--5-until-widescreen {
    margin-top: -25px !important;
  }

  .mr--5-until-widescreen {
    margin-right: -25px !important;
  }

  .mb--5-until-widescreen {
    margin-bottom: -25px !important;
  }

  .ml--5-until-widescreen {
    margin-left: -25px !important;
  }

  .mx--5-until-widescreen {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-until-widescreen {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-until-widescreen {
    margin: -30px !important;
  }

  .mt--6-until-widescreen {
    margin-top: -30px !important;
  }

  .mr--6-until-widescreen {
    margin-right: -30px !important;
  }

  .mb--6-until-widescreen {
    margin-bottom: -30px !important;
  }

  .ml--6-until-widescreen {
    margin-left: -30px !important;
  }

  .mx--6-until-widescreen {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-until-widescreen {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-until-widescreen {
    margin: -40px !important;
  }

  .mt--7-until-widescreen {
    margin-top: -40px !important;
  }

  .mr--7-until-widescreen {
    margin-right: -40px !important;
  }

  .mb--7-until-widescreen {
    margin-bottom: -40px !important;
  }

  .ml--7-until-widescreen {
    margin-left: -40px !important;
  }

  .mx--7-until-widescreen {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-until-widescreen {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-until-widescreen {
    margin: -50px !important;
  }

  .mt--8-until-widescreen {
    margin-top: -50px !important;
  }

  .mr--8-until-widescreen {
    margin-right: -50px !important;
  }

  .mb--8-until-widescreen {
    margin-bottom: -50px !important;
  }

  .ml--8-until-widescreen {
    margin-left: -50px !important;
  }

  .mx--8-until-widescreen {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-until-widescreen {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 1216px) {
  .m-0-widescreen {
    margin: 0 !important;
  }

  .mt-0-widescreen {
    margin-top: 0 !important;
  }

  .mr-0-widescreen {
    margin-right: 0 !important;
  }

  .mb-0-widescreen {
    margin-bottom: 0 !important;
  }

  .ml-0-widescreen {
    margin-left: 0 !important;
  }

  .mx-0-widescreen {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-widescreen {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-widescreen {
    margin: 5px !important;
  }

  .mt-1-widescreen {
    margin-top: 5px !important;
  }

  .mr-1-widescreen {
    margin-right: 5px !important;
  }

  .mb-1-widescreen {
    margin-bottom: 5px !important;
  }

  .ml-1-widescreen {
    margin-left: 5px !important;
  }

  .mx-1-widescreen {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-widescreen {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-widescreen {
    margin: 10px !important;
  }

  .mt-2-widescreen {
    margin-top: 10px !important;
  }

  .mr-2-widescreen {
    margin-right: 10px !important;
  }

  .mb-2-widescreen {
    margin-bottom: 10px !important;
  }

  .ml-2-widescreen {
    margin-left: 10px !important;
  }

  .mx-2-widescreen {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-widescreen {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-widescreen {
    margin: 15px !important;
  }

  .mt-3-widescreen {
    margin-top: 15px !important;
  }

  .mr-3-widescreen {
    margin-right: 15px !important;
  }

  .mb-3-widescreen {
    margin-bottom: 15px !important;
  }

  .ml-3-widescreen {
    margin-left: 15px !important;
  }

  .mx-3-widescreen {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-widescreen {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-widescreen {
    margin: 20px !important;
  }

  .mt-4-widescreen {
    margin-top: 20px !important;
  }

  .mr-4-widescreen {
    margin-right: 20px !important;
  }

  .mb-4-widescreen {
    margin-bottom: 20px !important;
  }

  .ml-4-widescreen {
    margin-left: 20px !important;
  }

  .mx-4-widescreen {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-widescreen {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-widescreen {
    margin: 25px !important;
  }

  .mt-5-widescreen {
    margin-top: 25px !important;
  }

  .mr-5-widescreen {
    margin-right: 25px !important;
  }

  .mb-5-widescreen {
    margin-bottom: 25px !important;
  }

  .ml-5-widescreen {
    margin-left: 25px !important;
  }

  .mx-5-widescreen {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-widescreen {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-widescreen {
    margin: 30px !important;
  }

  .mt-6-widescreen {
    margin-top: 30px !important;
  }

  .mr-6-widescreen {
    margin-right: 30px !important;
  }

  .mb-6-widescreen {
    margin-bottom: 30px !important;
  }

  .ml-6-widescreen {
    margin-left: 30px !important;
  }

  .mx-6-widescreen {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-widescreen {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-widescreen {
    margin: 40px !important;
  }

  .mt-7-widescreen {
    margin-top: 40px !important;
  }

  .mr-7-widescreen {
    margin-right: 40px !important;
  }

  .mb-7-widescreen {
    margin-bottom: 40px !important;
  }

  .ml-7-widescreen {
    margin-left: 40px !important;
  }

  .mx-7-widescreen {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-widescreen {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-widescreen {
    margin: 50px !important;
  }

  .mt-8-widescreen {
    margin-top: 50px !important;
  }

  .mr-8-widescreen {
    margin-right: 50px !important;
  }

  .mb-8-widescreen {
    margin-bottom: 50px !important;
  }

  .ml-8-widescreen {
    margin-left: 50px !important;
  }

  .mx-8-widescreen {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-widescreen {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-widescreen {
    margin: auto !important;
  }

  .mt-auto-widescreen {
    margin-top: auto !important;
  }

  .mr-auto-widescreen {
    margin-right: auto !important;
  }

  .mb-auto-widescreen {
    margin-bottom: auto !important;
  }

  .ml-auto-widescreen {
    margin-left: auto !important;
  }

  .mx-auto-widescreen {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-widescreen {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-widescreen {
    margin: -5px !important;
  }

  .mt--1-widescreen {
    margin-top: -5px !important;
  }

  .mr--1-widescreen {
    margin-right: -5px !important;
  }

  .mb--1-widescreen {
    margin-bottom: -5px !important;
  }

  .ml--1-widescreen {
    margin-left: -5px !important;
  }

  .mx--1-widescreen {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-widescreen {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-widescreen {
    margin: -10px !important;
  }

  .mt--2-widescreen {
    margin-top: -10px !important;
  }

  .mr--2-widescreen {
    margin-right: -10px !important;
  }

  .mb--2-widescreen {
    margin-bottom: -10px !important;
  }

  .ml--2-widescreen {
    margin-left: -10px !important;
  }

  .mx--2-widescreen {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-widescreen {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-widescreen {
    margin: -15px !important;
  }

  .mt--3-widescreen {
    margin-top: -15px !important;
  }

  .mr--3-widescreen {
    margin-right: -15px !important;
  }

  .mb--3-widescreen {
    margin-bottom: -15px !important;
  }

  .ml--3-widescreen {
    margin-left: -15px !important;
  }

  .mx--3-widescreen {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-widescreen {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-widescreen {
    margin: -20px !important;
  }

  .mt--4-widescreen {
    margin-top: -20px !important;
  }

  .mr--4-widescreen {
    margin-right: -20px !important;
  }

  .mb--4-widescreen {
    margin-bottom: -20px !important;
  }

  .ml--4-widescreen {
    margin-left: -20px !important;
  }

  .mx--4-widescreen {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-widescreen {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-widescreen {
    margin: -25px !important;
  }

  .mt--5-widescreen {
    margin-top: -25px !important;
  }

  .mr--5-widescreen {
    margin-right: -25px !important;
  }

  .mb--5-widescreen {
    margin-bottom: -25px !important;
  }

  .ml--5-widescreen {
    margin-left: -25px !important;
  }

  .mx--5-widescreen {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-widescreen {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-widescreen {
    margin: -30px !important;
  }

  .mt--6-widescreen {
    margin-top: -30px !important;
  }

  .mr--6-widescreen {
    margin-right: -30px !important;
  }

  .mb--6-widescreen {
    margin-bottom: -30px !important;
  }

  .ml--6-widescreen {
    margin-left: -30px !important;
  }

  .mx--6-widescreen {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-widescreen {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-widescreen {
    margin: -40px !important;
  }

  .mt--7-widescreen {
    margin-top: -40px !important;
  }

  .mr--7-widescreen {
    margin-right: -40px !important;
  }

  .mb--7-widescreen {
    margin-bottom: -40px !important;
  }

  .ml--7-widescreen {
    margin-left: -40px !important;
  }

  .mx--7-widescreen {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-widescreen {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-widescreen {
    margin: -50px !important;
  }

  .mt--8-widescreen {
    margin-top: -50px !important;
  }

  .mr--8-widescreen {
    margin-right: -50px !important;
  }

  .mb--8-widescreen {
    margin-bottom: -50px !important;
  }

  .ml--8-widescreen {
    margin-left: -50px !important;
  }

  .mx--8-widescreen {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-widescreen {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .m-0-widescreen-only {
    margin: 0 !important;
  }

  .mt-0-widescreen-only {
    margin-top: 0 !important;
  }

  .mr-0-widescreen-only {
    margin-right: 0 !important;
  }

  .mb-0-widescreen-only {
    margin-bottom: 0 !important;
  }

  .ml-0-widescreen-only {
    margin-left: 0 !important;
  }

  .mx-0-widescreen-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-widescreen-only {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-widescreen-only {
    margin: 5px !important;
  }

  .mt-1-widescreen-only {
    margin-top: 5px !important;
  }

  .mr-1-widescreen-only {
    margin-right: 5px !important;
  }

  .mb-1-widescreen-only {
    margin-bottom: 5px !important;
  }

  .ml-1-widescreen-only {
    margin-left: 5px !important;
  }

  .mx-1-widescreen-only {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-widescreen-only {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-widescreen-only {
    margin: 10px !important;
  }

  .mt-2-widescreen-only {
    margin-top: 10px !important;
  }

  .mr-2-widescreen-only {
    margin-right: 10px !important;
  }

  .mb-2-widescreen-only {
    margin-bottom: 10px !important;
  }

  .ml-2-widescreen-only {
    margin-left: 10px !important;
  }

  .mx-2-widescreen-only {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-widescreen-only {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-widescreen-only {
    margin: 15px !important;
  }

  .mt-3-widescreen-only {
    margin-top: 15px !important;
  }

  .mr-3-widescreen-only {
    margin-right: 15px !important;
  }

  .mb-3-widescreen-only {
    margin-bottom: 15px !important;
  }

  .ml-3-widescreen-only {
    margin-left: 15px !important;
  }

  .mx-3-widescreen-only {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-widescreen-only {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-widescreen-only {
    margin: 20px !important;
  }

  .mt-4-widescreen-only {
    margin-top: 20px !important;
  }

  .mr-4-widescreen-only {
    margin-right: 20px !important;
  }

  .mb-4-widescreen-only {
    margin-bottom: 20px !important;
  }

  .ml-4-widescreen-only {
    margin-left: 20px !important;
  }

  .mx-4-widescreen-only {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-widescreen-only {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-widescreen-only {
    margin: 25px !important;
  }

  .mt-5-widescreen-only {
    margin-top: 25px !important;
  }

  .mr-5-widescreen-only {
    margin-right: 25px !important;
  }

  .mb-5-widescreen-only {
    margin-bottom: 25px !important;
  }

  .ml-5-widescreen-only {
    margin-left: 25px !important;
  }

  .mx-5-widescreen-only {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-widescreen-only {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-widescreen-only {
    margin: 30px !important;
  }

  .mt-6-widescreen-only {
    margin-top: 30px !important;
  }

  .mr-6-widescreen-only {
    margin-right: 30px !important;
  }

  .mb-6-widescreen-only {
    margin-bottom: 30px !important;
  }

  .ml-6-widescreen-only {
    margin-left: 30px !important;
  }

  .mx-6-widescreen-only {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-widescreen-only {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-widescreen-only {
    margin: 40px !important;
  }

  .mt-7-widescreen-only {
    margin-top: 40px !important;
  }

  .mr-7-widescreen-only {
    margin-right: 40px !important;
  }

  .mb-7-widescreen-only {
    margin-bottom: 40px !important;
  }

  .ml-7-widescreen-only {
    margin-left: 40px !important;
  }

  .mx-7-widescreen-only {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-widescreen-only {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-widescreen-only {
    margin: 50px !important;
  }

  .mt-8-widescreen-only {
    margin-top: 50px !important;
  }

  .mr-8-widescreen-only {
    margin-right: 50px !important;
  }

  .mb-8-widescreen-only {
    margin-bottom: 50px !important;
  }

  .ml-8-widescreen-only {
    margin-left: 50px !important;
  }

  .mx-8-widescreen-only {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-widescreen-only {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-widescreen-only {
    margin: auto !important;
  }

  .mt-auto-widescreen-only {
    margin-top: auto !important;
  }

  .mr-auto-widescreen-only {
    margin-right: auto !important;
  }

  .mb-auto-widescreen-only {
    margin-bottom: auto !important;
  }

  .ml-auto-widescreen-only {
    margin-left: auto !important;
  }

  .mx-auto-widescreen-only {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-widescreen-only {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-widescreen-only {
    margin: -5px !important;
  }

  .mt--1-widescreen-only {
    margin-top: -5px !important;
  }

  .mr--1-widescreen-only {
    margin-right: -5px !important;
  }

  .mb--1-widescreen-only {
    margin-bottom: -5px !important;
  }

  .ml--1-widescreen-only {
    margin-left: -5px !important;
  }

  .mx--1-widescreen-only {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-widescreen-only {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-widescreen-only {
    margin: -10px !important;
  }

  .mt--2-widescreen-only {
    margin-top: -10px !important;
  }

  .mr--2-widescreen-only {
    margin-right: -10px !important;
  }

  .mb--2-widescreen-only {
    margin-bottom: -10px !important;
  }

  .ml--2-widescreen-only {
    margin-left: -10px !important;
  }

  .mx--2-widescreen-only {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-widescreen-only {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-widescreen-only {
    margin: -15px !important;
  }

  .mt--3-widescreen-only {
    margin-top: -15px !important;
  }

  .mr--3-widescreen-only {
    margin-right: -15px !important;
  }

  .mb--3-widescreen-only {
    margin-bottom: -15px !important;
  }

  .ml--3-widescreen-only {
    margin-left: -15px !important;
  }

  .mx--3-widescreen-only {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-widescreen-only {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-widescreen-only {
    margin: -20px !important;
  }

  .mt--4-widescreen-only {
    margin-top: -20px !important;
  }

  .mr--4-widescreen-only {
    margin-right: -20px !important;
  }

  .mb--4-widescreen-only {
    margin-bottom: -20px !important;
  }

  .ml--4-widescreen-only {
    margin-left: -20px !important;
  }

  .mx--4-widescreen-only {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-widescreen-only {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-widescreen-only {
    margin: -25px !important;
  }

  .mt--5-widescreen-only {
    margin-top: -25px !important;
  }

  .mr--5-widescreen-only {
    margin-right: -25px !important;
  }

  .mb--5-widescreen-only {
    margin-bottom: -25px !important;
  }

  .ml--5-widescreen-only {
    margin-left: -25px !important;
  }

  .mx--5-widescreen-only {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-widescreen-only {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-widescreen-only {
    margin: -30px !important;
  }

  .mt--6-widescreen-only {
    margin-top: -30px !important;
  }

  .mr--6-widescreen-only {
    margin-right: -30px !important;
  }

  .mb--6-widescreen-only {
    margin-bottom: -30px !important;
  }

  .ml--6-widescreen-only {
    margin-left: -30px !important;
  }

  .mx--6-widescreen-only {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-widescreen-only {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-widescreen-only {
    margin: -40px !important;
  }

  .mt--7-widescreen-only {
    margin-top: -40px !important;
  }

  .mr--7-widescreen-only {
    margin-right: -40px !important;
  }

  .mb--7-widescreen-only {
    margin-bottom: -40px !important;
  }

  .ml--7-widescreen-only {
    margin-left: -40px !important;
  }

  .mx--7-widescreen-only {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-widescreen-only {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-widescreen-only {
    margin: -50px !important;
  }

  .mt--8-widescreen-only {
    margin-top: -50px !important;
  }

  .mr--8-widescreen-only {
    margin-right: -50px !important;
  }

  .mb--8-widescreen-only {
    margin-bottom: -50px !important;
  }

  .ml--8-widescreen-only {
    margin-left: -50px !important;
  }

  .mx--8-widescreen-only {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-widescreen-only {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width <= 1407px) {
  .m-0-until-fullhd {
    margin: 0 !important;
  }

  .mt-0-until-fullhd {
    margin-top: 0 !important;
  }

  .mr-0-until-fullhd {
    margin-right: 0 !important;
  }

  .mb-0-until-fullhd {
    margin-bottom: 0 !important;
  }

  .ml-0-until-fullhd {
    margin-left: 0 !important;
  }

  .mx-0-until-fullhd {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-until-fullhd {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-until-fullhd {
    margin: 5px !important;
  }

  .mt-1-until-fullhd {
    margin-top: 5px !important;
  }

  .mr-1-until-fullhd {
    margin-right: 5px !important;
  }

  .mb-1-until-fullhd {
    margin-bottom: 5px !important;
  }

  .ml-1-until-fullhd {
    margin-left: 5px !important;
  }

  .mx-1-until-fullhd {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-until-fullhd {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-until-fullhd {
    margin: 10px !important;
  }

  .mt-2-until-fullhd {
    margin-top: 10px !important;
  }

  .mr-2-until-fullhd {
    margin-right: 10px !important;
  }

  .mb-2-until-fullhd {
    margin-bottom: 10px !important;
  }

  .ml-2-until-fullhd {
    margin-left: 10px !important;
  }

  .mx-2-until-fullhd {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-until-fullhd {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-until-fullhd {
    margin: 15px !important;
  }

  .mt-3-until-fullhd {
    margin-top: 15px !important;
  }

  .mr-3-until-fullhd {
    margin-right: 15px !important;
  }

  .mb-3-until-fullhd {
    margin-bottom: 15px !important;
  }

  .ml-3-until-fullhd {
    margin-left: 15px !important;
  }

  .mx-3-until-fullhd {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-until-fullhd {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-until-fullhd {
    margin: 20px !important;
  }

  .mt-4-until-fullhd {
    margin-top: 20px !important;
  }

  .mr-4-until-fullhd {
    margin-right: 20px !important;
  }

  .mb-4-until-fullhd {
    margin-bottom: 20px !important;
  }

  .ml-4-until-fullhd {
    margin-left: 20px !important;
  }

  .mx-4-until-fullhd {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-until-fullhd {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-until-fullhd {
    margin: 25px !important;
  }

  .mt-5-until-fullhd {
    margin-top: 25px !important;
  }

  .mr-5-until-fullhd {
    margin-right: 25px !important;
  }

  .mb-5-until-fullhd {
    margin-bottom: 25px !important;
  }

  .ml-5-until-fullhd {
    margin-left: 25px !important;
  }

  .mx-5-until-fullhd {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-until-fullhd {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-until-fullhd {
    margin: 30px !important;
  }

  .mt-6-until-fullhd {
    margin-top: 30px !important;
  }

  .mr-6-until-fullhd {
    margin-right: 30px !important;
  }

  .mb-6-until-fullhd {
    margin-bottom: 30px !important;
  }

  .ml-6-until-fullhd {
    margin-left: 30px !important;
  }

  .mx-6-until-fullhd {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-until-fullhd {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-until-fullhd {
    margin: 40px !important;
  }

  .mt-7-until-fullhd {
    margin-top: 40px !important;
  }

  .mr-7-until-fullhd {
    margin-right: 40px !important;
  }

  .mb-7-until-fullhd {
    margin-bottom: 40px !important;
  }

  .ml-7-until-fullhd {
    margin-left: 40px !important;
  }

  .mx-7-until-fullhd {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-until-fullhd {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-until-fullhd {
    margin: 50px !important;
  }

  .mt-8-until-fullhd {
    margin-top: 50px !important;
  }

  .mr-8-until-fullhd {
    margin-right: 50px !important;
  }

  .mb-8-until-fullhd {
    margin-bottom: 50px !important;
  }

  .ml-8-until-fullhd {
    margin-left: 50px !important;
  }

  .mx-8-until-fullhd {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-until-fullhd {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-until-fullhd {
    margin: auto !important;
  }

  .mt-auto-until-fullhd {
    margin-top: auto !important;
  }

  .mr-auto-until-fullhd {
    margin-right: auto !important;
  }

  .mb-auto-until-fullhd {
    margin-bottom: auto !important;
  }

  .ml-auto-until-fullhd {
    margin-left: auto !important;
  }

  .mx-auto-until-fullhd {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-until-fullhd {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-until-fullhd {
    margin: -5px !important;
  }

  .mt--1-until-fullhd {
    margin-top: -5px !important;
  }

  .mr--1-until-fullhd {
    margin-right: -5px !important;
  }

  .mb--1-until-fullhd {
    margin-bottom: -5px !important;
  }

  .ml--1-until-fullhd {
    margin-left: -5px !important;
  }

  .mx--1-until-fullhd {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-until-fullhd {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-until-fullhd {
    margin: -10px !important;
  }

  .mt--2-until-fullhd {
    margin-top: -10px !important;
  }

  .mr--2-until-fullhd {
    margin-right: -10px !important;
  }

  .mb--2-until-fullhd {
    margin-bottom: -10px !important;
  }

  .ml--2-until-fullhd {
    margin-left: -10px !important;
  }

  .mx--2-until-fullhd {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-until-fullhd {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-until-fullhd {
    margin: -15px !important;
  }

  .mt--3-until-fullhd {
    margin-top: -15px !important;
  }

  .mr--3-until-fullhd {
    margin-right: -15px !important;
  }

  .mb--3-until-fullhd {
    margin-bottom: -15px !important;
  }

  .ml--3-until-fullhd {
    margin-left: -15px !important;
  }

  .mx--3-until-fullhd {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-until-fullhd {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-until-fullhd {
    margin: -20px !important;
  }

  .mt--4-until-fullhd {
    margin-top: -20px !important;
  }

  .mr--4-until-fullhd {
    margin-right: -20px !important;
  }

  .mb--4-until-fullhd {
    margin-bottom: -20px !important;
  }

  .ml--4-until-fullhd {
    margin-left: -20px !important;
  }

  .mx--4-until-fullhd {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-until-fullhd {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-until-fullhd {
    margin: -25px !important;
  }

  .mt--5-until-fullhd {
    margin-top: -25px !important;
  }

  .mr--5-until-fullhd {
    margin-right: -25px !important;
  }

  .mb--5-until-fullhd {
    margin-bottom: -25px !important;
  }

  .ml--5-until-fullhd {
    margin-left: -25px !important;
  }

  .mx--5-until-fullhd {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-until-fullhd {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-until-fullhd {
    margin: -30px !important;
  }

  .mt--6-until-fullhd {
    margin-top: -30px !important;
  }

  .mr--6-until-fullhd {
    margin-right: -30px !important;
  }

  .mb--6-until-fullhd {
    margin-bottom: -30px !important;
  }

  .ml--6-until-fullhd {
    margin-left: -30px !important;
  }

  .mx--6-until-fullhd {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-until-fullhd {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-until-fullhd {
    margin: -40px !important;
  }

  .mt--7-until-fullhd {
    margin-top: -40px !important;
  }

  .mr--7-until-fullhd {
    margin-right: -40px !important;
  }

  .mb--7-until-fullhd {
    margin-bottom: -40px !important;
  }

  .ml--7-until-fullhd {
    margin-left: -40px !important;
  }

  .mx--7-until-fullhd {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-until-fullhd {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-until-fullhd {
    margin: -50px !important;
  }

  .mt--8-until-fullhd {
    margin-top: -50px !important;
  }

  .mr--8-until-fullhd {
    margin-right: -50px !important;
  }

  .mb--8-until-fullhd {
    margin-bottom: -50px !important;
  }

  .ml--8-until-fullhd {
    margin-left: -50px !important;
  }

  .mx--8-until-fullhd {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-until-fullhd {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width >= 1408px) {
  .m-0-fullhd {
    margin: 0 !important;
  }

  .mt-0-fullhd {
    margin-top: 0 !important;
  }

  .mr-0-fullhd {
    margin-right: 0 !important;
  }

  .mb-0-fullhd {
    margin-bottom: 0 !important;
  }

  .ml-0-fullhd {
    margin-left: 0 !important;
  }

  .mx-0-fullhd {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0-fullhd {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-1-fullhd {
    margin: 5px !important;
  }

  .mt-1-fullhd {
    margin-top: 5px !important;
  }

  .mr-1-fullhd {
    margin-right: 5px !important;
  }

  .mb-1-fullhd {
    margin-bottom: 5px !important;
  }

  .ml-1-fullhd {
    margin-left: 5px !important;
  }

  .mx-1-fullhd {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-1-fullhd {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .m-2-fullhd {
    margin: 10px !important;
  }

  .mt-2-fullhd {
    margin-top: 10px !important;
  }

  .mr-2-fullhd {
    margin-right: 10px !important;
  }

  .mb-2-fullhd {
    margin-bottom: 10px !important;
  }

  .ml-2-fullhd {
    margin-left: 10px !important;
  }

  .mx-2-fullhd {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-2-fullhd {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-3-fullhd {
    margin: 15px !important;
  }

  .mt-3-fullhd {
    margin-top: 15px !important;
  }

  .mr-3-fullhd {
    margin-right: 15px !important;
  }

  .mb-3-fullhd {
    margin-bottom: 15px !important;
  }

  .ml-3-fullhd {
    margin-left: 15px !important;
  }

  .mx-3-fullhd {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-3-fullhd {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .m-4-fullhd {
    margin: 20px !important;
  }

  .mt-4-fullhd {
    margin-top: 20px !important;
  }

  .mr-4-fullhd {
    margin-right: 20px !important;
  }

  .mb-4-fullhd {
    margin-bottom: 20px !important;
  }

  .ml-4-fullhd {
    margin-left: 20px !important;
  }

  .mx-4-fullhd {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-4-fullhd {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-5-fullhd {
    margin: 25px !important;
  }

  .mt-5-fullhd {
    margin-top: 25px !important;
  }

  .mr-5-fullhd {
    margin-right: 25px !important;
  }

  .mb-5-fullhd {
    margin-bottom: 25px !important;
  }

  .ml-5-fullhd {
    margin-left: 25px !important;
  }

  .mx-5-fullhd {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .my-5-fullhd {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .m-6-fullhd {
    margin: 30px !important;
  }

  .mt-6-fullhd {
    margin-top: 30px !important;
  }

  .mr-6-fullhd {
    margin-right: 30px !important;
  }

  .mb-6-fullhd {
    margin-bottom: 30px !important;
  }

  .ml-6-fullhd {
    margin-left: 30px !important;
  }

  .mx-6-fullhd {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .my-6-fullhd {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-7-fullhd {
    margin: 40px !important;
  }

  .mt-7-fullhd {
    margin-top: 40px !important;
  }

  .mr-7-fullhd {
    margin-right: 40px !important;
  }

  .mb-7-fullhd {
    margin-bottom: 40px !important;
  }

  .ml-7-fullhd {
    margin-left: 40px !important;
  }

  .mx-7-fullhd {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .my-7-fullhd {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-8-fullhd {
    margin: 50px !important;
  }

  .mt-8-fullhd {
    margin-top: 50px !important;
  }

  .mr-8-fullhd {
    margin-right: 50px !important;
  }

  .mb-8-fullhd {
    margin-bottom: 50px !important;
  }

  .ml-8-fullhd {
    margin-left: 50px !important;
  }

  .mx-8-fullhd {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .my-8-fullhd {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .m-auto-fullhd {
    margin: auto !important;
  }

  .mt-auto-fullhd {
    margin-top: auto !important;
  }

  .mr-auto-fullhd {
    margin-right: auto !important;
  }

  .mb-auto-fullhd {
    margin-bottom: auto !important;
  }

  .ml-auto-fullhd {
    margin-left: auto !important;
  }

  .mx-auto-fullhd {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto-fullhd {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m--1-fullhd {
    margin: -5px !important;
  }

  .mt--1-fullhd {
    margin-top: -5px !important;
  }

  .mr--1-fullhd {
    margin-right: -5px !important;
  }

  .mb--1-fullhd {
    margin-bottom: -5px !important;
  }

  .ml--1-fullhd {
    margin-left: -5px !important;
  }

  .mx--1-fullhd {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .my--1-fullhd {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .m--2-fullhd {
    margin: -10px !important;
  }

  .mt--2-fullhd {
    margin-top: -10px !important;
  }

  .mr--2-fullhd {
    margin-right: -10px !important;
  }

  .mb--2-fullhd {
    margin-bottom: -10px !important;
  }

  .ml--2-fullhd {
    margin-left: -10px !important;
  }

  .mx--2-fullhd {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .my--2-fullhd {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .m--3-fullhd {
    margin: -15px !important;
  }

  .mt--3-fullhd {
    margin-top: -15px !important;
  }

  .mr--3-fullhd {
    margin-right: -15px !important;
  }

  .mb--3-fullhd {
    margin-bottom: -15px !important;
  }

  .ml--3-fullhd {
    margin-left: -15px !important;
  }

  .mx--3-fullhd {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .my--3-fullhd {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .m--4-fullhd {
    margin: -20px !important;
  }

  .mt--4-fullhd {
    margin-top: -20px !important;
  }

  .mr--4-fullhd {
    margin-right: -20px !important;
  }

  .mb--4-fullhd {
    margin-bottom: -20px !important;
  }

  .ml--4-fullhd {
    margin-left: -20px !important;
  }

  .mx--4-fullhd {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .my--4-fullhd {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m--5-fullhd {
    margin: -25px !important;
  }

  .mt--5-fullhd {
    margin-top: -25px !important;
  }

  .mr--5-fullhd {
    margin-right: -25px !important;
  }

  .mb--5-fullhd {
    margin-bottom: -25px !important;
  }

  .ml--5-fullhd {
    margin-left: -25px !important;
  }

  .mx--5-fullhd {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }

  .my--5-fullhd {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }

  .m--6-fullhd {
    margin: -30px !important;
  }

  .mt--6-fullhd {
    margin-top: -30px !important;
  }

  .mr--6-fullhd {
    margin-right: -30px !important;
  }

  .mb--6-fullhd {
    margin-bottom: -30px !important;
  }

  .ml--6-fullhd {
    margin-left: -30px !important;
  }

  .mx--6-fullhd {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }

  .my--6-fullhd {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }

  .m--7-fullhd {
    margin: -40px !important;
  }

  .mt--7-fullhd {
    margin-top: -40px !important;
  }

  .mr--7-fullhd {
    margin-right: -40px !important;
  }

  .mb--7-fullhd {
    margin-bottom: -40px !important;
  }

  .ml--7-fullhd {
    margin-left: -40px !important;
  }

  .mx--7-fullhd {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .my--7-fullhd {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m--8-fullhd {
    margin: -50px !important;
  }

  .mt--8-fullhd {
    margin-top: -50px !important;
  }

  .mr--8-fullhd {
    margin-right: -50px !important;
  }

  .mb--8-fullhd {
    margin-bottom: -50px !important;
  }

  .ml--8-fullhd {
    margin-left: -50px !important;
  }

  .mx--8-fullhd {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .my--8-fullhd {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
  }
}

@media screen and (width <= 768px) {
  .p-0-mobile {
    padding: 0 !important;
  }

  .pt-0-mobile {
    padding-top: 0 !important;
  }

  .pr-0-mobile {
    padding-right: 0 !important;
  }

  .pb-0-mobile {
    padding-bottom: 0 !important;
  }

  .pl-0-mobile {
    padding-left: 0 !important;
  }

  .px-0-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-mobile {
    padding: 5px !important;
  }

  .pt-1-mobile {
    padding-top: 5px !important;
  }

  .pr-1-mobile {
    padding-right: 5px !important;
  }

  .pb-1-mobile {
    padding-bottom: 5px !important;
  }

  .pl-1-mobile {
    padding-left: 5px !important;
  }

  .px-1-mobile {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-mobile {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-mobile {
    padding: 10px !important;
  }

  .pt-2-mobile {
    padding-top: 10px !important;
  }

  .pr-2-mobile {
    padding-right: 10px !important;
  }

  .pb-2-mobile {
    padding-bottom: 10px !important;
  }

  .pl-2-mobile {
    padding-left: 10px !important;
  }

  .px-2-mobile {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-mobile {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-mobile {
    padding: 15px !important;
  }

  .pt-3-mobile {
    padding-top: 15px !important;
  }

  .pr-3-mobile {
    padding-right: 15px !important;
  }

  .pb-3-mobile {
    padding-bottom: 15px !important;
  }

  .pl-3-mobile {
    padding-left: 15px !important;
  }

  .px-3-mobile {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-mobile {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-mobile {
    padding: 20px !important;
  }

  .pt-4-mobile {
    padding-top: 20px !important;
  }

  .pr-4-mobile {
    padding-right: 20px !important;
  }

  .pb-4-mobile {
    padding-bottom: 20px !important;
  }

  .pl-4-mobile {
    padding-left: 20px !important;
  }

  .px-4-mobile {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-mobile {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-mobile {
    padding: 25px !important;
  }

  .pt-5-mobile {
    padding-top: 25px !important;
  }

  .pr-5-mobile {
    padding-right: 25px !important;
  }

  .pb-5-mobile {
    padding-bottom: 25px !important;
  }

  .pl-5-mobile {
    padding-left: 25px !important;
  }

  .px-5-mobile {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-mobile {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-mobile {
    padding: 30px !important;
  }

  .pt-6-mobile {
    padding-top: 30px !important;
  }

  .pr-6-mobile {
    padding-right: 30px !important;
  }

  .pb-6-mobile {
    padding-bottom: 30px !important;
  }

  .pl-6-mobile {
    padding-left: 30px !important;
  }

  .px-6-mobile {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-mobile {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-mobile {
    padding: 40px !important;
  }

  .pt-7-mobile {
    padding-top: 40px !important;
  }

  .pr-7-mobile {
    padding-right: 40px !important;
  }

  .pb-7-mobile {
    padding-bottom: 40px !important;
  }

  .pl-7-mobile {
    padding-left: 40px !important;
  }

  .px-7-mobile {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-mobile {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-mobile {
    padding: 50px !important;
  }

  .pt-8-mobile {
    padding-top: 50px !important;
  }

  .pr-8-mobile {
    padding-right: 50px !important;
  }

  .pb-8-mobile {
    padding-bottom: 50px !important;
  }

  .pl-8-mobile {
    padding-left: 50px !important;
  }

  .px-8-mobile {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-mobile {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-mobile {
    padding: auto !important;
  }

  .pt-auto-mobile {
    padding-top: auto !important;
  }

  .pr-auto-mobile {
    padding-right: auto !important;
  }

  .pb-auto-mobile {
    padding-bottom: auto !important;
  }

  .pl-auto-mobile {
    padding-left: auto !important;
  }

  .px-auto-mobile {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-mobile {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-mobile {
    padding: -5px !important;
  }

  .pt--1-mobile {
    padding-top: -5px !important;
  }

  .pr--1-mobile {
    padding-right: -5px !important;
  }

  .pb--1-mobile {
    padding-bottom: -5px !important;
  }

  .pl--1-mobile {
    padding-left: -5px !important;
  }

  .px--1-mobile {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-mobile {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-mobile {
    padding: -10px !important;
  }

  .pt--2-mobile {
    padding-top: -10px !important;
  }

  .pr--2-mobile {
    padding-right: -10px !important;
  }

  .pb--2-mobile {
    padding-bottom: -10px !important;
  }

  .pl--2-mobile {
    padding-left: -10px !important;
  }

  .px--2-mobile {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-mobile {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-mobile {
    padding: -15px !important;
  }

  .pt--3-mobile {
    padding-top: -15px !important;
  }

  .pr--3-mobile {
    padding-right: -15px !important;
  }

  .pb--3-mobile {
    padding-bottom: -15px !important;
  }

  .pl--3-mobile {
    padding-left: -15px !important;
  }

  .px--3-mobile {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-mobile {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-mobile {
    padding: -20px !important;
  }

  .pt--4-mobile {
    padding-top: -20px !important;
  }

  .pr--4-mobile {
    padding-right: -20px !important;
  }

  .pb--4-mobile {
    padding-bottom: -20px !important;
  }

  .pl--4-mobile {
    padding-left: -20px !important;
  }

  .px--4-mobile {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-mobile {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-mobile {
    padding: -25px !important;
  }

  .pt--5-mobile {
    padding-top: -25px !important;
  }

  .pr--5-mobile {
    padding-right: -25px !important;
  }

  .pb--5-mobile {
    padding-bottom: -25px !important;
  }

  .pl--5-mobile {
    padding-left: -25px !important;
  }

  .px--5-mobile {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-mobile {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-mobile {
    padding: -30px !important;
  }

  .pt--6-mobile {
    padding-top: -30px !important;
  }

  .pr--6-mobile {
    padding-right: -30px !important;
  }

  .pb--6-mobile {
    padding-bottom: -30px !important;
  }

  .pl--6-mobile {
    padding-left: -30px !important;
  }

  .px--6-mobile {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-mobile {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-mobile {
    padding: -40px !important;
  }

  .pt--7-mobile {
    padding-top: -40px !important;
  }

  .pr--7-mobile {
    padding-right: -40px !important;
  }

  .pb--7-mobile {
    padding-bottom: -40px !important;
  }

  .pl--7-mobile {
    padding-left: -40px !important;
  }

  .px--7-mobile {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-mobile {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-mobile {
    padding: -50px !important;
  }

  .pt--8-mobile {
    padding-top: -50px !important;
  }

  .pr--8-mobile {
    padding-right: -50px !important;
  }

  .pb--8-mobile {
    padding-bottom: -50px !important;
  }

  .pl--8-mobile {
    padding-left: -50px !important;
  }

  .px--8-mobile {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-mobile {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 769px) {
  .p-0-tablet {
    padding: 0 !important;
  }

  .pt-0-tablet {
    padding-top: 0 !important;
  }

  .pr-0-tablet {
    padding-right: 0 !important;
  }

  .pb-0-tablet {
    padding-bottom: 0 !important;
  }

  .pl-0-tablet {
    padding-left: 0 !important;
  }

  .px-0-tablet {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-tablet {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-tablet {
    padding: 5px !important;
  }

  .pt-1-tablet {
    padding-top: 5px !important;
  }

  .pr-1-tablet {
    padding-right: 5px !important;
  }

  .pb-1-tablet {
    padding-bottom: 5px !important;
  }

  .pl-1-tablet {
    padding-left: 5px !important;
  }

  .px-1-tablet {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-tablet {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-tablet {
    padding: 10px !important;
  }

  .pt-2-tablet {
    padding-top: 10px !important;
  }

  .pr-2-tablet {
    padding-right: 10px !important;
  }

  .pb-2-tablet {
    padding-bottom: 10px !important;
  }

  .pl-2-tablet {
    padding-left: 10px !important;
  }

  .px-2-tablet {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-tablet {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-tablet {
    padding: 15px !important;
  }

  .pt-3-tablet {
    padding-top: 15px !important;
  }

  .pr-3-tablet {
    padding-right: 15px !important;
  }

  .pb-3-tablet {
    padding-bottom: 15px !important;
  }

  .pl-3-tablet {
    padding-left: 15px !important;
  }

  .px-3-tablet {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-tablet {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-tablet {
    padding: 20px !important;
  }

  .pt-4-tablet {
    padding-top: 20px !important;
  }

  .pr-4-tablet {
    padding-right: 20px !important;
  }

  .pb-4-tablet {
    padding-bottom: 20px !important;
  }

  .pl-4-tablet {
    padding-left: 20px !important;
  }

  .px-4-tablet {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-tablet {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-tablet {
    padding: 25px !important;
  }

  .pt-5-tablet {
    padding-top: 25px !important;
  }

  .pr-5-tablet {
    padding-right: 25px !important;
  }

  .pb-5-tablet {
    padding-bottom: 25px !important;
  }

  .pl-5-tablet {
    padding-left: 25px !important;
  }

  .px-5-tablet {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-tablet {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-tablet {
    padding: 30px !important;
  }

  .pt-6-tablet {
    padding-top: 30px !important;
  }

  .pr-6-tablet {
    padding-right: 30px !important;
  }

  .pb-6-tablet {
    padding-bottom: 30px !important;
  }

  .pl-6-tablet {
    padding-left: 30px !important;
  }

  .px-6-tablet {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-tablet {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-tablet {
    padding: 40px !important;
  }

  .pt-7-tablet {
    padding-top: 40px !important;
  }

  .pr-7-tablet {
    padding-right: 40px !important;
  }

  .pb-7-tablet {
    padding-bottom: 40px !important;
  }

  .pl-7-tablet {
    padding-left: 40px !important;
  }

  .px-7-tablet {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-tablet {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-tablet {
    padding: 50px !important;
  }

  .pt-8-tablet {
    padding-top: 50px !important;
  }

  .pr-8-tablet {
    padding-right: 50px !important;
  }

  .pb-8-tablet {
    padding-bottom: 50px !important;
  }

  .pl-8-tablet {
    padding-left: 50px !important;
  }

  .px-8-tablet {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-tablet {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-tablet {
    padding: auto !important;
  }

  .pt-auto-tablet {
    padding-top: auto !important;
  }

  .pr-auto-tablet {
    padding-right: auto !important;
  }

  .pb-auto-tablet {
    padding-bottom: auto !important;
  }

  .pl-auto-tablet {
    padding-left: auto !important;
  }

  .px-auto-tablet {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-tablet {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-tablet {
    padding: -5px !important;
  }

  .pt--1-tablet {
    padding-top: -5px !important;
  }

  .pr--1-tablet {
    padding-right: -5px !important;
  }

  .pb--1-tablet {
    padding-bottom: -5px !important;
  }

  .pl--1-tablet {
    padding-left: -5px !important;
  }

  .px--1-tablet {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-tablet {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-tablet {
    padding: -10px !important;
  }

  .pt--2-tablet {
    padding-top: -10px !important;
  }

  .pr--2-tablet {
    padding-right: -10px !important;
  }

  .pb--2-tablet {
    padding-bottom: -10px !important;
  }

  .pl--2-tablet {
    padding-left: -10px !important;
  }

  .px--2-tablet {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-tablet {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-tablet {
    padding: -15px !important;
  }

  .pt--3-tablet {
    padding-top: -15px !important;
  }

  .pr--3-tablet {
    padding-right: -15px !important;
  }

  .pb--3-tablet {
    padding-bottom: -15px !important;
  }

  .pl--3-tablet {
    padding-left: -15px !important;
  }

  .px--3-tablet {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-tablet {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-tablet {
    padding: -20px !important;
  }

  .pt--4-tablet {
    padding-top: -20px !important;
  }

  .pr--4-tablet {
    padding-right: -20px !important;
  }

  .pb--4-tablet {
    padding-bottom: -20px !important;
  }

  .pl--4-tablet {
    padding-left: -20px !important;
  }

  .px--4-tablet {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-tablet {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-tablet {
    padding: -25px !important;
  }

  .pt--5-tablet {
    padding-top: -25px !important;
  }

  .pr--5-tablet {
    padding-right: -25px !important;
  }

  .pb--5-tablet {
    padding-bottom: -25px !important;
  }

  .pl--5-tablet {
    padding-left: -25px !important;
  }

  .px--5-tablet {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-tablet {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-tablet {
    padding: -30px !important;
  }

  .pt--6-tablet {
    padding-top: -30px !important;
  }

  .pr--6-tablet {
    padding-right: -30px !important;
  }

  .pb--6-tablet {
    padding-bottom: -30px !important;
  }

  .pl--6-tablet {
    padding-left: -30px !important;
  }

  .px--6-tablet {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-tablet {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-tablet {
    padding: -40px !important;
  }

  .pt--7-tablet {
    padding-top: -40px !important;
  }

  .pr--7-tablet {
    padding-right: -40px !important;
  }

  .pb--7-tablet {
    padding-bottom: -40px !important;
  }

  .pl--7-tablet {
    padding-left: -40px !important;
  }

  .px--7-tablet {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-tablet {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-tablet {
    padding: -50px !important;
  }

  .pt--8-tablet {
    padding-top: -50px !important;
  }

  .pr--8-tablet {
    padding-right: -50px !important;
  }

  .pb--8-tablet {
    padding-bottom: -50px !important;
  }

  .pl--8-tablet {
    padding-left: -50px !important;
  }

  .px--8-tablet {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-tablet {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .p-0-tablet-only {
    padding: 0 !important;
  }

  .pt-0-tablet-only {
    padding-top: 0 !important;
  }

  .pr-0-tablet-only {
    padding-right: 0 !important;
  }

  .pb-0-tablet-only {
    padding-bottom: 0 !important;
  }

  .pl-0-tablet-only {
    padding-left: 0 !important;
  }

  .px-0-tablet-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-tablet-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-tablet-only {
    padding: 5px !important;
  }

  .pt-1-tablet-only {
    padding-top: 5px !important;
  }

  .pr-1-tablet-only {
    padding-right: 5px !important;
  }

  .pb-1-tablet-only {
    padding-bottom: 5px !important;
  }

  .pl-1-tablet-only {
    padding-left: 5px !important;
  }

  .px-1-tablet-only {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-tablet-only {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-tablet-only {
    padding: 10px !important;
  }

  .pt-2-tablet-only {
    padding-top: 10px !important;
  }

  .pr-2-tablet-only {
    padding-right: 10px !important;
  }

  .pb-2-tablet-only {
    padding-bottom: 10px !important;
  }

  .pl-2-tablet-only {
    padding-left: 10px !important;
  }

  .px-2-tablet-only {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-tablet-only {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-tablet-only {
    padding: 15px !important;
  }

  .pt-3-tablet-only {
    padding-top: 15px !important;
  }

  .pr-3-tablet-only {
    padding-right: 15px !important;
  }

  .pb-3-tablet-only {
    padding-bottom: 15px !important;
  }

  .pl-3-tablet-only {
    padding-left: 15px !important;
  }

  .px-3-tablet-only {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-tablet-only {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-tablet-only {
    padding: 20px !important;
  }

  .pt-4-tablet-only {
    padding-top: 20px !important;
  }

  .pr-4-tablet-only {
    padding-right: 20px !important;
  }

  .pb-4-tablet-only {
    padding-bottom: 20px !important;
  }

  .pl-4-tablet-only {
    padding-left: 20px !important;
  }

  .px-4-tablet-only {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-tablet-only {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-tablet-only {
    padding: 25px !important;
  }

  .pt-5-tablet-only {
    padding-top: 25px !important;
  }

  .pr-5-tablet-only {
    padding-right: 25px !important;
  }

  .pb-5-tablet-only {
    padding-bottom: 25px !important;
  }

  .pl-5-tablet-only {
    padding-left: 25px !important;
  }

  .px-5-tablet-only {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-tablet-only {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-tablet-only {
    padding: 30px !important;
  }

  .pt-6-tablet-only {
    padding-top: 30px !important;
  }

  .pr-6-tablet-only {
    padding-right: 30px !important;
  }

  .pb-6-tablet-only {
    padding-bottom: 30px !important;
  }

  .pl-6-tablet-only {
    padding-left: 30px !important;
  }

  .px-6-tablet-only {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-tablet-only {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-tablet-only {
    padding: 40px !important;
  }

  .pt-7-tablet-only {
    padding-top: 40px !important;
  }

  .pr-7-tablet-only {
    padding-right: 40px !important;
  }

  .pb-7-tablet-only {
    padding-bottom: 40px !important;
  }

  .pl-7-tablet-only {
    padding-left: 40px !important;
  }

  .px-7-tablet-only {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-tablet-only {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-tablet-only {
    padding: 50px !important;
  }

  .pt-8-tablet-only {
    padding-top: 50px !important;
  }

  .pr-8-tablet-only {
    padding-right: 50px !important;
  }

  .pb-8-tablet-only {
    padding-bottom: 50px !important;
  }

  .pl-8-tablet-only {
    padding-left: 50px !important;
  }

  .px-8-tablet-only {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-tablet-only {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-tablet-only {
    padding: auto !important;
  }

  .pt-auto-tablet-only {
    padding-top: auto !important;
  }

  .pr-auto-tablet-only {
    padding-right: auto !important;
  }

  .pb-auto-tablet-only {
    padding-bottom: auto !important;
  }

  .pl-auto-tablet-only {
    padding-left: auto !important;
  }

  .px-auto-tablet-only {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-tablet-only {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-tablet-only {
    padding: -5px !important;
  }

  .pt--1-tablet-only {
    padding-top: -5px !important;
  }

  .pr--1-tablet-only {
    padding-right: -5px !important;
  }

  .pb--1-tablet-only {
    padding-bottom: -5px !important;
  }

  .pl--1-tablet-only {
    padding-left: -5px !important;
  }

  .px--1-tablet-only {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-tablet-only {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-tablet-only {
    padding: -10px !important;
  }

  .pt--2-tablet-only {
    padding-top: -10px !important;
  }

  .pr--2-tablet-only {
    padding-right: -10px !important;
  }

  .pb--2-tablet-only {
    padding-bottom: -10px !important;
  }

  .pl--2-tablet-only {
    padding-left: -10px !important;
  }

  .px--2-tablet-only {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-tablet-only {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-tablet-only {
    padding: -15px !important;
  }

  .pt--3-tablet-only {
    padding-top: -15px !important;
  }

  .pr--3-tablet-only {
    padding-right: -15px !important;
  }

  .pb--3-tablet-only {
    padding-bottom: -15px !important;
  }

  .pl--3-tablet-only {
    padding-left: -15px !important;
  }

  .px--3-tablet-only {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-tablet-only {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-tablet-only {
    padding: -20px !important;
  }

  .pt--4-tablet-only {
    padding-top: -20px !important;
  }

  .pr--4-tablet-only {
    padding-right: -20px !important;
  }

  .pb--4-tablet-only {
    padding-bottom: -20px !important;
  }

  .pl--4-tablet-only {
    padding-left: -20px !important;
  }

  .px--4-tablet-only {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-tablet-only {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-tablet-only {
    padding: -25px !important;
  }

  .pt--5-tablet-only {
    padding-top: -25px !important;
  }

  .pr--5-tablet-only {
    padding-right: -25px !important;
  }

  .pb--5-tablet-only {
    padding-bottom: -25px !important;
  }

  .pl--5-tablet-only {
    padding-left: -25px !important;
  }

  .px--5-tablet-only {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-tablet-only {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-tablet-only {
    padding: -30px !important;
  }

  .pt--6-tablet-only {
    padding-top: -30px !important;
  }

  .pr--6-tablet-only {
    padding-right: -30px !important;
  }

  .pb--6-tablet-only {
    padding-bottom: -30px !important;
  }

  .pl--6-tablet-only {
    padding-left: -30px !important;
  }

  .px--6-tablet-only {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-tablet-only {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-tablet-only {
    padding: -40px !important;
  }

  .pt--7-tablet-only {
    padding-top: -40px !important;
  }

  .pr--7-tablet-only {
    padding-right: -40px !important;
  }

  .pb--7-tablet-only {
    padding-bottom: -40px !important;
  }

  .pl--7-tablet-only {
    padding-left: -40px !important;
  }

  .px--7-tablet-only {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-tablet-only {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-tablet-only {
    padding: -50px !important;
  }

  .pt--8-tablet-only {
    padding-top: -50px !important;
  }

  .pr--8-tablet-only {
    padding-right: -50px !important;
  }

  .pb--8-tablet-only {
    padding-bottom: -50px !important;
  }

  .pl--8-tablet-only {
    padding-left: -50px !important;
  }

  .px--8-tablet-only {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-tablet-only {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width <= 1023px) {
  .p-0-touch {
    padding: 0 !important;
  }

  .pt-0-touch {
    padding-top: 0 !important;
  }

  .pr-0-touch {
    padding-right: 0 !important;
  }

  .pb-0-touch {
    padding-bottom: 0 !important;
  }

  .pl-0-touch {
    padding-left: 0 !important;
  }

  .px-0-touch {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-touch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-touch {
    padding: 5px !important;
  }

  .pt-1-touch {
    padding-top: 5px !important;
  }

  .pr-1-touch {
    padding-right: 5px !important;
  }

  .pb-1-touch {
    padding-bottom: 5px !important;
  }

  .pl-1-touch {
    padding-left: 5px !important;
  }

  .px-1-touch {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-touch {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-touch {
    padding: 10px !important;
  }

  .pt-2-touch {
    padding-top: 10px !important;
  }

  .pr-2-touch {
    padding-right: 10px !important;
  }

  .pb-2-touch {
    padding-bottom: 10px !important;
  }

  .pl-2-touch {
    padding-left: 10px !important;
  }

  .px-2-touch {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-touch {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-touch {
    padding: 15px !important;
  }

  .pt-3-touch {
    padding-top: 15px !important;
  }

  .pr-3-touch {
    padding-right: 15px !important;
  }

  .pb-3-touch {
    padding-bottom: 15px !important;
  }

  .pl-3-touch {
    padding-left: 15px !important;
  }

  .px-3-touch {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-touch {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-touch {
    padding: 20px !important;
  }

  .pt-4-touch {
    padding-top: 20px !important;
  }

  .pr-4-touch {
    padding-right: 20px !important;
  }

  .pb-4-touch {
    padding-bottom: 20px !important;
  }

  .pl-4-touch {
    padding-left: 20px !important;
  }

  .px-4-touch {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-touch {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-touch {
    padding: 25px !important;
  }

  .pt-5-touch {
    padding-top: 25px !important;
  }

  .pr-5-touch {
    padding-right: 25px !important;
  }

  .pb-5-touch {
    padding-bottom: 25px !important;
  }

  .pl-5-touch {
    padding-left: 25px !important;
  }

  .px-5-touch {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-touch {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-touch {
    padding: 30px !important;
  }

  .pt-6-touch {
    padding-top: 30px !important;
  }

  .pr-6-touch {
    padding-right: 30px !important;
  }

  .pb-6-touch {
    padding-bottom: 30px !important;
  }

  .pl-6-touch {
    padding-left: 30px !important;
  }

  .px-6-touch {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-touch {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-touch {
    padding: 40px !important;
  }

  .pt-7-touch {
    padding-top: 40px !important;
  }

  .pr-7-touch {
    padding-right: 40px !important;
  }

  .pb-7-touch {
    padding-bottom: 40px !important;
  }

  .pl-7-touch {
    padding-left: 40px !important;
  }

  .px-7-touch {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-touch {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-touch {
    padding: 50px !important;
  }

  .pt-8-touch {
    padding-top: 50px !important;
  }

  .pr-8-touch {
    padding-right: 50px !important;
  }

  .pb-8-touch {
    padding-bottom: 50px !important;
  }

  .pl-8-touch {
    padding-left: 50px !important;
  }

  .px-8-touch {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-touch {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-touch {
    padding: auto !important;
  }

  .pt-auto-touch {
    padding-top: auto !important;
  }

  .pr-auto-touch {
    padding-right: auto !important;
  }

  .pb-auto-touch {
    padding-bottom: auto !important;
  }

  .pl-auto-touch {
    padding-left: auto !important;
  }

  .px-auto-touch {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-touch {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-touch {
    padding: -5px !important;
  }

  .pt--1-touch {
    padding-top: -5px !important;
  }

  .pr--1-touch {
    padding-right: -5px !important;
  }

  .pb--1-touch {
    padding-bottom: -5px !important;
  }

  .pl--1-touch {
    padding-left: -5px !important;
  }

  .px--1-touch {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-touch {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-touch {
    padding: -10px !important;
  }

  .pt--2-touch {
    padding-top: -10px !important;
  }

  .pr--2-touch {
    padding-right: -10px !important;
  }

  .pb--2-touch {
    padding-bottom: -10px !important;
  }

  .pl--2-touch {
    padding-left: -10px !important;
  }

  .px--2-touch {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-touch {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-touch {
    padding: -15px !important;
  }

  .pt--3-touch {
    padding-top: -15px !important;
  }

  .pr--3-touch {
    padding-right: -15px !important;
  }

  .pb--3-touch {
    padding-bottom: -15px !important;
  }

  .pl--3-touch {
    padding-left: -15px !important;
  }

  .px--3-touch {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-touch {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-touch {
    padding: -20px !important;
  }

  .pt--4-touch {
    padding-top: -20px !important;
  }

  .pr--4-touch {
    padding-right: -20px !important;
  }

  .pb--4-touch {
    padding-bottom: -20px !important;
  }

  .pl--4-touch {
    padding-left: -20px !important;
  }

  .px--4-touch {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-touch {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-touch {
    padding: -25px !important;
  }

  .pt--5-touch {
    padding-top: -25px !important;
  }

  .pr--5-touch {
    padding-right: -25px !important;
  }

  .pb--5-touch {
    padding-bottom: -25px !important;
  }

  .pl--5-touch {
    padding-left: -25px !important;
  }

  .px--5-touch {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-touch {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-touch {
    padding: -30px !important;
  }

  .pt--6-touch {
    padding-top: -30px !important;
  }

  .pr--6-touch {
    padding-right: -30px !important;
  }

  .pb--6-touch {
    padding-bottom: -30px !important;
  }

  .pl--6-touch {
    padding-left: -30px !important;
  }

  .px--6-touch {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-touch {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-touch {
    padding: -40px !important;
  }

  .pt--7-touch {
    padding-top: -40px !important;
  }

  .pr--7-touch {
    padding-right: -40px !important;
  }

  .pb--7-touch {
    padding-bottom: -40px !important;
  }

  .pl--7-touch {
    padding-left: -40px !important;
  }

  .px--7-touch {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-touch {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-touch {
    padding: -50px !important;
  }

  .pt--8-touch {
    padding-top: -50px !important;
  }

  .pr--8-touch {
    padding-right: -50px !important;
  }

  .pb--8-touch {
    padding-bottom: -50px !important;
  }

  .pl--8-touch {
    padding-left: -50px !important;
  }

  .px--8-touch {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-touch {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 1024px) {
  .p-0-desktop {
    padding: 0 !important;
  }

  .pt-0-desktop {
    padding-top: 0 !important;
  }

  .pr-0-desktop {
    padding-right: 0 !important;
  }

  .pb-0-desktop {
    padding-bottom: 0 !important;
  }

  .pl-0-desktop {
    padding-left: 0 !important;
  }

  .px-0-desktop {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-desktop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-desktop {
    padding: 5px !important;
  }

  .pt-1-desktop {
    padding-top: 5px !important;
  }

  .pr-1-desktop {
    padding-right: 5px !important;
  }

  .pb-1-desktop {
    padding-bottom: 5px !important;
  }

  .pl-1-desktop {
    padding-left: 5px !important;
  }

  .px-1-desktop {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-desktop {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-desktop {
    padding: 10px !important;
  }

  .pt-2-desktop {
    padding-top: 10px !important;
  }

  .pr-2-desktop {
    padding-right: 10px !important;
  }

  .pb-2-desktop {
    padding-bottom: 10px !important;
  }

  .pl-2-desktop {
    padding-left: 10px !important;
  }

  .px-2-desktop {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-desktop {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-desktop {
    padding: 15px !important;
  }

  .pt-3-desktop {
    padding-top: 15px !important;
  }

  .pr-3-desktop {
    padding-right: 15px !important;
  }

  .pb-3-desktop {
    padding-bottom: 15px !important;
  }

  .pl-3-desktop {
    padding-left: 15px !important;
  }

  .px-3-desktop {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-desktop {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-desktop {
    padding: 20px !important;
  }

  .pt-4-desktop {
    padding-top: 20px !important;
  }

  .pr-4-desktop {
    padding-right: 20px !important;
  }

  .pb-4-desktop {
    padding-bottom: 20px !important;
  }

  .pl-4-desktop {
    padding-left: 20px !important;
  }

  .px-4-desktop {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-desktop {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-desktop {
    padding: 25px !important;
  }

  .pt-5-desktop {
    padding-top: 25px !important;
  }

  .pr-5-desktop {
    padding-right: 25px !important;
  }

  .pb-5-desktop {
    padding-bottom: 25px !important;
  }

  .pl-5-desktop {
    padding-left: 25px !important;
  }

  .px-5-desktop {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-desktop {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-desktop {
    padding: 30px !important;
  }

  .pt-6-desktop {
    padding-top: 30px !important;
  }

  .pr-6-desktop {
    padding-right: 30px !important;
  }

  .pb-6-desktop {
    padding-bottom: 30px !important;
  }

  .pl-6-desktop {
    padding-left: 30px !important;
  }

  .px-6-desktop {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-desktop {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-desktop {
    padding: 40px !important;
  }

  .pt-7-desktop {
    padding-top: 40px !important;
  }

  .pr-7-desktop {
    padding-right: 40px !important;
  }

  .pb-7-desktop {
    padding-bottom: 40px !important;
  }

  .pl-7-desktop {
    padding-left: 40px !important;
  }

  .px-7-desktop {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-desktop {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-desktop {
    padding: 50px !important;
  }

  .pt-8-desktop {
    padding-top: 50px !important;
  }

  .pr-8-desktop {
    padding-right: 50px !important;
  }

  .pb-8-desktop {
    padding-bottom: 50px !important;
  }

  .pl-8-desktop {
    padding-left: 50px !important;
  }

  .px-8-desktop {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-desktop {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-desktop {
    padding: auto !important;
  }

  .pt-auto-desktop {
    padding-top: auto !important;
  }

  .pr-auto-desktop {
    padding-right: auto !important;
  }

  .pb-auto-desktop {
    padding-bottom: auto !important;
  }

  .pl-auto-desktop {
    padding-left: auto !important;
  }

  .px-auto-desktop {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-desktop {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-desktop {
    padding: -5px !important;
  }

  .pt--1-desktop {
    padding-top: -5px !important;
  }

  .pr--1-desktop {
    padding-right: -5px !important;
  }

  .pb--1-desktop {
    padding-bottom: -5px !important;
  }

  .pl--1-desktop {
    padding-left: -5px !important;
  }

  .px--1-desktop {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-desktop {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-desktop {
    padding: -10px !important;
  }

  .pt--2-desktop {
    padding-top: -10px !important;
  }

  .pr--2-desktop {
    padding-right: -10px !important;
  }

  .pb--2-desktop {
    padding-bottom: -10px !important;
  }

  .pl--2-desktop {
    padding-left: -10px !important;
  }

  .px--2-desktop {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-desktop {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-desktop {
    padding: -15px !important;
  }

  .pt--3-desktop {
    padding-top: -15px !important;
  }

  .pr--3-desktop {
    padding-right: -15px !important;
  }

  .pb--3-desktop {
    padding-bottom: -15px !important;
  }

  .pl--3-desktop {
    padding-left: -15px !important;
  }

  .px--3-desktop {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-desktop {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-desktop {
    padding: -20px !important;
  }

  .pt--4-desktop {
    padding-top: -20px !important;
  }

  .pr--4-desktop {
    padding-right: -20px !important;
  }

  .pb--4-desktop {
    padding-bottom: -20px !important;
  }

  .pl--4-desktop {
    padding-left: -20px !important;
  }

  .px--4-desktop {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-desktop {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-desktop {
    padding: -25px !important;
  }

  .pt--5-desktop {
    padding-top: -25px !important;
  }

  .pr--5-desktop {
    padding-right: -25px !important;
  }

  .pb--5-desktop {
    padding-bottom: -25px !important;
  }

  .pl--5-desktop {
    padding-left: -25px !important;
  }

  .px--5-desktop {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-desktop {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-desktop {
    padding: -30px !important;
  }

  .pt--6-desktop {
    padding-top: -30px !important;
  }

  .pr--6-desktop {
    padding-right: -30px !important;
  }

  .pb--6-desktop {
    padding-bottom: -30px !important;
  }

  .pl--6-desktop {
    padding-left: -30px !important;
  }

  .px--6-desktop {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-desktop {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-desktop {
    padding: -40px !important;
  }

  .pt--7-desktop {
    padding-top: -40px !important;
  }

  .pr--7-desktop {
    padding-right: -40px !important;
  }

  .pb--7-desktop {
    padding-bottom: -40px !important;
  }

  .pl--7-desktop {
    padding-left: -40px !important;
  }

  .px--7-desktop {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-desktop {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-desktop {
    padding: -50px !important;
  }

  .pt--8-desktop {
    padding-top: -50px !important;
  }

  .pr--8-desktop {
    padding-right: -50px !important;
  }

  .pb--8-desktop {
    padding-bottom: -50px !important;
  }

  .pl--8-desktop {
    padding-left: -50px !important;
  }

  .px--8-desktop {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-desktop {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .p-0-desktop-only {
    padding: 0 !important;
  }

  .pt-0-desktop-only {
    padding-top: 0 !important;
  }

  .pr-0-desktop-only {
    padding-right: 0 !important;
  }

  .pb-0-desktop-only {
    padding-bottom: 0 !important;
  }

  .pl-0-desktop-only {
    padding-left: 0 !important;
  }

  .px-0-desktop-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-desktop-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-desktop-only {
    padding: 5px !important;
  }

  .pt-1-desktop-only {
    padding-top: 5px !important;
  }

  .pr-1-desktop-only {
    padding-right: 5px !important;
  }

  .pb-1-desktop-only {
    padding-bottom: 5px !important;
  }

  .pl-1-desktop-only {
    padding-left: 5px !important;
  }

  .px-1-desktop-only {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-desktop-only {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-desktop-only {
    padding: 10px !important;
  }

  .pt-2-desktop-only {
    padding-top: 10px !important;
  }

  .pr-2-desktop-only {
    padding-right: 10px !important;
  }

  .pb-2-desktop-only {
    padding-bottom: 10px !important;
  }

  .pl-2-desktop-only {
    padding-left: 10px !important;
  }

  .px-2-desktop-only {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-desktop-only {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-desktop-only {
    padding: 15px !important;
  }

  .pt-3-desktop-only {
    padding-top: 15px !important;
  }

  .pr-3-desktop-only {
    padding-right: 15px !important;
  }

  .pb-3-desktop-only {
    padding-bottom: 15px !important;
  }

  .pl-3-desktop-only {
    padding-left: 15px !important;
  }

  .px-3-desktop-only {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-desktop-only {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-desktop-only {
    padding: 20px !important;
  }

  .pt-4-desktop-only {
    padding-top: 20px !important;
  }

  .pr-4-desktop-only {
    padding-right: 20px !important;
  }

  .pb-4-desktop-only {
    padding-bottom: 20px !important;
  }

  .pl-4-desktop-only {
    padding-left: 20px !important;
  }

  .px-4-desktop-only {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-desktop-only {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-desktop-only {
    padding: 25px !important;
  }

  .pt-5-desktop-only {
    padding-top: 25px !important;
  }

  .pr-5-desktop-only {
    padding-right: 25px !important;
  }

  .pb-5-desktop-only {
    padding-bottom: 25px !important;
  }

  .pl-5-desktop-only {
    padding-left: 25px !important;
  }

  .px-5-desktop-only {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-desktop-only {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-desktop-only {
    padding: 30px !important;
  }

  .pt-6-desktop-only {
    padding-top: 30px !important;
  }

  .pr-6-desktop-only {
    padding-right: 30px !important;
  }

  .pb-6-desktop-only {
    padding-bottom: 30px !important;
  }

  .pl-6-desktop-only {
    padding-left: 30px !important;
  }

  .px-6-desktop-only {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-desktop-only {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-desktop-only {
    padding: 40px !important;
  }

  .pt-7-desktop-only {
    padding-top: 40px !important;
  }

  .pr-7-desktop-only {
    padding-right: 40px !important;
  }

  .pb-7-desktop-only {
    padding-bottom: 40px !important;
  }

  .pl-7-desktop-only {
    padding-left: 40px !important;
  }

  .px-7-desktop-only {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-desktop-only {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-desktop-only {
    padding: 50px !important;
  }

  .pt-8-desktop-only {
    padding-top: 50px !important;
  }

  .pr-8-desktop-only {
    padding-right: 50px !important;
  }

  .pb-8-desktop-only {
    padding-bottom: 50px !important;
  }

  .pl-8-desktop-only {
    padding-left: 50px !important;
  }

  .px-8-desktop-only {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-desktop-only {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-desktop-only {
    padding: auto !important;
  }

  .pt-auto-desktop-only {
    padding-top: auto !important;
  }

  .pr-auto-desktop-only {
    padding-right: auto !important;
  }

  .pb-auto-desktop-only {
    padding-bottom: auto !important;
  }

  .pl-auto-desktop-only {
    padding-left: auto !important;
  }

  .px-auto-desktop-only {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-desktop-only {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-desktop-only {
    padding: -5px !important;
  }

  .pt--1-desktop-only {
    padding-top: -5px !important;
  }

  .pr--1-desktop-only {
    padding-right: -5px !important;
  }

  .pb--1-desktop-only {
    padding-bottom: -5px !important;
  }

  .pl--1-desktop-only {
    padding-left: -5px !important;
  }

  .px--1-desktop-only {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-desktop-only {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-desktop-only {
    padding: -10px !important;
  }

  .pt--2-desktop-only {
    padding-top: -10px !important;
  }

  .pr--2-desktop-only {
    padding-right: -10px !important;
  }

  .pb--2-desktop-only {
    padding-bottom: -10px !important;
  }

  .pl--2-desktop-only {
    padding-left: -10px !important;
  }

  .px--2-desktop-only {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-desktop-only {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-desktop-only {
    padding: -15px !important;
  }

  .pt--3-desktop-only {
    padding-top: -15px !important;
  }

  .pr--3-desktop-only {
    padding-right: -15px !important;
  }

  .pb--3-desktop-only {
    padding-bottom: -15px !important;
  }

  .pl--3-desktop-only {
    padding-left: -15px !important;
  }

  .px--3-desktop-only {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-desktop-only {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-desktop-only {
    padding: -20px !important;
  }

  .pt--4-desktop-only {
    padding-top: -20px !important;
  }

  .pr--4-desktop-only {
    padding-right: -20px !important;
  }

  .pb--4-desktop-only {
    padding-bottom: -20px !important;
  }

  .pl--4-desktop-only {
    padding-left: -20px !important;
  }

  .px--4-desktop-only {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-desktop-only {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-desktop-only {
    padding: -25px !important;
  }

  .pt--5-desktop-only {
    padding-top: -25px !important;
  }

  .pr--5-desktop-only {
    padding-right: -25px !important;
  }

  .pb--5-desktop-only {
    padding-bottom: -25px !important;
  }

  .pl--5-desktop-only {
    padding-left: -25px !important;
  }

  .px--5-desktop-only {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-desktop-only {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-desktop-only {
    padding: -30px !important;
  }

  .pt--6-desktop-only {
    padding-top: -30px !important;
  }

  .pr--6-desktop-only {
    padding-right: -30px !important;
  }

  .pb--6-desktop-only {
    padding-bottom: -30px !important;
  }

  .pl--6-desktop-only {
    padding-left: -30px !important;
  }

  .px--6-desktop-only {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-desktop-only {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-desktop-only {
    padding: -40px !important;
  }

  .pt--7-desktop-only {
    padding-top: -40px !important;
  }

  .pr--7-desktop-only {
    padding-right: -40px !important;
  }

  .pb--7-desktop-only {
    padding-bottom: -40px !important;
  }

  .pl--7-desktop-only {
    padding-left: -40px !important;
  }

  .px--7-desktop-only {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-desktop-only {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-desktop-only {
    padding: -50px !important;
  }

  .pt--8-desktop-only {
    padding-top: -50px !important;
  }

  .pr--8-desktop-only {
    padding-right: -50px !important;
  }

  .pb--8-desktop-only {
    padding-bottom: -50px !important;
  }

  .pl--8-desktop-only {
    padding-left: -50px !important;
  }

  .px--8-desktop-only {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-desktop-only {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width <= 1215px) {
  .p-0-until-widescreen {
    padding: 0 !important;
  }

  .pt-0-until-widescreen {
    padding-top: 0 !important;
  }

  .pr-0-until-widescreen {
    padding-right: 0 !important;
  }

  .pb-0-until-widescreen {
    padding-bottom: 0 !important;
  }

  .pl-0-until-widescreen {
    padding-left: 0 !important;
  }

  .px-0-until-widescreen {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-until-widescreen {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-until-widescreen {
    padding: 5px !important;
  }

  .pt-1-until-widescreen {
    padding-top: 5px !important;
  }

  .pr-1-until-widescreen {
    padding-right: 5px !important;
  }

  .pb-1-until-widescreen {
    padding-bottom: 5px !important;
  }

  .pl-1-until-widescreen {
    padding-left: 5px !important;
  }

  .px-1-until-widescreen {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-until-widescreen {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-until-widescreen {
    padding: 10px !important;
  }

  .pt-2-until-widescreen {
    padding-top: 10px !important;
  }

  .pr-2-until-widescreen {
    padding-right: 10px !important;
  }

  .pb-2-until-widescreen {
    padding-bottom: 10px !important;
  }

  .pl-2-until-widescreen {
    padding-left: 10px !important;
  }

  .px-2-until-widescreen {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-until-widescreen {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-until-widescreen {
    padding: 15px !important;
  }

  .pt-3-until-widescreen {
    padding-top: 15px !important;
  }

  .pr-3-until-widescreen {
    padding-right: 15px !important;
  }

  .pb-3-until-widescreen {
    padding-bottom: 15px !important;
  }

  .pl-3-until-widescreen {
    padding-left: 15px !important;
  }

  .px-3-until-widescreen {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-until-widescreen {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-until-widescreen {
    padding: 20px !important;
  }

  .pt-4-until-widescreen {
    padding-top: 20px !important;
  }

  .pr-4-until-widescreen {
    padding-right: 20px !important;
  }

  .pb-4-until-widescreen {
    padding-bottom: 20px !important;
  }

  .pl-4-until-widescreen {
    padding-left: 20px !important;
  }

  .px-4-until-widescreen {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-until-widescreen {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-until-widescreen {
    padding: 25px !important;
  }

  .pt-5-until-widescreen {
    padding-top: 25px !important;
  }

  .pr-5-until-widescreen {
    padding-right: 25px !important;
  }

  .pb-5-until-widescreen {
    padding-bottom: 25px !important;
  }

  .pl-5-until-widescreen {
    padding-left: 25px !important;
  }

  .px-5-until-widescreen {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-until-widescreen {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-until-widescreen {
    padding: 30px !important;
  }

  .pt-6-until-widescreen {
    padding-top: 30px !important;
  }

  .pr-6-until-widescreen {
    padding-right: 30px !important;
  }

  .pb-6-until-widescreen {
    padding-bottom: 30px !important;
  }

  .pl-6-until-widescreen {
    padding-left: 30px !important;
  }

  .px-6-until-widescreen {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-until-widescreen {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-until-widescreen {
    padding: 40px !important;
  }

  .pt-7-until-widescreen {
    padding-top: 40px !important;
  }

  .pr-7-until-widescreen {
    padding-right: 40px !important;
  }

  .pb-7-until-widescreen {
    padding-bottom: 40px !important;
  }

  .pl-7-until-widescreen {
    padding-left: 40px !important;
  }

  .px-7-until-widescreen {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-until-widescreen {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-until-widescreen {
    padding: 50px !important;
  }

  .pt-8-until-widescreen {
    padding-top: 50px !important;
  }

  .pr-8-until-widescreen {
    padding-right: 50px !important;
  }

  .pb-8-until-widescreen {
    padding-bottom: 50px !important;
  }

  .pl-8-until-widescreen {
    padding-left: 50px !important;
  }

  .px-8-until-widescreen {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-until-widescreen {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-until-widescreen {
    padding: auto !important;
  }

  .pt-auto-until-widescreen {
    padding-top: auto !important;
  }

  .pr-auto-until-widescreen {
    padding-right: auto !important;
  }

  .pb-auto-until-widescreen {
    padding-bottom: auto !important;
  }

  .pl-auto-until-widescreen {
    padding-left: auto !important;
  }

  .px-auto-until-widescreen {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-until-widescreen {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-until-widescreen {
    padding: -5px !important;
  }

  .pt--1-until-widescreen {
    padding-top: -5px !important;
  }

  .pr--1-until-widescreen {
    padding-right: -5px !important;
  }

  .pb--1-until-widescreen {
    padding-bottom: -5px !important;
  }

  .pl--1-until-widescreen {
    padding-left: -5px !important;
  }

  .px--1-until-widescreen {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-until-widescreen {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-until-widescreen {
    padding: -10px !important;
  }

  .pt--2-until-widescreen {
    padding-top: -10px !important;
  }

  .pr--2-until-widescreen {
    padding-right: -10px !important;
  }

  .pb--2-until-widescreen {
    padding-bottom: -10px !important;
  }

  .pl--2-until-widescreen {
    padding-left: -10px !important;
  }

  .px--2-until-widescreen {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-until-widescreen {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-until-widescreen {
    padding: -15px !important;
  }

  .pt--3-until-widescreen {
    padding-top: -15px !important;
  }

  .pr--3-until-widescreen {
    padding-right: -15px !important;
  }

  .pb--3-until-widescreen {
    padding-bottom: -15px !important;
  }

  .pl--3-until-widescreen {
    padding-left: -15px !important;
  }

  .px--3-until-widescreen {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-until-widescreen {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-until-widescreen {
    padding: -20px !important;
  }

  .pt--4-until-widescreen {
    padding-top: -20px !important;
  }

  .pr--4-until-widescreen {
    padding-right: -20px !important;
  }

  .pb--4-until-widescreen {
    padding-bottom: -20px !important;
  }

  .pl--4-until-widescreen {
    padding-left: -20px !important;
  }

  .px--4-until-widescreen {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-until-widescreen {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-until-widescreen {
    padding: -25px !important;
  }

  .pt--5-until-widescreen {
    padding-top: -25px !important;
  }

  .pr--5-until-widescreen {
    padding-right: -25px !important;
  }

  .pb--5-until-widescreen {
    padding-bottom: -25px !important;
  }

  .pl--5-until-widescreen {
    padding-left: -25px !important;
  }

  .px--5-until-widescreen {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-until-widescreen {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-until-widescreen {
    padding: -30px !important;
  }

  .pt--6-until-widescreen {
    padding-top: -30px !important;
  }

  .pr--6-until-widescreen {
    padding-right: -30px !important;
  }

  .pb--6-until-widescreen {
    padding-bottom: -30px !important;
  }

  .pl--6-until-widescreen {
    padding-left: -30px !important;
  }

  .px--6-until-widescreen {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-until-widescreen {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-until-widescreen {
    padding: -40px !important;
  }

  .pt--7-until-widescreen {
    padding-top: -40px !important;
  }

  .pr--7-until-widescreen {
    padding-right: -40px !important;
  }

  .pb--7-until-widescreen {
    padding-bottom: -40px !important;
  }

  .pl--7-until-widescreen {
    padding-left: -40px !important;
  }

  .px--7-until-widescreen {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-until-widescreen {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-until-widescreen {
    padding: -50px !important;
  }

  .pt--8-until-widescreen {
    padding-top: -50px !important;
  }

  .pr--8-until-widescreen {
    padding-right: -50px !important;
  }

  .pb--8-until-widescreen {
    padding-bottom: -50px !important;
  }

  .pl--8-until-widescreen {
    padding-left: -50px !important;
  }

  .px--8-until-widescreen {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-until-widescreen {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 1216px) {
  .p-0-widescreen {
    padding: 0 !important;
  }

  .pt-0-widescreen {
    padding-top: 0 !important;
  }

  .pr-0-widescreen {
    padding-right: 0 !important;
  }

  .pb-0-widescreen {
    padding-bottom: 0 !important;
  }

  .pl-0-widescreen {
    padding-left: 0 !important;
  }

  .px-0-widescreen {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-widescreen {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-widescreen {
    padding: 5px !important;
  }

  .pt-1-widescreen {
    padding-top: 5px !important;
  }

  .pr-1-widescreen {
    padding-right: 5px !important;
  }

  .pb-1-widescreen {
    padding-bottom: 5px !important;
  }

  .pl-1-widescreen {
    padding-left: 5px !important;
  }

  .px-1-widescreen {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-widescreen {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-widescreen {
    padding: 10px !important;
  }

  .pt-2-widescreen {
    padding-top: 10px !important;
  }

  .pr-2-widescreen {
    padding-right: 10px !important;
  }

  .pb-2-widescreen {
    padding-bottom: 10px !important;
  }

  .pl-2-widescreen {
    padding-left: 10px !important;
  }

  .px-2-widescreen {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-widescreen {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-widescreen {
    padding: 15px !important;
  }

  .pt-3-widescreen {
    padding-top: 15px !important;
  }

  .pr-3-widescreen {
    padding-right: 15px !important;
  }

  .pb-3-widescreen {
    padding-bottom: 15px !important;
  }

  .pl-3-widescreen {
    padding-left: 15px !important;
  }

  .px-3-widescreen {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-widescreen {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-widescreen {
    padding: 20px !important;
  }

  .pt-4-widescreen {
    padding-top: 20px !important;
  }

  .pr-4-widescreen {
    padding-right: 20px !important;
  }

  .pb-4-widescreen {
    padding-bottom: 20px !important;
  }

  .pl-4-widescreen {
    padding-left: 20px !important;
  }

  .px-4-widescreen {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-widescreen {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-widescreen {
    padding: 25px !important;
  }

  .pt-5-widescreen {
    padding-top: 25px !important;
  }

  .pr-5-widescreen {
    padding-right: 25px !important;
  }

  .pb-5-widescreen {
    padding-bottom: 25px !important;
  }

  .pl-5-widescreen {
    padding-left: 25px !important;
  }

  .px-5-widescreen {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-widescreen {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-widescreen {
    padding: 30px !important;
  }

  .pt-6-widescreen {
    padding-top: 30px !important;
  }

  .pr-6-widescreen {
    padding-right: 30px !important;
  }

  .pb-6-widescreen {
    padding-bottom: 30px !important;
  }

  .pl-6-widescreen {
    padding-left: 30px !important;
  }

  .px-6-widescreen {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-widescreen {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-widescreen {
    padding: 40px !important;
  }

  .pt-7-widescreen {
    padding-top: 40px !important;
  }

  .pr-7-widescreen {
    padding-right: 40px !important;
  }

  .pb-7-widescreen {
    padding-bottom: 40px !important;
  }

  .pl-7-widescreen {
    padding-left: 40px !important;
  }

  .px-7-widescreen {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-widescreen {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-widescreen {
    padding: 50px !important;
  }

  .pt-8-widescreen {
    padding-top: 50px !important;
  }

  .pr-8-widescreen {
    padding-right: 50px !important;
  }

  .pb-8-widescreen {
    padding-bottom: 50px !important;
  }

  .pl-8-widescreen {
    padding-left: 50px !important;
  }

  .px-8-widescreen {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-widescreen {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-widescreen {
    padding: auto !important;
  }

  .pt-auto-widescreen {
    padding-top: auto !important;
  }

  .pr-auto-widescreen {
    padding-right: auto !important;
  }

  .pb-auto-widescreen {
    padding-bottom: auto !important;
  }

  .pl-auto-widescreen {
    padding-left: auto !important;
  }

  .px-auto-widescreen {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-widescreen {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-widescreen {
    padding: -5px !important;
  }

  .pt--1-widescreen {
    padding-top: -5px !important;
  }

  .pr--1-widescreen {
    padding-right: -5px !important;
  }

  .pb--1-widescreen {
    padding-bottom: -5px !important;
  }

  .pl--1-widescreen {
    padding-left: -5px !important;
  }

  .px--1-widescreen {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-widescreen {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-widescreen {
    padding: -10px !important;
  }

  .pt--2-widescreen {
    padding-top: -10px !important;
  }

  .pr--2-widescreen {
    padding-right: -10px !important;
  }

  .pb--2-widescreen {
    padding-bottom: -10px !important;
  }

  .pl--2-widescreen {
    padding-left: -10px !important;
  }

  .px--2-widescreen {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-widescreen {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-widescreen {
    padding: -15px !important;
  }

  .pt--3-widescreen {
    padding-top: -15px !important;
  }

  .pr--3-widescreen {
    padding-right: -15px !important;
  }

  .pb--3-widescreen {
    padding-bottom: -15px !important;
  }

  .pl--3-widescreen {
    padding-left: -15px !important;
  }

  .px--3-widescreen {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-widescreen {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-widescreen {
    padding: -20px !important;
  }

  .pt--4-widescreen {
    padding-top: -20px !important;
  }

  .pr--4-widescreen {
    padding-right: -20px !important;
  }

  .pb--4-widescreen {
    padding-bottom: -20px !important;
  }

  .pl--4-widescreen {
    padding-left: -20px !important;
  }

  .px--4-widescreen {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-widescreen {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-widescreen {
    padding: -25px !important;
  }

  .pt--5-widescreen {
    padding-top: -25px !important;
  }

  .pr--5-widescreen {
    padding-right: -25px !important;
  }

  .pb--5-widescreen {
    padding-bottom: -25px !important;
  }

  .pl--5-widescreen {
    padding-left: -25px !important;
  }

  .px--5-widescreen {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-widescreen {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-widescreen {
    padding: -30px !important;
  }

  .pt--6-widescreen {
    padding-top: -30px !important;
  }

  .pr--6-widescreen {
    padding-right: -30px !important;
  }

  .pb--6-widescreen {
    padding-bottom: -30px !important;
  }

  .pl--6-widescreen {
    padding-left: -30px !important;
  }

  .px--6-widescreen {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-widescreen {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-widescreen {
    padding: -40px !important;
  }

  .pt--7-widescreen {
    padding-top: -40px !important;
  }

  .pr--7-widescreen {
    padding-right: -40px !important;
  }

  .pb--7-widescreen {
    padding-bottom: -40px !important;
  }

  .pl--7-widescreen {
    padding-left: -40px !important;
  }

  .px--7-widescreen {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-widescreen {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-widescreen {
    padding: -50px !important;
  }

  .pt--8-widescreen {
    padding-top: -50px !important;
  }

  .pr--8-widescreen {
    padding-right: -50px !important;
  }

  .pb--8-widescreen {
    padding-bottom: -50px !important;
  }

  .pl--8-widescreen {
    padding-left: -50px !important;
  }

  .px--8-widescreen {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-widescreen {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .p-0-widescreen-only {
    padding: 0 !important;
  }

  .pt-0-widescreen-only {
    padding-top: 0 !important;
  }

  .pr-0-widescreen-only {
    padding-right: 0 !important;
  }

  .pb-0-widescreen-only {
    padding-bottom: 0 !important;
  }

  .pl-0-widescreen-only {
    padding-left: 0 !important;
  }

  .px-0-widescreen-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-widescreen-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-widescreen-only {
    padding: 5px !important;
  }

  .pt-1-widescreen-only {
    padding-top: 5px !important;
  }

  .pr-1-widescreen-only {
    padding-right: 5px !important;
  }

  .pb-1-widescreen-only {
    padding-bottom: 5px !important;
  }

  .pl-1-widescreen-only {
    padding-left: 5px !important;
  }

  .px-1-widescreen-only {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-widescreen-only {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-widescreen-only {
    padding: 10px !important;
  }

  .pt-2-widescreen-only {
    padding-top: 10px !important;
  }

  .pr-2-widescreen-only {
    padding-right: 10px !important;
  }

  .pb-2-widescreen-only {
    padding-bottom: 10px !important;
  }

  .pl-2-widescreen-only {
    padding-left: 10px !important;
  }

  .px-2-widescreen-only {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-widescreen-only {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-widescreen-only {
    padding: 15px !important;
  }

  .pt-3-widescreen-only {
    padding-top: 15px !important;
  }

  .pr-3-widescreen-only {
    padding-right: 15px !important;
  }

  .pb-3-widescreen-only {
    padding-bottom: 15px !important;
  }

  .pl-3-widescreen-only {
    padding-left: 15px !important;
  }

  .px-3-widescreen-only {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-widescreen-only {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-widescreen-only {
    padding: 20px !important;
  }

  .pt-4-widescreen-only {
    padding-top: 20px !important;
  }

  .pr-4-widescreen-only {
    padding-right: 20px !important;
  }

  .pb-4-widescreen-only {
    padding-bottom: 20px !important;
  }

  .pl-4-widescreen-only {
    padding-left: 20px !important;
  }

  .px-4-widescreen-only {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-widescreen-only {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-widescreen-only {
    padding: 25px !important;
  }

  .pt-5-widescreen-only {
    padding-top: 25px !important;
  }

  .pr-5-widescreen-only {
    padding-right: 25px !important;
  }

  .pb-5-widescreen-only {
    padding-bottom: 25px !important;
  }

  .pl-5-widescreen-only {
    padding-left: 25px !important;
  }

  .px-5-widescreen-only {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-widescreen-only {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-widescreen-only {
    padding: 30px !important;
  }

  .pt-6-widescreen-only {
    padding-top: 30px !important;
  }

  .pr-6-widescreen-only {
    padding-right: 30px !important;
  }

  .pb-6-widescreen-only {
    padding-bottom: 30px !important;
  }

  .pl-6-widescreen-only {
    padding-left: 30px !important;
  }

  .px-6-widescreen-only {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-widescreen-only {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-widescreen-only {
    padding: 40px !important;
  }

  .pt-7-widescreen-only {
    padding-top: 40px !important;
  }

  .pr-7-widescreen-only {
    padding-right: 40px !important;
  }

  .pb-7-widescreen-only {
    padding-bottom: 40px !important;
  }

  .pl-7-widescreen-only {
    padding-left: 40px !important;
  }

  .px-7-widescreen-only {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-widescreen-only {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-widescreen-only {
    padding: 50px !important;
  }

  .pt-8-widescreen-only {
    padding-top: 50px !important;
  }

  .pr-8-widescreen-only {
    padding-right: 50px !important;
  }

  .pb-8-widescreen-only {
    padding-bottom: 50px !important;
  }

  .pl-8-widescreen-only {
    padding-left: 50px !important;
  }

  .px-8-widescreen-only {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-widescreen-only {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-widescreen-only {
    padding: auto !important;
  }

  .pt-auto-widescreen-only {
    padding-top: auto !important;
  }

  .pr-auto-widescreen-only {
    padding-right: auto !important;
  }

  .pb-auto-widescreen-only {
    padding-bottom: auto !important;
  }

  .pl-auto-widescreen-only {
    padding-left: auto !important;
  }

  .px-auto-widescreen-only {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-widescreen-only {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-widescreen-only {
    padding: -5px !important;
  }

  .pt--1-widescreen-only {
    padding-top: -5px !important;
  }

  .pr--1-widescreen-only {
    padding-right: -5px !important;
  }

  .pb--1-widescreen-only {
    padding-bottom: -5px !important;
  }

  .pl--1-widescreen-only {
    padding-left: -5px !important;
  }

  .px--1-widescreen-only {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-widescreen-only {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-widescreen-only {
    padding: -10px !important;
  }

  .pt--2-widescreen-only {
    padding-top: -10px !important;
  }

  .pr--2-widescreen-only {
    padding-right: -10px !important;
  }

  .pb--2-widescreen-only {
    padding-bottom: -10px !important;
  }

  .pl--2-widescreen-only {
    padding-left: -10px !important;
  }

  .px--2-widescreen-only {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-widescreen-only {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-widescreen-only {
    padding: -15px !important;
  }

  .pt--3-widescreen-only {
    padding-top: -15px !important;
  }

  .pr--3-widescreen-only {
    padding-right: -15px !important;
  }

  .pb--3-widescreen-only {
    padding-bottom: -15px !important;
  }

  .pl--3-widescreen-only {
    padding-left: -15px !important;
  }

  .px--3-widescreen-only {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-widescreen-only {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-widescreen-only {
    padding: -20px !important;
  }

  .pt--4-widescreen-only {
    padding-top: -20px !important;
  }

  .pr--4-widescreen-only {
    padding-right: -20px !important;
  }

  .pb--4-widescreen-only {
    padding-bottom: -20px !important;
  }

  .pl--4-widescreen-only {
    padding-left: -20px !important;
  }

  .px--4-widescreen-only {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-widescreen-only {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-widescreen-only {
    padding: -25px !important;
  }

  .pt--5-widescreen-only {
    padding-top: -25px !important;
  }

  .pr--5-widescreen-only {
    padding-right: -25px !important;
  }

  .pb--5-widescreen-only {
    padding-bottom: -25px !important;
  }

  .pl--5-widescreen-only {
    padding-left: -25px !important;
  }

  .px--5-widescreen-only {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-widescreen-only {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-widescreen-only {
    padding: -30px !important;
  }

  .pt--6-widescreen-only {
    padding-top: -30px !important;
  }

  .pr--6-widescreen-only {
    padding-right: -30px !important;
  }

  .pb--6-widescreen-only {
    padding-bottom: -30px !important;
  }

  .pl--6-widescreen-only {
    padding-left: -30px !important;
  }

  .px--6-widescreen-only {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-widescreen-only {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-widescreen-only {
    padding: -40px !important;
  }

  .pt--7-widescreen-only {
    padding-top: -40px !important;
  }

  .pr--7-widescreen-only {
    padding-right: -40px !important;
  }

  .pb--7-widescreen-only {
    padding-bottom: -40px !important;
  }

  .pl--7-widescreen-only {
    padding-left: -40px !important;
  }

  .px--7-widescreen-only {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-widescreen-only {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-widescreen-only {
    padding: -50px !important;
  }

  .pt--8-widescreen-only {
    padding-top: -50px !important;
  }

  .pr--8-widescreen-only {
    padding-right: -50px !important;
  }

  .pb--8-widescreen-only {
    padding-bottom: -50px !important;
  }

  .pl--8-widescreen-only {
    padding-left: -50px !important;
  }

  .px--8-widescreen-only {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-widescreen-only {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width <= 1407px) {
  .p-0-until-fullhd {
    padding: 0 !important;
  }

  .pt-0-until-fullhd {
    padding-top: 0 !important;
  }

  .pr-0-until-fullhd {
    padding-right: 0 !important;
  }

  .pb-0-until-fullhd {
    padding-bottom: 0 !important;
  }

  .pl-0-until-fullhd {
    padding-left: 0 !important;
  }

  .px-0-until-fullhd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-until-fullhd {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-until-fullhd {
    padding: 5px !important;
  }

  .pt-1-until-fullhd {
    padding-top: 5px !important;
  }

  .pr-1-until-fullhd {
    padding-right: 5px !important;
  }

  .pb-1-until-fullhd {
    padding-bottom: 5px !important;
  }

  .pl-1-until-fullhd {
    padding-left: 5px !important;
  }

  .px-1-until-fullhd {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-until-fullhd {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-until-fullhd {
    padding: 10px !important;
  }

  .pt-2-until-fullhd {
    padding-top: 10px !important;
  }

  .pr-2-until-fullhd {
    padding-right: 10px !important;
  }

  .pb-2-until-fullhd {
    padding-bottom: 10px !important;
  }

  .pl-2-until-fullhd {
    padding-left: 10px !important;
  }

  .px-2-until-fullhd {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-until-fullhd {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-until-fullhd {
    padding: 15px !important;
  }

  .pt-3-until-fullhd {
    padding-top: 15px !important;
  }

  .pr-3-until-fullhd {
    padding-right: 15px !important;
  }

  .pb-3-until-fullhd {
    padding-bottom: 15px !important;
  }

  .pl-3-until-fullhd {
    padding-left: 15px !important;
  }

  .px-3-until-fullhd {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-until-fullhd {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-until-fullhd {
    padding: 20px !important;
  }

  .pt-4-until-fullhd {
    padding-top: 20px !important;
  }

  .pr-4-until-fullhd {
    padding-right: 20px !important;
  }

  .pb-4-until-fullhd {
    padding-bottom: 20px !important;
  }

  .pl-4-until-fullhd {
    padding-left: 20px !important;
  }

  .px-4-until-fullhd {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-until-fullhd {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-until-fullhd {
    padding: 25px !important;
  }

  .pt-5-until-fullhd {
    padding-top: 25px !important;
  }

  .pr-5-until-fullhd {
    padding-right: 25px !important;
  }

  .pb-5-until-fullhd {
    padding-bottom: 25px !important;
  }

  .pl-5-until-fullhd {
    padding-left: 25px !important;
  }

  .px-5-until-fullhd {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-until-fullhd {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-until-fullhd {
    padding: 30px !important;
  }

  .pt-6-until-fullhd {
    padding-top: 30px !important;
  }

  .pr-6-until-fullhd {
    padding-right: 30px !important;
  }

  .pb-6-until-fullhd {
    padding-bottom: 30px !important;
  }

  .pl-6-until-fullhd {
    padding-left: 30px !important;
  }

  .px-6-until-fullhd {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-until-fullhd {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-until-fullhd {
    padding: 40px !important;
  }

  .pt-7-until-fullhd {
    padding-top: 40px !important;
  }

  .pr-7-until-fullhd {
    padding-right: 40px !important;
  }

  .pb-7-until-fullhd {
    padding-bottom: 40px !important;
  }

  .pl-7-until-fullhd {
    padding-left: 40px !important;
  }

  .px-7-until-fullhd {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-until-fullhd {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-until-fullhd {
    padding: 50px !important;
  }

  .pt-8-until-fullhd {
    padding-top: 50px !important;
  }

  .pr-8-until-fullhd {
    padding-right: 50px !important;
  }

  .pb-8-until-fullhd {
    padding-bottom: 50px !important;
  }

  .pl-8-until-fullhd {
    padding-left: 50px !important;
  }

  .px-8-until-fullhd {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-until-fullhd {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-until-fullhd {
    padding: auto !important;
  }

  .pt-auto-until-fullhd {
    padding-top: auto !important;
  }

  .pr-auto-until-fullhd {
    padding-right: auto !important;
  }

  .pb-auto-until-fullhd {
    padding-bottom: auto !important;
  }

  .pl-auto-until-fullhd {
    padding-left: auto !important;
  }

  .px-auto-until-fullhd {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-until-fullhd {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-until-fullhd {
    padding: -5px !important;
  }

  .pt--1-until-fullhd {
    padding-top: -5px !important;
  }

  .pr--1-until-fullhd {
    padding-right: -5px !important;
  }

  .pb--1-until-fullhd {
    padding-bottom: -5px !important;
  }

  .pl--1-until-fullhd {
    padding-left: -5px !important;
  }

  .px--1-until-fullhd {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-until-fullhd {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-until-fullhd {
    padding: -10px !important;
  }

  .pt--2-until-fullhd {
    padding-top: -10px !important;
  }

  .pr--2-until-fullhd {
    padding-right: -10px !important;
  }

  .pb--2-until-fullhd {
    padding-bottom: -10px !important;
  }

  .pl--2-until-fullhd {
    padding-left: -10px !important;
  }

  .px--2-until-fullhd {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-until-fullhd {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-until-fullhd {
    padding: -15px !important;
  }

  .pt--3-until-fullhd {
    padding-top: -15px !important;
  }

  .pr--3-until-fullhd {
    padding-right: -15px !important;
  }

  .pb--3-until-fullhd {
    padding-bottom: -15px !important;
  }

  .pl--3-until-fullhd {
    padding-left: -15px !important;
  }

  .px--3-until-fullhd {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-until-fullhd {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-until-fullhd {
    padding: -20px !important;
  }

  .pt--4-until-fullhd {
    padding-top: -20px !important;
  }

  .pr--4-until-fullhd {
    padding-right: -20px !important;
  }

  .pb--4-until-fullhd {
    padding-bottom: -20px !important;
  }

  .pl--4-until-fullhd {
    padding-left: -20px !important;
  }

  .px--4-until-fullhd {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-until-fullhd {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-until-fullhd {
    padding: -25px !important;
  }

  .pt--5-until-fullhd {
    padding-top: -25px !important;
  }

  .pr--5-until-fullhd {
    padding-right: -25px !important;
  }

  .pb--5-until-fullhd {
    padding-bottom: -25px !important;
  }

  .pl--5-until-fullhd {
    padding-left: -25px !important;
  }

  .px--5-until-fullhd {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-until-fullhd {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-until-fullhd {
    padding: -30px !important;
  }

  .pt--6-until-fullhd {
    padding-top: -30px !important;
  }

  .pr--6-until-fullhd {
    padding-right: -30px !important;
  }

  .pb--6-until-fullhd {
    padding-bottom: -30px !important;
  }

  .pl--6-until-fullhd {
    padding-left: -30px !important;
  }

  .px--6-until-fullhd {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-until-fullhd {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-until-fullhd {
    padding: -40px !important;
  }

  .pt--7-until-fullhd {
    padding-top: -40px !important;
  }

  .pr--7-until-fullhd {
    padding-right: -40px !important;
  }

  .pb--7-until-fullhd {
    padding-bottom: -40px !important;
  }

  .pl--7-until-fullhd {
    padding-left: -40px !important;
  }

  .px--7-until-fullhd {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-until-fullhd {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-until-fullhd {
    padding: -50px !important;
  }

  .pt--8-until-fullhd {
    padding-top: -50px !important;
  }

  .pr--8-until-fullhd {
    padding-right: -50px !important;
  }

  .pb--8-until-fullhd {
    padding-bottom: -50px !important;
  }

  .pl--8-until-fullhd {
    padding-left: -50px !important;
  }

  .px--8-until-fullhd {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-until-fullhd {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

@media screen and (width >= 1408px) {
  .p-0-fullhd {
    padding: 0 !important;
  }

  .pt-0-fullhd {
    padding-top: 0 !important;
  }

  .pr-0-fullhd {
    padding-right: 0 !important;
  }

  .pb-0-fullhd {
    padding-bottom: 0 !important;
  }

  .pl-0-fullhd {
    padding-left: 0 !important;
  }

  .px-0-fullhd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-0-fullhd {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-1-fullhd {
    padding: 5px !important;
  }

  .pt-1-fullhd {
    padding-top: 5px !important;
  }

  .pr-1-fullhd {
    padding-right: 5px !important;
  }

  .pb-1-fullhd {
    padding-bottom: 5px !important;
  }

  .pl-1-fullhd {
    padding-left: 5px !important;
  }

  .px-1-fullhd {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-1-fullhd {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .p-2-fullhd {
    padding: 10px !important;
  }

  .pt-2-fullhd {
    padding-top: 10px !important;
  }

  .pr-2-fullhd {
    padding-right: 10px !important;
  }

  .pb-2-fullhd {
    padding-bottom: 10px !important;
  }

  .pl-2-fullhd {
    padding-left: 10px !important;
  }

  .px-2-fullhd {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-2-fullhd {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-3-fullhd {
    padding: 15px !important;
  }

  .pt-3-fullhd {
    padding-top: 15px !important;
  }

  .pr-3-fullhd {
    padding-right: 15px !important;
  }

  .pb-3-fullhd {
    padding-bottom: 15px !important;
  }

  .pl-3-fullhd {
    padding-left: 15px !important;
  }

  .px-3-fullhd {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-3-fullhd {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .p-4-fullhd {
    padding: 20px !important;
  }

  .pt-4-fullhd {
    padding-top: 20px !important;
  }

  .pr-4-fullhd {
    padding-right: 20px !important;
  }

  .pb-4-fullhd {
    padding-bottom: 20px !important;
  }

  .pl-4-fullhd {
    padding-left: 20px !important;
  }

  .px-4-fullhd {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-4-fullhd {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-5-fullhd {
    padding: 25px !important;
  }

  .pt-5-fullhd {
    padding-top: 25px !important;
  }

  .pr-5-fullhd {
    padding-right: 25px !important;
  }

  .pb-5-fullhd {
    padding-bottom: 25px !important;
  }

  .pl-5-fullhd {
    padding-left: 25px !important;
  }

  .px-5-fullhd {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .py-5-fullhd {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .p-6-fullhd {
    padding: 30px !important;
  }

  .pt-6-fullhd {
    padding-top: 30px !important;
  }

  .pr-6-fullhd {
    padding-right: 30px !important;
  }

  .pb-6-fullhd {
    padding-bottom: 30px !important;
  }

  .pl-6-fullhd {
    padding-left: 30px !important;
  }

  .px-6-fullhd {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .py-6-fullhd {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-7-fullhd {
    padding: 40px !important;
  }

  .pt-7-fullhd {
    padding-top: 40px !important;
  }

  .pr-7-fullhd {
    padding-right: 40px !important;
  }

  .pb-7-fullhd {
    padding-bottom: 40px !important;
  }

  .pl-7-fullhd {
    padding-left: 40px !important;
  }

  .px-7-fullhd {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .py-7-fullhd {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-8-fullhd {
    padding: 50px !important;
  }

  .pt-8-fullhd {
    padding-top: 50px !important;
  }

  .pr-8-fullhd {
    padding-right: 50px !important;
  }

  .pb-8-fullhd {
    padding-bottom: 50px !important;
  }

  .pl-8-fullhd {
    padding-left: 50px !important;
  }

  .px-8-fullhd {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .py-8-fullhd {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .p-auto-fullhd {
    padding: auto !important;
  }

  .pt-auto-fullhd {
    padding-top: auto !important;
  }

  .pr-auto-fullhd {
    padding-right: auto !important;
  }

  .pb-auto-fullhd {
    padding-bottom: auto !important;
  }

  .pl-auto-fullhd {
    padding-left: auto !important;
  }

  .px-auto-fullhd {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .py-auto-fullhd {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  .p--1-fullhd {
    padding: -5px !important;
  }

  .pt--1-fullhd {
    padding-top: -5px !important;
  }

  .pr--1-fullhd {
    padding-right: -5px !important;
  }

  .pb--1-fullhd {
    padding-bottom: -5px !important;
  }

  .pl--1-fullhd {
    padding-left: -5px !important;
  }

  .px--1-fullhd {
    padding-left: -5px !important;
    padding-right: -5px !important;
  }

  .py--1-fullhd {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .p--2-fullhd {
    padding: -10px !important;
  }

  .pt--2-fullhd {
    padding-top: -10px !important;
  }

  .pr--2-fullhd {
    padding-right: -10px !important;
  }

  .pb--2-fullhd {
    padding-bottom: -10px !important;
  }

  .pl--2-fullhd {
    padding-left: -10px !important;
  }

  .px--2-fullhd {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }

  .py--2-fullhd {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .p--3-fullhd {
    padding: -15px !important;
  }

  .pt--3-fullhd {
    padding-top: -15px !important;
  }

  .pr--3-fullhd {
    padding-right: -15px !important;
  }

  .pb--3-fullhd {
    padding-bottom: -15px !important;
  }

  .pl--3-fullhd {
    padding-left: -15px !important;
  }

  .px--3-fullhd {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }

  .py--3-fullhd {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .p--4-fullhd {
    padding: -20px !important;
  }

  .pt--4-fullhd {
    padding-top: -20px !important;
  }

  .pr--4-fullhd {
    padding-right: -20px !important;
  }

  .pb--4-fullhd {
    padding-bottom: -20px !important;
  }

  .pl--4-fullhd {
    padding-left: -20px !important;
  }

  .px--4-fullhd {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }

  .py--4-fullhd {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }

  .p--5-fullhd {
    padding: -25px !important;
  }

  .pt--5-fullhd {
    padding-top: -25px !important;
  }

  .pr--5-fullhd {
    padding-right: -25px !important;
  }

  .pb--5-fullhd {
    padding-bottom: -25px !important;
  }

  .pl--5-fullhd {
    padding-left: -25px !important;
  }

  .px--5-fullhd {
    padding-left: -25px !important;
    padding-right: -25px !important;
  }

  .py--5-fullhd {
    padding-top: -25px !important;
    padding-bottom: -25px !important;
  }

  .p--6-fullhd {
    padding: -30px !important;
  }

  .pt--6-fullhd {
    padding-top: -30px !important;
  }

  .pr--6-fullhd {
    padding-right: -30px !important;
  }

  .pb--6-fullhd {
    padding-bottom: -30px !important;
  }

  .pl--6-fullhd {
    padding-left: -30px !important;
  }

  .px--6-fullhd {
    padding-left: -30px !important;
    padding-right: -30px !important;
  }

  .py--6-fullhd {
    padding-top: -30px !important;
    padding-bottom: -30px !important;
  }

  .p--7-fullhd {
    padding: -40px !important;
  }

  .pt--7-fullhd {
    padding-top: -40px !important;
  }

  .pr--7-fullhd {
    padding-right: -40px !important;
  }

  .pb--7-fullhd {
    padding-bottom: -40px !important;
  }

  .pl--7-fullhd {
    padding-left: -40px !important;
  }

  .px--7-fullhd {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }

  .py--7-fullhd {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }

  .p--8-fullhd {
    padding: -50px !important;
  }

  .pt--8-fullhd {
    padding-top: -50px !important;
  }

  .pr--8-fullhd {
    padding-right: -50px !important;
  }

  .pb--8-fullhd {
    padding-bottom: -50px !important;
  }

  .pl--8-fullhd {
    padding-left: -50px !important;
  }

  .px--8-fullhd {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }

  .py--8-fullhd {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
}

.has-text-purple-blue {
  color: #2727c4 !important;
}

.has-background-purple-blue {
  background-color: #2727c4 !important;
}

.has-text-purple-blue-100 {
  color: #f1f1fb !important;
}

.has-border-purple-blue-100-1 {
  border: 1px solid #f1f1fb;
}

.has-inset-border-purple-blue-100-1 {
  box-shadow: inset 0 0 0 1px #f1f1fb;
}

.has-border-purple-blue-100-2 {
  border: 2px solid #f1f1fb;
}

.has-inset-border-purple-blue-100-2 {
  box-shadow: inset 0 0 0 2px #f1f1fb;
}

.has-border-purple-blue-100-3 {
  border: 3px solid #f1f1fb;
}

.has-inset-border-purple-blue-100-3 {
  box-shadow: inset 0 0 0 3px #f1f1fb;
}

.has-text-purple-blue-100-10 {
  color: #f1f1fb1a !important;
}

.has-text-purple-blue-100-20 {
  color: #f1f1fb33 !important;
}

.has-text-purple-blue-100-30 {
  color: #f1f1fb4d !important;
}

.has-text-purple-blue-100-40 {
  color: #f1f1fb66 !important;
}

.has-text-purple-blue-100-50 {
  color: #f1f1fb80 !important;
}

.has-text-purple-blue-100-60 {
  color: #f1f1fb99 !important;
}

.has-text-purple-blue-100-70 {
  color: #f1f1fbb3 !important;
}

.has-text-purple-blue-100-80 {
  color: #f1f1fbcc !important;
}

.has-text-purple-blue-100-90 {
  color: #f1f1fbe6 !important;
}

.has-text-purple-blue-100-100 {
  color: #f1f1fb !important;
}

.has-background-purple-blue-100 {
  background-color: #f1f1fb !important;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-mobile {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-tablet {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-tablet-only {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-touch {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-desktop {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-desktop-only {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-until-widescreen {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-widescreen {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-widescreen-only {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-until-fullhd {
    background-color: #f1f1fb !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-fullhd {
    background-color: #f1f1fb !important;
  }
}

.has-background-purple-blue-100-10 {
  background-color: #f1f1fb1a !important;
}

.has-background-purple-blue-100-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-10-mobile {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-10-tablet {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-10-tablet-only {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-10-touch {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-10-desktop {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-10-desktop-only {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-10-until-widescreen {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-10-widescreen {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-10-widescreen-only {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-10-until-fullhd {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-10-fullhd {
    background-color: #f1f1fb1a;
  }
}

.has-background-purple-blue-100-20 {
  background-color: #f1f1fb33 !important;
}

.has-background-purple-blue-100-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-20-mobile {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-20-tablet {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-20-tablet-only {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-20-touch {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-20-desktop {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-20-desktop-only {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-20-until-widescreen {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-20-widescreen {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-20-widescreen-only {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-20-until-fullhd {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-20-fullhd {
    background-color: #f1f1fb33;
  }
}

.has-background-purple-blue-100-30 {
  background-color: #f1f1fb4d !important;
}

.has-background-purple-blue-100-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-30-mobile {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-30-tablet {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-30-tablet-only {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-30-touch {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-30-desktop {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-30-desktop-only {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-30-until-widescreen {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-30-widescreen {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-30-widescreen-only {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-30-until-fullhd {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-30-fullhd {
    background-color: #f1f1fb4d;
  }
}

.has-background-purple-blue-100-40 {
  background-color: #f1f1fb66 !important;
}

.has-background-purple-blue-100-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-40-mobile {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-40-tablet {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-40-tablet-only {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-40-touch {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-40-desktop {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-40-desktop-only {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-40-until-widescreen {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-40-widescreen {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-40-widescreen-only {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-40-until-fullhd {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-40-fullhd {
    background-color: #f1f1fb66;
  }
}

.has-background-purple-blue-100-50 {
  background-color: #f1f1fb80 !important;
}

.has-background-purple-blue-100-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-50-mobile {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-50-tablet {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-50-tablet-only {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-50-touch {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-50-desktop {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-50-desktop-only {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-50-until-widescreen {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-50-widescreen {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-50-widescreen-only {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-50-until-fullhd {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-50-fullhd {
    background-color: #f1f1fb80;
  }
}

.has-background-purple-blue-100-60 {
  background-color: #f1f1fb99 !important;
}

.has-background-purple-blue-100-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-60-mobile {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-60-tablet {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-60-tablet-only {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-60-touch {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-60-desktop {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-60-desktop-only {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-60-until-widescreen {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-60-widescreen {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-60-widescreen-only {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-60-until-fullhd {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-60-fullhd {
    background-color: #f1f1fb99;
  }
}

.has-background-purple-blue-100-70 {
  background-color: #f1f1fbb3 !important;
}

.has-background-purple-blue-100-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-70-mobile {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-70-tablet {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-70-tablet-only {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-70-touch {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-70-desktop {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-70-desktop-only {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-70-until-widescreen {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-70-widescreen {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-70-widescreen-only {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-70-until-fullhd {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-70-fullhd {
    background-color: #f1f1fbb3;
  }
}

.has-background-purple-blue-100-80 {
  background-color: #f1f1fbcc !important;
}

.has-background-purple-blue-100-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-80-mobile {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-80-tablet {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-80-tablet-only {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-80-touch {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-80-desktop {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-80-desktop-only {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-80-until-widescreen {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-80-widescreen {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-80-widescreen-only {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-80-until-fullhd {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-80-fullhd {
    background-color: #f1f1fbcc;
  }
}

.has-background-purple-blue-100-90 {
  background-color: #f1f1fbe6 !important;
}

.has-background-purple-blue-100-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-90-mobile {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-90-tablet {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-90-tablet-only {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-90-touch {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-90-desktop {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-90-desktop-only {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-90-until-widescreen {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-90-widescreen {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-90-widescreen-only {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-90-until-fullhd {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-90-fullhd {
    background-color: #f1f1fbe6;
  }
}

.has-background-purple-blue-100-100 {
  background-color: #f1f1fb !important;
}

.has-background-purple-blue-100-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-100-100-mobile {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-100-100-tablet {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-100-100-tablet-only {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-100-100-touch {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-100-100-desktop {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-100-100-desktop-only {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-100-100-until-widescreen {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-100-100-widescreen {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-100-100-widescreen-only {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-100-100-until-fullhd {
    background-color: #f1f1fb;
  }
}

.has-background-purple-blue-100-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-100-100-fullhd {
    background-color: #f1f1fb;
  }
}

.has-text-purple-blue-200 {
  color: #c9c9f0 !important;
}

.has-border-purple-blue-200-1 {
  border: 1px solid #c9c9f0;
}

.has-inset-border-purple-blue-200-1 {
  box-shadow: inset 0 0 0 1px #c9c9f0;
}

.has-border-purple-blue-200-2 {
  border: 2px solid #c9c9f0;
}

.has-inset-border-purple-blue-200-2 {
  box-shadow: inset 0 0 0 2px #c9c9f0;
}

.has-border-purple-blue-200-3 {
  border: 3px solid #c9c9f0;
}

.has-inset-border-purple-blue-200-3 {
  box-shadow: inset 0 0 0 3px #c9c9f0;
}

.has-text-purple-blue-200-10 {
  color: #c9c9f01a !important;
}

.has-text-purple-blue-200-20 {
  color: #c9c9f033 !important;
}

.has-text-purple-blue-200-30 {
  color: #c9c9f04d !important;
}

.has-text-purple-blue-200-40 {
  color: #c9c9f066 !important;
}

.has-text-purple-blue-200-50 {
  color: #c9c9f080 !important;
}

.has-text-purple-blue-200-60 {
  color: #c9c9f099 !important;
}

.has-text-purple-blue-200-70 {
  color: #c9c9f0b3 !important;
}

.has-text-purple-blue-200-80 {
  color: #c9c9f0cc !important;
}

.has-text-purple-blue-200-90 {
  color: #c9c9f0e6 !important;
}

.has-text-purple-blue-200-100 {
  color: #c9c9f0 !important;
}

.has-background-purple-blue-200 {
  background-color: #c9c9f0 !important;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-mobile {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-tablet {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-tablet-only {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-touch {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-desktop {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-desktop-only {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-until-widescreen {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-widescreen {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-widescreen-only {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-until-fullhd {
    background-color: #c9c9f0 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-fullhd {
    background-color: #c9c9f0 !important;
  }
}

.has-background-purple-blue-200-10 {
  background-color: #c9c9f01a !important;
}

.has-background-purple-blue-200-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-10-mobile {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-10-tablet {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-10-tablet-only {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-10-touch {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-10-desktop {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-10-desktop-only {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-10-until-widescreen {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-10-widescreen {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-10-widescreen-only {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-10-until-fullhd {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-10-fullhd {
    background-color: #c9c9f01a;
  }
}

.has-background-purple-blue-200-20 {
  background-color: #c9c9f033 !important;
}

.has-background-purple-blue-200-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-20-mobile {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-20-tablet {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-20-tablet-only {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-20-touch {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-20-desktop {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-20-desktop-only {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-20-until-widescreen {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-20-widescreen {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-20-widescreen-only {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-20-until-fullhd {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-20-fullhd {
    background-color: #c9c9f033;
  }
}

.has-background-purple-blue-200-30 {
  background-color: #c9c9f04d !important;
}

.has-background-purple-blue-200-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-30-mobile {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-30-tablet {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-30-tablet-only {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-30-touch {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-30-desktop {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-30-desktop-only {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-30-until-widescreen {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-30-widescreen {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-30-widescreen-only {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-30-until-fullhd {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-30-fullhd {
    background-color: #c9c9f04d;
  }
}

.has-background-purple-blue-200-40 {
  background-color: #c9c9f066 !important;
}

.has-background-purple-blue-200-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-40-mobile {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-40-tablet {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-40-tablet-only {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-40-touch {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-40-desktop {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-40-desktop-only {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-40-until-widescreen {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-40-widescreen {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-40-widescreen-only {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-40-until-fullhd {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-40-fullhd {
    background-color: #c9c9f066;
  }
}

.has-background-purple-blue-200-50 {
  background-color: #c9c9f080 !important;
}

.has-background-purple-blue-200-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-50-mobile {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-50-tablet {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-50-tablet-only {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-50-touch {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-50-desktop {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-50-desktop-only {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-50-until-widescreen {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-50-widescreen {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-50-widescreen-only {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-50-until-fullhd {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-50-fullhd {
    background-color: #c9c9f080;
  }
}

.has-background-purple-blue-200-60 {
  background-color: #c9c9f099 !important;
}

.has-background-purple-blue-200-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-60-mobile {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-60-tablet {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-60-tablet-only {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-60-touch {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-60-desktop {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-60-desktop-only {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-60-until-widescreen {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-60-widescreen {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-60-widescreen-only {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-60-until-fullhd {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-60-fullhd {
    background-color: #c9c9f099;
  }
}

.has-background-purple-blue-200-70 {
  background-color: #c9c9f0b3 !important;
}

.has-background-purple-blue-200-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-70-mobile {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-70-tablet {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-70-tablet-only {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-70-touch {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-70-desktop {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-70-desktop-only {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-70-until-widescreen {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-70-widescreen {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-70-widescreen-only {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-70-until-fullhd {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-70-fullhd {
    background-color: #c9c9f0b3;
  }
}

.has-background-purple-blue-200-80 {
  background-color: #c9c9f0cc !important;
}

.has-background-purple-blue-200-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-80-mobile {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-80-tablet {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-80-tablet-only {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-80-touch {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-80-desktop {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-80-desktop-only {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-80-until-widescreen {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-80-widescreen {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-80-widescreen-only {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-80-until-fullhd {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-80-fullhd {
    background-color: #c9c9f0cc;
  }
}

.has-background-purple-blue-200-90 {
  background-color: #c9c9f0e6 !important;
}

.has-background-purple-blue-200-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-90-mobile {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-90-tablet {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-90-tablet-only {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-90-touch {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-90-desktop {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-90-desktop-only {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-90-until-widescreen {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-90-widescreen {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-90-widescreen-only {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-90-until-fullhd {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-90-fullhd {
    background-color: #c9c9f0e6;
  }
}

.has-background-purple-blue-200-100 {
  background-color: #c9c9f0 !important;
}

.has-background-purple-blue-200-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-200-100-mobile {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-200-100-tablet {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-200-100-tablet-only {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-200-100-touch {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-200-100-desktop {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-200-100-desktop-only {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-200-100-until-widescreen {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-200-100-widescreen {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-200-100-widescreen-only {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-200-100-until-fullhd {
    background-color: #c9c9f0;
  }
}

.has-background-purple-blue-200-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-200-100-fullhd {
    background-color: #c9c9f0;
  }
}

.has-text-purple-blue-300 {
  color: #a594f9 !important;
}

.has-border-purple-blue-300-1 {
  border: 1px solid #a594f9;
}

.has-inset-border-purple-blue-300-1 {
  box-shadow: inset 0 0 0 1px #a594f9;
}

.has-border-purple-blue-300-2 {
  border: 2px solid #a594f9;
}

.has-inset-border-purple-blue-300-2 {
  box-shadow: inset 0 0 0 2px #a594f9;
}

.has-border-purple-blue-300-3 {
  border: 3px solid #a594f9;
}

.has-inset-border-purple-blue-300-3 {
  box-shadow: inset 0 0 0 3px #a594f9;
}

.has-text-purple-blue-300-10 {
  color: #a594f91a !important;
}

.has-text-purple-blue-300-20 {
  color: #a594f933 !important;
}

.has-text-purple-blue-300-30 {
  color: #a594f94d !important;
}

.has-text-purple-blue-300-40 {
  color: #a594f966 !important;
}

.has-text-purple-blue-300-50 {
  color: #a594f980 !important;
}

.has-text-purple-blue-300-60 {
  color: #a594f999 !important;
}

.has-text-purple-blue-300-70 {
  color: #a594f9b3 !important;
}

.has-text-purple-blue-300-80 {
  color: #a594f9cc !important;
}

.has-text-purple-blue-300-90 {
  color: #a594f9e6 !important;
}

.has-text-purple-blue-300-100 {
  color: #a594f9 !important;
}

.has-background-purple-blue-300 {
  background-color: #a594f9 !important;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-mobile {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-tablet {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-tablet-only {
    background-color: #a594f9 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-touch {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-desktop {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-desktop-only {
    background-color: #a594f9 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-until-widescreen {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-widescreen {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-widescreen-only {
    background-color: #a594f9 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-until-fullhd {
    background-color: #a594f9 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-fullhd {
    background-color: #a594f9 !important;
  }
}

.has-background-purple-blue-300-10 {
  background-color: #a594f91a !important;
}

.has-background-purple-blue-300-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-10-mobile {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-10-tablet {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-10-tablet-only {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-10-touch {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-10-desktop {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-10-desktop-only {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-10-until-widescreen {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-10-widescreen {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-10-widescreen-only {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-10-until-fullhd {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-10-fullhd {
    background-color: #a594f91a;
  }
}

.has-background-purple-blue-300-20 {
  background-color: #a594f933 !important;
}

.has-background-purple-blue-300-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-20-mobile {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-20-tablet {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-20-tablet-only {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-20-touch {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-20-desktop {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-20-desktop-only {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-20-until-widescreen {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-20-widescreen {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-20-widescreen-only {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-20-until-fullhd {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-20-fullhd {
    background-color: #a594f933;
  }
}

.has-background-purple-blue-300-30 {
  background-color: #a594f94d !important;
}

.has-background-purple-blue-300-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-30-mobile {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-30-tablet {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-30-tablet-only {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-30-touch {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-30-desktop {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-30-desktop-only {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-30-until-widescreen {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-30-widescreen {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-30-widescreen-only {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-30-until-fullhd {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-30-fullhd {
    background-color: #a594f94d;
  }
}

.has-background-purple-blue-300-40 {
  background-color: #a594f966 !important;
}

.has-background-purple-blue-300-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-40-mobile {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-40-tablet {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-40-tablet-only {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-40-touch {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-40-desktop {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-40-desktop-only {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-40-until-widescreen {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-40-widescreen {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-40-widescreen-only {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-40-until-fullhd {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-40-fullhd {
    background-color: #a594f966;
  }
}

.has-background-purple-blue-300-50 {
  background-color: #a594f980 !important;
}

.has-background-purple-blue-300-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-50-mobile {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-50-tablet {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-50-tablet-only {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-50-touch {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-50-desktop {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-50-desktop-only {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-50-until-widescreen {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-50-widescreen {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-50-widescreen-only {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-50-until-fullhd {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-50-fullhd {
    background-color: #a594f980;
  }
}

.has-background-purple-blue-300-60 {
  background-color: #a594f999 !important;
}

.has-background-purple-blue-300-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-60-mobile {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-60-tablet {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-60-tablet-only {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-60-touch {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-60-desktop {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-60-desktop-only {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-60-until-widescreen {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-60-widescreen {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-60-widescreen-only {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-60-until-fullhd {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-60-fullhd {
    background-color: #a594f999;
  }
}

.has-background-purple-blue-300-70 {
  background-color: #a594f9b3 !important;
}

.has-background-purple-blue-300-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-70-mobile {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-70-tablet {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-70-tablet-only {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-70-touch {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-70-desktop {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-70-desktop-only {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-70-until-widescreen {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-70-widescreen {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-70-widescreen-only {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-70-until-fullhd {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-70-fullhd {
    background-color: #a594f9b3;
  }
}

.has-background-purple-blue-300-80 {
  background-color: #a594f9cc !important;
}

.has-background-purple-blue-300-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-80-mobile {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-80-tablet {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-80-tablet-only {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-80-touch {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-80-desktop {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-80-desktop-only {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-80-until-widescreen {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-80-widescreen {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-80-widescreen-only {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-80-until-fullhd {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-80-fullhd {
    background-color: #a594f9cc;
  }
}

.has-background-purple-blue-300-90 {
  background-color: #a594f9e6 !important;
}

.has-background-purple-blue-300-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-90-mobile {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-90-tablet {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-90-tablet-only {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-90-touch {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-90-desktop {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-90-desktop-only {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-90-until-widescreen {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-90-widescreen {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-90-widescreen-only {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-90-until-fullhd {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-90-fullhd {
    background-color: #a594f9e6;
  }
}

.has-background-purple-blue-300-100 {
  background-color: #a594f9 !important;
}

.has-background-purple-blue-300-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-300-100-mobile {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-300-100-tablet {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-300-100-tablet-only {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-300-100-touch {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-300-100-desktop {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-300-100-desktop-only {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-300-100-until-widescreen {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-300-100-widescreen {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-300-100-widescreen-only {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-300-100-until-fullhd {
    background-color: #a594f9;
  }
}

.has-background-purple-blue-300-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-300-100-fullhd {
    background-color: #a594f9;
  }
}

.has-text-purple-blue-400 {
  color: #2727c4 !important;
}

.has-border-purple-blue-400-1 {
  border: 1px solid #2727c4;
}

.has-inset-border-purple-blue-400-1 {
  box-shadow: inset 0 0 0 1px #2727c4;
}

.has-border-purple-blue-400-2 {
  border: 2px solid #2727c4;
}

.has-inset-border-purple-blue-400-2 {
  box-shadow: inset 0 0 0 2px #2727c4;
}

.has-border-purple-blue-400-3 {
  border: 3px solid #2727c4;
}

.has-inset-border-purple-blue-400-3 {
  box-shadow: inset 0 0 0 3px #2727c4;
}

.has-text-purple-blue-400-10 {
  color: #2727c41a !important;
}

.has-text-purple-blue-400-20 {
  color: #2727c433 !important;
}

.has-text-purple-blue-400-30 {
  color: #2727c44d !important;
}

.has-text-purple-blue-400-40 {
  color: #2727c466 !important;
}

.has-text-purple-blue-400-50 {
  color: #2727c480 !important;
}

.has-text-purple-blue-400-60 {
  color: #2727c499 !important;
}

.has-text-purple-blue-400-70 {
  color: #2727c4b3 !important;
}

.has-text-purple-blue-400-80 {
  color: #2727c4cc !important;
}

.has-text-purple-blue-400-90 {
  color: #2727c4e6 !important;
}

.has-text-purple-blue-400-100 {
  color: #2727c4 !important;
}

.has-background-purple-blue-400 {
  background-color: #2727c4 !important;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-mobile {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-tablet {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-tablet-only {
    background-color: #2727c4 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-touch {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-desktop {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-desktop-only {
    background-color: #2727c4 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-until-widescreen {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-widescreen {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-widescreen-only {
    background-color: #2727c4 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-until-fullhd {
    background-color: #2727c4 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-fullhd {
    background-color: #2727c4 !important;
  }
}

.has-background-purple-blue-400-10 {
  background-color: #2727c41a !important;
}

.has-background-purple-blue-400-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-10-mobile {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-10-tablet {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-10-tablet-only {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-10-touch {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-10-desktop {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-10-desktop-only {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-10-until-widescreen {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-10-widescreen {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-10-widescreen-only {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-10-until-fullhd {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-10-fullhd {
    background-color: #2727c41a;
  }
}

.has-background-purple-blue-400-20 {
  background-color: #2727c433 !important;
}

.has-background-purple-blue-400-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-20-mobile {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-20-tablet {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-20-tablet-only {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-20-touch {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-20-desktop {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-20-desktop-only {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-20-until-widescreen {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-20-widescreen {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-20-widescreen-only {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-20-until-fullhd {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-20-fullhd {
    background-color: #2727c433;
  }
}

.has-background-purple-blue-400-30 {
  background-color: #2727c44d !important;
}

.has-background-purple-blue-400-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-30-mobile {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-30-tablet {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-30-tablet-only {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-30-touch {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-30-desktop {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-30-desktop-only {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-30-until-widescreen {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-30-widescreen {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-30-widescreen-only {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-30-until-fullhd {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-30-fullhd {
    background-color: #2727c44d;
  }
}

.has-background-purple-blue-400-40 {
  background-color: #2727c466 !important;
}

.has-background-purple-blue-400-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-40-mobile {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-40-tablet {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-40-tablet-only {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-40-touch {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-40-desktop {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-40-desktop-only {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-40-until-widescreen {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-40-widescreen {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-40-widescreen-only {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-40-until-fullhd {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-40-fullhd {
    background-color: #2727c466;
  }
}

.has-background-purple-blue-400-50 {
  background-color: #2727c480 !important;
}

.has-background-purple-blue-400-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-50-mobile {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-50-tablet {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-50-tablet-only {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-50-touch {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-50-desktop {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-50-desktop-only {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-50-until-widescreen {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-50-widescreen {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-50-widescreen-only {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-50-until-fullhd {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-50-fullhd {
    background-color: #2727c480;
  }
}

.has-background-purple-blue-400-60 {
  background-color: #2727c499 !important;
}

.has-background-purple-blue-400-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-60-mobile {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-60-tablet {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-60-tablet-only {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-60-touch {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-60-desktop {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-60-desktop-only {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-60-until-widescreen {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-60-widescreen {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-60-widescreen-only {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-60-until-fullhd {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-60-fullhd {
    background-color: #2727c499;
  }
}

.has-background-purple-blue-400-70 {
  background-color: #2727c4b3 !important;
}

.has-background-purple-blue-400-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-70-mobile {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-70-tablet {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-70-tablet-only {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-70-touch {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-70-desktop {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-70-desktop-only {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-70-until-widescreen {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-70-widescreen {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-70-widescreen-only {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-70-until-fullhd {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-70-fullhd {
    background-color: #2727c4b3;
  }
}

.has-background-purple-blue-400-80 {
  background-color: #2727c4cc !important;
}

.has-background-purple-blue-400-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-80-mobile {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-80-tablet {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-80-tablet-only {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-80-touch {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-80-desktop {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-80-desktop-only {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-80-until-widescreen {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-80-widescreen {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-80-widescreen-only {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-80-until-fullhd {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-80-fullhd {
    background-color: #2727c4cc;
  }
}

.has-background-purple-blue-400-90 {
  background-color: #2727c4e6 !important;
}

.has-background-purple-blue-400-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-90-mobile {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-90-tablet {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-90-tablet-only {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-90-touch {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-90-desktop {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-90-desktop-only {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-90-until-widescreen {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-90-widescreen {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-90-widescreen-only {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-90-until-fullhd {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-90-fullhd {
    background-color: #2727c4e6;
  }
}

.has-background-purple-blue-400-100 {
  background-color: #2727c4 !important;
}

.has-background-purple-blue-400-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-400-100-mobile {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-400-100-tablet {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-400-100-tablet-only {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-400-100-touch {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-400-100-desktop {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-400-100-desktop-only {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-400-100-until-widescreen {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-400-100-widescreen {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-400-100-widescreen-only {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-400-100-until-fullhd {
    background-color: #2727c4;
  }
}

.has-background-purple-blue-400-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-400-100-fullhd {
    background-color: #2727c4;
  }
}

.has-text-purple-blue-500 {
  color: #1b0777 !important;
}

.has-border-purple-blue-500-1 {
  border: 1px solid #1b0777;
}

.has-inset-border-purple-blue-500-1 {
  box-shadow: inset 0 0 0 1px #1b0777;
}

.has-border-purple-blue-500-2 {
  border: 2px solid #1b0777;
}

.has-inset-border-purple-blue-500-2 {
  box-shadow: inset 0 0 0 2px #1b0777;
}

.has-border-purple-blue-500-3 {
  border: 3px solid #1b0777;
}

.has-inset-border-purple-blue-500-3 {
  box-shadow: inset 0 0 0 3px #1b0777;
}

.has-text-purple-blue-500-10 {
  color: #1b07771a !important;
}

.has-text-purple-blue-500-20 {
  color: #1b077733 !important;
}

.has-text-purple-blue-500-30 {
  color: #1b07774d !important;
}

.has-text-purple-blue-500-40 {
  color: #1b077766 !important;
}

.has-text-purple-blue-500-50 {
  color: #1b077780 !important;
}

.has-text-purple-blue-500-60 {
  color: #1b077799 !important;
}

.has-text-purple-blue-500-70 {
  color: #1b0777b3 !important;
}

.has-text-purple-blue-500-80 {
  color: #1b0777cc !important;
}

.has-text-purple-blue-500-90 {
  color: #1b0777e6 !important;
}

.has-text-purple-blue-500-100 {
  color: #1b0777 !important;
}

.has-background-purple-blue-500 {
  background-color: #1b0777 !important;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-mobile {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-tablet {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-tablet-only {
    background-color: #1b0777 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-touch {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-desktop {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-desktop-only {
    background-color: #1b0777 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-until-widescreen {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-widescreen {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-widescreen-only {
    background-color: #1b0777 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-until-fullhd {
    background-color: #1b0777 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-fullhd {
    background-color: #1b0777 !important;
  }
}

.has-background-purple-blue-500-10 {
  background-color: #1b07771a !important;
}

.has-background-purple-blue-500-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-10-mobile {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-10-tablet {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-10-tablet-only {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-10-touch {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-10-desktop {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-10-desktop-only {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-10-until-widescreen {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-10-widescreen {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-10-widescreen-only {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-10-until-fullhd {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-10-fullhd {
    background-color: #1b07771a;
  }
}

.has-background-purple-blue-500-20 {
  background-color: #1b077733 !important;
}

.has-background-purple-blue-500-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-20-mobile {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-20-tablet {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-20-tablet-only {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-20-touch {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-20-desktop {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-20-desktop-only {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-20-until-widescreen {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-20-widescreen {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-20-widescreen-only {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-20-until-fullhd {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-20-fullhd {
    background-color: #1b077733;
  }
}

.has-background-purple-blue-500-30 {
  background-color: #1b07774d !important;
}

.has-background-purple-blue-500-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-30-mobile {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-30-tablet {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-30-tablet-only {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-30-touch {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-30-desktop {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-30-desktop-only {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-30-until-widescreen {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-30-widescreen {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-30-widescreen-only {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-30-until-fullhd {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-30-fullhd {
    background-color: #1b07774d;
  }
}

.has-background-purple-blue-500-40 {
  background-color: #1b077766 !important;
}

.has-background-purple-blue-500-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-40-mobile {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-40-tablet {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-40-tablet-only {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-40-touch {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-40-desktop {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-40-desktop-only {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-40-until-widescreen {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-40-widescreen {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-40-widescreen-only {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-40-until-fullhd {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-40-fullhd {
    background-color: #1b077766;
  }
}

.has-background-purple-blue-500-50 {
  background-color: #1b077780 !important;
}

.has-background-purple-blue-500-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-50-mobile {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-50-tablet {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-50-tablet-only {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-50-touch {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-50-desktop {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-50-desktop-only {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-50-until-widescreen {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-50-widescreen {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-50-widescreen-only {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-50-until-fullhd {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-50-fullhd {
    background-color: #1b077780;
  }
}

.has-background-purple-blue-500-60 {
  background-color: #1b077799 !important;
}

.has-background-purple-blue-500-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-60-mobile {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-60-tablet {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-60-tablet-only {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-60-touch {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-60-desktop {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-60-desktop-only {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-60-until-widescreen {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-60-widescreen {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-60-widescreen-only {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-60-until-fullhd {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-60-fullhd {
    background-color: #1b077799;
  }
}

.has-background-purple-blue-500-70 {
  background-color: #1b0777b3 !important;
}

.has-background-purple-blue-500-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-70-mobile {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-70-tablet {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-70-tablet-only {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-70-touch {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-70-desktop {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-70-desktop-only {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-70-until-widescreen {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-70-widescreen {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-70-widescreen-only {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-70-until-fullhd {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-70-fullhd {
    background-color: #1b0777b3;
  }
}

.has-background-purple-blue-500-80 {
  background-color: #1b0777cc !important;
}

.has-background-purple-blue-500-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-80-mobile {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-80-tablet {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-80-tablet-only {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-80-touch {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-80-desktop {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-80-desktop-only {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-80-until-widescreen {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-80-widescreen {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-80-widescreen-only {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-80-until-fullhd {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-80-fullhd {
    background-color: #1b0777cc;
  }
}

.has-background-purple-blue-500-90 {
  background-color: #1b0777e6 !important;
}

.has-background-purple-blue-500-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-90-mobile {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-90-tablet {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-90-tablet-only {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-90-touch {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-90-desktop {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-90-desktop-only {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-90-until-widescreen {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-90-widescreen {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-90-widescreen-only {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-90-until-fullhd {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-90-fullhd {
    background-color: #1b0777e6;
  }
}

.has-background-purple-blue-500-100 {
  background-color: #1b0777 !important;
}

.has-background-purple-blue-500-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-purple-blue-500-100-mobile {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-purple-blue-500-100-tablet {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-purple-blue-500-100-tablet-only {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-purple-blue-500-100-touch {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-purple-blue-500-100-desktop {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-purple-blue-500-100-desktop-only {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-purple-blue-500-100-until-widescreen {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-purple-blue-500-100-widescreen {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-purple-blue-500-100-widescreen-only {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-purple-blue-500-100-until-fullhd {
    background-color: #1b0777;
  }
}

.has-background-purple-blue-500-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-purple-blue-500-100-fullhd {
    background-color: #1b0777;
  }
}

.has-text-peach {
  color: #e9897d !important;
}

.has-background-peach {
  background-color: #e9897d !important;
}

.has-text-peach-100 {
  color: #fdf3f2 !important;
}

.has-border-peach-100-1 {
  border: 1px solid #fdf3f2;
}

.has-inset-border-peach-100-1 {
  box-shadow: inset 0 0 0 1px #fdf3f2;
}

.has-border-peach-100-2 {
  border: 2px solid #fdf3f2;
}

.has-inset-border-peach-100-2 {
  box-shadow: inset 0 0 0 2px #fdf3f2;
}

.has-border-peach-100-3 {
  border: 3px solid #fdf3f2;
}

.has-inset-border-peach-100-3 {
  box-shadow: inset 0 0 0 3px #fdf3f2;
}

.has-text-peach-100-10 {
  color: #fdf3f21a !important;
}

.has-text-peach-100-20 {
  color: #fdf3f233 !important;
}

.has-text-peach-100-30 {
  color: #fdf3f24d !important;
}

.has-text-peach-100-40 {
  color: #fdf3f266 !important;
}

.has-text-peach-100-50 {
  color: #fdf3f280 !important;
}

.has-text-peach-100-60 {
  color: #fdf3f299 !important;
}

.has-text-peach-100-70 {
  color: #fdf3f2b3 !important;
}

.has-text-peach-100-80 {
  color: #fdf3f2cc !important;
}

.has-text-peach-100-90 {
  color: #fdf3f2e6 !important;
}

.has-text-peach-100-100 {
  color: #fdf3f2 !important;
}

.has-background-peach-100 {
  background-color: #fdf3f2 !important;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-mobile {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-peach-100-tablet {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-tablet-only {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-touch {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-desktop {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-desktop-only {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-until-widescreen {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-widescreen {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-widescreen-only {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-until-fullhd {
    background-color: #fdf3f2 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-fullhd {
    background-color: #fdf3f2 !important;
  }
}

.has-background-peach-100-10 {
  background-color: #fdf3f21a !important;
}

.has-background-peach-100-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-10-mobile {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-10-tablet {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-10-tablet-only {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-10-touch {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-10-desktop {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-10-desktop-only {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-10-until-widescreen {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-10-widescreen {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-10-widescreen-only {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-10-until-fullhd {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-10-fullhd {
    background-color: #fdf3f21a;
  }
}

.has-background-peach-100-20 {
  background-color: #fdf3f233 !important;
}

.has-background-peach-100-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-20-mobile {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-20-tablet {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-20-tablet-only {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-20-touch {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-20-desktop {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-20-desktop-only {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-20-until-widescreen {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-20-widescreen {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-20-widescreen-only {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-20-until-fullhd {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-20-fullhd {
    background-color: #fdf3f233;
  }
}

.has-background-peach-100-30 {
  background-color: #fdf3f24d !important;
}

.has-background-peach-100-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-30-mobile {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-30-tablet {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-30-tablet-only {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-30-touch {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-30-desktop {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-30-desktop-only {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-30-until-widescreen {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-30-widescreen {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-30-widescreen-only {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-30-until-fullhd {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-30-fullhd {
    background-color: #fdf3f24d;
  }
}

.has-background-peach-100-40 {
  background-color: #fdf3f266 !important;
}

.has-background-peach-100-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-40-mobile {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-40-tablet {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-40-tablet-only {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-40-touch {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-40-desktop {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-40-desktop-only {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-40-until-widescreen {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-40-widescreen {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-40-widescreen-only {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-40-until-fullhd {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-40-fullhd {
    background-color: #fdf3f266;
  }
}

.has-background-peach-100-50 {
  background-color: #fdf3f280 !important;
}

.has-background-peach-100-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-50-mobile {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-50-tablet {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-50-tablet-only {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-50-touch {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-50-desktop {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-50-desktop-only {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-50-until-widescreen {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-50-widescreen {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-50-widescreen-only {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-50-until-fullhd {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-50-fullhd {
    background-color: #fdf3f280;
  }
}

.has-background-peach-100-60 {
  background-color: #fdf3f299 !important;
}

.has-background-peach-100-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-60-mobile {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-60-tablet {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-60-tablet-only {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-60-touch {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-60-desktop {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-60-desktop-only {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-60-until-widescreen {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-60-widescreen {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-60-widescreen-only {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-60-until-fullhd {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-60-fullhd {
    background-color: #fdf3f299;
  }
}

.has-background-peach-100-70 {
  background-color: #fdf3f2b3 !important;
}

.has-background-peach-100-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-70-mobile {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-70-tablet {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-70-tablet-only {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-70-touch {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-70-desktop {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-70-desktop-only {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-70-until-widescreen {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-70-widescreen {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-70-widescreen-only {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-70-until-fullhd {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-70-fullhd {
    background-color: #fdf3f2b3;
  }
}

.has-background-peach-100-80 {
  background-color: #fdf3f2cc !important;
}

.has-background-peach-100-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-80-mobile {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-80-tablet {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-80-tablet-only {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-80-touch {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-80-desktop {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-80-desktop-only {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-80-until-widescreen {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-80-widescreen {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-80-widescreen-only {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-80-until-fullhd {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-80-fullhd {
    background-color: #fdf3f2cc;
  }
}

.has-background-peach-100-90 {
  background-color: #fdf3f2e6 !important;
}

.has-background-peach-100-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-90-mobile {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-90-tablet {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-90-tablet-only {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-90-touch {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-90-desktop {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-90-desktop-only {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-90-until-widescreen {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-90-widescreen {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-90-widescreen-only {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-90-until-fullhd {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-90-fullhd {
    background-color: #fdf3f2e6;
  }
}

.has-background-peach-100-100 {
  background-color: #fdf3f2 !important;
}

.has-background-peach-100-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-100-100-mobile {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-100-100-tablet {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-100-100-tablet-only {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-100-100-touch {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-100-100-desktop {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-100-100-desktop-only {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-100-100-until-widescreen {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-100-100-widescreen {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-100-100-widescreen-only {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-100-100-until-fullhd {
    background-color: #fdf3f2;
  }
}

.has-background-peach-100-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-100-100-fullhd {
    background-color: #fdf3f2;
  }
}

.has-text-peach-200 {
  color: #f8d8d4 !important;
}

.has-border-peach-200-1 {
  border: 1px solid #f8d8d4;
}

.has-inset-border-peach-200-1 {
  box-shadow: inset 0 0 0 1px #f8d8d4;
}

.has-border-peach-200-2 {
  border: 2px solid #f8d8d4;
}

.has-inset-border-peach-200-2 {
  box-shadow: inset 0 0 0 2px #f8d8d4;
}

.has-border-peach-200-3 {
  border: 3px solid #f8d8d4;
}

.has-inset-border-peach-200-3 {
  box-shadow: inset 0 0 0 3px #f8d8d4;
}

.has-text-peach-200-10 {
  color: #f8d8d41a !important;
}

.has-text-peach-200-20 {
  color: #f8d8d433 !important;
}

.has-text-peach-200-30 {
  color: #f8d8d44d !important;
}

.has-text-peach-200-40 {
  color: #f8d8d466 !important;
}

.has-text-peach-200-50 {
  color: #f8d8d480 !important;
}

.has-text-peach-200-60 {
  color: #f8d8d499 !important;
}

.has-text-peach-200-70 {
  color: #f8d8d4b3 !important;
}

.has-text-peach-200-80 {
  color: #f8d8d4cc !important;
}

.has-text-peach-200-90 {
  color: #f8d8d4e6 !important;
}

.has-text-peach-200-100 {
  color: #f8d8d4 !important;
}

.has-background-peach-200 {
  background-color: #f8d8d4 !important;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-mobile {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-peach-200-tablet {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-tablet-only {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-touch {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-desktop {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-desktop-only {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-until-widescreen {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-widescreen {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-widescreen-only {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-until-fullhd {
    background-color: #f8d8d4 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-fullhd {
    background-color: #f8d8d4 !important;
  }
}

.has-background-peach-200-10 {
  background-color: #f8d8d41a !important;
}

.has-background-peach-200-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-10-mobile {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-10-tablet {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-10-tablet-only {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-10-touch {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-10-desktop {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-10-desktop-only {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-10-until-widescreen {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-10-widescreen {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-10-widescreen-only {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-10-until-fullhd {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-10-fullhd {
    background-color: #f8d8d41a;
  }
}

.has-background-peach-200-20 {
  background-color: #f8d8d433 !important;
}

.has-background-peach-200-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-20-mobile {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-20-tablet {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-20-tablet-only {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-20-touch {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-20-desktop {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-20-desktop-only {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-20-until-widescreen {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-20-widescreen {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-20-widescreen-only {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-20-until-fullhd {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-20-fullhd {
    background-color: #f8d8d433;
  }
}

.has-background-peach-200-30 {
  background-color: #f8d8d44d !important;
}

.has-background-peach-200-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-30-mobile {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-30-tablet {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-30-tablet-only {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-30-touch {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-30-desktop {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-30-desktop-only {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-30-until-widescreen {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-30-widescreen {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-30-widescreen-only {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-30-until-fullhd {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-30-fullhd {
    background-color: #f8d8d44d;
  }
}

.has-background-peach-200-40 {
  background-color: #f8d8d466 !important;
}

.has-background-peach-200-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-40-mobile {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-40-tablet {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-40-tablet-only {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-40-touch {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-40-desktop {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-40-desktop-only {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-40-until-widescreen {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-40-widescreen {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-40-widescreen-only {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-40-until-fullhd {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-40-fullhd {
    background-color: #f8d8d466;
  }
}

.has-background-peach-200-50 {
  background-color: #f8d8d480 !important;
}

.has-background-peach-200-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-50-mobile {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-50-tablet {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-50-tablet-only {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-50-touch {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-50-desktop {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-50-desktop-only {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-50-until-widescreen {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-50-widescreen {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-50-widescreen-only {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-50-until-fullhd {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-50-fullhd {
    background-color: #f8d8d480;
  }
}

.has-background-peach-200-60 {
  background-color: #f8d8d499 !important;
}

.has-background-peach-200-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-60-mobile {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-60-tablet {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-60-tablet-only {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-60-touch {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-60-desktop {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-60-desktop-only {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-60-until-widescreen {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-60-widescreen {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-60-widescreen-only {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-60-until-fullhd {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-60-fullhd {
    background-color: #f8d8d499;
  }
}

.has-background-peach-200-70 {
  background-color: #f8d8d4b3 !important;
}

.has-background-peach-200-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-70-mobile {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-70-tablet {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-70-tablet-only {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-70-touch {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-70-desktop {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-70-desktop-only {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-70-until-widescreen {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-70-widescreen {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-70-widescreen-only {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-70-until-fullhd {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-70-fullhd {
    background-color: #f8d8d4b3;
  }
}

.has-background-peach-200-80 {
  background-color: #f8d8d4cc !important;
}

.has-background-peach-200-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-80-mobile {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-80-tablet {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-80-tablet-only {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-80-touch {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-80-desktop {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-80-desktop-only {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-80-until-widescreen {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-80-widescreen {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-80-widescreen-only {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-80-until-fullhd {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-80-fullhd {
    background-color: #f8d8d4cc;
  }
}

.has-background-peach-200-90 {
  background-color: #f8d8d4e6 !important;
}

.has-background-peach-200-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-90-mobile {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-90-tablet {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-90-tablet-only {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-90-touch {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-90-desktop {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-90-desktop-only {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-90-until-widescreen {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-90-widescreen {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-90-widescreen-only {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-90-until-fullhd {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-90-fullhd {
    background-color: #f8d8d4e6;
  }
}

.has-background-peach-200-100 {
  background-color: #f8d8d4 !important;
}

.has-background-peach-200-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-200-100-mobile {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-200-100-tablet {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-200-100-tablet-only {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-200-100-touch {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-200-100-desktop {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-200-100-desktop-only {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-200-100-until-widescreen {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-200-100-widescreen {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-200-100-widescreen-only {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-200-100-until-fullhd {
    background-color: #f8d8d4;
  }
}

.has-background-peach-200-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-200-100-fullhd {
    background-color: #f8d8d4;
  }
}

.has-text-peach-300 {
  color: #f0b0a8 !important;
}

.has-border-peach-300-1 {
  border: 1px solid #f0b0a8;
}

.has-inset-border-peach-300-1 {
  box-shadow: inset 0 0 0 1px #f0b0a8;
}

.has-border-peach-300-2 {
  border: 2px solid #f0b0a8;
}

.has-inset-border-peach-300-2 {
  box-shadow: inset 0 0 0 2px #f0b0a8;
}

.has-border-peach-300-3 {
  border: 3px solid #f0b0a8;
}

.has-inset-border-peach-300-3 {
  box-shadow: inset 0 0 0 3px #f0b0a8;
}

.has-text-peach-300-10 {
  color: #f0b0a81a !important;
}

.has-text-peach-300-20 {
  color: #f0b0a833 !important;
}

.has-text-peach-300-30 {
  color: #f0b0a84d !important;
}

.has-text-peach-300-40 {
  color: #f0b0a866 !important;
}

.has-text-peach-300-50 {
  color: #f0b0a880 !important;
}

.has-text-peach-300-60 {
  color: #f0b0a899 !important;
}

.has-text-peach-300-70 {
  color: #f0b0a8b3 !important;
}

.has-text-peach-300-80 {
  color: #f0b0a8cc !important;
}

.has-text-peach-300-90 {
  color: #f0b0a8e6 !important;
}

.has-text-peach-300-100 {
  color: #f0b0a8 !important;
}

.has-background-peach-300 {
  background-color: #f0b0a8 !important;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-mobile {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-peach-300-tablet {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-tablet-only {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-touch {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-desktop {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-desktop-only {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-until-widescreen {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-widescreen {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-widescreen-only {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-until-fullhd {
    background-color: #f0b0a8 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-fullhd {
    background-color: #f0b0a8 !important;
  }
}

.has-background-peach-300-10 {
  background-color: #f0b0a81a !important;
}

.has-background-peach-300-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-10-mobile {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-10-tablet {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-10-tablet-only {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-10-touch {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-10-desktop {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-10-desktop-only {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-10-until-widescreen {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-10-widescreen {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-10-widescreen-only {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-10-until-fullhd {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-10-fullhd {
    background-color: #f0b0a81a;
  }
}

.has-background-peach-300-20 {
  background-color: #f0b0a833 !important;
}

.has-background-peach-300-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-20-mobile {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-20-tablet {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-20-tablet-only {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-20-touch {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-20-desktop {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-20-desktop-only {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-20-until-widescreen {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-20-widescreen {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-20-widescreen-only {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-20-until-fullhd {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-20-fullhd {
    background-color: #f0b0a833;
  }
}

.has-background-peach-300-30 {
  background-color: #f0b0a84d !important;
}

.has-background-peach-300-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-30-mobile {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-30-tablet {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-30-tablet-only {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-30-touch {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-30-desktop {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-30-desktop-only {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-30-until-widescreen {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-30-widescreen {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-30-widescreen-only {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-30-until-fullhd {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-30-fullhd {
    background-color: #f0b0a84d;
  }
}

.has-background-peach-300-40 {
  background-color: #f0b0a866 !important;
}

.has-background-peach-300-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-40-mobile {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-40-tablet {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-40-tablet-only {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-40-touch {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-40-desktop {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-40-desktop-only {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-40-until-widescreen {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-40-widescreen {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-40-widescreen-only {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-40-until-fullhd {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-40-fullhd {
    background-color: #f0b0a866;
  }
}

.has-background-peach-300-50 {
  background-color: #f0b0a880 !important;
}

.has-background-peach-300-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-50-mobile {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-50-tablet {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-50-tablet-only {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-50-touch {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-50-desktop {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-50-desktop-only {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-50-until-widescreen {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-50-widescreen {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-50-widescreen-only {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-50-until-fullhd {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-50-fullhd {
    background-color: #f0b0a880;
  }
}

.has-background-peach-300-60 {
  background-color: #f0b0a899 !important;
}

.has-background-peach-300-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-60-mobile {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-60-tablet {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-60-tablet-only {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-60-touch {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-60-desktop {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-60-desktop-only {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-60-until-widescreen {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-60-widescreen {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-60-widescreen-only {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-60-until-fullhd {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-60-fullhd {
    background-color: #f0b0a899;
  }
}

.has-background-peach-300-70 {
  background-color: #f0b0a8b3 !important;
}

.has-background-peach-300-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-70-mobile {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-70-tablet {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-70-tablet-only {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-70-touch {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-70-desktop {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-70-desktop-only {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-70-until-widescreen {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-70-widescreen {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-70-widescreen-only {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-70-until-fullhd {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-70-fullhd {
    background-color: #f0b0a8b3;
  }
}

.has-background-peach-300-80 {
  background-color: #f0b0a8cc !important;
}

.has-background-peach-300-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-80-mobile {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-80-tablet {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-80-tablet-only {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-80-touch {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-80-desktop {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-80-desktop-only {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-80-until-widescreen {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-80-widescreen {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-80-widescreen-only {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-80-until-fullhd {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-80-fullhd {
    background-color: #f0b0a8cc;
  }
}

.has-background-peach-300-90 {
  background-color: #f0b0a8e6 !important;
}

.has-background-peach-300-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-90-mobile {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-90-tablet {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-90-tablet-only {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-90-touch {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-90-desktop {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-90-desktop-only {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-90-until-widescreen {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-90-widescreen {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-90-widescreen-only {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-90-until-fullhd {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-90-fullhd {
    background-color: #f0b0a8e6;
  }
}

.has-background-peach-300-100 {
  background-color: #f0b0a8 !important;
}

.has-background-peach-300-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-300-100-mobile {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-300-100-tablet {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-300-100-tablet-only {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-300-100-touch {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-300-100-desktop {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-300-100-desktop-only {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-300-100-until-widescreen {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-300-100-widescreen {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-300-100-widescreen-only {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-300-100-until-fullhd {
    background-color: #f0b0a8;
  }
}

.has-background-peach-300-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-300-100-fullhd {
    background-color: #f0b0a8;
  }
}

.has-text-peach-400 {
  color: #e9897d !important;
}

.has-border-peach-400-1 {
  border: 1px solid #e9897d;
}

.has-inset-border-peach-400-1 {
  box-shadow: inset 0 0 0 1px #e9897d;
}

.has-border-peach-400-2 {
  border: 2px solid #e9897d;
}

.has-inset-border-peach-400-2 {
  box-shadow: inset 0 0 0 2px #e9897d;
}

.has-border-peach-400-3 {
  border: 3px solid #e9897d;
}

.has-inset-border-peach-400-3 {
  box-shadow: inset 0 0 0 3px #e9897d;
}

.has-text-peach-400-10 {
  color: #e9897d1a !important;
}

.has-text-peach-400-20 {
  color: #e9897d33 !important;
}

.has-text-peach-400-30 {
  color: #e9897d4d !important;
}

.has-text-peach-400-40 {
  color: #e9897d66 !important;
}

.has-text-peach-400-50 {
  color: #e9897d80 !important;
}

.has-text-peach-400-60 {
  color: #e9897d99 !important;
}

.has-text-peach-400-70 {
  color: #e9897db3 !important;
}

.has-text-peach-400-80 {
  color: #e9897dcc !important;
}

.has-text-peach-400-90 {
  color: #e9897de6 !important;
}

.has-text-peach-400-100 {
  color: #e9897d !important;
}

.has-background-peach-400 {
  background-color: #e9897d !important;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-mobile {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-peach-400-tablet {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-tablet-only {
    background-color: #e9897d !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-touch {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-desktop {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-desktop-only {
    background-color: #e9897d !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-until-widescreen {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-widescreen {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-widescreen-only {
    background-color: #e9897d !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-until-fullhd {
    background-color: #e9897d !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-fullhd {
    background-color: #e9897d !important;
  }
}

.has-background-peach-400-10 {
  background-color: #e9897d1a !important;
}

.has-background-peach-400-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-10-mobile {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-10-tablet {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-10-tablet-only {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-10-touch {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-10-desktop {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-10-desktop-only {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-10-until-widescreen {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-10-widescreen {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-10-widescreen-only {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-10-until-fullhd {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-10-fullhd {
    background-color: #e9897d1a;
  }
}

.has-background-peach-400-20 {
  background-color: #e9897d33 !important;
}

.has-background-peach-400-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-20-mobile {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-20-tablet {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-20-tablet-only {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-20-touch {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-20-desktop {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-20-desktop-only {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-20-until-widescreen {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-20-widescreen {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-20-widescreen-only {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-20-until-fullhd {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-20-fullhd {
    background-color: #e9897d33;
  }
}

.has-background-peach-400-30 {
  background-color: #e9897d4d !important;
}

.has-background-peach-400-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-30-mobile {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-30-tablet {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-30-tablet-only {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-30-touch {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-30-desktop {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-30-desktop-only {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-30-until-widescreen {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-30-widescreen {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-30-widescreen-only {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-30-until-fullhd {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-30-fullhd {
    background-color: #e9897d4d;
  }
}

.has-background-peach-400-40 {
  background-color: #e9897d66 !important;
}

.has-background-peach-400-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-40-mobile {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-40-tablet {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-40-tablet-only {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-40-touch {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-40-desktop {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-40-desktop-only {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-40-until-widescreen {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-40-widescreen {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-40-widescreen-only {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-40-until-fullhd {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-40-fullhd {
    background-color: #e9897d66;
  }
}

.has-background-peach-400-50 {
  background-color: #e9897d80 !important;
}

.has-background-peach-400-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-50-mobile {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-50-tablet {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-50-tablet-only {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-50-touch {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-50-desktop {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-50-desktop-only {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-50-until-widescreen {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-50-widescreen {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-50-widescreen-only {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-50-until-fullhd {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-50-fullhd {
    background-color: #e9897d80;
  }
}

.has-background-peach-400-60 {
  background-color: #e9897d99 !important;
}

.has-background-peach-400-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-60-mobile {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-60-tablet {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-60-tablet-only {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-60-touch {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-60-desktop {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-60-desktop-only {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-60-until-widescreen {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-60-widescreen {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-60-widescreen-only {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-60-until-fullhd {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-60-fullhd {
    background-color: #e9897d99;
  }
}

.has-background-peach-400-70 {
  background-color: #e9897db3 !important;
}

.has-background-peach-400-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-70-mobile {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-70-tablet {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-70-tablet-only {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-70-touch {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-70-desktop {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-70-desktop-only {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-70-until-widescreen {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-70-widescreen {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-70-widescreen-only {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-70-until-fullhd {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-70-fullhd {
    background-color: #e9897db3;
  }
}

.has-background-peach-400-80 {
  background-color: #e9897dcc !important;
}

.has-background-peach-400-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-80-mobile {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-80-tablet {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-80-tablet-only {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-80-touch {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-80-desktop {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-80-desktop-only {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-80-until-widescreen {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-80-widescreen {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-80-widescreen-only {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-80-until-fullhd {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-80-fullhd {
    background-color: #e9897dcc;
  }
}

.has-background-peach-400-90 {
  background-color: #e9897de6 !important;
}

.has-background-peach-400-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-90-mobile {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-90-tablet {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-90-tablet-only {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-90-touch {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-90-desktop {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-90-desktop-only {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-90-until-widescreen {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-90-widescreen {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-90-widescreen-only {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-90-until-fullhd {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-90-fullhd {
    background-color: #e9897de6;
  }
}

.has-background-peach-400-100 {
  background-color: #e9897d !important;
}

.has-background-peach-400-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-400-100-mobile {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-400-100-tablet {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-400-100-tablet-only {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-400-100-touch {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-400-100-desktop {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-400-100-desktop-only {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-400-100-until-widescreen {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-400-100-widescreen {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-400-100-widescreen-only {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-400-100-until-fullhd {
    background-color: #e9897d;
  }
}

.has-background-peach-400-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-400-100-fullhd {
    background-color: #e9897d;
  }
}

.has-text-peach-500 {
  color: #9b5b53 !important;
}

.has-border-peach-500-1 {
  border: 1px solid #9b5b53;
}

.has-inset-border-peach-500-1 {
  box-shadow: inset 0 0 0 1px #9b5b53;
}

.has-border-peach-500-2 {
  border: 2px solid #9b5b53;
}

.has-inset-border-peach-500-2 {
  box-shadow: inset 0 0 0 2px #9b5b53;
}

.has-border-peach-500-3 {
  border: 3px solid #9b5b53;
}

.has-inset-border-peach-500-3 {
  box-shadow: inset 0 0 0 3px #9b5b53;
}

.has-text-peach-500-10 {
  color: #9b5b531a !important;
}

.has-text-peach-500-20 {
  color: #9b5b5333 !important;
}

.has-text-peach-500-30 {
  color: #9b5b534d !important;
}

.has-text-peach-500-40 {
  color: #9b5b5366 !important;
}

.has-text-peach-500-50 {
  color: #9b5b5380 !important;
}

.has-text-peach-500-60 {
  color: #9b5b5399 !important;
}

.has-text-peach-500-70 {
  color: #9b5b53b3 !important;
}

.has-text-peach-500-80 {
  color: #9b5b53cc !important;
}

.has-text-peach-500-90 {
  color: #9b5b53e6 !important;
}

.has-text-peach-500-100 {
  color: #9b5b53 !important;
}

.has-background-peach-500 {
  background-color: #9b5b53 !important;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-mobile {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-peach-500-tablet {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-tablet-only {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-touch {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-desktop {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-desktop-only {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-until-widescreen {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-widescreen {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-widescreen-only {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-until-fullhd {
    background-color: #9b5b53 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-fullhd {
    background-color: #9b5b53 !important;
  }
}

.has-background-peach-500-10 {
  background-color: #9b5b531a !important;
}

.has-background-peach-500-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-10-mobile {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-10-tablet {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-10-tablet-only {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-10-touch {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-10-desktop {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-10-desktop-only {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-10-until-widescreen {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-10-widescreen {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-10-widescreen-only {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-10-until-fullhd {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-10-fullhd {
    background-color: #9b5b531a;
  }
}

.has-background-peach-500-20 {
  background-color: #9b5b5333 !important;
}

.has-background-peach-500-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-20-mobile {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-20-tablet {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-20-tablet-only {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-20-touch {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-20-desktop {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-20-desktop-only {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-20-until-widescreen {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-20-widescreen {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-20-widescreen-only {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-20-until-fullhd {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-20-fullhd {
    background-color: #9b5b5333;
  }
}

.has-background-peach-500-30 {
  background-color: #9b5b534d !important;
}

.has-background-peach-500-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-30-mobile {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-30-tablet {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-30-tablet-only {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-30-touch {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-30-desktop {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-30-desktop-only {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-30-until-widescreen {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-30-widescreen {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-30-widescreen-only {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-30-until-fullhd {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-30-fullhd {
    background-color: #9b5b534d;
  }
}

.has-background-peach-500-40 {
  background-color: #9b5b5366 !important;
}

.has-background-peach-500-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-40-mobile {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-40-tablet {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-40-tablet-only {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-40-touch {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-40-desktop {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-40-desktop-only {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-40-until-widescreen {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-40-widescreen {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-40-widescreen-only {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-40-until-fullhd {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-40-fullhd {
    background-color: #9b5b5366;
  }
}

.has-background-peach-500-50 {
  background-color: #9b5b5380 !important;
}

.has-background-peach-500-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-50-mobile {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-50-tablet {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-50-tablet-only {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-50-touch {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-50-desktop {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-50-desktop-only {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-50-until-widescreen {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-50-widescreen {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-50-widescreen-only {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-50-until-fullhd {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-50-fullhd {
    background-color: #9b5b5380;
  }
}

.has-background-peach-500-60 {
  background-color: #9b5b5399 !important;
}

.has-background-peach-500-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-60-mobile {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-60-tablet {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-60-tablet-only {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-60-touch {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-60-desktop {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-60-desktop-only {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-60-until-widescreen {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-60-widescreen {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-60-widescreen-only {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-60-until-fullhd {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-60-fullhd {
    background-color: #9b5b5399;
  }
}

.has-background-peach-500-70 {
  background-color: #9b5b53b3 !important;
}

.has-background-peach-500-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-70-mobile {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-70-tablet {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-70-tablet-only {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-70-touch {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-70-desktop {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-70-desktop-only {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-70-until-widescreen {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-70-widescreen {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-70-widescreen-only {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-70-until-fullhd {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-70-fullhd {
    background-color: #9b5b53b3;
  }
}

.has-background-peach-500-80 {
  background-color: #9b5b53cc !important;
}

.has-background-peach-500-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-80-mobile {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-80-tablet {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-80-tablet-only {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-80-touch {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-80-desktop {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-80-desktop-only {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-80-until-widescreen {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-80-widescreen {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-80-widescreen-only {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-80-until-fullhd {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-80-fullhd {
    background-color: #9b5b53cc;
  }
}

.has-background-peach-500-90 {
  background-color: #9b5b53e6 !important;
}

.has-background-peach-500-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-90-mobile {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-90-tablet {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-90-tablet-only {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-90-touch {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-90-desktop {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-90-desktop-only {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-90-until-widescreen {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-90-widescreen {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-90-widescreen-only {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-90-until-fullhd {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-90-fullhd {
    background-color: #9b5b53e6;
  }
}

.has-background-peach-500-100 {
  background-color: #9b5b53 !important;
}

.has-background-peach-500-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-peach-500-100-mobile {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-peach-500-100-tablet {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-peach-500-100-tablet-only {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-peach-500-100-touch {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-peach-500-100-desktop {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-peach-500-100-desktop-only {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-peach-500-100-until-widescreen {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-peach-500-100-widescreen {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-peach-500-100-widescreen-only {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-peach-500-100-until-fullhd {
    background-color: #9b5b53;
  }
}

.has-background-peach-500-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-peach-500-100-fullhd {
    background-color: #9b5b53;
  }
}

.has-text-yellow {
  color: #ffb938 !important;
}

.has-background-yellow {
  background-color: #ffb938 !important;
}

.has-text-yellow-100 {
  color: #fff8f1 !important;
}

.has-border-yellow-100-1 {
  border: 1px solid #fff8f1;
}

.has-inset-border-yellow-100-1 {
  box-shadow: inset 0 0 0 1px #fff8f1;
}

.has-border-yellow-100-2 {
  border: 2px solid #fff8f1;
}

.has-inset-border-yellow-100-2 {
  box-shadow: inset 0 0 0 2px #fff8f1;
}

.has-border-yellow-100-3 {
  border: 3px solid #fff8f1;
}

.has-inset-border-yellow-100-3 {
  box-shadow: inset 0 0 0 3px #fff8f1;
}

.has-text-yellow-100-10 {
  color: #fff8f11a !important;
}

.has-text-yellow-100-20 {
  color: #fff8f133 !important;
}

.has-text-yellow-100-30 {
  color: #fff8f14d !important;
}

.has-text-yellow-100-40 {
  color: #fff8f166 !important;
}

.has-text-yellow-100-50 {
  color: #fff8f180 !important;
}

.has-text-yellow-100-60 {
  color: #fff8f199 !important;
}

.has-text-yellow-100-70 {
  color: #fff8f1b3 !important;
}

.has-text-yellow-100-80 {
  color: #fff8f1cc !important;
}

.has-text-yellow-100-90 {
  color: #fff8f1e6 !important;
}

.has-text-yellow-100-100 {
  color: #fff8f1 !important;
}

.has-background-yellow-100 {
  background-color: #fff8f1 !important;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-mobile {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-tablet {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-tablet-only {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-touch {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-desktop {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-desktop-only {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-until-widescreen {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-widescreen {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-widescreen-only {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-until-fullhd {
    background-color: #fff8f1 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-fullhd {
    background-color: #fff8f1 !important;
  }
}

.has-background-yellow-100-10 {
  background-color: #fff8f11a !important;
}

.has-background-yellow-100-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-10-mobile {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-10-tablet {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-10-tablet-only {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-10-touch {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-10-desktop {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-10-desktop-only {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-10-until-widescreen {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-10-widescreen {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-10-widescreen-only {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-10-until-fullhd {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-10-fullhd {
    background-color: #fff8f11a;
  }
}

.has-background-yellow-100-20 {
  background-color: #fff8f133 !important;
}

.has-background-yellow-100-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-20-mobile {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-20-tablet {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-20-tablet-only {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-20-touch {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-20-desktop {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-20-desktop-only {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-20-until-widescreen {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-20-widescreen {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-20-widescreen-only {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-20-until-fullhd {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-20-fullhd {
    background-color: #fff8f133;
  }
}

.has-background-yellow-100-30 {
  background-color: #fff8f14d !important;
}

.has-background-yellow-100-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-30-mobile {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-30-tablet {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-30-tablet-only {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-30-touch {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-30-desktop {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-30-desktop-only {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-30-until-widescreen {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-30-widescreen {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-30-widescreen-only {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-30-until-fullhd {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-30-fullhd {
    background-color: #fff8f14d;
  }
}

.has-background-yellow-100-40 {
  background-color: #fff8f166 !important;
}

.has-background-yellow-100-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-40-mobile {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-40-tablet {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-40-tablet-only {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-40-touch {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-40-desktop {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-40-desktop-only {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-40-until-widescreen {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-40-widescreen {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-40-widescreen-only {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-40-until-fullhd {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-40-fullhd {
    background-color: #fff8f166;
  }
}

.has-background-yellow-100-50 {
  background-color: #fff8f180 !important;
}

.has-background-yellow-100-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-50-mobile {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-50-tablet {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-50-tablet-only {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-50-touch {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-50-desktop {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-50-desktop-only {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-50-until-widescreen {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-50-widescreen {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-50-widescreen-only {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-50-until-fullhd {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-50-fullhd {
    background-color: #fff8f180;
  }
}

.has-background-yellow-100-60 {
  background-color: #fff8f199 !important;
}

.has-background-yellow-100-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-60-mobile {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-60-tablet {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-60-tablet-only {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-60-touch {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-60-desktop {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-60-desktop-only {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-60-until-widescreen {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-60-widescreen {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-60-widescreen-only {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-60-until-fullhd {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-60-fullhd {
    background-color: #fff8f199;
  }
}

.has-background-yellow-100-70 {
  background-color: #fff8f1b3 !important;
}

.has-background-yellow-100-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-70-mobile {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-70-tablet {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-70-tablet-only {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-70-touch {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-70-desktop {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-70-desktop-only {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-70-until-widescreen {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-70-widescreen {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-70-widescreen-only {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-70-until-fullhd {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-70-fullhd {
    background-color: #fff8f1b3;
  }
}

.has-background-yellow-100-80 {
  background-color: #fff8f1cc !important;
}

.has-background-yellow-100-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-80-mobile {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-80-tablet {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-80-tablet-only {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-80-touch {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-80-desktop {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-80-desktop-only {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-80-until-widescreen {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-80-widescreen {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-80-widescreen-only {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-80-until-fullhd {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-80-fullhd {
    background-color: #fff8f1cc;
  }
}

.has-background-yellow-100-90 {
  background-color: #fff8f1e6 !important;
}

.has-background-yellow-100-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-90-mobile {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-90-tablet {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-90-tablet-only {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-90-touch {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-90-desktop {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-90-desktop-only {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-90-until-widescreen {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-90-widescreen {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-90-widescreen-only {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-90-until-fullhd {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-90-fullhd {
    background-color: #fff8f1e6;
  }
}

.has-background-yellow-100-100 {
  background-color: #fff8f1 !important;
}

.has-background-yellow-100-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-100-100-mobile {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-100-100-tablet {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-100-100-tablet-only {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-100-100-touch {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-100-100-desktop {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-100-100-desktop-only {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-100-100-until-widescreen {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-100-100-widescreen {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-100-100-widescreen-only {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-100-100-until-fullhd {
    background-color: #fff8f1;
  }
}

.has-background-yellow-100-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-100-100-fullhd {
    background-color: #fff8f1;
  }
}

.has-text-yellow-200 {
  color: #ffe8bd !important;
}

.has-border-yellow-200-1 {
  border: 1px solid #ffe8bd;
}

.has-inset-border-yellow-200-1 {
  box-shadow: inset 0 0 0 1px #ffe8bd;
}

.has-border-yellow-200-2 {
  border: 2px solid #ffe8bd;
}

.has-inset-border-yellow-200-2 {
  box-shadow: inset 0 0 0 2px #ffe8bd;
}

.has-border-yellow-200-3 {
  border: 3px solid #ffe8bd;
}

.has-inset-border-yellow-200-3 {
  box-shadow: inset 0 0 0 3px #ffe8bd;
}

.has-text-yellow-200-10 {
  color: #ffe8bd1a !important;
}

.has-text-yellow-200-20 {
  color: #ffe8bd33 !important;
}

.has-text-yellow-200-30 {
  color: #ffe8bd4d !important;
}

.has-text-yellow-200-40 {
  color: #ffe8bd66 !important;
}

.has-text-yellow-200-50 {
  color: #ffe8bd80 !important;
}

.has-text-yellow-200-60 {
  color: #ffe8bd99 !important;
}

.has-text-yellow-200-70 {
  color: #ffe8bdb3 !important;
}

.has-text-yellow-200-80 {
  color: #ffe8bdcc !important;
}

.has-text-yellow-200-90 {
  color: #ffe8bde6 !important;
}

.has-text-yellow-200-100 {
  color: #ffe8bd !important;
}

.has-background-yellow-200 {
  background-color: #ffe8bd !important;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-mobile {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-tablet {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-tablet-only {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-touch {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-desktop {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-desktop-only {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-until-widescreen {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-widescreen {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-widescreen-only {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-until-fullhd {
    background-color: #ffe8bd !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-fullhd {
    background-color: #ffe8bd !important;
  }
}

.has-background-yellow-200-10 {
  background-color: #ffe8bd1a !important;
}

.has-background-yellow-200-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-10-mobile {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-10-tablet {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-10-tablet-only {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-10-touch {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-10-desktop {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-10-desktop-only {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-10-until-widescreen {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-10-widescreen {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-10-widescreen-only {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-10-until-fullhd {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-10-fullhd {
    background-color: #ffe8bd1a;
  }
}

.has-background-yellow-200-20 {
  background-color: #ffe8bd33 !important;
}

.has-background-yellow-200-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-20-mobile {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-20-tablet {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-20-tablet-only {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-20-touch {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-20-desktop {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-20-desktop-only {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-20-until-widescreen {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-20-widescreen {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-20-widescreen-only {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-20-until-fullhd {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-20-fullhd {
    background-color: #ffe8bd33;
  }
}

.has-background-yellow-200-30 {
  background-color: #ffe8bd4d !important;
}

.has-background-yellow-200-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-30-mobile {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-30-tablet {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-30-tablet-only {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-30-touch {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-30-desktop {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-30-desktop-only {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-30-until-widescreen {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-30-widescreen {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-30-widescreen-only {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-30-until-fullhd {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-30-fullhd {
    background-color: #ffe8bd4d;
  }
}

.has-background-yellow-200-40 {
  background-color: #ffe8bd66 !important;
}

.has-background-yellow-200-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-40-mobile {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-40-tablet {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-40-tablet-only {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-40-touch {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-40-desktop {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-40-desktop-only {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-40-until-widescreen {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-40-widescreen {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-40-widescreen-only {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-40-until-fullhd {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-40-fullhd {
    background-color: #ffe8bd66;
  }
}

.has-background-yellow-200-50 {
  background-color: #ffe8bd80 !important;
}

.has-background-yellow-200-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-50-mobile {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-50-tablet {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-50-tablet-only {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-50-touch {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-50-desktop {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-50-desktop-only {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-50-until-widescreen {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-50-widescreen {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-50-widescreen-only {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-50-until-fullhd {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-50-fullhd {
    background-color: #ffe8bd80;
  }
}

.has-background-yellow-200-60 {
  background-color: #ffe8bd99 !important;
}

.has-background-yellow-200-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-60-mobile {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-60-tablet {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-60-tablet-only {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-60-touch {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-60-desktop {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-60-desktop-only {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-60-until-widescreen {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-60-widescreen {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-60-widescreen-only {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-60-until-fullhd {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-60-fullhd {
    background-color: #ffe8bd99;
  }
}

.has-background-yellow-200-70 {
  background-color: #ffe8bdb3 !important;
}

.has-background-yellow-200-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-70-mobile {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-70-tablet {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-70-tablet-only {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-70-touch {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-70-desktop {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-70-desktop-only {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-70-until-widescreen {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-70-widescreen {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-70-widescreen-only {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-70-until-fullhd {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-70-fullhd {
    background-color: #ffe8bdb3;
  }
}

.has-background-yellow-200-80 {
  background-color: #ffe8bdcc !important;
}

.has-background-yellow-200-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-80-mobile {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-80-tablet {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-80-tablet-only {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-80-touch {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-80-desktop {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-80-desktop-only {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-80-until-widescreen {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-80-widescreen {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-80-widescreen-only {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-80-until-fullhd {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-80-fullhd {
    background-color: #ffe8bdcc;
  }
}

.has-background-yellow-200-90 {
  background-color: #ffe8bde6 !important;
}

.has-background-yellow-200-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-90-mobile {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-90-tablet {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-90-tablet-only {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-90-touch {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-90-desktop {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-90-desktop-only {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-90-until-widescreen {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-90-widescreen {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-90-widescreen-only {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-90-until-fullhd {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-90-fullhd {
    background-color: #ffe8bde6;
  }
}

.has-background-yellow-200-100 {
  background-color: #ffe8bd !important;
}

.has-background-yellow-200-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-200-100-mobile {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-200-100-tablet {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-200-100-tablet-only {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-200-100-touch {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-200-100-desktop {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-200-100-desktop-only {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-200-100-until-widescreen {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-200-100-widescreen {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-200-100-widescreen-only {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-200-100-until-fullhd {
    background-color: #ffe8bd;
  }
}

.has-background-yellow-200-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-200-100-fullhd {
    background-color: #ffe8bd;
  }
}

.has-text-yellow-300 {
  color: #ffd07a !important;
}

.has-border-yellow-300-1 {
  border: 1px solid #ffd07a;
}

.has-inset-border-yellow-300-1 {
  box-shadow: inset 0 0 0 1px #ffd07a;
}

.has-border-yellow-300-2 {
  border: 2px solid #ffd07a;
}

.has-inset-border-yellow-300-2 {
  box-shadow: inset 0 0 0 2px #ffd07a;
}

.has-border-yellow-300-3 {
  border: 3px solid #ffd07a;
}

.has-inset-border-yellow-300-3 {
  box-shadow: inset 0 0 0 3px #ffd07a;
}

.has-text-yellow-300-10 {
  color: #ffd07a1a !important;
}

.has-text-yellow-300-20 {
  color: #ffd07a33 !important;
}

.has-text-yellow-300-30 {
  color: #ffd07a4d !important;
}

.has-text-yellow-300-40 {
  color: #ffd07a66 !important;
}

.has-text-yellow-300-50 {
  color: #ffd07a80 !important;
}

.has-text-yellow-300-60 {
  color: #ffd07a99 !important;
}

.has-text-yellow-300-70 {
  color: #ffd07ab3 !important;
}

.has-text-yellow-300-80 {
  color: #ffd07acc !important;
}

.has-text-yellow-300-90 {
  color: #ffd07ae6 !important;
}

.has-text-yellow-300-100 {
  color: #ffd07a !important;
}

.has-background-yellow-300 {
  background-color: #ffd07a !important;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-mobile {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-tablet {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-tablet-only {
    background-color: #ffd07a !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-touch {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-desktop {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-desktop-only {
    background-color: #ffd07a !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-until-widescreen {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-widescreen {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-widescreen-only {
    background-color: #ffd07a !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-until-fullhd {
    background-color: #ffd07a !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-fullhd {
    background-color: #ffd07a !important;
  }
}

.has-background-yellow-300-10 {
  background-color: #ffd07a1a !important;
}

.has-background-yellow-300-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-10-mobile {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-10-tablet {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-10-tablet-only {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-10-touch {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-10-desktop {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-10-desktop-only {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-10-until-widescreen {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-10-widescreen {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-10-widescreen-only {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-10-until-fullhd {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-10-fullhd {
    background-color: #ffd07a1a;
  }
}

.has-background-yellow-300-20 {
  background-color: #ffd07a33 !important;
}

.has-background-yellow-300-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-20-mobile {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-20-tablet {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-20-tablet-only {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-20-touch {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-20-desktop {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-20-desktop-only {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-20-until-widescreen {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-20-widescreen {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-20-widescreen-only {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-20-until-fullhd {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-20-fullhd {
    background-color: #ffd07a33;
  }
}

.has-background-yellow-300-30 {
  background-color: #ffd07a4d !important;
}

.has-background-yellow-300-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-30-mobile {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-30-tablet {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-30-tablet-only {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-30-touch {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-30-desktop {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-30-desktop-only {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-30-until-widescreen {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-30-widescreen {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-30-widescreen-only {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-30-until-fullhd {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-30-fullhd {
    background-color: #ffd07a4d;
  }
}

.has-background-yellow-300-40 {
  background-color: #ffd07a66 !important;
}

.has-background-yellow-300-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-40-mobile {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-40-tablet {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-40-tablet-only {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-40-touch {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-40-desktop {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-40-desktop-only {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-40-until-widescreen {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-40-widescreen {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-40-widescreen-only {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-40-until-fullhd {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-40-fullhd {
    background-color: #ffd07a66;
  }
}

.has-background-yellow-300-50 {
  background-color: #ffd07a80 !important;
}

.has-background-yellow-300-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-50-mobile {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-50-tablet {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-50-tablet-only {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-50-touch {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-50-desktop {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-50-desktop-only {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-50-until-widescreen {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-50-widescreen {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-50-widescreen-only {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-50-until-fullhd {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-50-fullhd {
    background-color: #ffd07a80;
  }
}

.has-background-yellow-300-60 {
  background-color: #ffd07a99 !important;
}

.has-background-yellow-300-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-60-mobile {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-60-tablet {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-60-tablet-only {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-60-touch {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-60-desktop {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-60-desktop-only {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-60-until-widescreen {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-60-widescreen {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-60-widescreen-only {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-60-until-fullhd {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-60-fullhd {
    background-color: #ffd07a99;
  }
}

.has-background-yellow-300-70 {
  background-color: #ffd07ab3 !important;
}

.has-background-yellow-300-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-70-mobile {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-70-tablet {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-70-tablet-only {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-70-touch {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-70-desktop {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-70-desktop-only {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-70-until-widescreen {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-70-widescreen {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-70-widescreen-only {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-70-until-fullhd {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-70-fullhd {
    background-color: #ffd07ab3;
  }
}

.has-background-yellow-300-80 {
  background-color: #ffd07acc !important;
}

.has-background-yellow-300-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-80-mobile {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-80-tablet {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-80-tablet-only {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-80-touch {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-80-desktop {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-80-desktop-only {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-80-until-widescreen {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-80-widescreen {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-80-widescreen-only {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-80-until-fullhd {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-80-fullhd {
    background-color: #ffd07acc;
  }
}

.has-background-yellow-300-90 {
  background-color: #ffd07ae6 !important;
}

.has-background-yellow-300-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-90-mobile {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-90-tablet {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-90-tablet-only {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-90-touch {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-90-desktop {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-90-desktop-only {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-90-until-widescreen {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-90-widescreen {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-90-widescreen-only {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-90-until-fullhd {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-90-fullhd {
    background-color: #ffd07ae6;
  }
}

.has-background-yellow-300-100 {
  background-color: #ffd07a !important;
}

.has-background-yellow-300-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-300-100-mobile {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-300-100-tablet {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-300-100-tablet-only {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-300-100-touch {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-300-100-desktop {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-300-100-desktop-only {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-300-100-until-widescreen {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-300-100-widescreen {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-300-100-widescreen-only {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-300-100-until-fullhd {
    background-color: #ffd07a;
  }
}

.has-background-yellow-300-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-300-100-fullhd {
    background-color: #ffd07a;
  }
}

.has-text-yellow-400 {
  color: #ffb938 !important;
}

.has-border-yellow-400-1 {
  border: 1px solid #ffb938;
}

.has-inset-border-yellow-400-1 {
  box-shadow: inset 0 0 0 1px #ffb938;
}

.has-border-yellow-400-2 {
  border: 2px solid #ffb938;
}

.has-inset-border-yellow-400-2 {
  box-shadow: inset 0 0 0 2px #ffb938;
}

.has-border-yellow-400-3 {
  border: 3px solid #ffb938;
}

.has-inset-border-yellow-400-3 {
  box-shadow: inset 0 0 0 3px #ffb938;
}

.has-text-yellow-400-10 {
  color: #ffb9381a !important;
}

.has-text-yellow-400-20 {
  color: #ffb93833 !important;
}

.has-text-yellow-400-30 {
  color: #ffb9384d !important;
}

.has-text-yellow-400-40 {
  color: #ffb93866 !important;
}

.has-text-yellow-400-50 {
  color: #ffb93880 !important;
}

.has-text-yellow-400-60 {
  color: #ffb93899 !important;
}

.has-text-yellow-400-70 {
  color: #ffb938b3 !important;
}

.has-text-yellow-400-80 {
  color: #ffb938cc !important;
}

.has-text-yellow-400-90 {
  color: #ffb938e6 !important;
}

.has-text-yellow-400-100 {
  color: #ffb938 !important;
}

.has-background-yellow-400 {
  background-color: #ffb938 !important;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-mobile {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-tablet {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-tablet-only {
    background-color: #ffb938 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-touch {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-desktop {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-desktop-only {
    background-color: #ffb938 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-until-widescreen {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-widescreen {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-widescreen-only {
    background-color: #ffb938 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-until-fullhd {
    background-color: #ffb938 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-fullhd {
    background-color: #ffb938 !important;
  }
}

.has-background-yellow-400-10 {
  background-color: #ffb9381a !important;
}

.has-background-yellow-400-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-10-mobile {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-10-tablet {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-10-tablet-only {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-10-touch {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-10-desktop {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-10-desktop-only {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-10-until-widescreen {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-10-widescreen {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-10-widescreen-only {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-10-until-fullhd {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-10-fullhd {
    background-color: #ffb9381a;
  }
}

.has-background-yellow-400-20 {
  background-color: #ffb93833 !important;
}

.has-background-yellow-400-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-20-mobile {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-20-tablet {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-20-tablet-only {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-20-touch {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-20-desktop {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-20-desktop-only {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-20-until-widescreen {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-20-widescreen {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-20-widescreen-only {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-20-until-fullhd {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-20-fullhd {
    background-color: #ffb93833;
  }
}

.has-background-yellow-400-30 {
  background-color: #ffb9384d !important;
}

.has-background-yellow-400-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-30-mobile {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-30-tablet {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-30-tablet-only {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-30-touch {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-30-desktop {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-30-desktop-only {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-30-until-widescreen {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-30-widescreen {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-30-widescreen-only {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-30-until-fullhd {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-30-fullhd {
    background-color: #ffb9384d;
  }
}

.has-background-yellow-400-40 {
  background-color: #ffb93866 !important;
}

.has-background-yellow-400-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-40-mobile {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-40-tablet {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-40-tablet-only {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-40-touch {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-40-desktop {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-40-desktop-only {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-40-until-widescreen {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-40-widescreen {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-40-widescreen-only {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-40-until-fullhd {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-40-fullhd {
    background-color: #ffb93866;
  }
}

.has-background-yellow-400-50 {
  background-color: #ffb93880 !important;
}

.has-background-yellow-400-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-50-mobile {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-50-tablet {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-50-tablet-only {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-50-touch {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-50-desktop {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-50-desktop-only {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-50-until-widescreen {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-50-widescreen {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-50-widescreen-only {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-50-until-fullhd {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-50-fullhd {
    background-color: #ffb93880;
  }
}

.has-background-yellow-400-60 {
  background-color: #ffb93899 !important;
}

.has-background-yellow-400-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-60-mobile {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-60-tablet {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-60-tablet-only {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-60-touch {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-60-desktop {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-60-desktop-only {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-60-until-widescreen {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-60-widescreen {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-60-widescreen-only {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-60-until-fullhd {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-60-fullhd {
    background-color: #ffb93899;
  }
}

.has-background-yellow-400-70 {
  background-color: #ffb938b3 !important;
}

.has-background-yellow-400-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-70-mobile {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-70-tablet {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-70-tablet-only {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-70-touch {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-70-desktop {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-70-desktop-only {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-70-until-widescreen {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-70-widescreen {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-70-widescreen-only {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-70-until-fullhd {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-70-fullhd {
    background-color: #ffb938b3;
  }
}

.has-background-yellow-400-80 {
  background-color: #ffb938cc !important;
}

.has-background-yellow-400-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-80-mobile {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-80-tablet {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-80-tablet-only {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-80-touch {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-80-desktop {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-80-desktop-only {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-80-until-widescreen {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-80-widescreen {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-80-widescreen-only {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-80-until-fullhd {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-80-fullhd {
    background-color: #ffb938cc;
  }
}

.has-background-yellow-400-90 {
  background-color: #ffb938e6 !important;
}

.has-background-yellow-400-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-90-mobile {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-90-tablet {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-90-tablet-only {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-90-touch {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-90-desktop {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-90-desktop-only {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-90-until-widescreen {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-90-widescreen {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-90-widescreen-only {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-90-until-fullhd {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-90-fullhd {
    background-color: #ffb938e6;
  }
}

.has-background-yellow-400-100 {
  background-color: #ffb938 !important;
}

.has-background-yellow-400-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-400-100-mobile {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-400-100-tablet {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-400-100-tablet-only {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-400-100-touch {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-400-100-desktop {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-400-100-desktop-only {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-400-100-until-widescreen {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-400-100-widescreen {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-400-100-widescreen-only {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-400-100-until-fullhd {
    background-color: #ffb938;
  }
}

.has-background-yellow-400-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-400-100-fullhd {
    background-color: #ffb938;
  }
}

.has-text-yellow-500 {
  color: #aa7b25 !important;
}

.has-border-yellow-500-1 {
  border: 1px solid #aa7b25;
}

.has-inset-border-yellow-500-1 {
  box-shadow: inset 0 0 0 1px #aa7b25;
}

.has-border-yellow-500-2 {
  border: 2px solid #aa7b25;
}

.has-inset-border-yellow-500-2 {
  box-shadow: inset 0 0 0 2px #aa7b25;
}

.has-border-yellow-500-3 {
  border: 3px solid #aa7b25;
}

.has-inset-border-yellow-500-3 {
  box-shadow: inset 0 0 0 3px #aa7b25;
}

.has-text-yellow-500-10 {
  color: #aa7b251a !important;
}

.has-text-yellow-500-20 {
  color: #aa7b2533 !important;
}

.has-text-yellow-500-30 {
  color: #aa7b254d !important;
}

.has-text-yellow-500-40 {
  color: #aa7b2566 !important;
}

.has-text-yellow-500-50 {
  color: #aa7b2580 !important;
}

.has-text-yellow-500-60 {
  color: #aa7b2599 !important;
}

.has-text-yellow-500-70 {
  color: #aa7b25b3 !important;
}

.has-text-yellow-500-80 {
  color: #aa7b25cc !important;
}

.has-text-yellow-500-90 {
  color: #aa7b25e6 !important;
}

.has-text-yellow-500-100 {
  color: #aa7b25 !important;
}

.has-background-yellow-500 {
  background-color: #aa7b25 !important;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-mobile {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-tablet {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-tablet-only {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-touch {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-desktop {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-desktop-only {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-until-widescreen {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-widescreen {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-widescreen-only {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-until-fullhd {
    background-color: #aa7b25 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-fullhd {
    background-color: #aa7b25 !important;
  }
}

.has-background-yellow-500-10 {
  background-color: #aa7b251a !important;
}

.has-background-yellow-500-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-10-mobile {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-10-tablet {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-10-tablet-only {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-10-touch {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-10-desktop {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-10-desktop-only {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-10-until-widescreen {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-10-widescreen {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-10-widescreen-only {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-10-until-fullhd {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-10-fullhd {
    background-color: #aa7b251a;
  }
}

.has-background-yellow-500-20 {
  background-color: #aa7b2533 !important;
}

.has-background-yellow-500-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-20-mobile {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-20-tablet {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-20-tablet-only {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-20-touch {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-20-desktop {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-20-desktop-only {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-20-until-widescreen {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-20-widescreen {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-20-widescreen-only {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-20-until-fullhd {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-20-fullhd {
    background-color: #aa7b2533;
  }
}

.has-background-yellow-500-30 {
  background-color: #aa7b254d !important;
}

.has-background-yellow-500-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-30-mobile {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-30-tablet {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-30-tablet-only {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-30-touch {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-30-desktop {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-30-desktop-only {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-30-until-widescreen {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-30-widescreen {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-30-widescreen-only {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-30-until-fullhd {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-30-fullhd {
    background-color: #aa7b254d;
  }
}

.has-background-yellow-500-40 {
  background-color: #aa7b2566 !important;
}

.has-background-yellow-500-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-40-mobile {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-40-tablet {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-40-tablet-only {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-40-touch {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-40-desktop {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-40-desktop-only {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-40-until-widescreen {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-40-widescreen {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-40-widescreen-only {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-40-until-fullhd {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-40-fullhd {
    background-color: #aa7b2566;
  }
}

.has-background-yellow-500-50 {
  background-color: #aa7b2580 !important;
}

.has-background-yellow-500-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-50-mobile {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-50-tablet {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-50-tablet-only {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-50-touch {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-50-desktop {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-50-desktop-only {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-50-until-widescreen {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-50-widescreen {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-50-widescreen-only {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-50-until-fullhd {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-50-fullhd {
    background-color: #aa7b2580;
  }
}

.has-background-yellow-500-60 {
  background-color: #aa7b2599 !important;
}

.has-background-yellow-500-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-60-mobile {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-60-tablet {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-60-tablet-only {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-60-touch {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-60-desktop {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-60-desktop-only {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-60-until-widescreen {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-60-widescreen {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-60-widescreen-only {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-60-until-fullhd {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-60-fullhd {
    background-color: #aa7b2599;
  }
}

.has-background-yellow-500-70 {
  background-color: #aa7b25b3 !important;
}

.has-background-yellow-500-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-70-mobile {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-70-tablet {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-70-tablet-only {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-70-touch {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-70-desktop {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-70-desktop-only {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-70-until-widescreen {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-70-widescreen {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-70-widescreen-only {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-70-until-fullhd {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-70-fullhd {
    background-color: #aa7b25b3;
  }
}

.has-background-yellow-500-80 {
  background-color: #aa7b25cc !important;
}

.has-background-yellow-500-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-80-mobile {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-80-tablet {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-80-tablet-only {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-80-touch {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-80-desktop {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-80-desktop-only {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-80-until-widescreen {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-80-widescreen {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-80-widescreen-only {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-80-until-fullhd {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-80-fullhd {
    background-color: #aa7b25cc;
  }
}

.has-background-yellow-500-90 {
  background-color: #aa7b25e6 !important;
}

.has-background-yellow-500-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-90-mobile {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-90-tablet {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-90-tablet-only {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-90-touch {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-90-desktop {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-90-desktop-only {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-90-until-widescreen {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-90-widescreen {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-90-widescreen-only {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-90-until-fullhd {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-90-fullhd {
    background-color: #aa7b25e6;
  }
}

.has-background-yellow-500-100 {
  background-color: #aa7b25 !important;
}

.has-background-yellow-500-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-yellow-500-100-mobile {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-yellow-500-100-tablet {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-yellow-500-100-tablet-only {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-yellow-500-100-touch {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-yellow-500-100-desktop {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-yellow-500-100-desktop-only {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-yellow-500-100-until-widescreen {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-yellow-500-100-widescreen {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-yellow-500-100-widescreen-only {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-yellow-500-100-until-fullhd {
    background-color: #aa7b25;
  }
}

.has-background-yellow-500-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-yellow-500-100-fullhd {
    background-color: #aa7b25;
  }
}

.has-text-pink {
  color: #d85568 !important;
}

.has-background-pink {
  background-color: #d85568 !important;
}

.has-text-pink-100 {
  color: #fbeef0 !important;
}

.has-border-pink-100-1 {
  border: 1px solid #fbeef0;
}

.has-inset-border-pink-100-1 {
  box-shadow: inset 0 0 0 1px #fbeef0;
}

.has-border-pink-100-2 {
  border: 2px solid #fbeef0;
}

.has-inset-border-pink-100-2 {
  box-shadow: inset 0 0 0 2px #fbeef0;
}

.has-border-pink-100-3 {
  border: 3px solid #fbeef0;
}

.has-inset-border-pink-100-3 {
  box-shadow: inset 0 0 0 3px #fbeef0;
}

.has-text-pink-100-10 {
  color: #fbeef01a !important;
}

.has-text-pink-100-20 {
  color: #fbeef033 !important;
}

.has-text-pink-100-30 {
  color: #fbeef04d !important;
}

.has-text-pink-100-40 {
  color: #fbeef066 !important;
}

.has-text-pink-100-50 {
  color: #fbeef080 !important;
}

.has-text-pink-100-60 {
  color: #fbeef099 !important;
}

.has-text-pink-100-70 {
  color: #fbeef0b3 !important;
}

.has-text-pink-100-80 {
  color: #fbeef0cc !important;
}

.has-text-pink-100-90 {
  color: #fbeef0e6 !important;
}

.has-text-pink-100-100 {
  color: #fbeef0 !important;
}

.has-background-pink-100 {
  background-color: #fbeef0 !important;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-mobile {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-pink-100-tablet {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-tablet-only {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-touch {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-desktop {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-desktop-only {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-until-widescreen {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-widescreen {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-widescreen-only {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-until-fullhd {
    background-color: #fbeef0 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-fullhd {
    background-color: #fbeef0 !important;
  }
}

.has-background-pink-100-10 {
  background-color: #fbeef01a !important;
}

.has-background-pink-100-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-10-mobile {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-10-tablet {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-10-tablet-only {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-10-touch {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-10-desktop {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-10-desktop-only {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-10-until-widescreen {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-10-widescreen {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-10-widescreen-only {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-10-until-fullhd {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-10-fullhd {
    background-color: #fbeef01a;
  }
}

.has-background-pink-100-20 {
  background-color: #fbeef033 !important;
}

.has-background-pink-100-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-20-mobile {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-20-tablet {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-20-tablet-only {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-20-touch {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-20-desktop {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-20-desktop-only {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-20-until-widescreen {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-20-widescreen {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-20-widescreen-only {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-20-until-fullhd {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-20-fullhd {
    background-color: #fbeef033;
  }
}

.has-background-pink-100-30 {
  background-color: #fbeef04d !important;
}

.has-background-pink-100-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-30-mobile {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-30-tablet {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-30-tablet-only {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-30-touch {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-30-desktop {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-30-desktop-only {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-30-until-widescreen {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-30-widescreen {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-30-widescreen-only {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-30-until-fullhd {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-30-fullhd {
    background-color: #fbeef04d;
  }
}

.has-background-pink-100-40 {
  background-color: #fbeef066 !important;
}

.has-background-pink-100-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-40-mobile {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-40-tablet {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-40-tablet-only {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-40-touch {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-40-desktop {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-40-desktop-only {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-40-until-widescreen {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-40-widescreen {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-40-widescreen-only {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-40-until-fullhd {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-40-fullhd {
    background-color: #fbeef066;
  }
}

.has-background-pink-100-50 {
  background-color: #fbeef080 !important;
}

.has-background-pink-100-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-50-mobile {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-50-tablet {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-50-tablet-only {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-50-touch {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-50-desktop {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-50-desktop-only {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-50-until-widescreen {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-50-widescreen {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-50-widescreen-only {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-50-until-fullhd {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-50-fullhd {
    background-color: #fbeef080;
  }
}

.has-background-pink-100-60 {
  background-color: #fbeef099 !important;
}

.has-background-pink-100-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-60-mobile {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-60-tablet {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-60-tablet-only {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-60-touch {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-60-desktop {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-60-desktop-only {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-60-until-widescreen {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-60-widescreen {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-60-widescreen-only {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-60-until-fullhd {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-60-fullhd {
    background-color: #fbeef099;
  }
}

.has-background-pink-100-70 {
  background-color: #fbeef0b3 !important;
}

.has-background-pink-100-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-70-mobile {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-70-tablet {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-70-tablet-only {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-70-touch {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-70-desktop {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-70-desktop-only {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-70-until-widescreen {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-70-widescreen {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-70-widescreen-only {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-70-until-fullhd {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-70-fullhd {
    background-color: #fbeef0b3;
  }
}

.has-background-pink-100-80 {
  background-color: #fbeef0cc !important;
}

.has-background-pink-100-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-80-mobile {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-80-tablet {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-80-tablet-only {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-80-touch {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-80-desktop {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-80-desktop-only {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-80-until-widescreen {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-80-widescreen {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-80-widescreen-only {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-80-until-fullhd {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-80-fullhd {
    background-color: #fbeef0cc;
  }
}

.has-background-pink-100-90 {
  background-color: #fbeef0e6 !important;
}

.has-background-pink-100-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-90-mobile {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-90-tablet {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-90-tablet-only {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-90-touch {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-90-desktop {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-90-desktop-only {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-90-until-widescreen {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-90-widescreen {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-90-widescreen-only {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-90-until-fullhd {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-90-fullhd {
    background-color: #fbeef0e6;
  }
}

.has-background-pink-100-100 {
  background-color: #fbeef0 !important;
}

.has-background-pink-100-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-100-100-mobile {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-100-100-tablet {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-100-100-tablet-only {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-100-100-touch {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-100-100-desktop {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-100-100-desktop-only {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-100-100-until-widescreen {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-100-100-widescreen {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-100-100-widescreen-only {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-100-100-until-fullhd {
    background-color: #fbeef0;
  }
}

.has-background-pink-100-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-100-100-fullhd {
    background-color: #fbeef0;
  }
}

.has-text-pink-200 {
  color: #f2c6cd !important;
}

.has-border-pink-200-1 {
  border: 1px solid #f2c6cd;
}

.has-inset-border-pink-200-1 {
  box-shadow: inset 0 0 0 1px #f2c6cd;
}

.has-border-pink-200-2 {
  border: 2px solid #f2c6cd;
}

.has-inset-border-pink-200-2 {
  box-shadow: inset 0 0 0 2px #f2c6cd;
}

.has-border-pink-200-3 {
  border: 3px solid #f2c6cd;
}

.has-inset-border-pink-200-3 {
  box-shadow: inset 0 0 0 3px #f2c6cd;
}

.has-text-pink-200-10 {
  color: #f2c6cd1a !important;
}

.has-text-pink-200-20 {
  color: #f2c6cd33 !important;
}

.has-text-pink-200-30 {
  color: #f2c6cd4d !important;
}

.has-text-pink-200-40 {
  color: #f2c6cd66 !important;
}

.has-text-pink-200-50 {
  color: #f2c6cd80 !important;
}

.has-text-pink-200-60 {
  color: #f2c6cd99 !important;
}

.has-text-pink-200-70 {
  color: #f2c6cdb3 !important;
}

.has-text-pink-200-80 {
  color: #f2c6cdcc !important;
}

.has-text-pink-200-90 {
  color: #f2c6cde6 !important;
}

.has-text-pink-200-100 {
  color: #f2c6cd !important;
}

.has-background-pink-200 {
  background-color: #f2c6cd !important;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-mobile {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-pink-200-tablet {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-tablet-only {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-touch {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-desktop {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-desktop-only {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-until-widescreen {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-widescreen {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-widescreen-only {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-until-fullhd {
    background-color: #f2c6cd !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-fullhd {
    background-color: #f2c6cd !important;
  }
}

.has-background-pink-200-10 {
  background-color: #f2c6cd1a !important;
}

.has-background-pink-200-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-10-mobile {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-10-tablet {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-10-tablet-only {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-10-touch {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-10-desktop {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-10-desktop-only {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-10-until-widescreen {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-10-widescreen {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-10-widescreen-only {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-10-until-fullhd {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-10-fullhd {
    background-color: #f2c6cd1a;
  }
}

.has-background-pink-200-20 {
  background-color: #f2c6cd33 !important;
}

.has-background-pink-200-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-20-mobile {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-20-tablet {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-20-tablet-only {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-20-touch {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-20-desktop {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-20-desktop-only {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-20-until-widescreen {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-20-widescreen {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-20-widescreen-only {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-20-until-fullhd {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-20-fullhd {
    background-color: #f2c6cd33;
  }
}

.has-background-pink-200-30 {
  background-color: #f2c6cd4d !important;
}

.has-background-pink-200-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-30-mobile {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-30-tablet {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-30-tablet-only {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-30-touch {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-30-desktop {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-30-desktop-only {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-30-until-widescreen {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-30-widescreen {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-30-widescreen-only {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-30-until-fullhd {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-30-fullhd {
    background-color: #f2c6cd4d;
  }
}

.has-background-pink-200-40 {
  background-color: #f2c6cd66 !important;
}

.has-background-pink-200-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-40-mobile {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-40-tablet {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-40-tablet-only {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-40-touch {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-40-desktop {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-40-desktop-only {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-40-until-widescreen {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-40-widescreen {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-40-widescreen-only {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-40-until-fullhd {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-40-fullhd {
    background-color: #f2c6cd66;
  }
}

.has-background-pink-200-50 {
  background-color: #f2c6cd80 !important;
}

.has-background-pink-200-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-50-mobile {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-50-tablet {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-50-tablet-only {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-50-touch {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-50-desktop {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-50-desktop-only {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-50-until-widescreen {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-50-widescreen {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-50-widescreen-only {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-50-until-fullhd {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-50-fullhd {
    background-color: #f2c6cd80;
  }
}

.has-background-pink-200-60 {
  background-color: #f2c6cd99 !important;
}

.has-background-pink-200-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-60-mobile {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-60-tablet {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-60-tablet-only {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-60-touch {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-60-desktop {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-60-desktop-only {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-60-until-widescreen {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-60-widescreen {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-60-widescreen-only {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-60-until-fullhd {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-60-fullhd {
    background-color: #f2c6cd99;
  }
}

.has-background-pink-200-70 {
  background-color: #f2c6cdb3 !important;
}

.has-background-pink-200-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-70-mobile {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-70-tablet {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-70-tablet-only {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-70-touch {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-70-desktop {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-70-desktop-only {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-70-until-widescreen {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-70-widescreen {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-70-widescreen-only {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-70-until-fullhd {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-70-fullhd {
    background-color: #f2c6cdb3;
  }
}

.has-background-pink-200-80 {
  background-color: #f2c6cdcc !important;
}

.has-background-pink-200-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-80-mobile {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-80-tablet {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-80-tablet-only {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-80-touch {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-80-desktop {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-80-desktop-only {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-80-until-widescreen {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-80-widescreen {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-80-widescreen-only {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-80-until-fullhd {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-80-fullhd {
    background-color: #f2c6cdcc;
  }
}

.has-background-pink-200-90 {
  background-color: #f2c6cde6 !important;
}

.has-background-pink-200-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-90-mobile {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-90-tablet {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-90-tablet-only {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-90-touch {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-90-desktop {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-90-desktop-only {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-90-until-widescreen {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-90-widescreen {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-90-widescreen-only {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-90-until-fullhd {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-90-fullhd {
    background-color: #f2c6cde6;
  }
}

.has-background-pink-200-100 {
  background-color: #f2c6cd !important;
}

.has-background-pink-200-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-200-100-mobile {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-200-100-tablet {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-200-100-tablet-only {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-200-100-touch {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-200-100-desktop {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-200-100-desktop-only {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-200-100-until-widescreen {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-200-100-widescreen {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-200-100-widescreen-only {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-200-100-until-fullhd {
    background-color: #f2c6cd;
  }
}

.has-background-pink-200-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-200-100-fullhd {
    background-color: #f2c6cd;
  }
}

.has-text-pink-300 {
  color: #e58e9a !important;
}

.has-border-pink-300-1 {
  border: 1px solid #e58e9a;
}

.has-inset-border-pink-300-1 {
  box-shadow: inset 0 0 0 1px #e58e9a;
}

.has-border-pink-300-2 {
  border: 2px solid #e58e9a;
}

.has-inset-border-pink-300-2 {
  box-shadow: inset 0 0 0 2px #e58e9a;
}

.has-border-pink-300-3 {
  border: 3px solid #e58e9a;
}

.has-inset-border-pink-300-3 {
  box-shadow: inset 0 0 0 3px #e58e9a;
}

.has-text-pink-300-10 {
  color: #e58e9a1a !important;
}

.has-text-pink-300-20 {
  color: #e58e9a33 !important;
}

.has-text-pink-300-30 {
  color: #e58e9a4d !important;
}

.has-text-pink-300-40 {
  color: #e58e9a66 !important;
}

.has-text-pink-300-50 {
  color: #e58e9a80 !important;
}

.has-text-pink-300-60 {
  color: #e58e9a99 !important;
}

.has-text-pink-300-70 {
  color: #e58e9ab3 !important;
}

.has-text-pink-300-80 {
  color: #e58e9acc !important;
}

.has-text-pink-300-90 {
  color: #e58e9ae6 !important;
}

.has-text-pink-300-100 {
  color: #e58e9a !important;
}

.has-background-pink-300 {
  background-color: #e58e9a !important;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-mobile {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-pink-300-tablet {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-tablet-only {
    background-color: #e58e9a !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-touch {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-desktop {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-desktop-only {
    background-color: #e58e9a !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-until-widescreen {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-widescreen {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-widescreen-only {
    background-color: #e58e9a !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-until-fullhd {
    background-color: #e58e9a !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-fullhd {
    background-color: #e58e9a !important;
  }
}

.has-background-pink-300-10 {
  background-color: #e58e9a1a !important;
}

.has-background-pink-300-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-10-mobile {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-10-tablet {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-10-tablet-only {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-10-touch {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-10-desktop {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-10-desktop-only {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-10-until-widescreen {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-10-widescreen {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-10-widescreen-only {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-10-until-fullhd {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-10-fullhd {
    background-color: #e58e9a1a;
  }
}

.has-background-pink-300-20 {
  background-color: #e58e9a33 !important;
}

.has-background-pink-300-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-20-mobile {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-20-tablet {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-20-tablet-only {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-20-touch {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-20-desktop {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-20-desktop-only {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-20-until-widescreen {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-20-widescreen {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-20-widescreen-only {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-20-until-fullhd {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-20-fullhd {
    background-color: #e58e9a33;
  }
}

.has-background-pink-300-30 {
  background-color: #e58e9a4d !important;
}

.has-background-pink-300-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-30-mobile {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-30-tablet {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-30-tablet-only {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-30-touch {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-30-desktop {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-30-desktop-only {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-30-until-widescreen {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-30-widescreen {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-30-widescreen-only {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-30-until-fullhd {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-30-fullhd {
    background-color: #e58e9a4d;
  }
}

.has-background-pink-300-40 {
  background-color: #e58e9a66 !important;
}

.has-background-pink-300-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-40-mobile {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-40-tablet {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-40-tablet-only {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-40-touch {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-40-desktop {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-40-desktop-only {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-40-until-widescreen {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-40-widescreen {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-40-widescreen-only {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-40-until-fullhd {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-40-fullhd {
    background-color: #e58e9a66;
  }
}

.has-background-pink-300-50 {
  background-color: #e58e9a80 !important;
}

.has-background-pink-300-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-50-mobile {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-50-tablet {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-50-tablet-only {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-50-touch {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-50-desktop {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-50-desktop-only {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-50-until-widescreen {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-50-widescreen {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-50-widescreen-only {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-50-until-fullhd {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-50-fullhd {
    background-color: #e58e9a80;
  }
}

.has-background-pink-300-60 {
  background-color: #e58e9a99 !important;
}

.has-background-pink-300-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-60-mobile {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-60-tablet {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-60-tablet-only {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-60-touch {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-60-desktop {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-60-desktop-only {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-60-until-widescreen {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-60-widescreen {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-60-widescreen-only {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-60-until-fullhd {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-60-fullhd {
    background-color: #e58e9a99;
  }
}

.has-background-pink-300-70 {
  background-color: #e58e9ab3 !important;
}

.has-background-pink-300-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-70-mobile {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-70-tablet {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-70-tablet-only {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-70-touch {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-70-desktop {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-70-desktop-only {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-70-until-widescreen {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-70-widescreen {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-70-widescreen-only {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-70-until-fullhd {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-70-fullhd {
    background-color: #e58e9ab3;
  }
}

.has-background-pink-300-80 {
  background-color: #e58e9acc !important;
}

.has-background-pink-300-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-80-mobile {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-80-tablet {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-80-tablet-only {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-80-touch {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-80-desktop {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-80-desktop-only {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-80-until-widescreen {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-80-widescreen {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-80-widescreen-only {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-80-until-fullhd {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-80-fullhd {
    background-color: #e58e9acc;
  }
}

.has-background-pink-300-90 {
  background-color: #e58e9ae6 !important;
}

.has-background-pink-300-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-90-mobile {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-90-tablet {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-90-tablet-only {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-90-touch {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-90-desktop {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-90-desktop-only {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-90-until-widescreen {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-90-widescreen {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-90-widescreen-only {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-90-until-fullhd {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-90-fullhd {
    background-color: #e58e9ae6;
  }
}

.has-background-pink-300-100 {
  background-color: #e58e9a !important;
}

.has-background-pink-300-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-300-100-mobile {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-300-100-tablet {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-300-100-tablet-only {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-300-100-touch {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-300-100-desktop {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-300-100-desktop-only {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-300-100-until-widescreen {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-300-100-widescreen {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-300-100-widescreen-only {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-300-100-until-fullhd {
    background-color: #e58e9a;
  }
}

.has-background-pink-300-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-300-100-fullhd {
    background-color: #e58e9a;
  }
}

.has-text-pink-400 {
  color: #d85568 !important;
}

.has-border-pink-400-1 {
  border: 1px solid #d85568;
}

.has-inset-border-pink-400-1 {
  box-shadow: inset 0 0 0 1px #d85568;
}

.has-border-pink-400-2 {
  border: 2px solid #d85568;
}

.has-inset-border-pink-400-2 {
  box-shadow: inset 0 0 0 2px #d85568;
}

.has-border-pink-400-3 {
  border: 3px solid #d85568;
}

.has-inset-border-pink-400-3 {
  box-shadow: inset 0 0 0 3px #d85568;
}

.has-text-pink-400-10 {
  color: #d855681a !important;
}

.has-text-pink-400-20 {
  color: #d8556833 !important;
}

.has-text-pink-400-30 {
  color: #d855684d !important;
}

.has-text-pink-400-40 {
  color: #d8556866 !important;
}

.has-text-pink-400-50 {
  color: #d8556880 !important;
}

.has-text-pink-400-60 {
  color: #d8556899 !important;
}

.has-text-pink-400-70 {
  color: #d85568b3 !important;
}

.has-text-pink-400-80 {
  color: #d85568cc !important;
}

.has-text-pink-400-90 {
  color: #d85568e6 !important;
}

.has-text-pink-400-100 {
  color: #d85568 !important;
}

.has-background-pink-400 {
  background-color: #d85568 !important;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-mobile {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-pink-400-tablet {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-tablet-only {
    background-color: #d85568 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-touch {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-desktop {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-desktop-only {
    background-color: #d85568 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-until-widescreen {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-widescreen {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-widescreen-only {
    background-color: #d85568 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-until-fullhd {
    background-color: #d85568 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-fullhd {
    background-color: #d85568 !important;
  }
}

.has-background-pink-400-10 {
  background-color: #d855681a !important;
}

.has-background-pink-400-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-10-mobile {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-10-tablet {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-10-tablet-only {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-10-touch {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-10-desktop {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-10-desktop-only {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-10-until-widescreen {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-10-widescreen {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-10-widescreen-only {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-10-until-fullhd {
    background-color: #d855681a;
  }
}

.has-background-pink-400-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-10-fullhd {
    background-color: #d855681a;
  }
}

.has-background-pink-400-20 {
  background-color: #d8556833 !important;
}

.has-background-pink-400-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-20-mobile {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-20-tablet {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-20-tablet-only {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-20-touch {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-20-desktop {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-20-desktop-only {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-20-until-widescreen {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-20-widescreen {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-20-widescreen-only {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-20-until-fullhd {
    background-color: #d8556833;
  }
}

.has-background-pink-400-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-20-fullhd {
    background-color: #d8556833;
  }
}

.has-background-pink-400-30 {
  background-color: #d855684d !important;
}

.has-background-pink-400-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-30-mobile {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-30-tablet {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-30-tablet-only {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-30-touch {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-30-desktop {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-30-desktop-only {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-30-until-widescreen {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-30-widescreen {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-30-widescreen-only {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-30-until-fullhd {
    background-color: #d855684d;
  }
}

.has-background-pink-400-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-30-fullhd {
    background-color: #d855684d;
  }
}

.has-background-pink-400-40 {
  background-color: #d8556866 !important;
}

.has-background-pink-400-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-40-mobile {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-40-tablet {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-40-tablet-only {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-40-touch {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-40-desktop {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-40-desktop-only {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-40-until-widescreen {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-40-widescreen {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-40-widescreen-only {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-40-until-fullhd {
    background-color: #d8556866;
  }
}

.has-background-pink-400-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-40-fullhd {
    background-color: #d8556866;
  }
}

.has-background-pink-400-50 {
  background-color: #d8556880 !important;
}

.has-background-pink-400-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-50-mobile {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-50-tablet {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-50-tablet-only {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-50-touch {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-50-desktop {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-50-desktop-only {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-50-until-widescreen {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-50-widescreen {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-50-widescreen-only {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-50-until-fullhd {
    background-color: #d8556880;
  }
}

.has-background-pink-400-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-50-fullhd {
    background-color: #d8556880;
  }
}

.has-background-pink-400-60 {
  background-color: #d8556899 !important;
}

.has-background-pink-400-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-60-mobile {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-60-tablet {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-60-tablet-only {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-60-touch {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-60-desktop {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-60-desktop-only {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-60-until-widescreen {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-60-widescreen {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-60-widescreen-only {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-60-until-fullhd {
    background-color: #d8556899;
  }
}

.has-background-pink-400-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-60-fullhd {
    background-color: #d8556899;
  }
}

.has-background-pink-400-70 {
  background-color: #d85568b3 !important;
}

.has-background-pink-400-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-70-mobile {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-70-tablet {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-70-tablet-only {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-70-touch {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-70-desktop {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-70-desktop-only {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-70-until-widescreen {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-70-widescreen {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-70-widescreen-only {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-70-until-fullhd {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-70-fullhd {
    background-color: #d85568b3;
  }
}

.has-background-pink-400-80 {
  background-color: #d85568cc !important;
}

.has-background-pink-400-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-80-mobile {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-80-tablet {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-80-tablet-only {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-80-touch {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-80-desktop {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-80-desktop-only {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-80-until-widescreen {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-80-widescreen {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-80-widescreen-only {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-80-until-fullhd {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-80-fullhd {
    background-color: #d85568cc;
  }
}

.has-background-pink-400-90 {
  background-color: #d85568e6 !important;
}

.has-background-pink-400-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-90-mobile {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-90-tablet {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-90-tablet-only {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-90-touch {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-90-desktop {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-90-desktop-only {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-90-until-widescreen {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-90-widescreen {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-90-widescreen-only {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-90-until-fullhd {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-90-fullhd {
    background-color: #d85568e6;
  }
}

.has-background-pink-400-100 {
  background-color: #d85568 !important;
}

.has-background-pink-400-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-400-100-mobile {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-400-100-tablet {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-400-100-tablet-only {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-400-100-touch {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-400-100-desktop {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-400-100-desktop-only {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-400-100-until-widescreen {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-400-100-widescreen {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-400-100-widescreen-only {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-400-100-until-fullhd {
    background-color: #d85568;
  }
}

.has-background-pink-400-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-400-100-fullhd {
    background-color: #d85568;
  }
}

.has-text-pink-500 {
  color: #481c23 !important;
}

.has-border-pink-500-1 {
  border: 1px solid #481c23;
}

.has-inset-border-pink-500-1 {
  box-shadow: inset 0 0 0 1px #481c23;
}

.has-border-pink-500-2 {
  border: 2px solid #481c23;
}

.has-inset-border-pink-500-2 {
  box-shadow: inset 0 0 0 2px #481c23;
}

.has-border-pink-500-3 {
  border: 3px solid #481c23;
}

.has-inset-border-pink-500-3 {
  box-shadow: inset 0 0 0 3px #481c23;
}

.has-text-pink-500-10 {
  color: #481c231a !important;
}

.has-text-pink-500-20 {
  color: #481c2333 !important;
}

.has-text-pink-500-30 {
  color: #481c234d !important;
}

.has-text-pink-500-40 {
  color: #481c2366 !important;
}

.has-text-pink-500-50 {
  color: #481c2380 !important;
}

.has-text-pink-500-60 {
  color: #481c2399 !important;
}

.has-text-pink-500-70 {
  color: #481c23b3 !important;
}

.has-text-pink-500-80 {
  color: #481c23cc !important;
}

.has-text-pink-500-90 {
  color: #481c23e6 !important;
}

.has-text-pink-500-100 {
  color: #481c23 !important;
}

.has-background-pink-500 {
  background-color: #481c23 !important;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-mobile {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-pink-500-tablet {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-tablet-only {
    background-color: #481c23 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-touch {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-desktop {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-desktop-only {
    background-color: #481c23 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-until-widescreen {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-widescreen {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-widescreen-only {
    background-color: #481c23 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-until-fullhd {
    background-color: #481c23 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-fullhd {
    background-color: #481c23 !important;
  }
}

.has-background-pink-500-10 {
  background-color: #481c231a !important;
}

.has-background-pink-500-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-10-mobile {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-10-tablet {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-10-tablet-only {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-10-touch {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-10-desktop {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-10-desktop-only {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-10-until-widescreen {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-10-widescreen {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-10-widescreen-only {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-10-until-fullhd {
    background-color: #481c231a;
  }
}

.has-background-pink-500-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-10-fullhd {
    background-color: #481c231a;
  }
}

.has-background-pink-500-20 {
  background-color: #481c2333 !important;
}

.has-background-pink-500-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-20-mobile {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-20-tablet {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-20-tablet-only {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-20-touch {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-20-desktop {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-20-desktop-only {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-20-until-widescreen {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-20-widescreen {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-20-widescreen-only {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-20-until-fullhd {
    background-color: #481c2333;
  }
}

.has-background-pink-500-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-20-fullhd {
    background-color: #481c2333;
  }
}

.has-background-pink-500-30 {
  background-color: #481c234d !important;
}

.has-background-pink-500-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-30-mobile {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-30-tablet {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-30-tablet-only {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-30-touch {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-30-desktop {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-30-desktop-only {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-30-until-widescreen {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-30-widescreen {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-30-widescreen-only {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-30-until-fullhd {
    background-color: #481c234d;
  }
}

.has-background-pink-500-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-30-fullhd {
    background-color: #481c234d;
  }
}

.has-background-pink-500-40 {
  background-color: #481c2366 !important;
}

.has-background-pink-500-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-40-mobile {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-40-tablet {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-40-tablet-only {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-40-touch {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-40-desktop {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-40-desktop-only {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-40-until-widescreen {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-40-widescreen {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-40-widescreen-only {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-40-until-fullhd {
    background-color: #481c2366;
  }
}

.has-background-pink-500-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-40-fullhd {
    background-color: #481c2366;
  }
}

.has-background-pink-500-50 {
  background-color: #481c2380 !important;
}

.has-background-pink-500-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-50-mobile {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-50-tablet {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-50-tablet-only {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-50-touch {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-50-desktop {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-50-desktop-only {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-50-until-widescreen {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-50-widescreen {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-50-widescreen-only {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-50-until-fullhd {
    background-color: #481c2380;
  }
}

.has-background-pink-500-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-50-fullhd {
    background-color: #481c2380;
  }
}

.has-background-pink-500-60 {
  background-color: #481c2399 !important;
}

.has-background-pink-500-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-60-mobile {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-60-tablet {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-60-tablet-only {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-60-touch {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-60-desktop {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-60-desktop-only {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-60-until-widescreen {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-60-widescreen {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-60-widescreen-only {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-60-until-fullhd {
    background-color: #481c2399;
  }
}

.has-background-pink-500-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-60-fullhd {
    background-color: #481c2399;
  }
}

.has-background-pink-500-70 {
  background-color: #481c23b3 !important;
}

.has-background-pink-500-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-70-mobile {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-70-tablet {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-70-tablet-only {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-70-touch {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-70-desktop {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-70-desktop-only {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-70-until-widescreen {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-70-widescreen {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-70-widescreen-only {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-70-until-fullhd {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-70-fullhd {
    background-color: #481c23b3;
  }
}

.has-background-pink-500-80 {
  background-color: #481c23cc !important;
}

.has-background-pink-500-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-80-mobile {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-80-tablet {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-80-tablet-only {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-80-touch {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-80-desktop {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-80-desktop-only {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-80-until-widescreen {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-80-widescreen {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-80-widescreen-only {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-80-until-fullhd {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-80-fullhd {
    background-color: #481c23cc;
  }
}

.has-background-pink-500-90 {
  background-color: #481c23e6 !important;
}

.has-background-pink-500-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-90-mobile {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-90-tablet {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-90-tablet-only {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-90-touch {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-90-desktop {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-90-desktop-only {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-90-until-widescreen {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-90-widescreen {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-90-widescreen-only {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-90-until-fullhd {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-90-fullhd {
    background-color: #481c23e6;
  }
}

.has-background-pink-500-100 {
  background-color: #481c23 !important;
}

.has-background-pink-500-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-pink-500-100-mobile {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-pink-500-100-tablet {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-pink-500-100-tablet-only {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-pink-500-100-touch {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-pink-500-100-desktop {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-pink-500-100-desktop-only {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-pink-500-100-until-widescreen {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-pink-500-100-widescreen {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-pink-500-100-widescreen-only {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-pink-500-100-until-fullhd {
    background-color: #481c23;
  }
}

.has-background-pink-500-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-pink-500-100-fullhd {
    background-color: #481c23;
  }
}

.has-text-grey {
  color: #616161 !important;
}

.has-background-grey {
  background-color: #616161 !important;
}

.has-text-grey-100 {
  color: #fff !important;
}

.has-border-grey-100-1 {
  border: 1px solid #fff;
}

.has-inset-border-grey-100-1 {
  box-shadow: inset 0 0 0 1px #fff;
}

.has-border-grey-100-2 {
  border: 2px solid #fff;
}

.has-inset-border-grey-100-2 {
  box-shadow: inset 0 0 0 2px #fff;
}

.has-border-grey-100-3 {
  border: 3px solid #fff;
}

.has-inset-border-grey-100-3 {
  box-shadow: inset 0 0 0 3px #fff;
}

.has-text-grey-100-10 {
  color: #ffffff1a !important;
}

.has-text-grey-100-20 {
  color: #fff3 !important;
}

.has-text-grey-100-30 {
  color: #ffffff4d !important;
}

.has-text-grey-100-40 {
  color: #fff6 !important;
}

.has-text-grey-100-50 {
  color: #ffffff80 !important;
}

.has-text-grey-100-60 {
  color: #fff9 !important;
}

.has-text-grey-100-70 {
  color: #ffffffb3 !important;
}

.has-text-grey-100-80 {
  color: #fffc !important;
}

.has-text-grey-100-90 {
  color: #ffffffe6 !important;
}

.has-text-grey-100-100 {
  color: #fff !important;
}

.has-background-grey-100 {
  background-color: #fff !important;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-mobile {
    background-color: #fff !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-grey-100-tablet {
    background-color: #fff !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-tablet-only {
    background-color: #fff !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-touch {
    background-color: #fff !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-desktop {
    background-color: #fff !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-desktop-only {
    background-color: #fff !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-until-widescreen {
    background-color: #fff !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-widescreen {
    background-color: #fff !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-widescreen-only {
    background-color: #fff !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-until-fullhd {
    background-color: #fff !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-fullhd {
    background-color: #fff !important;
  }
}

.has-background-grey-100-10 {
  background-color: #ffffff1a !important;
}

.has-background-grey-100-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-10-mobile {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-10-tablet {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-10-tablet-only {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-10-touch {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-10-desktop {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-10-desktop-only {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-10-until-widescreen {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-10-widescreen {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-10-widescreen-only {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-10-until-fullhd {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-10-fullhd {
    background-color: #ffffff1a;
  }
}

.has-background-grey-100-20 {
  background-color: #fff3 !important;
}

.has-background-grey-100-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-20-mobile {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-20-tablet {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-20-tablet-only {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-20-touch {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-20-desktop {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-20-desktop-only {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-20-until-widescreen {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-20-widescreen {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-20-widescreen-only {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-20-until-fullhd {
    background-color: #fff3;
  }
}

.has-background-grey-100-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-20-fullhd {
    background-color: #fff3;
  }
}

.has-background-grey-100-30 {
  background-color: #ffffff4d !important;
}

.has-background-grey-100-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-30-mobile {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-30-tablet {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-30-tablet-only {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-30-touch {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-30-desktop {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-30-desktop-only {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-30-until-widescreen {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-30-widescreen {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-30-widescreen-only {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-30-until-fullhd {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-30-fullhd {
    background-color: #ffffff4d;
  }
}

.has-background-grey-100-40 {
  background-color: #fff6 !important;
}

.has-background-grey-100-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-40-mobile {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-40-tablet {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-40-tablet-only {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-40-touch {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-40-desktop {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-40-desktop-only {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-40-until-widescreen {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-40-widescreen {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-40-widescreen-only {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-40-until-fullhd {
    background-color: #fff6;
  }
}

.has-background-grey-100-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-40-fullhd {
    background-color: #fff6;
  }
}

.has-background-grey-100-50 {
  background-color: #ffffff80 !important;
}

.has-background-grey-100-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-50-mobile {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-50-tablet {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-50-tablet-only {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-50-touch {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-50-desktop {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-50-desktop-only {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-50-until-widescreen {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-50-widescreen {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-50-widescreen-only {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-50-until-fullhd {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-50-fullhd {
    background-color: #ffffff80;
  }
}

.has-background-grey-100-60 {
  background-color: #fff9 !important;
}

.has-background-grey-100-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-60-mobile {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-60-tablet {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-60-tablet-only {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-60-touch {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-60-desktop {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-60-desktop-only {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-60-until-widescreen {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-60-widescreen {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-60-widescreen-only {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-60-until-fullhd {
    background-color: #fff9;
  }
}

.has-background-grey-100-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-60-fullhd {
    background-color: #fff9;
  }
}

.has-background-grey-100-70 {
  background-color: #ffffffb3 !important;
}

.has-background-grey-100-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-70-mobile {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-70-tablet {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-70-tablet-only {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-70-touch {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-70-desktop {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-70-desktop-only {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-70-until-widescreen {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-70-widescreen {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-70-widescreen-only {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-70-until-fullhd {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-70-fullhd {
    background-color: #ffffffb3;
  }
}

.has-background-grey-100-80 {
  background-color: #fffc !important;
}

.has-background-grey-100-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-80-mobile {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-80-tablet {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-80-tablet-only {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-80-touch {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-80-desktop {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-80-desktop-only {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-80-until-widescreen {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-80-widescreen {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-80-widescreen-only {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-80-until-fullhd {
    background-color: #fffc;
  }
}

.has-background-grey-100-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-80-fullhd {
    background-color: #fffc;
  }
}

.has-background-grey-100-90 {
  background-color: #ffffffe6 !important;
}

.has-background-grey-100-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-90-mobile {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-90-tablet {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-90-tablet-only {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-90-touch {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-90-desktop {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-90-desktop-only {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-90-until-widescreen {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-90-widescreen {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-90-widescreen-only {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-90-until-fullhd {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-90-fullhd {
    background-color: #ffffffe6;
  }
}

.has-background-grey-100-100 {
  background-color: #fff !important;
}

.has-background-grey-100-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-100-100-mobile {
    background-color: #fff;
  }
}

.has-background-grey-100-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-100-100-tablet {
    background-color: #fff;
  }
}

.has-background-grey-100-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-100-100-tablet-only {
    background-color: #fff;
  }
}

.has-background-grey-100-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-100-100-touch {
    background-color: #fff;
  }
}

.has-background-grey-100-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-100-100-desktop {
    background-color: #fff;
  }
}

.has-background-grey-100-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-100-100-desktop-only {
    background-color: #fff;
  }
}

.has-background-grey-100-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-100-100-until-widescreen {
    background-color: #fff;
  }
}

.has-background-grey-100-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-100-100-widescreen {
    background-color: #fff;
  }
}

.has-background-grey-100-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-100-100-widescreen-only {
    background-color: #fff;
  }
}

.has-background-grey-100-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-100-100-until-fullhd {
    background-color: #fff;
  }
}

.has-background-grey-100-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-100-100-fullhd {
    background-color: #fff;
  }
}

.has-text-grey-200 {
  color: #e2e2e2 !important;
}

.has-border-grey-200-1 {
  border: 1px solid #e2e2e2;
}

.has-inset-border-grey-200-1 {
  box-shadow: inset 0 0 0 1px #e2e2e2;
}

.has-border-grey-200-2 {
  border: 2px solid #e2e2e2;
}

.has-inset-border-grey-200-2 {
  box-shadow: inset 0 0 0 2px #e2e2e2;
}

.has-border-grey-200-3 {
  border: 3px solid #e2e2e2;
}

.has-inset-border-grey-200-3 {
  box-shadow: inset 0 0 0 3px #e2e2e2;
}

.has-text-grey-200-10 {
  color: #e2e2e21a !important;
}

.has-text-grey-200-20 {
  color: #e2e2e233 !important;
}

.has-text-grey-200-30 {
  color: #e2e2e24d !important;
}

.has-text-grey-200-40 {
  color: #e2e2e266 !important;
}

.has-text-grey-200-50 {
  color: #e2e2e280 !important;
}

.has-text-grey-200-60 {
  color: #e2e2e299 !important;
}

.has-text-grey-200-70 {
  color: #e2e2e2b3 !important;
}

.has-text-grey-200-80 {
  color: #e2e2e2cc !important;
}

.has-text-grey-200-90 {
  color: #e2e2e2e6 !important;
}

.has-text-grey-200-100 {
  color: #e2e2e2 !important;
}

.has-background-grey-200 {
  background-color: #e2e2e2 !important;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-mobile {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-grey-200-tablet {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-tablet-only {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-touch {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-desktop {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-desktop-only {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-until-widescreen {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-widescreen {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-widescreen-only {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-until-fullhd {
    background-color: #e2e2e2 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-fullhd {
    background-color: #e2e2e2 !important;
  }
}

.has-background-grey-200-10 {
  background-color: #e2e2e21a !important;
}

.has-background-grey-200-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-10-mobile {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-10-tablet {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-10-tablet-only {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-10-touch {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-10-desktop {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-10-desktop-only {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-10-until-widescreen {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-10-widescreen {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-10-widescreen-only {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-10-until-fullhd {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-10-fullhd {
    background-color: #e2e2e21a;
  }
}

.has-background-grey-200-20 {
  background-color: #e2e2e233 !important;
}

.has-background-grey-200-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-20-mobile {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-20-tablet {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-20-tablet-only {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-20-touch {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-20-desktop {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-20-desktop-only {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-20-until-widescreen {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-20-widescreen {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-20-widescreen-only {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-20-until-fullhd {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-20-fullhd {
    background-color: #e2e2e233;
  }
}

.has-background-grey-200-30 {
  background-color: #e2e2e24d !important;
}

.has-background-grey-200-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-30-mobile {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-30-tablet {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-30-tablet-only {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-30-touch {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-30-desktop {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-30-desktop-only {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-30-until-widescreen {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-30-widescreen {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-30-widescreen-only {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-30-until-fullhd {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-30-fullhd {
    background-color: #e2e2e24d;
  }
}

.has-background-grey-200-40 {
  background-color: #e2e2e266 !important;
}

.has-background-grey-200-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-40-mobile {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-40-tablet {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-40-tablet-only {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-40-touch {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-40-desktop {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-40-desktop-only {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-40-until-widescreen {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-40-widescreen {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-40-widescreen-only {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-40-until-fullhd {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-40-fullhd {
    background-color: #e2e2e266;
  }
}

.has-background-grey-200-50 {
  background-color: #e2e2e280 !important;
}

.has-background-grey-200-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-50-mobile {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-50-tablet {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-50-tablet-only {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-50-touch {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-50-desktop {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-50-desktop-only {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-50-until-widescreen {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-50-widescreen {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-50-widescreen-only {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-50-until-fullhd {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-50-fullhd {
    background-color: #e2e2e280;
  }
}

.has-background-grey-200-60 {
  background-color: #e2e2e299 !important;
}

.has-background-grey-200-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-60-mobile {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-60-tablet {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-60-tablet-only {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-60-touch {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-60-desktop {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-60-desktop-only {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-60-until-widescreen {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-60-widescreen {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-60-widescreen-only {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-60-until-fullhd {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-60-fullhd {
    background-color: #e2e2e299;
  }
}

.has-background-grey-200-70 {
  background-color: #e2e2e2b3 !important;
}

.has-background-grey-200-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-70-mobile {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-70-tablet {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-70-tablet-only {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-70-touch {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-70-desktop {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-70-desktop-only {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-70-until-widescreen {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-70-widescreen {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-70-widescreen-only {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-70-until-fullhd {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-70-fullhd {
    background-color: #e2e2e2b3;
  }
}

.has-background-grey-200-80 {
  background-color: #e2e2e2cc !important;
}

.has-background-grey-200-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-80-mobile {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-80-tablet {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-80-tablet-only {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-80-touch {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-80-desktop {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-80-desktop-only {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-80-until-widescreen {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-80-widescreen {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-80-widescreen-only {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-80-until-fullhd {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-80-fullhd {
    background-color: #e2e2e2cc;
  }
}

.has-background-grey-200-90 {
  background-color: #e2e2e2e6 !important;
}

.has-background-grey-200-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-90-mobile {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-90-tablet {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-90-tablet-only {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-90-touch {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-90-desktop {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-90-desktop-only {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-90-until-widescreen {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-90-widescreen {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-90-widescreen-only {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-90-until-fullhd {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-90-fullhd {
    background-color: #e2e2e2e6;
  }
}

.has-background-grey-200-100 {
  background-color: #e2e2e2 !important;
}

.has-background-grey-200-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-200-100-mobile {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-200-100-tablet {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-200-100-tablet-only {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-200-100-touch {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-200-100-desktop {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-200-100-desktop-only {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-200-100-until-widescreen {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-200-100-widescreen {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-200-100-widescreen-only {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-200-100-until-fullhd {
    background-color: #e2e2e2;
  }
}

.has-background-grey-200-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-200-100-fullhd {
    background-color: #e2e2e2;
  }
}

.has-text-grey-300 {
  color: #a8a8a8 !important;
}

.has-border-grey-300-1 {
  border: 1px solid #a8a8a8;
}

.has-inset-border-grey-300-1 {
  box-shadow: inset 0 0 0 1px #a8a8a8;
}

.has-border-grey-300-2 {
  border: 2px solid #a8a8a8;
}

.has-inset-border-grey-300-2 {
  box-shadow: inset 0 0 0 2px #a8a8a8;
}

.has-border-grey-300-3 {
  border: 3px solid #a8a8a8;
}

.has-inset-border-grey-300-3 {
  box-shadow: inset 0 0 0 3px #a8a8a8;
}

.has-text-grey-300-10 {
  color: #a8a8a81a !important;
}

.has-text-grey-300-20 {
  color: #a8a8a833 !important;
}

.has-text-grey-300-30 {
  color: #a8a8a84d !important;
}

.has-text-grey-300-40 {
  color: #a8a8a866 !important;
}

.has-text-grey-300-50 {
  color: #a8a8a880 !important;
}

.has-text-grey-300-60 {
  color: #a8a8a899 !important;
}

.has-text-grey-300-70 {
  color: #a8a8a8b3 !important;
}

.has-text-grey-300-80 {
  color: #a8a8a8cc !important;
}

.has-text-grey-300-90 {
  color: #a8a8a8e6 !important;
}

.has-text-grey-300-100 {
  color: #a8a8a8 !important;
}

.has-background-grey-300 {
  background-color: #a8a8a8 !important;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-mobile {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-grey-300-tablet {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-tablet-only {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-touch {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-desktop {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-desktop-only {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-until-widescreen {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-widescreen {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-widescreen-only {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-until-fullhd {
    background-color: #a8a8a8 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-fullhd {
    background-color: #a8a8a8 !important;
  }
}

.has-background-grey-300-10 {
  background-color: #a8a8a81a !important;
}

.has-background-grey-300-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-10-mobile {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-10-tablet {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-10-tablet-only {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-10-touch {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-10-desktop {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-10-desktop-only {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-10-until-widescreen {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-10-widescreen {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-10-widescreen-only {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-10-until-fullhd {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-10-fullhd {
    background-color: #a8a8a81a;
  }
}

.has-background-grey-300-20 {
  background-color: #a8a8a833 !important;
}

.has-background-grey-300-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-20-mobile {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-20-tablet {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-20-tablet-only {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-20-touch {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-20-desktop {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-20-desktop-only {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-20-until-widescreen {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-20-widescreen {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-20-widescreen-only {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-20-until-fullhd {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-20-fullhd {
    background-color: #a8a8a833;
  }
}

.has-background-grey-300-30 {
  background-color: #a8a8a84d !important;
}

.has-background-grey-300-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-30-mobile {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-30-tablet {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-30-tablet-only {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-30-touch {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-30-desktop {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-30-desktop-only {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-30-until-widescreen {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-30-widescreen {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-30-widescreen-only {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-30-until-fullhd {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-30-fullhd {
    background-color: #a8a8a84d;
  }
}

.has-background-grey-300-40 {
  background-color: #a8a8a866 !important;
}

.has-background-grey-300-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-40-mobile {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-40-tablet {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-40-tablet-only {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-40-touch {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-40-desktop {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-40-desktop-only {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-40-until-widescreen {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-40-widescreen {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-40-widescreen-only {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-40-until-fullhd {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-40-fullhd {
    background-color: #a8a8a866;
  }
}

.has-background-grey-300-50 {
  background-color: #a8a8a880 !important;
}

.has-background-grey-300-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-50-mobile {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-50-tablet {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-50-tablet-only {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-50-touch {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-50-desktop {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-50-desktop-only {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-50-until-widescreen {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-50-widescreen {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-50-widescreen-only {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-50-until-fullhd {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-50-fullhd {
    background-color: #a8a8a880;
  }
}

.has-background-grey-300-60 {
  background-color: #a8a8a899 !important;
}

.has-background-grey-300-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-60-mobile {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-60-tablet {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-60-tablet-only {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-60-touch {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-60-desktop {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-60-desktop-only {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-60-until-widescreen {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-60-widescreen {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-60-widescreen-only {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-60-until-fullhd {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-60-fullhd {
    background-color: #a8a8a899;
  }
}

.has-background-grey-300-70 {
  background-color: #a8a8a8b3 !important;
}

.has-background-grey-300-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-70-mobile {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-70-tablet {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-70-tablet-only {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-70-touch {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-70-desktop {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-70-desktop-only {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-70-until-widescreen {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-70-widescreen {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-70-widescreen-only {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-70-until-fullhd {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-70-fullhd {
    background-color: #a8a8a8b3;
  }
}

.has-background-grey-300-80 {
  background-color: #a8a8a8cc !important;
}

.has-background-grey-300-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-80-mobile {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-80-tablet {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-80-tablet-only {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-80-touch {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-80-desktop {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-80-desktop-only {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-80-until-widescreen {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-80-widescreen {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-80-widescreen-only {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-80-until-fullhd {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-80-fullhd {
    background-color: #a8a8a8cc;
  }
}

.has-background-grey-300-90 {
  background-color: #a8a8a8e6 !important;
}

.has-background-grey-300-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-90-mobile {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-90-tablet {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-90-tablet-only {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-90-touch {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-90-desktop {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-90-desktop-only {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-90-until-widescreen {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-90-widescreen {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-90-widescreen-only {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-90-until-fullhd {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-90-fullhd {
    background-color: #a8a8a8e6;
  }
}

.has-background-grey-300-100 {
  background-color: #a8a8a8 !important;
}

.has-background-grey-300-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-300-100-mobile {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-300-100-tablet {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-300-100-tablet-only {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-300-100-touch {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-300-100-desktop {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-300-100-desktop-only {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-300-100-until-widescreen {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-300-100-widescreen {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-300-100-widescreen-only {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-300-100-until-fullhd {
    background-color: #a8a8a8;
  }
}

.has-background-grey-300-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-300-100-fullhd {
    background-color: #a8a8a8;
  }
}

.has-text-grey-400 {
  color: #616161 !important;
}

.has-border-grey-400-1 {
  border: 1px solid #616161;
}

.has-inset-border-grey-400-1 {
  box-shadow: inset 0 0 0 1px #616161;
}

.has-border-grey-400-2 {
  border: 2px solid #616161;
}

.has-inset-border-grey-400-2 {
  box-shadow: inset 0 0 0 2px #616161;
}

.has-border-grey-400-3 {
  border: 3px solid #616161;
}

.has-inset-border-grey-400-3 {
  box-shadow: inset 0 0 0 3px #616161;
}

.has-text-grey-400-10 {
  color: #6161611a !important;
}

.has-text-grey-400-20 {
  color: #61616133 !important;
}

.has-text-grey-400-30 {
  color: #6161614d !important;
}

.has-text-grey-400-40 {
  color: #61616166 !important;
}

.has-text-grey-400-50 {
  color: #61616180 !important;
}

.has-text-grey-400-60 {
  color: #61616199 !important;
}

.has-text-grey-400-70 {
  color: #616161b3 !important;
}

.has-text-grey-400-80 {
  color: #616161cc !important;
}

.has-text-grey-400-90 {
  color: #616161e6 !important;
}

.has-text-grey-400-100 {
  color: #616161 !important;
}

.has-background-grey-400 {
  background-color: #616161 !important;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-mobile {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-grey-400-tablet {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-tablet-only {
    background-color: #616161 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-touch {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-desktop {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-desktop-only {
    background-color: #616161 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-until-widescreen {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-widescreen {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-widescreen-only {
    background-color: #616161 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-until-fullhd {
    background-color: #616161 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-fullhd {
    background-color: #616161 !important;
  }
}

.has-background-grey-400-10 {
  background-color: #6161611a !important;
}

.has-background-grey-400-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-10-mobile {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-10-tablet {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-10-tablet-only {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-10-touch {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-10-desktop {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-10-desktop-only {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-10-until-widescreen {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-10-widescreen {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-10-widescreen-only {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-10-until-fullhd {
    background-color: #6161611a;
  }
}

.has-background-grey-400-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-10-fullhd {
    background-color: #6161611a;
  }
}

.has-background-grey-400-20 {
  background-color: #61616133 !important;
}

.has-background-grey-400-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-20-mobile {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-20-tablet {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-20-tablet-only {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-20-touch {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-20-desktop {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-20-desktop-only {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-20-until-widescreen {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-20-widescreen {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-20-widescreen-only {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-20-until-fullhd {
    background-color: #61616133;
  }
}

.has-background-grey-400-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-20-fullhd {
    background-color: #61616133;
  }
}

.has-background-grey-400-30 {
  background-color: #6161614d !important;
}

.has-background-grey-400-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-30-mobile {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-30-tablet {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-30-tablet-only {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-30-touch {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-30-desktop {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-30-desktop-only {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-30-until-widescreen {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-30-widescreen {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-30-widescreen-only {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-30-until-fullhd {
    background-color: #6161614d;
  }
}

.has-background-grey-400-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-30-fullhd {
    background-color: #6161614d;
  }
}

.has-background-grey-400-40 {
  background-color: #61616166 !important;
}

.has-background-grey-400-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-40-mobile {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-40-tablet {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-40-tablet-only {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-40-touch {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-40-desktop {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-40-desktop-only {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-40-until-widescreen {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-40-widescreen {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-40-widescreen-only {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-40-until-fullhd {
    background-color: #61616166;
  }
}

.has-background-grey-400-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-40-fullhd {
    background-color: #61616166;
  }
}

.has-background-grey-400-50 {
  background-color: #61616180 !important;
}

.has-background-grey-400-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-50-mobile {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-50-tablet {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-50-tablet-only {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-50-touch {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-50-desktop {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-50-desktop-only {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-50-until-widescreen {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-50-widescreen {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-50-widescreen-only {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-50-until-fullhd {
    background-color: #61616180;
  }
}

.has-background-grey-400-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-50-fullhd {
    background-color: #61616180;
  }
}

.has-background-grey-400-60 {
  background-color: #61616199 !important;
}

.has-background-grey-400-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-60-mobile {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-60-tablet {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-60-tablet-only {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-60-touch {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-60-desktop {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-60-desktop-only {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-60-until-widescreen {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-60-widescreen {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-60-widescreen-only {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-60-until-fullhd {
    background-color: #61616199;
  }
}

.has-background-grey-400-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-60-fullhd {
    background-color: #61616199;
  }
}

.has-background-grey-400-70 {
  background-color: #616161b3 !important;
}

.has-background-grey-400-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-70-mobile {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-70-tablet {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-70-tablet-only {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-70-touch {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-70-desktop {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-70-desktop-only {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-70-until-widescreen {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-70-widescreen {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-70-widescreen-only {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-70-until-fullhd {
    background-color: #616161b3;
  }
}

.has-background-grey-400-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-70-fullhd {
    background-color: #616161b3;
  }
}

.has-background-grey-400-80 {
  background-color: #616161cc !important;
}

.has-background-grey-400-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-80-mobile {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-80-tablet {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-80-tablet-only {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-80-touch {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-80-desktop {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-80-desktop-only {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-80-until-widescreen {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-80-widescreen {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-80-widescreen-only {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-80-until-fullhd {
    background-color: #616161cc;
  }
}

.has-background-grey-400-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-80-fullhd {
    background-color: #616161cc;
  }
}

.has-background-grey-400-90 {
  background-color: #616161e6 !important;
}

.has-background-grey-400-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-90-mobile {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-90-tablet {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-90-tablet-only {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-90-touch {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-90-desktop {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-90-desktop-only {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-90-until-widescreen {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-90-widescreen {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-90-widescreen-only {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-90-until-fullhd {
    background-color: #616161e6;
  }
}

.has-background-grey-400-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-90-fullhd {
    background-color: #616161e6;
  }
}

.has-background-grey-400-100 {
  background-color: #616161 !important;
}

.has-background-grey-400-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-400-100-mobile {
    background-color: #616161;
  }
}

.has-background-grey-400-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-400-100-tablet {
    background-color: #616161;
  }
}

.has-background-grey-400-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-400-100-tablet-only {
    background-color: #616161;
  }
}

.has-background-grey-400-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-400-100-touch {
    background-color: #616161;
  }
}

.has-background-grey-400-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-400-100-desktop {
    background-color: #616161;
  }
}

.has-background-grey-400-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-400-100-desktop-only {
    background-color: #616161;
  }
}

.has-background-grey-400-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-400-100-until-widescreen {
    background-color: #616161;
  }
}

.has-background-grey-400-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-400-100-widescreen {
    background-color: #616161;
  }
}

.has-background-grey-400-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-400-100-widescreen-only {
    background-color: #616161;
  }
}

.has-background-grey-400-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-400-100-until-fullhd {
    background-color: #616161;
  }
}

.has-background-grey-400-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-400-100-fullhd {
    background-color: #616161;
  }
}

.has-text-grey-500 {
  color: #393939 !important;
}

.has-border-grey-500-1 {
  border: 1px solid #393939;
}

.has-inset-border-grey-500-1 {
  box-shadow: inset 0 0 0 1px #393939;
}

.has-border-grey-500-2 {
  border: 2px solid #393939;
}

.has-inset-border-grey-500-2 {
  box-shadow: inset 0 0 0 2px #393939;
}

.has-border-grey-500-3 {
  border: 3px solid #393939;
}

.has-inset-border-grey-500-3 {
  box-shadow: inset 0 0 0 3px #393939;
}

.has-text-grey-500-10 {
  color: #3939391a !important;
}

.has-text-grey-500-20 {
  color: #39393933 !important;
}

.has-text-grey-500-30 {
  color: #3939394d !important;
}

.has-text-grey-500-40 {
  color: #39393966 !important;
}

.has-text-grey-500-50 {
  color: #39393980 !important;
}

.has-text-grey-500-60 {
  color: #39393999 !important;
}

.has-text-grey-500-70 {
  color: #393939b3 !important;
}

.has-text-grey-500-80 {
  color: #393939cc !important;
}

.has-text-grey-500-90 {
  color: #393939e6 !important;
}

.has-text-grey-500-100 {
  color: #393939 !important;
}

.has-background-grey-500 {
  background-color: #393939 !important;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-mobile {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-grey-500-tablet {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-tablet-only {
    background-color: #393939 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-touch {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-desktop {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-desktop-only {
    background-color: #393939 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-until-widescreen {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-widescreen {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-widescreen-only {
    background-color: #393939 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-until-fullhd {
    background-color: #393939 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-fullhd {
    background-color: #393939 !important;
  }
}

.has-background-grey-500-10 {
  background-color: #3939391a !important;
}

.has-background-grey-500-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-10-mobile {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-10-tablet {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-10-tablet-only {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-10-touch {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-10-desktop {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-10-desktop-only {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-10-until-widescreen {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-10-widescreen {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-10-widescreen-only {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-10-until-fullhd {
    background-color: #3939391a;
  }
}

.has-background-grey-500-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-10-fullhd {
    background-color: #3939391a;
  }
}

.has-background-grey-500-20 {
  background-color: #39393933 !important;
}

.has-background-grey-500-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-20-mobile {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-20-tablet {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-20-tablet-only {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-20-touch {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-20-desktop {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-20-desktop-only {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-20-until-widescreen {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-20-widescreen {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-20-widescreen-only {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-20-until-fullhd {
    background-color: #39393933;
  }
}

.has-background-grey-500-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-20-fullhd {
    background-color: #39393933;
  }
}

.has-background-grey-500-30 {
  background-color: #3939394d !important;
}

.has-background-grey-500-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-30-mobile {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-30-tablet {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-30-tablet-only {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-30-touch {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-30-desktop {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-30-desktop-only {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-30-until-widescreen {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-30-widescreen {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-30-widescreen-only {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-30-until-fullhd {
    background-color: #3939394d;
  }
}

.has-background-grey-500-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-30-fullhd {
    background-color: #3939394d;
  }
}

.has-background-grey-500-40 {
  background-color: #39393966 !important;
}

.has-background-grey-500-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-40-mobile {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-40-tablet {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-40-tablet-only {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-40-touch {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-40-desktop {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-40-desktop-only {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-40-until-widescreen {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-40-widescreen {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-40-widescreen-only {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-40-until-fullhd {
    background-color: #39393966;
  }
}

.has-background-grey-500-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-40-fullhd {
    background-color: #39393966;
  }
}

.has-background-grey-500-50 {
  background-color: #39393980 !important;
}

.has-background-grey-500-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-50-mobile {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-50-tablet {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-50-tablet-only {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-50-touch {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-50-desktop {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-50-desktop-only {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-50-until-widescreen {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-50-widescreen {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-50-widescreen-only {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-50-until-fullhd {
    background-color: #39393980;
  }
}

.has-background-grey-500-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-50-fullhd {
    background-color: #39393980;
  }
}

.has-background-grey-500-60 {
  background-color: #39393999 !important;
}

.has-background-grey-500-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-60-mobile {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-60-tablet {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-60-tablet-only {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-60-touch {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-60-desktop {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-60-desktop-only {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-60-until-widescreen {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-60-widescreen {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-60-widescreen-only {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-60-until-fullhd {
    background-color: #39393999;
  }
}

.has-background-grey-500-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-60-fullhd {
    background-color: #39393999;
  }
}

.has-background-grey-500-70 {
  background-color: #393939b3 !important;
}

.has-background-grey-500-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-70-mobile {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-70-tablet {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-70-tablet-only {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-70-touch {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-70-desktop {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-70-desktop-only {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-70-until-widescreen {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-70-widescreen {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-70-widescreen-only {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-70-until-fullhd {
    background-color: #393939b3;
  }
}

.has-background-grey-500-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-70-fullhd {
    background-color: #393939b3;
  }
}

.has-background-grey-500-80 {
  background-color: #393939cc !important;
}

.has-background-grey-500-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-80-mobile {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-80-tablet {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-80-tablet-only {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-80-touch {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-80-desktop {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-80-desktop-only {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-80-until-widescreen {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-80-widescreen {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-80-widescreen-only {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-80-until-fullhd {
    background-color: #393939cc;
  }
}

.has-background-grey-500-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-80-fullhd {
    background-color: #393939cc;
  }
}

.has-background-grey-500-90 {
  background-color: #393939e6 !important;
}

.has-background-grey-500-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-90-mobile {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-90-tablet {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-90-tablet-only {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-90-touch {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-90-desktop {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-90-desktop-only {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-90-until-widescreen {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-90-widescreen {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-90-widescreen-only {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-90-until-fullhd {
    background-color: #393939e6;
  }
}

.has-background-grey-500-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-90-fullhd {
    background-color: #393939e6;
  }
}

.has-background-grey-500-100 {
  background-color: #393939 !important;
}

.has-background-grey-500-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-500-100-mobile {
    background-color: #393939;
  }
}

.has-background-grey-500-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-500-100-tablet {
    background-color: #393939;
  }
}

.has-background-grey-500-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-500-100-tablet-only {
    background-color: #393939;
  }
}

.has-background-grey-500-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-500-100-touch {
    background-color: #393939;
  }
}

.has-background-grey-500-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-500-100-desktop {
    background-color: #393939;
  }
}

.has-background-grey-500-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-500-100-desktop-only {
    background-color: #393939;
  }
}

.has-background-grey-500-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-500-100-until-widescreen {
    background-color: #393939;
  }
}

.has-background-grey-500-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-500-100-widescreen {
    background-color: #393939;
  }
}

.has-background-grey-500-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-500-100-widescreen-only {
    background-color: #393939;
  }
}

.has-background-grey-500-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-500-100-until-fullhd {
    background-color: #393939;
  }
}

.has-background-grey-500-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-500-100-fullhd {
    background-color: #393939;
  }
}

.has-text-grey-600 {
  color: #000 !important;
}

.has-border-grey-600-1 {
  border: 1px solid #000;
}

.has-inset-border-grey-600-1 {
  box-shadow: inset 0 0 0 1px #000;
}

.has-border-grey-600-2 {
  border: 2px solid #000;
}

.has-inset-border-grey-600-2 {
  box-shadow: inset 0 0 0 2px #000;
}

.has-border-grey-600-3 {
  border: 3px solid #000;
}

.has-inset-border-grey-600-3 {
  box-shadow: inset 0 0 0 3px #000;
}

.has-text-grey-600-10 {
  color: #0000001a !important;
}

.has-text-grey-600-20 {
  color: #0003 !important;
}

.has-text-grey-600-30 {
  color: #0000004d !important;
}

.has-text-grey-600-40 {
  color: #0006 !important;
}

.has-text-grey-600-50 {
  color: #00000080 !important;
}

.has-text-grey-600-60 {
  color: #0009 !important;
}

.has-text-grey-600-70 {
  color: #000000b3 !important;
}

.has-text-grey-600-80 {
  color: #000c !important;
}

.has-text-grey-600-90 {
  color: #000000e6 !important;
}

.has-text-grey-600-100 {
  color: #000 !important;
}

.has-background-grey-600 {
  background-color: #000 !important;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-mobile {
    background-color: #000 !important;
  }
}

@media screen and (width >= 769px) {
  .has-background-grey-600-tablet {
    background-color: #000 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-tablet-only {
    background-color: #000 !important;
  }
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-touch {
    background-color: #000 !important;
  }
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-desktop {
    background-color: #000 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-desktop-only {
    background-color: #000 !important;
  }
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-until-widescreen {
    background-color: #000 !important;
  }
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-widescreen {
    background-color: #000 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-widescreen-only {
    background-color: #000 !important;
  }
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-until-fullhd {
    background-color: #000 !important;
  }
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-fullhd {
    background-color: #000 !important;
  }
}

.has-background-grey-600-10 {
  background-color: #0000001a !important;
}

.has-background-grey-600-10-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-10-mobile {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-10-tablet {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-10-tablet-only {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-10-touch {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-10-desktop {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-10-desktop-only {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-10-until-widescreen {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-10-widescreen {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-10-widescreen-only {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-10-until-fullhd {
    background-color: #0000001a;
  }
}

.has-background-grey-600-10-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-10-fullhd {
    background-color: #0000001a;
  }
}

.has-background-grey-600-20 {
  background-color: #0003 !important;
}

.has-background-grey-600-20-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-20-mobile {
    background-color: #0003;
  }
}

.has-background-grey-600-20-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-20-tablet {
    background-color: #0003;
  }
}

.has-background-grey-600-20-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-20-tablet-only {
    background-color: #0003;
  }
}

.has-background-grey-600-20-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-20-touch {
    background-color: #0003;
  }
}

.has-background-grey-600-20-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-20-desktop {
    background-color: #0003;
  }
}

.has-background-grey-600-20-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-20-desktop-only {
    background-color: #0003;
  }
}

.has-background-grey-600-20-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-20-until-widescreen {
    background-color: #0003;
  }
}

.has-background-grey-600-20-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-20-widescreen {
    background-color: #0003;
  }
}

.has-background-grey-600-20-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-20-widescreen-only {
    background-color: #0003;
  }
}

.has-background-grey-600-20-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-20-until-fullhd {
    background-color: #0003;
  }
}

.has-background-grey-600-20-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-20-fullhd {
    background-color: #0003;
  }
}

.has-background-grey-600-30 {
  background-color: #0000004d !important;
}

.has-background-grey-600-30-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-30-mobile {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-30-tablet {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-30-tablet-only {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-30-touch {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-30-desktop {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-30-desktop-only {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-30-until-widescreen {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-30-widescreen {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-30-widescreen-only {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-30-until-fullhd {
    background-color: #0000004d;
  }
}

.has-background-grey-600-30-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-30-fullhd {
    background-color: #0000004d;
  }
}

.has-background-grey-600-40 {
  background-color: #0006 !important;
}

.has-background-grey-600-40-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-40-mobile {
    background-color: #0006;
  }
}

.has-background-grey-600-40-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-40-tablet {
    background-color: #0006;
  }
}

.has-background-grey-600-40-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-40-tablet-only {
    background-color: #0006;
  }
}

.has-background-grey-600-40-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-40-touch {
    background-color: #0006;
  }
}

.has-background-grey-600-40-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-40-desktop {
    background-color: #0006;
  }
}

.has-background-grey-600-40-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-40-desktop-only {
    background-color: #0006;
  }
}

.has-background-grey-600-40-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-40-until-widescreen {
    background-color: #0006;
  }
}

.has-background-grey-600-40-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-40-widescreen {
    background-color: #0006;
  }
}

.has-background-grey-600-40-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-40-widescreen-only {
    background-color: #0006;
  }
}

.has-background-grey-600-40-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-40-until-fullhd {
    background-color: #0006;
  }
}

.has-background-grey-600-40-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-40-fullhd {
    background-color: #0006;
  }
}

.has-background-grey-600-50 {
  background-color: #00000080 !important;
}

.has-background-grey-600-50-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-50-mobile {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-50-tablet {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-50-tablet-only {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-50-touch {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-50-desktop {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-50-desktop-only {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-50-until-widescreen {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-50-widescreen {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-50-widescreen-only {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-50-until-fullhd {
    background-color: #00000080;
  }
}

.has-background-grey-600-50-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-50-fullhd {
    background-color: #00000080;
  }
}

.has-background-grey-600-60 {
  background-color: #0009 !important;
}

.has-background-grey-600-60-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-60-mobile {
    background-color: #0009;
  }
}

.has-background-grey-600-60-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-60-tablet {
    background-color: #0009;
  }
}

.has-background-grey-600-60-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-60-tablet-only {
    background-color: #0009;
  }
}

.has-background-grey-600-60-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-60-touch {
    background-color: #0009;
  }
}

.has-background-grey-600-60-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-60-desktop {
    background-color: #0009;
  }
}

.has-background-grey-600-60-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-60-desktop-only {
    background-color: #0009;
  }
}

.has-background-grey-600-60-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-60-until-widescreen {
    background-color: #0009;
  }
}

.has-background-grey-600-60-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-60-widescreen {
    background-color: #0009;
  }
}

.has-background-grey-600-60-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-60-widescreen-only {
    background-color: #0009;
  }
}

.has-background-grey-600-60-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-60-until-fullhd {
    background-color: #0009;
  }
}

.has-background-grey-600-60-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-60-fullhd {
    background-color: #0009;
  }
}

.has-background-grey-600-70 {
  background-color: #000000b3 !important;
}

.has-background-grey-600-70-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-70-mobile {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-70-tablet {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-70-tablet-only {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-70-touch {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-70-desktop {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-70-desktop-only {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-70-until-widescreen {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-70-widescreen {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-70-widescreen-only {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-70-until-fullhd {
    background-color: #000000b3;
  }
}

.has-background-grey-600-70-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-70-fullhd {
    background-color: #000000b3;
  }
}

.has-background-grey-600-80 {
  background-color: #000c !important;
}

.has-background-grey-600-80-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-80-mobile {
    background-color: #000c;
  }
}

.has-background-grey-600-80-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-80-tablet {
    background-color: #000c;
  }
}

.has-background-grey-600-80-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-80-tablet-only {
    background-color: #000c;
  }
}

.has-background-grey-600-80-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-80-touch {
    background-color: #000c;
  }
}

.has-background-grey-600-80-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-80-desktop {
    background-color: #000c;
  }
}

.has-background-grey-600-80-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-80-desktop-only {
    background-color: #000c;
  }
}

.has-background-grey-600-80-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-80-until-widescreen {
    background-color: #000c;
  }
}

.has-background-grey-600-80-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-80-widescreen {
    background-color: #000c;
  }
}

.has-background-grey-600-80-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-80-widescreen-only {
    background-color: #000c;
  }
}

.has-background-grey-600-80-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-80-until-fullhd {
    background-color: #000c;
  }
}

.has-background-grey-600-80-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-80-fullhd {
    background-color: #000c;
  }
}

.has-background-grey-600-90 {
  background-color: #000000e6 !important;
}

.has-background-grey-600-90-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-90-mobile {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-90-tablet {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-90-tablet-only {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-90-touch {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-90-desktop {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-90-desktop-only {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-90-until-widescreen {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-90-widescreen {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-90-widescreen-only {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-90-until-fullhd {
    background-color: #000000e6;
  }
}

.has-background-grey-600-90-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-90-fullhd {
    background-color: #000000e6;
  }
}

.has-background-grey-600-100 {
  background-color: #000 !important;
}

.has-background-grey-600-100-mobile {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-grey-600-100-mobile {
    background-color: #000;
  }
}

.has-background-grey-600-100-tablet {
  background-color: #0000;
}

@media screen and (width >= 769px) {
  .has-background-grey-600-100-tablet {
    background-color: #000;
  }
}

.has-background-grey-600-100-tablet-only {
  background-color: #0000;
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-grey-600-100-tablet-only {
    background-color: #000;
  }
}

.has-background-grey-600-100-touch {
  background-color: #0000;
}

@media screen and (width <= 1023px) {
  .has-background-grey-600-100-touch {
    background-color: #000;
  }
}

.has-background-grey-600-100-desktop {
  background-color: #0000;
}

@media screen and (width >= 1024px) {
  .has-background-grey-600-100-desktop {
    background-color: #000;
  }
}

.has-background-grey-600-100-desktop-only {
  background-color: #0000;
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-grey-600-100-desktop-only {
    background-color: #000;
  }
}

.has-background-grey-600-100-until-widescreen {
  background-color: #0000;
}

@media screen and (width <= 1215px) {
  .has-background-grey-600-100-until-widescreen {
    background-color: #000;
  }
}

.has-background-grey-600-100-widescreen {
  background-color: #0000;
}

@media screen and (width >= 1216px) {
  .has-background-grey-600-100-widescreen {
    background-color: #000;
  }
}

.has-background-grey-600-100-widescreen-only {
  background-color: #0000;
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-grey-600-100-widescreen-only {
    background-color: #000;
  }
}

.has-background-grey-600-100-until-fullhd {
  background-color: #0000;
}

@media screen and (width <= 1407px) {
  .has-background-grey-600-100-until-fullhd {
    background-color: #000;
  }
}

.has-background-grey-600-100-fullhd {
  background-color: #0000;
}

@media screen and (width >= 1408px) {
  .has-background-grey-600-100-fullhd {
    background-color: #000;
  }
}

.has-background-transparent {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .has-background-transparent-mobile {
    background-color: #0000;
  }
}

@media screen and (width >= 769px) {
  .has-background-transparent-tablet {
    background-color: #0000;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-transparent-tablet-only {
    background-color: #0000;
  }
}

@media screen and (width <= 1023px) {
  .has-background-transparent-touch {
    background-color: #0000;
  }
}

@media screen and (width >= 1024px) {
  .has-background-transparent-desktop {
    background-color: #0000;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-transparent-desktop-only {
    background-color: #0000;
  }
}

@media screen and (width <= 1215px) {
  .has-background-transparent-until-widescreen {
    background-color: #0000;
  }
}

@media screen and (width >= 1216px) {
  .has-background-transparent-widescreen {
    background-color: #0000;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-transparent-widescreen-only {
    background-color: #0000;
  }
}

@media screen and (width <= 1407px) {
  .has-background-transparent-until-fullhd {
    background-color: #0000;
  }
}

@media screen and (width >= 1408px) {
  .has-background-transparent-fullhd {
    background-color: #0000;
  }
}

.has-background-gradient-peach {
  background: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
}

@media screen and (width <= 768px) {
  .has-background-gradient-peach-mobile {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 769px) {
  .has-background-gradient-peach-tablet {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-gradient-peach-tablet-only {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width <= 1023px) {
  .has-background-gradient-peach-touch {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 1024px) {
  .has-background-gradient-peach-desktop {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-gradient-peach-desktop-only {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width <= 1215px) {
  .has-background-gradient-peach-until-widescreen {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 1216px) {
  .has-background-gradient-peach-widescreen {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-gradient-peach-widescreen-only {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width <= 1407px) {
  .has-background-gradient-peach-until-fullhd {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

@media screen and (width >= 1408px) {
  .has-background-gradient-peach-fullhd {
    background-color: linear-gradient(118.93deg, #f5bdac -5.87%, #f5d5b8 109.87%);
  }
}

.has-background-gradient-peach-light {
  background: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
}

@media screen and (width <= 768px) {
  .has-background-gradient-peach-lightmobile {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 769px) {
  .has-background-gradient-peach-lighttablet {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-background-gradient-peach-lighttablet-only {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width <= 1023px) {
  .has-background-gradient-peach-lighttouch {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 1024px) {
  .has-background-gradient-peach-lightdesktop {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-background-gradient-peach-lightdesktop-only {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width <= 1215px) {
  .has-background-gradient-peach-lightuntil-widescreen {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 1216px) {
  .has-background-gradient-peach-lightwidescreen {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-background-gradient-peach-lightwidescreen-only {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width <= 1407px) {
  .has-background-gradient-peach-lightuntil-fullhd {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

@media screen and (width >= 1408px) {
  .has-background-gradient-peach-lightfullhd {
    background-color: linear-gradient(118.93deg, #ffd5c8 -5.87%, #fce5d0 109.87%);
  }
}

.has-background-gradient-vertical-from-purple-blue-100-to-purple-blue-100 {
  background: linear-gradient(#f1f1fb, #f1f1fb);
}

.has-background-gradient-vertical-from-purple-blue-100-to-purple-blue-200 {
  background: linear-gradient(#f1f1fb, #c9c9f0);
}

.has-background-gradient-vertical-from-purple-blue-100-to-purple-blue-300 {
  background: linear-gradient(#f1f1fb, #a594f9);
}

.has-background-gradient-vertical-from-purple-blue-100-to-purple-blue-400 {
  background: linear-gradient(#f1f1fb, #2727c4);
}

.has-background-gradient-vertical-from-purple-blue-100-to-purple-blue-500 {
  background: linear-gradient(#f1f1fb, #1b0777);
}

.has-background-gradient-vertical-from-purple-blue-100-to-peach-100 {
  background: linear-gradient(#f1f1fb, #fdf3f2);
}

.has-background-gradient-vertical-from-purple-blue-100-to-peach-200 {
  background: linear-gradient(#f1f1fb, #f8d8d4);
}

.has-background-gradient-vertical-from-purple-blue-100-to-peach-300 {
  background: linear-gradient(#f1f1fb, #f0b0a8);
}

.has-background-gradient-vertical-from-purple-blue-100-to-peach-400 {
  background: linear-gradient(#f1f1fb, #e9897d);
}

.has-background-gradient-vertical-from-purple-blue-100-to-peach-500 {
  background: linear-gradient(#f1f1fb, #9b5b53);
}

.has-background-gradient-vertical-from-purple-blue-100-to-yellow-100 {
  background: linear-gradient(#f1f1fb, #fff8f1);
}

.has-background-gradient-vertical-from-purple-blue-100-to-yellow-200 {
  background: linear-gradient(#f1f1fb, #ffe8bd);
}

.has-background-gradient-vertical-from-purple-blue-100-to-yellow-300 {
  background: linear-gradient(#f1f1fb, #ffd07a);
}

.has-background-gradient-vertical-from-purple-blue-100-to-yellow-400 {
  background: linear-gradient(#f1f1fb, #ffb938);
}

.has-background-gradient-vertical-from-purple-blue-100-to-yellow-500 {
  background: linear-gradient(#f1f1fb, #aa7b25);
}

.has-background-gradient-vertical-from-purple-blue-100-to-pink-100 {
  background: linear-gradient(#f1f1fb, #fbeef0);
}

.has-background-gradient-vertical-from-purple-blue-100-to-pink-200 {
  background: linear-gradient(#f1f1fb, #f2c6cd);
}

.has-background-gradient-vertical-from-purple-blue-100-to-pink-300 {
  background: linear-gradient(#f1f1fb, #e58e9a);
}

.has-background-gradient-vertical-from-purple-blue-100-to-pink-400 {
  background: linear-gradient(#f1f1fb, #d85568);
}

.has-background-gradient-vertical-from-purple-blue-100-to-pink-500 {
  background: linear-gradient(#f1f1fb, #481c23);
}

.has-background-gradient-vertical-from-purple-blue-100-to-grey-100 {
  background: linear-gradient(#f1f1fb, #fff);
}

.has-background-gradient-vertical-from-purple-blue-100-to-grey-200 {
  background: linear-gradient(#f1f1fb, #e2e2e2);
}

.has-background-gradient-vertical-from-purple-blue-100-to-grey-300 {
  background: linear-gradient(#f1f1fb, #a8a8a8);
}

.has-background-gradient-vertical-from-purple-blue-100-to-grey-400 {
  background: linear-gradient(#f1f1fb, #616161);
}

.has-background-gradient-vertical-from-purple-blue-100-to-grey-500 {
  background: linear-gradient(#f1f1fb, #393939);
}

.has-background-gradient-vertical-from-purple-blue-100-to-grey-600 {
  background: linear-gradient(#f1f1fb, #000);
}

.has-background-gradient-vertical-from-purple-blue-200-to-purple-blue-100 {
  background: linear-gradient(#c9c9f0, #f1f1fb);
}

.has-background-gradient-vertical-from-purple-blue-200-to-purple-blue-200 {
  background: linear-gradient(#c9c9f0, #c9c9f0);
}

.has-background-gradient-vertical-from-purple-blue-200-to-purple-blue-300 {
  background: linear-gradient(#c9c9f0, #a594f9);
}

.has-background-gradient-vertical-from-purple-blue-200-to-purple-blue-400 {
  background: linear-gradient(#c9c9f0, #2727c4);
}

.has-background-gradient-vertical-from-purple-blue-200-to-purple-blue-500 {
  background: linear-gradient(#c9c9f0, #1b0777);
}

.has-background-gradient-vertical-from-purple-blue-200-to-peach-100 {
  background: linear-gradient(#c9c9f0, #fdf3f2);
}

.has-background-gradient-vertical-from-purple-blue-200-to-peach-200 {
  background: linear-gradient(#c9c9f0, #f8d8d4);
}

.has-background-gradient-vertical-from-purple-blue-200-to-peach-300 {
  background: linear-gradient(#c9c9f0, #f0b0a8);
}

.has-background-gradient-vertical-from-purple-blue-200-to-peach-400 {
  background: linear-gradient(#c9c9f0, #e9897d);
}

.has-background-gradient-vertical-from-purple-blue-200-to-peach-500 {
  background: linear-gradient(#c9c9f0, #9b5b53);
}

.has-background-gradient-vertical-from-purple-blue-200-to-yellow-100 {
  background: linear-gradient(#c9c9f0, #fff8f1);
}

.has-background-gradient-vertical-from-purple-blue-200-to-yellow-200 {
  background: linear-gradient(#c9c9f0, #ffe8bd);
}

.has-background-gradient-vertical-from-purple-blue-200-to-yellow-300 {
  background: linear-gradient(#c9c9f0, #ffd07a);
}

.has-background-gradient-vertical-from-purple-blue-200-to-yellow-400 {
  background: linear-gradient(#c9c9f0, #ffb938);
}

.has-background-gradient-vertical-from-purple-blue-200-to-yellow-500 {
  background: linear-gradient(#c9c9f0, #aa7b25);
}

.has-background-gradient-vertical-from-purple-blue-200-to-pink-100 {
  background: linear-gradient(#c9c9f0, #fbeef0);
}

.has-background-gradient-vertical-from-purple-blue-200-to-pink-200 {
  background: linear-gradient(#c9c9f0, #f2c6cd);
}

.has-background-gradient-vertical-from-purple-blue-200-to-pink-300 {
  background: linear-gradient(#c9c9f0, #e58e9a);
}

.has-background-gradient-vertical-from-purple-blue-200-to-pink-400 {
  background: linear-gradient(#c9c9f0, #d85568);
}

.has-background-gradient-vertical-from-purple-blue-200-to-pink-500 {
  background: linear-gradient(#c9c9f0, #481c23);
}

.has-background-gradient-vertical-from-purple-blue-200-to-grey-100 {
  background: linear-gradient(#c9c9f0, #fff);
}

.has-background-gradient-vertical-from-purple-blue-200-to-grey-200 {
  background: linear-gradient(#c9c9f0, #e2e2e2);
}

.has-background-gradient-vertical-from-purple-blue-200-to-grey-300 {
  background: linear-gradient(#c9c9f0, #a8a8a8);
}

.has-background-gradient-vertical-from-purple-blue-200-to-grey-400 {
  background: linear-gradient(#c9c9f0, #616161);
}

.has-background-gradient-vertical-from-purple-blue-200-to-grey-500 {
  background: linear-gradient(#c9c9f0, #393939);
}

.has-background-gradient-vertical-from-purple-blue-200-to-grey-600 {
  background: linear-gradient(#c9c9f0, #000);
}

.has-background-gradient-vertical-from-purple-blue-300-to-purple-blue-100 {
  background: linear-gradient(#a594f9, #f1f1fb);
}

.has-background-gradient-vertical-from-purple-blue-300-to-purple-blue-200 {
  background: linear-gradient(#a594f9, #c9c9f0);
}

.has-background-gradient-vertical-from-purple-blue-300-to-purple-blue-300 {
  background: linear-gradient(#a594f9, #a594f9);
}

.has-background-gradient-vertical-from-purple-blue-300-to-purple-blue-400 {
  background: linear-gradient(#a594f9, #2727c4);
}

.has-background-gradient-vertical-from-purple-blue-300-to-purple-blue-500 {
  background: linear-gradient(#a594f9, #1b0777);
}

.has-background-gradient-vertical-from-purple-blue-300-to-peach-100 {
  background: linear-gradient(#a594f9, #fdf3f2);
}

.has-background-gradient-vertical-from-purple-blue-300-to-peach-200 {
  background: linear-gradient(#a594f9, #f8d8d4);
}

.has-background-gradient-vertical-from-purple-blue-300-to-peach-300 {
  background: linear-gradient(#a594f9, #f0b0a8);
}

.has-background-gradient-vertical-from-purple-blue-300-to-peach-400 {
  background: linear-gradient(#a594f9, #e9897d);
}

.has-background-gradient-vertical-from-purple-blue-300-to-peach-500 {
  background: linear-gradient(#a594f9, #9b5b53);
}

.has-background-gradient-vertical-from-purple-blue-300-to-yellow-100 {
  background: linear-gradient(#a594f9, #fff8f1);
}

.has-background-gradient-vertical-from-purple-blue-300-to-yellow-200 {
  background: linear-gradient(#a594f9, #ffe8bd);
}

.has-background-gradient-vertical-from-purple-blue-300-to-yellow-300 {
  background: linear-gradient(#a594f9, #ffd07a);
}

.has-background-gradient-vertical-from-purple-blue-300-to-yellow-400 {
  background: linear-gradient(#a594f9, #ffb938);
}

.has-background-gradient-vertical-from-purple-blue-300-to-yellow-500 {
  background: linear-gradient(#a594f9, #aa7b25);
}

.has-background-gradient-vertical-from-purple-blue-300-to-pink-100 {
  background: linear-gradient(#a594f9, #fbeef0);
}

.has-background-gradient-vertical-from-purple-blue-300-to-pink-200 {
  background: linear-gradient(#a594f9, #f2c6cd);
}

.has-background-gradient-vertical-from-purple-blue-300-to-pink-300 {
  background: linear-gradient(#a594f9, #e58e9a);
}

.has-background-gradient-vertical-from-purple-blue-300-to-pink-400 {
  background: linear-gradient(#a594f9, #d85568);
}

.has-background-gradient-vertical-from-purple-blue-300-to-pink-500 {
  background: linear-gradient(#a594f9, #481c23);
}

.has-background-gradient-vertical-from-purple-blue-300-to-grey-100 {
  background: linear-gradient(#a594f9, #fff);
}

.has-background-gradient-vertical-from-purple-blue-300-to-grey-200 {
  background: linear-gradient(#a594f9, #e2e2e2);
}

.has-background-gradient-vertical-from-purple-blue-300-to-grey-300 {
  background: linear-gradient(#a594f9, #a8a8a8);
}

.has-background-gradient-vertical-from-purple-blue-300-to-grey-400 {
  background: linear-gradient(#a594f9, #616161);
}

.has-background-gradient-vertical-from-purple-blue-300-to-grey-500 {
  background: linear-gradient(#a594f9, #393939);
}

.has-background-gradient-vertical-from-purple-blue-300-to-grey-600 {
  background: linear-gradient(#a594f9, #000);
}

.has-background-gradient-vertical-from-purple-blue-400-to-purple-blue-100 {
  background: linear-gradient(#2727c4, #f1f1fb);
}

.has-background-gradient-vertical-from-purple-blue-400-to-purple-blue-200 {
  background: linear-gradient(#2727c4, #c9c9f0);
}

.has-background-gradient-vertical-from-purple-blue-400-to-purple-blue-300 {
  background: linear-gradient(#2727c4, #a594f9);
}

.has-background-gradient-vertical-from-purple-blue-400-to-purple-blue-400 {
  background: linear-gradient(#2727c4, #2727c4);
}

.has-background-gradient-vertical-from-purple-blue-400-to-purple-blue-500 {
  background: linear-gradient(#2727c4, #1b0777);
}

.has-background-gradient-vertical-from-purple-blue-400-to-peach-100 {
  background: linear-gradient(#2727c4, #fdf3f2);
}

.has-background-gradient-vertical-from-purple-blue-400-to-peach-200 {
  background: linear-gradient(#2727c4, #f8d8d4);
}

.has-background-gradient-vertical-from-purple-blue-400-to-peach-300 {
  background: linear-gradient(#2727c4, #f0b0a8);
}

.has-background-gradient-vertical-from-purple-blue-400-to-peach-400 {
  background: linear-gradient(#2727c4, #e9897d);
}

.has-background-gradient-vertical-from-purple-blue-400-to-peach-500 {
  background: linear-gradient(#2727c4, #9b5b53);
}

.has-background-gradient-vertical-from-purple-blue-400-to-yellow-100 {
  background: linear-gradient(#2727c4, #fff8f1);
}

.has-background-gradient-vertical-from-purple-blue-400-to-yellow-200 {
  background: linear-gradient(#2727c4, #ffe8bd);
}

.has-background-gradient-vertical-from-purple-blue-400-to-yellow-300 {
  background: linear-gradient(#2727c4, #ffd07a);
}

.has-background-gradient-vertical-from-purple-blue-400-to-yellow-400 {
  background: linear-gradient(#2727c4, #ffb938);
}

.has-background-gradient-vertical-from-purple-blue-400-to-yellow-500 {
  background: linear-gradient(#2727c4, #aa7b25);
}

.has-background-gradient-vertical-from-purple-blue-400-to-pink-100 {
  background: linear-gradient(#2727c4, #fbeef0);
}

.has-background-gradient-vertical-from-purple-blue-400-to-pink-200 {
  background: linear-gradient(#2727c4, #f2c6cd);
}

.has-background-gradient-vertical-from-purple-blue-400-to-pink-300 {
  background: linear-gradient(#2727c4, #e58e9a);
}

.has-background-gradient-vertical-from-purple-blue-400-to-pink-400 {
  background: linear-gradient(#2727c4, #d85568);
}

.has-background-gradient-vertical-from-purple-blue-400-to-pink-500 {
  background: linear-gradient(#2727c4, #481c23);
}

.has-background-gradient-vertical-from-purple-blue-400-to-grey-100 {
  background: linear-gradient(#2727c4, #fff);
}

.has-background-gradient-vertical-from-purple-blue-400-to-grey-200 {
  background: linear-gradient(#2727c4, #e2e2e2);
}

.has-background-gradient-vertical-from-purple-blue-400-to-grey-300 {
  background: linear-gradient(#2727c4, #a8a8a8);
}

.has-background-gradient-vertical-from-purple-blue-400-to-grey-400 {
  background: linear-gradient(#2727c4, #616161);
}

.has-background-gradient-vertical-from-purple-blue-400-to-grey-500 {
  background: linear-gradient(#2727c4, #393939);
}

.has-background-gradient-vertical-from-purple-blue-400-to-grey-600 {
  background: linear-gradient(#2727c4, #000);
}

.has-background-gradient-vertical-from-purple-blue-500-to-purple-blue-100 {
  background: linear-gradient(#1b0777, #f1f1fb);
}

.has-background-gradient-vertical-from-purple-blue-500-to-purple-blue-200 {
  background: linear-gradient(#1b0777, #c9c9f0);
}

.has-background-gradient-vertical-from-purple-blue-500-to-purple-blue-300 {
  background: linear-gradient(#1b0777, #a594f9);
}

.has-background-gradient-vertical-from-purple-blue-500-to-purple-blue-400 {
  background: linear-gradient(#1b0777, #2727c4);
}

.has-background-gradient-vertical-from-purple-blue-500-to-purple-blue-500 {
  background: linear-gradient(#1b0777, #1b0777);
}

.has-background-gradient-vertical-from-purple-blue-500-to-peach-100 {
  background: linear-gradient(#1b0777, #fdf3f2);
}

.has-background-gradient-vertical-from-purple-blue-500-to-peach-200 {
  background: linear-gradient(#1b0777, #f8d8d4);
}

.has-background-gradient-vertical-from-purple-blue-500-to-peach-300 {
  background: linear-gradient(#1b0777, #f0b0a8);
}

.has-background-gradient-vertical-from-purple-blue-500-to-peach-400 {
  background: linear-gradient(#1b0777, #e9897d);
}

.has-background-gradient-vertical-from-purple-blue-500-to-peach-500 {
  background: linear-gradient(#1b0777, #9b5b53);
}

.has-background-gradient-vertical-from-purple-blue-500-to-yellow-100 {
  background: linear-gradient(#1b0777, #fff8f1);
}

.has-background-gradient-vertical-from-purple-blue-500-to-yellow-200 {
  background: linear-gradient(#1b0777, #ffe8bd);
}

.has-background-gradient-vertical-from-purple-blue-500-to-yellow-300 {
  background: linear-gradient(#1b0777, #ffd07a);
}

.has-background-gradient-vertical-from-purple-blue-500-to-yellow-400 {
  background: linear-gradient(#1b0777, #ffb938);
}

.has-background-gradient-vertical-from-purple-blue-500-to-yellow-500 {
  background: linear-gradient(#1b0777, #aa7b25);
}

.has-background-gradient-vertical-from-purple-blue-500-to-pink-100 {
  background: linear-gradient(#1b0777, #fbeef0);
}

.has-background-gradient-vertical-from-purple-blue-500-to-pink-200 {
  background: linear-gradient(#1b0777, #f2c6cd);
}

.has-background-gradient-vertical-from-purple-blue-500-to-pink-300 {
  background: linear-gradient(#1b0777, #e58e9a);
}

.has-background-gradient-vertical-from-purple-blue-500-to-pink-400 {
  background: linear-gradient(#1b0777, #d85568);
}

.has-background-gradient-vertical-from-purple-blue-500-to-pink-500 {
  background: linear-gradient(#1b0777, #481c23);
}

.has-background-gradient-vertical-from-purple-blue-500-to-grey-100 {
  background: linear-gradient(#1b0777, #fff);
}

.has-background-gradient-vertical-from-purple-blue-500-to-grey-200 {
  background: linear-gradient(#1b0777, #e2e2e2);
}

.has-background-gradient-vertical-from-purple-blue-500-to-grey-300 {
  background: linear-gradient(#1b0777, #a8a8a8);
}

.has-background-gradient-vertical-from-purple-blue-500-to-grey-400 {
  background: linear-gradient(#1b0777, #616161);
}

.has-background-gradient-vertical-from-purple-blue-500-to-grey-500 {
  background: linear-gradient(#1b0777, #393939);
}

.has-background-gradient-vertical-from-purple-blue-500-to-grey-600 {
  background: linear-gradient(#1b0777, #000);
}

.has-background-gradient-vertical-from-peach-100-to-purple-blue-100 {
  background: linear-gradient(#fdf3f2, #f1f1fb);
}

.has-background-gradient-vertical-from-peach-100-to-purple-blue-200 {
  background: linear-gradient(#fdf3f2, #c9c9f0);
}

.has-background-gradient-vertical-from-peach-100-to-purple-blue-300 {
  background: linear-gradient(#fdf3f2, #a594f9);
}

.has-background-gradient-vertical-from-peach-100-to-purple-blue-400 {
  background: linear-gradient(#fdf3f2, #2727c4);
}

.has-background-gradient-vertical-from-peach-100-to-purple-blue-500 {
  background: linear-gradient(#fdf3f2, #1b0777);
}

.has-background-gradient-vertical-from-peach-100-to-peach-100 {
  background: linear-gradient(#fdf3f2, #fdf3f2);
}

.has-background-gradient-vertical-from-peach-100-to-peach-200 {
  background: linear-gradient(#fdf3f2, #f8d8d4);
}

.has-background-gradient-vertical-from-peach-100-to-peach-300 {
  background: linear-gradient(#fdf3f2, #f0b0a8);
}

.has-background-gradient-vertical-from-peach-100-to-peach-400 {
  background: linear-gradient(#fdf3f2, #e9897d);
}

.has-background-gradient-vertical-from-peach-100-to-peach-500 {
  background: linear-gradient(#fdf3f2, #9b5b53);
}

.has-background-gradient-vertical-from-peach-100-to-yellow-100 {
  background: linear-gradient(#fdf3f2, #fff8f1);
}

.has-background-gradient-vertical-from-peach-100-to-yellow-200 {
  background: linear-gradient(#fdf3f2, #ffe8bd);
}

.has-background-gradient-vertical-from-peach-100-to-yellow-300 {
  background: linear-gradient(#fdf3f2, #ffd07a);
}

.has-background-gradient-vertical-from-peach-100-to-yellow-400 {
  background: linear-gradient(#fdf3f2, #ffb938);
}

.has-background-gradient-vertical-from-peach-100-to-yellow-500 {
  background: linear-gradient(#fdf3f2, #aa7b25);
}

.has-background-gradient-vertical-from-peach-100-to-pink-100 {
  background: linear-gradient(#fdf3f2, #fbeef0);
}

.has-background-gradient-vertical-from-peach-100-to-pink-200 {
  background: linear-gradient(#fdf3f2, #f2c6cd);
}

.has-background-gradient-vertical-from-peach-100-to-pink-300 {
  background: linear-gradient(#fdf3f2, #e58e9a);
}

.has-background-gradient-vertical-from-peach-100-to-pink-400 {
  background: linear-gradient(#fdf3f2, #d85568);
}

.has-background-gradient-vertical-from-peach-100-to-pink-500 {
  background: linear-gradient(#fdf3f2, #481c23);
}

.has-background-gradient-vertical-from-peach-100-to-grey-100 {
  background: linear-gradient(#fdf3f2, #fff);
}

.has-background-gradient-vertical-from-peach-100-to-grey-200 {
  background: linear-gradient(#fdf3f2, #e2e2e2);
}

.has-background-gradient-vertical-from-peach-100-to-grey-300 {
  background: linear-gradient(#fdf3f2, #a8a8a8);
}

.has-background-gradient-vertical-from-peach-100-to-grey-400 {
  background: linear-gradient(#fdf3f2, #616161);
}

.has-background-gradient-vertical-from-peach-100-to-grey-500 {
  background: linear-gradient(#fdf3f2, #393939);
}

.has-background-gradient-vertical-from-peach-100-to-grey-600 {
  background: linear-gradient(#fdf3f2, #000);
}

.has-background-gradient-vertical-from-peach-200-to-purple-blue-100 {
  background: linear-gradient(#f8d8d4, #f1f1fb);
}

.has-background-gradient-vertical-from-peach-200-to-purple-blue-200 {
  background: linear-gradient(#f8d8d4, #c9c9f0);
}

.has-background-gradient-vertical-from-peach-200-to-purple-blue-300 {
  background: linear-gradient(#f8d8d4, #a594f9);
}

.has-background-gradient-vertical-from-peach-200-to-purple-blue-400 {
  background: linear-gradient(#f8d8d4, #2727c4);
}

.has-background-gradient-vertical-from-peach-200-to-purple-blue-500 {
  background: linear-gradient(#f8d8d4, #1b0777);
}

.has-background-gradient-vertical-from-peach-200-to-peach-100 {
  background: linear-gradient(#f8d8d4, #fdf3f2);
}

.has-background-gradient-vertical-from-peach-200-to-peach-200 {
  background: linear-gradient(#f8d8d4, #f8d8d4);
}

.has-background-gradient-vertical-from-peach-200-to-peach-300 {
  background: linear-gradient(#f8d8d4, #f0b0a8);
}

.has-background-gradient-vertical-from-peach-200-to-peach-400 {
  background: linear-gradient(#f8d8d4, #e9897d);
}

.has-background-gradient-vertical-from-peach-200-to-peach-500 {
  background: linear-gradient(#f8d8d4, #9b5b53);
}

.has-background-gradient-vertical-from-peach-200-to-yellow-100 {
  background: linear-gradient(#f8d8d4, #fff8f1);
}

.has-background-gradient-vertical-from-peach-200-to-yellow-200 {
  background: linear-gradient(#f8d8d4, #ffe8bd);
}

.has-background-gradient-vertical-from-peach-200-to-yellow-300 {
  background: linear-gradient(#f8d8d4, #ffd07a);
}

.has-background-gradient-vertical-from-peach-200-to-yellow-400 {
  background: linear-gradient(#f8d8d4, #ffb938);
}

.has-background-gradient-vertical-from-peach-200-to-yellow-500 {
  background: linear-gradient(#f8d8d4, #aa7b25);
}

.has-background-gradient-vertical-from-peach-200-to-pink-100 {
  background: linear-gradient(#f8d8d4, #fbeef0);
}

.has-background-gradient-vertical-from-peach-200-to-pink-200 {
  background: linear-gradient(#f8d8d4, #f2c6cd);
}

.has-background-gradient-vertical-from-peach-200-to-pink-300 {
  background: linear-gradient(#f8d8d4, #e58e9a);
}

.has-background-gradient-vertical-from-peach-200-to-pink-400 {
  background: linear-gradient(#f8d8d4, #d85568);
}

.has-background-gradient-vertical-from-peach-200-to-pink-500 {
  background: linear-gradient(#f8d8d4, #481c23);
}

.has-background-gradient-vertical-from-peach-200-to-grey-100 {
  background: linear-gradient(#f8d8d4, #fff);
}

.has-background-gradient-vertical-from-peach-200-to-grey-200 {
  background: linear-gradient(#f8d8d4, #e2e2e2);
}

.has-background-gradient-vertical-from-peach-200-to-grey-300 {
  background: linear-gradient(#f8d8d4, #a8a8a8);
}

.has-background-gradient-vertical-from-peach-200-to-grey-400 {
  background: linear-gradient(#f8d8d4, #616161);
}

.has-background-gradient-vertical-from-peach-200-to-grey-500 {
  background: linear-gradient(#f8d8d4, #393939);
}

.has-background-gradient-vertical-from-peach-200-to-grey-600 {
  background: linear-gradient(#f8d8d4, #000);
}

.has-background-gradient-vertical-from-peach-300-to-purple-blue-100 {
  background: linear-gradient(#f0b0a8, #f1f1fb);
}

.has-background-gradient-vertical-from-peach-300-to-purple-blue-200 {
  background: linear-gradient(#f0b0a8, #c9c9f0);
}

.has-background-gradient-vertical-from-peach-300-to-purple-blue-300 {
  background: linear-gradient(#f0b0a8, #a594f9);
}

.has-background-gradient-vertical-from-peach-300-to-purple-blue-400 {
  background: linear-gradient(#f0b0a8, #2727c4);
}

.has-background-gradient-vertical-from-peach-300-to-purple-blue-500 {
  background: linear-gradient(#f0b0a8, #1b0777);
}

.has-background-gradient-vertical-from-peach-300-to-peach-100 {
  background: linear-gradient(#f0b0a8, #fdf3f2);
}

.has-background-gradient-vertical-from-peach-300-to-peach-200 {
  background: linear-gradient(#f0b0a8, #f8d8d4);
}

.has-background-gradient-vertical-from-peach-300-to-peach-300 {
  background: linear-gradient(#f0b0a8, #f0b0a8);
}

.has-background-gradient-vertical-from-peach-300-to-peach-400 {
  background: linear-gradient(#f0b0a8, #e9897d);
}

.has-background-gradient-vertical-from-peach-300-to-peach-500 {
  background: linear-gradient(#f0b0a8, #9b5b53);
}

.has-background-gradient-vertical-from-peach-300-to-yellow-100 {
  background: linear-gradient(#f0b0a8, #fff8f1);
}

.has-background-gradient-vertical-from-peach-300-to-yellow-200 {
  background: linear-gradient(#f0b0a8, #ffe8bd);
}

.has-background-gradient-vertical-from-peach-300-to-yellow-300 {
  background: linear-gradient(#f0b0a8, #ffd07a);
}

.has-background-gradient-vertical-from-peach-300-to-yellow-400 {
  background: linear-gradient(#f0b0a8, #ffb938);
}

.has-background-gradient-vertical-from-peach-300-to-yellow-500 {
  background: linear-gradient(#f0b0a8, #aa7b25);
}

.has-background-gradient-vertical-from-peach-300-to-pink-100 {
  background: linear-gradient(#f0b0a8, #fbeef0);
}

.has-background-gradient-vertical-from-peach-300-to-pink-200 {
  background: linear-gradient(#f0b0a8, #f2c6cd);
}

.has-background-gradient-vertical-from-peach-300-to-pink-300 {
  background: linear-gradient(#f0b0a8, #e58e9a);
}

.has-background-gradient-vertical-from-peach-300-to-pink-400 {
  background: linear-gradient(#f0b0a8, #d85568);
}

.has-background-gradient-vertical-from-peach-300-to-pink-500 {
  background: linear-gradient(#f0b0a8, #481c23);
}

.has-background-gradient-vertical-from-peach-300-to-grey-100 {
  background: linear-gradient(#f0b0a8, #fff);
}

.has-background-gradient-vertical-from-peach-300-to-grey-200 {
  background: linear-gradient(#f0b0a8, #e2e2e2);
}

.has-background-gradient-vertical-from-peach-300-to-grey-300 {
  background: linear-gradient(#f0b0a8, #a8a8a8);
}

.has-background-gradient-vertical-from-peach-300-to-grey-400 {
  background: linear-gradient(#f0b0a8, #616161);
}

.has-background-gradient-vertical-from-peach-300-to-grey-500 {
  background: linear-gradient(#f0b0a8, #393939);
}

.has-background-gradient-vertical-from-peach-300-to-grey-600 {
  background: linear-gradient(#f0b0a8, #000);
}

.has-background-gradient-vertical-from-peach-400-to-purple-blue-100 {
  background: linear-gradient(#e9897d, #f1f1fb);
}

.has-background-gradient-vertical-from-peach-400-to-purple-blue-200 {
  background: linear-gradient(#e9897d, #c9c9f0);
}

.has-background-gradient-vertical-from-peach-400-to-purple-blue-300 {
  background: linear-gradient(#e9897d, #a594f9);
}

.has-background-gradient-vertical-from-peach-400-to-purple-blue-400 {
  background: linear-gradient(#e9897d, #2727c4);
}

.has-background-gradient-vertical-from-peach-400-to-purple-blue-500 {
  background: linear-gradient(#e9897d, #1b0777);
}

.has-background-gradient-vertical-from-peach-400-to-peach-100 {
  background: linear-gradient(#e9897d, #fdf3f2);
}

.has-background-gradient-vertical-from-peach-400-to-peach-200 {
  background: linear-gradient(#e9897d, #f8d8d4);
}

.has-background-gradient-vertical-from-peach-400-to-peach-300 {
  background: linear-gradient(#e9897d, #f0b0a8);
}

.has-background-gradient-vertical-from-peach-400-to-peach-400 {
  background: linear-gradient(#e9897d, #e9897d);
}

.has-background-gradient-vertical-from-peach-400-to-peach-500 {
  background: linear-gradient(#e9897d, #9b5b53);
}

.has-background-gradient-vertical-from-peach-400-to-yellow-100 {
  background: linear-gradient(#e9897d, #fff8f1);
}

.has-background-gradient-vertical-from-peach-400-to-yellow-200 {
  background: linear-gradient(#e9897d, #ffe8bd);
}

.has-background-gradient-vertical-from-peach-400-to-yellow-300 {
  background: linear-gradient(#e9897d, #ffd07a);
}

.has-background-gradient-vertical-from-peach-400-to-yellow-400 {
  background: linear-gradient(#e9897d, #ffb938);
}

.has-background-gradient-vertical-from-peach-400-to-yellow-500 {
  background: linear-gradient(#e9897d, #aa7b25);
}

.has-background-gradient-vertical-from-peach-400-to-pink-100 {
  background: linear-gradient(#e9897d, #fbeef0);
}

.has-background-gradient-vertical-from-peach-400-to-pink-200 {
  background: linear-gradient(#e9897d, #f2c6cd);
}

.has-background-gradient-vertical-from-peach-400-to-pink-300 {
  background: linear-gradient(#e9897d, #e58e9a);
}

.has-background-gradient-vertical-from-peach-400-to-pink-400 {
  background: linear-gradient(#e9897d, #d85568);
}

.has-background-gradient-vertical-from-peach-400-to-pink-500 {
  background: linear-gradient(#e9897d, #481c23);
}

.has-background-gradient-vertical-from-peach-400-to-grey-100 {
  background: linear-gradient(#e9897d, #fff);
}

.has-background-gradient-vertical-from-peach-400-to-grey-200 {
  background: linear-gradient(#e9897d, #e2e2e2);
}

.has-background-gradient-vertical-from-peach-400-to-grey-300 {
  background: linear-gradient(#e9897d, #a8a8a8);
}

.has-background-gradient-vertical-from-peach-400-to-grey-400 {
  background: linear-gradient(#e9897d, #616161);
}

.has-background-gradient-vertical-from-peach-400-to-grey-500 {
  background: linear-gradient(#e9897d, #393939);
}

.has-background-gradient-vertical-from-peach-400-to-grey-600 {
  background: linear-gradient(#e9897d, #000);
}

.has-background-gradient-vertical-from-peach-500-to-purple-blue-100 {
  background: linear-gradient(#9b5b53, #f1f1fb);
}

.has-background-gradient-vertical-from-peach-500-to-purple-blue-200 {
  background: linear-gradient(#9b5b53, #c9c9f0);
}

.has-background-gradient-vertical-from-peach-500-to-purple-blue-300 {
  background: linear-gradient(#9b5b53, #a594f9);
}

.has-background-gradient-vertical-from-peach-500-to-purple-blue-400 {
  background: linear-gradient(#9b5b53, #2727c4);
}

.has-background-gradient-vertical-from-peach-500-to-purple-blue-500 {
  background: linear-gradient(#9b5b53, #1b0777);
}

.has-background-gradient-vertical-from-peach-500-to-peach-100 {
  background: linear-gradient(#9b5b53, #fdf3f2);
}

.has-background-gradient-vertical-from-peach-500-to-peach-200 {
  background: linear-gradient(#9b5b53, #f8d8d4);
}

.has-background-gradient-vertical-from-peach-500-to-peach-300 {
  background: linear-gradient(#9b5b53, #f0b0a8);
}

.has-background-gradient-vertical-from-peach-500-to-peach-400 {
  background: linear-gradient(#9b5b53, #e9897d);
}

.has-background-gradient-vertical-from-peach-500-to-peach-500 {
  background: linear-gradient(#9b5b53, #9b5b53);
}

.has-background-gradient-vertical-from-peach-500-to-yellow-100 {
  background: linear-gradient(#9b5b53, #fff8f1);
}

.has-background-gradient-vertical-from-peach-500-to-yellow-200 {
  background: linear-gradient(#9b5b53, #ffe8bd);
}

.has-background-gradient-vertical-from-peach-500-to-yellow-300 {
  background: linear-gradient(#9b5b53, #ffd07a);
}

.has-background-gradient-vertical-from-peach-500-to-yellow-400 {
  background: linear-gradient(#9b5b53, #ffb938);
}

.has-background-gradient-vertical-from-peach-500-to-yellow-500 {
  background: linear-gradient(#9b5b53, #aa7b25);
}

.has-background-gradient-vertical-from-peach-500-to-pink-100 {
  background: linear-gradient(#9b5b53, #fbeef0);
}

.has-background-gradient-vertical-from-peach-500-to-pink-200 {
  background: linear-gradient(#9b5b53, #f2c6cd);
}

.has-background-gradient-vertical-from-peach-500-to-pink-300 {
  background: linear-gradient(#9b5b53, #e58e9a);
}

.has-background-gradient-vertical-from-peach-500-to-pink-400 {
  background: linear-gradient(#9b5b53, #d85568);
}

.has-background-gradient-vertical-from-peach-500-to-pink-500 {
  background: linear-gradient(#9b5b53, #481c23);
}

.has-background-gradient-vertical-from-peach-500-to-grey-100 {
  background: linear-gradient(#9b5b53, #fff);
}

.has-background-gradient-vertical-from-peach-500-to-grey-200 {
  background: linear-gradient(#9b5b53, #e2e2e2);
}

.has-background-gradient-vertical-from-peach-500-to-grey-300 {
  background: linear-gradient(#9b5b53, #a8a8a8);
}

.has-background-gradient-vertical-from-peach-500-to-grey-400 {
  background: linear-gradient(#9b5b53, #616161);
}

.has-background-gradient-vertical-from-peach-500-to-grey-500 {
  background: linear-gradient(#9b5b53, #393939);
}

.has-background-gradient-vertical-from-peach-500-to-grey-600 {
  background: linear-gradient(#9b5b53, #000);
}

.has-background-gradient-vertical-from-yellow-100-to-purple-blue-100 {
  background: linear-gradient(#fff8f1, #f1f1fb);
}

.has-background-gradient-vertical-from-yellow-100-to-purple-blue-200 {
  background: linear-gradient(#fff8f1, #c9c9f0);
}

.has-background-gradient-vertical-from-yellow-100-to-purple-blue-300 {
  background: linear-gradient(#fff8f1, #a594f9);
}

.has-background-gradient-vertical-from-yellow-100-to-purple-blue-400 {
  background: linear-gradient(#fff8f1, #2727c4);
}

.has-background-gradient-vertical-from-yellow-100-to-purple-blue-500 {
  background: linear-gradient(#fff8f1, #1b0777);
}

.has-background-gradient-vertical-from-yellow-100-to-peach-100 {
  background: linear-gradient(#fff8f1, #fdf3f2);
}

.has-background-gradient-vertical-from-yellow-100-to-peach-200 {
  background: linear-gradient(#fff8f1, #f8d8d4);
}

.has-background-gradient-vertical-from-yellow-100-to-peach-300 {
  background: linear-gradient(#fff8f1, #f0b0a8);
}

.has-background-gradient-vertical-from-yellow-100-to-peach-400 {
  background: linear-gradient(#fff8f1, #e9897d);
}

.has-background-gradient-vertical-from-yellow-100-to-peach-500 {
  background: linear-gradient(#fff8f1, #9b5b53);
}

.has-background-gradient-vertical-from-yellow-100-to-yellow-100 {
  background: linear-gradient(#fff8f1, #fff8f1);
}

.has-background-gradient-vertical-from-yellow-100-to-yellow-200 {
  background: linear-gradient(#fff8f1, #ffe8bd);
}

.has-background-gradient-vertical-from-yellow-100-to-yellow-300 {
  background: linear-gradient(#fff8f1, #ffd07a);
}

.has-background-gradient-vertical-from-yellow-100-to-yellow-400 {
  background: linear-gradient(#fff8f1, #ffb938);
}

.has-background-gradient-vertical-from-yellow-100-to-yellow-500 {
  background: linear-gradient(#fff8f1, #aa7b25);
}

.has-background-gradient-vertical-from-yellow-100-to-pink-100 {
  background: linear-gradient(#fff8f1, #fbeef0);
}

.has-background-gradient-vertical-from-yellow-100-to-pink-200 {
  background: linear-gradient(#fff8f1, #f2c6cd);
}

.has-background-gradient-vertical-from-yellow-100-to-pink-300 {
  background: linear-gradient(#fff8f1, #e58e9a);
}

.has-background-gradient-vertical-from-yellow-100-to-pink-400 {
  background: linear-gradient(#fff8f1, #d85568);
}

.has-background-gradient-vertical-from-yellow-100-to-pink-500 {
  background: linear-gradient(#fff8f1, #481c23);
}

.has-background-gradient-vertical-from-yellow-100-to-grey-100 {
  background: linear-gradient(#fff8f1, #fff);
}

.has-background-gradient-vertical-from-yellow-100-to-grey-200 {
  background: linear-gradient(#fff8f1, #e2e2e2);
}

.has-background-gradient-vertical-from-yellow-100-to-grey-300 {
  background: linear-gradient(#fff8f1, #a8a8a8);
}

.has-background-gradient-vertical-from-yellow-100-to-grey-400 {
  background: linear-gradient(#fff8f1, #616161);
}

.has-background-gradient-vertical-from-yellow-100-to-grey-500 {
  background: linear-gradient(#fff8f1, #393939);
}

.has-background-gradient-vertical-from-yellow-100-to-grey-600 {
  background: linear-gradient(#fff8f1, #000);
}

.has-background-gradient-vertical-from-yellow-200-to-purple-blue-100 {
  background: linear-gradient(#ffe8bd, #f1f1fb);
}

.has-background-gradient-vertical-from-yellow-200-to-purple-blue-200 {
  background: linear-gradient(#ffe8bd, #c9c9f0);
}

.has-background-gradient-vertical-from-yellow-200-to-purple-blue-300 {
  background: linear-gradient(#ffe8bd, #a594f9);
}

.has-background-gradient-vertical-from-yellow-200-to-purple-blue-400 {
  background: linear-gradient(#ffe8bd, #2727c4);
}

.has-background-gradient-vertical-from-yellow-200-to-purple-blue-500 {
  background: linear-gradient(#ffe8bd, #1b0777);
}

.has-background-gradient-vertical-from-yellow-200-to-peach-100 {
  background: linear-gradient(#ffe8bd, #fdf3f2);
}

.has-background-gradient-vertical-from-yellow-200-to-peach-200 {
  background: linear-gradient(#ffe8bd, #f8d8d4);
}

.has-background-gradient-vertical-from-yellow-200-to-peach-300 {
  background: linear-gradient(#ffe8bd, #f0b0a8);
}

.has-background-gradient-vertical-from-yellow-200-to-peach-400 {
  background: linear-gradient(#ffe8bd, #e9897d);
}

.has-background-gradient-vertical-from-yellow-200-to-peach-500 {
  background: linear-gradient(#ffe8bd, #9b5b53);
}

.has-background-gradient-vertical-from-yellow-200-to-yellow-100 {
  background: linear-gradient(#ffe8bd, #fff8f1);
}

.has-background-gradient-vertical-from-yellow-200-to-yellow-200 {
  background: linear-gradient(#ffe8bd, #ffe8bd);
}

.has-background-gradient-vertical-from-yellow-200-to-yellow-300 {
  background: linear-gradient(#ffe8bd, #ffd07a);
}

.has-background-gradient-vertical-from-yellow-200-to-yellow-400 {
  background: linear-gradient(#ffe8bd, #ffb938);
}

.has-background-gradient-vertical-from-yellow-200-to-yellow-500 {
  background: linear-gradient(#ffe8bd, #aa7b25);
}

.has-background-gradient-vertical-from-yellow-200-to-pink-100 {
  background: linear-gradient(#ffe8bd, #fbeef0);
}

.has-background-gradient-vertical-from-yellow-200-to-pink-200 {
  background: linear-gradient(#ffe8bd, #f2c6cd);
}

.has-background-gradient-vertical-from-yellow-200-to-pink-300 {
  background: linear-gradient(#ffe8bd, #e58e9a);
}

.has-background-gradient-vertical-from-yellow-200-to-pink-400 {
  background: linear-gradient(#ffe8bd, #d85568);
}

.has-background-gradient-vertical-from-yellow-200-to-pink-500 {
  background: linear-gradient(#ffe8bd, #481c23);
}

.has-background-gradient-vertical-from-yellow-200-to-grey-100 {
  background: linear-gradient(#ffe8bd, #fff);
}

.has-background-gradient-vertical-from-yellow-200-to-grey-200 {
  background: linear-gradient(#ffe8bd, #e2e2e2);
}

.has-background-gradient-vertical-from-yellow-200-to-grey-300 {
  background: linear-gradient(#ffe8bd, #a8a8a8);
}

.has-background-gradient-vertical-from-yellow-200-to-grey-400 {
  background: linear-gradient(#ffe8bd, #616161);
}

.has-background-gradient-vertical-from-yellow-200-to-grey-500 {
  background: linear-gradient(#ffe8bd, #393939);
}

.has-background-gradient-vertical-from-yellow-200-to-grey-600 {
  background: linear-gradient(#ffe8bd, #000);
}

.has-background-gradient-vertical-from-yellow-300-to-purple-blue-100 {
  background: linear-gradient(#ffd07a, #f1f1fb);
}

.has-background-gradient-vertical-from-yellow-300-to-purple-blue-200 {
  background: linear-gradient(#ffd07a, #c9c9f0);
}

.has-background-gradient-vertical-from-yellow-300-to-purple-blue-300 {
  background: linear-gradient(#ffd07a, #a594f9);
}

.has-background-gradient-vertical-from-yellow-300-to-purple-blue-400 {
  background: linear-gradient(#ffd07a, #2727c4);
}

.has-background-gradient-vertical-from-yellow-300-to-purple-blue-500 {
  background: linear-gradient(#ffd07a, #1b0777);
}

.has-background-gradient-vertical-from-yellow-300-to-peach-100 {
  background: linear-gradient(#ffd07a, #fdf3f2);
}

.has-background-gradient-vertical-from-yellow-300-to-peach-200 {
  background: linear-gradient(#ffd07a, #f8d8d4);
}

.has-background-gradient-vertical-from-yellow-300-to-peach-300 {
  background: linear-gradient(#ffd07a, #f0b0a8);
}

.has-background-gradient-vertical-from-yellow-300-to-peach-400 {
  background: linear-gradient(#ffd07a, #e9897d);
}

.has-background-gradient-vertical-from-yellow-300-to-peach-500 {
  background: linear-gradient(#ffd07a, #9b5b53);
}

.has-background-gradient-vertical-from-yellow-300-to-yellow-100 {
  background: linear-gradient(#ffd07a, #fff8f1);
}

.has-background-gradient-vertical-from-yellow-300-to-yellow-200 {
  background: linear-gradient(#ffd07a, #ffe8bd);
}

.has-background-gradient-vertical-from-yellow-300-to-yellow-300 {
  background: linear-gradient(#ffd07a, #ffd07a);
}

.has-background-gradient-vertical-from-yellow-300-to-yellow-400 {
  background: linear-gradient(#ffd07a, #ffb938);
}

.has-background-gradient-vertical-from-yellow-300-to-yellow-500 {
  background: linear-gradient(#ffd07a, #aa7b25);
}

.has-background-gradient-vertical-from-yellow-300-to-pink-100 {
  background: linear-gradient(#ffd07a, #fbeef0);
}

.has-background-gradient-vertical-from-yellow-300-to-pink-200 {
  background: linear-gradient(#ffd07a, #f2c6cd);
}

.has-background-gradient-vertical-from-yellow-300-to-pink-300 {
  background: linear-gradient(#ffd07a, #e58e9a);
}

.has-background-gradient-vertical-from-yellow-300-to-pink-400 {
  background: linear-gradient(#ffd07a, #d85568);
}

.has-background-gradient-vertical-from-yellow-300-to-pink-500 {
  background: linear-gradient(#ffd07a, #481c23);
}

.has-background-gradient-vertical-from-yellow-300-to-grey-100 {
  background: linear-gradient(#ffd07a, #fff);
}

.has-background-gradient-vertical-from-yellow-300-to-grey-200 {
  background: linear-gradient(#ffd07a, #e2e2e2);
}

.has-background-gradient-vertical-from-yellow-300-to-grey-300 {
  background: linear-gradient(#ffd07a, #a8a8a8);
}

.has-background-gradient-vertical-from-yellow-300-to-grey-400 {
  background: linear-gradient(#ffd07a, #616161);
}

.has-background-gradient-vertical-from-yellow-300-to-grey-500 {
  background: linear-gradient(#ffd07a, #393939);
}

.has-background-gradient-vertical-from-yellow-300-to-grey-600 {
  background: linear-gradient(#ffd07a, #000);
}

.has-background-gradient-vertical-from-yellow-400-to-purple-blue-100 {
  background: linear-gradient(#ffb938, #f1f1fb);
}

.has-background-gradient-vertical-from-yellow-400-to-purple-blue-200 {
  background: linear-gradient(#ffb938, #c9c9f0);
}

.has-background-gradient-vertical-from-yellow-400-to-purple-blue-300 {
  background: linear-gradient(#ffb938, #a594f9);
}

.has-background-gradient-vertical-from-yellow-400-to-purple-blue-400 {
  background: linear-gradient(#ffb938, #2727c4);
}

.has-background-gradient-vertical-from-yellow-400-to-purple-blue-500 {
  background: linear-gradient(#ffb938, #1b0777);
}

.has-background-gradient-vertical-from-yellow-400-to-peach-100 {
  background: linear-gradient(#ffb938, #fdf3f2);
}

.has-background-gradient-vertical-from-yellow-400-to-peach-200 {
  background: linear-gradient(#ffb938, #f8d8d4);
}

.has-background-gradient-vertical-from-yellow-400-to-peach-300 {
  background: linear-gradient(#ffb938, #f0b0a8);
}

.has-background-gradient-vertical-from-yellow-400-to-peach-400 {
  background: linear-gradient(#ffb938, #e9897d);
}

.has-background-gradient-vertical-from-yellow-400-to-peach-500 {
  background: linear-gradient(#ffb938, #9b5b53);
}

.has-background-gradient-vertical-from-yellow-400-to-yellow-100 {
  background: linear-gradient(#ffb938, #fff8f1);
}

.has-background-gradient-vertical-from-yellow-400-to-yellow-200 {
  background: linear-gradient(#ffb938, #ffe8bd);
}

.has-background-gradient-vertical-from-yellow-400-to-yellow-300 {
  background: linear-gradient(#ffb938, #ffd07a);
}

.has-background-gradient-vertical-from-yellow-400-to-yellow-400 {
  background: linear-gradient(#ffb938, #ffb938);
}

.has-background-gradient-vertical-from-yellow-400-to-yellow-500 {
  background: linear-gradient(#ffb938, #aa7b25);
}

.has-background-gradient-vertical-from-yellow-400-to-pink-100 {
  background: linear-gradient(#ffb938, #fbeef0);
}

.has-background-gradient-vertical-from-yellow-400-to-pink-200 {
  background: linear-gradient(#ffb938, #f2c6cd);
}

.has-background-gradient-vertical-from-yellow-400-to-pink-300 {
  background: linear-gradient(#ffb938, #e58e9a);
}

.has-background-gradient-vertical-from-yellow-400-to-pink-400 {
  background: linear-gradient(#ffb938, #d85568);
}

.has-background-gradient-vertical-from-yellow-400-to-pink-500 {
  background: linear-gradient(#ffb938, #481c23);
}

.has-background-gradient-vertical-from-yellow-400-to-grey-100 {
  background: linear-gradient(#ffb938, #fff);
}

.has-background-gradient-vertical-from-yellow-400-to-grey-200 {
  background: linear-gradient(#ffb938, #e2e2e2);
}

.has-background-gradient-vertical-from-yellow-400-to-grey-300 {
  background: linear-gradient(#ffb938, #a8a8a8);
}

.has-background-gradient-vertical-from-yellow-400-to-grey-400 {
  background: linear-gradient(#ffb938, #616161);
}

.has-background-gradient-vertical-from-yellow-400-to-grey-500 {
  background: linear-gradient(#ffb938, #393939);
}

.has-background-gradient-vertical-from-yellow-400-to-grey-600 {
  background: linear-gradient(#ffb938, #000);
}

.has-background-gradient-vertical-from-yellow-500-to-purple-blue-100 {
  background: linear-gradient(#aa7b25, #f1f1fb);
}

.has-background-gradient-vertical-from-yellow-500-to-purple-blue-200 {
  background: linear-gradient(#aa7b25, #c9c9f0);
}

.has-background-gradient-vertical-from-yellow-500-to-purple-blue-300 {
  background: linear-gradient(#aa7b25, #a594f9);
}

.has-background-gradient-vertical-from-yellow-500-to-purple-blue-400 {
  background: linear-gradient(#aa7b25, #2727c4);
}

.has-background-gradient-vertical-from-yellow-500-to-purple-blue-500 {
  background: linear-gradient(#aa7b25, #1b0777);
}

.has-background-gradient-vertical-from-yellow-500-to-peach-100 {
  background: linear-gradient(#aa7b25, #fdf3f2);
}

.has-background-gradient-vertical-from-yellow-500-to-peach-200 {
  background: linear-gradient(#aa7b25, #f8d8d4);
}

.has-background-gradient-vertical-from-yellow-500-to-peach-300 {
  background: linear-gradient(#aa7b25, #f0b0a8);
}

.has-background-gradient-vertical-from-yellow-500-to-peach-400 {
  background: linear-gradient(#aa7b25, #e9897d);
}

.has-background-gradient-vertical-from-yellow-500-to-peach-500 {
  background: linear-gradient(#aa7b25, #9b5b53);
}

.has-background-gradient-vertical-from-yellow-500-to-yellow-100 {
  background: linear-gradient(#aa7b25, #fff8f1);
}

.has-background-gradient-vertical-from-yellow-500-to-yellow-200 {
  background: linear-gradient(#aa7b25, #ffe8bd);
}

.has-background-gradient-vertical-from-yellow-500-to-yellow-300 {
  background: linear-gradient(#aa7b25, #ffd07a);
}

.has-background-gradient-vertical-from-yellow-500-to-yellow-400 {
  background: linear-gradient(#aa7b25, #ffb938);
}

.has-background-gradient-vertical-from-yellow-500-to-yellow-500 {
  background: linear-gradient(#aa7b25, #aa7b25);
}

.has-background-gradient-vertical-from-yellow-500-to-pink-100 {
  background: linear-gradient(#aa7b25, #fbeef0);
}

.has-background-gradient-vertical-from-yellow-500-to-pink-200 {
  background: linear-gradient(#aa7b25, #f2c6cd);
}

.has-background-gradient-vertical-from-yellow-500-to-pink-300 {
  background: linear-gradient(#aa7b25, #e58e9a);
}

.has-background-gradient-vertical-from-yellow-500-to-pink-400 {
  background: linear-gradient(#aa7b25, #d85568);
}

.has-background-gradient-vertical-from-yellow-500-to-pink-500 {
  background: linear-gradient(#aa7b25, #481c23);
}

.has-background-gradient-vertical-from-yellow-500-to-grey-100 {
  background: linear-gradient(#aa7b25, #fff);
}

.has-background-gradient-vertical-from-yellow-500-to-grey-200 {
  background: linear-gradient(#aa7b25, #e2e2e2);
}

.has-background-gradient-vertical-from-yellow-500-to-grey-300 {
  background: linear-gradient(#aa7b25, #a8a8a8);
}

.has-background-gradient-vertical-from-yellow-500-to-grey-400 {
  background: linear-gradient(#aa7b25, #616161);
}

.has-background-gradient-vertical-from-yellow-500-to-grey-500 {
  background: linear-gradient(#aa7b25, #393939);
}

.has-background-gradient-vertical-from-yellow-500-to-grey-600 {
  background: linear-gradient(#aa7b25, #000);
}

.has-background-gradient-vertical-from-pink-100-to-purple-blue-100 {
  background: linear-gradient(#fbeef0, #f1f1fb);
}

.has-background-gradient-vertical-from-pink-100-to-purple-blue-200 {
  background: linear-gradient(#fbeef0, #c9c9f0);
}

.has-background-gradient-vertical-from-pink-100-to-purple-blue-300 {
  background: linear-gradient(#fbeef0, #a594f9);
}

.has-background-gradient-vertical-from-pink-100-to-purple-blue-400 {
  background: linear-gradient(#fbeef0, #2727c4);
}

.has-background-gradient-vertical-from-pink-100-to-purple-blue-500 {
  background: linear-gradient(#fbeef0, #1b0777);
}

.has-background-gradient-vertical-from-pink-100-to-peach-100 {
  background: linear-gradient(#fbeef0, #fdf3f2);
}

.has-background-gradient-vertical-from-pink-100-to-peach-200 {
  background: linear-gradient(#fbeef0, #f8d8d4);
}

.has-background-gradient-vertical-from-pink-100-to-peach-300 {
  background: linear-gradient(#fbeef0, #f0b0a8);
}

.has-background-gradient-vertical-from-pink-100-to-peach-400 {
  background: linear-gradient(#fbeef0, #e9897d);
}

.has-background-gradient-vertical-from-pink-100-to-peach-500 {
  background: linear-gradient(#fbeef0, #9b5b53);
}

.has-background-gradient-vertical-from-pink-100-to-yellow-100 {
  background: linear-gradient(#fbeef0, #fff8f1);
}

.has-background-gradient-vertical-from-pink-100-to-yellow-200 {
  background: linear-gradient(#fbeef0, #ffe8bd);
}

.has-background-gradient-vertical-from-pink-100-to-yellow-300 {
  background: linear-gradient(#fbeef0, #ffd07a);
}

.has-background-gradient-vertical-from-pink-100-to-yellow-400 {
  background: linear-gradient(#fbeef0, #ffb938);
}

.has-background-gradient-vertical-from-pink-100-to-yellow-500 {
  background: linear-gradient(#fbeef0, #aa7b25);
}

.has-background-gradient-vertical-from-pink-100-to-pink-100 {
  background: linear-gradient(#fbeef0, #fbeef0);
}

.has-background-gradient-vertical-from-pink-100-to-pink-200 {
  background: linear-gradient(#fbeef0, #f2c6cd);
}

.has-background-gradient-vertical-from-pink-100-to-pink-300 {
  background: linear-gradient(#fbeef0, #e58e9a);
}

.has-background-gradient-vertical-from-pink-100-to-pink-400 {
  background: linear-gradient(#fbeef0, #d85568);
}

.has-background-gradient-vertical-from-pink-100-to-pink-500 {
  background: linear-gradient(#fbeef0, #481c23);
}

.has-background-gradient-vertical-from-pink-100-to-grey-100 {
  background: linear-gradient(#fbeef0, #fff);
}

.has-background-gradient-vertical-from-pink-100-to-grey-200 {
  background: linear-gradient(#fbeef0, #e2e2e2);
}

.has-background-gradient-vertical-from-pink-100-to-grey-300 {
  background: linear-gradient(#fbeef0, #a8a8a8);
}

.has-background-gradient-vertical-from-pink-100-to-grey-400 {
  background: linear-gradient(#fbeef0, #616161);
}

.has-background-gradient-vertical-from-pink-100-to-grey-500 {
  background: linear-gradient(#fbeef0, #393939);
}

.has-background-gradient-vertical-from-pink-100-to-grey-600 {
  background: linear-gradient(#fbeef0, #000);
}

.has-background-gradient-vertical-from-pink-200-to-purple-blue-100 {
  background: linear-gradient(#f2c6cd, #f1f1fb);
}

.has-background-gradient-vertical-from-pink-200-to-purple-blue-200 {
  background: linear-gradient(#f2c6cd, #c9c9f0);
}

.has-background-gradient-vertical-from-pink-200-to-purple-blue-300 {
  background: linear-gradient(#f2c6cd, #a594f9);
}

.has-background-gradient-vertical-from-pink-200-to-purple-blue-400 {
  background: linear-gradient(#f2c6cd, #2727c4);
}

.has-background-gradient-vertical-from-pink-200-to-purple-blue-500 {
  background: linear-gradient(#f2c6cd, #1b0777);
}

.has-background-gradient-vertical-from-pink-200-to-peach-100 {
  background: linear-gradient(#f2c6cd, #fdf3f2);
}

.has-background-gradient-vertical-from-pink-200-to-peach-200 {
  background: linear-gradient(#f2c6cd, #f8d8d4);
}

.has-background-gradient-vertical-from-pink-200-to-peach-300 {
  background: linear-gradient(#f2c6cd, #f0b0a8);
}

.has-background-gradient-vertical-from-pink-200-to-peach-400 {
  background: linear-gradient(#f2c6cd, #e9897d);
}

.has-background-gradient-vertical-from-pink-200-to-peach-500 {
  background: linear-gradient(#f2c6cd, #9b5b53);
}

.has-background-gradient-vertical-from-pink-200-to-yellow-100 {
  background: linear-gradient(#f2c6cd, #fff8f1);
}

.has-background-gradient-vertical-from-pink-200-to-yellow-200 {
  background: linear-gradient(#f2c6cd, #ffe8bd);
}

.has-background-gradient-vertical-from-pink-200-to-yellow-300 {
  background: linear-gradient(#f2c6cd, #ffd07a);
}

.has-background-gradient-vertical-from-pink-200-to-yellow-400 {
  background: linear-gradient(#f2c6cd, #ffb938);
}

.has-background-gradient-vertical-from-pink-200-to-yellow-500 {
  background: linear-gradient(#f2c6cd, #aa7b25);
}

.has-background-gradient-vertical-from-pink-200-to-pink-100 {
  background: linear-gradient(#f2c6cd, #fbeef0);
}

.has-background-gradient-vertical-from-pink-200-to-pink-200 {
  background: linear-gradient(#f2c6cd, #f2c6cd);
}

.has-background-gradient-vertical-from-pink-200-to-pink-300 {
  background: linear-gradient(#f2c6cd, #e58e9a);
}

.has-background-gradient-vertical-from-pink-200-to-pink-400 {
  background: linear-gradient(#f2c6cd, #d85568);
}

.has-background-gradient-vertical-from-pink-200-to-pink-500 {
  background: linear-gradient(#f2c6cd, #481c23);
}

.has-background-gradient-vertical-from-pink-200-to-grey-100 {
  background: linear-gradient(#f2c6cd, #fff);
}

.has-background-gradient-vertical-from-pink-200-to-grey-200 {
  background: linear-gradient(#f2c6cd, #e2e2e2);
}

.has-background-gradient-vertical-from-pink-200-to-grey-300 {
  background: linear-gradient(#f2c6cd, #a8a8a8);
}

.has-background-gradient-vertical-from-pink-200-to-grey-400 {
  background: linear-gradient(#f2c6cd, #616161);
}

.has-background-gradient-vertical-from-pink-200-to-grey-500 {
  background: linear-gradient(#f2c6cd, #393939);
}

.has-background-gradient-vertical-from-pink-200-to-grey-600 {
  background: linear-gradient(#f2c6cd, #000);
}

.has-background-gradient-vertical-from-pink-300-to-purple-blue-100 {
  background: linear-gradient(#e58e9a, #f1f1fb);
}

.has-background-gradient-vertical-from-pink-300-to-purple-blue-200 {
  background: linear-gradient(#e58e9a, #c9c9f0);
}

.has-background-gradient-vertical-from-pink-300-to-purple-blue-300 {
  background: linear-gradient(#e58e9a, #a594f9);
}

.has-background-gradient-vertical-from-pink-300-to-purple-blue-400 {
  background: linear-gradient(#e58e9a, #2727c4);
}

.has-background-gradient-vertical-from-pink-300-to-purple-blue-500 {
  background: linear-gradient(#e58e9a, #1b0777);
}

.has-background-gradient-vertical-from-pink-300-to-peach-100 {
  background: linear-gradient(#e58e9a, #fdf3f2);
}

.has-background-gradient-vertical-from-pink-300-to-peach-200 {
  background: linear-gradient(#e58e9a, #f8d8d4);
}

.has-background-gradient-vertical-from-pink-300-to-peach-300 {
  background: linear-gradient(#e58e9a, #f0b0a8);
}

.has-background-gradient-vertical-from-pink-300-to-peach-400 {
  background: linear-gradient(#e58e9a, #e9897d);
}

.has-background-gradient-vertical-from-pink-300-to-peach-500 {
  background: linear-gradient(#e58e9a, #9b5b53);
}

.has-background-gradient-vertical-from-pink-300-to-yellow-100 {
  background: linear-gradient(#e58e9a, #fff8f1);
}

.has-background-gradient-vertical-from-pink-300-to-yellow-200 {
  background: linear-gradient(#e58e9a, #ffe8bd);
}

.has-background-gradient-vertical-from-pink-300-to-yellow-300 {
  background: linear-gradient(#e58e9a, #ffd07a);
}

.has-background-gradient-vertical-from-pink-300-to-yellow-400 {
  background: linear-gradient(#e58e9a, #ffb938);
}

.has-background-gradient-vertical-from-pink-300-to-yellow-500 {
  background: linear-gradient(#e58e9a, #aa7b25);
}

.has-background-gradient-vertical-from-pink-300-to-pink-100 {
  background: linear-gradient(#e58e9a, #fbeef0);
}

.has-background-gradient-vertical-from-pink-300-to-pink-200 {
  background: linear-gradient(#e58e9a, #f2c6cd);
}

.has-background-gradient-vertical-from-pink-300-to-pink-300 {
  background: linear-gradient(#e58e9a, #e58e9a);
}

.has-background-gradient-vertical-from-pink-300-to-pink-400 {
  background: linear-gradient(#e58e9a, #d85568);
}

.has-background-gradient-vertical-from-pink-300-to-pink-500 {
  background: linear-gradient(#e58e9a, #481c23);
}

.has-background-gradient-vertical-from-pink-300-to-grey-100 {
  background: linear-gradient(#e58e9a, #fff);
}

.has-background-gradient-vertical-from-pink-300-to-grey-200 {
  background: linear-gradient(#e58e9a, #e2e2e2);
}

.has-background-gradient-vertical-from-pink-300-to-grey-300 {
  background: linear-gradient(#e58e9a, #a8a8a8);
}

.has-background-gradient-vertical-from-pink-300-to-grey-400 {
  background: linear-gradient(#e58e9a, #616161);
}

.has-background-gradient-vertical-from-pink-300-to-grey-500 {
  background: linear-gradient(#e58e9a, #393939);
}

.has-background-gradient-vertical-from-pink-300-to-grey-600 {
  background: linear-gradient(#e58e9a, #000);
}

.has-background-gradient-vertical-from-pink-400-to-purple-blue-100 {
  background: linear-gradient(#d85568, #f1f1fb);
}

.has-background-gradient-vertical-from-pink-400-to-purple-blue-200 {
  background: linear-gradient(#d85568, #c9c9f0);
}

.has-background-gradient-vertical-from-pink-400-to-purple-blue-300 {
  background: linear-gradient(#d85568, #a594f9);
}

.has-background-gradient-vertical-from-pink-400-to-purple-blue-400 {
  background: linear-gradient(#d85568, #2727c4);
}

.has-background-gradient-vertical-from-pink-400-to-purple-blue-500 {
  background: linear-gradient(#d85568, #1b0777);
}

.has-background-gradient-vertical-from-pink-400-to-peach-100 {
  background: linear-gradient(#d85568, #fdf3f2);
}

.has-background-gradient-vertical-from-pink-400-to-peach-200 {
  background: linear-gradient(#d85568, #f8d8d4);
}

.has-background-gradient-vertical-from-pink-400-to-peach-300 {
  background: linear-gradient(#d85568, #f0b0a8);
}

.has-background-gradient-vertical-from-pink-400-to-peach-400 {
  background: linear-gradient(#d85568, #e9897d);
}

.has-background-gradient-vertical-from-pink-400-to-peach-500 {
  background: linear-gradient(#d85568, #9b5b53);
}

.has-background-gradient-vertical-from-pink-400-to-yellow-100 {
  background: linear-gradient(#d85568, #fff8f1);
}

.has-background-gradient-vertical-from-pink-400-to-yellow-200 {
  background: linear-gradient(#d85568, #ffe8bd);
}

.has-background-gradient-vertical-from-pink-400-to-yellow-300 {
  background: linear-gradient(#d85568, #ffd07a);
}

.has-background-gradient-vertical-from-pink-400-to-yellow-400 {
  background: linear-gradient(#d85568, #ffb938);
}

.has-background-gradient-vertical-from-pink-400-to-yellow-500 {
  background: linear-gradient(#d85568, #aa7b25);
}

.has-background-gradient-vertical-from-pink-400-to-pink-100 {
  background: linear-gradient(#d85568, #fbeef0);
}

.has-background-gradient-vertical-from-pink-400-to-pink-200 {
  background: linear-gradient(#d85568, #f2c6cd);
}

.has-background-gradient-vertical-from-pink-400-to-pink-300 {
  background: linear-gradient(#d85568, #e58e9a);
}

.has-background-gradient-vertical-from-pink-400-to-pink-400 {
  background: linear-gradient(#d85568, #d85568);
}

.has-background-gradient-vertical-from-pink-400-to-pink-500 {
  background: linear-gradient(#d85568, #481c23);
}

.has-background-gradient-vertical-from-pink-400-to-grey-100 {
  background: linear-gradient(#d85568, #fff);
}

.has-background-gradient-vertical-from-pink-400-to-grey-200 {
  background: linear-gradient(#d85568, #e2e2e2);
}

.has-background-gradient-vertical-from-pink-400-to-grey-300 {
  background: linear-gradient(#d85568, #a8a8a8);
}

.has-background-gradient-vertical-from-pink-400-to-grey-400 {
  background: linear-gradient(#d85568, #616161);
}

.has-background-gradient-vertical-from-pink-400-to-grey-500 {
  background: linear-gradient(#d85568, #393939);
}

.has-background-gradient-vertical-from-pink-400-to-grey-600 {
  background: linear-gradient(#d85568, #000);
}

.has-background-gradient-vertical-from-pink-500-to-purple-blue-100 {
  background: linear-gradient(#481c23, #f1f1fb);
}

.has-background-gradient-vertical-from-pink-500-to-purple-blue-200 {
  background: linear-gradient(#481c23, #c9c9f0);
}

.has-background-gradient-vertical-from-pink-500-to-purple-blue-300 {
  background: linear-gradient(#481c23, #a594f9);
}

.has-background-gradient-vertical-from-pink-500-to-purple-blue-400 {
  background: linear-gradient(#481c23, #2727c4);
}

.has-background-gradient-vertical-from-pink-500-to-purple-blue-500 {
  background: linear-gradient(#481c23, #1b0777);
}

.has-background-gradient-vertical-from-pink-500-to-peach-100 {
  background: linear-gradient(#481c23, #fdf3f2);
}

.has-background-gradient-vertical-from-pink-500-to-peach-200 {
  background: linear-gradient(#481c23, #f8d8d4);
}

.has-background-gradient-vertical-from-pink-500-to-peach-300 {
  background: linear-gradient(#481c23, #f0b0a8);
}

.has-background-gradient-vertical-from-pink-500-to-peach-400 {
  background: linear-gradient(#481c23, #e9897d);
}

.has-background-gradient-vertical-from-pink-500-to-peach-500 {
  background: linear-gradient(#481c23, #9b5b53);
}

.has-background-gradient-vertical-from-pink-500-to-yellow-100 {
  background: linear-gradient(#481c23, #fff8f1);
}

.has-background-gradient-vertical-from-pink-500-to-yellow-200 {
  background: linear-gradient(#481c23, #ffe8bd);
}

.has-background-gradient-vertical-from-pink-500-to-yellow-300 {
  background: linear-gradient(#481c23, #ffd07a);
}

.has-background-gradient-vertical-from-pink-500-to-yellow-400 {
  background: linear-gradient(#481c23, #ffb938);
}

.has-background-gradient-vertical-from-pink-500-to-yellow-500 {
  background: linear-gradient(#481c23, #aa7b25);
}

.has-background-gradient-vertical-from-pink-500-to-pink-100 {
  background: linear-gradient(#481c23, #fbeef0);
}

.has-background-gradient-vertical-from-pink-500-to-pink-200 {
  background: linear-gradient(#481c23, #f2c6cd);
}

.has-background-gradient-vertical-from-pink-500-to-pink-300 {
  background: linear-gradient(#481c23, #e58e9a);
}

.has-background-gradient-vertical-from-pink-500-to-pink-400 {
  background: linear-gradient(#481c23, #d85568);
}

.has-background-gradient-vertical-from-pink-500-to-pink-500 {
  background: linear-gradient(#481c23, #481c23);
}

.has-background-gradient-vertical-from-pink-500-to-grey-100 {
  background: linear-gradient(#481c23, #fff);
}

.has-background-gradient-vertical-from-pink-500-to-grey-200 {
  background: linear-gradient(#481c23, #e2e2e2);
}

.has-background-gradient-vertical-from-pink-500-to-grey-300 {
  background: linear-gradient(#481c23, #a8a8a8);
}

.has-background-gradient-vertical-from-pink-500-to-grey-400 {
  background: linear-gradient(#481c23, #616161);
}

.has-background-gradient-vertical-from-pink-500-to-grey-500 {
  background: linear-gradient(#481c23, #393939);
}

.has-background-gradient-vertical-from-pink-500-to-grey-600 {
  background: linear-gradient(#481c23, #000);
}

.has-background-gradient-vertical-from-grey-100-to-purple-blue-100 {
  background: linear-gradient(#fff, #f1f1fb);
}

.has-background-gradient-vertical-from-grey-100-to-purple-blue-200 {
  background: linear-gradient(#fff, #c9c9f0);
}

.has-background-gradient-vertical-from-grey-100-to-purple-blue-300 {
  background: linear-gradient(#fff, #a594f9);
}

.has-background-gradient-vertical-from-grey-100-to-purple-blue-400 {
  background: linear-gradient(#fff, #2727c4);
}

.has-background-gradient-vertical-from-grey-100-to-purple-blue-500 {
  background: linear-gradient(#fff, #1b0777);
}

.has-background-gradient-vertical-from-grey-100-to-peach-100 {
  background: linear-gradient(#fff, #fdf3f2);
}

.has-background-gradient-vertical-from-grey-100-to-peach-200 {
  background: linear-gradient(#fff, #f8d8d4);
}

.has-background-gradient-vertical-from-grey-100-to-peach-300 {
  background: linear-gradient(#fff, #f0b0a8);
}

.has-background-gradient-vertical-from-grey-100-to-peach-400 {
  background: linear-gradient(#fff, #e9897d);
}

.has-background-gradient-vertical-from-grey-100-to-peach-500 {
  background: linear-gradient(#fff, #9b5b53);
}

.has-background-gradient-vertical-from-grey-100-to-yellow-100 {
  background: linear-gradient(#fff, #fff8f1);
}

.has-background-gradient-vertical-from-grey-100-to-yellow-200 {
  background: linear-gradient(#fff, #ffe8bd);
}

.has-background-gradient-vertical-from-grey-100-to-yellow-300 {
  background: linear-gradient(#fff, #ffd07a);
}

.has-background-gradient-vertical-from-grey-100-to-yellow-400 {
  background: linear-gradient(#fff, #ffb938);
}

.has-background-gradient-vertical-from-grey-100-to-yellow-500 {
  background: linear-gradient(#fff, #aa7b25);
}

.has-background-gradient-vertical-from-grey-100-to-pink-100 {
  background: linear-gradient(#fff, #fbeef0);
}

.has-background-gradient-vertical-from-grey-100-to-pink-200 {
  background: linear-gradient(#fff, #f2c6cd);
}

.has-background-gradient-vertical-from-grey-100-to-pink-300 {
  background: linear-gradient(#fff, #e58e9a);
}

.has-background-gradient-vertical-from-grey-100-to-pink-400 {
  background: linear-gradient(#fff, #d85568);
}

.has-background-gradient-vertical-from-grey-100-to-pink-500 {
  background: linear-gradient(#fff, #481c23);
}

.has-background-gradient-vertical-from-grey-100-to-grey-100 {
  background: linear-gradient(#fff, #fff);
}

.has-background-gradient-vertical-from-grey-100-to-grey-200 {
  background: linear-gradient(#fff, #e2e2e2);
}

.has-background-gradient-vertical-from-grey-100-to-grey-300 {
  background: linear-gradient(#fff, #a8a8a8);
}

.has-background-gradient-vertical-from-grey-100-to-grey-400 {
  background: linear-gradient(#fff, #616161);
}

.has-background-gradient-vertical-from-grey-100-to-grey-500 {
  background: linear-gradient(#fff, #393939);
}

.has-background-gradient-vertical-from-grey-100-to-grey-600 {
  background: linear-gradient(#fff, #000);
}

.has-background-gradient-vertical-from-grey-200-to-purple-blue-100 {
  background: linear-gradient(#e2e2e2, #f1f1fb);
}

.has-background-gradient-vertical-from-grey-200-to-purple-blue-200 {
  background: linear-gradient(#e2e2e2, #c9c9f0);
}

.has-background-gradient-vertical-from-grey-200-to-purple-blue-300 {
  background: linear-gradient(#e2e2e2, #a594f9);
}

.has-background-gradient-vertical-from-grey-200-to-purple-blue-400 {
  background: linear-gradient(#e2e2e2, #2727c4);
}

.has-background-gradient-vertical-from-grey-200-to-purple-blue-500 {
  background: linear-gradient(#e2e2e2, #1b0777);
}

.has-background-gradient-vertical-from-grey-200-to-peach-100 {
  background: linear-gradient(#e2e2e2, #fdf3f2);
}

.has-background-gradient-vertical-from-grey-200-to-peach-200 {
  background: linear-gradient(#e2e2e2, #f8d8d4);
}

.has-background-gradient-vertical-from-grey-200-to-peach-300 {
  background: linear-gradient(#e2e2e2, #f0b0a8);
}

.has-background-gradient-vertical-from-grey-200-to-peach-400 {
  background: linear-gradient(#e2e2e2, #e9897d);
}

.has-background-gradient-vertical-from-grey-200-to-peach-500 {
  background: linear-gradient(#e2e2e2, #9b5b53);
}

.has-background-gradient-vertical-from-grey-200-to-yellow-100 {
  background: linear-gradient(#e2e2e2, #fff8f1);
}

.has-background-gradient-vertical-from-grey-200-to-yellow-200 {
  background: linear-gradient(#e2e2e2, #ffe8bd);
}

.has-background-gradient-vertical-from-grey-200-to-yellow-300 {
  background: linear-gradient(#e2e2e2, #ffd07a);
}

.has-background-gradient-vertical-from-grey-200-to-yellow-400 {
  background: linear-gradient(#e2e2e2, #ffb938);
}

.has-background-gradient-vertical-from-grey-200-to-yellow-500 {
  background: linear-gradient(#e2e2e2, #aa7b25);
}

.has-background-gradient-vertical-from-grey-200-to-pink-100 {
  background: linear-gradient(#e2e2e2, #fbeef0);
}

.has-background-gradient-vertical-from-grey-200-to-pink-200 {
  background: linear-gradient(#e2e2e2, #f2c6cd);
}

.has-background-gradient-vertical-from-grey-200-to-pink-300 {
  background: linear-gradient(#e2e2e2, #e58e9a);
}

.has-background-gradient-vertical-from-grey-200-to-pink-400 {
  background: linear-gradient(#e2e2e2, #d85568);
}

.has-background-gradient-vertical-from-grey-200-to-pink-500 {
  background: linear-gradient(#e2e2e2, #481c23);
}

.has-background-gradient-vertical-from-grey-200-to-grey-100 {
  background: linear-gradient(#e2e2e2, #fff);
}

.has-background-gradient-vertical-from-grey-200-to-grey-200 {
  background: linear-gradient(#e2e2e2, #e2e2e2);
}

.has-background-gradient-vertical-from-grey-200-to-grey-300 {
  background: linear-gradient(#e2e2e2, #a8a8a8);
}

.has-background-gradient-vertical-from-grey-200-to-grey-400 {
  background: linear-gradient(#e2e2e2, #616161);
}

.has-background-gradient-vertical-from-grey-200-to-grey-500 {
  background: linear-gradient(#e2e2e2, #393939);
}

.has-background-gradient-vertical-from-grey-200-to-grey-600 {
  background: linear-gradient(#e2e2e2, #000);
}

.has-background-gradient-vertical-from-grey-300-to-purple-blue-100 {
  background: linear-gradient(#a8a8a8, #f1f1fb);
}

.has-background-gradient-vertical-from-grey-300-to-purple-blue-200 {
  background: linear-gradient(#a8a8a8, #c9c9f0);
}

.has-background-gradient-vertical-from-grey-300-to-purple-blue-300 {
  background: linear-gradient(#a8a8a8, #a594f9);
}

.has-background-gradient-vertical-from-grey-300-to-purple-blue-400 {
  background: linear-gradient(#a8a8a8, #2727c4);
}

.has-background-gradient-vertical-from-grey-300-to-purple-blue-500 {
  background: linear-gradient(#a8a8a8, #1b0777);
}

.has-background-gradient-vertical-from-grey-300-to-peach-100 {
  background: linear-gradient(#a8a8a8, #fdf3f2);
}

.has-background-gradient-vertical-from-grey-300-to-peach-200 {
  background: linear-gradient(#a8a8a8, #f8d8d4);
}

.has-background-gradient-vertical-from-grey-300-to-peach-300 {
  background: linear-gradient(#a8a8a8, #f0b0a8);
}

.has-background-gradient-vertical-from-grey-300-to-peach-400 {
  background: linear-gradient(#a8a8a8, #e9897d);
}

.has-background-gradient-vertical-from-grey-300-to-peach-500 {
  background: linear-gradient(#a8a8a8, #9b5b53);
}

.has-background-gradient-vertical-from-grey-300-to-yellow-100 {
  background: linear-gradient(#a8a8a8, #fff8f1);
}

.has-background-gradient-vertical-from-grey-300-to-yellow-200 {
  background: linear-gradient(#a8a8a8, #ffe8bd);
}

.has-background-gradient-vertical-from-grey-300-to-yellow-300 {
  background: linear-gradient(#a8a8a8, #ffd07a);
}

.has-background-gradient-vertical-from-grey-300-to-yellow-400 {
  background: linear-gradient(#a8a8a8, #ffb938);
}

.has-background-gradient-vertical-from-grey-300-to-yellow-500 {
  background: linear-gradient(#a8a8a8, #aa7b25);
}

.has-background-gradient-vertical-from-grey-300-to-pink-100 {
  background: linear-gradient(#a8a8a8, #fbeef0);
}

.has-background-gradient-vertical-from-grey-300-to-pink-200 {
  background: linear-gradient(#a8a8a8, #f2c6cd);
}

.has-background-gradient-vertical-from-grey-300-to-pink-300 {
  background: linear-gradient(#a8a8a8, #e58e9a);
}

.has-background-gradient-vertical-from-grey-300-to-pink-400 {
  background: linear-gradient(#a8a8a8, #d85568);
}

.has-background-gradient-vertical-from-grey-300-to-pink-500 {
  background: linear-gradient(#a8a8a8, #481c23);
}

.has-background-gradient-vertical-from-grey-300-to-grey-100 {
  background: linear-gradient(#a8a8a8, #fff);
}

.has-background-gradient-vertical-from-grey-300-to-grey-200 {
  background: linear-gradient(#a8a8a8, #e2e2e2);
}

.has-background-gradient-vertical-from-grey-300-to-grey-300 {
  background: linear-gradient(#a8a8a8, #a8a8a8);
}

.has-background-gradient-vertical-from-grey-300-to-grey-400 {
  background: linear-gradient(#a8a8a8, #616161);
}

.has-background-gradient-vertical-from-grey-300-to-grey-500 {
  background: linear-gradient(#a8a8a8, #393939);
}

.has-background-gradient-vertical-from-grey-300-to-grey-600 {
  background: linear-gradient(#a8a8a8, #000);
}

.has-background-gradient-vertical-from-grey-400-to-purple-blue-100 {
  background: linear-gradient(#616161, #f1f1fb);
}

.has-background-gradient-vertical-from-grey-400-to-purple-blue-200 {
  background: linear-gradient(#616161, #c9c9f0);
}

.has-background-gradient-vertical-from-grey-400-to-purple-blue-300 {
  background: linear-gradient(#616161, #a594f9);
}

.has-background-gradient-vertical-from-grey-400-to-purple-blue-400 {
  background: linear-gradient(#616161, #2727c4);
}

.has-background-gradient-vertical-from-grey-400-to-purple-blue-500 {
  background: linear-gradient(#616161, #1b0777);
}

.has-background-gradient-vertical-from-grey-400-to-peach-100 {
  background: linear-gradient(#616161, #fdf3f2);
}

.has-background-gradient-vertical-from-grey-400-to-peach-200 {
  background: linear-gradient(#616161, #f8d8d4);
}

.has-background-gradient-vertical-from-grey-400-to-peach-300 {
  background: linear-gradient(#616161, #f0b0a8);
}

.has-background-gradient-vertical-from-grey-400-to-peach-400 {
  background: linear-gradient(#616161, #e9897d);
}

.has-background-gradient-vertical-from-grey-400-to-peach-500 {
  background: linear-gradient(#616161, #9b5b53);
}

.has-background-gradient-vertical-from-grey-400-to-yellow-100 {
  background: linear-gradient(#616161, #fff8f1);
}

.has-background-gradient-vertical-from-grey-400-to-yellow-200 {
  background: linear-gradient(#616161, #ffe8bd);
}

.has-background-gradient-vertical-from-grey-400-to-yellow-300 {
  background: linear-gradient(#616161, #ffd07a);
}

.has-background-gradient-vertical-from-grey-400-to-yellow-400 {
  background: linear-gradient(#616161, #ffb938);
}

.has-background-gradient-vertical-from-grey-400-to-yellow-500 {
  background: linear-gradient(#616161, #aa7b25);
}

.has-background-gradient-vertical-from-grey-400-to-pink-100 {
  background: linear-gradient(#616161, #fbeef0);
}

.has-background-gradient-vertical-from-grey-400-to-pink-200 {
  background: linear-gradient(#616161, #f2c6cd);
}

.has-background-gradient-vertical-from-grey-400-to-pink-300 {
  background: linear-gradient(#616161, #e58e9a);
}

.has-background-gradient-vertical-from-grey-400-to-pink-400 {
  background: linear-gradient(#616161, #d85568);
}

.has-background-gradient-vertical-from-grey-400-to-pink-500 {
  background: linear-gradient(#616161, #481c23);
}

.has-background-gradient-vertical-from-grey-400-to-grey-100 {
  background: linear-gradient(#616161, #fff);
}

.has-background-gradient-vertical-from-grey-400-to-grey-200 {
  background: linear-gradient(#616161, #e2e2e2);
}

.has-background-gradient-vertical-from-grey-400-to-grey-300 {
  background: linear-gradient(#616161, #a8a8a8);
}

.has-background-gradient-vertical-from-grey-400-to-grey-400 {
  background: linear-gradient(#616161, #616161);
}

.has-background-gradient-vertical-from-grey-400-to-grey-500 {
  background: linear-gradient(#616161, #393939);
}

.has-background-gradient-vertical-from-grey-400-to-grey-600 {
  background: linear-gradient(#616161, #000);
}

.has-background-gradient-vertical-from-grey-500-to-purple-blue-100 {
  background: linear-gradient(#393939, #f1f1fb);
}

.has-background-gradient-vertical-from-grey-500-to-purple-blue-200 {
  background: linear-gradient(#393939, #c9c9f0);
}

.has-background-gradient-vertical-from-grey-500-to-purple-blue-300 {
  background: linear-gradient(#393939, #a594f9);
}

.has-background-gradient-vertical-from-grey-500-to-purple-blue-400 {
  background: linear-gradient(#393939, #2727c4);
}

.has-background-gradient-vertical-from-grey-500-to-purple-blue-500 {
  background: linear-gradient(#393939, #1b0777);
}

.has-background-gradient-vertical-from-grey-500-to-peach-100 {
  background: linear-gradient(#393939, #fdf3f2);
}

.has-background-gradient-vertical-from-grey-500-to-peach-200 {
  background: linear-gradient(#393939, #f8d8d4);
}

.has-background-gradient-vertical-from-grey-500-to-peach-300 {
  background: linear-gradient(#393939, #f0b0a8);
}

.has-background-gradient-vertical-from-grey-500-to-peach-400 {
  background: linear-gradient(#393939, #e9897d);
}

.has-background-gradient-vertical-from-grey-500-to-peach-500 {
  background: linear-gradient(#393939, #9b5b53);
}

.has-background-gradient-vertical-from-grey-500-to-yellow-100 {
  background: linear-gradient(#393939, #fff8f1);
}

.has-background-gradient-vertical-from-grey-500-to-yellow-200 {
  background: linear-gradient(#393939, #ffe8bd);
}

.has-background-gradient-vertical-from-grey-500-to-yellow-300 {
  background: linear-gradient(#393939, #ffd07a);
}

.has-background-gradient-vertical-from-grey-500-to-yellow-400 {
  background: linear-gradient(#393939, #ffb938);
}

.has-background-gradient-vertical-from-grey-500-to-yellow-500 {
  background: linear-gradient(#393939, #aa7b25);
}

.has-background-gradient-vertical-from-grey-500-to-pink-100 {
  background: linear-gradient(#393939, #fbeef0);
}

.has-background-gradient-vertical-from-grey-500-to-pink-200 {
  background: linear-gradient(#393939, #f2c6cd);
}

.has-background-gradient-vertical-from-grey-500-to-pink-300 {
  background: linear-gradient(#393939, #e58e9a);
}

.has-background-gradient-vertical-from-grey-500-to-pink-400 {
  background: linear-gradient(#393939, #d85568);
}

.has-background-gradient-vertical-from-grey-500-to-pink-500 {
  background: linear-gradient(#393939, #481c23);
}

.has-background-gradient-vertical-from-grey-500-to-grey-100 {
  background: linear-gradient(#393939, #fff);
}

.has-background-gradient-vertical-from-grey-500-to-grey-200 {
  background: linear-gradient(#393939, #e2e2e2);
}

.has-background-gradient-vertical-from-grey-500-to-grey-300 {
  background: linear-gradient(#393939, #a8a8a8);
}

.has-background-gradient-vertical-from-grey-500-to-grey-400 {
  background: linear-gradient(#393939, #616161);
}

.has-background-gradient-vertical-from-grey-500-to-grey-500 {
  background: linear-gradient(#393939, #393939);
}

.has-background-gradient-vertical-from-grey-500-to-grey-600 {
  background: linear-gradient(#393939, #000);
}

.has-background-gradient-vertical-from-grey-600-to-purple-blue-100 {
  background: linear-gradient(#000, #f1f1fb);
}

.has-background-gradient-vertical-from-grey-600-to-purple-blue-200 {
  background: linear-gradient(#000, #c9c9f0);
}

.has-background-gradient-vertical-from-grey-600-to-purple-blue-300 {
  background: linear-gradient(#000, #a594f9);
}

.has-background-gradient-vertical-from-grey-600-to-purple-blue-400 {
  background: linear-gradient(#000, #2727c4);
}

.has-background-gradient-vertical-from-grey-600-to-purple-blue-500 {
  background: linear-gradient(#000, #1b0777);
}

.has-background-gradient-vertical-from-grey-600-to-peach-100 {
  background: linear-gradient(#000, #fdf3f2);
}

.has-background-gradient-vertical-from-grey-600-to-peach-200 {
  background: linear-gradient(#000, #f8d8d4);
}

.has-background-gradient-vertical-from-grey-600-to-peach-300 {
  background: linear-gradient(#000, #f0b0a8);
}

.has-background-gradient-vertical-from-grey-600-to-peach-400 {
  background: linear-gradient(#000, #e9897d);
}

.has-background-gradient-vertical-from-grey-600-to-peach-500 {
  background: linear-gradient(#000, #9b5b53);
}

.has-background-gradient-vertical-from-grey-600-to-yellow-100 {
  background: linear-gradient(#000, #fff8f1);
}

.has-background-gradient-vertical-from-grey-600-to-yellow-200 {
  background: linear-gradient(#000, #ffe8bd);
}

.has-background-gradient-vertical-from-grey-600-to-yellow-300 {
  background: linear-gradient(#000, #ffd07a);
}

.has-background-gradient-vertical-from-grey-600-to-yellow-400 {
  background: linear-gradient(#000, #ffb938);
}

.has-background-gradient-vertical-from-grey-600-to-yellow-500 {
  background: linear-gradient(#000, #aa7b25);
}

.has-background-gradient-vertical-from-grey-600-to-pink-100 {
  background: linear-gradient(#000, #fbeef0);
}

.has-background-gradient-vertical-from-grey-600-to-pink-200 {
  background: linear-gradient(#000, #f2c6cd);
}

.has-background-gradient-vertical-from-grey-600-to-pink-300 {
  background: linear-gradient(#000, #e58e9a);
}

.has-background-gradient-vertical-from-grey-600-to-pink-400 {
  background: linear-gradient(#000, #d85568);
}

.has-background-gradient-vertical-from-grey-600-to-pink-500 {
  background: linear-gradient(#000, #481c23);
}

.has-background-gradient-vertical-from-grey-600-to-grey-100 {
  background: linear-gradient(#000, #fff);
}

.has-background-gradient-vertical-from-grey-600-to-grey-200 {
  background: linear-gradient(#000, #e2e2e2);
}

.has-background-gradient-vertical-from-grey-600-to-grey-300 {
  background: linear-gradient(#000, #a8a8a8);
}

.has-background-gradient-vertical-from-grey-600-to-grey-400 {
  background: linear-gradient(#000, #616161);
}

.has-background-gradient-vertical-from-grey-600-to-grey-500 {
  background: linear-gradient(#000, #393939);
}

.has-background-gradient-vertical-from-grey-600-to-grey-600 {
  background: linear-gradient(#000, #000);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-purple-blue-100 {
  background: linear-gradient(to right, #f1f1fb, #f1f1fb);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-purple-blue-200 {
  background: linear-gradient(to right, #f1f1fb, #c9c9f0);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-purple-blue-300 {
  background: linear-gradient(to right, #f1f1fb, #a594f9);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-purple-blue-400 {
  background: linear-gradient(to right, #f1f1fb, #2727c4);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-purple-blue-500 {
  background: linear-gradient(to right, #f1f1fb, #1b0777);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-peach-100 {
  background: linear-gradient(to right, #f1f1fb, #fdf3f2);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-peach-200 {
  background: linear-gradient(to right, #f1f1fb, #f8d8d4);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-peach-300 {
  background: linear-gradient(to right, #f1f1fb, #f0b0a8);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-peach-400 {
  background: linear-gradient(to right, #f1f1fb, #e9897d);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-peach-500 {
  background: linear-gradient(to right, #f1f1fb, #9b5b53);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-yellow-100 {
  background: linear-gradient(to right, #f1f1fb, #fff8f1);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-yellow-200 {
  background: linear-gradient(to right, #f1f1fb, #ffe8bd);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-yellow-300 {
  background: linear-gradient(to right, #f1f1fb, #ffd07a);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-yellow-400 {
  background: linear-gradient(to right, #f1f1fb, #ffb938);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-yellow-500 {
  background: linear-gradient(to right, #f1f1fb, #aa7b25);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-pink-100 {
  background: linear-gradient(to right, #f1f1fb, #fbeef0);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-pink-200 {
  background: linear-gradient(to right, #f1f1fb, #f2c6cd);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-pink-300 {
  background: linear-gradient(to right, #f1f1fb, #e58e9a);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-pink-400 {
  background: linear-gradient(to right, #f1f1fb, #d85568);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-pink-500 {
  background: linear-gradient(to right, #f1f1fb, #481c23);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-grey-100 {
  background: linear-gradient(to right, #f1f1fb, #fff);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-grey-200 {
  background: linear-gradient(to right, #f1f1fb, #e2e2e2);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-grey-300 {
  background: linear-gradient(to right, #f1f1fb, #a8a8a8);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-grey-400 {
  background: linear-gradient(to right, #f1f1fb, #616161);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-grey-500 {
  background: linear-gradient(to right, #f1f1fb, #393939);
}

.has-background-gradient-horizontal-from-purple-blue-100-to-grey-600 {
  background: linear-gradient(to right, #f1f1fb, #000);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-purple-blue-100 {
  background: linear-gradient(to right, #c9c9f0, #f1f1fb);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-purple-blue-200 {
  background: linear-gradient(to right, #c9c9f0, #c9c9f0);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-purple-blue-300 {
  background: linear-gradient(to right, #c9c9f0, #a594f9);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-purple-blue-400 {
  background: linear-gradient(to right, #c9c9f0, #2727c4);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-purple-blue-500 {
  background: linear-gradient(to right, #c9c9f0, #1b0777);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-peach-100 {
  background: linear-gradient(to right, #c9c9f0, #fdf3f2);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-peach-200 {
  background: linear-gradient(to right, #c9c9f0, #f8d8d4);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-peach-300 {
  background: linear-gradient(to right, #c9c9f0, #f0b0a8);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-peach-400 {
  background: linear-gradient(to right, #c9c9f0, #e9897d);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-peach-500 {
  background: linear-gradient(to right, #c9c9f0, #9b5b53);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-yellow-100 {
  background: linear-gradient(to right, #c9c9f0, #fff8f1);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-yellow-200 {
  background: linear-gradient(to right, #c9c9f0, #ffe8bd);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-yellow-300 {
  background: linear-gradient(to right, #c9c9f0, #ffd07a);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-yellow-400 {
  background: linear-gradient(to right, #c9c9f0, #ffb938);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-yellow-500 {
  background: linear-gradient(to right, #c9c9f0, #aa7b25);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-pink-100 {
  background: linear-gradient(to right, #c9c9f0, #fbeef0);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-pink-200 {
  background: linear-gradient(to right, #c9c9f0, #f2c6cd);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-pink-300 {
  background: linear-gradient(to right, #c9c9f0, #e58e9a);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-pink-400 {
  background: linear-gradient(to right, #c9c9f0, #d85568);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-pink-500 {
  background: linear-gradient(to right, #c9c9f0, #481c23);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-grey-100 {
  background: linear-gradient(to right, #c9c9f0, #fff);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-grey-200 {
  background: linear-gradient(to right, #c9c9f0, #e2e2e2);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-grey-300 {
  background: linear-gradient(to right, #c9c9f0, #a8a8a8);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-grey-400 {
  background: linear-gradient(to right, #c9c9f0, #616161);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-grey-500 {
  background: linear-gradient(to right, #c9c9f0, #393939);
}

.has-background-gradient-horizontal-from-purple-blue-200-to-grey-600 {
  background: linear-gradient(to right, #c9c9f0, #000);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-purple-blue-100 {
  background: linear-gradient(to right, #a594f9, #f1f1fb);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-purple-blue-200 {
  background: linear-gradient(to right, #a594f9, #c9c9f0);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-purple-blue-300 {
  background: linear-gradient(to right, #a594f9, #a594f9);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-purple-blue-400 {
  background: linear-gradient(to right, #a594f9, #2727c4);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-purple-blue-500 {
  background: linear-gradient(to right, #a594f9, #1b0777);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-peach-100 {
  background: linear-gradient(to right, #a594f9, #fdf3f2);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-peach-200 {
  background: linear-gradient(to right, #a594f9, #f8d8d4);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-peach-300 {
  background: linear-gradient(to right, #a594f9, #f0b0a8);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-peach-400 {
  background: linear-gradient(to right, #a594f9, #e9897d);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-peach-500 {
  background: linear-gradient(to right, #a594f9, #9b5b53);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-yellow-100 {
  background: linear-gradient(to right, #a594f9, #fff8f1);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-yellow-200 {
  background: linear-gradient(to right, #a594f9, #ffe8bd);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-yellow-300 {
  background: linear-gradient(to right, #a594f9, #ffd07a);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-yellow-400 {
  background: linear-gradient(to right, #a594f9, #ffb938);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-yellow-500 {
  background: linear-gradient(to right, #a594f9, #aa7b25);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-pink-100 {
  background: linear-gradient(to right, #a594f9, #fbeef0);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-pink-200 {
  background: linear-gradient(to right, #a594f9, #f2c6cd);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-pink-300 {
  background: linear-gradient(to right, #a594f9, #e58e9a);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-pink-400 {
  background: linear-gradient(to right, #a594f9, #d85568);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-pink-500 {
  background: linear-gradient(to right, #a594f9, #481c23);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-grey-100 {
  background: linear-gradient(to right, #a594f9, #fff);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-grey-200 {
  background: linear-gradient(to right, #a594f9, #e2e2e2);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-grey-300 {
  background: linear-gradient(to right, #a594f9, #a8a8a8);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-grey-400 {
  background: linear-gradient(to right, #a594f9, #616161);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-grey-500 {
  background: linear-gradient(to right, #a594f9, #393939);
}

.has-background-gradient-horizontal-from-purple-blue-300-to-grey-600 {
  background: linear-gradient(to right, #a594f9, #000);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-purple-blue-100 {
  background: linear-gradient(to right, #2727c4, #f1f1fb);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-purple-blue-200 {
  background: linear-gradient(to right, #2727c4, #c9c9f0);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-purple-blue-300 {
  background: linear-gradient(to right, #2727c4, #a594f9);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-purple-blue-400 {
  background: linear-gradient(to right, #2727c4, #2727c4);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-purple-blue-500 {
  background: linear-gradient(to right, #2727c4, #1b0777);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-peach-100 {
  background: linear-gradient(to right, #2727c4, #fdf3f2);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-peach-200 {
  background: linear-gradient(to right, #2727c4, #f8d8d4);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-peach-300 {
  background: linear-gradient(to right, #2727c4, #f0b0a8);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-peach-400 {
  background: linear-gradient(to right, #2727c4, #e9897d);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-peach-500 {
  background: linear-gradient(to right, #2727c4, #9b5b53);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-yellow-100 {
  background: linear-gradient(to right, #2727c4, #fff8f1);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-yellow-200 {
  background: linear-gradient(to right, #2727c4, #ffe8bd);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-yellow-300 {
  background: linear-gradient(to right, #2727c4, #ffd07a);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-yellow-400 {
  background: linear-gradient(to right, #2727c4, #ffb938);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-yellow-500 {
  background: linear-gradient(to right, #2727c4, #aa7b25);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-pink-100 {
  background: linear-gradient(to right, #2727c4, #fbeef0);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-pink-200 {
  background: linear-gradient(to right, #2727c4, #f2c6cd);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-pink-300 {
  background: linear-gradient(to right, #2727c4, #e58e9a);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-pink-400 {
  background: linear-gradient(to right, #2727c4, #d85568);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-pink-500 {
  background: linear-gradient(to right, #2727c4, #481c23);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-grey-100 {
  background: linear-gradient(to right, #2727c4, #fff);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-grey-200 {
  background: linear-gradient(to right, #2727c4, #e2e2e2);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-grey-300 {
  background: linear-gradient(to right, #2727c4, #a8a8a8);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-grey-400 {
  background: linear-gradient(to right, #2727c4, #616161);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-grey-500 {
  background: linear-gradient(to right, #2727c4, #393939);
}

.has-background-gradient-horizontal-from-purple-blue-400-to-grey-600 {
  background: linear-gradient(to right, #2727c4, #000);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-purple-blue-100 {
  background: linear-gradient(to right, #1b0777, #f1f1fb);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-purple-blue-200 {
  background: linear-gradient(to right, #1b0777, #c9c9f0);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-purple-blue-300 {
  background: linear-gradient(to right, #1b0777, #a594f9);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-purple-blue-400 {
  background: linear-gradient(to right, #1b0777, #2727c4);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-purple-blue-500 {
  background: linear-gradient(to right, #1b0777, #1b0777);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-peach-100 {
  background: linear-gradient(to right, #1b0777, #fdf3f2);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-peach-200 {
  background: linear-gradient(to right, #1b0777, #f8d8d4);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-peach-300 {
  background: linear-gradient(to right, #1b0777, #f0b0a8);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-peach-400 {
  background: linear-gradient(to right, #1b0777, #e9897d);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-peach-500 {
  background: linear-gradient(to right, #1b0777, #9b5b53);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-yellow-100 {
  background: linear-gradient(to right, #1b0777, #fff8f1);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-yellow-200 {
  background: linear-gradient(to right, #1b0777, #ffe8bd);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-yellow-300 {
  background: linear-gradient(to right, #1b0777, #ffd07a);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-yellow-400 {
  background: linear-gradient(to right, #1b0777, #ffb938);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-yellow-500 {
  background: linear-gradient(to right, #1b0777, #aa7b25);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-pink-100 {
  background: linear-gradient(to right, #1b0777, #fbeef0);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-pink-200 {
  background: linear-gradient(to right, #1b0777, #f2c6cd);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-pink-300 {
  background: linear-gradient(to right, #1b0777, #e58e9a);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-pink-400 {
  background: linear-gradient(to right, #1b0777, #d85568);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-pink-500 {
  background: linear-gradient(to right, #1b0777, #481c23);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-grey-100 {
  background: linear-gradient(to right, #1b0777, #fff);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-grey-200 {
  background: linear-gradient(to right, #1b0777, #e2e2e2);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-grey-300 {
  background: linear-gradient(to right, #1b0777, #a8a8a8);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-grey-400 {
  background: linear-gradient(to right, #1b0777, #616161);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-grey-500 {
  background: linear-gradient(to right, #1b0777, #393939);
}

.has-background-gradient-horizontal-from-purple-blue-500-to-grey-600 {
  background: linear-gradient(to right, #1b0777, #000);
}

.has-background-gradient-horizontal-from-peach-100-to-purple-blue-100 {
  background: linear-gradient(to right, #fdf3f2, #f1f1fb);
}

.has-background-gradient-horizontal-from-peach-100-to-purple-blue-200 {
  background: linear-gradient(to right, #fdf3f2, #c9c9f0);
}

.has-background-gradient-horizontal-from-peach-100-to-purple-blue-300 {
  background: linear-gradient(to right, #fdf3f2, #a594f9);
}

.has-background-gradient-horizontal-from-peach-100-to-purple-blue-400 {
  background: linear-gradient(to right, #fdf3f2, #2727c4);
}

.has-background-gradient-horizontal-from-peach-100-to-purple-blue-500 {
  background: linear-gradient(to right, #fdf3f2, #1b0777);
}

.has-background-gradient-horizontal-from-peach-100-to-peach-100 {
  background: linear-gradient(to right, #fdf3f2, #fdf3f2);
}

.has-background-gradient-horizontal-from-peach-100-to-peach-200 {
  background: linear-gradient(to right, #fdf3f2, #f8d8d4);
}

.has-background-gradient-horizontal-from-peach-100-to-peach-300 {
  background: linear-gradient(to right, #fdf3f2, #f0b0a8);
}

.has-background-gradient-horizontal-from-peach-100-to-peach-400 {
  background: linear-gradient(to right, #fdf3f2, #e9897d);
}

.has-background-gradient-horizontal-from-peach-100-to-peach-500 {
  background: linear-gradient(to right, #fdf3f2, #9b5b53);
}

.has-background-gradient-horizontal-from-peach-100-to-yellow-100 {
  background: linear-gradient(to right, #fdf3f2, #fff8f1);
}

.has-background-gradient-horizontal-from-peach-100-to-yellow-200 {
  background: linear-gradient(to right, #fdf3f2, #ffe8bd);
}

.has-background-gradient-horizontal-from-peach-100-to-yellow-300 {
  background: linear-gradient(to right, #fdf3f2, #ffd07a);
}

.has-background-gradient-horizontal-from-peach-100-to-yellow-400 {
  background: linear-gradient(to right, #fdf3f2, #ffb938);
}

.has-background-gradient-horizontal-from-peach-100-to-yellow-500 {
  background: linear-gradient(to right, #fdf3f2, #aa7b25);
}

.has-background-gradient-horizontal-from-peach-100-to-pink-100 {
  background: linear-gradient(to right, #fdf3f2, #fbeef0);
}

.has-background-gradient-horizontal-from-peach-100-to-pink-200 {
  background: linear-gradient(to right, #fdf3f2, #f2c6cd);
}

.has-background-gradient-horizontal-from-peach-100-to-pink-300 {
  background: linear-gradient(to right, #fdf3f2, #e58e9a);
}

.has-background-gradient-horizontal-from-peach-100-to-pink-400 {
  background: linear-gradient(to right, #fdf3f2, #d85568);
}

.has-background-gradient-horizontal-from-peach-100-to-pink-500 {
  background: linear-gradient(to right, #fdf3f2, #481c23);
}

.has-background-gradient-horizontal-from-peach-100-to-grey-100 {
  background: linear-gradient(to right, #fdf3f2, #fff);
}

.has-background-gradient-horizontal-from-peach-100-to-grey-200 {
  background: linear-gradient(to right, #fdf3f2, #e2e2e2);
}

.has-background-gradient-horizontal-from-peach-100-to-grey-300 {
  background: linear-gradient(to right, #fdf3f2, #a8a8a8);
}

.has-background-gradient-horizontal-from-peach-100-to-grey-400 {
  background: linear-gradient(to right, #fdf3f2, #616161);
}

.has-background-gradient-horizontal-from-peach-100-to-grey-500 {
  background: linear-gradient(to right, #fdf3f2, #393939);
}

.has-background-gradient-horizontal-from-peach-100-to-grey-600 {
  background: linear-gradient(to right, #fdf3f2, #000);
}

.has-background-gradient-horizontal-from-peach-200-to-purple-blue-100 {
  background: linear-gradient(to right, #f8d8d4, #f1f1fb);
}

.has-background-gradient-horizontal-from-peach-200-to-purple-blue-200 {
  background: linear-gradient(to right, #f8d8d4, #c9c9f0);
}

.has-background-gradient-horizontal-from-peach-200-to-purple-blue-300 {
  background: linear-gradient(to right, #f8d8d4, #a594f9);
}

.has-background-gradient-horizontal-from-peach-200-to-purple-blue-400 {
  background: linear-gradient(to right, #f8d8d4, #2727c4);
}

.has-background-gradient-horizontal-from-peach-200-to-purple-blue-500 {
  background: linear-gradient(to right, #f8d8d4, #1b0777);
}

.has-background-gradient-horizontal-from-peach-200-to-peach-100 {
  background: linear-gradient(to right, #f8d8d4, #fdf3f2);
}

.has-background-gradient-horizontal-from-peach-200-to-peach-200 {
  background: linear-gradient(to right, #f8d8d4, #f8d8d4);
}

.has-background-gradient-horizontal-from-peach-200-to-peach-300 {
  background: linear-gradient(to right, #f8d8d4, #f0b0a8);
}

.has-background-gradient-horizontal-from-peach-200-to-peach-400 {
  background: linear-gradient(to right, #f8d8d4, #e9897d);
}

.has-background-gradient-horizontal-from-peach-200-to-peach-500 {
  background: linear-gradient(to right, #f8d8d4, #9b5b53);
}

.has-background-gradient-horizontal-from-peach-200-to-yellow-100 {
  background: linear-gradient(to right, #f8d8d4, #fff8f1);
}

.has-background-gradient-horizontal-from-peach-200-to-yellow-200 {
  background: linear-gradient(to right, #f8d8d4, #ffe8bd);
}

.has-background-gradient-horizontal-from-peach-200-to-yellow-300 {
  background: linear-gradient(to right, #f8d8d4, #ffd07a);
}

.has-background-gradient-horizontal-from-peach-200-to-yellow-400 {
  background: linear-gradient(to right, #f8d8d4, #ffb938);
}

.has-background-gradient-horizontal-from-peach-200-to-yellow-500 {
  background: linear-gradient(to right, #f8d8d4, #aa7b25);
}

.has-background-gradient-horizontal-from-peach-200-to-pink-100 {
  background: linear-gradient(to right, #f8d8d4, #fbeef0);
}

.has-background-gradient-horizontal-from-peach-200-to-pink-200 {
  background: linear-gradient(to right, #f8d8d4, #f2c6cd);
}

.has-background-gradient-horizontal-from-peach-200-to-pink-300 {
  background: linear-gradient(to right, #f8d8d4, #e58e9a);
}

.has-background-gradient-horizontal-from-peach-200-to-pink-400 {
  background: linear-gradient(to right, #f8d8d4, #d85568);
}

.has-background-gradient-horizontal-from-peach-200-to-pink-500 {
  background: linear-gradient(to right, #f8d8d4, #481c23);
}

.has-background-gradient-horizontal-from-peach-200-to-grey-100 {
  background: linear-gradient(to right, #f8d8d4, #fff);
}

.has-background-gradient-horizontal-from-peach-200-to-grey-200 {
  background: linear-gradient(to right, #f8d8d4, #e2e2e2);
}

.has-background-gradient-horizontal-from-peach-200-to-grey-300 {
  background: linear-gradient(to right, #f8d8d4, #a8a8a8);
}

.has-background-gradient-horizontal-from-peach-200-to-grey-400 {
  background: linear-gradient(to right, #f8d8d4, #616161);
}

.has-background-gradient-horizontal-from-peach-200-to-grey-500 {
  background: linear-gradient(to right, #f8d8d4, #393939);
}

.has-background-gradient-horizontal-from-peach-200-to-grey-600 {
  background: linear-gradient(to right, #f8d8d4, #000);
}

.has-background-gradient-horizontal-from-peach-300-to-purple-blue-100 {
  background: linear-gradient(to right, #f0b0a8, #f1f1fb);
}

.has-background-gradient-horizontal-from-peach-300-to-purple-blue-200 {
  background: linear-gradient(to right, #f0b0a8, #c9c9f0);
}

.has-background-gradient-horizontal-from-peach-300-to-purple-blue-300 {
  background: linear-gradient(to right, #f0b0a8, #a594f9);
}

.has-background-gradient-horizontal-from-peach-300-to-purple-blue-400 {
  background: linear-gradient(to right, #f0b0a8, #2727c4);
}

.has-background-gradient-horizontal-from-peach-300-to-purple-blue-500 {
  background: linear-gradient(to right, #f0b0a8, #1b0777);
}

.has-background-gradient-horizontal-from-peach-300-to-peach-100 {
  background: linear-gradient(to right, #f0b0a8, #fdf3f2);
}

.has-background-gradient-horizontal-from-peach-300-to-peach-200 {
  background: linear-gradient(to right, #f0b0a8, #f8d8d4);
}

.has-background-gradient-horizontal-from-peach-300-to-peach-300 {
  background: linear-gradient(to right, #f0b0a8, #f0b0a8);
}

.has-background-gradient-horizontal-from-peach-300-to-peach-400 {
  background: linear-gradient(to right, #f0b0a8, #e9897d);
}

.has-background-gradient-horizontal-from-peach-300-to-peach-500 {
  background: linear-gradient(to right, #f0b0a8, #9b5b53);
}

.has-background-gradient-horizontal-from-peach-300-to-yellow-100 {
  background: linear-gradient(to right, #f0b0a8, #fff8f1);
}

.has-background-gradient-horizontal-from-peach-300-to-yellow-200 {
  background: linear-gradient(to right, #f0b0a8, #ffe8bd);
}

.has-background-gradient-horizontal-from-peach-300-to-yellow-300 {
  background: linear-gradient(to right, #f0b0a8, #ffd07a);
}

.has-background-gradient-horizontal-from-peach-300-to-yellow-400 {
  background: linear-gradient(to right, #f0b0a8, #ffb938);
}

.has-background-gradient-horizontal-from-peach-300-to-yellow-500 {
  background: linear-gradient(to right, #f0b0a8, #aa7b25);
}

.has-background-gradient-horizontal-from-peach-300-to-pink-100 {
  background: linear-gradient(to right, #f0b0a8, #fbeef0);
}

.has-background-gradient-horizontal-from-peach-300-to-pink-200 {
  background: linear-gradient(to right, #f0b0a8, #f2c6cd);
}

.has-background-gradient-horizontal-from-peach-300-to-pink-300 {
  background: linear-gradient(to right, #f0b0a8, #e58e9a);
}

.has-background-gradient-horizontal-from-peach-300-to-pink-400 {
  background: linear-gradient(to right, #f0b0a8, #d85568);
}

.has-background-gradient-horizontal-from-peach-300-to-pink-500 {
  background: linear-gradient(to right, #f0b0a8, #481c23);
}

.has-background-gradient-horizontal-from-peach-300-to-grey-100 {
  background: linear-gradient(to right, #f0b0a8, #fff);
}

.has-background-gradient-horizontal-from-peach-300-to-grey-200 {
  background: linear-gradient(to right, #f0b0a8, #e2e2e2);
}

.has-background-gradient-horizontal-from-peach-300-to-grey-300 {
  background: linear-gradient(to right, #f0b0a8, #a8a8a8);
}

.has-background-gradient-horizontal-from-peach-300-to-grey-400 {
  background: linear-gradient(to right, #f0b0a8, #616161);
}

.has-background-gradient-horizontal-from-peach-300-to-grey-500 {
  background: linear-gradient(to right, #f0b0a8, #393939);
}

.has-background-gradient-horizontal-from-peach-300-to-grey-600 {
  background: linear-gradient(to right, #f0b0a8, #000);
}

.has-background-gradient-horizontal-from-peach-400-to-purple-blue-100 {
  background: linear-gradient(to right, #e9897d, #f1f1fb);
}

.has-background-gradient-horizontal-from-peach-400-to-purple-blue-200 {
  background: linear-gradient(to right, #e9897d, #c9c9f0);
}

.has-background-gradient-horizontal-from-peach-400-to-purple-blue-300 {
  background: linear-gradient(to right, #e9897d, #a594f9);
}

.has-background-gradient-horizontal-from-peach-400-to-purple-blue-400 {
  background: linear-gradient(to right, #e9897d, #2727c4);
}

.has-background-gradient-horizontal-from-peach-400-to-purple-blue-500 {
  background: linear-gradient(to right, #e9897d, #1b0777);
}

.has-background-gradient-horizontal-from-peach-400-to-peach-100 {
  background: linear-gradient(to right, #e9897d, #fdf3f2);
}

.has-background-gradient-horizontal-from-peach-400-to-peach-200 {
  background: linear-gradient(to right, #e9897d, #f8d8d4);
}

.has-background-gradient-horizontal-from-peach-400-to-peach-300 {
  background: linear-gradient(to right, #e9897d, #f0b0a8);
}

.has-background-gradient-horizontal-from-peach-400-to-peach-400 {
  background: linear-gradient(to right, #e9897d, #e9897d);
}

.has-background-gradient-horizontal-from-peach-400-to-peach-500 {
  background: linear-gradient(to right, #e9897d, #9b5b53);
}

.has-background-gradient-horizontal-from-peach-400-to-yellow-100 {
  background: linear-gradient(to right, #e9897d, #fff8f1);
}

.has-background-gradient-horizontal-from-peach-400-to-yellow-200 {
  background: linear-gradient(to right, #e9897d, #ffe8bd);
}

.has-background-gradient-horizontal-from-peach-400-to-yellow-300 {
  background: linear-gradient(to right, #e9897d, #ffd07a);
}

.has-background-gradient-horizontal-from-peach-400-to-yellow-400 {
  background: linear-gradient(to right, #e9897d, #ffb938);
}

.has-background-gradient-horizontal-from-peach-400-to-yellow-500 {
  background: linear-gradient(to right, #e9897d, #aa7b25);
}

.has-background-gradient-horizontal-from-peach-400-to-pink-100 {
  background: linear-gradient(to right, #e9897d, #fbeef0);
}

.has-background-gradient-horizontal-from-peach-400-to-pink-200 {
  background: linear-gradient(to right, #e9897d, #f2c6cd);
}

.has-background-gradient-horizontal-from-peach-400-to-pink-300 {
  background: linear-gradient(to right, #e9897d, #e58e9a);
}

.has-background-gradient-horizontal-from-peach-400-to-pink-400 {
  background: linear-gradient(to right, #e9897d, #d85568);
}

.has-background-gradient-horizontal-from-peach-400-to-pink-500 {
  background: linear-gradient(to right, #e9897d, #481c23);
}

.has-background-gradient-horizontal-from-peach-400-to-grey-100 {
  background: linear-gradient(to right, #e9897d, #fff);
}

.has-background-gradient-horizontal-from-peach-400-to-grey-200 {
  background: linear-gradient(to right, #e9897d, #e2e2e2);
}

.has-background-gradient-horizontal-from-peach-400-to-grey-300 {
  background: linear-gradient(to right, #e9897d, #a8a8a8);
}

.has-background-gradient-horizontal-from-peach-400-to-grey-400 {
  background: linear-gradient(to right, #e9897d, #616161);
}

.has-background-gradient-horizontal-from-peach-400-to-grey-500 {
  background: linear-gradient(to right, #e9897d, #393939);
}

.has-background-gradient-horizontal-from-peach-400-to-grey-600 {
  background: linear-gradient(to right, #e9897d, #000);
}

.has-background-gradient-horizontal-from-peach-500-to-purple-blue-100 {
  background: linear-gradient(to right, #9b5b53, #f1f1fb);
}

.has-background-gradient-horizontal-from-peach-500-to-purple-blue-200 {
  background: linear-gradient(to right, #9b5b53, #c9c9f0);
}

.has-background-gradient-horizontal-from-peach-500-to-purple-blue-300 {
  background: linear-gradient(to right, #9b5b53, #a594f9);
}

.has-background-gradient-horizontal-from-peach-500-to-purple-blue-400 {
  background: linear-gradient(to right, #9b5b53, #2727c4);
}

.has-background-gradient-horizontal-from-peach-500-to-purple-blue-500 {
  background: linear-gradient(to right, #9b5b53, #1b0777);
}

.has-background-gradient-horizontal-from-peach-500-to-peach-100 {
  background: linear-gradient(to right, #9b5b53, #fdf3f2);
}

.has-background-gradient-horizontal-from-peach-500-to-peach-200 {
  background: linear-gradient(to right, #9b5b53, #f8d8d4);
}

.has-background-gradient-horizontal-from-peach-500-to-peach-300 {
  background: linear-gradient(to right, #9b5b53, #f0b0a8);
}

.has-background-gradient-horizontal-from-peach-500-to-peach-400 {
  background: linear-gradient(to right, #9b5b53, #e9897d);
}

.has-background-gradient-horizontal-from-peach-500-to-peach-500 {
  background: linear-gradient(to right, #9b5b53, #9b5b53);
}

.has-background-gradient-horizontal-from-peach-500-to-yellow-100 {
  background: linear-gradient(to right, #9b5b53, #fff8f1);
}

.has-background-gradient-horizontal-from-peach-500-to-yellow-200 {
  background: linear-gradient(to right, #9b5b53, #ffe8bd);
}

.has-background-gradient-horizontal-from-peach-500-to-yellow-300 {
  background: linear-gradient(to right, #9b5b53, #ffd07a);
}

.has-background-gradient-horizontal-from-peach-500-to-yellow-400 {
  background: linear-gradient(to right, #9b5b53, #ffb938);
}

.has-background-gradient-horizontal-from-peach-500-to-yellow-500 {
  background: linear-gradient(to right, #9b5b53, #aa7b25);
}

.has-background-gradient-horizontal-from-peach-500-to-pink-100 {
  background: linear-gradient(to right, #9b5b53, #fbeef0);
}

.has-background-gradient-horizontal-from-peach-500-to-pink-200 {
  background: linear-gradient(to right, #9b5b53, #f2c6cd);
}

.has-background-gradient-horizontal-from-peach-500-to-pink-300 {
  background: linear-gradient(to right, #9b5b53, #e58e9a);
}

.has-background-gradient-horizontal-from-peach-500-to-pink-400 {
  background: linear-gradient(to right, #9b5b53, #d85568);
}

.has-background-gradient-horizontal-from-peach-500-to-pink-500 {
  background: linear-gradient(to right, #9b5b53, #481c23);
}

.has-background-gradient-horizontal-from-peach-500-to-grey-100 {
  background: linear-gradient(to right, #9b5b53, #fff);
}

.has-background-gradient-horizontal-from-peach-500-to-grey-200 {
  background: linear-gradient(to right, #9b5b53, #e2e2e2);
}

.has-background-gradient-horizontal-from-peach-500-to-grey-300 {
  background: linear-gradient(to right, #9b5b53, #a8a8a8);
}

.has-background-gradient-horizontal-from-peach-500-to-grey-400 {
  background: linear-gradient(to right, #9b5b53, #616161);
}

.has-background-gradient-horizontal-from-peach-500-to-grey-500 {
  background: linear-gradient(to right, #9b5b53, #393939);
}

.has-background-gradient-horizontal-from-peach-500-to-grey-600 {
  background: linear-gradient(to right, #9b5b53, #000);
}

.has-background-gradient-horizontal-from-yellow-100-to-purple-blue-100 {
  background: linear-gradient(to right, #fff8f1, #f1f1fb);
}

.has-background-gradient-horizontal-from-yellow-100-to-purple-blue-200 {
  background: linear-gradient(to right, #fff8f1, #c9c9f0);
}

.has-background-gradient-horizontal-from-yellow-100-to-purple-blue-300 {
  background: linear-gradient(to right, #fff8f1, #a594f9);
}

.has-background-gradient-horizontal-from-yellow-100-to-purple-blue-400 {
  background: linear-gradient(to right, #fff8f1, #2727c4);
}

.has-background-gradient-horizontal-from-yellow-100-to-purple-blue-500 {
  background: linear-gradient(to right, #fff8f1, #1b0777);
}

.has-background-gradient-horizontal-from-yellow-100-to-peach-100 {
  background: linear-gradient(to right, #fff8f1, #fdf3f2);
}

.has-background-gradient-horizontal-from-yellow-100-to-peach-200 {
  background: linear-gradient(to right, #fff8f1, #f8d8d4);
}

.has-background-gradient-horizontal-from-yellow-100-to-peach-300 {
  background: linear-gradient(to right, #fff8f1, #f0b0a8);
}

.has-background-gradient-horizontal-from-yellow-100-to-peach-400 {
  background: linear-gradient(to right, #fff8f1, #e9897d);
}

.has-background-gradient-horizontal-from-yellow-100-to-peach-500 {
  background: linear-gradient(to right, #fff8f1, #9b5b53);
}

.has-background-gradient-horizontal-from-yellow-100-to-yellow-100 {
  background: linear-gradient(to right, #fff8f1, #fff8f1);
}

.has-background-gradient-horizontal-from-yellow-100-to-yellow-200 {
  background: linear-gradient(to right, #fff8f1, #ffe8bd);
}

.has-background-gradient-horizontal-from-yellow-100-to-yellow-300 {
  background: linear-gradient(to right, #fff8f1, #ffd07a);
}

.has-background-gradient-horizontal-from-yellow-100-to-yellow-400 {
  background: linear-gradient(to right, #fff8f1, #ffb938);
}

.has-background-gradient-horizontal-from-yellow-100-to-yellow-500 {
  background: linear-gradient(to right, #fff8f1, #aa7b25);
}

.has-background-gradient-horizontal-from-yellow-100-to-pink-100 {
  background: linear-gradient(to right, #fff8f1, #fbeef0);
}

.has-background-gradient-horizontal-from-yellow-100-to-pink-200 {
  background: linear-gradient(to right, #fff8f1, #f2c6cd);
}

.has-background-gradient-horizontal-from-yellow-100-to-pink-300 {
  background: linear-gradient(to right, #fff8f1, #e58e9a);
}

.has-background-gradient-horizontal-from-yellow-100-to-pink-400 {
  background: linear-gradient(to right, #fff8f1, #d85568);
}

.has-background-gradient-horizontal-from-yellow-100-to-pink-500 {
  background: linear-gradient(to right, #fff8f1, #481c23);
}

.has-background-gradient-horizontal-from-yellow-100-to-grey-100 {
  background: linear-gradient(to right, #fff8f1, #fff);
}

.has-background-gradient-horizontal-from-yellow-100-to-grey-200 {
  background: linear-gradient(to right, #fff8f1, #e2e2e2);
}

.has-background-gradient-horizontal-from-yellow-100-to-grey-300 {
  background: linear-gradient(to right, #fff8f1, #a8a8a8);
}

.has-background-gradient-horizontal-from-yellow-100-to-grey-400 {
  background: linear-gradient(to right, #fff8f1, #616161);
}

.has-background-gradient-horizontal-from-yellow-100-to-grey-500 {
  background: linear-gradient(to right, #fff8f1, #393939);
}

.has-background-gradient-horizontal-from-yellow-100-to-grey-600 {
  background: linear-gradient(to right, #fff8f1, #000);
}

.has-background-gradient-horizontal-from-yellow-200-to-purple-blue-100 {
  background: linear-gradient(to right, #ffe8bd, #f1f1fb);
}

.has-background-gradient-horizontal-from-yellow-200-to-purple-blue-200 {
  background: linear-gradient(to right, #ffe8bd, #c9c9f0);
}

.has-background-gradient-horizontal-from-yellow-200-to-purple-blue-300 {
  background: linear-gradient(to right, #ffe8bd, #a594f9);
}

.has-background-gradient-horizontal-from-yellow-200-to-purple-blue-400 {
  background: linear-gradient(to right, #ffe8bd, #2727c4);
}

.has-background-gradient-horizontal-from-yellow-200-to-purple-blue-500 {
  background: linear-gradient(to right, #ffe8bd, #1b0777);
}

.has-background-gradient-horizontal-from-yellow-200-to-peach-100 {
  background: linear-gradient(to right, #ffe8bd, #fdf3f2);
}

.has-background-gradient-horizontal-from-yellow-200-to-peach-200 {
  background: linear-gradient(to right, #ffe8bd, #f8d8d4);
}

.has-background-gradient-horizontal-from-yellow-200-to-peach-300 {
  background: linear-gradient(to right, #ffe8bd, #f0b0a8);
}

.has-background-gradient-horizontal-from-yellow-200-to-peach-400 {
  background: linear-gradient(to right, #ffe8bd, #e9897d);
}

.has-background-gradient-horizontal-from-yellow-200-to-peach-500 {
  background: linear-gradient(to right, #ffe8bd, #9b5b53);
}

.has-background-gradient-horizontal-from-yellow-200-to-yellow-100 {
  background: linear-gradient(to right, #ffe8bd, #fff8f1);
}

.has-background-gradient-horizontal-from-yellow-200-to-yellow-200 {
  background: linear-gradient(to right, #ffe8bd, #ffe8bd);
}

.has-background-gradient-horizontal-from-yellow-200-to-yellow-300 {
  background: linear-gradient(to right, #ffe8bd, #ffd07a);
}

.has-background-gradient-horizontal-from-yellow-200-to-yellow-400 {
  background: linear-gradient(to right, #ffe8bd, #ffb938);
}

.has-background-gradient-horizontal-from-yellow-200-to-yellow-500 {
  background: linear-gradient(to right, #ffe8bd, #aa7b25);
}

.has-background-gradient-horizontal-from-yellow-200-to-pink-100 {
  background: linear-gradient(to right, #ffe8bd, #fbeef0);
}

.has-background-gradient-horizontal-from-yellow-200-to-pink-200 {
  background: linear-gradient(to right, #ffe8bd, #f2c6cd);
}

.has-background-gradient-horizontal-from-yellow-200-to-pink-300 {
  background: linear-gradient(to right, #ffe8bd, #e58e9a);
}

.has-background-gradient-horizontal-from-yellow-200-to-pink-400 {
  background: linear-gradient(to right, #ffe8bd, #d85568);
}

.has-background-gradient-horizontal-from-yellow-200-to-pink-500 {
  background: linear-gradient(to right, #ffe8bd, #481c23);
}

.has-background-gradient-horizontal-from-yellow-200-to-grey-100 {
  background: linear-gradient(to right, #ffe8bd, #fff);
}

.has-background-gradient-horizontal-from-yellow-200-to-grey-200 {
  background: linear-gradient(to right, #ffe8bd, #e2e2e2);
}

.has-background-gradient-horizontal-from-yellow-200-to-grey-300 {
  background: linear-gradient(to right, #ffe8bd, #a8a8a8);
}

.has-background-gradient-horizontal-from-yellow-200-to-grey-400 {
  background: linear-gradient(to right, #ffe8bd, #616161);
}

.has-background-gradient-horizontal-from-yellow-200-to-grey-500 {
  background: linear-gradient(to right, #ffe8bd, #393939);
}

.has-background-gradient-horizontal-from-yellow-200-to-grey-600 {
  background: linear-gradient(to right, #ffe8bd, #000);
}

.has-background-gradient-horizontal-from-yellow-300-to-purple-blue-100 {
  background: linear-gradient(to right, #ffd07a, #f1f1fb);
}

.has-background-gradient-horizontal-from-yellow-300-to-purple-blue-200 {
  background: linear-gradient(to right, #ffd07a, #c9c9f0);
}

.has-background-gradient-horizontal-from-yellow-300-to-purple-blue-300 {
  background: linear-gradient(to right, #ffd07a, #a594f9);
}

.has-background-gradient-horizontal-from-yellow-300-to-purple-blue-400 {
  background: linear-gradient(to right, #ffd07a, #2727c4);
}

.has-background-gradient-horizontal-from-yellow-300-to-purple-blue-500 {
  background: linear-gradient(to right, #ffd07a, #1b0777);
}

.has-background-gradient-horizontal-from-yellow-300-to-peach-100 {
  background: linear-gradient(to right, #ffd07a, #fdf3f2);
}

.has-background-gradient-horizontal-from-yellow-300-to-peach-200 {
  background: linear-gradient(to right, #ffd07a, #f8d8d4);
}

.has-background-gradient-horizontal-from-yellow-300-to-peach-300 {
  background: linear-gradient(to right, #ffd07a, #f0b0a8);
}

.has-background-gradient-horizontal-from-yellow-300-to-peach-400 {
  background: linear-gradient(to right, #ffd07a, #e9897d);
}

.has-background-gradient-horizontal-from-yellow-300-to-peach-500 {
  background: linear-gradient(to right, #ffd07a, #9b5b53);
}

.has-background-gradient-horizontal-from-yellow-300-to-yellow-100 {
  background: linear-gradient(to right, #ffd07a, #fff8f1);
}

.has-background-gradient-horizontal-from-yellow-300-to-yellow-200 {
  background: linear-gradient(to right, #ffd07a, #ffe8bd);
}

.has-background-gradient-horizontal-from-yellow-300-to-yellow-300 {
  background: linear-gradient(to right, #ffd07a, #ffd07a);
}

.has-background-gradient-horizontal-from-yellow-300-to-yellow-400 {
  background: linear-gradient(to right, #ffd07a, #ffb938);
}

.has-background-gradient-horizontal-from-yellow-300-to-yellow-500 {
  background: linear-gradient(to right, #ffd07a, #aa7b25);
}

.has-background-gradient-horizontal-from-yellow-300-to-pink-100 {
  background: linear-gradient(to right, #ffd07a, #fbeef0);
}

.has-background-gradient-horizontal-from-yellow-300-to-pink-200 {
  background: linear-gradient(to right, #ffd07a, #f2c6cd);
}

.has-background-gradient-horizontal-from-yellow-300-to-pink-300 {
  background: linear-gradient(to right, #ffd07a, #e58e9a);
}

.has-background-gradient-horizontal-from-yellow-300-to-pink-400 {
  background: linear-gradient(to right, #ffd07a, #d85568);
}

.has-background-gradient-horizontal-from-yellow-300-to-pink-500 {
  background: linear-gradient(to right, #ffd07a, #481c23);
}

.has-background-gradient-horizontal-from-yellow-300-to-grey-100 {
  background: linear-gradient(to right, #ffd07a, #fff);
}

.has-background-gradient-horizontal-from-yellow-300-to-grey-200 {
  background: linear-gradient(to right, #ffd07a, #e2e2e2);
}

.has-background-gradient-horizontal-from-yellow-300-to-grey-300 {
  background: linear-gradient(to right, #ffd07a, #a8a8a8);
}

.has-background-gradient-horizontal-from-yellow-300-to-grey-400 {
  background: linear-gradient(to right, #ffd07a, #616161);
}

.has-background-gradient-horizontal-from-yellow-300-to-grey-500 {
  background: linear-gradient(to right, #ffd07a, #393939);
}

.has-background-gradient-horizontal-from-yellow-300-to-grey-600 {
  background: linear-gradient(to right, #ffd07a, #000);
}

.has-background-gradient-horizontal-from-yellow-400-to-purple-blue-100 {
  background: linear-gradient(to right, #ffb938, #f1f1fb);
}

.has-background-gradient-horizontal-from-yellow-400-to-purple-blue-200 {
  background: linear-gradient(to right, #ffb938, #c9c9f0);
}

.has-background-gradient-horizontal-from-yellow-400-to-purple-blue-300 {
  background: linear-gradient(to right, #ffb938, #a594f9);
}

.has-background-gradient-horizontal-from-yellow-400-to-purple-blue-400 {
  background: linear-gradient(to right, #ffb938, #2727c4);
}

.has-background-gradient-horizontal-from-yellow-400-to-purple-blue-500 {
  background: linear-gradient(to right, #ffb938, #1b0777);
}

.has-background-gradient-horizontal-from-yellow-400-to-peach-100 {
  background: linear-gradient(to right, #ffb938, #fdf3f2);
}

.has-background-gradient-horizontal-from-yellow-400-to-peach-200 {
  background: linear-gradient(to right, #ffb938, #f8d8d4);
}

.has-background-gradient-horizontal-from-yellow-400-to-peach-300 {
  background: linear-gradient(to right, #ffb938, #f0b0a8);
}

.has-background-gradient-horizontal-from-yellow-400-to-peach-400 {
  background: linear-gradient(to right, #ffb938, #e9897d);
}

.has-background-gradient-horizontal-from-yellow-400-to-peach-500 {
  background: linear-gradient(to right, #ffb938, #9b5b53);
}

.has-background-gradient-horizontal-from-yellow-400-to-yellow-100 {
  background: linear-gradient(to right, #ffb938, #fff8f1);
}

.has-background-gradient-horizontal-from-yellow-400-to-yellow-200 {
  background: linear-gradient(to right, #ffb938, #ffe8bd);
}

.has-background-gradient-horizontal-from-yellow-400-to-yellow-300 {
  background: linear-gradient(to right, #ffb938, #ffd07a);
}

.has-background-gradient-horizontal-from-yellow-400-to-yellow-400 {
  background: linear-gradient(to right, #ffb938, #ffb938);
}

.has-background-gradient-horizontal-from-yellow-400-to-yellow-500 {
  background: linear-gradient(to right, #ffb938, #aa7b25);
}

.has-background-gradient-horizontal-from-yellow-400-to-pink-100 {
  background: linear-gradient(to right, #ffb938, #fbeef0);
}

.has-background-gradient-horizontal-from-yellow-400-to-pink-200 {
  background: linear-gradient(to right, #ffb938, #f2c6cd);
}

.has-background-gradient-horizontal-from-yellow-400-to-pink-300 {
  background: linear-gradient(to right, #ffb938, #e58e9a);
}

.has-background-gradient-horizontal-from-yellow-400-to-pink-400 {
  background: linear-gradient(to right, #ffb938, #d85568);
}

.has-background-gradient-horizontal-from-yellow-400-to-pink-500 {
  background: linear-gradient(to right, #ffb938, #481c23);
}

.has-background-gradient-horizontal-from-yellow-400-to-grey-100 {
  background: linear-gradient(to right, #ffb938, #fff);
}

.has-background-gradient-horizontal-from-yellow-400-to-grey-200 {
  background: linear-gradient(to right, #ffb938, #e2e2e2);
}

.has-background-gradient-horizontal-from-yellow-400-to-grey-300 {
  background: linear-gradient(to right, #ffb938, #a8a8a8);
}

.has-background-gradient-horizontal-from-yellow-400-to-grey-400 {
  background: linear-gradient(to right, #ffb938, #616161);
}

.has-background-gradient-horizontal-from-yellow-400-to-grey-500 {
  background: linear-gradient(to right, #ffb938, #393939);
}

.has-background-gradient-horizontal-from-yellow-400-to-grey-600 {
  background: linear-gradient(to right, #ffb938, #000);
}

.has-background-gradient-horizontal-from-yellow-500-to-purple-blue-100 {
  background: linear-gradient(to right, #aa7b25, #f1f1fb);
}

.has-background-gradient-horizontal-from-yellow-500-to-purple-blue-200 {
  background: linear-gradient(to right, #aa7b25, #c9c9f0);
}

.has-background-gradient-horizontal-from-yellow-500-to-purple-blue-300 {
  background: linear-gradient(to right, #aa7b25, #a594f9);
}

.has-background-gradient-horizontal-from-yellow-500-to-purple-blue-400 {
  background: linear-gradient(to right, #aa7b25, #2727c4);
}

.has-background-gradient-horizontal-from-yellow-500-to-purple-blue-500 {
  background: linear-gradient(to right, #aa7b25, #1b0777);
}

.has-background-gradient-horizontal-from-yellow-500-to-peach-100 {
  background: linear-gradient(to right, #aa7b25, #fdf3f2);
}

.has-background-gradient-horizontal-from-yellow-500-to-peach-200 {
  background: linear-gradient(to right, #aa7b25, #f8d8d4);
}

.has-background-gradient-horizontal-from-yellow-500-to-peach-300 {
  background: linear-gradient(to right, #aa7b25, #f0b0a8);
}

.has-background-gradient-horizontal-from-yellow-500-to-peach-400 {
  background: linear-gradient(to right, #aa7b25, #e9897d);
}

.has-background-gradient-horizontal-from-yellow-500-to-peach-500 {
  background: linear-gradient(to right, #aa7b25, #9b5b53);
}

.has-background-gradient-horizontal-from-yellow-500-to-yellow-100 {
  background: linear-gradient(to right, #aa7b25, #fff8f1);
}

.has-background-gradient-horizontal-from-yellow-500-to-yellow-200 {
  background: linear-gradient(to right, #aa7b25, #ffe8bd);
}

.has-background-gradient-horizontal-from-yellow-500-to-yellow-300 {
  background: linear-gradient(to right, #aa7b25, #ffd07a);
}

.has-background-gradient-horizontal-from-yellow-500-to-yellow-400 {
  background: linear-gradient(to right, #aa7b25, #ffb938);
}

.has-background-gradient-horizontal-from-yellow-500-to-yellow-500 {
  background: linear-gradient(to right, #aa7b25, #aa7b25);
}

.has-background-gradient-horizontal-from-yellow-500-to-pink-100 {
  background: linear-gradient(to right, #aa7b25, #fbeef0);
}

.has-background-gradient-horizontal-from-yellow-500-to-pink-200 {
  background: linear-gradient(to right, #aa7b25, #f2c6cd);
}

.has-background-gradient-horizontal-from-yellow-500-to-pink-300 {
  background: linear-gradient(to right, #aa7b25, #e58e9a);
}

.has-background-gradient-horizontal-from-yellow-500-to-pink-400 {
  background: linear-gradient(to right, #aa7b25, #d85568);
}

.has-background-gradient-horizontal-from-yellow-500-to-pink-500 {
  background: linear-gradient(to right, #aa7b25, #481c23);
}

.has-background-gradient-horizontal-from-yellow-500-to-grey-100 {
  background: linear-gradient(to right, #aa7b25, #fff);
}

.has-background-gradient-horizontal-from-yellow-500-to-grey-200 {
  background: linear-gradient(to right, #aa7b25, #e2e2e2);
}

.has-background-gradient-horizontal-from-yellow-500-to-grey-300 {
  background: linear-gradient(to right, #aa7b25, #a8a8a8);
}

.has-background-gradient-horizontal-from-yellow-500-to-grey-400 {
  background: linear-gradient(to right, #aa7b25, #616161);
}

.has-background-gradient-horizontal-from-yellow-500-to-grey-500 {
  background: linear-gradient(to right, #aa7b25, #393939);
}

.has-background-gradient-horizontal-from-yellow-500-to-grey-600 {
  background: linear-gradient(to right, #aa7b25, #000);
}

.has-background-gradient-horizontal-from-pink-100-to-purple-blue-100 {
  background: linear-gradient(to right, #fbeef0, #f1f1fb);
}

.has-background-gradient-horizontal-from-pink-100-to-purple-blue-200 {
  background: linear-gradient(to right, #fbeef0, #c9c9f0);
}

.has-background-gradient-horizontal-from-pink-100-to-purple-blue-300 {
  background: linear-gradient(to right, #fbeef0, #a594f9);
}

.has-background-gradient-horizontal-from-pink-100-to-purple-blue-400 {
  background: linear-gradient(to right, #fbeef0, #2727c4);
}

.has-background-gradient-horizontal-from-pink-100-to-purple-blue-500 {
  background: linear-gradient(to right, #fbeef0, #1b0777);
}

.has-background-gradient-horizontal-from-pink-100-to-peach-100 {
  background: linear-gradient(to right, #fbeef0, #fdf3f2);
}

.has-background-gradient-horizontal-from-pink-100-to-peach-200 {
  background: linear-gradient(to right, #fbeef0, #f8d8d4);
}

.has-background-gradient-horizontal-from-pink-100-to-peach-300 {
  background: linear-gradient(to right, #fbeef0, #f0b0a8);
}

.has-background-gradient-horizontal-from-pink-100-to-peach-400 {
  background: linear-gradient(to right, #fbeef0, #e9897d);
}

.has-background-gradient-horizontal-from-pink-100-to-peach-500 {
  background: linear-gradient(to right, #fbeef0, #9b5b53);
}

.has-background-gradient-horizontal-from-pink-100-to-yellow-100 {
  background: linear-gradient(to right, #fbeef0, #fff8f1);
}

.has-background-gradient-horizontal-from-pink-100-to-yellow-200 {
  background: linear-gradient(to right, #fbeef0, #ffe8bd);
}

.has-background-gradient-horizontal-from-pink-100-to-yellow-300 {
  background: linear-gradient(to right, #fbeef0, #ffd07a);
}

.has-background-gradient-horizontal-from-pink-100-to-yellow-400 {
  background: linear-gradient(to right, #fbeef0, #ffb938);
}

.has-background-gradient-horizontal-from-pink-100-to-yellow-500 {
  background: linear-gradient(to right, #fbeef0, #aa7b25);
}

.has-background-gradient-horizontal-from-pink-100-to-pink-100 {
  background: linear-gradient(to right, #fbeef0, #fbeef0);
}

.has-background-gradient-horizontal-from-pink-100-to-pink-200 {
  background: linear-gradient(to right, #fbeef0, #f2c6cd);
}

.has-background-gradient-horizontal-from-pink-100-to-pink-300 {
  background: linear-gradient(to right, #fbeef0, #e58e9a);
}

.has-background-gradient-horizontal-from-pink-100-to-pink-400 {
  background: linear-gradient(to right, #fbeef0, #d85568);
}

.has-background-gradient-horizontal-from-pink-100-to-pink-500 {
  background: linear-gradient(to right, #fbeef0, #481c23);
}

.has-background-gradient-horizontal-from-pink-100-to-grey-100 {
  background: linear-gradient(to right, #fbeef0, #fff);
}

.has-background-gradient-horizontal-from-pink-100-to-grey-200 {
  background: linear-gradient(to right, #fbeef0, #e2e2e2);
}

.has-background-gradient-horizontal-from-pink-100-to-grey-300 {
  background: linear-gradient(to right, #fbeef0, #a8a8a8);
}

.has-background-gradient-horizontal-from-pink-100-to-grey-400 {
  background: linear-gradient(to right, #fbeef0, #616161);
}

.has-background-gradient-horizontal-from-pink-100-to-grey-500 {
  background: linear-gradient(to right, #fbeef0, #393939);
}

.has-background-gradient-horizontal-from-pink-100-to-grey-600 {
  background: linear-gradient(to right, #fbeef0, #000);
}

.has-background-gradient-horizontal-from-pink-200-to-purple-blue-100 {
  background: linear-gradient(to right, #f2c6cd, #f1f1fb);
}

.has-background-gradient-horizontal-from-pink-200-to-purple-blue-200 {
  background: linear-gradient(to right, #f2c6cd, #c9c9f0);
}

.has-background-gradient-horizontal-from-pink-200-to-purple-blue-300 {
  background: linear-gradient(to right, #f2c6cd, #a594f9);
}

.has-background-gradient-horizontal-from-pink-200-to-purple-blue-400 {
  background: linear-gradient(to right, #f2c6cd, #2727c4);
}

.has-background-gradient-horizontal-from-pink-200-to-purple-blue-500 {
  background: linear-gradient(to right, #f2c6cd, #1b0777);
}

.has-background-gradient-horizontal-from-pink-200-to-peach-100 {
  background: linear-gradient(to right, #f2c6cd, #fdf3f2);
}

.has-background-gradient-horizontal-from-pink-200-to-peach-200 {
  background: linear-gradient(to right, #f2c6cd, #f8d8d4);
}

.has-background-gradient-horizontal-from-pink-200-to-peach-300 {
  background: linear-gradient(to right, #f2c6cd, #f0b0a8);
}

.has-background-gradient-horizontal-from-pink-200-to-peach-400 {
  background: linear-gradient(to right, #f2c6cd, #e9897d);
}

.has-background-gradient-horizontal-from-pink-200-to-peach-500 {
  background: linear-gradient(to right, #f2c6cd, #9b5b53);
}

.has-background-gradient-horizontal-from-pink-200-to-yellow-100 {
  background: linear-gradient(to right, #f2c6cd, #fff8f1);
}

.has-background-gradient-horizontal-from-pink-200-to-yellow-200 {
  background: linear-gradient(to right, #f2c6cd, #ffe8bd);
}

.has-background-gradient-horizontal-from-pink-200-to-yellow-300 {
  background: linear-gradient(to right, #f2c6cd, #ffd07a);
}

.has-background-gradient-horizontal-from-pink-200-to-yellow-400 {
  background: linear-gradient(to right, #f2c6cd, #ffb938);
}

.has-background-gradient-horizontal-from-pink-200-to-yellow-500 {
  background: linear-gradient(to right, #f2c6cd, #aa7b25);
}

.has-background-gradient-horizontal-from-pink-200-to-pink-100 {
  background: linear-gradient(to right, #f2c6cd, #fbeef0);
}

.has-background-gradient-horizontal-from-pink-200-to-pink-200 {
  background: linear-gradient(to right, #f2c6cd, #f2c6cd);
}

.has-background-gradient-horizontal-from-pink-200-to-pink-300 {
  background: linear-gradient(to right, #f2c6cd, #e58e9a);
}

.has-background-gradient-horizontal-from-pink-200-to-pink-400 {
  background: linear-gradient(to right, #f2c6cd, #d85568);
}

.has-background-gradient-horizontal-from-pink-200-to-pink-500 {
  background: linear-gradient(to right, #f2c6cd, #481c23);
}

.has-background-gradient-horizontal-from-pink-200-to-grey-100 {
  background: linear-gradient(to right, #f2c6cd, #fff);
}

.has-background-gradient-horizontal-from-pink-200-to-grey-200 {
  background: linear-gradient(to right, #f2c6cd, #e2e2e2);
}

.has-background-gradient-horizontal-from-pink-200-to-grey-300 {
  background: linear-gradient(to right, #f2c6cd, #a8a8a8);
}

.has-background-gradient-horizontal-from-pink-200-to-grey-400 {
  background: linear-gradient(to right, #f2c6cd, #616161);
}

.has-background-gradient-horizontal-from-pink-200-to-grey-500 {
  background: linear-gradient(to right, #f2c6cd, #393939);
}

.has-background-gradient-horizontal-from-pink-200-to-grey-600 {
  background: linear-gradient(to right, #f2c6cd, #000);
}

.has-background-gradient-horizontal-from-pink-300-to-purple-blue-100 {
  background: linear-gradient(to right, #e58e9a, #f1f1fb);
}

.has-background-gradient-horizontal-from-pink-300-to-purple-blue-200 {
  background: linear-gradient(to right, #e58e9a, #c9c9f0);
}

.has-background-gradient-horizontal-from-pink-300-to-purple-blue-300 {
  background: linear-gradient(to right, #e58e9a, #a594f9);
}

.has-background-gradient-horizontal-from-pink-300-to-purple-blue-400 {
  background: linear-gradient(to right, #e58e9a, #2727c4);
}

.has-background-gradient-horizontal-from-pink-300-to-purple-blue-500 {
  background: linear-gradient(to right, #e58e9a, #1b0777);
}

.has-background-gradient-horizontal-from-pink-300-to-peach-100 {
  background: linear-gradient(to right, #e58e9a, #fdf3f2);
}

.has-background-gradient-horizontal-from-pink-300-to-peach-200 {
  background: linear-gradient(to right, #e58e9a, #f8d8d4);
}

.has-background-gradient-horizontal-from-pink-300-to-peach-300 {
  background: linear-gradient(to right, #e58e9a, #f0b0a8);
}

.has-background-gradient-horizontal-from-pink-300-to-peach-400 {
  background: linear-gradient(to right, #e58e9a, #e9897d);
}

.has-background-gradient-horizontal-from-pink-300-to-peach-500 {
  background: linear-gradient(to right, #e58e9a, #9b5b53);
}

.has-background-gradient-horizontal-from-pink-300-to-yellow-100 {
  background: linear-gradient(to right, #e58e9a, #fff8f1);
}

.has-background-gradient-horizontal-from-pink-300-to-yellow-200 {
  background: linear-gradient(to right, #e58e9a, #ffe8bd);
}

.has-background-gradient-horizontal-from-pink-300-to-yellow-300 {
  background: linear-gradient(to right, #e58e9a, #ffd07a);
}

.has-background-gradient-horizontal-from-pink-300-to-yellow-400 {
  background: linear-gradient(to right, #e58e9a, #ffb938);
}

.has-background-gradient-horizontal-from-pink-300-to-yellow-500 {
  background: linear-gradient(to right, #e58e9a, #aa7b25);
}

.has-background-gradient-horizontal-from-pink-300-to-pink-100 {
  background: linear-gradient(to right, #e58e9a, #fbeef0);
}

.has-background-gradient-horizontal-from-pink-300-to-pink-200 {
  background: linear-gradient(to right, #e58e9a, #f2c6cd);
}

.has-background-gradient-horizontal-from-pink-300-to-pink-300 {
  background: linear-gradient(to right, #e58e9a, #e58e9a);
}

.has-background-gradient-horizontal-from-pink-300-to-pink-400 {
  background: linear-gradient(to right, #e58e9a, #d85568);
}

.has-background-gradient-horizontal-from-pink-300-to-pink-500 {
  background: linear-gradient(to right, #e58e9a, #481c23);
}

.has-background-gradient-horizontal-from-pink-300-to-grey-100 {
  background: linear-gradient(to right, #e58e9a, #fff);
}

.has-background-gradient-horizontal-from-pink-300-to-grey-200 {
  background: linear-gradient(to right, #e58e9a, #e2e2e2);
}

.has-background-gradient-horizontal-from-pink-300-to-grey-300 {
  background: linear-gradient(to right, #e58e9a, #a8a8a8);
}

.has-background-gradient-horizontal-from-pink-300-to-grey-400 {
  background: linear-gradient(to right, #e58e9a, #616161);
}

.has-background-gradient-horizontal-from-pink-300-to-grey-500 {
  background: linear-gradient(to right, #e58e9a, #393939);
}

.has-background-gradient-horizontal-from-pink-300-to-grey-600 {
  background: linear-gradient(to right, #e58e9a, #000);
}

.has-background-gradient-horizontal-from-pink-400-to-purple-blue-100 {
  background: linear-gradient(to right, #d85568, #f1f1fb);
}

.has-background-gradient-horizontal-from-pink-400-to-purple-blue-200 {
  background: linear-gradient(to right, #d85568, #c9c9f0);
}

.has-background-gradient-horizontal-from-pink-400-to-purple-blue-300 {
  background: linear-gradient(to right, #d85568, #a594f9);
}

.has-background-gradient-horizontal-from-pink-400-to-purple-blue-400 {
  background: linear-gradient(to right, #d85568, #2727c4);
}

.has-background-gradient-horizontal-from-pink-400-to-purple-blue-500 {
  background: linear-gradient(to right, #d85568, #1b0777);
}

.has-background-gradient-horizontal-from-pink-400-to-peach-100 {
  background: linear-gradient(to right, #d85568, #fdf3f2);
}

.has-background-gradient-horizontal-from-pink-400-to-peach-200 {
  background: linear-gradient(to right, #d85568, #f8d8d4);
}

.has-background-gradient-horizontal-from-pink-400-to-peach-300 {
  background: linear-gradient(to right, #d85568, #f0b0a8);
}

.has-background-gradient-horizontal-from-pink-400-to-peach-400 {
  background: linear-gradient(to right, #d85568, #e9897d);
}

.has-background-gradient-horizontal-from-pink-400-to-peach-500 {
  background: linear-gradient(to right, #d85568, #9b5b53);
}

.has-background-gradient-horizontal-from-pink-400-to-yellow-100 {
  background: linear-gradient(to right, #d85568, #fff8f1);
}

.has-background-gradient-horizontal-from-pink-400-to-yellow-200 {
  background: linear-gradient(to right, #d85568, #ffe8bd);
}

.has-background-gradient-horizontal-from-pink-400-to-yellow-300 {
  background: linear-gradient(to right, #d85568, #ffd07a);
}

.has-background-gradient-horizontal-from-pink-400-to-yellow-400 {
  background: linear-gradient(to right, #d85568, #ffb938);
}

.has-background-gradient-horizontal-from-pink-400-to-yellow-500 {
  background: linear-gradient(to right, #d85568, #aa7b25);
}

.has-background-gradient-horizontal-from-pink-400-to-pink-100 {
  background: linear-gradient(to right, #d85568, #fbeef0);
}

.has-background-gradient-horizontal-from-pink-400-to-pink-200 {
  background: linear-gradient(to right, #d85568, #f2c6cd);
}

.has-background-gradient-horizontal-from-pink-400-to-pink-300 {
  background: linear-gradient(to right, #d85568, #e58e9a);
}

.has-background-gradient-horizontal-from-pink-400-to-pink-400 {
  background: linear-gradient(to right, #d85568, #d85568);
}

.has-background-gradient-horizontal-from-pink-400-to-pink-500 {
  background: linear-gradient(to right, #d85568, #481c23);
}

.has-background-gradient-horizontal-from-pink-400-to-grey-100 {
  background: linear-gradient(to right, #d85568, #fff);
}

.has-background-gradient-horizontal-from-pink-400-to-grey-200 {
  background: linear-gradient(to right, #d85568, #e2e2e2);
}

.has-background-gradient-horizontal-from-pink-400-to-grey-300 {
  background: linear-gradient(to right, #d85568, #a8a8a8);
}

.has-background-gradient-horizontal-from-pink-400-to-grey-400 {
  background: linear-gradient(to right, #d85568, #616161);
}

.has-background-gradient-horizontal-from-pink-400-to-grey-500 {
  background: linear-gradient(to right, #d85568, #393939);
}

.has-background-gradient-horizontal-from-pink-400-to-grey-600 {
  background: linear-gradient(to right, #d85568, #000);
}

.has-background-gradient-horizontal-from-pink-500-to-purple-blue-100 {
  background: linear-gradient(to right, #481c23, #f1f1fb);
}

.has-background-gradient-horizontal-from-pink-500-to-purple-blue-200 {
  background: linear-gradient(to right, #481c23, #c9c9f0);
}

.has-background-gradient-horizontal-from-pink-500-to-purple-blue-300 {
  background: linear-gradient(to right, #481c23, #a594f9);
}

.has-background-gradient-horizontal-from-pink-500-to-purple-blue-400 {
  background: linear-gradient(to right, #481c23, #2727c4);
}

.has-background-gradient-horizontal-from-pink-500-to-purple-blue-500 {
  background: linear-gradient(to right, #481c23, #1b0777);
}

.has-background-gradient-horizontal-from-pink-500-to-peach-100 {
  background: linear-gradient(to right, #481c23, #fdf3f2);
}

.has-background-gradient-horizontal-from-pink-500-to-peach-200 {
  background: linear-gradient(to right, #481c23, #f8d8d4);
}

.has-background-gradient-horizontal-from-pink-500-to-peach-300 {
  background: linear-gradient(to right, #481c23, #f0b0a8);
}

.has-background-gradient-horizontal-from-pink-500-to-peach-400 {
  background: linear-gradient(to right, #481c23, #e9897d);
}

.has-background-gradient-horizontal-from-pink-500-to-peach-500 {
  background: linear-gradient(to right, #481c23, #9b5b53);
}

.has-background-gradient-horizontal-from-pink-500-to-yellow-100 {
  background: linear-gradient(to right, #481c23, #fff8f1);
}

.has-background-gradient-horizontal-from-pink-500-to-yellow-200 {
  background: linear-gradient(to right, #481c23, #ffe8bd);
}

.has-background-gradient-horizontal-from-pink-500-to-yellow-300 {
  background: linear-gradient(to right, #481c23, #ffd07a);
}

.has-background-gradient-horizontal-from-pink-500-to-yellow-400 {
  background: linear-gradient(to right, #481c23, #ffb938);
}

.has-background-gradient-horizontal-from-pink-500-to-yellow-500 {
  background: linear-gradient(to right, #481c23, #aa7b25);
}

.has-background-gradient-horizontal-from-pink-500-to-pink-100 {
  background: linear-gradient(to right, #481c23, #fbeef0);
}

.has-background-gradient-horizontal-from-pink-500-to-pink-200 {
  background: linear-gradient(to right, #481c23, #f2c6cd);
}

.has-background-gradient-horizontal-from-pink-500-to-pink-300 {
  background: linear-gradient(to right, #481c23, #e58e9a);
}

.has-background-gradient-horizontal-from-pink-500-to-pink-400 {
  background: linear-gradient(to right, #481c23, #d85568);
}

.has-background-gradient-horizontal-from-pink-500-to-pink-500 {
  background: linear-gradient(to right, #481c23, #481c23);
}

.has-background-gradient-horizontal-from-pink-500-to-grey-100 {
  background: linear-gradient(to right, #481c23, #fff);
}

.has-background-gradient-horizontal-from-pink-500-to-grey-200 {
  background: linear-gradient(to right, #481c23, #e2e2e2);
}

.has-background-gradient-horizontal-from-pink-500-to-grey-300 {
  background: linear-gradient(to right, #481c23, #a8a8a8);
}

.has-background-gradient-horizontal-from-pink-500-to-grey-400 {
  background: linear-gradient(to right, #481c23, #616161);
}

.has-background-gradient-horizontal-from-pink-500-to-grey-500 {
  background: linear-gradient(to right, #481c23, #393939);
}

.has-background-gradient-horizontal-from-pink-500-to-grey-600 {
  background: linear-gradient(to right, #481c23, #000);
}

.has-background-gradient-horizontal-from-grey-100-to-purple-blue-100 {
  background: linear-gradient(to right, #fff, #f1f1fb);
}

.has-background-gradient-horizontal-from-grey-100-to-purple-blue-200 {
  background: linear-gradient(to right, #fff, #c9c9f0);
}

.has-background-gradient-horizontal-from-grey-100-to-purple-blue-300 {
  background: linear-gradient(to right, #fff, #a594f9);
}

.has-background-gradient-horizontal-from-grey-100-to-purple-blue-400 {
  background: linear-gradient(to right, #fff, #2727c4);
}

.has-background-gradient-horizontal-from-grey-100-to-purple-blue-500 {
  background: linear-gradient(to right, #fff, #1b0777);
}

.has-background-gradient-horizontal-from-grey-100-to-peach-100 {
  background: linear-gradient(to right, #fff, #fdf3f2);
}

.has-background-gradient-horizontal-from-grey-100-to-peach-200 {
  background: linear-gradient(to right, #fff, #f8d8d4);
}

.has-background-gradient-horizontal-from-grey-100-to-peach-300 {
  background: linear-gradient(to right, #fff, #f0b0a8);
}

.has-background-gradient-horizontal-from-grey-100-to-peach-400 {
  background: linear-gradient(to right, #fff, #e9897d);
}

.has-background-gradient-horizontal-from-grey-100-to-peach-500 {
  background: linear-gradient(to right, #fff, #9b5b53);
}

.has-background-gradient-horizontal-from-grey-100-to-yellow-100 {
  background: linear-gradient(to right, #fff, #fff8f1);
}

.has-background-gradient-horizontal-from-grey-100-to-yellow-200 {
  background: linear-gradient(to right, #fff, #ffe8bd);
}

.has-background-gradient-horizontal-from-grey-100-to-yellow-300 {
  background: linear-gradient(to right, #fff, #ffd07a);
}

.has-background-gradient-horizontal-from-grey-100-to-yellow-400 {
  background: linear-gradient(to right, #fff, #ffb938);
}

.has-background-gradient-horizontal-from-grey-100-to-yellow-500 {
  background: linear-gradient(to right, #fff, #aa7b25);
}

.has-background-gradient-horizontal-from-grey-100-to-pink-100 {
  background: linear-gradient(to right, #fff, #fbeef0);
}

.has-background-gradient-horizontal-from-grey-100-to-pink-200 {
  background: linear-gradient(to right, #fff, #f2c6cd);
}

.has-background-gradient-horizontal-from-grey-100-to-pink-300 {
  background: linear-gradient(to right, #fff, #e58e9a);
}

.has-background-gradient-horizontal-from-grey-100-to-pink-400 {
  background: linear-gradient(to right, #fff, #d85568);
}

.has-background-gradient-horizontal-from-grey-100-to-pink-500 {
  background: linear-gradient(to right, #fff, #481c23);
}

.has-background-gradient-horizontal-from-grey-100-to-grey-100 {
  background: linear-gradient(to right, #fff, #fff);
}

.has-background-gradient-horizontal-from-grey-100-to-grey-200 {
  background: linear-gradient(to right, #fff, #e2e2e2);
}

.has-background-gradient-horizontal-from-grey-100-to-grey-300 {
  background: linear-gradient(to right, #fff, #a8a8a8);
}

.has-background-gradient-horizontal-from-grey-100-to-grey-400 {
  background: linear-gradient(to right, #fff, #616161);
}

.has-background-gradient-horizontal-from-grey-100-to-grey-500 {
  background: linear-gradient(to right, #fff, #393939);
}

.has-background-gradient-horizontal-from-grey-100-to-grey-600 {
  background: linear-gradient(to right, #fff, #000);
}

.has-background-gradient-horizontal-from-grey-200-to-purple-blue-100 {
  background: linear-gradient(to right, #e2e2e2, #f1f1fb);
}

.has-background-gradient-horizontal-from-grey-200-to-purple-blue-200 {
  background: linear-gradient(to right, #e2e2e2, #c9c9f0);
}

.has-background-gradient-horizontal-from-grey-200-to-purple-blue-300 {
  background: linear-gradient(to right, #e2e2e2, #a594f9);
}

.has-background-gradient-horizontal-from-grey-200-to-purple-blue-400 {
  background: linear-gradient(to right, #e2e2e2, #2727c4);
}

.has-background-gradient-horizontal-from-grey-200-to-purple-blue-500 {
  background: linear-gradient(to right, #e2e2e2, #1b0777);
}

.has-background-gradient-horizontal-from-grey-200-to-peach-100 {
  background: linear-gradient(to right, #e2e2e2, #fdf3f2);
}

.has-background-gradient-horizontal-from-grey-200-to-peach-200 {
  background: linear-gradient(to right, #e2e2e2, #f8d8d4);
}

.has-background-gradient-horizontal-from-grey-200-to-peach-300 {
  background: linear-gradient(to right, #e2e2e2, #f0b0a8);
}

.has-background-gradient-horizontal-from-grey-200-to-peach-400 {
  background: linear-gradient(to right, #e2e2e2, #e9897d);
}

.has-background-gradient-horizontal-from-grey-200-to-peach-500 {
  background: linear-gradient(to right, #e2e2e2, #9b5b53);
}

.has-background-gradient-horizontal-from-grey-200-to-yellow-100 {
  background: linear-gradient(to right, #e2e2e2, #fff8f1);
}

.has-background-gradient-horizontal-from-grey-200-to-yellow-200 {
  background: linear-gradient(to right, #e2e2e2, #ffe8bd);
}

.has-background-gradient-horizontal-from-grey-200-to-yellow-300 {
  background: linear-gradient(to right, #e2e2e2, #ffd07a);
}

.has-background-gradient-horizontal-from-grey-200-to-yellow-400 {
  background: linear-gradient(to right, #e2e2e2, #ffb938);
}

.has-background-gradient-horizontal-from-grey-200-to-yellow-500 {
  background: linear-gradient(to right, #e2e2e2, #aa7b25);
}

.has-background-gradient-horizontal-from-grey-200-to-pink-100 {
  background: linear-gradient(to right, #e2e2e2, #fbeef0);
}

.has-background-gradient-horizontal-from-grey-200-to-pink-200 {
  background: linear-gradient(to right, #e2e2e2, #f2c6cd);
}

.has-background-gradient-horizontal-from-grey-200-to-pink-300 {
  background: linear-gradient(to right, #e2e2e2, #e58e9a);
}

.has-background-gradient-horizontal-from-grey-200-to-pink-400 {
  background: linear-gradient(to right, #e2e2e2, #d85568);
}

.has-background-gradient-horizontal-from-grey-200-to-pink-500 {
  background: linear-gradient(to right, #e2e2e2, #481c23);
}

.has-background-gradient-horizontal-from-grey-200-to-grey-100 {
  background: linear-gradient(to right, #e2e2e2, #fff);
}

.has-background-gradient-horizontal-from-grey-200-to-grey-200 {
  background: linear-gradient(to right, #e2e2e2, #e2e2e2);
}

.has-background-gradient-horizontal-from-grey-200-to-grey-300 {
  background: linear-gradient(to right, #e2e2e2, #a8a8a8);
}

.has-background-gradient-horizontal-from-grey-200-to-grey-400 {
  background: linear-gradient(to right, #e2e2e2, #616161);
}

.has-background-gradient-horizontal-from-grey-200-to-grey-500 {
  background: linear-gradient(to right, #e2e2e2, #393939);
}

.has-background-gradient-horizontal-from-grey-200-to-grey-600 {
  background: linear-gradient(to right, #e2e2e2, #000);
}

.has-background-gradient-horizontal-from-grey-300-to-purple-blue-100 {
  background: linear-gradient(to right, #a8a8a8, #f1f1fb);
}

.has-background-gradient-horizontal-from-grey-300-to-purple-blue-200 {
  background: linear-gradient(to right, #a8a8a8, #c9c9f0);
}

.has-background-gradient-horizontal-from-grey-300-to-purple-blue-300 {
  background: linear-gradient(to right, #a8a8a8, #a594f9);
}

.has-background-gradient-horizontal-from-grey-300-to-purple-blue-400 {
  background: linear-gradient(to right, #a8a8a8, #2727c4);
}

.has-background-gradient-horizontal-from-grey-300-to-purple-blue-500 {
  background: linear-gradient(to right, #a8a8a8, #1b0777);
}

.has-background-gradient-horizontal-from-grey-300-to-peach-100 {
  background: linear-gradient(to right, #a8a8a8, #fdf3f2);
}

.has-background-gradient-horizontal-from-grey-300-to-peach-200 {
  background: linear-gradient(to right, #a8a8a8, #f8d8d4);
}

.has-background-gradient-horizontal-from-grey-300-to-peach-300 {
  background: linear-gradient(to right, #a8a8a8, #f0b0a8);
}

.has-background-gradient-horizontal-from-grey-300-to-peach-400 {
  background: linear-gradient(to right, #a8a8a8, #e9897d);
}

.has-background-gradient-horizontal-from-grey-300-to-peach-500 {
  background: linear-gradient(to right, #a8a8a8, #9b5b53);
}

.has-background-gradient-horizontal-from-grey-300-to-yellow-100 {
  background: linear-gradient(to right, #a8a8a8, #fff8f1);
}

.has-background-gradient-horizontal-from-grey-300-to-yellow-200 {
  background: linear-gradient(to right, #a8a8a8, #ffe8bd);
}

.has-background-gradient-horizontal-from-grey-300-to-yellow-300 {
  background: linear-gradient(to right, #a8a8a8, #ffd07a);
}

.has-background-gradient-horizontal-from-grey-300-to-yellow-400 {
  background: linear-gradient(to right, #a8a8a8, #ffb938);
}

.has-background-gradient-horizontal-from-grey-300-to-yellow-500 {
  background: linear-gradient(to right, #a8a8a8, #aa7b25);
}

.has-background-gradient-horizontal-from-grey-300-to-pink-100 {
  background: linear-gradient(to right, #a8a8a8, #fbeef0);
}

.has-background-gradient-horizontal-from-grey-300-to-pink-200 {
  background: linear-gradient(to right, #a8a8a8, #f2c6cd);
}

.has-background-gradient-horizontal-from-grey-300-to-pink-300 {
  background: linear-gradient(to right, #a8a8a8, #e58e9a);
}

.has-background-gradient-horizontal-from-grey-300-to-pink-400 {
  background: linear-gradient(to right, #a8a8a8, #d85568);
}

.has-background-gradient-horizontal-from-grey-300-to-pink-500 {
  background: linear-gradient(to right, #a8a8a8, #481c23);
}

.has-background-gradient-horizontal-from-grey-300-to-grey-100 {
  background: linear-gradient(to right, #a8a8a8, #fff);
}

.has-background-gradient-horizontal-from-grey-300-to-grey-200 {
  background: linear-gradient(to right, #a8a8a8, #e2e2e2);
}

.has-background-gradient-horizontal-from-grey-300-to-grey-300 {
  background: linear-gradient(to right, #a8a8a8, #a8a8a8);
}

.has-background-gradient-horizontal-from-grey-300-to-grey-400 {
  background: linear-gradient(to right, #a8a8a8, #616161);
}

.has-background-gradient-horizontal-from-grey-300-to-grey-500 {
  background: linear-gradient(to right, #a8a8a8, #393939);
}

.has-background-gradient-horizontal-from-grey-300-to-grey-600 {
  background: linear-gradient(to right, #a8a8a8, #000);
}

.has-background-gradient-horizontal-from-grey-400-to-purple-blue-100 {
  background: linear-gradient(to right, #616161, #f1f1fb);
}

.has-background-gradient-horizontal-from-grey-400-to-purple-blue-200 {
  background: linear-gradient(to right, #616161, #c9c9f0);
}

.has-background-gradient-horizontal-from-grey-400-to-purple-blue-300 {
  background: linear-gradient(to right, #616161, #a594f9);
}

.has-background-gradient-horizontal-from-grey-400-to-purple-blue-400 {
  background: linear-gradient(to right, #616161, #2727c4);
}

.has-background-gradient-horizontal-from-grey-400-to-purple-blue-500 {
  background: linear-gradient(to right, #616161, #1b0777);
}

.has-background-gradient-horizontal-from-grey-400-to-peach-100 {
  background: linear-gradient(to right, #616161, #fdf3f2);
}

.has-background-gradient-horizontal-from-grey-400-to-peach-200 {
  background: linear-gradient(to right, #616161, #f8d8d4);
}

.has-background-gradient-horizontal-from-grey-400-to-peach-300 {
  background: linear-gradient(to right, #616161, #f0b0a8);
}

.has-background-gradient-horizontal-from-grey-400-to-peach-400 {
  background: linear-gradient(to right, #616161, #e9897d);
}

.has-background-gradient-horizontal-from-grey-400-to-peach-500 {
  background: linear-gradient(to right, #616161, #9b5b53);
}

.has-background-gradient-horizontal-from-grey-400-to-yellow-100 {
  background: linear-gradient(to right, #616161, #fff8f1);
}

.has-background-gradient-horizontal-from-grey-400-to-yellow-200 {
  background: linear-gradient(to right, #616161, #ffe8bd);
}

.has-background-gradient-horizontal-from-grey-400-to-yellow-300 {
  background: linear-gradient(to right, #616161, #ffd07a);
}

.has-background-gradient-horizontal-from-grey-400-to-yellow-400 {
  background: linear-gradient(to right, #616161, #ffb938);
}

.has-background-gradient-horizontal-from-grey-400-to-yellow-500 {
  background: linear-gradient(to right, #616161, #aa7b25);
}

.has-background-gradient-horizontal-from-grey-400-to-pink-100 {
  background: linear-gradient(to right, #616161, #fbeef0);
}

.has-background-gradient-horizontal-from-grey-400-to-pink-200 {
  background: linear-gradient(to right, #616161, #f2c6cd);
}

.has-background-gradient-horizontal-from-grey-400-to-pink-300 {
  background: linear-gradient(to right, #616161, #e58e9a);
}

.has-background-gradient-horizontal-from-grey-400-to-pink-400 {
  background: linear-gradient(to right, #616161, #d85568);
}

.has-background-gradient-horizontal-from-grey-400-to-pink-500 {
  background: linear-gradient(to right, #616161, #481c23);
}

.has-background-gradient-horizontal-from-grey-400-to-grey-100 {
  background: linear-gradient(to right, #616161, #fff);
}

.has-background-gradient-horizontal-from-grey-400-to-grey-200 {
  background: linear-gradient(to right, #616161, #e2e2e2);
}

.has-background-gradient-horizontal-from-grey-400-to-grey-300 {
  background: linear-gradient(to right, #616161, #a8a8a8);
}

.has-background-gradient-horizontal-from-grey-400-to-grey-400 {
  background: linear-gradient(to right, #616161, #616161);
}

.has-background-gradient-horizontal-from-grey-400-to-grey-500 {
  background: linear-gradient(to right, #616161, #393939);
}

.has-background-gradient-horizontal-from-grey-400-to-grey-600 {
  background: linear-gradient(to right, #616161, #000);
}

.has-background-gradient-horizontal-from-grey-500-to-purple-blue-100 {
  background: linear-gradient(to right, #393939, #f1f1fb);
}

.has-background-gradient-horizontal-from-grey-500-to-purple-blue-200 {
  background: linear-gradient(to right, #393939, #c9c9f0);
}

.has-background-gradient-horizontal-from-grey-500-to-purple-blue-300 {
  background: linear-gradient(to right, #393939, #a594f9);
}

.has-background-gradient-horizontal-from-grey-500-to-purple-blue-400 {
  background: linear-gradient(to right, #393939, #2727c4);
}

.has-background-gradient-horizontal-from-grey-500-to-purple-blue-500 {
  background: linear-gradient(to right, #393939, #1b0777);
}

.has-background-gradient-horizontal-from-grey-500-to-peach-100 {
  background: linear-gradient(to right, #393939, #fdf3f2);
}

.has-background-gradient-horizontal-from-grey-500-to-peach-200 {
  background: linear-gradient(to right, #393939, #f8d8d4);
}

.has-background-gradient-horizontal-from-grey-500-to-peach-300 {
  background: linear-gradient(to right, #393939, #f0b0a8);
}

.has-background-gradient-horizontal-from-grey-500-to-peach-400 {
  background: linear-gradient(to right, #393939, #e9897d);
}

.has-background-gradient-horizontal-from-grey-500-to-peach-500 {
  background: linear-gradient(to right, #393939, #9b5b53);
}

.has-background-gradient-horizontal-from-grey-500-to-yellow-100 {
  background: linear-gradient(to right, #393939, #fff8f1);
}

.has-background-gradient-horizontal-from-grey-500-to-yellow-200 {
  background: linear-gradient(to right, #393939, #ffe8bd);
}

.has-background-gradient-horizontal-from-grey-500-to-yellow-300 {
  background: linear-gradient(to right, #393939, #ffd07a);
}

.has-background-gradient-horizontal-from-grey-500-to-yellow-400 {
  background: linear-gradient(to right, #393939, #ffb938);
}

.has-background-gradient-horizontal-from-grey-500-to-yellow-500 {
  background: linear-gradient(to right, #393939, #aa7b25);
}

.has-background-gradient-horizontal-from-grey-500-to-pink-100 {
  background: linear-gradient(to right, #393939, #fbeef0);
}

.has-background-gradient-horizontal-from-grey-500-to-pink-200 {
  background: linear-gradient(to right, #393939, #f2c6cd);
}

.has-background-gradient-horizontal-from-grey-500-to-pink-300 {
  background: linear-gradient(to right, #393939, #e58e9a);
}

.has-background-gradient-horizontal-from-grey-500-to-pink-400 {
  background: linear-gradient(to right, #393939, #d85568);
}

.has-background-gradient-horizontal-from-grey-500-to-pink-500 {
  background: linear-gradient(to right, #393939, #481c23);
}

.has-background-gradient-horizontal-from-grey-500-to-grey-100 {
  background: linear-gradient(to right, #393939, #fff);
}

.has-background-gradient-horizontal-from-grey-500-to-grey-200 {
  background: linear-gradient(to right, #393939, #e2e2e2);
}

.has-background-gradient-horizontal-from-grey-500-to-grey-300 {
  background: linear-gradient(to right, #393939, #a8a8a8);
}

.has-background-gradient-horizontal-from-grey-500-to-grey-400 {
  background: linear-gradient(to right, #393939, #616161);
}

.has-background-gradient-horizontal-from-grey-500-to-grey-500 {
  background: linear-gradient(to right, #393939, #393939);
}

.has-background-gradient-horizontal-from-grey-500-to-grey-600 {
  background: linear-gradient(to right, #393939, #000);
}

.has-background-gradient-horizontal-from-grey-600-to-purple-blue-100 {
  background: linear-gradient(to right, #000, #f1f1fb);
}

.has-background-gradient-horizontal-from-grey-600-to-purple-blue-200 {
  background: linear-gradient(to right, #000, #c9c9f0);
}

.has-background-gradient-horizontal-from-grey-600-to-purple-blue-300 {
  background: linear-gradient(to right, #000, #a594f9);
}

.has-background-gradient-horizontal-from-grey-600-to-purple-blue-400 {
  background: linear-gradient(to right, #000, #2727c4);
}

.has-background-gradient-horizontal-from-grey-600-to-purple-blue-500 {
  background: linear-gradient(to right, #000, #1b0777);
}

.has-background-gradient-horizontal-from-grey-600-to-peach-100 {
  background: linear-gradient(to right, #000, #fdf3f2);
}

.has-background-gradient-horizontal-from-grey-600-to-peach-200 {
  background: linear-gradient(to right, #000, #f8d8d4);
}

.has-background-gradient-horizontal-from-grey-600-to-peach-300 {
  background: linear-gradient(to right, #000, #f0b0a8);
}

.has-background-gradient-horizontal-from-grey-600-to-peach-400 {
  background: linear-gradient(to right, #000, #e9897d);
}

.has-background-gradient-horizontal-from-grey-600-to-peach-500 {
  background: linear-gradient(to right, #000, #9b5b53);
}

.has-background-gradient-horizontal-from-grey-600-to-yellow-100 {
  background: linear-gradient(to right, #000, #fff8f1);
}

.has-background-gradient-horizontal-from-grey-600-to-yellow-200 {
  background: linear-gradient(to right, #000, #ffe8bd);
}

.has-background-gradient-horizontal-from-grey-600-to-yellow-300 {
  background: linear-gradient(to right, #000, #ffd07a);
}

.has-background-gradient-horizontal-from-grey-600-to-yellow-400 {
  background: linear-gradient(to right, #000, #ffb938);
}

.has-background-gradient-horizontal-from-grey-600-to-yellow-500 {
  background: linear-gradient(to right, #000, #aa7b25);
}

.has-background-gradient-horizontal-from-grey-600-to-pink-100 {
  background: linear-gradient(to right, #000, #fbeef0);
}

.has-background-gradient-horizontal-from-grey-600-to-pink-200 {
  background: linear-gradient(to right, #000, #f2c6cd);
}

.has-background-gradient-horizontal-from-grey-600-to-pink-300 {
  background: linear-gradient(to right, #000, #e58e9a);
}

.has-background-gradient-horizontal-from-grey-600-to-pink-400 {
  background: linear-gradient(to right, #000, #d85568);
}

.has-background-gradient-horizontal-from-grey-600-to-pink-500 {
  background: linear-gradient(to right, #000, #481c23);
}

.has-background-gradient-horizontal-from-grey-600-to-grey-100 {
  background: linear-gradient(to right, #000, #fff);
}

.has-background-gradient-horizontal-from-grey-600-to-grey-200 {
  background: linear-gradient(to right, #000, #e2e2e2);
}

.has-background-gradient-horizontal-from-grey-600-to-grey-300 {
  background: linear-gradient(to right, #000, #a8a8a8);
}

.has-background-gradient-horizontal-from-grey-600-to-grey-400 {
  background: linear-gradient(to right, #000, #616161);
}

.has-background-gradient-horizontal-from-grey-600-to-grey-500 {
  background: linear-gradient(to right, #000, #393939);
}

.has-background-gradient-horizontal-from-grey-600-to-grey-600 {
  background: linear-gradient(to right, #000, #000);
}

.has-text-nowrap {
  text-wrap: nowrap;
}

.is-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.is-line-break-anywhere {
  line-break: anywhere;
}

.is-white-space-nowrap {
  white-space: nowrap;
}

.is-position-relative {
  position: relative;
}

.is-position-absolute {
  position: absolute;
}

.is-table-layout-fixed {
  table-layout: fixed;
}

@media screen and (width <= 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px) {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width <= 1215px) {
  .is-flex-until-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width <= 1407px) {
  .is-flex-until-fullhd {
    display: flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-position-fixed {
  position: fixed;
}

.is-top {
  top: 0;
}

.is-bottom {
  bottom: 0;
}

.is-left {
  left: 0;
}

.is-right {
  right: 0;
}

.is-clipped {
  overflow: clip;
}

.is-overflow-scroll-x {
  overflow-x: scroll;
}

.is-overflow-scroll-y {
  overflow-y: scroll;
}

.is-fullwidth, .is-fullheight {
  width: 100%;
}

.is-height-0 {
  height: 0 !important;
}

.is-min-height-0 {
  min-height: 0 !important;
}

.is-max-height-0 {
  max-height: 0 !important;
}

.is-height-1 {
  height: 5px !important;
}

.is-min-height-1 {
  min-height: 5px !important;
}

.is-max-height-1 {
  max-height: 5px !important;
}

.is-height-2 {
  height: 10px !important;
}

.is-min-height-2 {
  min-height: 10px !important;
}

.is-max-height-2 {
  max-height: 10px !important;
}

.is-height-3 {
  height: 15px !important;
}

.is-min-height-3 {
  min-height: 15px !important;
}

.is-max-height-3 {
  max-height: 15px !important;
}

.is-height-4 {
  height: 20px !important;
}

.is-min-height-4 {
  min-height: 20px !important;
}

.is-max-height-4 {
  max-height: 20px !important;
}

.is-height-5 {
  height: 25px !important;
}

.is-min-height-5 {
  min-height: 25px !important;
}

.is-max-height-5 {
  max-height: 25px !important;
}

.is-height-6 {
  height: 30px !important;
}

.is-min-height-6 {
  min-height: 30px !important;
}

.is-max-height-6 {
  max-height: 30px !important;
}

.is-height-7 {
  height: 40px !important;
}

.is-min-height-7 {
  min-height: 40px !important;
}

.is-max-height-7 {
  max-height: 40px !important;
}

.is-height-8 {
  height: 50px !important;
}

.is-min-height-8 {
  min-height: 50px !important;
}

.is-max-height-8 {
  max-height: 50px !important;
}

.is-height-auto {
  height: auto !important;
}

.is-min-height-auto {
  min-height: auto !important;
}

.is-max-height-auto {
  max-height: auto !important;
}

.is-height--1 {
  height: -5px !important;
}

.is-min-height--1 {
  min-height: -5px !important;
}

.is-max-height--1 {
  max-height: -5px !important;
}

.is-height--2 {
  height: -10px !important;
}

.is-min-height--2 {
  min-height: -10px !important;
}

.is-max-height--2 {
  max-height: -10px !important;
}

.is-height--3 {
  height: -15px !important;
}

.is-min-height--3 {
  min-height: -15px !important;
}

.is-max-height--3 {
  max-height: -15px !important;
}

.is-height--4 {
  height: -20px !important;
}

.is-min-height--4 {
  min-height: -20px !important;
}

.is-max-height--4 {
  max-height: -20px !important;
}

.is-height--5 {
  height: -25px !important;
}

.is-min-height--5 {
  min-height: -25px !important;
}

.is-max-height--5 {
  max-height: -25px !important;
}

.is-height--6 {
  height: -30px !important;
}

.is-min-height--6 {
  min-height: -30px !important;
}

.is-max-height--6 {
  max-height: -30px !important;
}

.is-height--7 {
  height: -40px !important;
}

.is-min-height--7 {
  min-height: -40px !important;
}

.is-max-height--7 {
  max-height: -40px !important;
}

.is-height--8 {
  height: -50px !important;
}

.is-min-height--8 {
  min-height: -50px !important;
}

.is-max-height--8 {
  max-height: -50px !important;
}

.is-width-one-third {
  width: 33%;
}

@media screen and (width <= 768px) {
  .is-width-one-third-mobile {
    width: 33%;
  }
}

@media screen and (width >= 769px) {
  .is-width-one-third-tablet {
    width: 33%;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-width-one-third-tablet-only {
    width: 33%;
  }
}

@media screen and (width <= 1023px) {
  .is-width-one-third-touch {
    width: 33%;
  }
}

@media screen and (width >= 1024px) {
  .is-width-one-third-desktop {
    width: 33%;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-width-one-third-desktop-only {
    width: 33%;
  }
}

@media screen and (width <= 1215px) {
  .is-width-one-third-until-widescreen {
    width: 33%;
  }
}

@media screen and (width >= 1216px) {
  .is-width-one-third-widescreen {
    width: 33%;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-width-one-third-widescreen-only {
    width: 33%;
  }
}

@media screen and (width <= 1407px) {
  .is-width-one-third-until-fullhd {
    width: 33%;
  }
}

@media screen and (width >= 1408px) {
  .is-width-one-third-fullhd {
    width: 33%;
  }
}

.is-width-half {
  width: 50%;
}

@media screen and (width <= 768px) {
  .is-width-half-mobile {
    width: 50%;
  }
}

@media screen and (width >= 769px) {
  .is-width-half-tablet {
    width: 50%;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-width-half-tablet-only {
    width: 50%;
  }
}

@media screen and (width <= 1023px) {
  .is-width-half-touch {
    width: 50%;
  }
}

@media screen and (width >= 1024px) {
  .is-width-half-desktop {
    width: 50%;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-width-half-desktop-only {
    width: 50%;
  }
}

@media screen and (width <= 1215px) {
  .is-width-half-until-widescreen {
    width: 50%;
  }
}

@media screen and (width >= 1216px) {
  .is-width-half-widescreen {
    width: 50%;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-width-half-widescreen-only {
    width: 50%;
  }
}

@media screen and (width <= 1407px) {
  .is-width-half-until-fullhd {
    width: 50%;
  }
}

@media screen and (width >= 1408px) {
  .is-width-half-fullhd {
    width: 50%;
  }
}

.is-width-two-thirds {
  width: 66%;
}

@media screen and (width <= 768px) {
  .is-width-two-thirds-mobile {
    width: 66%;
  }
}

@media screen and (width >= 769px) {
  .is-width-two-thirds-tablet {
    width: 66%;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-width-two-thirds-tablet-only {
    width: 66%;
  }
}

@media screen and (width <= 1023px) {
  .is-width-two-thirds-touch {
    width: 66%;
  }
}

@media screen and (width >= 1024px) {
  .is-width-two-thirds-desktop {
    width: 66%;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-width-two-thirds-desktop-only {
    width: 66%;
  }
}

@media screen and (width <= 1215px) {
  .is-width-two-thirds-until-widescreen {
    width: 66%;
  }
}

@media screen and (width >= 1216px) {
  .is-width-two-thirds-widescreen {
    width: 66%;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-width-two-thirds-widescreen-only {
    width: 66%;
  }
}

@media screen and (width <= 1407px) {
  .is-width-two-thirds-until-fullhd {
    width: 66%;
  }
}

@media screen and (width >= 1408px) {
  .is-width-two-thirds-fullhd {
    width: 66%;
  }
}

.is-fullheight {
  height: 100%;
}

.is-rounded, .is-rounded-top, .is-rounded-bottom {
  border-radius: .5rem;
}

.is-rounded-0 {
  border-radius: 0 !important;
}

@media screen and (width <= 768px) {
  .is-rounded-0-mobile {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-0-tablet {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-0-tablet-only {
    border-radius: 0 !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-0-touch {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-0-desktop {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-0-desktop-only {
    border-radius: 0 !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-0-until-widescreen {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-0-widescreen {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-0-widescreen-only {
    border-radius: 0 !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-0-until-fullhd {
    border-radius: 0 !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-0-fullhd {
    border-radius: 0 !important;
  }
}

.is-rounded-1 {
  border-radius: 5px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-1-mobile {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-1-tablet {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-1-tablet-only {
    border-radius: 5px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-1-touch {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-1-desktop {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-1-desktop-only {
    border-radius: 5px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-1-until-widescreen {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-1-widescreen {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-1-widescreen-only {
    border-radius: 5px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-1-until-fullhd {
    border-radius: 5px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-1-fullhd {
    border-radius: 5px !important;
  }
}

.is-rounded-2 {
  border-radius: 10px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-2-mobile {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-2-tablet {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-2-tablet-only {
    border-radius: 10px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-2-touch {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-2-desktop {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-2-desktop-only {
    border-radius: 10px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-2-until-widescreen {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-2-widescreen {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-2-widescreen-only {
    border-radius: 10px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-2-until-fullhd {
    border-radius: 10px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-2-fullhd {
    border-radius: 10px !important;
  }
}

.is-rounded-3 {
  border-radius: 15px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-3-mobile {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-3-tablet {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-3-tablet-only {
    border-radius: 15px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-3-touch {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-3-desktop {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-3-desktop-only {
    border-radius: 15px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-3-until-widescreen {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-3-widescreen {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-3-widescreen-only {
    border-radius: 15px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-3-until-fullhd {
    border-radius: 15px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-3-fullhd {
    border-radius: 15px !important;
  }
}

.is-rounded-4 {
  border-radius: 20px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-4-mobile {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-4-tablet {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-4-tablet-only {
    border-radius: 20px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-4-touch {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-4-desktop {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-4-desktop-only {
    border-radius: 20px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-4-until-widescreen {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-4-widescreen {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-4-widescreen-only {
    border-radius: 20px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-4-until-fullhd {
    border-radius: 20px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-4-fullhd {
    border-radius: 20px !important;
  }
}

.is-rounded-5 {
  border-radius: 25px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-5-mobile {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-5-tablet {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-5-tablet-only {
    border-radius: 25px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-5-touch {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-5-desktop {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-5-desktop-only {
    border-radius: 25px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-5-until-widescreen {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-5-widescreen {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-5-widescreen-only {
    border-radius: 25px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-5-until-fullhd {
    border-radius: 25px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-5-fullhd {
    border-radius: 25px !important;
  }
}

.is-rounded-6 {
  border-radius: 30px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-6-mobile {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-6-tablet {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-6-tablet-only {
    border-radius: 30px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-6-touch {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-6-desktop {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-6-desktop-only {
    border-radius: 30px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-6-until-widescreen {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-6-widescreen {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-6-widescreen-only {
    border-radius: 30px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-6-until-fullhd {
    border-radius: 30px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-6-fullhd {
    border-radius: 30px !important;
  }
}

.is-rounded-7 {
  border-radius: 40px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-7-mobile {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-7-tablet {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-7-tablet-only {
    border-radius: 40px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-7-touch {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-7-desktop {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-7-desktop-only {
    border-radius: 40px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-7-until-widescreen {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-7-widescreen {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-7-widescreen-only {
    border-radius: 40px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-7-until-fullhd {
    border-radius: 40px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-7-fullhd {
    border-radius: 40px !important;
  }
}

.is-rounded-8 {
  border-radius: 50px !important;
}

@media screen and (width <= 768px) {
  .is-rounded-8-mobile {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-8-tablet {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-8-tablet-only {
    border-radius: 50px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-8-touch {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-8-desktop {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-8-desktop-only {
    border-radius: 50px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-8-until-widescreen {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-8-widescreen {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-8-widescreen-only {
    border-radius: 50px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-8-until-fullhd {
    border-radius: 50px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-8-fullhd {
    border-radius: 50px !important;
  }
}

.is-rounded-auto {
  border-radius: auto !important;
}

@media screen and (width <= 768px) {
  .is-rounded-auto-mobile {
    border-radius: auto !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded-auto-tablet {
    border-radius: auto !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded-auto-tablet-only {
    border-radius: auto !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded-auto-touch {
    border-radius: auto !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded-auto-desktop {
    border-radius: auto !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded-auto-desktop-only {
    border-radius: auto !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded-auto-until-widescreen {
    border-radius: auto !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded-auto-widescreen {
    border-radius: auto !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded-auto-widescreen-only {
    border-radius: auto !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded-auto-until-fullhd {
    border-radius: auto !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded-auto-fullhd {
    border-radius: auto !important;
  }
}

.is-rounded--1 {
  border-radius: -5px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--1-mobile {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--1-tablet {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--1-tablet-only {
    border-radius: -5px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--1-touch {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--1-desktop {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--1-desktop-only {
    border-radius: -5px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--1-until-widescreen {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--1-widescreen {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--1-widescreen-only {
    border-radius: -5px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--1-until-fullhd {
    border-radius: -5px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--1-fullhd {
    border-radius: -5px !important;
  }
}

.is-rounded--2 {
  border-radius: -10px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--2-mobile {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--2-tablet {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--2-tablet-only {
    border-radius: -10px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--2-touch {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--2-desktop {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--2-desktop-only {
    border-radius: -10px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--2-until-widescreen {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--2-widescreen {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--2-widescreen-only {
    border-radius: -10px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--2-until-fullhd {
    border-radius: -10px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--2-fullhd {
    border-radius: -10px !important;
  }
}

.is-rounded--3 {
  border-radius: -15px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--3-mobile {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--3-tablet {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--3-tablet-only {
    border-radius: -15px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--3-touch {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--3-desktop {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--3-desktop-only {
    border-radius: -15px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--3-until-widescreen {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--3-widescreen {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--3-widescreen-only {
    border-radius: -15px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--3-until-fullhd {
    border-radius: -15px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--3-fullhd {
    border-radius: -15px !important;
  }
}

.is-rounded--4 {
  border-radius: -20px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--4-mobile {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--4-tablet {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--4-tablet-only {
    border-radius: -20px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--4-touch {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--4-desktop {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--4-desktop-only {
    border-radius: -20px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--4-until-widescreen {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--4-widescreen {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--4-widescreen-only {
    border-radius: -20px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--4-until-fullhd {
    border-radius: -20px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--4-fullhd {
    border-radius: -20px !important;
  }
}

.is-rounded--5 {
  border-radius: -25px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--5-mobile {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--5-tablet {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--5-tablet-only {
    border-radius: -25px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--5-touch {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--5-desktop {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--5-desktop-only {
    border-radius: -25px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--5-until-widescreen {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--5-widescreen {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--5-widescreen-only {
    border-radius: -25px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--5-until-fullhd {
    border-radius: -25px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--5-fullhd {
    border-radius: -25px !important;
  }
}

.is-rounded--6 {
  border-radius: -30px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--6-mobile {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--6-tablet {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--6-tablet-only {
    border-radius: -30px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--6-touch {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--6-desktop {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--6-desktop-only {
    border-radius: -30px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--6-until-widescreen {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--6-widescreen {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--6-widescreen-only {
    border-radius: -30px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--6-until-fullhd {
    border-radius: -30px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--6-fullhd {
    border-radius: -30px !important;
  }
}

.is-rounded--7 {
  border-radius: -40px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--7-mobile {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--7-tablet {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--7-tablet-only {
    border-radius: -40px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--7-touch {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--7-desktop {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--7-desktop-only {
    border-radius: -40px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--7-until-widescreen {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--7-widescreen {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--7-widescreen-only {
    border-radius: -40px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--7-until-fullhd {
    border-radius: -40px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--7-fullhd {
    border-radius: -40px !important;
  }
}

.is-rounded--8 {
  border-radius: -50px !important;
}

@media screen and (width <= 768px) {
  .is-rounded--8-mobile {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 769px) {
  .is-rounded--8-tablet {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-rounded--8-tablet-only {
    border-radius: -50px !important;
  }
}

@media screen and (width <= 1023px) {
  .is-rounded--8-touch {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 1024px) {
  .is-rounded--8-desktop {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-rounded--8-desktop-only {
    border-radius: -50px !important;
  }
}

@media screen and (width <= 1215px) {
  .is-rounded--8-until-widescreen {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 1216px) {
  .is-rounded--8-widescreen {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-rounded--8-widescreen-only {
    border-radius: -50px !important;
  }
}

@media screen and (width <= 1407px) {
  .is-rounded--8-until-fullhd {
    border-radius: -50px !important;
  }
}

@media screen and (width >= 1408px) {
  .is-rounded--8-fullhd {
    border-radius: -50px !important;
  }
}

.is-rounded-bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.is-rounded-top {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.is-flex-gap-0 {
  gap: 0 !important;
}

.is-flex-gap-1 {
  gap: 5px !important;
}

.is-flex-gap-2 {
  gap: 10px !important;
}

.is-flex-gap-3 {
  gap: 15px !important;
}

.is-flex-gap-4 {
  gap: 20px !important;
}

.is-flex-gap-5 {
  gap: 25px !important;
}

.is-flex-gap-6 {
  gap: 30px !important;
}

.is-flex-gap-7 {
  gap: 40px !important;
}

.is-flex-gap-8 {
  gap: 50px !important;
}

.is-flex-gap-auto {
  gap: auto !important;
}

.is-flex-gap--1 {
  gap: -5px !important;
}

.is-flex-gap--2 {
  gap: -10px !important;
}

.is-flex-gap--3 {
  gap: -15px !important;
}

.is-flex-gap--4 {
  gap: -20px !important;
}

.is-flex-gap--5 {
  gap: -25px !important;
}

.is-flex-gap--6 {
  gap: -30px !important;
}

.is-flex-gap--7 {
  gap: -40px !important;
}

.is-flex-gap--8 {
  gap: -50px !important;
}

.is-z-index--100 {
  z-index: -100 !important;
}

.is-z-index--90 {
  z-index: -90 !important;
}

.is-z-index--80 {
  z-index: -80 !important;
}

.is-z-index--70 {
  z-index: -70 !important;
}

.is-z-index--60 {
  z-index: -60 !important;
}

.is-z-index--50 {
  z-index: -50 !important;
}

.is-z-index--40 {
  z-index: -40 !important;
}

.is-z-index--30 {
  z-index: -30 !important;
}

.is-z-index--20 {
  z-index: -20 !important;
}

.is-z-index--10 {
  z-index: -10 !important;
}

.is-z-index-0 {
  z-index: 0 !important;
}

.is-z-index-10 {
  z-index: 10 !important;
}

.is-z-index-20 {
  z-index: 20 !important;
}

.is-z-index-30 {
  z-index: 30 !important;
}

.is-z-index-40 {
  z-index: 40 !important;
}

.is-z-index-50 {
  z-index: 50 !important;
}

.is-z-index-60 {
  z-index: 60 !important;
}

.is-z-index-70 {
  z-index: 70 !important;
}

.is-z-index-80 {
  z-index: 80 !important;
}

.is-z-index-90 {
  z-index: 90 !important;
}

.is-z-index-100 {
  z-index: 100 !important;
}

@media screen and (width <= 768px) {
  .is-size-smaller-mobile {
    font-size: 14px;
  }
}

@media screen and (width >= 769px) {
  .is-size-smaller-tablet {
    font-size: 14px;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-size-smaller-tablet-only {
    font-size: 14px;
  }
}

@media screen and (width <= 1023px) {
  .is-size-smaller-touch {
    font-size: 14px;
  }
}

@media screen and (width >= 1024px) {
  .is-size-smaller-desktop {
    font-size: 14px;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-size-smaller-desktop-only {
    font-size: 14px;
  }
}

@media screen and (width <= 1215px) {
  .is-size-smaller-until-widescreen {
    font-size: 14px;
  }
}

@media screen and (width >= 1216px) {
  .is-size-smaller-widescreen {
    font-size: 14px;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-size-smaller-widescreen-only {
    font-size: 14px;
  }
}

@media screen and (width <= 1407px) {
  .is-size-smaller-until-fullhd {
    font-size: 14px;
  }
}

@media screen and (width >= 1408px) {
  .is-size-smaller-fullhd {
    font-size: 14px;
  }
}

.image.is-5by2 {
  padding-top: 40%;
}

.image.is-5by2 img {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.table.is-borderless, .table.is-borderless tr, .table.is-borderless td, .table.is-borderless th {
  border: none;
}

.table.is-no-wrap td, .table.is-no-wrap th {
  white-space: nowrap;
}

.locked {
  filter: blur(3px);
  pointer-events: none !important;
}

.locked * {
  pointer-events: none !important;
}

.locked:after {
  content: "";
  position: absolute;
  inset: 0;
}

.blocked {
  position: relative;
  pointer-events: none !important;
}

.blocked * {
  pointer-events: none !important;
}

.blocked:before {
  content: "";
  z-index: 999;
  position: absolute;
  inset: 0;
}

.has-letter-spacing-1 {
  letter-spacing: 1px;
}

.has-letter-spacing-2 {
  letter-spacing: .2rem;
}

.is-family-montserrat {
  font-family: Montserrat, sans-serif;
}

.is-size-hero {
  font-size: 60px;
}

.is-size-small {
  font-size: .9375rem;
}

.is-size-smaller {
  font-size: 14px;
}

.control.has-icons-left .icon {
  z-index: auto;
}

.control.has-icons-left .icon svg {
  fill: #dbdbdb;
}

.control.has-icons-left .input:focus ~ .icon svg {
  fill: #4a4a4a;
}

@media screen and (width >= 769px) {
  .has-order-1-tablet {
    order: 1;
  }
}

ul.propelle-list li {
  margin-bottom: 16px;
  list-style: none;
  position: relative;
}

ul.propelle-list li:before {
  content: "";
  background-image: url("bullet.c963c7b5.svg");
  background-repeat: no-repeat;
  width: 10px;
  height: 18px;
  display: block;
  position: absolute;
  top: 3px;
  left: -18px;
}

.blob-underline {
  z-index: 1;
  position: relative;
}

.blob-underline:after {
  content: "";
  z-index: -1;
  background-image: url("blob-underline-violet.0986389c.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 1rem;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.wordpress-content html, .wordpress-content body, .wordpress-content p, .wordpress-content ol, .wordpress-content ul, .wordpress-content li, .wordpress-content dl, .wordpress-content dt, .wordpress-content dd, .wordpress-content blockquote, .wordpress-content fieldset, .wordpress-content legend, .wordpress-content textarea, .wordpress-content pre, .wordpress-content iframe, .wordpress-content hr, .wordpress-content h1, .wordpress-content h2, .wordpress-content h3, .wordpress-content h4, .wordpress-content h5, .wordpress-content h6 {
  margin: revert;
  padding: revert;
}

.wordpress-content ul {
  list-style: revert;
}

.wordpress-content .wp-block-image .alignleft {
  float: left;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.wordpress-content .wp-block-image .alignright {
  float: right;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.wordpress-content .wp-block-table {
  margin-left: 0;
  margin-right: 0;
}

.wordpress-content .wp-block-table td {
  border: 1px solid #4a4a4a;
  padding: .5rem;
}

.wordpress-content .wp-block-embed__wrapper iframe {
  width: 100%;
}

.wordpress-content .is-layout-flex {
  display: flex;
}

.wordpress-content .wp-block-button {
  margin-right: 10px;
}

.wordpress-content .wp-block-button__link {
  border-radius: 9999px !important;
  padding-left: 1.25em !important;
  padding-right: 1.25em !important;
}

.button, .input, .textarea, .select select, .file-cta, .file-name, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .wordpress-content .wp-block-button__link {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 2.5em;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 16px;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.button:focus, .input:focus, .textarea:focus, .select select:focus, .file-cta:focus, .file-name:focus, .pagination-previous:focus, .pagination-next:focus, .pagination-link:focus, .pagination-ellipsis:focus, .wordpress-content .wp-block-button__link:focus, .is-focused.button, .is-focused.input, .is-focused.textarea, .select select.is-focused, .is-focused.file-cta, .is-focused.file-name, .is-focused.pagination-previous, .is-focused.pagination-next, .is-focused.pagination-link, .is-focused.pagination-ellipsis, .wordpress-content .is-focused.wp-block-button__link, .button:active, .input:active, .textarea:active, .select select:active, .file-cta:active, .file-name:active, .pagination-previous:active, .pagination-next:active, .pagination-link:active, .pagination-ellipsis:active, .wordpress-content .wp-block-button__link:active, .is-active.button, .is-active.input, .is-active.textarea, .select select.is-active, .is-active.file-cta, .is-active.file-name, .is-active.pagination-previous, .is-active.pagination-next, .is-active.pagination-link, .is-active.pagination-ellipsis, .wordpress-content .is-active.wp-block-button__link {
  outline: none;
}

[disabled].button, [disabled].input, [disabled].textarea, .select select[disabled], [disabled].file-cta, [disabled].file-name, [disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, .wordpress-content [disabled].wp-block-button__link, fieldset[disabled] .button, fieldset[disabled] .input, fieldset[disabled] .textarea, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .pagination-previous, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .wordpress-content .wp-block-button__link, .wordpress-content fieldset[disabled] .wp-block-button__link {
  cursor: not-allowed;
}

.button, .file, .breadcrumb, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .tabs, .is-unselectable, .wordpress-content .wp-block-button__link {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.select:not(.is-multiple):not(.is-loading):after, .navbar-link:not(.is-arrowless):after {
  content: " ";
  pointer-events: none;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.box:not(:last-child), .content:not(:last-child), .notification:not(:last-child), .progress:not(:last-child), .table:not(:last-child), .table-container:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child), .block:not(:last-child), .breadcrumb:not(:last-child), .level:not(:last-child), .message:not(:last-child), .pagination:not(:last-child), .tabs:not(:last-child) {
  margin-bottom: 1.5rem;
}

.delete, .modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: #0a0a0a33;
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.delete:before, .modal-close:before, .delete:after, .modal-close:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.delete:before, .modal-close:before {
  width: 50%;
  height: 2px;
}

.delete:after, .modal-close:after {
  width: 2px;
  height: 50%;
}

.delete:hover, .modal-close:hover, .delete:focus, .modal-close:focus {
  background-color: #0a0a0a4d;
}

.delete:active, .modal-close:active {
  background-color: #0a0a0a66;
}

.is-small.delete, .is-small.modal-close {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.delete, .is-medium.modal-close {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.delete, .is-large.modal-close {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.button.is-loading:after, .loader, .select.is-loading:after, .control.is-loading:after, .wordpress-content .is-loading.wp-block-button__link:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio, .modal, .modal-background, .is-overlay, .hero-video {
  position: absolute;
  inset: 0;
}

.navbar-burger {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

.field.is-horizontal .field-label {
  text-align: left;
}

.field.has-errors .label, .field.has-errors .field-label {
  color: #ea3f3f !important;
}

.field.has-errors .input {
  border-color: #ea3f3f !important;
}

.field:not(.has-errors) .help.is-danger {
  display: none;
}

.input {
  box-shadow: none !important;
  border-width: 1px !important;
  border-color: #cdcdcd !important;
}

.input:focus, .input:active {
  border-color: #a594f9;
}

.input-box {
  z-index: 25;
}

.input-box.multi-select {
  z-index: 26;
}

.radio-toggle {
  display: flex;
}

.radio-toggle input[type="radio"] {
  opacity: 0;
  width: 0;
  position: fixed;
}

.radio-toggle label {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  display: flex;
}

.radio-toggle label.right {
  border-left: 1px;
  border-radius: 0 50px 50px 0;
}

.radio-toggle label.left {
  border-radius: 50px 0 0 50px;
}

.radio-toggle input[type="radio"]:checked + label {
  background-color: #f5bdac;
}

input[disabled], button[disabled] {
  opacity: 1 !important;
  background-color: #a8a8a8 !important;
  border-color: #a8a8a8 !important;
}

button[type="submit"] {
  font-weight: normal !important;
}

button[disabled] {
  color: #fff !important;
}

.control.has-icons-right .icon {
  z-index: auto;
}

body, button, input, optgroup, select, textarea {
  font-family: Poppins, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Syne;
}

hr {
  height: 1px !important;
}

hr.is-size-2 {
  height: 2px !important;
}

.select select:focus {
  box-shadow: none;
  border-width: .15rem;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
}
/*# sourceMappingURL=index.db50788c.css.map */
