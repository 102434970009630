@import "../../../../../../../components/Nav/_variables.scss";
@import "../../../../../../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../../../../../styles/_colors.scss";

$fixed-overlay-height: 3rem;

.risk-tolerance {

    @include until($desktop) {
        background-color: $peach-very-light;
    }

    .start-over-nav {
        position: fixed;
        width: 100%;
        left: 0;
        top: $navbar-height;
        height: $fixed-overlay-height;
        z-index: 30;

        .is-overlay {
            height: $fixed-overlay-height;

            &.has-background {
                z-index: -1;

                @include until ($desktop) {
                    background: white;
                }
            }
        }

        @include until ($desktop) {
            justify-content: end;
            top: $navbar-mobile-height;
        }
    }
}