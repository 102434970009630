@use "../../styles/_colors.scss";
@use "../../styles/_fonts.scss";
@use "../../../node_modules/bulma/sass/utilities/_all.sass";
@use "./_variables.scss";

.mobile-menu-spacer {
    @include all.until(all.$desktop) {
        height: variables.$mobile-menu-height;
        min-height: variables.$mobile-menu-height;
    }
}

.mobile-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    height: variables.$mobile-menu-height;

    .mobile-menu-item {
        color: colors.$mobile-menu-inactive !important;

        a {
            color: colors.$mobile-menu-inactive !important;
            font-weight: 500;
            font-family: fonts.$montserrat;
            font-size: 13px;
            line-height: normal;
        }

        &.is-active {
            color: colors.$mobile-menu-active !important;

            a {
                color: colors.$mobile-menu-active !important;
            }
        }
    }
}