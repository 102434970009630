@use "../../../node_modules/bulma/sass/utilities/mixins.sass" as mixins;

.glide {
    .glide__track__wrapper {
        @include mixins.desktop {
            overflow: hidden;
            padding-left: 5px;
            padding-right: 5px;

            .glide__track {
                overflow: visible;
            }

            .glide__slides {
                overflow: visible;
            }
        }
        .glide__slide {
            height: auto;
        }
    }
}

.carousel-controls {
    &.controls-with-title {
        margin-top: -50px;
        @include mixins.desktop {
            margin-top: -40px;
        }  
    }
    button {
        &:focus:not(:active) {
            box-shadow: none;
        }
        img {
            width: 30px;
            @include mixins.desktop {
                width: 40px;
            }
        }
    }
}