@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../Nav/_variables.scss";
@import "../Menu/_variables.scss";

.chat-layout {
    .background {
        left: 0rem;

        @include until($desktop) {
            display: none;
        }
    }

    .propelle-navbar {
        &.navbar {
            @include until($desktop) {
                background: white;
                border-bottom: #CDCDCD 2px solid;
            }
        }
    }

    .chat-main {
        @include from ($desktop) {
            left: 0;
            min-height: calc(100vh - $navbar-height);
            width: 100%;
        }

        
        @include until($desktop) {
            min-height: calc(100vh - $navbar-mobile-height);
        }
    }
}