@import "../../styles/_colors.scss";

.contact-us {
    color: black;

    a {
        color: black;
        text-decoration: underline;
    }
}

.social-icon {
    border-radius: 9999px;
    background-color: $violet;
}