@import "../../styles/_colors.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";

.chat {
    & > .mobile {
        background-color: #FFFFFF;

        & > .columns {
            margin: 0;
        }
    }

    & > .desktop {
        & > .columns {
            margin: 0;

            & > .column:nth-child(2) {
                background-color: $grey-light;
            }
        }
    }

    .chat-dialog {
        .chat-message-line {
            &:first-child {
                margin-top: 0rem;
            }

            .chat-message {
                padding: 0.7rem 1.5rem;
                max-width: 80%;
                border-radius: 1.5rem;
                                    
                &.customer {
                    margin: 1rem 0rem;
                    background-color: $peach-dark;
                    color:#000000;
                }
        
                &.bot {
                    margin: 1rem 0rem;
                    background-color: $navy;
                    color: #FFFFFF;

                    &.loading {
                        color: #C9C9C9;
                    }
                }

                &.answer {
                    background-color: $chat-background-color;
                    color: #000000;
                    border-radius: 1em;
                }

                &:not(.loading) {
                    min-height: 1.5rem;
                }

                &.loading {
                    color: #C9C9C9;
                    font-size: 2em;
                    padding: 0rem 0.5rem;
                    min-width: 5rem;
                    max-height: 1.5em;

                    span {
                        font-size: 1.2em;
                        animation-name: bounce;
                        animation-duration: 0.6s;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease-in-out;
                        
                        &:nth-child(1) {
                            animation-delay: 0s;
                        }

                        &:nth-child(2) {
                            animation-delay: 0.04s;
                        }

                        &:nth-child(3) {
                            animation-delay: 0.08s;
                        }

                        @keyframes bounce {
                            0%, 90% {
                                transform: translateY(0);
                            }
                            45% {
                                transform: translateY(-0.2rem);
                            }
                        }
                    }
                }
            }
        }
    }
}

