@import "../../../../../node_modules/bulma/sass/utilities/_all.sass";

.faces-container {
    @include until($desktop) {
        display: none;
    }

    width: 100%;
    height: 100%;
    position: relative;

    $container-width: 18rem;
    $image-width: 8rem;

    .faces {
        position: absolute;
        width: $container-width;

        &.left {
            right: 0;
            height: 100%;

            .face {
                height: $image-width;
                width: $image-width;
                background-repeat: no-repeat;
                background-size: cover;

                &.top {
                    background-image: url('./tl.webp');
                    top: 4rem;
                    right: 8rem;
                }

                &.bottom {
                    background-image: url('./bl.webp');
                    bottom: 4rem;
                    right: 2rem;
                }
            }
        }

        &.right {
            left: 0;
            height: 100%;

            .face {
                height: $image-width;
                width: $image-width;
                background-repeat: no-repeat;
                background-size: cover;

                &.top {
                    background-image: url('./tr.webp');
                    top: 1rem;
                    left: 3rem;
                }

                &.bottom {
                    background-image: url('./br.webp');
                    bottom: 3rem;
                    left: 5rem;
                }
            }
        }

        .face-container {
            position: relative;
            height: 100%;
            width: 100%;

            .face {
                position: absolute;
            }
        }
    }
}