@import "../../node_modules/bulma/sass/utilities/derived-variables.sass";

ul.propelle-list {
    li {
        position: relative;
        list-style: none;
        margin-bottom: $size-6;
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 18px;
            left: -18px;
            top: 3px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url('../assets/bullet.svg');
        }
    }    
}

.blob-underline {
    position: relative;
    z-index: 1;

    &::after {
        content: '';
        background-image: url('../assets/blob-underline-violet.svg');
        background-repeat: no-repeat;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1rem;
        z-index: -1;
    }
}