@import "../../../../styles/_colors.scss";

.result {
    strong {
        color: $navy;
    }

    .goal {
        position: relative;

        .decoration {
            z-index: -1;
            width: 1px;
            height: 0;
            position: absolute;
            display: block;

            &.top-left {
                &:before {
                    content: "";
                    position: absolute;
                    height: 92px;
                    width: 92px;
                    background-repeat: no-repeat;
                    top: -36px;
                    left: 0;
                    background-image: url('./yay-tl.svg');
                }
            }

            &.top-right {    
                width: 100%;
                
                &:before {
                    content: "";
                    position: absolute;
                    height: 92px;
                    width: 92px;
                    background-repeat: no-repeat;
                    top: -36px;
                    right: 0;
                    background-image: url('./yay-tr.svg');
                }
            }

            &.bottom-left {
                &:before {
                    content: "";
                    position: absolute;
                    height: 92px;
                    width: 92px;
                    background-repeat: no-repeat;
                    left: 0;
                    background-image: url('./yay-bl.svg');
                }
            }
                
            &.bottom-right { 
                width: 100%;

                &:before {
                    content: "";
                    position: absolute;
                    height: 92px;
                    width: 92px;
                    background-repeat: no-repeat;
                    right: 0;
                    top: -24px;
                    background-image: url('./yay-br.svg');
                }
            }
        }
    }
}

.footer-container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;

    .footer {
        width: 100%;
    }
}