/* Fonts */
$syne: 'Syne';
$poppins: 'Poppins';
$montserrat: 'Montserrat';

$family-primary: $poppins, sans-serif; // Obtained from Google
$family-secondary: $syne;

/* Sizing */
$size-hero: 60px;
$size-1: 50px;
$size-2: 40px;
$size-3: 30px;
$size-4: 25px;
$size-5: 18px;
$size-6: 16px;
$size-7: 14px;

// Syne
.is-size-h1 {
    font-family: $family-secondary;
    font-size: $size-hero;
    font-weight: 700;
}

.is-size-h2 {
    font-family: $family-secondary;
    font-size: $size-hero;
    font-weight: 700;
}

.is-size-h3 {
    font-family: $family-secondary;
    font-size: $size-2;
    font-weight: 700;
}

.is-size-h4 {
    font-family: $family-secondary;
    font-size: $size-3;
    font-weight: 700;
}

.is-size-h5 {
    font-family: $family-secondary;
    font-size: $size-4;
    font-weight: 700;
}

// Poppins
.is-size-h6 {
    font-family: $family-primary;
    font-size: 22px;
    font-weight: 600;
}

.is-size-h7 {
    font-family: $family-primary;
    font-size: 20px;
    font-weight: 600;
}

.is-size-h8 {
    font-family: $family-primary;
    font-size: $size-6;
    font-weight: 600;
}
