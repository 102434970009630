@use "./spacing";

.wordpress-content {
    html, body, p, ol, ul, li, dl, dt, dd, blockquote, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
        margin: revert;
        padding: revert;
    }

    ul {
        list-style: revert;
    }

    .wp-block-image {
        .alignleft {
            float: left;
            margin-right: 2rem;
            margin-bottom: 1rem;
        } 

        .alignright {
            float: right;
            margin-left: 2rem;
            margin-bottom: 1rem;
        } 
    }

    .wp-block-table {
        margin-left: 0;
        margin-right: 0;
        td {
            border: 1px solid $default-text;
            padding: 0.5rem;
        }
    }

    .wp-block-embed__wrapper {
        iframe {
            width: 100%;
        }
    }

    .is-layout-flex {
        display: flex;
    }

    .wp-block-button {
        margin-right: map-get(spacing.$spacing-values, "2");
    }
    
    // .button.is-rounded.is-primary
    .wp-block-button__link {
        @extend .button, .is-primary;
        
        // Bulma overrides this - annoying!!
        border-radius: 9999px !important;
        padding-left: 1.25em !important;
        padding-right: 1.25em !important;
    }

}



