@import "../../styles/_colors.scss";

$dot-size: 1.5rem;
$gap: 1rem;
$animation-duration: 0.8s;

.propelle-loader {
    span {
        background-color: $violet;
        border-radius: 9999px;
        min-width: $dot-size;
        width: $dot-size;
        min-height: $dot-size;
        height: $dot-size;
        display: inline-block;
        
        &:not(:first-of-type) {
            margin-left: $gap;
        }

        animation-name: pulse;
        animation-duration: $animation-duration;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        
        &:nth-child(1) {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            animation-delay: 0.1s;
        }

        &:nth-child(3) {
            animation-delay: 0.2s;
        }

        @keyframes pulse {
            0%, 60% {
                transform: scale(1);
            }
            30% {
                transform: scale(1.2);
            }
        }
    }
}