@import '../../../../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../../../styles/colors';

.article {
    .author {
        img {
            height: 3rem;
            width: 3rem;
            border-radius: 100%;
            object-fit: cover;
        }
    }

    .article-info {
        @include mobile {
            flex-wrap: wrap;
        }

        .audio {
            @include mobile {
                width: 100%;
            }
        }
    }

    .rm-audio-player-provider {
        width: 22rem !important;
        border-radius: .25rem;
        --rm-audio-player-interface-container: transparent;
        --spectrum-alias-background-color-default: transparent;
        --rm-audio-player-track-current-time: #{$peach-very-dark};
        --rm-audio-player-progress-bar: #{$peach-very-dark};

        @include mobile {
            --rm-audio-player-interface-container: #ffffff80;
            --spectrum-alias-background-color-default: #ffffff80; 
        }


        .track-current-time, .track-time-duration-container {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            letter-spacing: normal;
        }

        .btn-wrapper button {
            color: $navy;
        }

        .interface-grid {
            border-radius: .25rem;
        }

        .play-button + .prev-n-next-button {
            display: none;
        }
    }
}