@import "../../../../../../node_modules/bulma/sass/utilities/_all.sass";


.goal-planning {

    .goal-planning-content {
        max-width: 52.5rem;
    }

    .goal-planning-padding {
        padding: 3.125rem 2.5rem 2.813rem 2.5rem;
    }

    .goal-planning-padding-x {
        padding: 0rem 2.5rem 0rem 2.5rem;
        @include until($tablet) {
            padding: 0rem 1rem 0rem 1rem;
        }
    }
    .goal-planning-padding-top {
        padding-top: 3.125rem;
        @include until($tablet) {
            padding-top: 0rem;
        }
    }
    .goal-planning-padding-bottom {
        padding-bottom: 2.813rem
    }

}