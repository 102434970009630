@import '../../styles/colors';

.my-account {
    form {
        .label {
            color: black;
        }
    }
    
    .explainer {
        position: absolute;
        z-index: 40;
        left: 0;
        color: black;
        max-width: 36rem;
    
        .notification {
            border: 1px solid $grey;
        }
    }
}