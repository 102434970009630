@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";
@import "../../styles/_headers.scss";
@import "../Nav/_variables.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "./_variables.scss";

$menu-item-default-padding: 0.75rem;
$menu-indent: 2rem;
$submenu-item-indent: 1rem;

.menu-container {
    top: $navbar-height;
    left: 0;
    position: fixed;
    min-height: calc(100vh - $navbar-height);
    width: $desktop-menu-width;
    z-index: 10;
    background-color: $off-white;

    @include until($desktop) {
        display: none;
    }
}

.menu {
    margin-top: $header-height;

    .menu-list {
        margin: 0;
        border-left: none;
    }

    .menu-list {
        li {
            &:not(.has-children) {
                padding-left: $menu-indent;
            }

            /* Submenu styles */
            &.has-children {
                p {
                    padding-left: calc($menu-indent + $menu-item-default-padding);
                }

                ul {
                    li {
                        padding-left: calc($menu-indent + $submenu-item-indent);
                    }
                }
            }

            ul {
                padding-left: 0;
            }
        }

        a {
            &:hover {
                color: $navy;
                background: inherit;
            }
        }
    }

    ul {
        li {
            padding: 0.5rem 0;

            &.has-children {
                > p {
                    &:hover {
                        background-color: $desktop-menu-background-hover;
                        color: $navy;
                        cursor: pointer;
                    }
                }

                ul {
                    max-height: 0;
                    overflow-y: clip;
                    transition: max-height 0.3s;

                    &.is-expanded {
                        max-height: 13rem;
                    }
                }

                .chevron {
                    padding: 0rem 2rem;
                }

                &.is-active {
                    > p {
                        background-color: $desktop-menu-background-hover;
                    }
                }
            }

            &:not(.has-children) {
                &:hover {
                    background-color: $desktop-menu-background-hover;
                    cursor: pointer;
                }
        
                &.is-active {
                    background-color: $desktop-menu-background-active;
                }
            }
        }
    }

    a {
        font-weight: 700;
        font-size: 1em;
        text-transform: none;
        font-family: $montserrat;
        color: $navy;
        letter-spacing: 0.2em;
                
        &:hover {
            background-color: transparent;
            color: $navy;
        }
    }

    p {
        padding: 1rem;
        font-weight: 700;
        font-size: 1em;
        text-transform: none;
        font-family: $montserrat;
        color: $navy;
        letter-spacing: 0.2em;
    }
}