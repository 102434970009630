@import "../../../../../../../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../../../../../../../styles/_colors.scss";
@import "../../../../../../../components/Nav/variables.scss";

.results {
    max-width: 61.875rem;

    @include until($desktop) {
        background-color: $peach-very-light;
        min-height: 100%;
        min-width: 100%;
        h4 {
            text-align: center;
        }

    }

    .button {
        @include until($desktop) {
            width: 100%;
            display: inline-block;
        }
    }
}