@import '../../../../../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../../../../styles/colors';

.topic-page {
    .unit-progress-container {
        gap: 10px;
    }

    .author {
        img {
            height: 3rem;
            width: 3rem;
            border-radius: 100%;
            object-fit: cover;
        }
    }

    .topic-complete-container {
        .topic-complete-content {

            .topic-complete-title {
                font-size: 25px;
                line-height: 1;
              
                text-align: center;
                line-height: normal;
                &--xl {
                    @extend .topic-complete-title;
                    font-size:60px;
                }
            }
        }
    }

    .topic-breadcrumb {
        @media screen and (max-width: 1023px) {
            margin-left:-1rem !important;
        }
    }

    .btn-locked {
        cursor: not-allowed !important;
        pointer-events: none !important;
    }

    .is-mid-width {
        flex-wrap: wrap !important;
        @media screen and (min-width: 1024px) {
            max-width:800px;
        }
    }

    .is-mid-width-btn-container {
        @include desktop {
            min-width: 345px;
            max-width: 345px;
        }
    }

    .is-small-width-btn-container {
        @include desktop {
            min-width: 245px;
            max-width: 245px;
        }
    }


    .quiz-questions-container {

            .quiz-question-index {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
            }

            .is-align-center {
                text-align: center;
            }

            .topic-btn {
                justify-content: center;
            }

            .quiz-option {
                @extend .topic-btn;
                color: #393939;
                background-color: #E9E6F7;
                white-space:normal;
                min-height: 2.5em;
                height: fit-content;

                &:hover {
                    background-color:  #CDC2FF;
                    color: #393939;
                }
            }
            .selected {
                @extend .quiz-option;
                background-color: #CDC2FF;
            }
            .correct {
                @extend .quiz-option;
                // @extend .btn-locked;
                background-color: #DCFFCA !important;
            }
            .wrong {
                @extend .quiz-option;
                // @extend .btn-locked;
                background-color: #F5C6B7 !important;
            }
            .quiz-answer {
                font-weight: 400;
                font-size: 18px;
            }

    }

    .close-quiz {
       
        color:white !important;
    }

    .article-info {
        @include mobile {
            flex-wrap: wrap;
        }

        .audio {
            @include mobile {
                width: 100%;
            }
        }
    }

    .rm-audio-player-provider {
        width: 22rem !important;
        border-radius: .25rem;
        --rm-audio-player-interface-container: transparent;
        --spectrum-alias-background-color-default: transparent;
        --rm-audio-player-track-current-time: #{$peach-very-dark};
        --rm-audio-player-progress-bar: #{$peach-very-dark};

        @include mobile {
            --rm-audio-player-interface-container: #ffffff80;
            --spectrum-alias-background-color-default: #ffffff80;
        }


        .track-current-time,
        .track-time-duration-container {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            letter-spacing: normal;
        }

        .btn-wrapper button {
            color: $navy;
        }

        .interface-grid {
            border-radius: .25rem;
        }

        .play-button+.prev-n-next-button {
            display: none;
        }
    }

    @media(max-width: 767px) {
        background: #FFF8F1;
        min-height: fit-content;
        .glass-desktop {
            min-height: calc(100vh - 11.5rem);
        }
        .wordpress-content {
            min-height: max-content;
        }
        .is-type-video{
            .wp-block-embed__wrapper {
                width: 100%;
                iframe {            
                    width: 100%;
                }
            }
        }
    }
}