.select-input {
    input[type="checkbox"] {
        display: none;
    }

    max-width: 80%;
    gap: 1rem;

    * {
        &:hover {
            cursor: pointer;
        }
    }
}