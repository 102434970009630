@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";
@import "../../styles/_headers.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../Menu/_variables.scss";

$active-item-border-width: 0.5rem;

.secondary-nav {
    a {
        letter-spacing: .2em;
        font-family: $montserrat;
        color: $navy;
    }

    .header {
       height: 52px;
    }
}

.secondary-sub-nav {
    margin-bottom: .5rem;

    .button {
        padding: 1.25rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        &.is-light.is-active {
            color: $white;
            background-color: $navy;
        }
    }

    .header {
        height: 60px;
        border-top: 1px solid $grey;
    }

    .navbar {
        padding-top: 0.5rem;
    }
}

.secondary-nav-spacer {
    position: relative;
    width: 100%;
    min-height: $header-height;
    height: $header-height;
}

.header-container {
    position: fixed;
    min-height: $header-height + calc($active-item-border-width / 2);
    width: 100%;
    z-index: 30;

    @include from($desktop) {
        width: calc(100% - $desktop-menu-width)
    }

    .header {
        width: 100%;
        z-index: 30;
        background: linear-gradient(90deg, #fff 0%, #fff6e9 100%);
        overflow-x: auto;
        overflow-y: hidden;
        // &::-webkit-scrollbar {
        //     -webkit-appearance: none;
        // }

        .navbar {
            background-color: transparent;
    
            .navbar-brand {
                width: 100%;
                max-width: $desktop;
    
                @include from($desktop) {
                    width: 50%;
                }
    
                .navbar-item {
                    // flex: 1;


                    // justify-content: center;
    
                    &.is-active {
                        position: relative;
                        padding-bottom: $active-item-border-width;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            height: .5rem;
                            width: calc(100% - 3rem);
                            background-color: $violet;
                            border-radius: 5px 5px 0 0;
                            min-width: 3rem;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    &:not(.is-active):hover {
                        background-color: $desktop-menu-background-hover;
                        color: $navy;
                    }
                }
            }
        }
    }
}

