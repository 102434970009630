@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "./_variables.scss";


@mixin dropdown-menu-link {
    color: $navy;

    &:hover {
        color: $navy;
        background-color: rgba($navy, 0.1);
    }

    &:active {
        color: $white;
        background-color: $navy;
    }
}

.propelle-navbar {
    &.navbar {

        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,246,233,1) 100%);
        height: $navbar-height;
        width: 100%;

        @include until($desktop) {
            height: $navbar-mobile-height;
        }

        &.is-white-touch {
            @include until($desktop) {
                background: white;
            }
        }

        @include from($desktop) {
            padding: 0 1rem;
        }
    
        img {
            min-height: $navbar-image-height;
            max-height: $navbar-image-height;
            margin: 0.25rem 0.5rem;

            @include until ($desktop) {
                min-height: $navbar-mobile-image-height;
                max-height: $navbar-mobile-image-height;
            }
        }
    
        .navbar-brand {
            height: $navbar-height;
            justify-content: space-between;

            @include until($desktop) {
                height: $navbar-mobile-height;
            }
        }
        
        a {
            font-weight: 600;
            color: $navy;
        }
    }
    
    .navbar-burger {
        min-height: $navbar-height;

        @include until($desktop) {
            min-height: $navbar-mobile-height;
        }

        &:hover {
            background-color: inherit;
        }
    
        span {
            height: 1px;
            background-color: $royal-blue;
            width: 24px;
            left: calc(50% - 16px);
        }
    }
    
    .navbar-menu {
        font-family: $montserrat;
        height: 100%;
    
        .navbar-link {
            color: $navy;
        }

        .navbar-dropdown {
            a {
                @include dropdown-menu-link;
            }
        }
    
        .navbar-item {
            color: $navy;
            display: flex;

            &.has-dropdown.is-desktop {
                padding-right: 3rem;
            }
    
            &.is-mobile {
                @include from($desktop) {
                    display: none;
                }

                @include dropdown-menu-link;
            }
    
            &.is-desktop {
                @include until($desktop) {
                    display: none;
                }
            }
        }
    
        @include until($desktop) {
            margin-left: 50%;
        }
    }
}
