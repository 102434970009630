
@import "../../../styles/_colors.scss";
@import "../../../../node_modules/bulma/sass/utilities/_all.sass";

.multiple-choice-radio-option {
    .expander {
        margin-left: -0.5rem;
        margin-top: 0.3rem;
        
        &.is-expanded {
            img {
                transition: transform 0.2s;
                transform: rotate(180deg);
            }
        }

        &:not(.is-expanded) {
            img {
                transition: transform 0.2s;
                transform: rotate(0);
            }
        }
    }

    input[type="radio"] {

        transform: translateY(-0.075em);
        margin-right: 0.5rem;
        -webkit-appearance: none;
        appearance: none;
        background-color: $chat-background-color;

        /* Custom radio button style */
        $inactiveColor: #888888;
        $activeColor: $navy;

        color: $inactiveColor;
        width: 1.25em;
        height: 1.25em;
        border: 0.15em solid $inactiveColor;
        border-radius: 50%;
        display: grid;
        place-content: center;

        &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $activeColor;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}