@import "../styles/colors";

.section {
    @include until(768px) {
        padding: 1.5rem;
    }
}

.footer {
    @include until(768px) {
        padding: 1.5rem 1.5rem 2rem;
    }
}

.divider {
    height: 1px;
    background-color: lighten($default-text, 50%);
}

main.without-nav {
    left: 0;
    .background {
        left: 0;
    }
    .section {
        @include desktop {
            position: relative;
            left: 14.5rem;
        }
    }
}

.button {
    &.is-fullwidth-mobile {
        @include mobile {
            width: 100%;
            justify-content: center;
        }
    }
}
