
@import "../../../../../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../../../../styles/_colors.scss";


$goal-reached: #EEFFEE;


.calculator-result {
    
    &.default-background {
        background-color: $calculator-background-color!important;
    }

    &.goal-reached {
        background-color: $goal-reached!important;
    }

    .title {
        &.is-4 {
            margin-bottom: 0.75rem;
        }
    }

    p {
        font-size: 0.8em;
    }

    .highlighted {
        margin: 1.5rem 0;
        transform: scale(1.2);

        @include from($desktop) {
            transform: scale(1.2) translateY(-0.75rem);
        }
    }
    .goal-planning-padding-x {
        padding: 0rem 2.5rem 0rem 2.5rem;
        @include until($tablet) {
            padding: 0rem 1.625rem 0rem 1.625rem;
        }
    }
    .goal-planning-padding-top {
        padding-top: 3.125rem
    }
    .goal-planning-padding-bottom {
        padding-bottom: 2.813rem
    }
}

.performance-explainer {

    $default-performance-explainer-bullet-width: 33px;
    $default-performance-explainer-bullet-height: 18px;
    $default-performance-explainer-bullet-margin-right: 8px;

    @mixin performance-explainer-bullet {
        content: '';
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        min-width: $default-performance-explainer-bullet-width;
        height: $default-performance-explainer-bullet-height;
        margin-right: $default-performance-explainer-bullet-margin-right;
    }

    .has-performance-bullet {

        &-down {
            &::before {
                @include performance-explainer-bullet;
                background-image: url('./down.svg');
            }
        }

        &-level {
            &::before {
                $less-width-by: 10px;

                @include performance-explainer-bullet;
                background-image: url('./level.svg');
                min-width: calc($default-performance-explainer-bullet-width - $less-width-by);
                height: 18px;
                margin-top: 3px;
                margin-right: calc($default-performance-explainer-bullet-margin-right + $less-width-by);
            }
        }

        &-up {
            &::before {
                @include performance-explainer-bullet;
                background-image: url('./up.svg');
            }
        }
    }
}