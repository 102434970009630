@import "../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../../node_modules/bulma/sass/helpers/spacing.sass";
@import "./colors";

// pull-right-#{breakpoint}
@each $breakpoint,
$queries in $breakpoints {
  .pull-right-#{$breakpoint} {
    @include breakpoint($breakpoint) {
      order: 1;
    }
  }
}

// image-banner-#{breakpoint}
@each $breakpoint,
$queries in $breakpoints {

  .image-banner-#{$breakpoint} figure {
    @include breakpoint($breakpoint) {
      width: 100%;
      margin: 0;
      height: 200px;
    }

    img {
      @include breakpoint($breakpoint) {
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
      }
    }
  }
}

// criss-cross-#{breakpoint}
@each $breakpoint,
$queries in $breakpoints {
  .criss-cross-#{$breakpoint} {
    .columns {
      padding-top: $size-3;

      @include breakpoint($breakpoint) {
        padding-top: $size-6;
      }

      &:first-child {
        padding-top: 0;
      }

      &:nth-child(2n - 1) {
        .column:first-child {
          order: 1;

          @include breakpoint($breakpoint) {
            order: 0;
          }
        }
      }

      .column {
        figure {
          @include breakpoint($breakpoint) {
            width: 100%;
            margin: 0;
            height: 250px;
          }

          img {
            @include breakpoint($breakpoint) {
              height: 100%;
              object-fit: cover;
              border-radius: $radius;
            }
          }
        }
      }
    }
  }
}

// glass-#{breakpoint}
@each $breakpoint,
$queries in $breakpoints {
  .glass-#{$breakpoint} {
    position: relative;

    @include breakpoint($breakpoint) {
      background-color: transparent !important;
    }

    &:before {
      @include breakpoint($breakpoint) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparentize($color: #fff, $amount: 0.5);
        border-radius: 0.5rem;
        z-index: -1;
      }
    }

    >* {
      background-color: transparent;
    }
  }
}

//responsive padding & margins
@each $property,
$shortcut in $spacing-shortcuts {

  @each $breakpoint,
  $breakpoint-value in $breakpoints {

    @each $name,
    $value in $spacing-values {

      // All directions
      .#{$shortcut}-#{$name}-#{$breakpoint} {
        @include breakpoint(#{$breakpoint}) {
          #{$property}: $value !important;
        }
      }

      // Cardinal directions
      @each $direction,
      $suffix in $spacing-directions {
        .#{$shortcut}#{$suffix}-#{$name}-#{$breakpoint} {
          @include breakpoint(#{$breakpoint}) {
            #{$property}-#{$direction}: $value !important;
          }
        }
      }

      // Horizontal axis
      @if $spacing-horizontal !=null {
        .#{$shortcut}#{$spacing-horizontal}-#{$name}-#{$breakpoint} {
          @include breakpoint(#{$breakpoint}) {
            #{$property}-left: $value !important;
            #{$property}-right: $value !important;
          }
        }
      }

      // Vertical axis
      @if $spacing-vertical !=null {
        .#{$shortcut}#{$spacing-vertical}-#{$name}-#{$breakpoint} {
          @include breakpoint(#{$breakpoint}) {
            #{$property}-top: $value !important;
            #{$property}-bottom: $value !important;
          }
        }
      }
    }
  }
}

/* Base colors */
@each $family,
$entries in $color-pallete {
  .has-text-#{$family} {
    color: map-get($entries, "400") !important; 
  }

  .has-background-#{$family} {
    background-color: map-get($entries, "400") !important;
  }

  @each $variation,
  $hex in $entries {
    .has-text-#{$family}-#{$variation} {
      color: $hex !important;
    }

    /* Add base border colors too */
    $border-thicknesses: (
      "1": 1px,
      "2": 2px,
      "3": 3px
    );

  @each $thickness,
  $pixels in $border-thicknesses {
    .has-border-#{$family}-#{$variation}-#{$thickness} {
      border: $pixels solid $hex;
    }

    .has-inset-border-#{$family}-#{$variation}-#{$thickness} {
      $border: inset 0 0 0 $pixels $hex;

      box-shadow: $border;
      -webkit-box-shadow: $border;
      -moz-box-shadow: $border;
    }
  }

  @each $transparency,
  $value in $transparencies {
    .has-text-#{$family}-#{$variation}-#{$transparency} {
      color: rgba($hex, $value) !important;
    }
  }

  .has-background-#{$family}-#{$variation} {
    background-color: $hex !important;
  }

  /* Add responsive modifiers to backgrounds */
  @each $breakpoint,
  $queries in $breakpoints {
    .has-background-#{$family}-#{$variation}-#{$breakpoint} {
      @include breakpoint($breakpoint) {
        background-color: $hex !important;
      }
    }
  }

  /* Add transparency modifiers */
  @each $transparency,
  $value in $transparencies {
    .has-background-#{$family}-#{$variation}-#{$transparency} {
      background-color: rgba($hex, $value) !important;
    }

    /* Add responsive modifiers to backgrounds */
    @each $breakpoint,
    $queries in $breakpoints {
      .has-background-#{$family}-#{$variation}-#{$transparency}-#{$breakpoint} {
        background-color: transparent;

        @include breakpoint($breakpoint) {
          background-color: rgba($hex, $value);
        }
      }
    }
  }
}
}

.has-background-transparent {
  background-color: transparent;
}

/* Add responsive modifiers to transparent background */
@each $breakpoint,
$queries in $breakpoints {
  .has-background-transparent-#{$breakpoint} {
    @include breakpoint($breakpoint) {
      background-color: transparent;
    }
  }
}

/* Add peach gradient background */

.has-background-gradient-peach {
  background: $peach-gradient;
}

@each $breakpoint,
$queries in $breakpoints {
  .has-background-gradient-peach-#{$breakpoint} {
    @include breakpoint($breakpoint) {
      background-color: $peach-gradient;
    }
  }
}

.has-background-gradient-peach-light {
  background: $peach-gradient-lighter;
}

@each $breakpoint,
$queries in $breakpoints {
  .has-background-gradient-peach-light#{$breakpoint} {
    @include breakpoint($breakpoint) {
      background-color: $peach-gradient-lighter;
    }
  }
}

/* Gradients */
$gradient-directions: (
  "vertical": bottom,
  "horizontal": right
);

@each $gradient-direction,
$to-side in $gradient-directions {

  @each $from-family,
  $from-entries in $color-pallete {

    @each $from-variation,
    $from-hex in $from-entries {

      @each $to-family,
      $to-entries in $color-pallete {

        @each $to-variation,
        $to-hex in $to-entries {
          .has-background-gradient-#{$gradient-direction}-from-#{$from-family}-#{$from-variation}-to-#{$to-family}-#{$to-variation} {
            background: linear-gradient(to #{$to-side}, $from-hex, $to-hex);
          }
        }
      }
    }
  }
}

.has-text-nowrap {
  text-wrap: nowrap;
}

.is-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.is-line-break-anywhere {
  line-break: anywhere;
}

.is-white-space-nowrap {
  white-space: nowrap;
}

.is-position-relative {
  position: relative;
}

.is-position-absolute {
  position: absolute;
}

.is-table-layout-fixed {
  table-layout: fixed;;
}

.is-flex {

  @each $breakpoint,
  $queries in $breakpoints {
    &-#{$breakpoint} {
      @include breakpoint($breakpoint) {
        display: flex !important;
      }
    }
  }
}

.is-position-fixed {
  position: fixed;
}

.is-top {
  top: 0;
}

.is-bottom {
  bottom: 0;
}

.is-left {
  left: 0;
}

.is-right {
  right: 0;
}

.is-clipped {
  overflow: clip;
}

.is-overflow-scroll {
  &-x {
    overflow-x: scroll;
  }

  &-y {
    overflow-y: scroll;
  }
}

.is-fullwidth {
  width: 100%;
}

.is-fullheight {
  width: 100%;
}

@each $name, $value in $spacing-values {
  .is-height-#{$name} {
    height: $value !important;
  }

  .is-min-height-#{$name} {
    min-height: $value !important;
  }
  
  .is-max-height-#{$name} {
    max-height: $value !important;
  }
}

.is-width-one-third {
  width: 33%;
  
  @each $breakpoint,
  $queries in $breakpoints {
    &-#{$breakpoint} {
      @include breakpoint($breakpoint) {
        width: 33%;
      }
    }
  }
}

.is-width-half {
  width: 50%;
  
  @each $breakpoint,
  $queries in $breakpoints {
    &-#{$breakpoint} {
      @include breakpoint($breakpoint) {
        width: 50%;
      }
    }
  }
}

.is-width-two-thirds {
  width: 66%;

  @each $breakpoint,
  $queries in $breakpoints {
    &-#{$breakpoint} {
      @include breakpoint($breakpoint) {
        width: 66%;
      }
    }
  }
}

.is-fullheight {
  height: 100%;
}

.is-rounded {
  border-radius: 0.5rem;

  @each $name,
  $value in $spacing-values {
    &-#{$name} {
      border-radius: $value !important;
    }

    @each $breakpoint,
    $queries in $breakpoints {
      &-#{$name}-#{$breakpoint} {
        @include breakpoint($breakpoint) {
          border-radius: $value !important;
        }
      }
    }
  }

  &-bottom {
    @extend .is-rounded;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &-top {
    @extend .is-rounded;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@each $name,
$value in $spacing-values {
  .is-flex-gap-#{$name} {
    gap: $value !important;
  }
}

/* z-indexes */

.is-z-index {
  $i: -100;

  @while $i <=100 {
    &-#{$i} {
      z-index: $i !important;
    }

    $i: $i + 10;
  }
}

/* Smaller font size */
@each $breakpoint,
$queries in $breakpoints {
  .is-size-smaller-#{$breakpoint} {
    @include breakpoint($breakpoint) {
      font-size: $size-7;
    }
  }
}

// Add 5x2 image size

.image.is-5by2 {
  padding-top: 40%;
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.table {
  &.is-borderless {
    border: none;
    tr, td, th {
      border: none;
    }
  }
  &.is-no-wrap {
    td, th {
      white-space: nowrap;
    }
  }
}

.locked {
  filter: blur(3px);
  pointer-events: none !important;
  
  * {
    pointer-events: none !important;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.blocked {
  position: relative;
  pointer-events: none !important;
  
  * {
    pointer-events: none !important;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.has-letter-spacing-1 {
  letter-spacing: 1px;
}


.has-letter-spacing-2 {
  letter-spacing: 0.2rem;
}

.is-family-montserrat {
  font-family: $montserrat, sans-serif;
}

.is-size-hero {
  font-size: $size-hero;
}

.is-size-small {
  font-size: 0.9375rem;
}

.is-size-smaller {
  font-size: $size-7;
}

.control.has-icons-left {
  .icon {
    z-index: auto;
    svg {
      fill: #dbdbdb;
    }
  }
  .input:focus ~ .icon svg {
    fill: #4a4a4a;
  }
}

.has-order-1-tablet {
  @include from($tablet) {
      order: 1;
  }
}