@use "../../../styles/_colors.scss" as colors;
@use "../../../styles/fonts";

.property-opportunity {
    .border-bottom {
        border-bottom: 2px solid map-get(map-get(colors.$color-pallete, "grey"), "200");
    }

    .tag {
        z-index: 10;
        position: absolute;
        inset: auto;
        right: 0;
        border-top-right-radius: 10px;
        font-size: fonts.$size-7;
        height: 35px;
    }
}