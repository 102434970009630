@import '../../../node_modules/bulma/sass/components/card.sass';

$card-radius: 10px;

.events-carousel {
    .card {
        .card-header {
            box-shadow: none;
        }
        .card-image {
            img {
                border-radius: $card-radius;
            }
        }
    }

    .level {
        &.virtual-event-label {
            .level-item  {
                justify-content: start;

                &.virtual-event-label-icon {
                    flex-grow: 0;
                }
            }
        }
    }
}
