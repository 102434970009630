@import "../../../../../node_modules/bulma/sass/utilities/_all.sass";
@import "./_variables.scss";
@import "../../../../styles/_fonts.scss";
@import "../../../../styles/_colors.scss";
@import "../../../../components/Nav/_variables.scss";

.wealth-mapping-nav {
    height: $navbar-height;

    .navbar {
        background-image: url("../../../../assets/peach-white-fade.svg");
        min-height: $navbar-height;
        z-index: 10;

        
        @include from($desktop) {
            padding: 0 1rem;
        }
    
        img {
            min-height: $navbar-image-height;
            max-height: $navbar-image-height;
            margin: 0.25rem 0.5rem;
        }
    
        .navbar-brand {
            height: $navbar-height;
            justify-content: space-between;
        }
        
        a {
            font-weight: 600;
            color: $navy;
        }
    }

    .is-hidden-touch {
        .navbar {
            a:last-child {
                margin-left: 8rem;
            }
        }
    }
}