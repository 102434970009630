
@import "../../../../../../styles/_colors.scss";
@import "../../../../../../../node_modules/bulma/sass/utilities/_all.sass";

.projection {
    margin-top: 1.5rem;
    overflow: hidden;

    .projection-header {
        background-color: $calculator-background-color;
    }


    .input-big {
        max-width: 15.625rem;
    }

    .input-small {
        width: 4.063rem; 
    }

    .calculate-button {
        width: 10.625rem;
        @include until($tablet) {
            width: 100%;
        }
    }
    .goal-planning-padding-x {
        padding: 0rem 2.5rem 0rem 2.5rem;
        @include until($tablet) {
            padding: 0rem 1.625rem 0rem 1.625rem;
        }
    }
    .goal-planning-padding-top {
        padding-top: 3.125rem
    }
    .goal-planning-padding-bottom {
        padding-bottom: 2.813rem
    }

    .default-background {
        background-color: $calculator-background-color!important;
    }
}

.card {
    .card-content {
        color: $navy;
        
        p {
            &:not(:first-of-type) {
                margin-top: 1rem;
            }

            strong {
                color: $navy;
            }
        }
    }
}

.help {
    &.is-danger {
        @include from ($desktop) {
            text-align: right;
        }
    }
}

.calculator-padding {
    padding: 3.125rem 2.5rem 2.813rem 2.5rem;
}