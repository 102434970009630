@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../Nav/_variables.scss";
@import "../Menu/_variables.scss";

body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.content-container {
    position: absolute;
    top: $navbar-height;
    z-index: 0;
    display: flex;
    flex-direction: column;

    @include until($desktop) {
        left: 0;
        width: 100%;
        height: calc(100vh - $navbar-mobile-height - $mobile-menu-height);
        top: $navbar-mobile-height;
    }

    @include from ($desktop) {
        left: $desktop-menu-width;
        min-height: calc(100vh - $navbar-height);
        width: calc(100vw - $desktop-menu-width);
    }

    & > .propelle-loader {
        @include until($desktop) {
            height: calc(100vh - #{$navbar-height} - #{$mobile-menu-height} - 3rem);
        }
        @include from ($desktop) {
            height: calc(100vh - #{$navbar-height} - 3rem);
        }
    }
}

.columns {
    &.layout {

        &.is-full-height {
            margin: 0;
        }
        
        .column {
            padding: 0;
        }
      
        .menu-column {
            background-color: #FFFFFF;
            display: none;
            max-width: 24rem;

            @include from($desktop) {
                display: flex;
            }
        }
    }
}

.columns.is-full-height {
    height: 100%;
}

.background {
    background-color: $peach-very-light;
    background-image: url("../../assets/background-yellow-violet-fade.svg");
    background-repeat: no-repeat;
    width: 100vw;
    position: fixed;
    top: $navbar-height;
    z-index: -1;

    @include until($desktop) {
        left: 0;
        width: 100%;
        height: calc(100vh - $navbar-mobile-height - $mobile-menu-height);
        top: $navbar-mobile-height;
    }

    @include from ($desktop) {
        left: $desktop-menu-width;
        min-height: calc(100vh - $navbar-height);
    }
}