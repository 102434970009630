@charset "utf-8";

/* Imports */
@import "spacing";
@import "colors";
@import "fonts";
@import "columns";
@import "variable-overrides";

/* Customize which bulma framework elements to import */
@import "../../node_modules/bulma/bulma.sass";

// Bulma did a doozy
$breakpoints: map_merge($breakpoints, ("touch": ("until": $desktop)));

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

@import 'animations';
@import 'layout';
@import 'utils';
@import 'content';
@import 'cms';
@import "forms";

/* Overwrite bulma defaults where necessary */
body,
button,
input,
optgroup,
select,
textarea {
  font-family: $family-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $family-secondary;
}

hr {
  height: 1px !important;
  &.is-size-2 {
    height: 2px !important;
  }
}

.select select:focus {
  border-width: 0.15rem;
  box-shadow: none;
}

/* Miscellaneous */

// Show cursor when editing contenteditable fields in Safari
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

html {
  scroll-behavior: smooth;
}

// Smooth font appearance in Safari
body {
  -webkit-font-smoothing: antialiased;
}