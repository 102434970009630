@import "/node_modules/bulma/sass/utilities/mixins.sass";
@import "/node_modules/bulma/sass/helpers/spacing.sass";
@import "/src/styles/_colors.scss";
@import "/src/styles/_fonts.scss";

.account-setup-overview {
    ul {
        li.step {
            min-height: 82px;
            padding: 15px;
            line-height: 31px;

            &.step-in-progress {
                background: linear-gradient(118.93deg, rgba(255, 213, 200, 0.9) -5.87%, rgba(252, 229, 208, 0.9) 109.87%);
                border: 1px solid $peach-very-dark;
                border-radius: 10px;
                color: $navy;

                .bullet-point {
                    background-image: url('./ellipse.svg');
                    color: white;
                }
            }

            &.step-completed {
                color: #616161;

                .bullet-point {
                    background-image: url('./check.svg'), url('./ellipse.svg');
                    color: white;
                    opacity: 0.6;
                }
            }
        }
    }
    .bullet-point {
        color: #858585;
        background-image: url('./ellipse_grey.svg');
        background-position: 50% 0%;
        background-repeat: no-repeat;
        background-size: contain;
        min-width: 31px;
        min-height: 100%;
        font-weight: 700;
        font-size: 16px;
        font-family: $montserrat;
        text-align: center;
    
    }
}

