@use "../../../node_modules/bulma/sass/utilities/mixins.sass" as mixins;

.article-card {
    .card-large {

        .level {
            margin: 0;
        }

        @include mixins.tablet {
            .card-content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .card-image figure {
                padding-top: 75%;
            }
        }
    }

    .card-small {
        display: flex;
        flex-direction: row;
        .card-image {
            // margin-bottom: 1rem;
            @include mixins.mobile {
                width: 6rem;
                height: 6rem;
                flex: none;
            }
            // @include tablet {
            //     width: 50%;
            // }
            figure {
                @include mixins.mobile {
                    padding-top: 100%;
                }
                @include mixins.tablet {
                    min-height: 100%;
                    width: 125px;
                }
                @include mixins.fullhd {
                    width: 200px;
                }
            }
        }
    }

    .level {
        &.content-label {
            .level-item  {
                justify-content: start;
                text-decoration: underline;
    
                &.content-label-icon {
                    flex-grow: 0;
                }
            }
        }
    }
}