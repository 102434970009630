@import '../../../../../../../node_modules/bulma/sass/utilities/_all.sass';

.latest-articles {
    .article-list {
        .card-wrapper {
            @include tablet {
                display: flex;
                min-height: 7rem;
            }

            .card {
                @include tablet {
                    width: 100%;
                }
            }
        }
    }
}