@import './_colors';
@import "../../node_modules/bulma/sass/utilities/_all.sass";

/* These colours are not part of the default pallete */
$forms-grey: #CDCDCD;

// form {
//     .label {
//         @include from($tablet) {
//             white-space: nowrap;
//         }
//     }
// }

.field {
    // max-width: 36rem;

    &.is-horizontal {
        .field-label {
            text-align: left;
        }
    }

    &.has-errors {
        .label {
            color: $danger !important;
        }

        .field-label {
            color: $danger !important;
        }

        .input {
            border-color: $danger !important;
        }
    }

    &:not(.has-errors) {
        .help {
            &.is-danger {
                display: none;
            }
        }
    }
}

.input {
    border-color: $forms-grey !important;
    border-width: 1px !important;
    box-shadow: none !important;

    &:focus {
        border-color: map-get(map-get($color-pallete, "purple-blue"), "300");
    }

    &:active {
        border-color: map-get(map-get($color-pallete, "purple-blue"), "300");
    }
}

.input-box {
    z-index: 25;
    &.multi-select {
        z-index: 26;
    }
}

.radio-toggle {
    display: flex;
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: flex;
        background-color: #fff;
        border: 2px solid #CCC;
        border-radius: 4px;
        height: 40px;
        width: 50%;
        justify-content: center;
        align-items: center;

        &.right {
            border-radius: 0 50px 50px 0;
            border-left:1px;
        }

        &.left {
            border-radius: 50px 0 0 50px;
        }
    }

    input[type="radio"]:checked+label {
        background-color: $peach-dark;
    }
}

input[disabled], button[disabled] {
    background-color: map-get(map-get($color-pallete, "grey"), "300") !important;
    border-color: map-get(map-get($color-pallete, "grey"), "300") !important;
    opacity: 1 !important;
}

/* Overwrite strange Bulma default */
button[type="submit"] {
    font-weight: normal !important;
}

button[disabled] {
    color: map-get(map-get($color-pallete, "grey"), "100") !important;
}

.control.has-icons-right .icon {
    z-index: auto;
}