@import "../../../../styles/_colors.scss";
@import "../../../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../components//WealthMappingNavbar/_variables.scss";

.calculator {
    .section {
        background: url('../../../../assets/background-yellow-violet-fade.svg');
        min-height: calc(100vh - $navbar-height);
        padding:0;

        & > :not(.footer-container) {
            padding: 3rem 1.5rem;
        }

        .footer {
            margin-top: 3rem;
        }
    }

    .card {
        .card-content {
            color: $navy;

            p {
                &:not(:first-of-type) {
                    margin-top: 1rem;
                }

                strong {
                    color: $navy;
                }
            }
        }
    }

    .help {
        &.is-danger {
            @include from ($desktop) {
                text-align: right;
            }
        }
    }
}

.sign-up {
    ul {
        margin-top: 1rem;
        padding-left: 2rem;

        li {
            &::before {
                content: url('./bullet.svg');
                display: inline-block;
                width: 48px;
                height: 16px;
                margin-left: -48px;
                margin-top: 4px;
            }
        }
    }
}
