@use '../../../node_modules/bulma/sass/components/card.sass' as card;
@use '../../../node_modules/bulma/sass/utilities/mixins.sass' as mixins;

$card-radius: 10px;

.card-wrapper {
    position: relative;
    text-decoration: none !important;

    .card {
        background-color: transparent;
        box-shadow: none;

        .card-header {
            box-shadow: none;
        }

        .card-image {
            img {
                object-fit: cover;
                border-radius: $card-radius;
            }
        }

        .is-overlay {
            z-index: 10;
        }

        .match-height {
            .card-header-title {
                align-items: baseline;
            }
        }
    }

}

.card {
    .card-header {
        box-shadow: none;
    }
}

a.card-wrapper {
    .card {
        @include mixins.desktop {
            &:hover {
                background-color: card.$card-background-color;
                box-shadow: card.$card-shadow;
            }
        }
    }
}