.chat-input {
    .input {
        background-color: transparent;
        border: none;
        box-shadow: none;

        &:focus {
            border: none;
            box-shadow: none;
        }

        &:hover {
            border: none;
            box-shadow: none;
        }

        &:active {
            border: none;
            box-shadow: none;
        }
    }

    .answer {
        max-width: 40rem;
    }

    input[type=number] {
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;
    }

    .placeholder-text {
        white-space: nowrap;
    }
}